import React from "react"
import { useQuery } from "@apollo/client"
import GET_INVENTORY from "../../../graphql/queries/inventory/GetInventory"
import styled from "styled-components"
import dayjs from "dayjs"
import DotLoader from "../../Ui/DotLoader"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"

const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.6rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin-bottom: 0.8rem;
  padding: 1rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Dates = styled.div`
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`

const LoaderContainer = styled.div`
  height: 1.6rem;
  margin-top: 1rem;
`

type Props = {
  inventoryId: string
  stock: number
  created: string
  updated: string
}

function InventoryRow({ inventoryId, stock, created, updated }: Props) {
  const { data, loading, error } = useQuery(GET_INVENTORY, { variables: { id: inventoryId } })

  if (error)
    return (
      <ErrorMessage
        message={
          <>
            Error loading Inventory [{error.message}]
          </>
        }
      />
    )

  return (
    <Container>
      <Wrapper>
        {loading ? (
          <LoaderContainer>
            <DotLoader />
          </LoaderContainer>
        ) : (
          <b>{data.getInventory.name}</b>
        )}
      </Wrapper>
      <div>
        <b>Quantity</b>: {stock}
      </div>
      <Dates>
        <div>
          <b>Created</b>: {dayjs(created).format("YYYY-MM-DD")}
        </div>
        <div>
          <b>Updated</b>: {dayjs(updated).format("YYYY-MM-DD")}
        </div>
      </Dates>
    </Container>
  )
}

export default InventoryRow
