import gql from "graphql-tag"

const ALL_CUSTOM_STATES = gql`
  query getCustomStates {
    Order {
      customStates {
       states {
         created
         deleted
         description
         id
         operations
         revision
         type
         updated
       }
     }
    }
  }
`

export default ALL_CUSTOM_STATES
