import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  span {
    margin-right: 1rem;
  }

  svg {
    height: 1.6rem;
  }
`

export const Info = styled.div`
display: flex;
  flex-direction: column;
`

export const Comment = styled.div`
display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`

export const DeleteButton = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  padding: 1rem;
  display: flex;
  border-radius: 1rem;

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.greyLighter};
  }
`
