import React, { RefObject, useState } from "react"
import { Common } from "@lib/types"
import { Conditions, FrontendCondition } from "@lib/types/discount"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"

import Threshold from "../../Threshold"

type Props = {
  condition?: FrontendCondition
  addCondition: (data: Conditions) => void
  currencyOptions: Common.Option[]
  submitRef: RefObject<HTMLButtonElement> | undefined
}

const CartThreshold = ({ condition, addCondition, currencyOptions, submitRef }: Props) => {
  const dispatch = useAppDispatch()
  const [thresholds, setThresholds] = useState<Common.Money[]>(condition?.data?.thresholds ?? [])

  const onSubmit = () => {
    if (thresholds.length === 0) {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one threshold is required",
          type: "error"
        })
      )
      return
    }
    addCondition({
      thresholds
    })
  }

  return (
    <>
      <Threshold
        thresholds={thresholds}
        setThresholds={setThresholds}
        currencyOptions={currencyOptions}
      />
      <hr />
      <button
        onClick={() => onSubmit()}
        ref={submitRef}
        type="button"
        style={{ display: "none" }}
      />
    </>
  )
}

export default CartThreshold
