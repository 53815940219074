import React from "react"
import { Common } from "@lib/types"

import { Home } from "./Home"
import { Error404Page } from "./404"
import {
  CreateBundleGroup,
  CreateDiscountRule,
  Discounts,
  ShowBundleGroups,
  ShowCodeGenerationJobs,
  ShowDiscountCodes,
  ShowDiscountRules,
  UpdateBundleGroup,
  UpdateCartRule,
  UpdateDiscountCodeRule
} from "./Discounts"
import { UpdateExternalRule } from "./Discounts/ExternalRule/UpdateExternalRule"
import { Inventories } from "./Inventories/Inventories"
import { Stores } from "./Stores/Stores"
import { StoreGroups } from "./Stores/StoreGroups/StoreGroups"
import { Taxes } from "./Taxes/Taxes"
import { User } from "./Users/User"
import { ApiClients, ExternalEvents, Providers, Settings, UsagePlan } from "./Settings"
import {
  AdyenConfiguration,
  AvardaConfiguration,
  BonusConfiguration,
  IngridConfiguration,
  KlarnaConfig,
  NShiftConfiguration,
  QliroOneConfiguration,
  Retain24Config,
  ShowProviders,
  SveaConfiguration,
  VoyadoConfiguration,
  WalleyConfiguration
} from "./Settings/Providers"
import { Orders } from "./Orders/Orders"
import { ShowOrders } from "./Orders/OrderViews/ShowOrders"
import { ViewOrder } from "./Orders/OrderViews/ViewOrder"
import { CloneOrder } from "./Orders/CreateOrder/CloneOrder"
import { Products } from "./Products/Products"
import { ShowProducts } from "./Products/ShowProducts"
import { ViewProduct } from "./Products/ViewProduct"
import { Campaigns } from "./Campaigns/Campaigns"
import { ShowCampaigns } from "./Campaigns/ShowCampaigns"
import { CreateCampaign } from "./Campaigns/CreateCampaign"
import { UpdateCampaignGroup } from "./Campaigns/UpdateCampaignGroup"

import { ReactComponent as DashboardIcon } from "images/icons/chart-line.svg"
import {
  ReactComponent as CampaignIcon,
  ReactComponent as TaxIcon
} from "images/icons/badge-percent.svg"
import { ReactComponent as StoreIcon } from "images/icons/store.svg"
import { ReactComponent as InventoryIcon } from "images/icons/warehouse.svg"
import { ReactComponent as SettingsIcon } from "images/icons/gear.svg"
import { ReactComponent as OrderIcon } from "images/icons/cart-shopping.svg"
import { ReactComponent as ProductIcon } from "images/icons/bags-shopping.svg"
import { ReactComponent as DiscountIcon } from "images/icons/tags.svg"
import { ReactComponent as InfoIcon } from "images/icons/circle-info.svg"
import { CreateOrder } from "./Orders/CreateOrder/CreateOrder"
import Credentials from "./Settings/Credentials/Credentials"
import { ShowUsers } from "./Users/ShowUsers"
import { UpdateCampaign } from "./Campaigns/UpdateCampaign"
import { Info, ProductBoard } from "./Info"
import ManageOrder from "./Orders/ManageOrder/ManageOrder"
import { DuplicateCampaign } from "./Campaigns/DuplicateCampaign"
import CreateApiDestination from "./Settings/ExternalEvents/DestinationAPIs/CreateApiDestination"
import UpdateApiDestination from "./Settings/ExternalEvents/DestinationAPIs/UpdateApiDestination"
import CustomReasonCodes from "./Settings/CustomReason/CustomReasonCodes"
import { DashboardsSales } from "./Dashboards/DashboardsSales"
import { Dashboards } from "./Dashboards/Dashboards"
import CustomStates from "./Settings/CustomStates/CustomStates"


const views: Common.Views = [
  {
    element: <Home />,
    path: "/",
    title: "Home",
    icon: DashboardIcon,
    sortOrder: 1,
    showInSidebar: false
  },
  {
    element: <Dashboards />,
    path: "/dashboard",
    title: "Dashboard",
    icon: DashboardIcon,
    showInSidebar: true,
    sortOrder: 1,
    children: [
      {
        element: <Home />,
        path: "/dashboard/home",
        title: "Home",
        parentPath: "/dashboard",
        showInSidebar: true,
        section: "Dashboards"
      },
      {
        element: <DashboardsSales />,
        path: "/dashboard/sales",
        title: "Sales",
        parentPath: "/dashboard",
        showInSidebar: true
      }
    ]
  },
  {
    element: <Error404Page />,
    path: "*"
  },
  {
    element: <Discounts />,
    path: "/discounts",
    title: "Discounts",
    icon: DiscountIcon,
    showInSidebar: true,
    sortOrder: 2,
    children: [
      {
        element: <ShowBundleGroups />,
        path: "/discounts/bundle-groups/",
        title: "Bundle groups",
        parentPath: "/discounts",
        showInSidebar: true,
        section: "Bundles"
      },
      {
        element: <UpdateBundleGroup />,
        path: "/discounts/bundle-groups/:id",
        title: "Edit group",
        parentPath: "/discounts",
        showInSidebar: false,
        section: "Bundles"
      },
      {
        element: <ShowDiscountCodes />,
        path: "/discounts/discount-codes",
        title: "Discount codes",
        parentPath: "/discounts",
        showInSidebar: true,
        section: "Discount code"
      },
      {
        element: <ShowCodeGenerationJobs />,
        path: "/discounts/code-generation-jobs/",
        title: "Code generation jobs",
        parentPath: "/discounts",
        showInSidebar: true
      },
      {
        element: <ShowDiscountRules />,
        path: "/discounts/discount-rules",
        title: "Discount rules",
        parentPath: "/discounts",
        showInSidebar: true,
        section: "Discount rule"
      },
      {
        element: <UpdateDiscountCodeRule />,
        path: "/discounts/discount-code-rules/:id",
        title: "Edit template",
        parentPath: "/discounts"
      },
      {
        element: <UpdateCartRule />,
        path: "/discounts/cart-rules/:id",
        title: "Update cart rule",
        parentPath: "/discounts"
      },
      {
        element: <UpdateExternalRule />,
        path: "/discounts/external-rules/:id",
        title: "Update external rule",
        parentPath: "/discounts"
      }
    ]
  },
  {
    element: <Orders />,
    path: "/orders",
    title: "Orders",
    icon: OrderIcon,
    showInSidebar: true,
    sortOrder: 4,
    children: [
      {
        element: <ShowOrders />,
        path: "/orders/",
        title: "Orders",
        parentPath: "/orders",
        showInSidebar: true,
        section: "Order"
      },
      {
        element: <ViewOrder />,
        path: "/orders/:id",
        title: "Order",
        parentPath: "/orders"
      }
    ]
  },
  {
    element: <Stores />,
    path: "/stores",
    title: "Stores",
    icon: StoreIcon,
    showInSidebar: true,
    sortOrder: 6,
    children: [
      {
        element: <StoreGroups />,
        path: "/stores/store-groups",
        title: "Store groups",
        showInSidebar: true,
        icon: StoreIcon,
        parentPath: "/stores",
        section: "Store group"
      },
      {
        element: <Inventories />,
        path: "/stores/inventories",
        title: "Inventories",
        showInSidebar: true,
        icon: InventoryIcon,
        parentPath: "/stores",
        section: "Inventory"
      },
      {
        element: <Taxes />,
        path: "/stores/taxes",
        title: "Tax groups",
        showInSidebar: true,
        icon: TaxIcon,
        section: "Tax groups"
      }
    ]
  },
  {
    element: <Products />,
    path: "/products",
    title: "Products",
    icon: ProductIcon,
    showInSidebar: true,
    sortOrder: 5,
    children: [
      {
        element: <ShowProducts />,
        path: "/products/",
        title: "Products",
        icon: ProductIcon
      },
      {
        element: <ViewProduct />,
        path: "/products/:id",
        title: "Product",
        parentPath: "/products"
      }
    ]
  },
  {
    element: <Settings />,
    path: "/settings",
    title: "Settings",
    icon: SettingsIcon,
    showInSidebar: true,
    sortOrder: 7,
    children: [
      {
        element: <Providers />,
        path: "/settings/providers",
        title: "Providers",
        showInSidebar: true,
        parentPath: "/settings",
        section: "Connections",
        children: [
          {
            element: <ShowProviders />,
            path: "/settings/providers",
            title: "Show Providers",
            parentPath: "/settings"
          },
          {
            element: <AdyenConfiguration />,
            path: "/settings/providers/adyen",
            title: "Adyen",
            parentPath: "/settings/providers"
          },
          {
            element: <KlarnaConfig />,
            path: "/settings/providers/klarna",
            title: "Klarna",
            parentPath: "settings/providers"
          },
          {
            element: <SveaConfiguration />,
            path: "/settings/providers/svea",
            title: "Svea",
            parentPath: "/settings/providers"
          },
          {
            element: <IngridConfiguration />,
            path: "/settings/providers/ingrid",
            title: "Ingrid",
            parentPath: "/settings/providers"
          },
          {
            element: <Retain24Config />,
            path: "/settings/providers/retain24",
            title: "Retain24",
            parentPath: "/settings/providers"
          },
          {
            element: <NShiftConfiguration />,
            path: "/settings/providers/Nshift",
            title: "nShift",
            parentPath: "/settings/providers"
          },
          {
            element: <VoyadoConfiguration />,
            path: "/settings/providers/voyado",
            title: "Voyado",
            parentPath: "/settings/providers"
          },
          {
            element: <WalleyConfiguration />,
            path: "/settings/providers/walley",
            title: "Walley",
            parentPath: "/settings/providers"
          },
          {
            element: <BonusConfiguration />,
            path: "/settings/providers/bonus",
            title: "Bonus providers",
            parentPath: "/settings/providers"
          },
          {
            element: <QliroOneConfiguration />,
            path: "/settings/providers/qliro",
            title: "Qliro One",
            parentPath: "/settings/providers"
          },
          {
            element: <AvardaConfiguration />,
            path: "/settings/providers/avarda",
            title: "Avarda",
            parentPath: "/settings/providers"
          }
        ]
      }
    ]
  },
  {
    element: <Info />,
    icon: InfoIcon,
    title: "Info",
    path: "/info",
    showInSidebar: true,
    sortOrder: 8,
    children: [
      {
        element: <ProductBoard />,
        showInSidebar: true,
        path: "/info/product-board",
        title: "Product board",
        parentPath: "/info",
        section: "Product updates"
      },
      {
        showInSidebar: true,
        path: "https://docs.brinkcommerce.com/",
        title: "Docs",
        parentPath: "/info",
        section: "External links"
      }
    ]
  },
  {
    element: <User />,
    path: "/user"
  }
]

const protectedSuperUserViews = [
  {
    element: <ExternalEvents />,
    path: "/settings/external-events",
    showInSidebar: true,
    parentPath: "/settings",
    title: "External events"
  },
  {
    element: <CreateApiDestination />,
    path: "/settings/external-events/create-api-destination",
    showInSidebar: false,
    parentPath: "/settings/external-events",
    title: "Create API destination"
  },
  {
    element: <UpdateApiDestination />,
    path: "/settings/external-events/update-api-destination/:name",
    showInSidebar: false,
    parentPath: "/settings/external-events",
    title: "Update API destination"
  },
  {
    element: <CustomReasonCodes />,
    path: "/settings/order-definitions",
    showInSidebar: true,
    parentPath: "/settings",
    title: "Order definitions",
    section: "Order settings"
  },
  {
    element: <CustomStates />,
    path: "/settings/custom-states",
    showInSidebar: true,
    parentPath: "/settings",
    title: "Custom states",
  },
  {
    element: <UsagePlan />,
    path: "/settings/usage-plan",
    showInSidebar: true,
    parentPath: "/settings",
    title: "Usage plan",
    section: "Brink configurations"
  },
  {
    element: <Credentials />,
    path: "/settings/credentials",
    showInSidebar: true,
    parentPath: "/settings",
    title: "Credentials"
  },
  {
    element: <CreateDiscountRule />,
    path: "/discounts/create-discount-rule",
    title: "Create discount rule",
    parentPath: "/discounts",
    showInSidebar: true
  },
  {
    element: <CreateBundleGroup />,
    path: "/discounts/create-bundle-group/",
    title: "Create bundle group",
    parentPath: "/discounts",
    section: "Bundles"
  },
  {
    element: <CreateOrder />,
    path: "/orders/create-order",
    title: "Create order",
    parentPath: "/orders",
    showInSidebar: true,
    section: "Create order"
  },
  {
    element: <CloneOrder />,
    path: "/orders/create-order-from-existing-order/:id?",
    title: "Create order",
    parentPath: "/orders"
  },
  {
    element: <ManageOrder />,
    path: "/orders/manage-order/:id?",
    title: "Manage order",
    parentPath: "/orders"
  }
]

const protectedUserAdminViews = [
  {
    element: <ApiClients />,
    path: "/settings/api-configs",
    showInSidebar: true,
    parentPath: "/settings",
    title: "API clients"
  },
  {
    element: <ShowUsers />,
    path: "/settings/manage-users",
    showInSidebar: true,
    parentPath: "/settings",
    title: "Manage users",
    section: "Users"
  }
]

const protectedCampaignViews: Common.Views = [
  {
    element: <Campaigns />,
    path: "/campaigns",
    title: "Campaigns",
    showInSidebar: true,
    icon: CampaignIcon,
    sortOrder: 3,
    children: [
      {
        element: <ShowCampaigns />,
        path: "/campaigns/",
        title: "Show campaigns",
        parentPath: "/campaigns",
        showInSidebar: true,
        section: "Campaigns"
      },
      {
        element: <UpdateCampaignGroup />,
        path: "/campaigns/campaign-group/:id",
        title: "Campaigns",
        parentPath: "/campaigns"
      },
      {
        element: <DuplicateCampaign />,
        path: "/campaigns/duplicate-campaign/:id",
        title: "Duplicate campaign",
        parentPath: "/campaigns"
      },
      {
        element: <UpdateCampaign />,
        path: "/campaigns/campaign/:id",
        title: "Campaigns",
        parentPath: "/campaigns"
      },
      {
        element: <CreateCampaign />,
        path: "/campaigns/create-campaign",
        title: "Create campaign",
        parentPath: "/campaigns",
        showInSidebar: true
      }
    ]
  }
]

export { views, protectedSuperUserViews, protectedUserAdminViews, protectedCampaignViews }
