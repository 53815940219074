import React from "react"
import { Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { CapabilityProvider, InputUpdateStoreMarket } from "@lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import GET_VOYADO_CONFIGS from "../../../graphql/queries/config/Providers/Voyado/GetVoyadoConfigs"
import SingleSelect from "../../Ui/Form/SingleSelect"

const InputSection = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

type Props = {
  defaultValue: string | undefined
  control: Control
  errors: FieldErrors
  setValue: UseFormSetValue<InputUpdateStoreMarket>
}

export const VoyadoSelect = ({ defaultValue, control, errors, setValue }: Props) => {
  const { data: voyadoData } = useQuery(GET_VOYADO_CONFIGS)
  const voyadoConfigOptions =
    voyadoData?.getVoyadoConfigs.length > 0
      ? voyadoData?.getVoyadoConfigs?.map((config: CapabilityProvider) => ({
        label: config.id,
        value: config.id
      }))
      : []
  const options = [{ label: "Select voyado config...", value: undefined }, ...voyadoConfigOptions]
  const updateVoyadoConfig = (voyadoConfig: Common.Option) => {
    setValue("cartCapabilities.voyado", { id: voyadoConfig?.value })
  }

  if (voyadoConfigOptions.length === 0) return null

  return (
    <InputSection>
      <Label>Voyado</Label>
      <Controller
        name="cartCapabilities.voyado"
        render={() => (
          <SingleSelect
            defaultValue={defaultValue ? { label: defaultValue, value: defaultValue } : options[0]}
            options={options}
            setValue={updateVoyadoConfig}
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name="cartCapabilities.voyado" />
    </InputSection>
  )
}

export default VoyadoSelect
