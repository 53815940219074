import styled from "styled-components"

type StyledProps = {
  $type?: string
}

export const Container = styled.div<StyledProps>`
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  display: flex;
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  border-radius: 0.5rem;
  line-height: 2.2rem;

  border-left: 0.3rem solid
    ${(p) =>
      p.$type === "important"
        ? "rgb(212, 31, 28)"
        : p.$type === "reminder"
        ? p.theme.colors.yellow
        : p.$type === "info"
        ? "#0066CC"
        : p.theme.colors.turquoiseDark};
  background: ${(p) =>
    p.$type === "important"
      ? "rgba(212, 31, 28, 0.07)"
      : p.$type === "reminder"
      ? p.theme.colors.yellowLighter
      : p.$type === "info"
      ? "#e6f0fa"
      : p.theme.colors.turquoiseLighter};

  h5 {
    color: ${(p) =>
      p.$type === "important"
        ? "rgb(212, 31, 28)"
        : p.$type === "reminder"
        ? p.theme.colors.yellowDark
        : p.$type === "info"
        ? "#0066CC"
        : p.theme.colors.turquoiseDarker};
    font-size: 1.8rem;
    margin: 1.5rem 0 0;
    font-weight: 400;
    text-transform: uppercase;
  }

  p {
    word-break: break-word;
  }

  a {
    text-decoration: underline;
  }

  svg {
    align-self: flex-start;
    min-width: 2rem;
    max-width: 2rem;
    margin: 1.5rem 1rem 0 0;
    fill: ${(p) =>
      p.$type === "important"
        ? "rgb(212, 31, 28)"
        : p.$type === "reminder"
          ? p.theme.colors.yellowDark
          : p.$type === "info"
            ? "#0066CC"
            : p.theme.colors.turquoiseDarker};
  }
`
