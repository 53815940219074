import React, { useEffect } from "react"
import { Controller } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import { SidebarDivider } from "../../Dashboard/Shared.styled"
import Checkbox from "../../Ui/Form/Checkbox"
import { TooltipIcon } from "../../Ui/TooltipIcon"
import styled from "styled-components"
import SingleSelect from "../../Ui/Form/SingleSelect"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { InputWalleyCheckoutConfig, WalleyCheckoutConfig } from "@lib/types/generated/graphql-types"
import { Common } from "@lib/types"

const BaseUrlSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

const CheckboxWrapper = styled.div`
  display: flex;
  padding-bottom: 1rem;

  div {
    padding-top: 0;
  }
`

interface ExtendedInputWalleyCheckoutConfig extends InputWalleyCheckoutConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputWalleyCheckoutConfig>
  errors: FieldErrors
  setValue: UseFormSetValue<ExtendedInputWalleyCheckoutConfig>
  showId?: boolean
  defaultValues?: WalleyCheckoutConfig
}

const WalleyConfigInputs = ({ control, errors, setValue, showId, defaultValues }: Props) => {
  const updateEnv = (env: Common.Option) => {
    if (env.value === "test") {
      setValue("baseUrl", "https://api.uat.walleydev.com")
      setValue("scope", "705798e0-8cef-427c-ae00-6023deba29af/.default")
    } else {
      setValue("baseUrl", "https://api.walleypay.com")
      setValue("scope", "a3f3019f-2be9-41cc-a254-7bb347238e89/.default")
    }
  }

  useEffect(() => {
    if (defaultValues?.scope) {
      setValue("scope", defaultValues?.scope)
    }
  }, [defaultValues])

  return (
    <>
      {showId && (
        <Controller
          name="id"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="Identifier *"
              description="For internal use in Brink"
              placeholder="Aa - Zz, 0 - 9, -_."
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field",
            pattern: {
              value: /^[A-Za-z0-9_.-]+$/,
              message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
            }
          }}
        />
      )}
      <SidebarDivider />
      <CheckboxWrapper>
        <Checkbox
          name="deliveryModuleEnabled"
          label="Enable Walley delivery module"
          control={control}
          defaultValue={defaultValues?.deliveryModuleEnabled ?? false}
        />
        <TooltipIcon
          content={
            "Before enabling Walley delivery module, ensure that your Delivery module is properly configured in the Walley and that your shipping selector (ex. Nshift) is connected. See section `Delivery module` in Walley Docs or reach out to your technical support at Walley."
          }
        />
      </CheckboxWrapper>
      <Controller
        name="storeId"
        render={({ field }) => (
          <Input {...field} type="text" label="Walley store ID *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.storeId ?? ""}
      />

      <Controller
        name="clientId"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Walley client ID *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />

      <Controller
        name="secret"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Walley secret *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />

      <BaseUrlSelect
        options={[
          {
            value: "test",
            label: "Test | https://api.uat.walleydev.com"
          },
          {
            value: "production",
            label: "Production | https://api.walleypay.com"
          }
        ]}
        setValue={updateEnv}
        defaultValue={
          defaultValues?.baseUrl === "https://api.walleypay.com"
            ? {
                value: "production",
                label: "Production | https://api.walleypay.com"
              }
            : { label: "Test | https://api.uat.walleydev.com", value: "test" }
        }
        label="Environment *"
      />

      <Controller
        name="profileName"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Profile name" />
        )}
        control={control}
        defaultValue={defaultValues?.profileName ?? ""}
      />
    </>
  )
}

export default WalleyConfigInputs
