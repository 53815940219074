import styled from "styled-components"

export const ProviderContainer = styled.div`
display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 1rem 0;
`

export const ButtonWrapper = styled.div`
position: absolute;
  right: 2.7rem;
  top: 3rem;
  margin-bottom: 1rem;
`
