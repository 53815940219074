import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
`

export const Password = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const AccessInfo = styled.div`
  margin-top: 2rem;
`
