import styled from "styled-components"
import { Item } from "../../../../components/Ui/List/List"

export const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: grid;
    gap: 3rem;
  }

  ${`@media (min-width: ${1200 + 1}px)`} {
    grid-template-columns: 1fr 1fr;
  }
`

export const OrderValue = styled(Item)`
  margin-top: 0;
`

export const Total = styled(Item)`
  font-size: 1.6rem;
`

export const StatusItem = styled(Item)`
  height: fit-content;
`

export const StateStatus = styled.div`
  margin-right: 1rem;
  margin-bottom: 0.5rem;

  span {
    font-size: 1.1rem !important;
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
