import React from "react"
import {
  Header,
  InputWrapper,
  Label,
  SelectContainer,
  SelectWrapper,
  Subtitle
} from "./Shared.styled"
import Input from "../../Ui/Form/Input"
import { RadioButton } from "../../Ui/Form/RadioButton"
import { CustomOperation, CustomState, CustomStateType } from "lib/types/generated/graphql-types"
import { Control, Controller, UseFormRegister } from "react-hook-form"
import Select from "react-select"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { CustomStateInput, StateInput } from "lib/types/customState"

type Props = {
  register: UseFormRegister<CustomStateInput> | UseFormRegister<StateInput>
  errors: FieldErrors<CustomStateInput> | FieldErrors<StateInput>
  // eslint-disable-next-line
  control: Control<any>
  setBlockingType: () => void
  setInformativeType: () => void
  selectedType: CustomStateType
  defaultValue?: CustomState
  customStates?: CustomState[]
}

const CustomStateInputs = ({
  register,
  errors,
  control,
  setInformativeType,
  setBlockingType,
  selectedType,
  defaultValue,
  customStates
}: Props) => {
  const getOperationLabel = (operation: CustomOperation) => {
    switch (operation) {
      case CustomOperation.Release:
        return "Release"
      case CustomOperation.Refund:
        return "Refund"
      case CustomOperation.ModifyOrderLines:
        return "Modification"
      case CustomOperation.Delivery:
        return "Delivery"
      case CustomOperation.Compensation:
        return "Compensation"
      case CustomOperation.Cancellation:
        return "Cancellation"
    }
  }

  const operationOptions = [
    CustomOperation.Cancellation,
    CustomOperation.Delivery,
    CustomOperation.Compensation,
    CustomOperation.Refund,
    CustomOperation.Release,
    CustomOperation.ModifyOrderLines
  ].map((operation) => ({ label: getOperationLabel(operation), value: operation }))

  return (
    <>
      {" "}
      <InputWrapper>
        <Header>Information</Header>
        <Label>State name</Label>
        <Input
          name={"input.description"}
          placeholder={"Name"}
          register={register}
          removePaddingBottom={true}
          defaultValue={defaultValue?.description ?? ""}
          validation={{
            required: "This is a required field"
          }}
          hooksErrorMessage={true}
          errors={errors}
        />
        {!defaultValue && (
          <>
            <Label>Unique ID (skip to generate random)</Label>
            <Input
              name={"stateId"}
              placeholder={"Id"}
              register={register}
              validation={{
                validate: (value) =>
                  !customStates?.find((customState) => customState.id === value) ||
                  "State already exists"
              }}
              hooksErrorMessage={true}
              errors={errors}
              removePaddingBottom={true}
            />
          </>
        )}
      </InputWrapper>
      <InputWrapper>
        <Header>Settings</Header>
        <Label>Type</Label>
        <RadioButton
          id={"blocking"}
          name={"blocking"}
          label={
            <div>
              <b>Blocking</b>
              <Subtitle>Prevents further order operations until resolved.</Subtitle>
            </div>
          }
          onChange={() => setBlockingType()}
          checked={selectedType === CustomStateType.Blocking}
        />
        {selectedType === CustomStateType.Blocking && (
          <SelectWrapper>
            <div>Operations to block</div>
            <Controller
              name="input.operations"
              control={control}
              render={({ field }) => (
                <SelectContainer>
                  <Select
                    name={"input.operations"}
                    isMulti={true}
                    placeholder={"Select blocking operations"}
                    options={operationOptions}
                    onChange={(options) => field.onChange(options?.map((option) => option.value))}
                    defaultValue={defaultValue?.operations?.map((operation) => ({
                      label: getOperationLabel(operation as CustomOperation),
                      value: operation
                    }))}
                  />
                </SelectContainer>
              )}
            />
          </SelectWrapper>
        )}
        <RadioButton
          id={"informative"}
          name={"informative"}
          label={
            <div>
              <b>Informative</b>
              <Subtitle>Displays updates without restricting actions.</Subtitle>
            </div>
          }
          onChange={() => setInformativeType()}
          checked={selectedType === CustomStateType.Informative}
        />
      </InputWrapper>
    </>
  )
}

export default CustomStateInputs
