import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
  }
`

export const Label = styled.div`
  ${(p) => p.theme.bold}
  font-size: 2rem;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
`

export const FilterGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.blueDark};
  margin: 0.5rem 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 1px;
    height: 2rem;
    margin: 0 1rem;
  }
`

export const Show = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  p {
    ${(p) => p.theme.bold}
    margin: 0;
    color: ${(p) => p.theme.colors.black};
    font-size: 1.4rem;
  }
`

type StyledProps = {
  $active?: boolean
}

export const NumberOfHits = styled.div<StyledProps>`
  min-width: 3rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  padding: 0 1rem;
  background: ${(p) => (p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.white)};
  color: ${(p) => (p.$active ? p.theme.colors.white : p.theme.colors.black)};
  font-weight: ${(p) => (p.$active ? p.theme.bold : "normal")};
  transition: background-color 0.2s ease;
  font-size: 1.4rem;

  &:hover {
    cursor: pointer;
    background: ${(p) => (p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.grey)};
  }
`
