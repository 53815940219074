import React from "react"
import { InputReason, Order, OrderDelivery } from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { handleErrorMessages } from "helpers/errors"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { getDeliveryInput } from "../helpers"
import Reason from "../../Reason/Reason"

type Props = {
  delivery: OrderDelivery
  refetch: () => void
  order: Order
  orderLoading: boolean
}

const DeliveryReason = ({ delivery, refetch, orderLoading }: Props) => {
  const [updateDelivery, { loading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const addReason = (reason: InputReason) => {
    updateDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...getDeliveryInput(delivery),
          ...(reason.cause && reason.code && { reason: reason })
        }
      }
    })
  }

  const removeReason = () => {
    updateDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...getDeliveryInput(delivery),
          reason: null
        }
      }
    })
  }

  return (
    <Reason
      orderReason={delivery.reason}
      addReason={addReason}
      loading={loading}
      removeReason={removeReason}
      orderLoading={orderLoading}
    />
  )
}

export default DeliveryReason
