import styled from "styled-components"
import TableHeader from "components/Ui/Table/TableHeader"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
`

export const Container = styled.div`
  width: 100%;
`

export const Id = styled.p`
  font-size: 1rem;
  margin: 0;
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const Description = styled.div`
  padding-right: 3rem;
`

export const StoreMarkets = styled.div`
  flex: initial !important;
  ${(p) => p.theme.bold};
  min-width: 12rem;
  text-align: center;
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`
