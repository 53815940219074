import React from "react"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { OrderRelease } from "@lib/types/generated/graphql-types"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import { useQuery } from "@apollo/client"
import Status from "../../../Ui/Status"
import { Label } from "../../Updates/Shared.styled"
import { Container, ProviderStatus } from "./Shared.styled"
import GET_RELEASE from "../../../../graphql/queries/order/GetRelease"

type Props = {
  pendingRelease: OrderRelease
  orderRefetch: () => void
  orderLoading: boolean
}

const PendingRelease = ({ pendingRelease, orderRefetch, orderLoading }: Props) => {
  const { data, refetch, loading } = useQuery(GET_RELEASE, {
    variables: { releaseId: pendingRelease?.id },
    fetchPolicy: "network-only"
  })

  const release = data?.Order?.release as OrderRelease
  if (!release) return null

  return (
    <Container>
      <h4>Pending release #{pendingRelease.id}</h4>
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>{" "}
          <Status status={release.paymentProvider.status?.current ?? ""} />
        </div>
        {release?.shippingProvider?.status?.current && (
          <div>
            <Label>Shipping status:</Label>
            <Status status={release?.shippingProvider?.status?.current ?? ""} />
          </div>
        )}
        {release?.bonusProvider && (
          <div>
            <Label>Bonus status:</Label>
            <Status status={release?.bonusProvider?.status?.current ?? ""} />
          </div>
        )}
        {release?.giftCardProvider && (
          <div>
            <Label>Gift card status:</Label>
            <Status status={release?.giftCardProvider?.status?.current ?? ""} />
          </div>
        )}
      </ProviderStatus>
      <PrimaryButton
        handleClick={() => {
          refetch()
          orderRefetch()
        }}
        loading={loading || orderLoading}
      >
        <RefreshIcon />
        Refresh status
      </PrimaryButton>
    </Container>
  )
}

export default PendingRelease
