import React from "react"
import { CustomReasonCode, Maybe, Reason as OrderReason } from "@lib/types/generated/graphql-types"
import { ReactComponent as CommentIcon } from "images/icons/comment-lines.svg"
import { Comment, Info } from "./Reason.styled"

type Props = {
  getCustomCode: (code: Maybe<string> | undefined) => CustomReasonCode
  reason: Maybe<OrderReason> | undefined
}

const Reason = ({ getCustomCode, reason }: Props) => {
  const customCode = getCustomCode(reason?.code)

  if (!reason)
    return (
      <Info>
        Reason:
        <span>
          <b>- No reason added</b>
        </span>
      </Info>
    )

  return (
    <Info>
      Reason:
      {reason?.code && (
        <span>
          <b>{customCode ? `${customCode.code} - ${customCode.label}` : reason.code}</b>
        </span>
      )}
      {reason?.cause && (
        <Comment>
          <CommentIcon />
          {reason?.cause}
        </Comment>
      )}
    </Info>
  )
}

export default Reason
