import styled from "styled-components"

type StyledProps = {
  $loading?: boolean
}

export const CartItemContainer = styled.div`
  display: flex;
  padding: 0.7rem;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    flex-direction: column;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    flex-direction: row;
  }
`

export const CartItemWrapper = styled.div<StyledProps>`
  filter: ${(p) => (p.$loading ? "blur(0.3rem)" : "none")};
  -webkit-filter: ${(p) => (p.$loading ? "blur(0.3rem)" : "none")};
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  margin-bottom: 1rem;

  &:last-of-type {
    border-bottom: none;
  }
`

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;

  div {
    padding: 0;
  }

  button {
    height: 4.4rem;
  }
`

export const Amount = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 1rem;
    height: 1.4rem;
  }
`

export const DeleteButton = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 1.5rem;
    fill: ${(p) => p.theme.colors.greyDarker};
  }

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.error.background};
    border: 0.1rem solid ${(p) => p.theme.colors.white};

    svg {
      fill: ${(p) => p.theme.colors.white};
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
`

export const ItemImage = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;

  img {
    position: relative !important;
    object-fit: contain;
    background: ${(p) => p.theme.colors.background};
  }
`

export const ItemDetails = styled.div`
  padding: 1rem 1.5rem;
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1.5rem 2rem;
  }
`

export const ItemName = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  ${(p) => p.theme.bold};
`

export const Prices = styled.div``

export const Percentage = styled.div`
  background: ${(p) => p.theme.colors.turquoiseDark};
  color: ${(p) => p.theme.colors.white};
  padding: 0 0.3rem;
  border-radius: 0.6rem;
  width: 5rem;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  position: absolute;
  left: -1rem;
  top: 1rem;
`

export const ItemPrice = styled.div`
  font-size: 1.1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  span {
    text-decoration: line-through;
    color: #666;
    font-weight: 400;
    margin-left: 1rem;
  }
`

export const ActionWrapper = styled.div<StyledProps>`
  filter: ${(p) => (p.$loading ? "blur(0.3rem)" : "none")};
  -webkit-filter: ${(p) => (p.$loading ? "blur(0.3rem)" : "none")};
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  padding: 1rem;
  width: 30rem;
  font-size: 1.1rem;

  span {
    display: flex;
    justify-content: space-between;

    label {
      font-size: 1.1rem !important;
      font-weight: normal;
    }

    div {
      padding: 0;
    }
  }

  button {
    height: 2.5rem;
    padding: 0.2rem 1rem;
  }

  hr {
    margin: 1rem 0;
  }
`

export const QtySelector = styled.div`
  display: flex;
  align-items: center;

  span {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    &:first-of-type,
    &:last-of-type {
      width: 2.5rem;
      border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
      font-size: 1.6rem;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 0.3rem;
      padding: 0;

      ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        &:hover {
          background: ${(p) => p.theme.colors.greyLight};
          border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
        }
      }
    }
  }
`
