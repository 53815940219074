import gql from "graphql-tag"

const GET_CURRENCY_RATES = gql`
  query getCurrencyRates($baseCurrencyCode: String!, $filterCurrencies: [String!]!) {
    getCurrencyRates(baseCurrencyCode: $baseCurrencyCode, filterCurrencies: $filterCurrencies) {
      baseCurrencyCode
      rates {
        currencyCode
        rate
      }
    }
  }
`

export default GET_CURRENCY_RATES
