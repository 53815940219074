import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import { isSuperUser } from "../../helpers/user"
import { useAppDispatch } from "lib/store"
import { Common, Discount } from "lib/types"
import { showEditSidebar, toggleEditSidebar } from "lib/store/services/editSidebar/slice"
import { Gift, InputOutcomeType } from "lib/types/generated/graphql-types"
import {
  FrontendCondition,
  FrontendLogicItem,
  FrontendOutcome,
  LogicState
} from "@lib/types/discount"
import DineroFactory from "dinero.js"
import { getLogicTypeDisplayName } from "helpers/getLogicTypeDisplayName"
import { useLocalStorage } from "hooks/useLocalStorage"

import {
  duplicateLogicRule,
  generateNewId,
  isCondition,
  isOutcome,
  pasteCondition,
  pasteOutcome
} from "helpers/duplicateDiscount"

import PageSectionHeader from "components/Ui/Page/PageSectionHeader"
import EditCondition from "./Logic/Condition"
import EditOutcome from "./Logic/Outcome"
import Money from "components/Money"
import Popup from "components/Ui/Popup"
import { StoreGroupDataItems } from "./Logic/Conditions/StoreGroupDataItems"
import { Tooltip } from "components/Ui/Tooltip"
import { CopyNotice } from "./Ui/CopyNotice"

import {
  ActionButton,
  Actions,
  AddButton,
  AddButtonWrapper,
  AddListButton,
  Container,
  Content,
  DataItemCondition,
  DataItemOutcome,
  DataItems,
  DisabledAddButton,
  Divider,
  EmptyContainer,
  Flex,
  Header,
  Item,
  ItemHeader,
  Ladder,
  LadderContainer,
  List,
  Logic,
  LogicRule,
  LogicTitle,
  Operator,
  Wrapper
} from "./LogicRules.styled"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as EditIcon } from "images/icons/gear.svg"
import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import { ReactComponent as PasteIcon } from "images/icons/paste.svg"
import { Percentage } from "helpers/Percentage"

const DEFAULT_LOGIC_ITEM = {
  type: "",
  title: ""
}

type EditConditionItem = {
  listId: string
  item?: FrontendCondition
}

type EditOutcomeItem = {
  listId: string
  item?: FrontendOutcome
}

type Props = {
  state: LogicState
  // eslint-disable-next-line
  setState: any
}

const MAXIMUM_OUTCOMES = 3
const MAXIMUM_CONDITIONS = 3

const LogicRules = ({ state, setState }: Props) => {
  const dispatch = useAppDispatch()
  const [editCondition, setEditCondition] = useState<EditConditionItem>()
  const [editOutcome, setEditOutcome] = useState<EditOutcomeItem>()
  const [logicRuleToDelete, setLogicRuleToDelete] = useState<string>("")
  const [copyLogic, setCopyLogic] = useLocalStorage<FrontendLogicItem>(
    "copyLogic",
    DEFAULT_LOGIC_ITEM
  )

  const addLogicRule = () => {
    setState((prev: LogicState) => ({
      ...prev,
      [uuid()]: {
        conditions: [],
        outcomes: []
      }
    }))
  }

  const removeLogicRule = (listId: string) => {
    const listClone = state
    delete listClone[listId]

    setState(() => ({
      ...listClone
    }))
    setLogicRuleToDelete("")
  }

  const removeOutcome = (index: number, listId: string) => {
    const listClone = [...state[listId].outcomes]
    listClone.splice(index === 0 ? index : index - 1, 2)

    setState((prev: LogicState) => ({
      ...prev,
      [listId]: { ...prev[listId], outcomes: listClone }
    }))
  }

  const removeCondition = (index: number, listId: string) => {
    const listClone = [...state[listId].conditions]
    listClone.splice(index === 0 ? index : index - 1, 2)

    setState((prev: LogicState) => ({
      ...prev,
      [listId]: { ...prev[listId], conditions: listClone }
    }))
  }

  return (
    <Wrapper>
      {(isCondition(copyLogic) || isOutcome(copyLogic)) && (
        <CopyNotice copyLogic={copyLogic} setCopyLogic={setCopyLogic} />
      )}
      {editCondition && (
        <EditCondition
          listId={editCondition.listId}
          condition={editCondition.item}
          setState={setState}
        />
      )}
      {editOutcome && (
        <EditOutcome listId={editOutcome.listId} outcome={editOutcome.item} setState={setState} />
      )}

      <Header>
        <PageSectionHeader
          title="Logic rules"
          description={
            <>
              Add logic rules to control the behaviour of the discount rule.
              <br /> To add for example a ladder rule, you create multiple Logic rules.
            </>
          }
        />
        <AddListButton
          handleClick={() => {
            addLogicRule()
          }}
        >
          <PlusIcon />
          Add new logic rule
        </AddListButton>
      </Header>

      <Content>
        {Object.keys(state).map((listId, index) => {
          const list = state[listId] ?? {}
          const { conditions, outcomes } = list
          const conditionsCount = conditions?.filter(
            (c: FrontendCondition) => c.type === "condition"
          )?.length
          const outcomesCount = outcomes.filter(
            (o: FrontendOutcome) => o.type === "outcome"
          )?.length

          return (
            <Container key={listId}>
              {index > 0 && (
                <LadderContainer $index={index}>
                  <Ladder $index={index} />
                </LadderContainer>
              )}
              <LogicRule>
                <Flex>
                  <h2>Logic rule #{index + 1}</h2>
                  {isSuperUser() && (
                    <Actions>
                      <Tooltip id="copy-logic-rule" />
                      <ActionButton
                        data-tooltip-id="copy-logic-rule"
                        data-tooltip-content="Duplicate logic rule"
                        type="button"
                        onClick={() => setState({ ...state, ...duplicateLogicRule(list) })}
                      >
                        <CopyIcon />
                      </ActionButton>
                      <Tooltip id="remove-logic-rule" />
                      <ActionButton
                        data-tooltip-id="remove-logic-rule"
                        data-tooltip-content="Remove logic rule"
                        type="button"
                        onClick={() => setLogicRuleToDelete(listId)}
                      >
                        <DeleteIcon />
                      </ActionButton>
                      {logicRuleToDelete === listId && (
                        <Popup
                          title="Delete logic rule"
                          subtitle={`Are you sure you want to delete the following logic rule?`}
                          item={{ itemId: listId, itemName: `Logic rule #${index + 1}` }}
                          handleCloseClick={() => setLogicRuleToDelete("")}
                          handleOkClick={() => removeLogicRule(listId)}
                          type="delete"
                        />
                      )}
                    </Actions>
                  )}
                </Flex>
                <Logic>
                  <List>
                    <LogicTitle>
                      <span>Outcomes</span>
                      <span>
                        {outcomesCount}/{MAXIMUM_OUTCOMES}
                      </span>
                    </LogicTitle>
                    {outcomesCount ? (
                      <>
                        {outcomes.map((item: Discount.FrontendOutcome, index: number) => {
                          if (!item) return false
                          const { id, data, type, title } = item

                          return (
                            <React.Fragment key={id}>
                              {type === "outcome" ? (
                                <Item>
                                  <ItemHeader>
                                    {data ? (
                                      <h4>
                                        {getLogicTypeDisplayName(data.type)}
                                        {data.discountValue?.type && (
                                          <>
                                            {" - "}
                                            {getLogicTypeDisplayName(data.discountValue?.type)}
                                          </>
                                        )}
                                      </h4>
                                    ) : (
                                      title
                                    )}
                                    <Actions>
                                      <Tooltip id="copy-outcome" />
                                      <ActionButton
                                        data-tooltip-id="copy-outcome"
                                        data-tooltip-content="Copy outcome"
                                        type="button"
                                        onClick={() => {
                                          setCopyLogic(generateNewId(item))
                                        }}
                                      >
                                        <CopyIcon />
                                      </ActionButton>
                                      <Tooltip id="edit-outcome" />
                                      <ActionButton
                                        data-tooltip-id="edit-outcome"
                                        data-tooltip-content="Edit outcome"
                                        type="button"
                                        onClick={() => {
                                          setEditOutcome({
                                            listId,
                                            item
                                          })
                                          setEditCondition(undefined)
                                          dispatch(toggleEditSidebar())
                                        }}
                                      >
                                        <EditIcon />
                                      </ActionButton>
                                      {isSuperUser() && (
                                        <>
                                          <Tooltip id="remove-outcome" />
                                          <ActionButton
                                            data-tooltip-id="remove-outcome"
                                            data-tooltip-content="Delete outcome"
                                            type="button"
                                            onClick={() => removeOutcome(index, listId)}
                                          >
                                            <DeleteIcon />
                                          </ActionButton>
                                        </>
                                      )}
                                    </Actions>
                                  </ItemHeader>
                                  {data && (
                                    <DataItems>
                                      {data.type === InputOutcomeType.Shippingtags &&
                                        data.shippingTags?.map((tag: string) => (
                                          <DataItemOutcome key={tag}>{tag}</DataItemOutcome>
                                        ))}
                                      {data.type === InputOutcomeType.Gift &&
                                        data.gifts?.map((gift: Gift) => (
                                          <DataItemOutcome key={gift.productVariantId}>
                                            ID: {gift.productVariantId}, Qty: {gift.quantity}
                                          </DataItemOutcome>
                                        ))}
                                      {data.discountValue?.type === "FIXED" &&
                                        data.discountValue?.fixedDiscounts?.map((threshold) => (
                                          <DataItemOutcome key={threshold.currencyCode}>
                                            -
                                            <Money
                                              amount={parseInt(`${threshold.units}`)}
                                              currencyUnit={
                                                threshold.currencyCode as DineroFactory.Currency
                                              }
                                            />
                                          </DataItemOutcome>
                                        ))}
                                      {data.discountValue?.type === "RELATIVE" && (
                                        <DataItemOutcome>
                                          -
                                          {new Percentage(
                                            data.discountValue.percentage ?? 0,
                                            data.discountValue.percentageDecimals ?? 0
                                          ).formatPercentage()}
                                        </DataItemOutcome>
                                      )}
                                    </DataItems>
                                  )}
                                </Item>
                              ) : (
                                <Operator>
                                  {title}
                                  {index === outcomes.length - 1 && isSuperUser() && (
                                    <Actions>
                                      <ActionButton
                                        type="button"
                                        onClick={() => removeOutcome(index, listId)}
                                      >
                                        <DeleteIcon />
                                      </ActionButton>
                                    </Actions>
                                  )}
                                </Operator>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </>
                    ) : (
                      <EmptyContainer>
                        <p>No outcomes added</p>
                      </EmptyContainer>
                    )}
                    <AddButtonWrapper>
                      {outcomesCount < MAXIMUM_OUTCOMES ? (
                        <>
                          {isOutcome(copyLogic) && (
                            <>
                              <Tooltip id="paste-outcome" />
                              <AddButton
                                data-tooltip-id="paste-outcome"
                                data-tooltip-content="Paste outcome"
                                handleClick={() => {
                                  setState(pasteOutcome(listId, copyLogic, state))
                                }}
                              >
                                <PasteIcon />
                              </AddButton>
                            </>
                          )}
                          <Tooltip id="add-outcome" />
                          <AddButton
                            data-tooltip-id="add-outcome"
                            data-tooltip-content="Add new outcome"
                            handleClick={() => {
                              dispatch(showEditSidebar())
                              setEditCondition(undefined)
                              setEditOutcome({ listId })
                            }}
                          >
                            <PlusIcon />
                          </AddButton>
                        </>
                      ) : (
                        <>
                          {isOutcome(copyLogic) && (
                            <>
                              <Tooltip id="disabled-paste-outcome" />
                              <DisabledAddButton
                                disabled
                                data-tooltip-id="disabled-paste-outcome"
                                data-tooltip-content="Maximum number och outcomes added"
                              >
                                <PasteIcon />
                              </DisabledAddButton>
                            </>
                          )}
                          <Tooltip id="disabled-add-outcome" />
                          <DisabledAddButton
                            disabled
                            data-tooltip-id="disabled-add-outcome"
                            data-tooltip-content="Maximum number och outcomes added"
                          >
                            <PlusIcon />
                          </DisabledAddButton>
                        </>
                      )}
                    </AddButtonWrapper>
                  </List>
                  <Divider />
                  <List>
                    <LogicTitle>
                      <span>Conditions (Optional)</span>
                      <span>
                        {conditionsCount}/{MAXIMUM_CONDITIONS}
                      </span>
                    </LogicTitle>
                    {conditions?.length ? (
                      <>
                        {conditions.map((item: FrontendCondition, index: number) => {
                          if (!item) return false
                          const { id, data, type, title } = item

                          return (
                            <React.Fragment key={id}>
                              {type === "condition" ? (
                                <Item>
                                  <ItemHeader>
                                    {data ? <h4>{getLogicTypeDisplayName(data.type)}</h4> : title}{" "}
                                    <Actions>
                                      <Tooltip id="copy-condition" />
                                      <ActionButton
                                        data-tooltip-id="copy-condition"
                                        data-tooltip-content="Copy condition"
                                        type="button"
                                        onClick={() => {
                                          setCopyLogic(generateNewId(item))
                                        }}
                                      >
                                        <CopyIcon />
                                      </ActionButton>
                                      <Tooltip id="edit-condition" />
                                      <ActionButton
                                        data-tooltip-id="edit-condition"
                                        data-tooltip-content="Edit condition"
                                        type="button"
                                        onClick={() => {
                                          setEditCondition({
                                            listId,
                                            item
                                          })
                                          setEditOutcome(undefined)
                                          dispatch(toggleEditSidebar())
                                        }}
                                      >
                                        <EditIcon />
                                      </ActionButton>
                                      {isSuperUser() && (
                                        <>
                                          <Tooltip id="remove-condition" />
                                          <ActionButton
                                            data-tooltip-id="remove-condition"
                                            data-tooltip-content="Delete condition"
                                            type="button"
                                            onClick={() => removeCondition(index, listId)}
                                          >
                                            <DeleteIcon />
                                          </ActionButton>
                                        </>
                                      )}
                                    </Actions>
                                  </ItemHeader>
                                  {data && (
                                    <div>
                                      <DataItems>
                                        {data.thresholds?.map((threshold: Common.Money) => (
                                          <DataItemCondition key={uuid()}>
                                            <Money
                                              amount={parseInt(`${threshold.units}`)}
                                              currencyUnit={
                                                threshold.currencyCode as DineroFactory.Currency
                                              }
                                            />
                                          </DataItemCondition>
                                        ))}
                                        {data?.triggerQuantity ? (
                                          <DataItemCondition>
                                            Qty: {data?.triggerQuantity}
                                          </DataItemCondition>
                                        ) : null}
                                        {data.type === "STOREGROUP" && (
                                          <StoreGroupDataItems identifiers={data?.identifiers} />
                                        )}
                                        {data.type === "COUNTRYCODE" &&
                                          data.identifiers?.map((identifiers) => (
                                            <DataItemCondition key={uuid()}>
                                              {identifiers}
                                            </DataItemCondition>
                                          ))}
                                      </DataItems>
                                    </div>
                                  )}
                                </Item>
                              ) : (
                                <Operator>
                                  {title}
                                  {index === conditions.length - 1 && isSuperUser() && (
                                    <Actions>
                                      <ActionButton
                                        type="button"
                                        onClick={() => removeCondition(index, listId)}
                                      >
                                        <DeleteIcon />
                                      </ActionButton>
                                    </Actions>
                                  )}
                                </Operator>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </>
                    ) : (
                      <EmptyContainer>
                        <p>No conditions added</p>
                      </EmptyContainer>
                    )}
                    <AddButtonWrapper>
                      {conditionsCount < MAXIMUM_CONDITIONS ? (
                        <>
                          {isCondition(copyLogic) && (
                            <>
                              <Tooltip id="paste-condition" />
                              <AddButton
                                data-tooltip-id="paste-condition"
                                data-tooltip-content="Paste condition"
                                handleClick={() => {
                                  setState(pasteCondition(listId, copyLogic, state))
                                }}
                              >
                                <PasteIcon />
                              </AddButton>
                            </>
                          )}
                          <Tooltip id="add-condition" />
                          <AddButton
                            data-tooltip-id="add-condition"
                            data-tooltip-content="Add new condition"
                            handleClick={() => {
                              dispatch(showEditSidebar())
                              setEditOutcome(undefined)
                              setEditCondition({ listId })
                            }}
                          >
                            <PlusIcon />
                          </AddButton>
                        </>
                      ) : (
                        <>
                          {isCondition(copyLogic) && (
                            <>
                              <Tooltip id="disabled-paste-condition" />
                              <AddButton
                                disabled
                                data-tooltip-id="disabled-paste-condition"
                                data-tooltip-content="Maximum number och conditions added"
                              >
                                <PasteIcon />
                              </AddButton>
                            </>
                          )}
                          <Tooltip id="disabled-add-condition" />
                          <DisabledAddButton
                            disabled
                            data-tooltip-id="disabled-add-condition"
                            data-tooltip-content="Maximum number och conditions added"
                          >
                            <PlusIcon />
                          </DisabledAddButton>
                        </>
                      )}
                    </AddButtonWrapper>
                  </List>
                </Logic>
              </LogicRule>
            </Container>
          )
        })}
      </Content>
    </Wrapper>
  )
}

export default LogicRules
