import React, { Dispatch, SetStateAction, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import styled from "styled-components"
import { User } from "@lib/types/generated/graphql-types"

import GROUP_USERS from "graphql/queries/user/GroupUsers"

import { BrinkLoader } from "../Ui/BrinkLoader"
import { UserTable } from "./UserTable"
import PrimaryButton from "../Ui/Buttons/PrimaryButton"
import { UpdateUser } from "views/Users/UpdateUser"
import { isUserAdmin } from "helpers/user"

const ShowMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  button {
    padding: 1rem 3rem;
  }
`

type Props = {
  groupname: string
  setEditUser: Dispatch<SetStateAction<string>>
  editUser: string
}
export const GroupUsers = ({ groupname, setEditUser, editUser }: Props) => {
  const [users, setUsers] = useState<User[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)

  const [getGroupUser, { loading: fetchMoreLoading, error: fetchMoreError }] =
    useLazyQuery(GROUP_USERS)

  const { error, loading, refetch } = useQuery(GROUP_USERS, {
    variables: { limit: 10, groupname },
    onCompleted: (data) => {
      setUsers(data?.groupUsers?.users)
      setNextToken(data?.groupUsers?.nextToken)
    }
  })

  const fetchMoreUsers = () => {
    getGroupUser({
      variables: { limit: 10, token: nextToken, groupname }
    }).then((result) => {
      setUsers([...users, ...result.data.groupUsers.users])
      setNextToken(result?.data?.groupUsers?.nextToken)
    })
  }

  return (
    <>
      {editUser && <UpdateUser editUser={editUser} setEditUser={setEditUser} refetch={refetch} />}
      {loading && !nextToken ? (
        <BrinkLoader />
      ) : (
        <UserTable
          users={users}
          error={error || fetchMoreError}
          editId={editUser}
          setEditId={setEditUser}
        />
      )}
      {nextToken && (
        <ShowMoreWrapper>
          <PrimaryButton
            loading={fetchMoreLoading}
            handleClick={() => fetchMoreUsers()}
            overrideDisabled={isUserAdmin()}
          >
            Show more users
          </PrimaryButton>
        </ShowMoreWrapper>
      )}
    </>
  )
}
