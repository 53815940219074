import { OrderDelivery } from "@lib/types/generated/graphql-types"
import CopyButton from "../../../Ui/CopyButton"
import { Info } from "./Operations.styled"
import Money from "../../../Money"
import DineroFactory from "dinero.js"

type Props = {
  delivery: OrderDelivery | undefined
  status: string
  currencyCode: string
}

const DeliveryInfo = ({ delivery, status, currencyCode }: Props) => {

  if (status === "TRACK_SHIPMENT_SUCCESS") {
    const trackingUrl = delivery?.tracking?.url
    return (
      <Info>
        <div>{delivery?.tracking?.reference}</div>
        <div>{delivery?.shippingProvider?.providerName}</div>
        <div>{delivery?.tracking?.shippingCompany}</div>
        <div>{delivery?.tracking?.shippingMethod}</div>
        {trackingUrl && (
          <div>
            <a href={trackingUrl} target={"_blank"}>
              Tracking url
            </a>{" "}
            <CopyButton string={trackingUrl} />
          </div>
        )}
      </Info>
    )
  }

  if (status === "CAPTURE_PAYMENT_SUCCESS") {
    return (
      <Info>
        <b>
          <Money
            amount={delivery?.totals?.grandTotal ?? 0}
            currencyUnit={currencyCode as DineroFactory.Currency}
          />
        </b>
        <div>{delivery?.capturedPayment?.reference}</div>
        <div>{delivery?.paymentProvider?.providerName}</div>
      </Info>
    )
  }
  return null
}

export default DeliveryInfo
