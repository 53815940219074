import styled from "styled-components"
import TableHeader from "../../Ui/Table/TableHeader"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`

export const StyledTableHeader = styled(TableHeader)``

export const Url = styled.div`
  min-width: 35rem;
  padding-right: 2rem;
`
