import gql from "graphql-tag"

const UPDATE_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API = gql`
  mutation updateExternalEventsDestinationAPI($destName: String!, $options: InputUpdateExternalEventsDestinationAPI!) {
    updateExternalEventsDestinationAPI(destName: $destName, options: $options) {
      apiDestinationName
    }
  }
`

export default UPDATE_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API
