import React from "react"
import {
  EntryDate,
  Error,
  Event,
  Events,
  IconWrapper,
  Info,
  Message,
  SuccessIcon,
  Text,
  TimeLine,
  User,
  Wrapper
} from "./OrderHistoryLog.styled"
import {
  CustomState,
  Order,
  OrderCustomState,
  OrderHistoryLogEntry
} from "@lib/types/generated/graphql-types"
import dayjs from "dayjs"
import Icon from "./Icon"
import Reason from "./Reason"
import { ReactComponent as ErrorIcon } from "images/icons/circle-exclamation.svg"
import DeliveryInfo from "./Operations/DeliveryInfo"
import RefundInfo from "./Operations/RefundInfo"
import ModificationInfo from "./Operations/ModificationInfo"
import { ReactComponent as PlacedIcon } from "../../../images/icons/cart-shopping.svg"
import AddCustomStateToOrder from "./AddCustomStateToOrder"
import { v4 as uuidv4 } from "uuid"
import CustomStateInfo from "./Operations/CustomStateInfo"
import { getMessage } from "../helpers"
import { useSelector } from "react-redux"
import { orderLoading } from "lib/store/services/Order/orderSelector"
import { ApolloQueryResult } from "@apollo/client"
import { isSuperUser } from "helpers/user"
import AddComment from "./AddComment"
import CommentInfo from "./Operations/CommentInfo"

type Props = {
  orderHistory: OrderHistoryLogEntry[]
  order: Order
  refetch: () => Promise<ApolloQueryResult<Order>>
  customStates: CustomState[]
  getCustomState: (id: string) => CustomState
  addedCustomStates: OrderCustomState[]
  addCustomStateToHistory: (customStateId: string) => void
}

const OrderHistoryLog = ({
  orderHistory,
  order,
  refetch,
  customStates,
  getCustomState,
  addedCustomStates,
  addCustomStateToHistory
}: Props) => {
  const getDelivery = (id: string) => order.Deliveries.find((delivery) => delivery.id === id)
  const getRefund = (id: string) => order.Refunds.find((refund) => refund.id === id)
  const getModification = (id: string) =>
    order.Modifications.find((modification) => modification.id === id)
  const orderSelector = useSelector(orderLoading)

  const getEntryMessage = (entry: OrderHistoryLogEntry) => {
    if (entry.type === "ADD_CUSTOM_STATE" || entry.type === "REMOVE_CUSTOM_STATE") {
      return getCustomState(entry.operationId)?.description ?? entry.operationId
    }
    return getMessage(entry)
  }

  return (
    <Wrapper className={orderSelector.orderLoading ? "blur" : ""}>
      {customStates && customStates.length > 0 && isSuperUser() && (
        <AddCustomStateToOrder
          orderId={order.id}
          customStates={customStates}
          addedCustomStates={addedCustomStates}
          addCustomStateToHistory={addCustomStateToHistory}
        />
      )}
      {isSuperUser() && <AddComment orderId={order.id} refetch={refetch} />}
      <TimeLine>
        <Events>
          {[...orderHistory]
            .filter((entry) => entry.type !== "REMOVE_COMMENT")
            .reverse()
            .map((entry) => (
              <Event key={uuidv4()}>
                <IconWrapper>
                  <Icon orderHistoryLogEntry={entry} />
                </IconWrapper>
                <Text>
                  <Info>
                    <Message>
                      <b>{getEntryMessage(entry)}</b>
                    </Message>
                    {entry.actor?.name && (
                      <>
                        <User className={"hideOnXSmallScreens"}>{entry.actor?.name}</User>
                      </>
                    )}
                    {entry?.reason && <Reason reason={entry?.reason} />}
                    {entry?.errorMessage && (
                      <Error className={"hideOnXSmallScreens"}>
                        <ErrorIcon />
                        {entry.errorMessage}
                      </Error>
                    )}
                    <div className={"hideOnXSmallScreens"}>
                      {entry?.operation === "DELIVERY" && (
                        <DeliveryInfo
                          delivery={getDelivery(entry.operationId)}
                          status={entry.status}
                          currencyCode={order.currencyCode}
                        />
                      )}
                      {entry?.operation === "REFUND" && (
                        <RefundInfo
                          refund={getRefund(entry.operationId)}
                          status={entry.status}
                          currencyCode={order.currencyCode}
                        />
                      )}
                      {entry?.operation === "MODIFY_ORDER_LINES" && (
                        <ModificationInfo
                          modification={getModification(entry.operationId)}
                          status={entry.status}
                          currencyCode={order.currencyCode}
                        />
                      )}
                      {entry.operation === "CUSTOM_STATE" && (
                        <CustomStateInfo
                          entry={entry}
                          getCustomState={getCustomState}
                          hideOperations={entry.type === "REMOVE_CUSTOM_STATE"}
                        />
                      )}
                      {entry.operation === "COMMENT" && (
                        <CommentInfo
                          comment={entry?.comment?.text}
                          commentId={entry.operationId}
                          orderId={order.id}
                          refetch={refetch}
                          actorEmail={entry?.actor?.name ?? ""}
                        />
                      )}
                    </div>
                  </Info>
                  <EntryDate>{dayjs(entry.timestamp).format("YYYY-MM-DD HH:mm")}</EntryDate>
                </Text>
              </Event>
            ))}
          <Event>
            <IconWrapper>
              <SuccessIcon>
                <PlacedIcon />
              </SuccessIcon>
            </IconWrapper>
            <Text>
              <div>
                <Message>
                  <b>Order placed</b>
                </Message>
              </div>
              <EntryDate>{dayjs(order.date).format("YYYY-MM-DD HH:mm")}</EntryDate>
            </Text>
          </Event>
        </Events>
      </TimeLine>
    </Wrapper>
  )
}

export default OrderHistoryLog
