import styled from "styled-components"

export const InfoWrapper = styled.div`
  display: flex;
  img {
    height: 5rem;
  }

  div {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
  }
`
