import styled from "styled-components"

type StyledProps = {
  $included?: boolean
  $selected?: boolean
}

export const RuleContainer = styled.div<StyledProps>`
    border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
    padding: 1rem;
    background: ${({ theme, $included }) =>
            $included ? theme.colors.turquoiseLighter : theme.colors.white};
    position: relative;
    display: flex;
    color: ${({ theme, $included }) =>
            $included ? theme.colors.turquoiseDarker : theme.colors.black};

    span {
        display: flex;
        align-items: center;
        width: 100%;

        svg {
            height: 2rem;
            fill: ${({ theme, $included }) =>
                    $included ? theme.colors.turquoiseDarker : theme.colors.black};
            margin-right: 1rem;
        }
    }

    button {
        height: 2.6rem;
        padding: 0 1rem;
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
    }


`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
        grid-template-columns: repeat(3, 1fr);
    }
`

export const DisabledRuleContainer = styled(RuleContainer)`
    color: ${({ theme }) => theme.colors.errorText};
    span {
        svg {
            fill: ${({ theme }) => theme.colors.errorText};
        }
    }
`

export const IconLabel = styled.h4`
  display: flex;
  align-items: center;
  margin-top: 3rem;
    width: fit-content;

  svg {
    height: 1.6rem;
    margin-left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
  }
`

export const TypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

export const Border = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  display: flex;
`

export const TypeSelect = styled.div<StyledProps>`
  width: 22rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
  background: ${({ theme, $selected }) =>
  $selected ? theme.colors.turquoiseDark : theme.colors.white};
  color: ${({ theme, $selected }) => ($selected ? theme.colors.white : theme.colors.black)};
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }
`

export const EventRuleRow = styled.div`
  display: flex;
  align-items: center;
svg {
  height: 1.4rem;
  margin-right: 1rem;
}
`

export const EnabledEvents = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  background: ${({theme}) => theme.colors.white};
  border-radius: 0.6rem;
  padding: 2rem;

`

export const EmptyList = styled.div`
  display: flex;
  align-items: center;

svg {
    height: 1.6rem;
    margin-right: 1rem;
}
`

export const AddButton = styled.div<StyledProps>`
    width: 3.2rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid ${({ theme }) => theme.colors.grey};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};

    &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.colors.greyLighter};
    }

    svg {
        fill: ${({ theme }) => theme.colors.greyDarker};
        margin: 0;
        height: 1.3rem;
    }
`

