import styled from "styled-components"
import Input from "../../../Ui/Form/Input"

export const Info = styled.div`
font-size: 1.2rem;
  line-height: 2rem;
  width: 100%;
  a {
    text-decoration: underline;
  }
`

export const Id = styled.div`
font-size: 1.1rem;
  line-height: 1rem;
`

export const Name = styled.div`
  margin-top: 1rem;
  font-weight: 700;
`

export const CommentInput = styled.input`
  border: none;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  width: 100%;
  padding-bottom: 1rem;
`

export const Operations = styled.div`
margin-top: 1rem;
`

export const Comment = styled.div`
display: flex;
align-items: center;

  svg {
    height: 1.2rem;
    margin-right: 0.5rem;
  }
`

export const StyledInput = styled(Input)`
width: 100% !important;

`

export const Operation = styled.div`
  display: flex;
  align-items: center;


  svg {
    fill: ${({ theme }) => theme.colors.errorText};
    height: 1.4rem;
    margin-right: 0.5rem;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6em;
    height: 2.6rem;
    margin-right: 0.5rem;
    padding: 0;

    svg {
      margin: 0;
      height: 1.4rem;
    }
  }
`
