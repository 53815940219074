import React, { Dispatch, SetStateAction } from "react"
import {
  InputActionPaymentOrderModificationOrderLines,
  InputActionType,
  OrderModification
} from "lib/types/generated/graphql-types"
import {
  ActionContainer,
  ActionWrapper,
  ButtonWrapper,
  Container,
  InfoWrapper,
  LabelWrapper,
  StyledActionDropdownButton
} from "./ModificationAction.styled"
import { ReactComponent as PaymentIcon } from "../../../../../images/icons/credit-card.svg"
import { useMutation } from "@apollo/client"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { handleErrorMessages } from "helpers/errors"
import START_MODIFICATION from "../../../../../graphql/mutations/order/modification/StartModification"

type Props = {
  modification: OrderModification
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  orderId: string
  setStartedModification: Dispatch<SetStateAction<string>>
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
  paymentAction: InputActionPaymentOrderModificationOrderLines
  setPaymentAction: Dispatch<SetStateAction<InputActionPaymentOrderModificationOrderLines>>
}

const ModificationAction = ({
  modification,
  setShowStatusPopup,
  setStartedModification,
  setShowConfirmation,
  paymentAction,
  setPaymentAction
}: Props) => {
  const [startModification, { loading }] = useMutation(START_MODIFICATION, {
    onCompleted: () => {
      setStartedModification(modification.id)
      setShowStatusPopup(true)
      setShowConfirmation(false)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const startOrderModification = () => {
    startModification({
      variables: {
        modificationId: modification.id,
        input: {
          payment: paymentAction
        }
      }
    })
  }

  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <>
      {" "}
      <h2>Modification actions</h2>
      <Container>
        <InfoWrapper>
          <b>Auto</b>{" "}
          <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
          <b>Skip</b> <p>The action will be skipped entirely.</p>
        </InfoWrapper>
        <div>
          <ActionContainer>
            <ActionWrapper>
              <LabelWrapper>
                <PaymentIcon />
                <b>Payment - {modification?.paymentProvider?.providerName}</b>
              </LabelWrapper>
              <StyledActionDropdownButton title={getLabel(paymentAction?.actionType ?? "")}>
                <li onClick={() => setPaymentAction({ actionType: InputActionType.Auto })}>Auto</li>
                <li onClick={() => setPaymentAction({ actionType: InputActionType.Skip })}>Skip</li>
              </StyledActionDropdownButton>
            </ActionWrapper>
          </ActionContainer>
        </div>
        {
          <ButtonWrapper>
            <SecondaryButton handleClick={() => setShowConfirmation(false)}>
              Back to draft
            </SecondaryButton>
            <PrimaryButton handleClick={startOrderModification} loading={loading}>
              Finalize Modification
            </PrimaryButton>
          </ButtonWrapper>
        }
      </Container>
    </>
  )
}

export default ModificationAction
