import styled from "styled-components"

import TableHeader from "components/Ui/Table/TableHeader"

export const Container = styled.div`
  width: 100%;
  min-height: 20rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 10rem;
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`

export const ProductImageContainer = styled.span`
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin: 0.5rem 2rem 0.5rem 0;
`

export const ProductImage = styled.img`
  object-fit: contain;
  display: block;
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
`

export const Product = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
`

export const Id = styled.p`
  font-size: 1.2rem;
  margin: 0;
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const HeaderName = styled.div``

export const StatusContainer = styled.div`
  padding-left: 3rem;
`
