import React, { useState } from "react"
import {
  CustomReasonCode,
  InputReason,
  Maybe,
  Reason as ReasonType
} from "@lib/types/generated/graphql-types"
import { ReactComponent as DownIcon } from "../../../../images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "../../../../images/icons/chevron-up.svg"
import { Label, LabelWrapper, Wrapper } from "./Reason.styled"
import AddedReason from "./AddedReason"
import ReasonInput from "./ReasonInput"
import { useQuery } from "@apollo/client"
import ALL_REASON_CODES from "../../../../graphql/queries/settings/reasonCodes/AllReasonCodes"

type Props = {
  addReason: (reason: InputReason) => void
  removeReason: () => void
  loading: boolean
  orderLoading: boolean
  orderReason: Maybe<ReasonType> | undefined
}

const Reason = ({ addReason, orderReason, removeReason, loading, orderLoading }: Props) => {
  const [reason, setReason] = React.useState<InputReason>({ cause: "", code: "" })
  const [expanded, setExpanded] = useState<boolean>(false)

  const { data, loading: allCodesLoading } = useQuery(ALL_REASON_CODES)
  const customReasons = data?.Order?.customReasonCodes?.codes

  const handleAddReason = () => {
    addReason(reason)
    setReason({ cause: "", code: "" })
  }

  const getCustomCode = (code: Maybe<string> | undefined) =>
    customReasons?.find((customCode: CustomReasonCode) => customCode.code === code)

  if (orderReason)
    return (
      <Wrapper className={allCodesLoading || orderLoading ? "blur" : ""}>
        <Label>Reason</Label>
        <AddedReason
          reason={orderReason}
          removeReason={removeReason}
          loading={loading}
          getCustomCode={getCustomCode}
        />
      </Wrapper>
    )

  return (
    <Wrapper className={allCodesLoading ? "blur" : ""}>
      <LabelWrapper onClick={() => setExpanded(!expanded)}>
        <Label>Reason (optional)</Label>
        {expanded ? <UpIcon /> : <DownIcon />}
      </LabelWrapper>
      {expanded && (
        <div>
          <ReasonInput
            reason={reason}
            setReason={setReason}
            addReason={handleAddReason}
            addReasonLoading={loading}
            customReasons={customReasons}
          />
        </div>
      )}
    </Wrapper>
  )
}

export default Reason
