import React from "react"
import { Card, Currency, Title, Value } from "./DataCard.styled"
import CountUp from "react-countup"

interface DataCardProps {
  label: string
  value: number
  currency?: string
  handleOnClick?: () => void
}

export default function DataCard({ label, value, currency, handleOnClick }: DataCardProps) {
  return (
    <Card onClick={handleOnClick}>
      <Title>{label}</Title>
      <Value>
        <CountUp end={value} duration={1} />
        {currency && <Currency>{currency}</Currency>}
      </Value>
    </Card>
  )
}
