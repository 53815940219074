import React from "react"
import styled from "styled-components"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import { ReactComponent as Icon } from "images/icons/tag.svg"
import {
  OrderPromotion, OrderPromotionVoyado,
} from "@lib/types/generated/graphql-types"
import Status from "../../../../../components/Ui/Status"

const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }

  > div {
    flex: 1;
  }
`

type Props = {
  promotionDetails: OrderPromotion
}

const Promotion = ({ promotionDetails }: Props) => {
  function isOrderPromotionVoyado(object: OrderPromotion): object is OrderPromotionVoyado {
    return object.providerName === "Voyado"
  }
  return (
    <Columns>
      <OrderBlock>
        <h2>
          <Icon />
          External promotion
        </h2>
        <List>
          <>
            {isOrderPromotionVoyado(promotionDetails) &&
            promotionDetails?.promotions &&
            promotionDetails?.promotions?.length > 0 ? (
              <>
                {promotionDetails?.promotions.map((promotion) => (
                  <List key={promotion.id}>
                    <Item>
                      <Label>Provider id:</Label>
                      <Value>{promotionDetails.providerId}</Value>
                    </Item>
                    <Item>
                      <Label>Provider name:</Label>
                      <Value>{promotionDetails.providerName}</Value>
                    </Item>
                    <Item>
                      <Label>Value:</Label>
                      <Value>
                        {promotion.value}
                      </Value>
                    </Item>
                    <Item>
                      <Label>Contact id:</Label>
                      <Value>{promotionDetails.contactId}</Value>
                    </Item>
                    <Item>
                      <Label>Status:</Label>
                      <Status status={promotion.status} />
                    </Item>
                    {promotion?.failedReason ? (
                      <Item>
                        <Label>Failed reason:</Label>
                        <Value>{promotion.failedReason}</Value>
                      </Item>
                    ) : (
                      <></>
                    )}
                  </List>
                ))}
              </>
            ) : (
              <p>No external promotion used for this order.</p>
            )}
          </>
        </List>
      </OrderBlock>
    </Columns>
  )
}

export default Promotion
