import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components"
import { useForm, Controller } from "react-hook-form"
import { useMutation } from "@apollo/client"
import { getCountryName } from "../../../helpers/countries"
import { useAppDispatch } from "lib/store"
import { TaxMarket } from "lib/types/generated/graphql-types"
import { Common } from "@lib/types"

import CREATE_OR_UPDATE_TAX_MARKET from "graphql/mutations/tax/CreateOrUpdateTaxMarket"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Box from "components/Ui/Box"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import alertActions from "lib/store/services/Alert/AlertSlice"
import FlagIcon from "components/Ui/FlagIcon"
import DeleteTaxMarket from "components/TaxMarket/DeleteTaxMarket"
import SingleSelect from "components/Ui/Form/SingleSelect"
import Input from "components/Ui/Form/Input"

const Form = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
`

const Tax = styled.div``

const Formatted = styled.div`
  margin: 1rem 0;
`

type Inputs = {
  countryCode: string
  taxPercentage: number
  taxPercentageDecimals: number
}

type Props = {
  editTaxMarket: TaxMarket | undefined
  setEditTaxMarket: Dispatch<SetStateAction<TaxMarket | undefined>>
  refetch: () => void
  refetchTaxGroups: () => void
  refetchTaxMarkets?: () => void
}

const UpdateTaxMarket = ({
  editTaxMarket,
  setEditTaxMarket,
  refetch,
  refetchTaxGroups,
  refetchTaxMarkets
}: Props) => {
  const {
    control,
    formState: { errors }
  } = useForm<Inputs>()

  const dispatch = useAppDispatch()
  const [taxPercentage, setTaxPercentage] = useState<number>(0)
  const [taxPercentageDecimals, setTaxPercentageDecimals] = useState<number>(0)

  const [updateTaxMarket, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_TAX_MARKET, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Tax market successfully updated",
          type: "success"
        })
      )
      setEditTaxMarket(undefined)
      refetch()
      refetchTaxMarkets && refetchTaxMarkets()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const submitTaxMarket = () => {
    updateTaxMarket({
      variables: {
        taxGroupId: editTaxMarket?.taxGroupId,
        countryCode: editTaxMarket?.countryCode,
        taxPercentage: taxPercentage || editTaxMarket?.taxPercentage,
        taxPercentageDecimals: taxPercentageDecimals || editTaxMarket?.taxPercentageDecimals
      }
    })
  }

  if (createLoading) return <EditSidebar loading={true} />
  if (!editTaxMarket) return null

  const updateValue = (value: Common.Option) => {
    setTaxPercentageDecimals(parseInt(value.value))
  }

  const options = [
    {
      value: "2",
      label: "2"
    },
    {
      value: "3",
      label: "3"
    }
  ]
  return (
    <EditSidebar setEditTaxMarket={setEditTaxMarket}>
      <Box padding="0" margin="0">
        <EditSidebarHeader
          title={getCountryName(editTaxMarket?.countryCode) ?? ""}
          icon={<FlagIcon countryCode={editTaxMarket.countryCode} />}
          cancelEvent={() => setEditTaxMarket(undefined)}
        >
          <DeleteTaxMarket
            name={getCountryName(editTaxMarket?.countryCode) ?? ""}
            taxGroupId={editTaxMarket?.taxGroupId ?? ""}
            countryCode={editTaxMarket?.countryCode ?? ""}
            refetch={refetch}
            icon={false}
            setEditTaxMarket={setEditTaxMarket}
            refetchTaxGroups={refetchTaxGroups}
            refetchTaxMarkets={refetchTaxMarkets}
          />
          <PrimaryButton type="submit" handleClick={submitTaxMarket} loading={createLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Form>
          <EditSidebarSection>
            <Tax>
              <Controller
                name="taxPercentage"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    errors={errors}
                    label="Tax percentage in minor units*"
                    onChange={(e: { target: { value: number } }) => {
                      setTaxPercentage(e.target.value)
                    }}
                    defaultValue={editTaxMarket.taxPercentage}
                  />
                )}
                control={control}
                rules={{
                  required: "This is a required field",
                  min: {
                    value: 0,
                    message: "Least value is 0"
                  }
                }}
              />
            </Tax>
            <Tax>
              <Controller
                name="taxPercentageDecimals"
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    options={options}
                    placeholder="2 or 3"
                    label="Tax percentage decimals *"
                    setValue={updateValue}
                    defaultValue={{
                      value: editTaxMarket.taxPercentageDecimals.toString(),
                      label: editTaxMarket.taxPercentageDecimals.toString()
                    }}
                  />
                )}
                control={control}
                rules={{
                  min: {
                    value: 2,
                    message: "Least value is 2"
                  },
                  max: { value: 3, message: "Greatest value is 3" },
                  required: "This is a required field"
                }}
              />
            </Tax>
          </EditSidebarSection>
        </Form>

        <Formatted>
          <strong>Formatted: </strong>
          {taxPercentage || taxPercentageDecimals
            ? (taxPercentage || editTaxMarket.taxPercentage) /
                10 ** (taxPercentageDecimals || editTaxMarket.taxPercentageDecimals) +
              "%"
            : editTaxMarket.taxPercentage / 10 ** editTaxMarket.taxPercentageDecimals + "%"}
        </Formatted>
      </Box>
    </EditSidebar>
  )
}
export default UpdateTaxMarket
