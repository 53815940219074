import { createSlice } from "@reduxjs/toolkit"

const initialStateValue = {
  orderLoading: false
}

const slice = createSlice({
  name: "order",
  initialState: initialStateValue,
  reducers: {
    setLoading: (state = initialStateValue, action) => {
      state.orderLoading = action.payload
      document.documentElement.classList.remove("sidebar-open")
    }
  }
})

const { actions, reducer } = slice
export const { setLoading } = actions

export default reducer
