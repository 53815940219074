import styled from "styled-components"
import TableRow from "../../Ui/Table/TableRow"

export const StyledTableRow = styled(TableRow)`

  > div > div {
    height: 3rem;
  }
`

export const InputWrapper = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.6rem;
  padding: 2rem;
`

export const Label = styled.div`
  margin-bottom: 1rem;
  font-weight: 700;
`

export const Info = styled.div`
display: flex;
`

export const Value = styled.div`
margin-left: 1rem;
`
