import React, { Dispatch, SetStateAction } from "react"
import {
  Order,
  OrderGiftCardProduct,
  OrderGiftCardProductRetain24
} from "lib/types/generated/graphql-types"

import { ButtonWrapper, CartContainer, CartItems, EmptyContainer, Label } from "./Cart.styled"

import CartItem from "./CartItem"
import Totals from "./Totals"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import GiftItem from "./GiftItem"
import ShippingFees from "./ShippingFees"
import DELETE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/DeleteDelivery"
import { handleErrorMessages } from "helpers/errors"
import GiftCardItem from "./GiftCardItem"
import DeliveryReason from "./DeliveryReason"

type Props = {
  order: Order
  refetch: () => void
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
  summaryMode?: boolean
  orderLoading: boolean
}

export const Cart = ({ order, refetch, setShowConfirmation, summaryMode = false, orderLoading }: Props) => {
  const filteredDeliveries = order?.Deliveries?.filter(
    (delivery) => !delivery.completed && !delivery.started
  )

  const [deleteDelivery, { loading: deleteLoading }] = useMutation(DELETE_DELIVERY, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  function isOrderGiftCardProductRetain24(
    object: OrderGiftCardProduct
  ): object is OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  if (!order) return null
  return (
    <>
      {filteredDeliveries?.length > 0 ? (
        <>
          <h2>Delivery draft</h2>
          {filteredDeliveries?.map((delivery) => (
            <CartContainer key={delivery.id}>
              <CartItems>
                {delivery?.orderLines?.map((deliveryOrderLine) => (
                  <CartItem
                    currencyCode={order.currencyCode}
                    key={deliveryOrderLine.orderLineId}
                    item={deliveryOrderLine}
                    orderLine={order?.orderLines?.find(
                      (ol) => ol.id === deliveryOrderLine.orderLineId
                    )}
                    delivery={delivery}
                    refetch={refetch}
                  />
                ))}
                {delivery?.gifts?.map((deliveryGift) => (
                  <GiftItem
                    currencyCode={order?.currencyCode}
                    key={deliveryGift.giftId}
                    item={deliveryGift}
                    orderGift={order?.gifts?.find((gift) => gift.id === deliveryGift.giftId)}
                    delivery={delivery}
                    refetch={refetch}
                  />
                ))}
                {delivery?.giftCardProducts?.map((deliveryGiftCardProduct) => (
                  <GiftCardItem
                    currencyCode={order?.currencyCode}
                    key={deliveryGiftCardProduct.giftCardProductId}
                    item={deliveryGiftCardProduct}
                    orderGiftCardProduct={
                      isOrderGiftCardProductRetain24(order?.GiftCardProduct)
                        ? order?.GiftCardProduct?.giftCardProducts?.find(
                            (giftCard) => giftCard.id === deliveryGiftCardProduct.giftCardProductId
                          )
                        : undefined
                    }
                    delivery={delivery}
                    refetch={refetch}
                  />
                ))}
                <ShippingFees
                  delivery={delivery}
                  refetch={refetch}
                  currencyCode={order.currencyCode}
                  order={order}
                />
                <DeliveryReason refetch={refetch} order={order} delivery={delivery} orderLoading={orderLoading}/>
                <Label>To be delivered: </Label>
                <Totals totals={delivery.totals} currencyCode={order?.currencyCode} />
              </CartItems>
              <ButtonWrapper>
                {!summaryMode && (
                  <SecondaryButton
                    loading={deleteLoading}
                    handleClick={() => deleteDelivery({ variables: { deliveryId: delivery.id } })}
                  >
                    Cancel delivery draft
                  </SecondaryButton>
                )}
                {!summaryMode && (
                  <PrimaryButton
                    disabled={
                      delivery?.orderLines.length < 1 &&
                      delivery?.gifts.length < 1 &&
                      delivery?.shippingFees.length < 1 &&
                      delivery?.giftCardProducts.length < 1
                    }
                    handleClick={() => setShowConfirmation(true)}
                  >
                    Next
                  </PrimaryButton>
                )}
              </ButtonWrapper>
            </CartContainer>
          ))}
        </>
      ) : (
        <>
          <h2>Delivery draft</h2>
          <EmptyContainer>
            <h2>Create deliveries to finalize.</h2>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
