import styled from "styled-components"
import TableHeader from "../Ui/Table/TableHeader"

export const StyledTableHeader = styled(TableHeader)`
  padding: 0 2rem 0 2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  background-color: ${(p) => p.theme.colors.greyLighter};
  color: ${(p) => p.theme.colors.black};
`

export const Container = styled.div`
  width: 100%;
  min-height: 5rem;
`
