import React, { RefObject } from "react"
import { Outcomes } from "@lib/types/discount"

type Props = {
  addOutcome: (data: Outcomes | undefined) => void
  submitRef: RefObject<HTMLButtonElement | null>
}

export const FreeShipping = ({ addOutcome, submitRef }: Props) => (
  <button
    onClick={() => addOutcome(undefined)}
    ref={submitRef}
    type="button"
    style={{ display: "none" }}
  />
)
