import { DeliveryGift, DeliveryGiftCard, OrderDelivery } from "@lib/types/generated/graphql-types"
import { OrderOperations } from "./OrderOperations"

export class Delivery extends OrderOperations {
  constructor(delivery: OrderDelivery[]) {
    super(delivery)
  }

  public completedDeliveries(): OrderDelivery[] {
    return this.completedList() as OrderDelivery[]
  }

  public failedDeliveries(): OrderDelivery[] {
    return this.failedList() as OrderDelivery[]
  }

  public getOngoingDelivery(): OrderDelivery {
    const onGoingList = this.onGoingList() as OrderDelivery[]
    return onGoingList[0]
  }

  public getPendingDelivery(): OrderDelivery {
    const pendingList = this.pendingList() as OrderDelivery[]
    return pendingList[0]
  }

  public giftsDelivered(giftId: string): (DeliveryGift | undefined)[] {
    return this.completedDeliveries().map((delivery) =>
      delivery.gifts.find((deliveryGift) => deliveryGift.giftId === giftId)
    )
  }

  public giftCardsInDeliveries(): DeliveryGiftCard[] {
    return this.completedDeliveries().flatMap((delivery) => delivery.giftCards)
  }

  public paymentTotal(): number {
    return this.completedDeliveries()
      .map((delivery) => delivery.totals.grandTotal)
      .reduce((a, b) => a + b, 0)
  }

  public giftCardTotal(): number {
    return this.completedDeliveries()
      .map((delivery) => delivery.totals.giftCardTotal)
      .reduce((a, b) => a + b, 0)
  }

  public bonusTotal(): number {
    return this.completedDeliveries()
      .map((delivery) => delivery.totals.bonusTotal)
      .reduce((a, b) => a + b, 0)
  }
}
