import Dinero from "dinero.js"
import DineroFactory from "dinero.js"

const currenciesWithoutDecimals = ["JPY", "KRW", "ISK"]

export const Currency = (currency: DineroFactory.Currency) => (amount: number) =>
  currenciesWithoutDecimals.includes(currency)
    ? Dinero({ currency, amount, precision: 0 })
    : Dinero({ currency, amount })

export const formatFromMinorUnits = (price: number, currency: string) =>
  currenciesWithoutDecimals.includes(currency) ? Number(price) : Number(price / 100)

export const formatPrice = (price: number, currency: string) =>
  currenciesWithoutDecimals.includes(currency)
    ? Number(price) | 0
    : Number(price * 100).toFixed(2) || 0
