import React from "react"
import EditSidebar from "../../Ui/EditSidebar/EditSidebar"
import Input from "../../Ui/Form/Input"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import EditSidebarHeader from "../../Ui/EditSidebar/EditSidebarHeader"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import CREATE_OR_UPDATE_REASON_CODE from "../../../graphql/mutations/settings/reasonCodes/CreateOrUpdateReasonCode"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { handleErrorMessages } from "helpers/errors"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import GET_REASON_CODE from "../../../graphql/queries/settings/reasonCodes/GetReasonCode"
import { Info, InputWrapper, Label, Value } from "./Shared.styled"
import dayjs from "dayjs"
import { ReactComponent as OptionIcon } from "images/icons/timeline.svg"
import DELETE_REASON_CODE from "../../../graphql/mutations/settings/reasonCodes/DeleteReasonCode"

type ReasonInput = {
  label: string
}

type Props = {
  reasonCode: string
  refetch: () => void
  handleClearReasonCode: () => void
}

const EditReasonCode = ({ refetch, reasonCode, handleClearReasonCode }: Props) => {
  const { register, handleSubmit, reset } = useForm<ReasonInput>()

  const dispatch = useDispatch()

  const { data, loading: fetchLoading } = useQuery(GET_REASON_CODE, {
    variables: { code: reasonCode }
  })

  const customReasonCode = data?.Order?.customReasonCode

  const [updateReasonCode, { loading }] = useMutation(CREATE_OR_UPDATE_REASON_CODE, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Definition updated",
          type: "success"
        })
      )
      refetch()
      reset()
      handleClearReasonCode()
      dispatch(hideEditSidebar())
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [deleteReasonCode, {loading: deleteLoading}] = useMutation(DELETE_REASON_CODE, {
    variables: { code: reasonCode },
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Definition deleted successfully",
          type: "success"
        })
      )
      refetch()
      reset()
      handleClearReasonCode()
      dispatch(hideEditSidebar())
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const cancelSidebar = () => {
    dispatch(hideEditSidebar())
    handleClearReasonCode()
  }

  const onSubmit: SubmitHandler<ReasonInput> = (data) => {
    updateReasonCode({ variables: { code: reasonCode, input: { label: data.label } } })
  }

  if (fetchLoading)
    return (
      <EditSidebar cancelEvent={cancelSidebar}>
        <EditSidebarHeader
          title={"Update Definition"}
          cancelEvent={cancelSidebar}
          icon={<OptionIcon />}
        >
          <PrimaryButton
            disabled={true}
            mode={"delete"}
          >
            Delete
          </PrimaryButton>
          <PrimaryButton disabled={true}>
            Update definition
          </PrimaryButton>
        </EditSidebarHeader>
        <InputWrapper className="blur">
          <Label>Reason code</Label>
          <Info>
            <div>Code:</div>
            <Value>
              <b>{reasonCode}</b>
            </Value>
          </Info>
        </InputWrapper>
      </EditSidebar>
    )

  return (
    <EditSidebar cancelEvent={cancelSidebar}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader
          title={"Update Definition"}
          cancelEvent={cancelSidebar}
          icon={<OptionIcon />}
        >
          <PrimaryButton
            type={"button"}
            mode={"delete"}
            loading={deleteLoading}
            handleClick={() => deleteReasonCode()}
          >
            Delete
          </PrimaryButton>
          <PrimaryButton type={"submit"} loading={loading}>
            Update definition
          </PrimaryButton>
        </EditSidebarHeader>

        <InputWrapper>
          <Label>Reason code</Label>
          <Info>
            <div>Code:</div>
            <Value>
              <b>{reasonCode}</b>
            </Value>
          </Info>
          <Info>
            <div>Created:</div>
            <Value>{dayjs(customReasonCode?.created).format("YYYY-MM-DD")}</Value>
          </Info>
          <Info>
            <div>Updated:</div>
            <Value>{dayjs(customReasonCode?.updated).format("YYYY-MM-DD")}</Value>
          </Info>
          <br />
          <Input
            name={"label"}
            placeholder={"Name"}
            register={register}
            defaultValue={customReasonCode?.label}
          />
        </InputWrapper>
      </form>
    </EditSidebar>
  )
}

export default EditReasonCode
