export class Percentage {
  private readonly percentage: number
  private readonly percentageDecimals: number

  constructor(percentage = 0, percentageDecimals = 0) {
    this.percentage = percentage
    this.percentageDecimals = percentageDecimals
  }

  public formatPercentage(): string {
    return this.percentage / 10 ** this.percentageDecimals + "%"
  }
}
