import React from "react"
import { Order, OrderDelivery } from "lib/types/generated/graphql-types"
import { Container, Label, ShippingFeeWrapper } from "./ShippingFees.styled"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { useMutation } from "@apollo/client"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { handleErrorMessages } from "helpers/errors"
import { getDeliveryInput } from "../helpers"

type Props = {
  delivery: OrderDelivery
  currencyCode: string
  refetch: () => void
  order: Order
}

const ShippingFees = ({ delivery, currencyCode, refetch, order }: Props) => {
  const [updateDelivery, { loading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const shippingFeeExistsInDelivery = (id: string) =>
    !!delivery?.shippingFees?.find((fee) => fee.shippingFeeId === id)

  const shippingFeeAlreadyDelivered = (id: string) => {
    const deliveryFees = order.Deliveries.filter((delivery) => !!delivery.completed).filter(
      (delivery) => delivery.shippingFees.find((fee) => fee.shippingFeeId === id)
    )
    return deliveryFees.length > 0
  }

  const addShippingFee = (id: string) => {
    updateDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...getDeliveryInput(delivery),
          shippingFees: [...delivery.shippingFees, id]
        }
      }
    })
  }

  const removeShippingFee = (id: string) => {
    updateDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...getDeliveryInput(delivery),
          shippingFees: delivery.shippingFees.filter((fee) => fee.shippingFeeId !== id)
        }
      }
    })
  }

  if (order.shippingFees?.length === 0) return null

  return (
    <Container>
      <Label>Shipping:</Label>
      {order.shippingFees &&
        order?.shippingFees?.map((fee) => {
          if (shippingFeeAlreadyDelivered(fee.id ?? ""))
            return <div key={fee.id}>No shipping fee to deliver</div>
          return (
            <ShippingFeeWrapper key={fee.id}>
              <div>
                <b>{fee?.name}</b>
                {" - "}
                <Money
                  amount={fee?.salePriceAmount ?? 0}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </div>
              {shippingFeeExistsInDelivery(fee.id ?? "") ? (
                <PrimaryButton
                  loading={loading}
                  mode="delete"
                  handleClick={() => removeShippingFee(fee?.id ?? "")}
                >
                  Exclude
                </PrimaryButton>
              ) : (
                <PrimaryButton loading={loading} handleClick={() => addShippingFee(fee?.id ?? "")}>
                  Include
                </PrimaryButton>
              )}
            </ShippingFeeWrapper>
          )
        })}
    </Container>
  )
}

export default ShippingFees
