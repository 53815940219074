import styled from "styled-components"
import { Link } from "react-router-dom"

interface ContainerProps {
  $hasTabs: boolean
}
interface StyledProps {
  $sticky: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 ${(p) => (p.$hasTabs ? "0" : "4rem")};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding-bottom: 2rem;
  position: relative;

  p {
    margin-top: 3rem;
    color: ${(p) => p.theme.colors.greyDarker};
  }
`

export const PageTitle = styled.h1`
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  padding-top: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: 0.5rem 0;
    padding-top: 0;
  }

  div,
  span {
    margin-left: 1.5rem;
  }

  svg {
    height: 4rem;
  }
`

export const Description = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    display: inline-flex;
    color: ${(p) => p.theme.colors.turquoiseDarker};
  }
`

export const Group = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`

export const Id = styled.div`
  display: flex;
  align-items: center;

  span {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.greyDark};
    margin: 0;
  }
`

export const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    position: relative;
    top: auto;
  }

  button {
    margin-left: 1rem;
  }
`

export const StickyActionButtons = styled(Actions)`
  position: relative;
`

export const GoBackLink = styled(Link)`
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.turquoiseDarker};

  svg {
    height: 1.4rem;
    margin-right: 0.5rem;
    fill: ${(p) => p.theme.colors.turquoiseDarker};
  }
`

export const StickyContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 1rem 4rem;
  width: 100%;
  position: fixed;
  background: ${(p) => p.theme.colors.blueDarkOpac};
  top: 0;
  left: 0;
  top: 5rem;
  z-index: 10;
  height: 7rem;
  transform: ${(p) => (p.$sticky ? "translateY(0)" : "translateY(-12rem)")};
  transition: transform 0.3s;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1rem 16rem 1rem 6rem;
    transform: ${(p) => (p.$sticky ? "translateY(0)" : "translateY(-7rem)")};
    left: 10rem;
    top: 0rem;
  }

  h1 {
    font-size: 1.4rem !important;
    padding-top: 0;

    span {
      display: none;
    }

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      font-size: 1.8rem !important;

      span {
        display: inline-flex;
      }
    }
  }
`

export const Wrapper = styled.div`
  color: ${(p) => p.theme.colors.white};

  h1 {
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin: 0 0 0.5rem;
  }

  span {
    font-size: 1.2rem;
  }
`

export const GoBackLinkSticky = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 6rem;
  }

  svg {
    height: 1.5rem;
    fill: ${(p) => p.theme.colors.white};
  }
`
