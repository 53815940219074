import React from "react"
import {
  InputReason,
  Order,
  OrderLineModificationCreateData, OrderLineModificationDeleteData,
  OrderLineModificationType,
  OrderLineModificationUpdateData,
  OrderModificationOrderLines
} from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { handleErrorMessages } from "helpers/errors"
import UPDATE_MODIFICATION from "../../../../../graphql/mutations/order/modification/UpdateModification"
import { Modification } from "../../Helpers/Modification"
import Reason from "../../Reason/Reason"

type Props = {
  modification: OrderModificationOrderLines
  refetch: () => void
  order: Order
  orderLoading: boolean
}

const ModificationReason = ({ modification, refetch, order, orderLoading }: Props) => {
  const modifications = new Modification(order.Modifications)

  const [updateModification, { loading }] = useMutation(UPDATE_MODIFICATION, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const addReason = (reason: InputReason) => {
    updateModification({
      variables: {
        modificationId: modification.id,
        input: {
          orderLines: modification.orderLines.map((orderLine) => ({
            ...orderLine,
            data:
              orderLine.modificationType === OrderLineModificationType.Update
                ? JSON.stringify(
                    modifications.updateOutputToInput(
                      orderLine.data as OrderLineModificationUpdateData
                    )
                  )
                : orderLine.modificationType === OrderLineModificationType.Create
                  ? JSON.stringify(
                      modifications.createOutputToInput(
                        orderLine.data as OrderLineModificationCreateData
                      )
                    )
                  : orderLine.modificationType === OrderLineModificationType.Delete
                    ? JSON.stringify(
                        modifications.deleteOutputToInput(
                          orderLine.data as OrderLineModificationDeleteData
                        )
                      )
                    : JSON.stringify(orderLine.data)
          })),
          ...(reason.cause && reason.code && { reason: reason })
        }
      }
    })
  }

  const removeReason = () => {
    updateModification({
      variables: {
        modificationId: modification.id,
        input: {
          orderLines: modification.orderLines.map((orderLine) => ({
            ...orderLine,
            data:
              orderLine.modificationType === OrderLineModificationType.Update
                ? JSON.stringify(
                    modifications.updateOutputToInput(
                      orderLine.data as OrderLineModificationUpdateData
                    )
                  )
                : orderLine.modificationType === OrderLineModificationType.Create
                  ? JSON.stringify(
                      modifications.createOutputToInput(
                        orderLine.data as OrderLineModificationCreateData
                      )
                    )
                  : JSON.stringify(orderLine.data)
          })),
          reason: null
        }
      }
    })
  }
  return (
    <Reason
      addReason={addReason}
      removeReason={removeReason}
      orderReason={modification.reason}
      loading={loading}
      orderLoading={orderLoading}
    />
  )
}

export default ModificationReason
