import styled from "styled-components"

type StyledProps = {
  $padding?: string
}

export const ProductParentRowContainer = styled.div<StyledProps>`
  padding: ${p => p.$padding ?? "2rem 1rem"};
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 0.5rem;
  border-radius: 0.6rem;
  position: relative;

  span {
    display: flex;
    align-items: center;
  }

  img {
    height: 3rem;
    margin-right: 0.5rem;
  }

`

export const CloseIcon = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: ${p => p.theme.colors.grey} 0.1rem solid;
    height: 2.3rem;
    width: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
        background-color: ${p => p.theme.colors.background};
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`

export const ProductParentRowContainerBlur = styled(ProductParentRowContainer)`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`
