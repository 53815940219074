import React from "react"
import { ApolloError } from "@apollo/client"
import { AdyenWebhook } from "lib/types/generated/graphql-types"

import { Container, EmptyList, Wrapper } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import { AdyenWebhookTableRow, getLoadingRow } from "./AdyenWebhookTableRow"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as Code } from "images/icons/code.svg"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  webhooks: AdyenWebhook[]
  loading: boolean
  error?: ApolloError
}

export const AdyenWebhookTable = ({ webhooks, loading, error }: Props) => {
  const tableHeader = (
    <TableHeader iconHeader={true}>
      <div>Identifier</div>
      <div className="hideOnSmallScreens">Username</div>
      <div>Password</div>
      <div className="hideOnSmallScreens hideOnMediumScreens">Created</div>
      <div className="hideOnSmallScreens hideOnMediumScreens">Updated</div>
    </TableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />
  if (webhooks?.length < 1)
    return (
      <EmptyList>
        <Code />
        <p> No webhooks configured</p>
      </EmptyList>
    )
  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Adyen webhooks. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...webhooks].map((webhook) => (
          <AdyenWebhookTableRow key={webhook.id} webhook={webhook} />
        ))}
      </Container>
    </Wrapper>
  )
}
