import styled from "styled-components"
import TableRowSimple from "../../../Ui/Table/TableRowSimple"

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  button {
    position: absolute;
    right: 0;
    bottom: 2rem;

    svg {
      margin-left: 1rem;
    }
  }
`

export const Row = styled(TableRowSimple)`
  min-height: 7rem;
  padding: 1.5rem 2rem;
  margin: 0 0 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    cursor: initial;
    background: ${p => p.theme.colors.white};
  }
`

export const ProductImage = styled.img`
  width: 5rem !important;
  display: block;
  flex-shrink: 0;
  border-radius: 0.6rem;
  align-self: flex-start;
`

export const Id = styled.p`
  font-size: 1.1rem;
  margin-top: 0.5rem;
  border: 0.1rem solid ${p => p.theme.colors.grey};
  background: ${p => p.theme.colors.greyLighter};
  border-radius: 0.4rem;
  width: fit-content;
  padding: 0 0.5rem;

  span {
    color: ${p => p.theme.colors.greyDarker};
  }
`

export const RefundButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  button {
    width: 12rem;
    margin-bottom: 4rem;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`


export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  padding: 2rem;
  border-radius: 0.6rem;
`

export const ProviderStatus = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 2rem 0 1rem 0;

  div {
    display: flex;
    margin-bottom: 1rem;
  }

  span {
    width: 24rem;
    display: flex;
    justify-content: center;
  }
`

