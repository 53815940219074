import React, { Dispatch, SetStateAction } from "react"
import { CampaignGroup, CampaignGroupSearchHit } from "lib/types/generated/graphql-types"
import { ReactComponent as DownIcon } from "../../../images/icons/chevron-down.svg"

import { Container, Name, NameHeader, Wrapper } from "./CampaignGroupTable.styled"

import { EmptyList, Id } from "../../Ui/Table/Shared.styled"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as SGIcon } from "../../../images/icons/circle-nodes.svg"
import ActionButton from "../../Ui/Table/ActionButton"
import CampaignGroupRow from "./CampaignGroupRow"
import { Actions, Created, Date } from "../CampaignTable.styled"
import { CampaignFilter, GroupedCampaign } from "@lib/types/common"
import CampaignGroupSearchHitRow from "./CampaignGroupSearchHitRow"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  groupedCampaigns: GroupedCampaign[]
  campaignGroupsSearchHits: CampaignGroupSearchHit[]
  loading: boolean
  error: object | undefined
  setCampaignGroups: Dispatch<SetStateAction<CampaignGroup[]>>
  setGroupedCampaigns: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
  campaignFilter: CampaignFilter
}

function CampaignGroupTable({
  groupedCampaigns,
  loading,
  error,
  setGroupedCampaigns,
  campaignFilter,
  campaignGroupsSearchHits
}: Props) {
  const tableHeader = (
    <TableHeader iconHeader={true}>
      <NameHeader>Name</NameHeader>
      <div>Status</div>
      <Date>Start date</Date>
      <Date>End date</Date>
      <Created>Created</Created>
      <Actions></Actions>
    </TableHeader>
  )

  const getLoadingRow = () => {
    return (
      <>
        <DownIcon />
        <div>
          <div>
            <Name>Campaign group name</Name>
            <Id>Campaign group id</Id>
          </div>
        </div>
        <ActionButton mode="delete" />
      </>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={10} tableHeader={tableHeader} rows={getLoadingRow()} />
  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Campaign groups. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {groupedCampaigns?.length > 0 ? (
          <>
            {tableHeader}
            {groupedCampaigns?.map((groupedCampaign) => (
              <CampaignGroupRow
                key={groupedCampaign?.campaignGroup?.id}
                groupedCampaign={groupedCampaign}
                setGroupedCampaigns={setGroupedCampaigns}
                campaignFilter={campaignFilter}
              />
            ))}
            {campaignGroupsSearchHits.length > 0 &&
              campaignGroupsSearchHits.map((campaignGroupSearchHit) => (
                <CampaignGroupSearchHitRow
                  key={campaignGroupSearchHit.id}
                  campaignGroupSearchHit={campaignGroupSearchHit}
                  setGroupedCampaigns={setGroupedCampaigns}
                />
              ))}
          </>
        ) : (
          <EmptyList>
            <SGIcon />
            <p> No campaign groups found</p>
          </EmptyList>
        )}
      </Container>
    </Wrapper>
  )
}

export default CampaignGroupTable
