import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import styled from "styled-components"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import GENERATE_DISCOUNT_CODES from "graphql/mutations/discount/GenerateDiscountCodes"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import Input from "components/Ui/Form/Input"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import GeneratedCodesResult from "components/Discount/DiscountCode/GeneratedCodesResult"
import PLATFORM_CONFIG from "../../../graphql/queries/settings/platformConfig/platformConfig"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`

const FieldTitle = styled.span`
  font-weight: bold;
`

const DiscountRuleId = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

type Inputs = {
  discountCodeRuleId: string
  usageLimit: number
  numberOfCodes: number
  prefix: string
}

type Props = {
  discountCodeRuleId: string
  setGenerateCodes: Dispatch<SetStateAction<boolean>>
  refetch: () => void
}

const GenerateDiscountCodes = ({ discountCodeRuleId, setGenerateCodes, refetch }: Props) => {
  const [jobId, setJobId] = useState<string>("")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [maxNumberOfCodes, setMaxNumberOfCodes] = useState<number>(10000)

  useQuery(PLATFORM_CONFIG, {
    onCompleted: (data) =>
      setMaxNumberOfCodes(
        data.PlatformConfig.discount.externalApi.discountManagementQuotas.maxNumberOfCodes
      )
  })

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Inputs>()

  const [createGenerateCodes, { loading }] = useMutation(GENERATE_DISCOUNT_CODES, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Discount code generation started",
          type: "success"
        })
      )
      setJobId(data.createGenerateCodes.jobId)
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  if (loading) return <EditSidebar loading={true} />

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    createGenerateCodes({
      variables: {
        ...data,
        discountCodeRuleId
      }
    })
  }

  const closeSidebar = () => {
    dispatch(hideEditSidebar())
    setGenerateCodes(false)
    if (jobId) refetch()
    setJobId("")
  }

  return (
    <EditSidebar cancelEvent={() => closeSidebar()}>
      {jobId ? (
        <>
          <EditSidebarHeader
            title="Generated discount codes"
            id={jobId}
            cancelEvent={() => {
              closeSidebar()
            }}
          />
          <GeneratedCodesResult jobId={jobId} />
        </>
      ) : (
        <form>
          <EditSidebarHeader title="Generate discount codes" cancelEvent={() => closeSidebar()}>
            <PrimaryButton type="submit" handleClick={handleSubmit(onSubmit)} loading={loading}>
              Generate
            </PrimaryButton>
          </EditSidebarHeader>
          <Row>
            <FieldTitle>Discount rule id:</FieldTitle>
            <DiscountRuleId
              onClick={() => {
                dispatch(hideEditSidebar())
                navigate(`/discounts/discount-code-rules/${discountCodeRuleId}`)
              }}
            >
              {discountCodeRuleId}
            </DiscountRuleId>
          </Row>
          <br />
          <Controller
            name="prefix"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Aa - Zz, 0-9, _-"
                label="Prefix *"
                errors={errors}
                {...field}
              />
            )}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_-]+$/,
                message: "Only characters Aa-Zz, 0-9 and _- are allowed. No spaces"
              }
            }}
            control={control}
          />
          <Controller
            name="usageLimit"
            render={({ field }) => (
              <Input type="number" label="Usage limit *" errors={errors} {...field} />
            )}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              },
              min: {
                value: 1,
                message: "Min value is 1"
              }
            }}
            control={control}
          />
          <Controller
            name="numberOfCodes"
            render={({ field }) => (
              <Input type="number" label="Number of codes *" errors={errors} {...field} />
            )}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              },
              min: {
                value: 1,
                message: "Min value is 1"
              },
              max: { value: maxNumberOfCodes, message: `Max value is ${maxNumberOfCodes}` }
            }}
            control={control}
          />
        </form>
      )}
      <hr />
    </EditSidebar>
  )
}

export default GenerateDiscountCodes
