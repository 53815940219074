import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Container } from "./DataCard.styled"
import DataCard from "./DataCard"
import { Currency } from "helpers/money"
import DineroFactory from "dinero.js"
import Dinero from "dinero.js"
import { CurrencyRate } from "@lib/types/generated/graphql-types"
import GET_CURRENCY_RATES from "../../graphql/queries/currencies/GetCurrencyRates"
import { useLazyQuery } from "@apollo/client"

interface DataObj {
  name: string
  value: number
}

interface AnalyticsOrdersOverviewProps {
  timeZone: string
  currencyCode: string
  totalOrders: number
  data: DataObj[]
  filterCurrencies: string[]
}

export const AnalyticsOrdersOverview = (
  {
    totalOrders,
    data,
    currencyCode,
    filterCurrencies
  }: AnalyticsOrdersOverviewProps) => {
  if (filterCurrencies?.length === 0) {
    return
  }
  const navigate = useNavigate()

  const [totalSales, setTotalSales] = useState<DineroFactory.Dinero>()
  const [averageSalesPerOrder, setAverageSalesPerOrder] = useState<DineroFactory.Dinero>()

  const [getCurrencyRates, { data: currencyRates, loading }] = useLazyQuery(GET_CURRENCY_RATES, {
    variables: { baseCurrencyCode: currencyCode, filterCurrencies: filterCurrencies }
  })

  useEffect(() => {
    async function convertTotalSales() {
      const totalSales = data.reduce(
        async (total, item) => {
          const Amount = Currency(item.name as DineroFactory.Currency)
          const money = Amount(item.value)

          if (item.name !== currencyCode) {
            const result = await getCurrencyRates({
              variables: {
                baseCurrencyCode: item.name,
                filterCurrencies: filterCurrencies
              }
            })

            const rates = {
              rates: {
                ...Object.fromEntries(
                  result.data.getCurrencyRates.rates.map((i: CurrencyRate) => [i.currencyCode, i.rate])
                )
              }
            }

            const convertedMoney = await money.convert(currencyCode, {
              endpoint: new Promise((resolve) => resolve(rates))
            })
            return (await total).add(convertedMoney)
          }

          return (await total).add(money)
        },
        Promise.resolve(Dinero({ amount: 0, currency: currencyCode as DineroFactory.Currency }))
      )

      setTotalSales(await totalSales)
    }

    convertTotalSales()
  }, [currencyCode, data, currencyRates])

  useEffect(() => {
    setAverageSalesPerOrder(Dinero({ amount: 0, currency: currencyCode as DineroFactory.Currency }))
    if (!totalSales || totalOrders === 0) {
      return
    }
    const average = totalSales?.divide(totalOrders)
    setAverageSalesPerOrder(average)
  }, [totalSales, data])

  const cards = [
    {
      label: "Total Orders",
      value: totalOrders,
      onClick: () => navigate("/orders")
    },
    {
      label: "Total Sales",
      value: Math.round(totalSales?.toUnit() ?? 0),
      currency: currencyCode,
      onClick: () => navigate("/orders")
    },
    {
      label: "Average Sale",
      value: Math.round(averageSalesPerOrder?.toUnit() ?? 0),
      currency: currencyCode,
      onClick: () => navigate("/orders")
    }
  ]

  return (
    <Container className={loading ? "blur" : ""}>
      {cards.map((card, index) => (
        <DataCard
          key={index}
          label={card.label}
          value={card.value}
          currency={card.currency}
          handleOnClick={card.onClick}
        />
      ))}
    </Container>
  )
}
