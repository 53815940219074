import { OrderHistoryLogEntry } from "@lib/types/generated/graphql-types"

export const formatOperation = (operation: string) => {
  switch (operation) {
    case "DELIVERY":
      return "Delivery"
    case "REFUND":
      return "Refund"
    case "RELEASE":
      return "Release"
    case "COMPENSATION":
      return "Compensation"
    case "CANCELLATION":
      return "Cancellation"
    case "MODIFY_ORDER_LINES":
      return "Modification"
    default:
      return operation
  }
}

export const getMessage = (entry: OrderHistoryLogEntry) => {
  if (entry.type === "ADD_CUSTOM_STATE") return "Added custom state"
  if (entry.type === "REMOVE_CUSTOM_STATE") return "Removed custom state"
  if (entry.type === "ADD_COMMENT") return "Added comment"
  return entry.message
}

export const getFormattedState = (state: string) => {
  switch (state) {
    case "FULLY_DELIVERED":
      return "Delivered"
    case "PARTIALLY_DELIVERED":
      return "Delivered in part"
    case "PARTIALLY_REFUNDED":
      return "Refunded in part"
    case "FULLY_REFUNDED":
      return "Refunded"
    case "FULLY_COMPENSATED":
      return "Compensated"
    case "PARTIALLY_COMPENSATED":
      return "Compensated in part"
    case "CANCELLED":
      return "Cancelled"
    case "PLACED":
      return "Placed"
    case "RELEASED":
      return "Released"
    default:
      return state
  }
}
