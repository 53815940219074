import React from "react"
import {
  ProductVariantSearchHit,
  ProductVariantSearchHits
} from "@lib/types/generated/graphql-types"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import {
  Container,
  EmptyList,
  HeaderName,
  Id,
  Name,
  Product,
  ProductImage,
  ProductImageContainer,
  StatusContainer,
  StyledTableHeader
} from "./Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"
import ErrorMessage from "../Ui/Messages/ErrorMessage"
import Status from "components/Ui/Status"

import { ReactComponent as ProductIcon } from "images/icons/bags-shopping.svg"
import placeholder from "images/placeholder.svg"
import { handleImageError } from "helpers/image"

type Props = {
  productData: ProductVariantSearchHits | undefined
  loading: boolean
  searchQuery: string
  error: object | undefined
}

export const ProductVariantSearchTable = ({ productData, loading, error, searchQuery }: Props) => {
  const navigate = useNavigate()
  const tableHeader = (
    <StyledTableHeader>
      <HeaderName>Name</HeaderName>
      <StatusContainer className="hideOnSmallScreens">Status</StatusContainer>
      <div className="hideOnSmallScreens">Created</div>
      <div className="hideOnSmallScreens">Updated</div>
    </StyledTableHeader>
  )

  const getRows = (productVariant?: ProductVariantSearchHit) =>
    productVariant ? (
      <TableRow
        key={productVariant.id}
        handleOnClick={() => {
          navigate(`/products/${productVariant?.productParentId}`)
        }}
      >
        <ProductImageContainer>
          <ProductImage src={productVariant?.imageUrl as string} onError={handleImageError} />
        </ProductImageContainer>
        <Product>
          <Name>{productVariant.name}</Name>
          <Id>{productVariant.id}</Id>
        </Product>
        <StatusContainer className="hideOnSmallScreens">
          {productVariant.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
        </StatusContainer>
        <div className="hideOnSmallScreens">
          {dayjs(productVariant.created).format("YYYY-MM-DD, HH:MM")}
        </div>
        <div className="hideOnSmallScreens">
          {dayjs(productVariant.updated).format("YYYY-MM-DD, HH:MM")}
        </div>
      </TableRow>
    ) : (
      <>
        <ProductImageContainer>
          <ProductImage src={placeholder} />
        </ProductImageContainer>
        <Product>
          <Name>Product name</Name>
          <Id>Product id</Id>
        </Product>
        <StatusContainer className="hideOnSmallScreens">
          <Status status="Default" />
        </StatusContainer>
        <div className="hideOnSmallScreens">
          <p>{dayjs("2000-00-00").format("YYYY-MM-DD")}</p>
        </div>
        <div className="hideOnSmallScreens">
          <p>{dayjs("2000-00-00").format("YYYY-MM-DD")}</p>
        </div>
      </>
    )

  if (loading && !searchQuery)
    return (
      <>
        <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getRows()} />
      </>
    )

  return (
    <Container>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading product variants. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {productData && productData?.hits?.length > 0 ? (
        <>
          {tableHeader}
          <div>{productData.hits.map((product) => getRows(product))}</div>
        </>
      ) : (
        <EmptyList>
          <ProductIcon />
          <p> No products found</p>
        </EmptyList>
      )}
    </Container>
  )
}
