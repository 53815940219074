import styled from "styled-components"

export const Container = styled.div`
border: 0.1rem solid ${p => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  padding: 1rem;
  margin-top: 2rem;

  h4 {
    margin: 0;
  }
`
