import type React from "react"
import { Content, Wrapper } from "./TopProductsList.styled"
import { TopProducts } from "./TopProducts"
import type { bucket } from "../../views/Dashboards/DashboardSalesAggregations"
import TopProductsFilters from "./TopProductsFilters"

type Props = {
  productParentNames?: { [productId: string]: string; }
  data: bucket[]
  loading: boolean
  error: object | undefined
}

const TopProductsList: React.FC<Props> = ({ data, loading, error, productParentNames }) => {
  return (
    <Wrapper className={loading ? "blur" : ""}>
      <Content>
        <TopProductsFilters />
        <TopProducts
          productParentNames={productParentNames}
          topProducts={data}
          loading={loading}
          error={error} />
      </Content>
    </Wrapper>
  )
}

export default TopProductsList
