import gql from "graphql-tag"

const GET_EXTERNAL_EVENTS_DESTINATION_API = gql`
  query getExternalEventsDestinationAPI($destName: String!) {
    getExternalEventsDestinationAPI(destName: $destName) {
      active
      created
      endpoint
      filters {
        ruleName
        pattern {
          detailTypes
        }
      }
      name
      updated
    }
  }
`

export default GET_EXTERNAL_EVENTS_DESTINATION_API
