import React, { Dispatch, ReactNode, SetStateAction} from "react"
import Select from "react-select"
import { Common } from "@lib/types"
import { customStyles, Label, SelectContainer } from "./SingleSelect.styled"


type Props = {
  options: Common.Option[]
  // eslint-disable-next-line
  setValue: Dispatch<SetStateAction<any>>
  value?: Common.Option | null
  placeholder?: string
  label?: string | ReactNode
  formatOptionLabel?: (data: Common.Option) => ReactNode
}

const SingleSelectSimple= (
  (
    {
      options,
      setValue,
      value,
      placeholder,
      label,
      formatOptionLabel,
      ...props
    }: Props
  ) => {
    return (
      <SelectContainer invalid={false} {...props}>
        {label && <Label>{label}</Label>}
        <Select
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e?.value)}
          options={options}
          formatOptionLabel={formatOptionLabel}
          styles={customStyles}
          isSearchable={false}
        />
      </SelectContainer>
    )
  }
)

export default SingleSelectSimple
