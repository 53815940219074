import React, { useState } from "react"
import styled from "styled-components"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as DescriptionIcon } from "images/icons/align-left.svg"
import { ReactComponent as NameIcon } from "images/icons/spell-check.svg"
import { Item, Label, List, Value } from "../Ui/List/List"

type StyledProps = {
  $expanded: boolean
}

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 3rem;
  position: relative;
`

const Arrow = styled(DownIcon)<StyledProps>`
  width: 1.2rem !important;
  margin-left: 1rem;
  transition: transform 0.2s;
  transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
`

const Tab = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => (p.$expanded ? "none" : `0.1rem solid ${p.theme.colors.greyLight}`)};

  svg {
    width: 1.6rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.turquoiseDark};

    svg {
      fill: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

type Props = {
  attributes: string  
  name: boolean
}

export const ProductVariantDisplayAttributes = ({ attributes, name }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const translations = JSON.parse(attributes)

  return (
    <>
      <Tab onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        {name ? <NameIcon /> : <DescriptionIcon />}
        <h4>
          {name ? "Display names" : "Display descriptions"} <Arrow $expanded={expanded} />
        </h4>
      </Tab>
      {expanded && (
        <Block>
          <List>
            {Object.keys(translations).length > 0 ? (
              Object.keys(translations).map((key) => (
                <Item key={key} flexibleHeight showAsColumn>
                  <Label>{key.toUpperCase()}</Label>
                  <Value>{translations[key]}</Value>
                </Item>
              ))
            ) : (
              <p> {name ? "No display names set" : "No display descriptions set"}</p>
            )}
          </List>
        </Block>
      )}
    </>
  )
}
