import React, { useState } from "react"
import styled from "styled-components"

import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import { ReactComponent as CheckIcon } from "images/icons/check.svg"

const Container = styled.div`
  display: inline-flex;
  font-size: 1.2rem;

  svg {
    height: 1.6rem;
    cursor: pointer;
    transition: all 0.2s;
    margin: 0 0.5rem 0 1rem;

    &:hover {
      fill: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

const Copied = styled.div`
  display: flex;
  align-items: center;
  height: 1.6rem;

  svg {
    margin-right: 0 !important;
    fill: ${(p) => p.theme.colors.success.background};
  }
`

type Props = {
  string: string | undefined | null
}

const CopyButton = ({ string }: Props) => {
  const [copyActive, setCopyActive] = useState(false)

  if (!string) return null

  return (
    <Container>
      {copyActive ? (
        <Copied>
          <CheckIcon /> Copied
        </Copied>
      ) : (
        <CopyIcon
          onClick={(e) => {
            setCopyActive(true)
            e.stopPropagation()
            setTimeout(() => setCopyActive(false), 3000)
            navigator.clipboard.writeText(string)
          }}
        />
      )}
    </Container>
  )
}

export default CopyButton
