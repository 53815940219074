import React from "react"
import { Content, Wrapper } from "./TopProductsList.styled"
import { TopDiscountCodes } from "./TopDiscountCodes"
import { topDiscountCodesData } from "../../views/Dashboards/DashboardSalesAggregations"
import TopDiscountCodesFilters from "./TopDiscountCodesFilters"

type Props = {
  data: topDiscountCodesData[]
  loading: boolean
  error: object | undefined
}

const TopDiscountCodesList = ({ data, loading, error }: Props) => {
  return (
    <Wrapper>
      <Content>
        <TopDiscountCodesFilters />
        <TopDiscountCodes data={data} loading={loading} error={error}></TopDiscountCodes>
      </Content>
    </Wrapper>
  )
}

export default TopDiscountCodesList
