import React from "react"
import * as graphql from "@lib/types/generated/graphql-types"
import { OrderPayment, OrderPaymentQliroOne } from "@lib/types/generated/graphql-types"
import { v4 as uuid } from "uuid"

import { Title } from "./OrderLines.styled"
import { Orderline } from "./OrderLine"

import { ReactComponent as OrderLineIcon } from "images/icons/cart-shopping.svg"
import { GiftLine } from "./GiftLine"
import { ReactComponent as GiftIcon } from "images/icons/gift.svg"
import { ReactComponent as CardIcon } from "images/icons/gift-card.svg"
import { GiftCardProductLine } from "./GiftCardProductLine"
import UpsellOrderLine from "./UpsellOrderLine"

type Props = {
  order: graphql.Order
  orderHistory: graphql.OrderHistory
}

export const OrderLines = ({ order, orderHistory }: Props) => {
  const { orderLines, gifts, GiftCardProduct } = order

  function isOrderGiftCardProductRetain24(
    object: graphql.OrderGiftCardProduct
  ): object is graphql.OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  function isOrderPaymentQliroOne(object: OrderPayment): object is OrderPaymentQliroOne {
    return object.providerName === "QliroOne"
  }

  const upsellOrderLines = () => {
    if (isOrderPaymentQliroOne(order.Payment)) {
      const orderPayment = order.Payment
      const upsellOrderLines = order?.orderLines?.filter((ol) =>
        orderPayment?.upsell?.items?.some(
          (item) => ol.productVariantId === item.productVariantId && ol.quantity === item.quantity
        )
      )
      return upsellOrderLines ?? []
    }
    return []
  }

  const showUpsellOrderLines = () => {
    if (upsellOrderLines().length > 0) {
      return (
        upsellOrderLines().map((ol) => ol.productVariantId).length ===
        new Set(upsellOrderLines().map((ol) => ol.productVariantId)).size
      )
    }
    return false
  }

  const upsellOnOrderLine = (orderLineId: string) => {
    if (showUpsellOrderLines()) {
      return upsellOrderLines()
        .map((ol) => ol.id)
        .includes(orderLineId)
    }
    return false
  }

  const displayUpsellSeparate = upsellOrderLines().length > 0 && !showUpsellOrderLines()

  return (
    <>
      {orderLines && orderLines?.length > 0 && (
        <>
          <Title>
            <OrderLineIcon />
            Products
          </Title>
          {orderLines.map((orderLine: graphql.OrderLine) => (
            <Orderline
              key={uuid()}
              orderLine={orderLine}
              orderHistory={orderHistory}
              upsell={upsellOnOrderLine(orderLine.id)}
            />
          ))}
        </>
      )}
      {gifts && gifts?.length > 0 && (
        <>
          <Title>
            <GiftIcon />
            Gifts
          </Title>

          {gifts &&
            gifts.map((gift: graphql.OrderGift) => (
              <GiftLine key={uuid()} gift={gift} orderHistory={orderHistory} />
            ))}
        </>
      )}
      {isOrderGiftCardProductRetain24(GiftCardProduct) && (
        <>
          <Title>
            <CardIcon />
            Gift cards
          </Title>
          {GiftCardProduct?.giftCardProducts?.map((product) => (
            <GiftCardProductLine
              key={product.id}
              giftCardProductLine={product}
              order={order}
              orderHistory={orderHistory}
            />
          ))}
        </>
      )}
      {displayUpsellSeparate && isOrderPaymentQliroOne(order.Payment) && (
        <>
          <h3>Purchased with upsell: </h3>
          {order?.Payment?.upsell?.items.map((item) => <UpsellOrderLine upsellItem={item} />)}
        </>
      )}
    </>
  )
}
