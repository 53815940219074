import { getPaymentMethodDisplayName } from "helpers/getPaymentDisplayName"

export interface topList {
  topList: topListBuckets
}

export interface topListBuckets {
  buckets: bucket[]
}

export interface bucket {
  topProductDetails?: {
    hits: {
      hits: {
        _source: topProductDetailsData
      }[]
    }
  }
  totalQuantity: totalQty
  discountTotal?: totalQty
  priceTotal?: totalQty
}

export interface totalQty {
  value: number
}

export interface topDiscountCodes {
  buckets: topDiscountCodesData[]
}

export interface topDiscountCodesData {
  doc_count: number
  key: string
  subTotal: totalQty
  discountTotal: totalQty
}

export interface topProductDetailsData {
  productVariantId: string
  productParentId: string
  name: string
  quantity: number
  imageUrl: string
  distributedTotalPriceAmount: number
}

export interface addData {
  totalValuesPerCurrencyCode: buckets
  totalValuesPerStoreGroup: buckets
  totalByPaymentMethod: buckets
  totalByPaymentProvider: buckets
  topProductVariants: topList
  topProductVariantsFilterByCampaign: {
    filtered_orderLines: topList
  }
  topProductVariantsFilterByNonDiscount: {
    filtered_orderLines: topList
  }
  topProductParents: topList
  topDiscountCodes: topDiscountCodes
}

export interface buckets {
  buckets: totalValue[]
}

export interface totalValue {
  key: string
  doc_count: number
  grandTotal: value
  taxTotal: value
}

export interface value {
  value: number
}

export function groupDiscountCodesByUppercaseKey(data: topDiscountCodesData[]): topDiscountCodesData[] {
  // Create a map to group by uppercase keys
  const groupedMap = new Map<string, topDiscountCodesData>()

  // Process each item
  data.forEach(item => {
    const uppercaseKey = item.key.toUpperCase()

    if (groupedMap.has(uppercaseKey)) {
      // If this uppercase key already exists, merge the data
      const existingItem = groupedMap.get(uppercaseKey)!

      // Update counts and totals
      existingItem.doc_count += item.doc_count
      existingItem.subTotal.value += item.subTotal.value
      existingItem.discountTotal.value += item.discountTotal.value
    } else {
      // Create a new entry with the uppercase key
      groupedMap.set(uppercaseKey, {
        ...item,
        key: uppercaseKey
      })
    }
  })

  // Convert map back to array
  return Array.from(groupedMap.values())
}

export function groupByPaymentMethodName(data: totalValue[]) {
  const groupedByName = data.reduce(
    (result: { [key: string]: number }, x) => {
      const name = getPaymentMethodDisplayName(x.key)
      if (result[name]) {
        result[name] += x.doc_count
      } else {
        result[name] = x.doc_count
      }
      return result
    },
    {}
  )

  return Object.entries(groupedByName || {})
    .map(([name, value]) => ({
      name,
      value
    }))
    .sort((a, b) => b.value - a.value)
}
