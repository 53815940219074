import gql from "graphql-tag"

const GET_RELEASE = gql`
  query getRelease($releaseId: ID!) {
    Order {
      release(releaseId: $releaseId) {
        id
        started
        failed
        completed
        created
        restarts
        restarted
        revision
        releasedPayment {
          reference
        }
        orderLines {
          currencyCode
          orderLineId
          quantity
          taxPercentage
          taxPercentageDecimals
          totalAmount
          totalDiscountAmount
          totalTaxAmount
        }
        reason {
          cause
          code
        }
        gifts {
          giftId
          quantity
        }
        giftCardProvider {
          providerName
          providerId
          status {
            current
            history {
              message
              status
              timestamp
              type
            }
          }
        }
        giftCards {
          amount
          giftCardId
          status
          currencyCode
        }
        giftCardProductProvider {
          providerId
          providerName
          status {
            current
            history {
              message
              status
              timestamp
              type
            }
          }
        }
        giftCardProducts {
          cancelErrorMessage
          cancelErrorMessage
          cancelTransactionId
          createFailedReason
          createId
          createTransactionId
          currencyCode
          giftCardProductId
          priceAmount
          status
        }
        bonusProvider {
          providerName
          providerId
          status {
            current
            history {
              message
              status
              timestamp
              type
            }
          }
        }
        bonus {
          amount
          reservationId
        }
        shippingProvider {
          providerName
          providerId
          status {
            current
            history {
              message
              status
              timestamp
              type
            }
          }
        }
        paymentProvider {
          providerName
          providerId
          status {
            current
            history {
              message
              status
              timestamp
              type
            }
          }
        }
        voucherProvider {
          providerName
          providerId

        }
        shippingFees {
          currencyCode
          shippingFeeId
          totalAmount
        }
      }
    }
  }
`

export default GET_RELEASE
