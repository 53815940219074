import React, { RefObject, useState } from "react"
import { FrontendOutcome, Outcomes } from "@lib/types/discount"
import Input from "../../../Ui/Form/Input"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { AddedTag, InputWrapper, RemoveTag, Tags } from "./ShippingTags.styled"
import { ReactComponent as TagIcon } from "images/icons/tag.svg"
import { ReactComponent as XmarkIcon } from "images/icons/xmark-thick.svg"

type Props = {
  outcome?: FrontendOutcome
  addOutcome: (data: Outcomes) => void
  submitRef: RefObject<HTMLButtonElement | null>
}

export const ShippingTags = ({ outcome, addOutcome, submitRef }: Props) => {
  const [inputTag, setInputTag] = useState<string>()
  const [tags, setTags] = useState<string[]>(outcome?.data?.shippingTags ?? [])

  const addTag = () => {
    if (inputTag) {
      setTags((prev) => [...prev, inputTag])
      setInputTag("")
    }
  }

  const removeTag = (removeTag: string) => {
    setTags((prev) => prev.filter((tag) => tag !== removeTag))
  }

  const onSubmit = () => {
    addOutcome({ shippingTags: tags })
  }

  return (
    <div>
      <hr />
      <Tags>
        {tags.map((tag) => (
          <AddedTag key={tag}>
            <span>
              <TagIcon />
              {tag}
            </span>
            <RemoveTag onClick={() => removeTag(tag)}>
              <XmarkIcon />
            </RemoveTag>
          </AddedTag>
        ))}
      </Tags>
      <InputWrapper>
        <Input
          name={"tag"}
          placeholder={"Input shipping tag"}
          value={inputTag}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputTag(e.target.value)}
        />
        <PrimaryButton disabled={!inputTag} handleClick={addTag} type={"button"}>
          {" "}
          <TagIcon />
          Add
        </PrimaryButton>
        <button
          onClick={() => onSubmit()}
          ref={submitRef}
          type="button"
          style={{ display: "none" }}
        />
      </InputWrapper>
    </div>
  )
}
