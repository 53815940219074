import React from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { InputKlarnaCheckoutConfig, KlarnaCheckoutConfig } from "lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { Common } from "lib/types"
import { useConfig } from "../../../configuration/useConfig"

import { SidebarDivider } from "components/Dashboard/Shared.styled"

import SingleSelect from "components/Ui/Form/SingleSelect"
import Input from "components/Ui/Form/Input"
import Checkbox from "components/Ui/Form/Checkbox"
import { TooltipIcon } from "components/Ui/TooltipIcon"

const BaseUrlSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

const CheckboxWrapper = styled.div`
  display: flex;
  padding-bottom: 1rem;

  div {
    padding-top: 0;
  }
`

interface ExtendedInputKlarnaConfig extends InputKlarnaCheckoutConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputKlarnaConfig>
  errors: FieldErrors
  setValue: UseFormSetValue<ExtendedInputKlarnaConfig>
  showId?: boolean
  defaultValues?: KlarnaCheckoutConfig
}

export const KlarnaCheckoutConfigInputs = ({
  control,
  errors,
  setValue,
  showId = false,
  defaultValues
}: Props) => {
  const { isTestEnvironment } = useConfig()
  const updateBaseUrl = (baseUrl: Common.Option) => {
    setValue("baseUrl", baseUrl.value)
  }

  const baseUrlOptions = isTestEnvironment
    ? [
        {
          value: "https://api.playground.klarna.com/",
          label: "Europe | api.playground.klarna.com"
        },
        {
          value: "https://api-na.playground.klarna.com/",
          label: "North America | api-na.playground.klarna.com"
        },
        {
          value: "https://api-oc.playground.klarna.com/",
          label: "Oceania | api-oc.playground.klarna.com"
        }
      ]
    : [
        {
          value: "https://api.klarna.com/",
          label: "Europe | api.klarna.com"
        },
        {
          value: "https://api-na.klarna.com/",
          label: "North America | api-na.klarna.com"
        },
        {
          value: "https://api-oc.klarna.com/",
          label: "Oceania | api-oc.klarna.com"
        }
      ]

  return (
    <>
      {showId && (
        <>
          <Controller
            name="id"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Identifier *"
                description="For internal use in Brink"
                placeholder="Aa - Zz, 0 - 9, -_."
                errors={errors}
              />
            )}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_.-]+$/,
                message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
              }
            }}
            defaultValue=""
          />
          <SidebarDivider />
        </>
      )}
      <CheckboxWrapper>
        <Checkbox
          name="klarnaShippingAssistantEnabled"
          label="Enable Klarna shipping assistant"
          control={control}
          defaultValue={defaultValues?.klarnaShippingAssistantEnabled ?? false}
        />
        <TooltipIcon
          content={
            "Before enabling Klarna Shipping Assistant, ensure that your Klarna Merchant ID is properly configured in the Klarna Merchant Portal and that your shipping selector (Nshift, Ingrid, etc.) is connected. See section `Shipping Assistant` in Klarna Docs or reach out to your technical support at Klarna."
          }
        />
      </CheckboxWrapper>
      <Controller
        name="merchantId"
        render={({ field }) => (
          <Input {...field} type="text" label="Klarna merchant ID *" errors={errors} />
        )}
        control={control}
        defaultValue={defaultValues?.merchantId ?? ""}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="username"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Klarna API username*" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="password"
        render={({ field }) => (
          <Input {...field} type="text" label="Klarna API password *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="baseUrl"
        render={({ field }) => (
          <BaseUrlSelect
            {...field}
            options={baseUrlOptions}
            setValue={updateBaseUrl}
            defaultValue={{
              label:
                baseUrlOptions.find((option) => option.value === defaultValues?.baseUrl)?.label ??
                "Select base URL",
              value: defaultValues?.baseUrl ?? ""
            }}
            label="Base URL *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
    </>
  )
}
