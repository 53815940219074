import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { PROVIDER_TYPE } from "lib/types/common"
import { Provider } from "lib/types/generated/graphql-types"

import { EmptyList } from "components/Ui/Table/Shared.styled"
import { Container, NameCell, Wrapper } from "./AdyenConfigTable.styled"

import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import { AdyenConfigTableRow, getLoadingRow } from "./AdyenConfigTableRow"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as AdyenIcon } from "images/providers/icons/adyen.svg"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  providers: Provider[]
  error: object | undefined
  loading: boolean
  adyenConfigId: string
  setAdyenConfigId: Dispatch<SetStateAction<string>>
}

export const AdyenConfigTable = ({
  providers,
  adyenConfigId,
  setAdyenConfigId,
  error,
  loading
}: Props) => {
  const [adyenProviders, setAdyenProviders] = useState<Provider[]>([])

  useEffect(() => {
    if (providers) {
      setAdyenProviders(providers?.filter((provider) => provider.type === PROVIDER_TYPE.ADYEN))
    }
  }, [providers])

  const tableHeader = (
    <TableHeader iconHeader={true}>
      <NameCell>Merchant account</NameCell>
      <div className="hideOnSmallScreens hideOnMediumScreens">Environment</div>
      <div className="hideOnSmallScreens hideOnMediumScreens">Created</div>
      <div className="hideOnSmallScreens hideOnMediumScreens">Updated</div>
    </TableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (adyenProviders?.length < 1)
    return (
      <EmptyList>
        <AdyenIcon />
        <p> No configurations added</p>
      </EmptyList>
    )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Adyen settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...adyenProviders].map((adyenProvider) => (
          <AdyenConfigTableRow
            key={adyenProvider.id}
            id={adyenProvider.id}
            adyenConfigId={adyenConfigId}
            setAdyenConfigId={setAdyenConfigId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
