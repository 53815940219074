import React from "react"
import { Controller } from "react-hook-form"
import MultiSelect from "../../Ui/Form/MultiSelect"
import { ErrorMessage } from "@hookform/error-message"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import ALL_SHIPPING_PROVIDERS from "../../../graphql/queries/config/AllShippingProviders"
import { InputUpdateStoreMarket, Provider } from "@lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"

const InputSection = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

type Props = {
  defaultValue: Common.Option[] | undefined
  control: Control
  errors: FieldErrors
  setValue: UseFormSetValue<InputUpdateStoreMarket>
}

export const ShippingProviders = ({ defaultValue, control, errors, setValue }: Props) => {
  const { data } = useQuery(ALL_SHIPPING_PROVIDERS)

  const shippingProviderOptions = data?.getShippingProviders?.map((provider: Provider) => ({
    value: provider.id,
    label: provider.id
  }))

  const updateShippingProviders = (providers: Common.Option[]) =>
    setValue(
      "capabilities.shippingProviders",
      providers.map((provider) => ({
        id: provider.value,
        type: data?.getShippingProviders?.find((p: Provider) => p.id === provider.value)?.type
      }))
    )

  if (!shippingProviderOptions) return null

  return (
    <InputSection>
      <Label>Shipping providers</Label>
      <Controller
        name="capabilities.shippingProviders"
        render={() => (
          <MultiSelect
            defaultValue={defaultValue}
            options={shippingProviderOptions}
            setValue={updateShippingProviders}
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name="capabilities.shippingProviders" />
    </InputSection>
  )
}

export default ShippingProviders
