import React, { useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import ALL_SHIPPING_PROVIDERS from "graphql/queries/config/AllShippingProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { IngridConfigTable } from "components/Config/Ingrid/IngridConfigTable"
import { CreateIngridConfig } from "./CreateIngridConfig"
import { EditIngridConfig } from "./EditIngridConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as IngridLogo } from "images/providers/ingrid-logo.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageLogo = styled(IngridLogo)`
  height: 6rem;
`

export const IngridConfiguration = () => {
  const [ingridConfigId, setIngridConfigId] = useState<string>("")
  const { loading, data, refetch, error } = useQuery(ALL_SHIPPING_PROVIDERS)
  const shippingProviders = data?.getShippingProviders
  const dispatch = useAppDispatch()

  return (
    <Container>
      <PageHeader
        title={<PageLogo />}
        description={<>Handle your Ingrid configurations</>}
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!ingridConfigId && <CreateIngridConfig refetch={refetch} />}
      {ingridConfigId && (
        <EditIngridConfig
          ingridConfigId={ingridConfigId}
          refetch={refetch}
          setIngridConfigId={setIngridConfigId}
        />
      )}
      <IngridConfigTable
        providers={shippingProviders}
        error={error}
        setIngridConfigId={setIngridConfigId}
        loading={loading}
      />
    </Container>
  )
}
