import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"

import DELETE_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS from "../../../graphql/mutations/settings/externalEvents/DeleteExternalEventsDestinationEventBus"

import { Container, EmptyList, Wrapper } from "../../Ui/Table/Shared.styled"
import { StyledIconTableHeader, StyledTableRow, Name } from "./EventBusesTable.styled"

import { BrinkLoader } from "../../Ui/BrinkLoader"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import Popup from "../../Ui/Popup"

import { ReactComponent as AWSIcon } from "../../../images/icons/aws-brands-solid.svg"
import ActionButton from "../../Ui/Table/ActionButton"

type Props = {
  eventBuses: string[]
  loading: boolean
  error: object | undefined
  refetch: () => void
}

const EventBusesTable = ({ eventBuses, loading, error, refetch }: Props) => {
  const dispatch = useDispatch()
  const [deleteItem, setDeleteItem] = useState<string>("")

  const [deleteEventBus, { loading: deleteLoading }] = useMutation(
    DELETE_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: `Event bus ${deleteItem} is now deleted`,
            type: "success"
          })
        )
        setDeleteItem("")
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Event buses. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}

        {deleteItem && (
          <Popup
            title="Delete Event bus?"
            subtitle="Are you sure you want to delete the following:"
            buttonText="Yes, delete"
            item={{ itemId: undefined, itemName: deleteItem }}
            loading={deleteLoading}
            type="delete"
            handleOkClick={() =>
              deleteEventBus({
                variables: {
                  eventBusArn: deleteItem
                }
              })
            }
            handleCloseClick={() => setDeleteItem("")}
          />
        )}

        {loading && !error ? (
          <BrinkLoader />
        ) : (
          <>
            {eventBuses?.length === 0 ? (
              <EmptyList>
                <AWSIcon />
                <p>No Event buses added</p>
              </EmptyList>
            ) : (
              <>
                <StyledIconTableHeader>
                  <div>Name</div>
                </StyledIconTableHeader>
                {eventBuses?.map((eventBus: string) => (
                  <StyledTableRow key={uuid()}>
                    <AWSIcon />
                      <Name>{eventBus}</Name>
                    <ActionButton
                      handleOnClick={() => setDeleteItem(eventBus)}
                      mode={"delete"}
                    />
                  </StyledTableRow>
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default EventBusesTable
