import { OrderModificationOrderLines } from "@lib/types/generated/graphql-types"
import { Info } from "./Operations.styled"
import Money from "../../../Money"
import DineroFactory from "dinero.js"

type Props = {
  modification: OrderModificationOrderLines | undefined
  status: string
  currencyCode: string
}

const ModificationInfo = ({ modification, status, currencyCode }: Props) => {

  if (status === "MODIFY_ORDER_LINES_PAYMENT_SUCCESS") {
    return (
      <Info>
        <div>
          Old total:{" "}
          <Money
            amount={modification?.oldTotals?.grandTotal ?? 0}
            currencyUnit={currencyCode as DineroFactory.Currency}
          />
        </div>
        <div>
          New total:{" "}
            <Money
              amount={modification?.newTotals?.grandTotal ?? 0}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
        </div>
      </Info>
    )
  }
  return null
}

export default ModificationInfo
