import React, { ReactNode } from "react"
import styled, { css } from "styled-components"

type StyledProps = {
  collapse?: boolean
}

const Container = styled.div<StyledProps>`
  flex: 1;

  ul {
    ${(p) =>
      !p.collapse &&
      css`
        background: ${(p) => p.theme.colors.white};
        position: relative;
      `}
  }

  h2 {
    display: flex;
    align-items: center;
    margin-top: 0;

    svg {
      height: 2.8rem;
      width: 2.8rem;
      margin-right: 1rem;
    }
  }
`

type Props = {
  children: ReactNode | ReactNode[] | undefined
  collapse?: boolean
}

export const ProductBlock = ({ children, collapse }: Props) => {
  return <Container collapse={collapse}>{children}</Container>
}
