import styled, { css } from "styled-components"

type StyledProps = {
  isInactive?: boolean
  wrapContent?: boolean
  smallButtons?: boolean
  disableHover?: boolean
}

export const Container = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-top: none;
  background: ${(p) => p.theme.colors.white};
  opacity: ${(p) => (p.isInactive ? "0.4" : "1")};
  padding: 0 2rem;

  ${(p) =>
    !p.disableHover &&
    css`
      &:hover {
        cursor: pointer;
        background: ${(p) => p.theme.colors.greyLightest};
      }
    `}

  h4 {
    width: 100%;
    padding-left: 1rem;
    font-size: 1.4rem;
    margin: 0;
  }

  > div > div {
    flex: 1;
    border-left: 0.1rem solid rgba(223, 227, 232, 01);
    padding-left: 1.5rem;
    height: 5rem;
    display: flex;
    align-items: center;
  }
`

export const Content = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  flex-wrap: ${(p) => (p.wrapContent ? "wrap" : "nowrap")};
  width: 100%;
  padding: 0.8rem 0;
  min-height: 6rem;

  > img,
  > svg {
    width: 2.4rem;
    margin-right: 2rem;
    display: block;
    flex-shrink: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 2rem;
  align-items: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0;
  align-items: center;
  font-size: 1.3rem;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: initial !important;
`

export const ActionButton = styled.button<StyledProps>`
  width: ${(p) => (p.smallButtons ? "3rem" : "4rem")};
  height: ${(p) => (p.smallButtons ? "3rem" : "4rem")};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: ${(p) => (p.smallButtons ? "1.5rem" : "2rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 1rem;

  svg {
    height: ${(p) => (p.smallButtons ? "1.4rem" : "1.8rem")};
    margin-right: 0;
    fill: ${(p) => p.theme.colors.greyDark};
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.colors.white};
    }
  }
`

export const EditButton = styled(ActionButton)`
  &:hover {
    background: ${(p) => p.theme.colors.turquoiseDark};
    border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
  }
`

export const DeleteButton = styled(ActionButton)`
  &:hover {
    background: ${(p) => p.theme.colors.error.background};
    border: 0.1rem solid ${(p) => p.theme.colors.error.background};
  }
`
