import React, { useState } from "react"
import styled from "styled-components"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { CapabilityProvider } from "lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"

import GET_VOYADO_CONFIGS from "graphql/queries/config/Providers/Voyado/GetVoyadoConfigs"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { VoyadoConfigTable } from "components/Config/Voyado/VoyadoConfigTable"
import { CreateVoyadoConfig } from "./CreateVoyadoConfig"
import { EditVoyadoConfig } from "./EditVoyadoConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as VoyadoLogo } from "images/providers/voyado-logo.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageLogo = styled(VoyadoLogo)`
  height: 6rem;
`

export const VoyadoConfiguration = () => {
  const [voyadoConfigId, setVoyadoConfigId] = useState<string>("")
  const [voyadoConfigurations, setVoyadoConfigurations] = useState<CapabilityProvider[]>([])

  const dispatch = useAppDispatch()

  const { error, loading } = useQuery(GET_VOYADO_CONFIGS, {
    onCompleted: (data) => setVoyadoConfigurations(data.getVoyadoConfigs)
  })

  return (
    <Container>
      <PageHeader
        title={<PageLogo />}
        description={<>Handle your Voyado configurations</>}
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!voyadoConfigId && <CreateVoyadoConfig setVoyadoConfigurations={setVoyadoConfigurations} />}
      {voyadoConfigId && (
        <EditVoyadoConfig
          voyadoConfigId={voyadoConfigId}
          setVoyadoConfigurations={setVoyadoConfigurations}
          setVoyadoConfigId={setVoyadoConfigId}
        />
      )}
      <VoyadoConfigTable
        voyadoConfigurations={voyadoConfigurations}
        error={error}
        setVoyadoConfigId={setVoyadoConfigId}
        loading={loading}
      />
    </Container>
  )
}
