import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { Provider } from "@lib/types/generated/graphql-types"
import { PROVIDER_TYPE } from "lib/types/common"

import { Container, EmptyList, Wrapper } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import { AvardaConfigTableRow, getLoadingRow } from "./AvardaConfigTableRow"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  providers: Provider[]
  error: object | undefined
  setEditId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const AvardaConfigTable = ({ providers, setEditId, error, loading }: Props) => {
  const [avardaProviders, setAvardaProviders] = useState<Provider[]>([])
  useEffect(() => {
    if (providers) {
      setAvardaProviders(providers?.filter((provider) => provider.type === PROVIDER_TYPE.AVARDA))
    }
  }, [providers])

  const tableHeader = (
    <TableHeader iconHeader={true}>
      <div>Identifier</div>
      <div>Base url</div>
      <div>Auth url</div>
      <div className="hideOnSmallScreens">Created</div>
      <div className="hideOnSmallScreens">Updated</div>
    </TableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (avardaProviders?.length < 1)
    return (
      <EmptyList>
        <p> No configurations added</p>
      </EmptyList>
    )
  if (!providers) return null

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Avarda configurations. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...avardaProviders].map((qliroOneProvider) => (
          <AvardaConfigTableRow
            key={qliroOneProvider.id}
            id={qliroOneProvider.id}
            setEditId={setEditId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
