import React, { useEffect, useState } from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import * as graphql from "@lib/types/generated/graphql-types"
import { CustomState, Order, OrderCustomState, OrderHistoryLogEntry } from "@lib/types/generated/graphql-types"
import { ApolloQueryResult, useQuery } from "@apollo/client"

import GET_STORE_GROUP from "graphql/queries/store/GetStoreGroup"

import Money from "components/Money"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import FlagIcon from "components/Ui/FlagIcon"
import DotLoader from "components/Ui/DotLoader"
import CopyButton from "components/Ui/CopyButton"

import { ReactComponent as Icon } from "images/icons/magnifying-glass.svg"
import { ReactComponent as TotalsIcon } from "images/icons/circle-dollar.svg"
import { ReactComponent as HistoryIcon } from "images/icons/rectangle-vertical-history.svg"

import Status from "../../../../components/Ui/Status"
import {
  Columns,
  OrderValue,
  StateStatus,
  StatusItem,
  StatusWrapper,
  Total
} from "./OrderOverview.styled"
import OrderHistoryLog from "../../../../components/Order/OrderHistoryLog/OrderHistoryLog"
import ALL_CUSTOM_STATES from "../../../../graphql/queries/settings/customState/AllCustomStates"
import { getFormattedState } from "components/Order/helpers"
import OrderCustomStates from "../../../../components/Order/OrderOverview/OrderCustomStates"
import { useSelector } from "react-redux"
import { selectUserAttributes } from "lib/store/services/auth/selectors"

type Props = {
  order: graphql.Order
  orderHistory: graphql.OrderHistory
  refetch: () => Promise<ApolloQueryResult<Order>>
}

const OrderOverview = ({ order, orderHistory, refetch }: Props) => {
  const [orderHistoryList, setOrderHistoryList] = useState<OrderHistoryLogEntry[]>(orderHistory.history)
  const [customStateList, setCustomStateList] = useState<OrderCustomState[]>(order?.status?.customStates ?? [])
  const userAttributes = useSelector(selectUserAttributes)

  const getCustomState = (id: string) =>
    customStates?.find((customState: CustomState) => customState.id === id)

  const addCustomStateToHistory = (customStateId: string) => {
    const orderState = getCustomState(customStateId)
    const entry = {
      actor: { name: userAttributes.email, type: "USER" },
      operation: "CUSTOM_STATE",
      operationId: customStateId,
      status: "NONE",
      timestamp: dayjs(),
      type: "ADD_CUSTOM_STATE"
    }
    const state = {
      id: orderState.id,
      type: orderState.type,
      operations: orderState.operations
    }
    setOrderHistoryList([...orderHistoryList, entry])
    setCustomStateList([...customStateList, state])
  }

  const removeCustomStateFromHistory = (id: string) => {
    const entry = {
      actor: { name: userAttributes.email, type: "USER" },
      operation: "CUSTOM_STATE",
      operationId: id,
      status: "NONE",
      timestamp: dayjs(),
      type: "REMOVE_CUSTOM_STATE"
    }
    setOrderHistoryList([...orderHistoryList, entry])
    setCustomStateList(customStateList.filter(state => state.id !== id))
  }

  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  const { data: customStateData } = useQuery(ALL_CUSTOM_STATES)
  const customStates = customStateData?.Order?.customStates?.states

  useEffect(() => {
    setOrderHistoryList(orderHistory.history)
    setCustomStateList(order?.status?.customStates ?? [])
  }, [orderHistory,order.status])

  if (!order) return null

  return (
    <>
      <Columns>
        <OrderBlock>
          <h2>
            <Icon />
            Overview
          </h2>
          <List>
            {order.status.orderStates && (
              <StatusItem>
                <Label>Order status:</Label>
                <Value>
                  <StatusWrapper>
                    {order.status.orderStates.map((state) => (
                      <StateStatus key={state}>
                        <Status status={getFormattedState(state)} />
                      </StateStatus>
                    ))}
                  </StatusWrapper>
                </Value>
              </StatusItem>
            )}
            {customStateList.length > 0 && (
              <StatusItem>
                <Label>Custom state:</Label>
                <Value>
                  <OrderCustomStates
                    customStates={customStateList}
                    getCustomState={getCustomState}
                    orderId={order.id}
                    refetch={refetch}
                    removeCustomStateFromHistory={removeCustomStateFromHistory}
                  />
                </Value>
              </StatusItem>
            )}
            <Item>
              <Label>Reference:</Label>
              <Value>
                <>
                  {order.reference}
                  <CopyButton string={order.reference} />
                </>
              </Value>
            </Item>
            {order?.attributes?.merchantReference1 ? (
              <Item>
                <Label>Merchant reference 1:</Label>
                <Value>
                  <>
                    {order?.attributes?.merchantReference1}{" "}
                    <CopyButton string={order?.attributes?.merchantReference1} />
                  </>
                </Value>
              </Item>
            ) : (
              <></>
            )}
            {order?.attributes?.merchantReference2 ? (
              <Item>
                <Label>Merchant reference 2:</Label>
                <Value>
                  <>
                    {order?.attributes?.merchantReference2}{" "}
                    <CopyButton string={order?.attributes?.merchantReference2} />
                  </>
                </Value>
              </Item>
            ) : (
              <></>
            )}
            <Item>
              <Label>Order date:</Label>
              <Value>{dayjs(order.date).format("YYYY-MM-DD HH:mm")}</Value>
            </Item>
            <Item flexibleHeight>
              <Label>E-mail:</Label>
              <Value>
                <>
                  {order.billingAddress.email}
                  <CopyButton string={order.billingAddress.email} />
                </>
              </Value>
            </Item>
            <Item>
              <Label>Store group:</Label>
              <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
            </Item>
            <Item>
              <Label>Store market:</Label>
              <Value flex>
                <>
                  <FlagIcon countryCode={order.countryCode} />
                  {getCountryName(order.countryCode)}
                </>
              </Value>
            </Item>
          </List>
        </OrderBlock>
        <OrderBlock>
          <h2>
            <TotalsIcon />
            Order totals
          </h2>
          <List>
            <OrderValue>
              <Label>Order value:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${order.totals?.subTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </OrderValue>
            <Item>
              <Label>Discount amount:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${-order.totals?.discountTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Item>
            {order.totals?.giftCardTotal > 0 ? (
              <Item>
                <Label>Gift card amount:</Label>
                <Value alignRight>
                  <Money
                    amount={parseInt(`${-order.totals?.giftCardTotal}`)}
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
            ) : (
              <></>
            )}
            {order.totals?.bonusTotal > 0 ? (
              <Item>
                <Label>Bonus amount:</Label>
                <Value alignRight>
                  <Money
                    amount={parseInt(`${-order.totals?.bonusTotal}`)}
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
            ) : (
              <></>
            )}

            {order.totals?.shippingTotal > 0 ? (
              <Item>
                <Label>Shipping cost:</Label>
                <Value alignRight>
                  <Money
                    amount={order.totals?.shippingTotal}
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
            ) : (
              <></>
            )}
            <Item>
              <Label>
                <>
                  Tax:
                  {order.isTaxIncludedInPrice && " (Included in price)"}
                </>
              </Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${order.totals?.taxTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Item>
            <Total>
              <Label>Total order value:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${order.totals?.grandTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          </List>
        </OrderBlock>
      </Columns>
      <br />
      <OrderBlock>
        <h2>
          <HistoryIcon />
          History
        </h2>
        <OrderHistoryLog
          orderHistory={orderHistoryList}
          order={order}
          refetch={refetch}
          customStates={customStates}
          getCustomState={getCustomState}
          addedCustomStates={customStateList}
          addCustomStateToHistory={addCustomStateToHistory}
        />
      </OrderBlock>
    </>
  )
}

export default OrderOverview
