import { OrderCancellation } from "@lib/types/generated/graphql-types"
import { OrderOperations } from "./OrderOperations"

export class Cancellation extends OrderOperations {
  constructor(cancellation: OrderCancellation[]) {
    super(cancellation)
  }

  public getPendingCancellation(): OrderCancellation {
    const pendingList = this.pendingList() as OrderCancellation[]
    return pendingList[0]
  }

  public getFailedCancellation(): OrderCancellation {
    const failedList = this.failedList() as OrderCancellation[]
    return failedList[0]
  }
}
