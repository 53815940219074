import gql from "graphql-tag"

const CREATE_OR_UPDATE_DISCOUNT_CODE_RULE = gql`
  mutation createOrUpdateDiscountCodeRule(
    $id: ID!
    $name: String!
    $logicRules: [InputLogicHolder!]!
    $isActive: Boolean!
    $isStackable: Boolean!
    $applyLast: Boolean!
    $isExclusive: Boolean!
    $sortOrder: Int!
    $conditions: [InputCondition!]!
    $outcomes: [InputOutcome!]!
    $validDateRange: InputValidDateRange
  ) {
    createOrUpdateDiscountCodeRule(
      id: $id
      discountCodeRule: {
        name: $name
        logicRules: $logicRules
        isActive: $isActive
        isStackable: $isStackable
        applyLast: $applyLast
        isExclusive: $isExclusive
        sortOrder: $sortOrder
        conditions: $conditions
        outcomes: $outcomes
        validDateRange: $validDateRange
      }
    ) {
      id
      name
      logicRules {
        if
        do
      }
      isActive
      isStackable
      isExclusive
      applyLast
      sortOrder
      conditions {
        type
        id
        triggerQuantity
        identifiers
        thresholds {
          currencyCode
          units
        }
        itemRule {
          tagConditions {
            mode
            key
            values
          }
          excludeDiscountedPrices
          excludeFullPrices
        }
      }
      outcomes {
        type
        id
        subOutcomes {
          triggerQuantity
          discountQuantity
          discountValue {
            type
            percentage
            percentageDecimals
            fixedDiscounts {
              currencyCode
              units
            }
          }
        }
        discountValue {
          type
          percentage
          percentageDecimals
          fixedDiscounts {
            currencyCode
            units
          }
        }
        discountItemRule {
          tagConditions {
            mode
            key
            values
          }
          excludeDiscountedPrices
          excludeFullPrices
        }
        triggerItemRule {
          tagConditions {
            mode
            key
            values
          }
          excludeDiscountedPrices
          excludeFullPrices
        }
        triggerQuantity
        discountQuantity
        gifts {
          productVariantId
          quantity
        }
        combineItemDiscount
        isRecurrent
      }
      validDateRange {
        from
        to
      }
    }
  }
`

export default CREATE_OR_UPDATE_DISCOUNT_CODE_RULE
