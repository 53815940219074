import React, { Dispatch, SetStateAction } from "react"
import { Order } from "lib/types/generated/graphql-types"

import { ButtonWrapper, CartContainer, CartItems, EmptyContainer } from "./Cart.styled"

import CartItem from "./CartItem"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import ShippingFeeItem from "./ShippingFeeItem"
import DELETE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/DeleteCompensation"
import Totals from "./Totals/Totals"
import CompensationReason from "./CompensationReason"
import { handleErrorMessages } from "helpers/errors"
import CompensationFee from "./CompensationFee"

type Props = {
  order: Order
  refetch: () => void
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
  summaryMode?: boolean
  orderLoading: boolean
}

export const Cart = ({ order, refetch, orderLoading, setShowConfirmation, summaryMode = false }: Props) => {
  const filteredCompensations = order?.Compensations?.filter(
    (compensation) => !compensation.completed && !compensation.started
  )

  const [deleteCompensation, { loading: deleteLoading }] = useMutation(DELETE_COMPENSATION, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  if (!order) return null

  return (
    <>
      {filteredCompensations?.length > 0 ? (
        <>
          <h2>Compensation draft</h2>
          {filteredCompensations?.map((compensation) => (
            <CartContainer key={compensation.id}>
              <CartItems>
                {compensation?.orderLines?.map((compensationOrderLine) => (
                  <CartItem
                    currencyCode={order.currencyCode}
                    key={compensationOrderLine.orderLineId}
                    item={compensationOrderLine}
                    orderLine={order?.orderLines?.find(
                      (ol) => ol.id === compensationOrderLine.orderLineId
                    )}
                    compensation={compensation}
                    refetch={refetch}
                  />
                ))}
                {compensation?.shippingFees?.map((compensationShippingFee) => (
                  <ShippingFeeItem
                    key={compensationShippingFee.shippingFeeId}
                    compensation={compensation}
                    compensationShippingFee={compensationShippingFee}
                    orderShippingFee={order?.shippingFees?.find(
                      (shippingFee) => shippingFee.id === compensationShippingFee.shippingFeeId
                    )}
                    refetch={refetch}
                    currencyCode={order.currencyCode}
                    order={order}
                  />
                ))}
                <CompensationReason refetch={refetch} compensation={compensation} orderLoading={orderLoading} />
                {(compensation?.payment ||
                  (compensation?.giftCards && compensation?.giftCards?.length > 0)) && (
                  <CompensationFee
                    compensation={compensation}
                    refetch={refetch}
                    currencyCode={order?.currencyCode}
                  />
                )}
                <Totals compensation={compensation} order={order} refetch={refetch} />
              </CartItems>
              <ButtonWrapper>
                {!summaryMode && (
                  <SecondaryButton
                    loading={deleteLoading}
                    handleClick={() =>
                      deleteCompensation({ variables: { compensationId: compensation.id } })
                    }
                  >
                    Cancel compensation draft
                  </SecondaryButton>
                )}
                {!summaryMode && (
                  <PrimaryButton
                    handleClick={() => {
                      setShowConfirmation(true)
                      window.scrollTo(0, 0)
                    }}
                  >
                    Next
                  </PrimaryButton>
                )}
              </ButtonWrapper>
            </CartContainer>
          ))}
        </>
      ) : (
        <>
          <h2>Compensation draft</h2>
          <EmptyContainer>
            <h2>Create compensation to finalize.</h2>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
