import styled from "styled-components"

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
  grid-template-columns: 1fr 1fr;
}
`

export const Info = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  font-size: 1.2rem;
  padding: 1rem;
  margin: 0.5rem 0;

  svg {
    height: 1.6rem;
    margin-right: 1rem;
  }
`

export const InfoWrapper = styled.div`
display: grid;
  grid-template-columns: 1fr;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    grid-template-columns: 1fr 1fr;
  }

  a {
    text-decoration: underline;
  }
`

