import React from "react"
import SecondaryButton from "../Ui/Buttons/SecondaryButton"
import { Divider, FilterGroup, Label, NumberOfHits, Show, Wrapper } from "./TopProductsFilters.styled"
import PrimaryButton from "../Ui/Buttons/PrimaryButton"
import {
  filterByAll,
  filterByDiscount,
  filterByNonDiscount,
  filterByParent,
  filterByVariant,
  NUMBER_OF_HITS_OPTIONS
} from "./AnalyticsData"
import { useDispatch, useSelector } from "react-redux"
import { setTopProductsFilteredBy, setTopProductsFilteredByHits, setTopProductsFilteredByType } from "lib/store/services/analytics/slice"
import { selectTopProductsFilteredBy, selectTopProductsFilteredByHits, selectTopProductsFilteredByType } from "lib/store/services/analytics/selectors"
import { SelectWrapper } from "./PeriodSelections.styled"

const TopProductsFilters = () => {
  const dispatch = useDispatch()
  const filteredBy = useSelector(selectTopProductsFilteredBy)
  const filteredByType = useSelector(selectTopProductsFilteredByType)
  const topProductsFilteredByHits = useSelector(selectTopProductsFilteredByHits)

  const handleClickFilterBy = (value: string) => {
    dispatch(setTopProductsFilteredBy(value))
  }

  const handleClickFilterByType = (value: string) => {
    dispatch(setTopProductsFilteredByType(value))
  }

  const handleClickFilterByHits = (value: string) => {
    dispatch(setTopProductsFilteredByHits(Number.parseInt(value)))
  }

  return (
    <Wrapper>
      <Label>Top Products</Label>
      <SelectWrapper>
        <FilterGroup>
          {filteredBy === filterByNonDiscount ? (
            <>
              <SecondaryButton
                handleClick={() => handleClickFilterBy(filterByAll)}
                overrideDisabled={true}
              >
                All
              </SecondaryButton>
              <SecondaryButton
                disabled={filteredByType !== filterByVariant}
                handleClick={() => handleClickFilterBy(filterByDiscount)}
                overrideDisabled={true}
              >
                Discounted
              </SecondaryButton>
              <PrimaryButton overrideDisabled={true}>Non Discounted</PrimaryButton>
            </>
          ) : filteredBy === filterByDiscount ? (
            <>
              <SecondaryButton handleClick={() => handleClickFilterBy(filterByAll)}
                               overrideDisabled={true}>
                All
              </SecondaryButton>
              <PrimaryButton
                disabled={filteredByType !== filterByVariant}
                handleClick={() => handleClickFilterBy(filterByDiscount)}
                overrideDisabled={true}
              >
                Discounted
              </PrimaryButton>
              <SecondaryButton
                disabled={filteredByType !== filterByVariant}
                handleClick={() => handleClickFilterBy(filterByNonDiscount)}
                overrideDisabled={true}
              >
                Non Discounted
              </SecondaryButton>
            </>
          ) : (
            <>
              <PrimaryButton overrideDisabled={true}>All</PrimaryButton>
              <SecondaryButton
                disabled={filteredByType !== filterByVariant}
                handleClick={() => handleClickFilterBy(filterByDiscount)}
                overrideDisabled={true}
              >
                Discounted
              </SecondaryButton>
              <SecondaryButton
                disabled={filteredByType !== filterByVariant}
                handleClick={() => handleClickFilterBy(filterByNonDiscount)}
                overrideDisabled={true}
              >
                Non Discounted
              </SecondaryButton>
            </>
          )}
        </FilterGroup>
        <Divider />
        <FilterGroup>
          {filteredByType.includes(filterByVariant) ? (
            <>
              <SecondaryButton
                disabled={filteredBy !== filterByAll}
                handleClick={() => handleClickFilterByType(filterByParent)}
                overrideDisabled={true}
              >
                By Product Parent
              </SecondaryButton>
              <PrimaryButton handleClick={() => handleClickFilterByType(filterByVariant)}
                             overrideDisabled={true}>
                By Product Variant
              </PrimaryButton>
            </>
          ) : (
            <>
              <PrimaryButton handleClick={() => handleClickFilterByType(filterByParent)}
                             overrideDisabled={true}>
                By Product Parent
              </PrimaryButton>
              <SecondaryButton handleClick={() => handleClickFilterByType(filterByVariant)}
                               overrideDisabled={true}>
                By Product Variant
              </SecondaryButton>
            </>
          )}
        </FilterGroup>
        <Divider />
        {NUMBER_OF_HITS_OPTIONS && topProductsFilteredByHits &&(
          <Show>
            <p>Show:</p>
            {NUMBER_OF_HITS_OPTIONS.map((hits) => (
              <NumberOfHits
                key={hits}
                onClick={() => handleClickFilterByHits(hits)}
                $active={hits === topProductsFilteredByHits.toString()}
              >
                {hits}
              </NumberOfHits>
            ))}
          </Show>
        )}
      </SelectWrapper>
    </Wrapper>
  )
}

export default TopProductsFilters
