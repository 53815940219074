import styled from "styled-components"

export const Header = styled.div`
  display: flex;

  svg {
    margin-left: 0.5rem;
    width: 1.2rem;
  }

  &:hover {
    cursor: pointer;
    font-weight: 700;
  }
`

export const Currencies = styled.div`
width: 100%
`
