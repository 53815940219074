import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Provider } from "@lib/types/generated/graphql-types"
import { PROVIDER_TYPE } from "lib/types/common"

import { Container, EmptyList, Wrapper } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import { getLoadingRow, SveaConfigTableRow } from "./SveaConfigTableRow"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as Logo } from "images/providers/icons/svea.svg"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  providers: Provider[]
  error: object | undefined
  setSveaConfigId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const SveaConfigTable = ({ providers, setSveaConfigId, error, loading }: Props) => {
  const [sveaProviders, setSveaProviders] = useState<Provider[]>([])

  useEffect(() => {
    if (providers) {
      setSveaProviders(
        providers?.filter((provider) => provider.type === PROVIDER_TYPE.SVEA_CHECKOUT)
      )
    }
  }, [providers])

  const tableHeader = (
    <TableHeader iconHeader={true}>
      <div>Merchant ID</div>
      <div>Environment</div>
      <div>Created</div>
      <div>Updated</div>
    </TableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (sveaProviders?.length < 1)
    return (
      <EmptyList>
        <Logo /> <p> No configurations added</p>
      </EmptyList>
    )
  if (!providers) return null

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Svea Checkout settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...sveaProviders].map((sveaProvider) => (
          <SveaConfigTableRow
            key={sveaProvider.id}
            id={sveaProvider.id}
            setSveaConfigId={setSveaConfigId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
