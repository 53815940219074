import { Discount } from "@lib/types"

export const transformLogicData = (data: Discount.LogicState) => {
  const entries = Object.keys(data)

  const conditions = entries.flatMap((logicRule) =>
    data[logicRule].conditions
      .filter((c: { type: string }) => c.type === "condition")
      .map((condition: Discount.FrontendCondition) => ({
        id: condition.id,
        ...condition.data
      }))
  )

  const outcomes = entries.flatMap((logicRule) =>
    data[logicRule].outcomes
      .filter((c: { type: string }) => c.type === "outcome")
      .map((outcome: Discount.FrontendOutcome) => ({
        id: outcome.id,
        ...outcome.data
      }))
  )

  const logicRules = entries.map((logicRule) => {
    const ruleData = data[logicRule]
    const conditionOperator = ruleData.conditions.find(
      (c: { type: string }) => c.type === "operator"
    )
    return {
      if: `{ "${conditionOperator?.title.toLowerCase() || "and"}": [${ruleData.conditions
        .filter((c: { type: string }) => c.type === "condition")
        .map((condition: Discount.FrontendCondition) => `{"validate": "${condition.id}"}`)}]}`,
      do: ruleData.outcomes
        .filter((outcome: Discount.FrontendOutcome) => outcome.type !== "operator")
        .map((outcome: Discount.FrontendOutcome) => outcome.id)
    }
  })

  return {
    conditions,
    outcomes,
    logicRules
  }
}
