import styled from "styled-components"

export const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};

  div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 2rem;
    margin-right: 1rem;
  }

  img {
    width: 2rem;
    margin-right: 1rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1.5rem 0;
  }
`

export const CartContainer = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.5rem;
  position: relative;
  margin-bottom: 2rem;
  padding: 0 1.5rem 2rem;

  div[class*="-MenuList"] {
    padding: 0;
  }

  div[class*="-singleValue"],
  div[class*="-option"] {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    svg {
      width: 3.4rem;
    }
  }
  div[class*="-placeholder"] {
    padding-left: 2rem;
  }

  div[class*="-option"] {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};
    height: 5rem;
    padding: 0 2rem 0 0.3rem;

    &:last-of-type {
      border-bottom: none;
    }
  }

  div[class*="-control"] {
    height: 5rem;
    padding: 0;
  }

  div[class*="-ValueContainer"] {
    padding: 0;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 0 2.5rem 3rem;
  }
`

export const EmptyContainer = styled(CartContainer)`
  text-align: center;
  padding-top: 2rem;

  h2 {
    margin-bottom: 1rem;
  }
`

export const Label = styled.h3`
  padding-top: 3rem;
`

export const CartItems = styled.div`
  padding: 0;
  border-top: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  margin-bottom: 2rem;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const IconContainer = styled.span`
  border: 0.1rem solid rgb(223, 227, 232);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 1.5rem;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 1rem 0rem 0.5rem;

  svg {
    margin-right: 0;
  }
`
