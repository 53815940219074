import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { TaxGroup } from "@lib/types/generated/graphql-types"

import { Container, EmptyList, Id, Name, Wrapper } from "./TaxGroupTable.styled"

import TableRow from "components/Ui/Table/TableRow"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"

import { ReactComponent as TaxGroupIcon } from "images/icons/receipt.svg"
import TableHeader from "../Ui/Table/TableHeader"

type Props = {
  taxGroups: TaxGroup[]
  loading: boolean
  error: object | undefined
  setEditId: Dispatch<SetStateAction<string>>
}

function TaxGroupTable({ taxGroups, loading = false, error, setEditId }: Props) {
  const dispatch = useAppDispatch()

  const tableHeader = (
    <TableHeader iconHeader={true}>
      <div>Name</div>
      <div className="hideOnSmallScreens">Description</div>
    </TableHeader>
  )

  const getTableRow = (taxGroup?: TaxGroup) => {
    if (taxGroup) {
      return (
        <TableRow
          key={taxGroup.id}
          handleOnClick={() => {
            setEditId(taxGroup.id)
            dispatch(showEditSidebar())
          }}
        >
          <TaxGroupIcon />
          <div>
            <div>
              <Name>{taxGroup.name}</Name>
              <Id>{taxGroup.id}</Id>
            </div>
          </div>
          <div className="hideOnSmallScreens">
            <p>{taxGroup.description}</p>
          </div>
        </TableRow>
      )
    }
    return (
      <TableRow>
        <TaxGroupIcon />
        <div>
          <div>
            <Name>Tax group name</Name>
            <Id>taxgroup-id</Id>
          </div>
        </div>
        <div className="hideOnSmallScreens">
          <p>Tax group description</p>
        </div>
      </TableRow>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getTableRow()} />

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Tax groups. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {taxGroups.length > 0 ? (
          <>
            {tableHeader}
            {[...taxGroups]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((taxGroup) => getTableRow(taxGroup))}
          </>
        ) : (
          <EmptyList>
            <TaxGroupIcon />
            <p> No tax groups found</p>
          </EmptyList>
        )}
      </Container>
    </Wrapper>
  )
}

export default TaxGroupTable
