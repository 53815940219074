import React from "react"
import { Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { CapabilityProvider, InputUpdateStoreMarket } from "@lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import SingleSelect from "../../Ui/Form/SingleSelect"
import ALL_GIFT_CARD_PROVIDERS from "../../../graphql/queries/config/AllGiftCardProviders"

const InputSection = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

type Props = {
  defaultValue: string | undefined
  control: Control
  errors: FieldErrors
  setValue: UseFormSetValue<InputUpdateStoreMarket>
}

export const GiftCardProductSelect = ({ defaultValue, control, errors, setValue }: Props) => {
  const { data } = useQuery(ALL_GIFT_CARD_PROVIDERS)

  const giftCardProductOptions =
    data?.getGiftCardProviders.length > 0
      ? data?.getGiftCardProviders?.map((config: CapabilityProvider) => ({
        label: config.id,
        value: config.id
      }))
      : []
  const options = [{ label: "Select gift card product config...", value: undefined }, ...giftCardProductOptions]
  const updateGiftCardProductConfig = (giftCardProductConfig: Common.Option) => {
    setValue("cartCapabilities.retain24", { id: giftCardProductConfig?.value })
  }

  if (giftCardProductOptions.length === 0) return null

  return (
    <InputSection>
      <Label>Retain24 gift card product</Label>
      <Controller
        name="cartCapabilities.retain24"
        render={() => (
          <SingleSelect
            defaultValue={defaultValue ? { label: defaultValue, value: defaultValue } : options[0]}
            options={options}
            setValue={updateGiftCardProductConfig}
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name="cartCapabilities.bonus" />
    </InputSection>
  )
}

export default GiftCardProductSelect
