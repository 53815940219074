import React, { useState } from "react"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { AddButton } from "./CustomReasonCodes.styled"
import AddReasonCode from "../../../components/Settings/CustomReason/AddReasonCode"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"
import ALL_REASON_CODES from "../../../graphql/queries/settings/reasonCodes/AllReasonCodes"
import ReasonCodeTable from "../../../components/Settings/CustomReason/ReasonCodeTable"
import EditReasonCode from "../../../components/Settings/CustomReason/EditReasonCode"

const CustomReasonCodes = () => {
  const [editReasonCode, setEditReasonCode] = useState<string | undefined>()
  const dispatch = useDispatch()

  const { data, loading, refetch } = useQuery(ALL_REASON_CODES)
  const reasonCodes = data?.Order?.customReasonCodes?.codes

  const handleSelectReasonCode = (code: string) => {
    setEditReasonCode(code)
  }

  const handleClearReasonCode = () => {
    setEditReasonCode(undefined)
  }

  return (
    <>
      <PageHeader
        title={" Policy definitions"}
        description={
          "Customize the reasons and codes for order management operations to match your business policies."
        }
      >
        <AddButton handleClick={() => dispatch(showEditSidebar())}>
          Add definition
          <PlusIcon />
        </AddButton>
      </PageHeader>
      {!editReasonCode && <AddReasonCode refetch={refetch} customReasonCodes={reasonCodes} />}
      {editReasonCode && <EditReasonCode refetch={refetch} reasonCode={editReasonCode} handleClearReasonCode={handleClearReasonCode} />}
      {loading ? (
        <div className={"blur"}>
          <ReasonCodeTable
            customReasonCodes={[]}
            handleSelectReasonCode={handleSelectReasonCode}
          />
        </div>
      ) : (
        <ReasonCodeTable
          customReasonCodes={reasonCodes}
          handleSelectReasonCode={handleSelectReasonCode}
        />
      )}
    </>
  )
}

export default CustomReasonCodes
