import React from "react"
import { useNavigate } from "react-router-dom"

import { EmptyList, FlexColumn } from "../Order/OrderTable/OrderTable.styled"

import TableRow from "../Ui/Table/TableRow"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"
import ErrorMessage from "../Ui/Messages/ErrorMessage"

import { ReactComponent as CartIcon } from "../../images/icons/cart-shopping.svg"
import { Container, StyledTableHeader } from "./TopDiscountCodes.styled"
import { topDiscountCodesData } from "../../views/Dashboards/DashboardSalesAggregations"
import Dinero from "dinero.js"

type Props = {
  data: topDiscountCodesData[]
  loading: boolean
  error: object | undefined
}

export const TopDiscountCodes = ({ data, loading, error }: Props) => {
  const navigate = useNavigate()

  function toAverageDiscountPercentage(d: topDiscountCodesData): string {
    // Average discount in percentage can be calculated with a fixed currency
    const total = Dinero({ amount: d.subTotal?.value })
    const discount = Dinero({ amount: d.discountTotal?.value })

    const discountPercentage = discount
      .multiply(100)
      .divide(total.getAmount())
    return (discountPercentage.getAmount()).toFixed(2)

  }

  const tableHeader = (
    <StyledTableHeader>
      <div>Discount Code</div>
      <div>Usage</div>
      <div>Average Order Discount</div>
    </StyledTableHeader>
  )

  const getRows = (d?: topDiscountCodesData) =>
    d ? (
      <TableRow
        key={d.key}
        handleOnClick={() => {
          navigate(`/discounts/discount-codes`)
        }}
      >
        <FlexColumn>
          <p>{d.key}</p>
        </FlexColumn>
        <FlexColumn>
          <p>{d.doc_count}</p>
        </FlexColumn>
        <FlexColumn>
          <p>{toAverageDiscountPercentage(d)}%</p>
        </FlexColumn>
      </TableRow>
    ) : (
      <></>
    )

  if (loading)
    return <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getRows()} />

  return (
    <Container>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading Top Discount Code List. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {data && data?.length > 0 ? (
        <>
          {tableHeader}
          <div>{data.map((order) => getRows(order))}</div>
        </>
      ) : (
        <EmptyList>
          <CartIcon />
          <p> No discount codes found</p>
        </EmptyList>
      )}
    </Container>
  )
}
