import React from "react"
import { v4 as uuid } from "uuid"
import { ExternalEventDestinationApIs } from "lib/types/generated/graphql-types"

import { Container, EmptyList, Wrapper } from "../../Ui/Table/Shared.styled"
import { BrinkLoader } from "../../Ui/BrinkLoader"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"

import { ReactComponent as Code } from "../../../images/icons/laptop-code.svg"
import { Name, StyledIconTableHeader, StyledTableRow } from "./DestinationApisTable.styled"
import { useNavigate } from "react-router-dom"

type Props = {
  apiDestinations: ExternalEventDestinationApIs
  loading: boolean
  error: object | undefined
  refetch: () => void
}

const DestinationApisTable = ({ apiDestinations, loading, error }: Props) => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading API destinations. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}

        {loading && !error ? (
          <BrinkLoader />
        ) : (
          <>
            {apiDestinations?.apiDestinationsDetails.length === 0 ? (
              <EmptyList>
                <Code />
                <p>No API destinations added</p>
              </EmptyList>
            ) : (
              <>
                <StyledIconTableHeader>
                  <Name>Name</Name>
                </StyledIconTableHeader>
                {apiDestinations?.apiDestinationsDetails.map((apiDestination) => (
                  <StyledTableRow
                    key={uuid()}
                    handleOnClick={() =>
                      navigate(
                        `/settings/external-events/update-api-destination/${apiDestination.name}`
                      )
                    }
                  >
                    <Code />
                    <div>
                      <Name
                        data-tooltip-id="tooltip"
                        data-tooltip-content={apiDestination.endpoint}
                        data-tooltip-delay-hide={100}
                      >
                        {apiDestination.name}
                      </Name>
                    </div>
                  </StyledTableRow>
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default DestinationApisTable
