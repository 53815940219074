import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { CustomState, OrderCustomState } from "lib/types/generated/graphql-types"
import {
  ActionWrapper,
  Container,
  CustomStateIcon,
  customStyles,
  IconWrapper,
  InputWrapper,
  Line,
  Operations,
  SelectedState
} from "./AddCustomStateToOrder.styled"
import ADD_CUSTOM_STATE_TO_ORDER from "../../../graphql/mutations/settings/customState/AddCustomStateToOrder"
import { handleErrorMessages } from "helpers/errors"
import Select from "react-select"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import { formatOperation } from "../helpers"
import { ReactComponent as BlockIcon } from "images/icons/circle-xmark.svg"

type Props = {
  orderId: string
  customStates: CustomState[]
  addedCustomStates: OrderCustomState[]
  addCustomStateToHistory: (customStateId: string) => void
}

const AddCustomStateToOrder = ({
  orderId,
  customStates,
  addedCustomStates,
  addCustomStateToHistory
}: Props) => {
  const [selectedState, setSelectedState] = useState<string>()

  const getState = (id: string) => customStates.find((customState) => customState.id === id)

  const stateAdded = (id: string) =>
    !!addedCustomStates.find((customState) => customState.id === id)

  const customStateOptions = customStates
    ?.filter((state) => !stateAdded(state.id))
    .map((state: CustomState) => ({
      value: state.id,
      label: state.description ?? state.id
    }))

  const getOptionFromValue = (value: string | undefined) => {
    if (!value) return null
    return customStateOptions.find((option) => option.value === value)
  }

  const [addCustomState, { loading }] = useMutation(ADD_CUSTOM_STATE_TO_ORDER, {
    onCompleted: () => {
      if (selectedState) addCustomStateToHistory(selectedState)
      setSelectedState(undefined)
    },
    onError: (err) => {
      handleErrorMessages(err)
    }
  })

  const addCustomStateToOrder = () => {
    addCustomState({ variables: { stateId: selectedState, orderId: orderId } })
  }

  return (
    <>
      <Line />
      <Container>
        <InputWrapper>
          <IconWrapper>
            <CustomStateIcon />
          </IconWrapper>
          <Select
            options={customStateOptions}
            value={getOptionFromValue(selectedState)}
            onChange={(e) => setSelectedState(e?.value)}
            placeholder={"Select a custom state"}
            styles={customStyles}
            isSearchable={false}
          />
        </InputWrapper>
        {selectedState && getState(selectedState)?.operations && (
          <SelectedState>
            <b>Blocked operations: </b>
            <Operations>
              {getState(selectedState)?.operations?.map((operation) => (
                <div key={operation}>
                  <BlockIcon />
                  {formatOperation(operation)}
                </div>
              ))}
            </Operations>
          </SelectedState>
        )}
        <ActionWrapper>
          <PrimaryButton
            disabled={!selectedState}
            handleClick={addCustomStateToOrder}
            loading={loading}
          >
            Add
          </PrimaryButton>
        </ActionWrapper>
      </Container>
      <Line />
    </>
  )
}

export default AddCustomStateToOrder
