import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"
import { useLazyQuery } from "@apollo/client"
import { CodesGenerationSearchHit } from "@lib/types/generated/graphql-types"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"

import SEARCH_CODES_GENERATIONS from "graphql/queries/discount/SearchCodesGenerations"

import PageHeader from "components/Ui/Page/PageHeader"
import ShowMultipleDiscount from "./ShowCodeGenerationJob"
import { CodeGenerationJobTable } from "components/Discount/CodeGenerationJob/CodeGenerationJobTable"
import Search from "components/Ui/Search"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
`

export const ShowCodeGenerationJobs = () => {
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [editCode, setEditCode] = useState("")
  const showSidebar = useSelector(editSidebarOpen)
  const [codeGenerationJobs, setCodeGenerationJobs] = useState<CodesGenerationSearchHit[]>([])

  const referenceSearchQuery = esb
    .requestBodySearch()
    .query(esb.boolQuery().must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)]))

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 25,
    query: JSON.stringify(query.query),
    sort: [{ field: "updated", order: "DESC" }]
  }

  const [searchDiscountCodes, { loading, error }] = useLazyQuery(SEARCH_CODES_GENERATIONS, {
    variables
  })

  useEffect(() => {
    if (!showSidebar) setEditCode("")
  }, [showSidebar])

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchDiscountCodes({
          variables
        }).then((response) => {
          setCodeGenerationJobs(response?.data?.searchCodesGenerations.hits)
        })
      },
      searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  useEffect(() => {
    if (!showSidebar) setEditCode("")
  }, [showSidebar])

  return (
    <Container>
      <PageHeader
        title="Code generation jobs"
        description={`Code generation jobs are listed below. These are generated from code rules. You can search for a particular job or download a CSV file containing the generated codes.`}
      ></PageHeader>
      <Search
        handleOnChange={(input) => setSearchQuery(input)}
        loading={loading}
        defaultValue={searchQuery}
        placeholder="Search by Rule ID or Code prefix"
      />
      <CodeGenerationJobTable
        codeGenerationJobs={codeGenerationJobs}
        setEditCode={setEditCode}
        loading={loading}
        error={error}
      />
      {editCode && <ShowMultipleDiscount jobId={editCode} />}
    </Container>
  )
}
