import React, { useState } from "react"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { AddButton } from "./CustomStates.styled"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"
import ALL_CUSTOM_STATES from "../../../graphql/queries/settings/customState/AllCustomStates"
import AddCustomState from "../../../components/Settings/CustomStates/AddCustomState"
import CustomStateTable from "../../../components/Settings/CustomStates/CustomStateTable"
import EditCustomState from "../../../components/Settings/CustomStates/EditCustomState"

const CustomStates = () => {
  const [editCustomStateId, setEditCustomStateId] = useState<string | undefined>()
  const dispatch = useDispatch()

  const { data, loading, refetch } = useQuery(ALL_CUSTOM_STATES)
  const customStates = data?.Order?.customStates?.states

  const handleSelectState = (id: string) => {
    setEditCustomStateId(id)
  }

  const handleClearState = () => {
    setEditCustomStateId(undefined)
  }

  return (
    <>
      <PageHeader
        title={"Custom states"}
        description={"Define custom order states that orders can transition through"}
      >
        <AddButton handleClick={() => dispatch(showEditSidebar())}>
          Add state
          <PlusIcon />
        </AddButton>
      </PageHeader>
      {!editCustomStateId && <AddCustomState refetch={refetch} customStates={customStates} />}
      {editCustomStateId && (
        <EditCustomState
          refetch={refetch}
          editCustomStateId={editCustomStateId}
          handleClearEditCustomStateId={handleClearState}
        />
      )}

      {loading ? (
        <div className={"blur"}>
          <CustomStateTable customStates={[]} handleSelectState={handleSelectState} />
        </div>
      ) : (
        <CustomStateTable customStates={customStates} handleSelectState={handleSelectState} />
      )}
    </>
  )
}

export default CustomStates
