import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AnalyticsState, Option } from "@lib/types/state"

const initialState: AnalyticsState = {
  filteredDays: 1,
  storeGroups: [],
  storeMarkets: [],
  topProductsFilteredBy: "all",
  topProductsFilteredByType: "variant",
  topProductsFilteredByHits: 5,
  topCodesFilteredByHits: 5,
  productNames: {},
  filteredByExcludingTax: true
}

function reset(state: AnalyticsState) {
  state.filteredDays = initialState.filteredDays
  state.storeGroups = initialState.storeGroups
  state.storeMarkets = initialState.storeMarkets
  state.topProductsFilteredBy = initialState.topProductsFilteredBy
  state.topProductsFilteredByType = initialState.topProductsFilteredByType
  state.topProductsFilteredByHits = initialState.topProductsFilteredByHits
  state.topCodesFilteredByHits = initialState.topCodesFilteredByHits
  state.productNames = initialState.productNames
  state.filteredByExcludingTax = initialState.filteredByExcludingTax
}

export const slice = createSlice({
  name: "analytics",
  initialState: { ...initialState },
  reducers: {
    reset: reset,
    setFilteredDays(state, action: PayloadAction<number>) {
      state.filteredDays = action.payload
    },
    setSelectedStoreGroups(state, action: PayloadAction<Option[]>) {
      state.storeGroups = action.payload
    },
    setSelectedStoreMarkets(state, action: PayloadAction<Option[]>) {
      state.storeMarkets = action.payload
    },
    setTopProductsFilteredBy(state, action: PayloadAction<string>) {
      state.topProductsFilteredBy = action.payload
    },
    setTopProductsFilteredByType(state, action: PayloadAction<string>) {
      state.topProductsFilteredByType = action.payload
    },
    setTopProductsFilteredByHits(state, action: PayloadAction<number>) {
      state.topProductsFilteredByHits = action.payload
    },
    setTopCodesFilteredByHits(state, action: PayloadAction<number>) {
      state.topCodesFilteredByHits = action.payload
    },
    setProductNames(state, action: PayloadAction<{ [productId: string]: string; }>) {
      state.productNames = action.payload
    },
    setFilteredByExcludingTax(state, action: PayloadAction<boolean>) {
      state.filteredByExcludingTax = action.payload
    }
  }
})

const { actions, reducer } = slice
export const {
  setFilteredDays,
  setSelectedStoreGroups,
  setSelectedStoreMarkets,
  setTopProductsFilteredBy,
  setTopProductsFilteredByType,
  setTopProductsFilteredByHits,
  setTopCodesFilteredByHits,
  setProductNames,
  setFilteredByExcludingTax
} = actions

export default reducer
