import styled from "styled-components"

type StyledProps = {
  $row: boolean
}

export const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: 3fr 1fr 1fr 2fr;
    &:hover {
      font-weight: 700;
      cursor: default;
    }
  }

  svg {
    width: 1.8rem;
    margin-right: 0.5rem;
  }


`
export const TableData = styled.div<StyledProps>`
  background: ${(p) => (p.$row ? p.theme.colors.greyLighter : p.theme.colors.white)};
  display: flex;
  padding: 0.5rem;
`

export const Time = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin: 0 0.5rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    flex-direction: row;
  }

`

export const HistoricalPricesInfo = styled.div`
  margin-top: 2rem;
  position: relative;

  img {
    width: 2rem;
  }
`

export const HistoricalPricesContainer = styled.div`
  padding: 2rem 3rem;
  margin: 4rem 0 2rem;
  border-radius: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};

  h3 {
    font-size: 1.2rem;
    ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
      font-size: 1.6rem;
    }
  }

  span {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;

      svg {
        margin-right: 1rem;
        width: 2rem;
      }
    }

    svg {
      width: 1.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
`

export const MarketPriceHeader = styled.div`
display: flex;
  img {
    margin-right: 0.8rem;
  }
`

export const Campaign = styled.div`
  text-decoration: underline;
  &:hover {
  cursor: pointer;
}
`
