import React from "react"
import styled from "styled-components"
import { OrderPaymentKlarnaCheckout } from "@lib/types/generated/graphql-types"
import { getPaymentMethodDisplayName, getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"

import Status from "components/Ui/Status"
import { List, Item, Value, Label } from "components/Ui/List/List"

import { ReactComponent as KlarnaLogo } from "images/providers/klarna-logo.svg"

const Logo = styled(KlarnaLogo)`
  position: absolute;
  height: 2rem;
  right: 3rem;
`

type Props = {
  orderPayment: OrderPaymentKlarnaCheckout
}

const Klarna = ({ orderPayment }: Props) => {
  if (!orderPayment) return null

  return (
    <List>
      <Logo />
      <Item>
        <Label>Status:</Label>
        <Value>
          <Status status={orderPayment.status.toUpperCase()} />
        </Value>
      </Item>
      {orderPayment.fraudStatus ? (
        <Item>
          <Label>Fraud status:</Label>
          <Value>
            <Status status={orderPayment.fraudStatus} />
          </Value>
        </Item>
      ) : (
        <></>
      )}
      <Item>
        <Label>Payment provider:</Label>
        <Value>{getPaymentProviderDisplayName(orderPayment.providerName)}</Value>
      </Item>
      <Item>
        <Label>Payment method:</Label>
        <Value>{getPaymentMethodDisplayName(orderPayment.method)}</Value>
      </Item>
      <Item>
        <Label>Payment reference:</Label>
        <Value>{orderPayment.reference}</Value>
      </Item>
    </List>
  )
}

export default Klarna
