import React, { useState } from "react"
import EditSidebar from "../../Ui/EditSidebar/EditSidebar"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import EditSidebarHeader from "../../Ui/EditSidebar/EditSidebarHeader"
import { BlockingIcon, InfoIcon } from "./Shared.styled"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { handleErrorMessages } from "helpers/errors"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { v4 as uuidv4 } from "uuid"
import { CustomState, CustomStateType } from "lib/types/generated/graphql-types"
import CREATE_OR_UPDATE_CUSTOM_STATE from "../../../graphql/mutations/settings/customState/CreateOrUpdateCustomState"
import CustomStateInputs from "./CustomStateInputs"
import { CustomStateInput } from "@lib/types/customState"

type Props = {
  refetch: () => void
  customStates: CustomState[]
}

const AddCustomState = ({ refetch, customStates = [] }: Props) => {
  const [type, setType] = useState<CustomStateType>(CustomStateType.Blocking)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors }
  } = useForm<CustomStateInput>({
    defaultValues: {
      input: { type: CustomStateType.Blocking }
    }
  })

  const dispatch = useDispatch()

  const setBlockingType = () => {
    setType(CustomStateType.Blocking)
    setValue("input.type", CustomStateType.Blocking)
  }

  const setInformativeType = () => {
    setType(CustomStateType.Informative)
    setValue("input.type", CustomStateType.Informative)
  }

  const [createCustomState, { loading }] = useMutation(CREATE_OR_UPDATE_CUSTOM_STATE, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "New state created",
          type: "success"
        })
      )
      refetch()
      reset()
      dispatch(hideEditSidebar())
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const onSubmit: SubmitHandler<CustomStateInput> = (data) => {
    createCustomState({
      variables: {
        ...data,
        stateId: data?.stateId && data?.stateId.trim() !== "" ? data?.stateId.trim() : uuidv4(),
        input: {
          ...data.input,
          ...(type === CustomStateType.Blocking && { operations: data.input.operations })
        }
      }
    })
  }

  return (
    <EditSidebar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader
          title={"New State"}
          icon={type === CustomStateType.Blocking ? <BlockingIcon /> : <InfoIcon />}
        >
          <PrimaryButton type={"submit"} loading={loading}>
            New custom state
          </PrimaryButton>
        </EditSidebarHeader>
        <CustomStateInputs
          setInformativeType={setInformativeType}
          register={register}
          control={control}
          errors={errors}
          selectedType={type}
          setBlockingType={setBlockingType}
          customStates={customStates}
        />
      </form>
    </EditSidebar>
  )
}

export default AddCustomState
