import React from "react"
import styled from "styled-components"
import { ProductVariant } from "lib/types/generated/graphql-types"

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const Dimension = styled.div`
  margin-left: 0.3rem;
`

const DimensionWrapper = styled.div`
  display: flex;
`

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 2rem 0.6rem;
  height: calc(100% - 6.4rem);
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
  justify-content: space-between;
  position: relative;
`

type Props = {
  variant: ProductVariant
}

const DimensionsComponent = ({ variant }: Props) => {
  return (
    <>
      <Label>Dimensions: </Label>
      <Block>
        <DimensionWrapper>
          <Label>Height:</Label>
          <Dimension>{variant?.dimensions?.height ?? "-"}</Dimension>
        </DimensionWrapper>
        <DimensionWrapper>
          <Label>Length:</Label>
          <Dimension>{variant?.dimensions?.length ?? "-"}</Dimension>
        </DimensionWrapper>
        <DimensionWrapper>
          <Label>Width:</Label>
          <Dimension>{variant?.dimensions?.width ?? "-"}</Dimension>
        </DimensionWrapper>
        <DimensionWrapper>
          <Label>Weight:</Label>
          <Dimension>{variant?.weight ?? "-"}</Dimension>
        </DimensionWrapper>
      </Block>
    </>
  )
}

export default DimensionsComponent
