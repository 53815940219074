import React, { RefObject, useState } from "react"
import { Common } from "@lib/types"
import { Conditions, FrontendCondition, TagCondition } from "@lib/types/discount"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"

import Threshold from "../../Threshold"
import { TagConditions } from "../../Ui/TagConditions/TagConditions"

type Props = {
  condition?: FrontendCondition
  addCondition: (data: Conditions) => void
  currencyOptions: Common.Option[]
  submitRef: RefObject<HTMLButtonElement> | undefined
}

const MinItemsValue = ({ condition, addCondition, currencyOptions, submitRef }: Props) => {
  const dispatch = useAppDispatch()
  const [thresholds, setThresholds] = useState<Common.Money[]>(condition?.data?.thresholds ?? [])
  const [tagConditions, setTagConditions] = useState<TagCondition[]>(
    condition?.data?.itemRule?.tagConditions ?? []
  )
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(
    condition?.data?.itemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPrices, setExcludeFullPrices] = useState(
    condition?.data?.itemRule?.excludeFullPrices ?? false
  )
  const onSubmit = () => {
    if (thresholds.length === 0) {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one threshold is required",
          type: "error"
        })
      )
      return
    }
    addCondition({
      thresholds,
      itemRule: { tagConditions, excludeDiscountedPrices, excludeFullPrices }
    })
  }

  return (
    <>
      <Threshold
        thresholds={thresholds}
        setThresholds={setThresholds}
        currencyOptions={currencyOptions}
      />
      <hr />
      <TagConditions
        tagConditions={tagConditions}
        setTagConditions={setTagConditions}
        excludeDiscountedPrices={excludeDiscountedPrices}
        setExcludeDiscountedPrices={setExcludeDiscountedPrices}
        excludeFullPrices={excludeFullPrices}
        setExcludeFullPrices={setExcludeFullPrices}
      />
      <button
        onClick={() => onSubmit()}
        ref={submitRef}
        type="button"
        style={{ display: "none" }}
      />
    </>
  )
}

export default MinItemsValue
