import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"

import ADD_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API from "graphql/mutations/settings/externalEvents/AddExternalEventsDestinationAPI"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import PageHeader from "../../../../components/Ui/Page/PageHeader"
import { InputAddExternalEventsDestinationApi } from "lib/types/generated/graphql-types"
import { AuthTypeSelect, AuthTypeWrapper, Border, Grid, Info } from "./Shared.styled"
import ConnectedEventRules from "../../../../components/ExternalEvents/DestinationApi/ConnectedEventRules"
import { useNavigate } from "react-router-dom"
import { FilterMode } from "lib/types/externalEvents"
import { handleErrorMessages } from "helpers/errors"

enum AuthType {
  API_KEY_AUTH,
  BASIC_AUTH
}

type Inputs = {
  destName: string
  options: InputAddExternalEventsDestinationApi
}

const CreateApiDestination = () => {
  const dispatch = useDispatch()
  const [authType, setAuthType] = useState<AuthType>(AuthType.BASIC_AUTH)
  const [filterEventRules, setFilterEventRules] = useState<string[]>([])
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.SELECT)

  const navigate = useNavigate()

  const saveButtonDisabled = filterMode === FilterMode.SELECT && filterEventRules?.length === 0

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>()

  const [createApiDestination, { loading: createLoading }] = useMutation(
    ADD_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API,
    {
      onCompleted: (data) => {
        dispatch(
          alertActions.actions.createAlert({
            message: `API Destination ${data.addExternalEventsDestinationAPI.apiDestinationName} successfully created`,
            type: "success"
          })
        )
        navigate("/settings/external-events")
      },
      onError: (error) => {
        handleErrorMessages(error)
      }
    }
  )

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    createApiDestination({
      variables: {
        destName: data.destName,
        options: {
          apiDestinationEndpoint: data.options.apiDestinationEndpoint.trim(),
          ...(authType === AuthType.BASIC_AUTH
            ? { basicAuth: data.options.basicAuth }
            : { apiKeyAuth: data.options.apiKeyAuth }),
          filterEventRules: filterMode === FilterMode.ALL ? [] : filterEventRules
        }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        title={"Create webhook API Destination"}
        goBackLinkText={"Back to external events"}
        goBackLinkUrl={"/settings/external-events"}
        description={
          "Subscribe to various events related to Product, Discount, and Order services."
        }
      >
        <PrimaryButton loading={createLoading} type="submit" disabled={saveButtonDisabled}>
          Save
        </PrimaryButton>
      </PageHeader>

      <div title="Create new API Destination"></div>
      <div>
        <Input
          name={"destName"}
          label="Destination name: *"
          errors={errors}
          register={register}
          validation={{
            required: "This is a required field",
            maxLength: {
              value: 20,
              message: "Max length 20"
            },
            pattern: {
              value: /^[a-zA-Z][.\-_A-Za-z0-9]+$/,
              message: "The input contains unallowed characters."
            }
          }}
        />
        <Input
          name={"options.apiDestinationEndpoint"}
          label="Destination endpoint: *"
          errors={errors}
          register={register}
          validation={{
            required: "This is a required field"
          }}
        />
        <AuthTypeWrapper>
          <Border>
            <AuthTypeSelect
              $selected={authType === AuthType.BASIC_AUTH}
              onClick={() => setAuthType(AuthType.BASIC_AUTH)}
            >
              Basic Auth
            </AuthTypeSelect>
            <AuthTypeSelect
              $selected={authType === AuthType.API_KEY_AUTH}
              onClick={() => setAuthType(AuthType.API_KEY_AUTH)}
            >
              API key auth
            </AuthTypeSelect>
          </Border>
        </AuthTypeWrapper>
        <>
          {authType === AuthType.BASIC_AUTH && (
            <>
              <Info>Enter user and password for basic auth.</Info>
              <Grid>
                <Input
                  name="options.basicAuth.user"
                  label="User: *"
                  register={register}
                  validation={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                  errors={errors}
                />
                <Input
                  name="options.basicAuth.password"
                  label="Password: *"
                  register={register}
                  validation={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                  errors={errors}
                />
              </Grid>
            </>
          )}
        </>
        <>
          {authType === AuthType.API_KEY_AUTH && (
            <>
              <Info>
                Enter an API key to include in the header for each authentication request.
              </Info>
              <Grid>
                <Input
                  name="options.apiKeyAuth.name"
                  label="Name: *"
                  register={register}
                  validation={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                  errors={errors}
                />
                <Input
                  name="options.apiKeyAuth.key"
                  label="API key: *"
                  register={register}
                  validation={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                  errors={errors}
                />
              </Grid>
            </>
          )}
        </>
      </div>
      <ConnectedEventRules
        filterEventRules={filterEventRules}
        setFilterEventRules={setFilterEventRules}
        filterMode={filterMode}
        setFilterMode={setFilterMode}
      />
    </form>
  )
}

export default CreateApiDestination
