import styled from "styled-components"

import TableRowSimple from "components/Ui/Table/TableRowSimple"

type StyledProps = {
  missingCapabilities: boolean
}

export const Container = styled.div`
  width: 100%;
`

export const StyledTableRow = styled(TableRowSimple)<StyledProps>`
  padding: 1rem;
  margin: 0 0 1rem 0;
  background: ${(p) => p.missingCapabilities && p.theme.colors.errorBackground};

  > div {
    justify-content: space-between;
  }

  &:hover {
    background: ${(p) =>
      p.missingCapabilities ? p.theme.colors.errorBackground : "rgba(255, 255, 255, 0.5)"};
    cursor: default;
  }
`

export const Name = styled.div`
  display: flex;

  img {
    width: 2.4rem;
    margin: 0 1.5rem 0 0.5rem;
  }
`

export const Actions = styled.div<StyledProps>`
  display: flex;
  justify-content: end;

  > button {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border: ${(p) => p.missingCapabilities && `0.1rem solid ${p.theme.colors.greyDark}`};

    svg {
      height: 1.4rem;
    }
  }
`

export const NotFound = styled.p`
  text-align: center;
  ${(p) => p.theme.bold};
`
