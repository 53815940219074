import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import GET_EXTERNAL_EVENTS_DESTINATION_API from "../../../../graphql/queries/settings/externalEvents/GetExternalEventsDestinationAPI"
import PageHeader from "../../../../components/Ui/Page/PageHeader"
import ConnectedEventRules from "../../../../components/ExternalEvents/DestinationApi/ConnectedEventRules"
import { ExternalEventDestinationApiFilter } from "lib/types/generated/graphql-types"
import dayjs from "dayjs"
import { Blur, GeneralBox, Status } from "./Shared.styled"
import CopyButton from "../../../../components/Ui/CopyButton"
import { FilterMode } from "lib/types/externalEvents"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"
import { ReactComponent as CheckIcon } from "images/icons/circle-check.svg"
import { ReactComponent as XmarkIcon } from "images/icons/circle-xmark.svg"
import DELETE_EXTERNAL_EVENTS_DESTINATION_API from "../../../../graphql/mutations/settings/externalEvents/DeleteExternalEventsDestinationAPI"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import Popup from "../../../../components/Ui/Popup"
import UPDATE_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API from "../../../../graphql/mutations/settings/externalEvents/UpdateExternalEventsDestinationAPI"

const UpdateApiDestination = () => {
  const { name } = useParams()
  const [filterEventRules, setFilterEventRules] = useState<string[]>([])
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.ALL)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const { data, loading, refetch } = useQuery(GET_EXTERNAL_EVENTS_DESTINATION_API, {
    variables: { destName: name }
  })
  const dispatch = useDispatch()
  const destinationApi = data?.getExternalEventsDestinationAPI
  const navigate = useNavigate()

  const customEvents = destinationApi && destinationApi?.filters?.length > 0

  const showSideBar = () => setShowDeletePopup(true)
  const closeSideBar = () => setShowDeletePopup(false)

  const saveButtonDisabled = filterMode === FilterMode.SELECT && filterEventRules?.length === 0

  const deleteDestination = () =>
    deleteApisDestination({
      variables: {
        destName: name
      }
    })
  const updateDestination = () =>
    updateApiDestination({
      variables: {
        destName: name,
        options: {
          filterEventRules: filterMode === FilterMode.ALL ? [] : filterEventRules
        }
      }
    })

  const [deleteApisDestination, { loading: deleteLoading }] = useMutation(
    DELETE_EXTERNAL_EVENTS_DESTINATION_API,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: `API destionation ${name} is now deleted`,
            type: "success"
          })
        )
        navigate("/settings/external-events/")
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [updateApiDestination, { loading: updateLoading }] = useMutation(
    UPDATE_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: `API destionation ${name} is now updated`,
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  useEffect(() => {
    if (customEvents) {
      setFilterEventRules(
        destinationApi.filters.map(
          (destinationApiFilter: ExternalEventDestinationApiFilter) => destinationApiFilter.ruleName
        )
      )
      setFilterMode(FilterMode.SELECT)
      return
    }
    setFilterEventRules([])
  }, [destinationApi])

  if (loading) return getLoadingElements()

  return (
    <div>
      {showDeletePopup && (
        <Popup
          title="Delete API destination?"
          subtitle="Are you sure you want to delete the following:"
          buttonText="Yes, delete"
          item={{ itemId: undefined, itemName: name ?? "" }}
          loading={deleteLoading}
          type="delete"
          handleOkClick={deleteDestination}
          handleCloseClick={closeSideBar}
        />
      )}
      <PageHeader
        title={`Update Webhook Destination API`}
        id={name}
        goBackLinkText={"Back to external events"}
        goBackLinkUrl={"/settings/external-events"}
      >
        <PrimaryButton mode={"delete"} handleClick={showSideBar}>
          Delete
        </PrimaryButton>
        <PrimaryButton handleClick={updateDestination} loading={updateLoading} disabled={saveButtonDisabled}>
          Save
        </PrimaryButton>
      </PageHeader>
      <h2>{destinationApi.name}</h2>
      <GeneralBox>
        <Status $active={destinationApi.active}>
          <b>Active: </b>
          {destinationApi.active ? <CheckIcon /> : <XmarkIcon />}
        </Status>
        <div>
          <b>Name: </b>
          {destinationApi.name}
        </div>
        <div>
          <b>Endpoint: </b>
          {destinationApi.endpoint} <CopyButton string={destinationApi.endpoint} />
        </div>
        <div>
          <b>Created: </b>
          {dayjs(destinationApi.created).format("YYYY-MM-DD HH:mm")}
        </div>
      </GeneralBox>
      <ConnectedEventRules
        filterEventRules={filterEventRules}
        setFilterEventRules={setFilterEventRules}
        filterMode={filterMode}
        setFilterMode={setFilterMode}
      />
    </div>
  )
}

const getLoadingElements = () => (
  <div>
    <PageHeader
      title={`Update Webhook Destination API`}
      goBackLinkText={"Back to external events"}
      goBackLinkUrl={"/settings/external-events"}
    ></PageHeader>
    <Blur>
      <h2>General info</h2>
      <GeneralBox>
        <Status $active={true}>
          <b>Active: </b>
          <CheckIcon />
        </Status>
        <div>
          <b>Name: </b>
          Destination name
        </div>
        <div>
          <b>Endpoint: </b>
          https://endpoint.com
        </div>
        <div>
          <b>Created: </b>
          {dayjs().format("YYYY-MM-DD HH:mm")}
        </div>
      </GeneralBox>
    </Blur>
  </div>
)

export default UpdateApiDestination
