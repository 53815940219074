import styled from "styled-components"
import { ReactComponent as XMarkCircle } from "images/icons/circle-xmark.svg"
import { ReactComponent as CircleInfo } from "images/icons/circle-info.svg"

type StyledProps = {
  $blockingState: boolean
}

export const CustomStateComponent = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  border-radius: 0.75rem;
  background: ${({ theme, $blockingState }) =>
    $blockingState ? theme.colors.errorBackground : theme.colors.yellowLight};
  color: ${({ theme, $blockingState }) =>
    $blockingState ? theme.colors.errorText : theme.colors.orange};
  font-weight: 700;
  border: 0.1rem solid
    ${({ theme, $blockingState }) =>
      $blockingState ? theme.colors.errorBorder : theme.colors.yellow};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`

export const CustomStateInfo = styled.div`
  padding: 0.3rem 0.8rem;
  justify-content: center;
  cursor: default;
  display: flex;
  align-items: center;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 1rem;
  }
`

export const CustomStateButton = styled.div<StyledProps>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  border-radius: 0 0.75rem 0.75rem 0;
  border-left: 0.1rem solid
    ${({ theme, $blockingState }) =>
      $blockingState ? theme.colors.errorBorder : theme.colors.yellow};

  svg {
    fill: ${({ theme }) => theme.colors.greyDark};
    height: 1.2rem;
    width: 1.2rem;
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.greyLighter};
  }
`

export const CustomStateWrapper = styled.div`
  margin: 1rem 0;
`

export const Operation = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 1rem;
    margin-right: 0.5rem;
    fill: ${({ theme }) => theme.colors.errorText};
  }
`

export const OperationWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  font-size: 1.1rem;
  padding: 1rem;
  border-radius: 0.6rem;
`

export const BlockingIcon = styled(XMarkCircle)`
  fill: ${({ theme }) => theme.colors.errorText};
`

export const InformativeIcon = styled(CircleInfo)`
  fill: ${({ theme }) => theme.colors.orange};
`
export const Id = styled.div`
  font-size: 1.1rem;
`
