import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import esb from "elastic-builder"
import { Discount } from "@lib/types"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { transformLogicData } from "helpers/transformLogicData"
import {
  DiscountCodeRule,
  DiscountCodeSearchHit,
  InputCreateOrUpdateDiscountCodeRule
} from "@lib/types/generated/graphql-types"
import { Query, RULE_TYPE } from "lib/types/common"

import CREATE_OR_UPDATE_DISCOUNT_CODE_RULE from "graphql/mutations/discount/CreateOrUpdateDiscountCodeRule"
import CREATE_OR_UPDATE_CART_RULE from "graphql/mutations/discount/CreateOrUpdateCartRule"
import CREATE_OR_UPDATE_DISCOUNT_EXTERNAL_RULE from "graphql/mutations/discount/CreateOrUpdateDiscountExternalRule"
import VALIDATE_EXTERNAL_RULE_ID from "graphql/queries/discount/ValidateDiscountExternalRuleId"

import { ButtonWrapper, IconRight, IconLeft } from "./CreateDiscountRule.styled"

import PageHeader from "components/Ui/Page/PageHeader"
import LogicRules from "components/Discount/LogicRules"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import SecondaryButton from "components/Ui/Buttons/SecondaryButton"
import Status from "components/Ui/Status"
import AddDiscountCodes from "components/Discount/DiscountCodeRule/AddDiscountCodes"
import TypeSelector from "components/Discount/DiscountRule/TypeSelector"
import DiscountRuleGeneralInput from "components/Discount/DiscountRule/DiscountRuleGeneralInput"

import {
  ReactComponent as SaveIcon,
  ReactComponent as SaveAndActivateIcon
} from "images/icons/floppy-disk-circle-arrow-right.svg"
import { ReactComponent as ActivateIcon } from "images/icons/plug-circle-check.svg"
import { ReactComponent as NotActivateIcon } from "images/icons/plug-circle-xmark.svg"

enum Step {
  TYPE,
  GENERAL,
  LOGIC_RULES,
  ACTIVATION
}

interface ExtendedInputCreateOrUpdateDiscountCodeRule extends InputCreateOrUpdateDiscountCodeRule {
  id?: string
}

export const CreateDiscountRule = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [selectedType, setSelectedType] = useState<RULE_TYPE | undefined>()
  const [step, setStep] = useState<Step>(Step.TYPE)
  const [discountRule, setDiscountRule] = useState<DiscountCodeRule | undefined>(undefined)
  const [discountCodes, setDiscountCodes] = useState<DiscountCodeSearchHit[]>([])
  const [logicState, setLogicState] = useState<Discount.LogicState>({
    [uuidv4()]: {
      conditions: [],
      outcomes: []
    }
  })
  const [validationError, setValidationError] = useState<boolean>(false)
  const [validateRuleId, { loading: validateLoading }] = useLazyQuery(VALIDATE_EXTERNAL_RULE_ID)

  const setGeneralStep = () => {
    setStep(Step.GENERAL)
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    reset,
    watch,
    trigger
  } = useForm<ExtendedInputCreateOrUpdateDiscountCodeRule>({ mode: "onChange" })

  const isStackable = watch("isStackable")
  const idField = watch("id")

  useEffect(() => {
    setValidationError(false)
  }, [idField])

  const getMutation = () => {
    switch (selectedType) {
      case RULE_TYPE.CART_RULE:
        return CREATE_OR_UPDATE_CART_RULE
      case RULE_TYPE.CODE_RULE:
        return CREATE_OR_UPDATE_DISCOUNT_CODE_RULE
      default:
        return CREATE_OR_UPDATE_DISCOUNT_EXTERNAL_RULE
    }
  }

  const getTypeKey = () => {
    switch (selectedType) {
      case RULE_TYPE.CART_RULE:
        return "createOrUpdateDiscountRule"
      case RULE_TYPE.CODE_RULE:
        return "createOrUpdateDiscountCodeRule"
      default:
        return "createOrUpdateDiscountExternalRule"
    }
  }

  const [addDiscountRule, { loading: createLoading }] = useMutation(getMutation(), {
    onCompleted: (data) => {
      if (!discountRule && selectedType === RULE_TYPE.CODE_RULE)
        setDiscountRule(data?.createOrUpdateDiscountCodeRule)
      if (selectedType !== RULE_TYPE.CODE_RULE) {
        navigate(getUrl(data[getTypeKey()].id) ?? "")
      }
    }
  })

  const getUrl = (id: string) => {
    switch (selectedType) {
      case RULE_TYPE.CART_RULE:
        return `/discounts/cart-rules/${id}`
      case RULE_TYPE.CODE_RULE:
        return `/discounts/discount-code-rules/${id}`
      case RULE_TYPE.EXTERNAL_RULE:
        return `/discounts/external-rules/${id}`
    }
  }

  const validateId = async () => {
    const id = getValues("id")
    const referenceSearchQuery = esb
      .requestBodySearch()
      .query(esb.boolQuery().must(esb.termsQuery("id", id)))
    const query = referenceSearchQuery.toJSON() as Query
    const response = await validateRuleId({
      variables: {
        from: 0,
        size: 1000,
        query: JSON.stringify(query.query)
      }
    })
    if (response.data.searchDiscountExternalRules.total !== 0) {
      setValidationError(true)
      window.scrollTo(0, 0)
    } else {
      setStep(Step.LOGIC_RULES)
    }
  }

  const onSubmit: SubmitHandler<ExtendedInputCreateOrUpdateDiscountCodeRule> = (data) => {
    const { id, name, isStackable, applyLast, isExclusive, sortOrder, validDateRange, isActive } =
      data
    const transformedData = transformLogicData(logicState)
    addDiscountRule({
      variables: {
        id: id ?? uuidv4(),
        name,
        isActive: isActive || false,
        isStackable: isStackable || false,
        ...(selectedType !== RULE_TYPE.CART_RULE && { applyLast: applyLast || false }),
        ...(selectedType !== RULE_TYPE.CART_RULE && { isExclusive: isExclusive || false }),
        sortOrder,
        ...transformedData,
        ...(validDateRange?.from &&
          validDateRange.to && {
            validDateRange: {
              from: new Date(validDateRange.from),
              to: new Date(validDateRange.to)
            }
          })
      }
    })
      .then(() => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Discount rule successfully created",
            type: "success"
          })
        )
        setStep(Step.ACTIVATION)
        if (discountRule) navigate(getUrl(discountRule.id) ?? "")
      })
      .catch((error) =>
        dispatch(
          alertActions.actions.createAlert({
            type: "error",
            message: error.message
          })
        )
      )
  }

  const activateCode = () => {
    addDiscountRule({
      variables: { ...discountRule, isActive: true }
    })
      .then(() => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Discount rule successfully activated",
            type: "success"
          })
        )
        if (discountRule) navigate(`/discounts/discount-code-rules/${discountRule.id}`)
      })
      .catch((error) =>
        dispatch(
          alertActions.actions.createAlert({
            type: "error",
            message: error.message
          })
        )
      )
  }

  useEffect(() => {
    if (!isStackable) {
      setValue("isExclusive", false)
      setValue("applyLast", false)
    }
  }, [isStackable, setValue])

  if (createLoading) return <BrinkLoader />

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={
            <>
              {`Create ${discountRule ? discountRule.name : "new discount rule"}`}{" "}
              {discountRule && <Status status="Inactive" />}
            </>
          }
          group="Discount rules"
          id={discountRule?.id}
        >
          {step === Step.ACTIVATION && discountRule && selectedType === RULE_TYPE.CODE_RULE && (
            <>
              <SecondaryButton
                type={"button"}
                handleClick={() => navigate(`/discounts/discount-code-rules/${discountRule.id}`)}
              >
                <NotActivateIcon /> Continue without activating
              </SecondaryButton>
              {discountCodes?.length > 0 && (
                <PrimaryButton loading={createLoading} handleClick={activateCode}>
                  <ActivateIcon /> Activate discount code rule
                </PrimaryButton>
              )}
            </>
          )}
        </PageHeader>

        {step === Step.TYPE && (
          <TypeSelector setGeneralStep={setGeneralStep} setSelectedType={setSelectedType} />
        )}

        {step === Step.GENERAL && (
          <div>
            <DiscountRuleGeneralInput
              control={control}
              isStackable={isStackable}
              errors={errors}
              selectedType={selectedType}
              setValue={setValue}
              trigger={trigger}
              validationError={validationError}
            />
            <ButtonWrapper>
              <SecondaryButton
                handleClick={() => {
                  reset()
                  setSelectedType(undefined)
                  setStep(Step.TYPE)
                }}
              >
                <IconLeft /> Back to type
              </SecondaryButton>
              <PrimaryButton
                loading={validateLoading}
                disabled={!isValid}
                handleClick={() => {
                  validateId()
                }}
              >
                Logic rules <IconRight />
              </PrimaryButton>
            </ButtonWrapper>
          </div>
        )}
        {step === Step.LOGIC_RULES && (
          <div>
            <LogicRules state={logicState} setState={setLogicState} />
            {selectedType === RULE_TYPE.CODE_RULE ? (
              <ButtonWrapper>
                <SecondaryButton handleClick={() => setStep(Step.GENERAL)}>
                  <IconLeft /> Back to general
                </SecondaryButton>
                <PrimaryButton
                  disabled={logicState[Object.keys(logicState)[0]].outcomes.length === 0}
                  type={"submit"}
                >
                  <SaveIcon />
                  Save and add codes
                </PrimaryButton>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <SecondaryButton handleClick={() => setStep(Step.GENERAL)}>
                  <IconLeft /> Back to general
                </SecondaryButton>
                <PrimaryButton
                  loading={!getValues("isActive") && createLoading}
                  handleClick={() => setValue("isActive", false)}
                  disabled={logicState[Object.keys(logicState)[0]].outcomes.length === 0}
                  type="submit"
                >
                  <SaveIcon />
                  Save
                </PrimaryButton>
                <PrimaryButton
                  loading={getValues("isActive") && createLoading}
                  handleClick={() => setValue("isActive", true)}
                  type="submit"
                  disabled={logicState[Object.keys(logicState)[0]].outcomes.length === 0}
                >
                  <SaveAndActivateIcon />
                  Save and activate discount rule
                </PrimaryButton>
              </ButtonWrapper>
            )}
          </div>
        )}
        {step === Step.ACTIVATION && selectedType === RULE_TYPE.CODE_RULE && (
          <div>
            <AddDiscountCodes
              discountRule={discountRule}
              discountCodes={discountCodes}
              setDiscountCodes={setDiscountCodes}
              loading={createLoading}
            />
          </div>
        )}
      </form>
    </>
  )
}
