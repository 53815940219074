import React, { ChangeEvent, useState } from "react"
import styled from "styled-components"
import { TypedDocumentNode, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "lib/store"
import { isSuperUser } from "helpers/user"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import alertActions from "lib/store/services/Alert/AlertSlice"

import Popup from "components/Ui/Popup"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "../Form/Input"

import { ReactComponent as LockIcon } from "images/icons/lock.svg"
import { ReactComponent as UnlockIcon } from "images/icons/unlock.svg"

const SecureDelete = styled.div`
  border-radius: 0.5rem;

  p {
    margin: 0;
  }

  div {
    padding: 0 0 1rem 0;
  }
`

const InputWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  position: relative;

  input {
    padding-left: 4.5rem;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    z-index: 1;
    top: 1.3rem;
    left: 1.5rem;
  }
`

type Props = {
  id: string
  name: string
  mutation: TypedDocumentNode
  entity: string
  redirectUrl?: string
  refetch?: () => void
  deleteWithId?: (id: string) => void
  identifier?: string
  postDeleteFunction?: () => void
  secureDelete?: boolean
}

const DeleteButton = ({
  id,
  name,
  mutation,
  entity,
  redirectUrl,
  refetch,
  deleteWithId,
  postDeleteFunction,
  identifier = "id",
  secureDelete
}: Props) => {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState<string>("")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [deleteFunction, { loading }] = useMutation(mutation, {
    onCompleted: (data) => {
      deleteWithId && deleteWithId(id)
      if (data) {
        dispatch(
          alertActions.actions.createAlert({
            message: `${entity} "${name}" successfully deleted`,
            type: "success"
          })
        )
        if (postDeleteFunction) postDeleteFunction()
        setOpen(false)
        if (refetch) refetch()
        dispatch(hideEditSidebar())
        if(redirectUrl) navigate(redirectUrl)
      } else {
        alertActions.actions.createAlert({
          type: "error"
        })
      }
    },
    onError: (error) => {
      setOpen(false)
      dispatch(
        alertActions.actions.createAlert({
          message: { error }.error.message,
          type: "error"
        })
      )
    }
  })

  const onSecureDelete = () => {
    deleteFunction({
      variables: {
        [identifier]: id
      }
    })
  }

  const onDelete = () => {
    deleteFunction({
      variables: {
        [identifier]: id
      }
    })
  }

  const closePopup = () => {
    setOpen(false)
    setInput("")
  }

  const matchInput = () => {
    return input === name
  }

  return (
    <>
      <PrimaryButton
        mode="delete"
        type="button"
        handleClick={() => setOpen(true)}
        disabled={!isSuperUser()}
      >
        Delete
      </PrimaryButton>
      {open && (
        <Popup
          title={`Delete ${entity}`}
          subtitle={`Are you sure you want to delete the following ${entity}?`}
          item={{ itemId: id, itemName: name }}
          handleCloseClick={closePopup}
          handleOkClick={secureDelete ? onSecureDelete : onDelete}
          type="delete"
          loading={loading}
          disableOkButton={secureDelete && !matchInput()}
        >
          {secureDelete && (
            <>
              <SecureDelete>
                <p>
                  Input &quot;<b>{name}</b>&quot; to confirm delete:
                </p>
                <InputWrapper>
                  {matchInput() ? <UnlockIcon /> : <LockIcon />}
                  <Input
                    name="deleteName"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
                  />
                </InputWrapper>
              </SecureDelete>
            </>
          )}
        </Popup>
      )}
    </>
  )
}

export default DeleteButton
