import React from "react"
import EditSidebar from "../../Ui/EditSidebar/EditSidebar"
import Input from "../../Ui/Form/Input"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import EditSidebarHeader from "../../Ui/EditSidebar/EditSidebarHeader"
import { InputWrapper, Label } from "./Shared.styled"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import CREATE_OR_UPDATE_REASON_CODE from "../../../graphql/mutations/settings/reasonCodes/CreateOrUpdateReasonCode"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { handleErrorMessages } from "helpers/errors"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { CustomReasonCode } from "@lib/types/generated/graphql-types"
import {ReactComponent as OptionIcon} from "images/icons/timeline.svg"

type ReasonInput = {
  code: string
  input: {
    label: string
  }
}

type Props = {
  refetch: () => void
  customReasonCodes: CustomReasonCode[]
}

const AddReasonCode = ({ refetch, customReasonCodes }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ReasonInput>()

  const dispatch = useDispatch()

  const [createReasonCode, { loading }] = useMutation(CREATE_OR_UPDATE_REASON_CODE, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "New definition created",
          type: "success"
        })
      )
      refetch()
      reset()
      dispatch(hideEditSidebar())
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const onSubmit: SubmitHandler<ReasonInput> = (data) => {
    createReasonCode({ variables: data })
  }

  return (
    <EditSidebar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader title={"New Definition"} icon={<OptionIcon/>}>
          <PrimaryButton type={"submit"} loading={loading}>
            Create definition
          </PrimaryButton>
        </EditSidebarHeader>

        <InputWrapper>
          <Label>Reason code</Label>
          <Input
            name={"code"}
            placeholder={"Code"}
            register={register}
            validation={{
              required: "Field is required",
              validate: (value) =>
                !customReasonCodes.find(customCode => customCode.code === value) ||
                "Code already exists"
            }}
            hooksErrorMessage={true}
            errors={errors}
          />
          <Input name={"input.label"} placeholder={"Name"} register={register} />
        </InputWrapper>
      </form>
    </EditSidebar>
  )
}

export default AddReasonCode
