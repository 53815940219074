import React, { useState } from "react"
import { useQuery } from "@apollo/client"

import GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES from "../../../graphql/queries/settings/externalEvents/GetExternalEventsDestinationEventBuses"
import EventBusesTable from "./EventBusesTable"
import { AddButton, Box } from "../Shared.styled"
import { ReactComponent as AWSIcon } from "../../../images/icons/aws-brands-solid.svg"
import CreateEventBus from "../../../views/Settings/ExternalEvents/EventBuses/CreateEventBus"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"

const EventBuses = () => {
  const [createEventBus, setCreateEventBus] = useState(false)
  const { data, loading, error, refetch } = useQuery(GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES)
  const eventBuses = data?.getExternalEventsDestinationEventBuses?.eventBuses
  const dispatch = useDispatch()


  return (
    <div>
      {createEventBus && <CreateEventBus setCreateEventBus={setCreateEventBus} refetch={refetch}/>}
      <h2>AWS Event buses</h2>
      <Box>
        <EventBusesTable
          eventBuses={eventBuses}
          loading={loading}
          error={error}
          refetch={refetch}
        />
        <AddButton
          onClick={() => {
            setCreateEventBus(true)
            dispatch(showEditSidebar())
          }}
        >
          <AWSIcon/>
          Add new Event bus
        </AddButton>
      </Box>
    </div>
  )
}

export default EventBuses
