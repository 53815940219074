import styled from "styled-components"
import SingleSelect from "../../../Ui/Form/SingleSelect"

type StyledProps = {
  $active: boolean
}

export const Container = styled.div<StyledProps>`
  border-left: 0.3rem solid ${(p) => p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.greyDark};
  padding: 0 0 1rem 1rem;
`

export const Conditions = styled.div`
  margin-bottom: 3rem;

  p {
    text-align: center;
  }
`

export const TagRow = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.6rem;
  margin-bottom: 0.3rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }
`

export const TagHeader = styled.div`
  padding: 0.9rem 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`

export const TagKey = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  ${(p) => p.theme.bold};

  svg {
    margin-right: 0.6rem;
  }

`

export const TagValues = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 2rem 2rem;
  background: rgba(0, 0, 0, 0.02);
`

export const TagValue = styled.div`
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin: 1rem 0.8rem 0 0;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  ${(p) => p.theme.bold}
  display: inline-flex;
  align-items: center;
`

export const Label = styled.span`
  padding: 0 1rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 1.2rem;
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.6);
    transition: 0.2s;
    outline: none;

    &:hover {
      fill: rgba(0, 0, 0, 1);
    }
  }
`

export const RemoveTagValue = styled.span`
  border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.greyLighter};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 0.7rem;
  cursor: pointer;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: ${(p) => p.theme.colors.black};
  }

  &:hover {
    background: ${(p) => p.theme.colors.greyLight};
  }
`

export const TagSelect = styled(SingleSelect)`
  margin: 1rem 0;
`

export const Tags = styled.div`
  margin-bottom: 5rem;
`

export const AddTagCondition = styled.button`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 1.4rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  left: 50%;
  margin-left: -1.4rem;
  margin-top: 0;
  cursor: pointer;
  transition: 0.2s;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${(p) => p.theme.colors.black};
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    &:hover {
      background: ${(p) => p.theme.colors.turquoiseDark};
      border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};

      svg {
        fill: ${(p) => p.theme.colors.white};
      }
    }
  }
`

export const EmptyTags = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.6rem;
  padding: 0.5rem 0;
  position: relative;
  margin-bottom: 1rem;

  p {
    ${(p) => p.theme.bold};
  }
`

export const Operator = styled(TagRow)`
  display: flex;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.3rem;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0 0.7rem;

  svg {
    margin-left: 1rem;
    height: 1.3rem;
  }
`

export const OperatorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
