import React from "react"
import styled from "styled-components"
import { OrderShippingQliroIntegratedShipping } from "@lib/types/generated/graphql-types"
import { Item, Label, List, Value } from "components/Ui/List/List"
import { ReactComponent as IngridLogo } from "images/providers/ingrid-logo.svg"
import CopyButton from "components/Ui/CopyButton"

const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem;
  position: relative;
  min-height: 27rem;
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem 2rem;
  }
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-top: 1rem;

  svg {
    height: 2.4rem;
    margin-right: 1rem;
  }
`

const Columns = styled.div`
  flex: 1;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }
`

type Props = {
  shippingQliroIntegratedShipping: OrderShippingQliroIntegratedShipping | undefined
}

const QliroIntegratedShippingDetails = ({ shippingQliroIntegratedShipping }: Props) => {
  if (!shippingQliroIntegratedShipping?.ingrid?.deliveries) return null

  const ingridDelivery = shippingQliroIntegratedShipping?.ingrid?.deliveries.length
    ? shippingQliroIntegratedShipping?.ingrid?.deliveries[0]
    : {}

  return (
    <>
      <Title>
        <IngridLogo /> details
      </Title>
      <Container>
        <Columns>
          <List>
            <Item showAsColumn flexibleHeight>
              <>
                <Label>Reference:</Label>
                <Value>
                  <>{shippingQliroIntegratedShipping?.reference}</>
                  <CopyButton string={shippingQliroIntegratedShipping?.reference} />
                </Value>
              </>
            </Item>
            <Item>
              <Label>Delivery type:</Label>
              <Value capitalize>{ingridDelivery?.deliveryType}</Value>
            </Item>
            <Item showAsColumn flexibleHeight>
              <>
                <Label>Transport ID:</Label>
                <Value>
                  <>{ingridDelivery?.tosId}</>
                  <CopyButton string={ingridDelivery?.tosId} />
                </Value>
              </>
            </Item>
            <Item>
              <Label>Carrier product ID:</Label>
              <Value>{ingridDelivery?.carrierProductId}</Value>
            </Item>
          </List>
          <List>
            <Item>
              <Label>Name:</Label>
              <Value>{ingridDelivery?.deliveryAddress?.name ?? "-"}</Value>
            </Item>
            <Item>
              <Label>Address:</Label>
              <Value>
                {ingridDelivery.deliveryAddress?.addressLines
                  ? ingridDelivery?.deliveryAddress?.addressLines[0]
                  : "-"}
              </Value>
            </Item>
            <Item>
              <Label>City:</Label>
              <Value>{ingridDelivery?.deliveryAddress?.city ?? "-"}</Value>
            </Item>
            <Item>
              <Label>Postal code:</Label>
              <Value>{ingridDelivery?.deliveryAddress?.postalCode ?? "-"}</Value>
            </Item>
          </List>
        </Columns>
      </Container>
    </>
  )
}

export default QliroIntegratedShippingDetails
