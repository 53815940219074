import styled from "styled-components"

export const StockAndAdd = styled.div`
  max-width: 20rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  white-space: nowrap;

  > div {
    padding: 0;
  }

  button {
    flex-shrink: 0;
  }

  input {
    height: 4rem;
  }
`
