import React, { RefObject, useEffect, useState } from "react"
import styled from "styled-components"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { Common } from "@lib/types"
import { FrontendOutcome, Outcomes, TagCondition } from "@lib/types/discount"
import { DiscountValue } from "@lib/types/generated/graphql-types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import { Percentage as PercentageHelper } from "helpers/Percentage"

import SingleSelect from "components/Ui/Form/SingleSelect"
import Input from "components/Ui/Form/Input"
import QuickAdd from "components/Ui/Table/QuickAdd"
import { TagConditions } from "components/Discount/Ui/TagConditions/TagConditions"
import Checkbox from "components/Ui/Form/Checkbox"
import FixedDiscounts from "../../FixedDiscounts"

const ConditionsAdd = styled(QuickAdd)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
  display: block;
`

const Percentage = styled.div`
  width: 100%;
  flex: auto !important;
  margin-top: 2rem;
  gap: 1rem;
  position: relative;
`

const RecurrentCheckbox = styled(Checkbox)`
  padding: 0.5rem 0 0;
  margin-bottom: 1.5rem;
`

type Inputs = {
  valueType: string
  triggerQuantity: number
  discountQuantity: number
  percentage: number
  percentageDecimals: number
  currency: string
  amount: number
  isRecurrent: boolean
  discountValue: DiscountValue
}

type Props = {
  outcome?: FrontendOutcome
  addOutcome: (data: Outcomes) => void
  currencyOptions: Common.Option[]
  submitRef: RefObject<HTMLButtonElement | null>
}

const getOption = (type: string) => {
  switch (type) {
    case "RELATIVE":
      return {
        value: "RELATIVE",
        label: "Percentage"
      }
    case "FIXED":
      return {
        value: "FIXED",
        label: "Fixed amount"
      }
    default:
      return {
        label: "Select discount value type",
        value: ""
      }
  }
}

export const BuyGet = ({ outcome, addOutcome, currencyOptions, submitRef }: Props) => {
  const dispatch = useAppDispatch()
  const [fixedDiscounts, setFixedDiscounts] = useState<Common.Money[]>(
    outcome?.data?.discountValue?.fixedDiscounts ?? []
  )
  const [valueType, setValueType] = useState<string>(outcome?.data?.discountValue?.type ?? "")
  const [tagConditions, setTagConditions] = useState<TagCondition[]>(
    outcome?.data?.discountItemRule?.tagConditions ?? []
  )
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(
    outcome?.data?.discountItemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPrices, setExcludeFullPrices] = useState(
    outcome?.data?.discountItemRule?.excludeFullPrices ?? false
  )

  const [relativeDiscount, setRelativeDiscount] = useState<Common.RelativeDiscount>({
    percentage: outcome?.data?.discountValue?.percentage ?? undefined,
    percentageDecimals: outcome?.data?.discountValue?.percentageDecimals ?? 0
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<Inputs>()

  useEffect(() => {
    reset({
      percentageDecimals: relativeDiscount?.percentageDecimals,
      percentage: relativeDiscount?.percentage
    })
  }, [relativeDiscount])

  const updateValueType = (valueType: Common.Option) => {
    setValueType(valueType.value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!valueType) {
      dispatch(
        alertActions.actions.createAlert({
          message: "Please select a discount value type",
          type: "error"
        })
      )
      return
    }
    if (valueType === "FIXED" && fixedDiscounts.length === 0) {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one fixed discount is required",
          type: "error"
        })
      )
      return
    }
    addOutcome({
      isRecurrent: data.isRecurrent,
      triggerQuantity: data.triggerQuantity,
      discountQuantity: data.discountQuantity,
      discountValue: {
        type: valueType,
        ...(valueType === "FIXED" && {
          fixedDiscounts: fixedDiscounts,
          percentage: undefined,
          percentageDecimals: undefined
        }),
        ...(valueType === "RELATIVE" && {
          percentage: data.percentage,
          percentageDecimals: data.percentageDecimals,
          fixedDiscounts: []
        })
      },
      discountItemRule: {
        tagConditions,
        excludeDiscountedPrices,
        excludeFullPrices
      }
    })
  }

  return (
    <>
      <ConditionsAdd>
        <form>
          <RecurrentCheckbox
            control={control}
            name="isRecurrent"
            label="Is recurrent"
            description="Defines if an outcome is recurrent"
            defaultValue={outcome?.data?.isRecurrent ?? false}
          />
          <Controller
            name="triggerQuantity"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Trigger quantity *"
                description="Quantity that is used as a trigger"
                errors={errors}
              />
            )}
            defaultValue={outcome?.data?.triggerQuantity}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              }
            }}
          />
          <Controller
            name="discountQuantity"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Discount quantity *"
                description="Quantity that will be discounted"
                errors={errors}
              />
            )}
            defaultValue={outcome?.data?.discountQuantity}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              },
              validate: (value, { triggerQuantity }) =>
                Number(value) <= Number(triggerQuantity) ||
                "Discount quantity can't be bigger than trigger quantity"
            }}
          />
          <Controller
            name="valueType"
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={[getOption("FIXED"), getOption("RELATIVE")]}
                setValue={updateValueType}
                defaultValue={valueType ? getOption(valueType) : getOption("")}
              />
            )}
            control={control}
          />

          <>
            {valueType === "RELATIVE" && (
              <Percentage>
                <Controller
                  name="percentage"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Percentage, 1 - 100"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setRelativeDiscount({
                          ...relativeDiscount,
                          percentage: parseInt(event.target.value)
                        })
                      }
                      label="Percentage value *"
                      errors={errors}
                    />
                  )}
                  control={control}
                  rules={{
                    required: "This is a required field",
                    pattern: {
                      value: /^\d+$/,
                      message: "Only integers 0 - 9 is allowed"
                    },
                    min: {
                      value: 1,
                      message: "Min value is 1"
                    },
                    max: { value: 100000, message: "Max value is 100" }
                  }}
                />

                <Controller
                  name="percentageDecimals"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      label="Percentage decimals *"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setRelativeDiscount({
                          ...relativeDiscount,
                          percentageDecimals: parseInt(event.target.value)
                        })
                      }
                      errors={errors}
                    />
                  )}
                  control={control}
                  rules={{
                    pattern: {
                      value: /^\d+$/,
                      message: "Only integers 0 - 9 is allowed"
                    },
                    min: {
                      value: 0,
                      message: "Min value is 0"
                    },
                    max: { value: 3, message: "Max value is 3" }
                  }}
                />
                <div>
                  <strong>Formatted: </strong>
                  {new PercentageHelper(
                    relativeDiscount.percentage,
                    relativeDiscount.percentageDecimals
                  ).formatPercentage()}
                </div>
              </Percentage>
            )}
            {valueType === "FIXED" && (
              <FixedDiscounts
                fixedDiscounts={fixedDiscounts}
                setFixedDiscounts={setFixedDiscounts}
                currencyOptions={currencyOptions}
              />
            )}
          </>
          <button
            onClick={handleSubmit(onSubmit)}
            ref={submitRef}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </ConditionsAdd>
      <TagConditions
        tagConditions={tagConditions}
        setTagConditions={setTagConditions}
        excludeDiscountedPrices={excludeDiscountedPrices}
        setExcludeDiscountedPrices={setExcludeDiscountedPrices}
        excludeFullPrices={excludeFullPrices}
        setExcludeFullPrices={setExcludeFullPrices}
      />
    </>
  )
}
