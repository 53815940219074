import gql from "graphql-tag"

const DELETE_USER = gql`
  mutation deleteUser(
    $username: AWSEmail!
  ) {
    deleteUser(
      username: $username
    ) {
      status
    }
  }
`

export default DELETE_USER
