import styled from "styled-components"
import { ReactComponent as LinkIcon } from "images/icons/link-horizontal.svg"
import { StylesConfig } from "react-select"
import { Common } from "@lib/types"

export const Container = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 0.75rem;
  position: relative;
  left: -1rem;
  width: 100%;
  margin: 0.2rem 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    width: calc(100% - 3rem);
  }
`

export const Line = styled.div`
  width: 0.1rem;
  height: 2rem;
  background: ${({ theme }) => theme.colors.borderLight};
  margin-left: 1rem;
  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    margin-left: 2rem;
  }
`

export const InputWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  font-size: 1.3rem;

  div[class*="-control"] {
    height: 4.5rem;
    padding: 0;
    transition: all 0.2s;
    cursor: pointer;
    border: none;

    &:focus {
      border: none !important;
    }

    &:hover {
      border: none !important;
    }
  }
`

export const customStyles: StylesConfig<Common.Option, false> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#eff1f4" : "white",
    cursor: "pointer",
    color: "black",
    ":active": {
      backgroundColor: "#f5f7f8"
    },
    ":hover": {
      backgroundColor: "#f5f7f8"
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: "0 2rem 0 0"
  }),
  placeholder: (provided) => ({
    ...provided,
    padding: "0 2rem 0 0"
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    ":hover": {
      border: "none"
    }
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0 0.4rem 1.1rem hsla(0, 0%, 0%, 0.1)",
    overflow: "hidden",
    borderRadius: "0.5rem",
    border: "0.1rem solid #dfe3e8",
    zIndex: "100000"
  })
}

export const CustomStateIcon = styled(LinkIcon)`
  height: 2rem;
  width: 2rem;
  fill: ${({ theme }) => theme.colors.blue};
`

export const SelectedState = styled.div`
  padding: 1rem 2rem;
  font-size: 1.1rem;
`

export const Operations = styled.div`

  div {
    display: flex;
    align-items: center;
    line-height: 1.8rem;
  }

  svg {
    fill: ${({ theme }) => theme.colors.errorText};
    height: 1.1rem;
    margin-right: 0.5rem;
  }
`

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.1rem solid ${({ theme }) => theme.colors.blue};
  background: ${({ theme }) => theme.colors.blueLight};
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ActionWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 0 0.75rem 0.75rem;

  button {
    height: 3rem;
    border-radius: 0.75rem;
    font-size: 1.2rem;
    padding: 0 1.5rem;
  }
`
