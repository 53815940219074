import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 3rem 4rem;
  border-radius: 0.6rem;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const TimeLine = styled.div`
  margin-left: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    margin-left: 2rem;
  }
`
export const Events = styled.div`
  position: relative;

  &:first-child {
    padding-top: 3rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.1rem;
    background: ${(p) => p.theme.colors.greyLight};
  }
`

export const Event = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -1.8rem;
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    left: -2.7rem;
  }
`

export const IconWrapper = styled.div`
  border-radius: 50%;
  border: 0.3rem solid ${(p) => p.theme.colors.white};
  display: flex;
  align-self: flex-start;
`

export const HistoryIcon = styled.div`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.greyLight};
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: ${(p) => p.theme.colors.greyDark};
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    height: 5rem;
    width: 5rem;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
`

export const SuccessIcon = styled(HistoryIcon)`
  background: #e0f3e5;
  border: 0.1rem solid #7fcd6a;

  svg {
    fill: #7fcd6a;
  }
`

export const FailureIcon = styled(HistoryIcon)`
  background: #fbe7e6;
  border: 0.1rem solid #d53538;

  svg {
    fill: #d53538;
  }
`

export const OperationIcon = styled(HistoryIcon)`
  svg {
    padding-left: 0.2rem;
  }
`

export const CreateIcon = styled(HistoryIcon)`
  background: ${({ theme }) => theme.colors.blueLight};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue};

  svg {
    fill: ${({ theme }) => theme.colors.blue};
  }
`

export const WaitingIcon = styled(HistoryIcon)`
  background: #fff6e5;
  border: 0.1rem solid #ffb732;

  svg {
    fill: #ffb732;
  }
`

export const UserIcon = styled(HistoryIcon)`
  background: ${({ theme }) => theme.colors.turquoiseDark};
  font-size: 1.3rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  border: none;
  height: 3rem;
  width: 3rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    margin: 0 0.5rem;
    height: 4rem;
    width: 4rem;
    font-size: 1.8rem;
  }
`

export const Text = styled.div`
  margin-left: 2rem;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 5rem);
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};


  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    width: calc(100% - 10rem);
  }
`

export const Info = styled.div`
  word-wrap: break-word;
  word-break: break-all;
  width: calc(100% - 12rem);
`

export const Message = styled.div`
  text-transform: capitalize;
  word-break: break-all;
`

export const EntryDate = styled.div`
  position: relative;
  font-size: 1.2rem;
`

export const Error = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  svg {
    height: 1.2rem;
    margin: 0 0.5rem 0.1rem;
  }
`

export const User = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  color: ${(p) => p.theme.colors.greyDarker};
  position: relative;
  top: -0.6rem;
`

export const CustomStateType = styled.div`
  font-size: 1.2rem;
  position: relative;
  top: -0.6rem;
`
