import styled from "styled-components"
import PrimaryButton from "../../../../../Ui/Buttons/PrimaryButton"

type StyledProps = {
  $loading?: boolean
}

export const Container = styled.div<StyledProps>`
  filter: ${p => p.$loading ? "blur(0.3rem)" : "none"};
  -webkit-filter: ${p => p.$loading ? "blur(0.3rem)" : "none"};
  padding: 2rem;
  position: relative;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin: 0 0 1rem;
  b {
    margin-right: 1rem;
  }
`

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr 1fr;

  align-items: center;
`

export const EditWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid ${p => p.theme.colors.greyLight};
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background: ${p => p.theme.colors.turquoiseDark};
    border: none;
    svg {
      fill: ${(p) => p.theme.colors.white};
    }
  }

  svg {
    width: 1.6rem;
    fill: ${p => p.theme.colors.greyDarker};
  }
`

export const Label = styled.div`
  display: flex;
  font-weight: bold;

  svg {
    height: 2rem;
    margin-right: 1rem;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 0.9rem;
    font-weight: normal;
  }
`

export const InputWrapper = styled.div`
  margin-top: 2rem;
  div {
    padding-bottom: 1rem;
  }
  span {
    display: flex;
  }
`
export const Format = styled.div`
font-size: 1.3rem;
  padding: 1rem;
`
export const UpdateButton = styled(PrimaryButton)`
  padding: 1rem;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 0.4rem;
  margin-left: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  background: ${(p) => p.theme.colors.turquoiseDark};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    transition: opacity 0.2s;
    opacity: 0;
    z-index: 1;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;

    &:hover {
      cursor: pointer;

      &:before {
        opacity: 0.1;
      }
    }
  }

  svg {
    fill: ${(p) => p.theme.colors.white};
    width: 1.4rem;
    margin: 0;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 0 2.5rem;
  }

  p,
  h4,
  h3 {
    margin: 0;
  }
`

export const Total = styled.div`
  margin: 4rem 0 1rem;
`
