import React from "react"

import DineroFactory from "dinero.js"

import {
  CartItemContainer,
  InfoWrapper,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice,
  SalePrice
} from "./CartItem.styled"
import Money from "../../../../Money"
import { OrderLine } from "lib/types/generated/graphql-types"
import { Id } from "../Shared.styled"
import { handleImageError } from "helpers/image"

type Props = {
  item: OrderLine
  currencyCode?: string
  refetch: () => void
}

const CartItem = ({ item, currencyCode, ...props }: Props) => {
  const hasDiscount = () => item.basePriceAmount !== item.salePriceAmount

  return (
    <CartItemContainer {...props}>
      <InfoWrapper>
        <ItemImage>
          <img src={item?.imageUrl ?? ""} onError={handleImageError} />
        </ItemImage>
        <ItemDetails>
          <ItemName>{item?.name}</ItemName>
          <div>Quantity: {item.quantity}</div>
          <ItemPrice>
            <SalePrice $hasDiscount={hasDiscount()}>
              <Money
                amount={Number(item?.basePriceAmount)}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </SalePrice>
            {hasDiscount() && (
              <Money
                amount={Number(item?.salePriceAmount)}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            )}
          </ItemPrice>
          <Id>
            <b>Variant ID: </b>
            {item.productVariantId}
          </Id>
        </ItemDetails>
      </InfoWrapper>
    </CartItemContainer>
  )
}

export default CartItem
