import styled from "styled-components"

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

  svg {
    height: 1.6rem;
  }
`

export const Comment = styled.div`
display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`
