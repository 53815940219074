import React from "react"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { OrderRefund } from "@lib/types/generated/graphql-types"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import { useQuery } from "@apollo/client"
import Status from "../../../Ui/Status"
import { Label } from "../../Updates/Shared.styled"
import { Container, ProviderStatus } from "./Shared.styled"
import GET_REFUND from "../../../../graphql/queries/order/GetRefund"

type Props = {
  pendingRefund: OrderRefund
  orderRefetch: () => void
  orderLoading: boolean
}

const PendingRefund = ({ pendingRefund, orderRefetch, orderLoading }: Props) => {
  const { data, refetch, loading } = useQuery(GET_REFUND, {
    variables: { refundId: pendingRefund?.id },
    fetchPolicy: "network-only"
  })

  const refund = data?.Order?.refund as OrderRefund
  if (!refund) return null

  return (
    <Container>
      <h4>Pending refund #{pendingRefund.id}</h4>
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>{" "}
          <Status status={refund.paymentProvider.status?.current ?? ""} />
        </div>
        {refund?.shippingProvider?.status?.current && (
          <div>
            <Label>Shipping status:</Label>
            <Status status={refund?.shippingProvider?.status?.current ?? ""} />
          </div>
        )}
        {refund?.bonusProvider && (
          <div>
            <Label>Bonus status:</Label>
            <Status status={refund?.bonusProvider?.status?.current ?? ""} />
          </div>
        )}
        {refund?.giftCardProvider && (
          <div>
            <Label>Gift card status:</Label>
            <Status status={refund?.giftCardProvider?.status?.current ?? ""} />
          </div>
        )}
      </ProviderStatus>
      <PrimaryButton
        handleClick={() => {
          refetch()
          orderRefetch()
        }}
        loading={loading || orderLoading}
      >
        <RefreshIcon />
        Refresh status
      </PrimaryButton>
    </Container>
  )
}

export default PendingRefund
