import styled from "styled-components"


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`


export const Label = styled.h4`
margin: 1rem 0 0 0;
`

export const ItemIcon = styled.div`
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    object-fit: contain;
    width: 5rem;
  }
`


export const Info = styled.div`
display: flex;
  svg {
    height: 3rem;
    margin-right: 2rem;
  }
`


