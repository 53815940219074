import React, { Dispatch, SetStateAction } from "react"
import { Order } from "lib/types/generated/graphql-types"

import { ButtonWrapper, CartContainer, CartItems, EmptyContainer } from "./Cart.styled"

import CartItem from "./CartItem"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import GiftItem from "./GiftItem"
import ReleaseReason from "./ReleaseReason"
import ShippingFees from "./ShippingFees"
import DELETE_RELEASE from "../../../../../graphql/mutations/order/releases/DeleteRelease"
import { handleErrorMessages } from "helpers/errors"

type Props = {
  order: Order
  refetch: () => void
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
  summaryMode?: boolean
}

export const Cart = ({ order, refetch, setShowConfirmation, summaryMode = false }: Props) => {
  const filteredReleases = order?.Releases?.filter(
    (release) => !release.completed && !release.started
  )

  const [deleteRelease, { loading: deleteLoading }] = useMutation(DELETE_RELEASE, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })
  if (!order) return null
  return (
    <>
      {filteredReleases?.length > 0 ? (
        <>
          <h2>Release draft</h2>
          {filteredReleases?.map((release) => (
            <CartContainer key={release.id}>
              <CartItems>
                {release?.orderLines?.map((releaseOrderLine) => (
                  <CartItem
                    currencyCode={order.currencyCode}
                    key={releaseOrderLine.orderLineId}
                    item={releaseOrderLine}
                    orderLine={order?.orderLines?.find(
                      (ol) => ol.id === releaseOrderLine.orderLineId
                    )}
                  />
                ))}
                {release?.gifts?.map((releaseGift) => (
                  <GiftItem
                    currencyCode={order?.currencyCode}
                    key={releaseGift.giftId}
                    item={releaseGift}
                    orderGift={order?.gifts?.find((gift) => gift.id === releaseGift.giftId)}
                  />
                ))}
                <ShippingFees release={release} currencyCode={order.currencyCode} order={order} />
                <ReleaseReason release={release} />
              </CartItems>
              {!summaryMode && (
                <ButtonWrapper>
                  <SecondaryButton
                    loading={deleteLoading}
                    handleClick={() => deleteRelease({ variables: { releaseId: release.id } })}
                  >
                    Cancel release draft
                  </SecondaryButton>
                  <PrimaryButton handleClick={() => setShowConfirmation(true)}>Next</PrimaryButton>
                </ButtonWrapper>
              )}
            </CartContainer>
          ))}
        </>
      ) : (
        <>
          <h2>New order draft</h2>
          <EmptyContainer>
            <h2>Create release to finalize order.</h2>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
