import gql from "graphql-tag"

const CREATE_ORDER_COMMENT = gql`
  mutation createOrderComment($orderId: ID!, $comment: InputComment!) {
    Order {
      createOrderComment(orderId: $orderId, comment: $comment) {
        id
      }
    }
  }
`

export default CREATE_ORDER_COMMENT
