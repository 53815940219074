import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.mQ.MEDIA_MIN_LARGE}) {
    margin-bottom: 3rem;
  }
`

export const Content = styled.div`
  padding: 2rem;
  min-height: 45rem;

  @media (min-width: ${(p) => p.theme.mQ.MEDIA_MIN_LARGE}) {
    padding: 3rem;
  }

  .xAxis {
    padding-top: 1rem;
    font-size: 1.1rem;
    font-weight: 700;
  }
`
