import styled from "styled-components"
import Search from "../../Ui/Search"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    justify-content: flex-end;
  }

  button {
    margin: 0 0 2rem 0;
    align-self: flex-end;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin: 0 0 4rem 0;
    }
  }

  .hideOnSmallScreens {
    white-space: pre-wrap;
  }
`

export const AddButton = styled(PrimaryButton)`
  position: relative;

  div {
    display: none;
    margin: 0 0.4rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      display: block;
    }
  }
`

export const StyledSearch = styled(Search)`
max-width: none;
`
