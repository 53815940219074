import React from "react"
import { CustomState, OrderHistoryLogEntry } from "@lib/types/generated/graphql-types"
import { Id, Info, Operation, Operations } from "./Operations.styled"
import { ReactComponent as BlockedIcon } from "images/icons/circle-xmark.svg"
import { formatOperation, getMessage } from "../../helpers"
import { CustomStateType } from "../OrderHistoryLog.styled"

type Props = {
  entry: OrderHistoryLogEntry
  getCustomState: (id: string) => CustomState
  hideOperations: boolean
}

const CustomStateInfo = ({ getCustomState, entry, hideOperations }: Props) => {
  const customState = getCustomState(entry.operationId)
  return (
    <Info>
      <CustomStateType>
        {getMessage(entry)}
      </CustomStateType>
      <Id>{entry.operationId}</Id>
      {!hideOperations && customState?.operations && customState?.operations.length > 0 && (
        <Operations>
          {customState?.operations.map((operation) => (
            <Operation key={operation}>
              <BlockedIcon />
              {formatOperation(operation)}
            </Operation>
          ))}
        </Operations>
      )}
    </Info>
  )
}

export default CustomStateInfo
