import React, { useState } from "react"
import * as graphql from "lib/types/generated/graphql-types"
import FailedRelease from "./FailedRelease"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import ReleaseAction from "./Cart/ReleaseAction"
import StatusPopup from "./Cart/StatusPopup"
import { Release } from "../Helpers/Release"
import PendingRelease from "./PendingRelease"

type Props = {
  order: graphql.Order
  refetch: () => void
  loading: boolean
  failedReleases: graphql.OrderRelease[]
  orderHistory: graphql.OrderHistory
}

const ReleaseOrder = ({ order, refetch, loading, failedReleases, orderHistory }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedRelease, setStartedRelease] = useState("")
  const releases = new Release(order.Releases)

  const [giftCardAction, setGiftCardAction] = useState<graphql.InputActionGiftCard>({
    actionType: graphql.InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<graphql.InputActionBonus>({
    actionType: graphql.InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<graphql.InputActionPaymentRelease>({
    actionType: graphql.InputActionType.Auto
  })

  const filteredReleases = order?.Releases?.filter(
    (release) => !release.completed && !release.started
  )
  if (releases.pending() && !showStatusPopup)
    return (
      <PendingRelease
        pendingRelease={releases.getPendingRelease()}
        orderRefetch={refetch}
        orderLoading={loading}
      />
    )

  return (
    <>
      {showStatusPopup && startedRelease && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          releaseId={startedRelease}
          refetchOrder={refetch}
        />
      )}
      {failedReleases?.length > 0 ? (
        failedReleases.map((release: graphql.OrderRelease) => (
          <FailedRelease
            key={release.id}
            refetch={refetch}
            failedRelease={release}
            orderStatus={orderHistory.history}
            setStartedRelease={setStartedRelease}
            setShowStatusPopup={setShowStatusPopup}
          />
        ))
      ) : !showConfirmation ? (
        <Container>
          <div>
            <OrderSummary order={order} refetch={refetch} orderLoading={loading} />
          </div>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation} />
          </div>
        </Container>
      ) : (
        <Container>
          <div>
            <Cart
              order={order}
              refetch={refetch}
              setShowConfirmation={setShowConfirmation}
              summaryMode={true}
            />
          </div>
          <div>
            {filteredReleases?.map((release) => (
              <ReleaseAction
                key={release.id}
                release={release}
                actionState={{
                  giftCardAction,
                  setGiftCardAction,
                  bonusAction,
                  setBonusAction,
                  paymentAction,
                  setPaymentAction
                }}
                orderId={order.id}
                setShowStatusPopup={setShowStatusPopup}
                setStartedRelease={setStartedRelease}
                setShowConfirmation={setShowConfirmation}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default ReleaseOrder
