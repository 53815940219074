import React, { ReactNode } from "react"
import styled from "styled-components"

type StyledProps = {
  $iconHeader?: boolean
  $deleteEnabled?: boolean
}

const Container = styled.div<StyledProps>`
  width: 100%;
  display: flex;
  padding: 0 ${({ $deleteEnabled }) => $deleteEnabled ? "8.6rem" : "2rem"} 0 ${({ $iconHeader }) => ($iconHeader ? "6.4rem" : "0")};
  ${(p) => p.theme.bold}
  color: ${(p) => p.theme.colors.greyDarker};
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  font-size: 1.2rem;

  > div {
    flex: 1;
    display: flex;
    height: 4rem;
    align-items: center;
    border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    padding-left: 1.5rem;
    flex-direction: row;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      .hideOnSmallScreens {
        display: flex !important;
      }
    }
  }

  p {
    margin: 0;
  }
`

type Props = {
  children: ReactNode | ReactNode[]
  iconHeader?: boolean
  deleteEnabled?: boolean
}

const TableHeader = ({ children, iconHeader, deleteEnabled, ...props }: Props) => {
  return (
    <Container $deleteEnabled={deleteEnabled} $iconHeader={iconHeader} {...props}>
      {children}
    </Container>
  )
}

export default TableHeader
