import React, { useEffect, useState } from "react"
import { useAddItemMutation } from "lib/store/services/brink/actions"
import { useConfig } from "configuration/useConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { ProductVariantSearchHit } from "@lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"

import GET_PRODUCT_VARIANT_STOCK from "graphql/queries/product/GetProductVariantStock"

import { Id, Name, ProductImage, Row } from "../Shared.styled"
import { StockAndAdd } from "./SearchResultRow.styled"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import Status from "components/Ui/Status"
import { Loader } from "components/Ui/Loader"
import { handleImageError } from "helpers/image"

type Props = {
  productVariant: ProductVariantSearchHit
}

export const SearchResultRow = ({ productVariant }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [qty, setQty] = useState<string>("1")
  const [addItem, { error }] = useAddItemMutation()
  const { awsRegion, customerName } = useConfig().config
  const dispatch = useDispatch()

  const { data, loading } = useQuery(GET_PRODUCT_VARIANT_STOCK, {
    variables: { id: productVariant.id }
  })

  const stockQty = data?.Product?.variant?.Stock?.stockQuantity

  const onAddToCart = async (selectedVariant: string) => {
    setIsLoading(true)
    addItem({
      awsRegion,
      customerName,
      productVariantId: selectedVariant,
      quantity: parseInt(qty)
    })
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (error) {
      if ("status" in error) {
        const errMsg = "error" in error ? error.error : JSON.stringify(error?.data?.error)
        dispatch(
          alertActions.actions.createAlert({
            type: "error",
            message: errMsg
          })
        )
      }
    }
  }, [error])

  return (
    <Row key={productVariant.id}>
      <ProductImage src={productVariant?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{productVariant.name}</Name>
        <Id>{productVariant.id}</Id>
        <span>Available stock: {stockQty ?? "-"}</span>
      </div>

      {loading ? (
        <Loader color="black" />
      ) : (
        <StockAndAdd>
          {stockQty === 0 || !stockQty ? (
            <Status status="Out of stock" />
          ) : (
            <>
              Qty:
              <Input
                name="qty"
                defaultValue={qty}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setQty(e.currentTarget.value)}
              />
              <PrimaryButton handleClick={() => onAddToCart(productVariant.id)} loading={isLoading}>
                Add
              </PrimaryButton>
            </>
          )}
        </StockAndAdd>
      )}
    </Row>
  )
}
