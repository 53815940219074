import styled from "styled-components"
import ErrorMessage from "./Messages/ErrorMessage"

type StyledProps = {
  $percentage: number
}

export const Container = styled.div`
  position: fixed;
  width: 100%;
  max-width: 65rem;
  min-height: 25rem;
  background: ${(p) => p.theme.colors.background};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.6rem;
  padding: 2rem 3rem 0;
  color: ${(p) => p.theme.colors.black};
  z-index: 10001;

  h2 {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    margin-bottom: 0;
    padding-bottom: 2rem;
    width: 100%;
  }
`

export const Blur = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  cursor: pointer;
`

export const StyledErrorMessage = styled(ErrorMessage)`
  margin: 2rem 0 !important;
`

export const BarContainer = styled.div`
  width: 100%;
  height: 5rem;
  margin: 2rem 0;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(9, 174, 76);
  font-weight: 700;
  position: relative;

  span {
    z-index: 2;
  }
`

export const ProgressBar = styled.div<StyledProps>`
  background: #dcf4e5;
  height: 3.8rem;
  left: 0.5rem;
  max-width: calc(100% - 1rem);
  transition: width 0.2s;
  width: ${(p) => p.$percentage}%;
  z-index: 1;
  position: absolute;
`
