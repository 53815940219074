import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { ApiClient } from "@lib/types/generated/graphql-types"

import { Container, Name, Wrapper } from "./ApiClientTable.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"

import { ReactComponent as Code } from "images/icons/laptop-code.svg"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  apiClients: ApiClient[]
  loading?: boolean
  error: object | undefined
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

function ApiClientsTable({ apiClients, loading = false, error, setEditId }: Props) {
  const dispatch = useAppDispatch()

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading API clients. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}

        {loading && <BrinkLoader />}
        <TableHeader iconHeader={true}>
          <div>Name</div>
          <div>Client ID</div>
        </TableHeader>
        {[...apiClients].map((apiClient) => (
          <TableRow
            key={apiClient.clientId}
            handleOnClick={() => {
              setEditId(apiClient.clientId)
              dispatch(showEditSidebar())
            }}
          >
            <Code />
            <div>
              <Name>{apiClient.name}</Name>
            </div>
            <div>{apiClient.clientId}</div>
          </TableRow>
        ))}
      </Container>
    </Wrapper>
  )
}

export default ApiClientsTable
