import React, { Dispatch, SetStateAction, useState } from "react"
import {
  BasePrice,
  CloseIcon,
  DiscountInputWrapper,
  Error,
  Flex,
  Info,
  LoadingContainer,
  Price,
  ProductVariantRowContainer,
  Qty,
  QtyButton,
  Quantity,
  Status,
  TotalPrice,
  Variant,
  Variants
} from "./ProductVariant.styled"
import {
  Order,
  OrderLine,
  ProductParent,
  ProductVariant,
  TaxMarket
} from "@lib/types/generated/graphql-types"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as MinusIcon } from "images/icons/minus.svg"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { useQuery } from "@apollo/client"
import GET_TAX_MARKETS from "../../../../../graphql/queries/tax/GetTaxMarkets"
import Input from "../../../../Ui/Form/Input"
import GET_PRODUCT from "../../../../../graphql/queries/product/GetProduct"
import { Id } from "../Shared.styled"
import { handleErrorMessages } from "helpers/errors"
import { handleImageError } from "helpers/image"
import Popup from "../../../../Ui/Popup"
import { InfoWrapper } from "./ProductVariantSearchHitRow.styled"
import { Modification } from "../../Helpers/Modification"

type Props = {
  padding?: string
  productVariant: ProductVariant
  updateProductVariant?: (variant: ProductVariant | undefined) => void
  storeGroupId: string
  countryCode?: string
  currencyCode: string
  quantity: number
  setQuantity: Dispatch<SetStateAction<number>>
  discount: number | undefined
  setDiscount: Dispatch<SetStateAction<number | undefined>>
  setTaxMarket: Dispatch<SetStateAction<TaxMarket | undefined>>
  editOrderLine: OrderLine | undefined
  order: Order
  amountToHigh: boolean
}

const AddedProductVariantRow = ({
  padding,
  updateProductVariant,
  productVariant,
  storeGroupId,
  countryCode,
  currencyCode,
  quantity,
  setQuantity,
  discount,
  setDiscount,
  setTaxMarket,
  editOrderLine,
  amountToHigh,
  order
}: Props) => {
  const [productParent, setProductParent] = useState<ProductParent>()
  const [showPopup, setShowPopup] = useState(false)
  const [outOfStockVariant, setOutOfStockVariant] = useState<ProductVariant>()

  const modifications = new Modification(order.Modifications)

  if (!productVariant) return null

  const price = productVariant.StoreGroupPrices.find(
    (storeGroupPrice) => storeGroupPrice.storeGroupId === storeGroupId
  )?.marketPrices?.find((marketPrice) => marketPrice.countryCode === countryCode)?.price

  const handleChangeVariant = (variant: ProductVariant) => {
    if (variant?.Stock && variant?.Stock?.stockQuantity < 1) {
      setOutOfStockVariant(variant)
      setShowPopup(true)
      return
    }
    if (updateProductVariant) updateProductVariant(variant)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setOutOfStockVariant(undefined)
  }

  const { loading: productLoading } = useQuery(GET_PRODUCT, {
    variables: { id: productVariant.productParentId },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (data) {
        setProductParent(data.Product.parent)
      }
    },
    onError: (error) => handleErrorMessages(error)
  })

  useQuery(GET_TAX_MARKETS, {
    variables: { taxGroupId: productVariant.TaxGroup.id },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (data) {
        setTaxMarket(
          data.getTaxMarkets.find((taxMarket: TaxMarket) => taxMarket.countryCode === countryCode)
        )
      }
    },
    onError: (error) => handleErrorMessages(error)
  })

  const hasDiscount = () =>
    editOrderLine
      ? editOrderLine.salePriceAmount !== editOrderLine.basePriceAmount
      : price?.salePriceAmount !== price?.basePriceAmount

  const getVariantPrice = (productVariant: ProductVariant) =>
    productVariant.StoreGroupPrices.find(
      (storeGroupPrice) => storeGroupPrice.storeGroupId === storeGroupId
    )?.marketPrices?.find((marketPrice) => marketPrice.countryCode === countryCode)?.price

  const getTotalAmount = () => {
    if (editOrderLine) {
      return (
        (price?.basePriceAmount ?? 0) * quantity -
        modifications.getDistributedDiscountOnQty(editOrderLine, currencyCode, quantity) -
        (discount ?? 0) * quantity
      )
    }
    return (price?.salePriceAmount ?? 0) * quantity - (discount ?? 0) * quantity
  }

  const getTotalDiscountAmount = () => {
    if (editOrderLine) {
      return (
        modifications.getDistributedDiscountOnQty(editOrderLine, currencyCode, quantity) +
        (discount ?? 0) * quantity
      )
    }
    return (discount ?? 0) * quantity
  }

  return (
    <>
      <ProductVariantRowContainer $padding={padding} key={productVariant?.id}>
        <Flex>
          <img
            src={productVariant?.imageUrl ?? ""}
            alt={productVariant?.name}
            onError={handleImageError}
          />
          <Info>
            <div>{productVariant?.name}</div>
            <div>Stock: {productVariant?.Stock?.stockQuantity}</div>
            {price && (
              <Price>
                {hasDiscount() && (
                  <BasePrice>
                    <Money
                      amount={editOrderLine ? editOrderLine.basePriceAmount : price.basePriceAmount}
                      currencyUnit={price.currencyCode as DineroFactory.Currency}
                    />
                  </BasePrice>
                )}
                <div>
                  <Money
                    amount={editOrderLine ? editOrderLine.salePriceAmount : price.salePriceAmount}
                    currencyUnit={price.currencyCode as DineroFactory.Currency}
                  />
                </div>
              </Price>
            )}
            <Id>
              <b>Variant ID: </b>
              {productVariant.id}
            </Id>
            {editOrderLine && editOrderLine?.productVariantId !== productVariant.id && (
              <Id>
                <b>Changed from: </b>
                {editOrderLine?.productVariantId}
              </Id>
            )}
            <Quantity>
              <QtyButton
                onClick={() => {
                  if (quantity > 1) setQuantity(quantity - 1)
                }}
              >
                <MinusIcon />
              </QtyButton>{" "}
              <Qty>{quantity}</Qty>
              <QtyButton onClick={() => setQuantity(quantity + 1)}>
                <PlusIcon />
              </QtyButton>
            </Quantity>
          </Info>
        </Flex>

        {updateProductVariant && !editOrderLine && (
          <CloseIcon onClick={() => updateProductVariant(undefined)}>
            <TrashIcon />
          </CloseIcon>
        )}
      </ProductVariantRowContainer>
      <DiscountInputWrapper>
        <Input
          name={"discount"}
          type="number"
          label={"Discount in minor units (optional)"}
          value={discount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.currentTarget.value) {
              setDiscount(undefined)
              return
            }
            setDiscount(parseInt(e.currentTarget.value))
          }}
        />
        {(discount ?? 0) > 0 && (
          <div>
            Formatted:{" "}
            <b>
              <Money currencyUnit={currencyCode as DineroFactory.Currency} amount={discount ?? 0} />
              {quantity > 1 && ` (x ${quantity})`}
            </b>
          </div>
        )}
      </DiscountInputWrapper>
      {price && (
        <>
          <b>Total order line amount: </b>
          <TotalPrice>
            <div>
              <span>Total discount</span> -
              <Money
                currencyUnit={currencyCode as DineroFactory.Currency}
                amount={getTotalDiscountAmount()}
              />
            </div>
            <div>
              <span>Total amount</span>
              <b>
                <Money
                  currencyUnit={currencyCode as DineroFactory.Currency}
                  amount={getTotalAmount()}
                />
              </b>
            </div>
            {amountToHigh && (
              <div>
                <span />
                <Error>Amount to high</Error>
              </div>
            )}
          </TotalPrice>
        </>
      )}
      {productLoading && (
        <LoadingContainer>
          <b>Change variant within the same parent</b>
          <Variants>
            {[...Array(4)].map((_, index) => (
              <Variant key={index}>
                <img
                  src={productVariant?.imageUrl ?? ""}
                  alt={productVariant?.name}
                  onError={handleImageError}
                />
                <div>{productVariant.name}</div>
                {getVariantPrice(productVariant) && (
                  <Money
                    amount={getVariantPrice(productVariant)?.salePriceAmount ?? 0}
                    currencyUnit={
                      getVariantPrice(productVariant)?.currencyCode as DineroFactory.Currency
                    }
                  />
                )}
                <Id>
                  <b>Variant ID:</b> {productVariant.id}
                </Id>
              </Variant>
            ))}
          </Variants>
        </LoadingContainer>
      )}
      {productParent?.Variants && productParent?.Variants?.length > 1 && (
        <>
          <b>Change variant within the same parent</b>
          {showPopup && (
            <Popup
              title={"Add product variant"}
              subtitle={"Add product variant without stock?"}
              handleCloseClick={handleClosePopup}
              handleOkClick={() =>
                outOfStockVariant && updateProductVariant && updateProductVariant(outOfStockVariant)
              }
              buttonText={"Add anyway"}
            >
              <InfoWrapper>
                <img
                  src={outOfStockVariant?.imageUrl ?? ""}
                  alt={outOfStockVariant?.name}
                  onError={handleImageError}
                />
                <div>
                  <b>{outOfStockVariant?.name}</b>
                  <span>Stock: {outOfStockVariant?.Stock?.stockQuantity}</span>
                </div>
              </InfoWrapper>
            </Popup>
          )}
          <Variants>
            {productParent?.Variants?.filter((variant) => variant.id !== productVariant.id).map(
              (variant) => (
                <Variant key={variant.id} onClick={() => handleChangeVariant(variant)}>
                  {variant?.Stock && variant?.Stock?.stockQuantity < 1 && (
                    <Status>Out of stock</Status>
                  )}
                  <img
                    src={productVariant?.imageUrl ?? ""}
                    alt={productVariant?.name}
                    onError={handleImageError}
                  />
                  <div>{variant.name}</div>
                  {getVariantPrice(productVariant) && (
                    <Money
                      amount={getVariantPrice(productVariant)?.salePriceAmount ?? 0}
                      currencyUnit={
                        getVariantPrice(productVariant)?.currencyCode as DineroFactory.Currency
                      }
                    />
                  )}
                  <Id>
                    <b>Variant ID:</b> {variant.id}
                  </Id>
                </Variant>
              )
            )}
          </Variants>
        </>
      )}
    </>
  )
}

export default AddedProductVariantRow
