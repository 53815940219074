import React, { Dispatch, SetStateAction, useState } from "react"
import { CustomReasonCode, InputReason } from "@lib/types/generated/graphql-types"
import Input from "../../../Ui/Form/Input"
import {
  Button,
  Container,
  Info,
  InputWrapper,
  StyledButton,
  StyledSelect,
  Switch
} from "./ReasonInput.styled"
import { Link } from "react-router-dom"
import { ReactComponent as InfoIcon } from "../../../../images/icons/circle-info.svg"
import { ReactComponent as PlusIcon } from "../../../../images/icons/plus.svg"

enum CodeInput {
  PRE_DEFINED,
  CUSTOM_INPUT
}

type Props = {
  setReason: Dispatch<SetStateAction<InputReason>>
  reason: InputReason
  addReason?: () => void
  addReasonLoading?: boolean
  customReasons: CustomReasonCode[]
}

const ReasonInput = ({ setReason, reason, addReason, addReasonLoading, customReasons }: Props) => {
  const [codeInput, setCodeInput] = useState<CodeInput>(CodeInput.PRE_DEFINED)

  const getOptions = () => {
    return customReasons?.map((reason: CustomReasonCode) => ({
      label: `${reason.label} - ${reason.code}`,
      value: reason.code
    }))
  }

  const selectedOption = (code: string) =>
    getOptions()?.find((option: { value: string; label: string }) => option.value === code)

  const updateCode = (value: string) => setReason((prev) => ({ ...prev, code: value }))
  const updateCause = (value: string) => setReason((prev) => ({ ...prev, cause: value }))

  return (
    <Container>
      <Switch>
        <Button
          $selected={codeInput === CodeInput.PRE_DEFINED}
          onClick={() => setCodeInput(CodeInput.PRE_DEFINED)}
        >
          Pre-defined code
        </Button>
        <Button
          $selected={codeInput === CodeInput.CUSTOM_INPUT}
          onClick={() => setCodeInput(CodeInput.CUSTOM_INPUT)}
        >
          Input custom code
        </Button>
      </Switch>
      {codeInput === CodeInput.PRE_DEFINED && (
        <Info>
          <InfoIcon />
          Pre-defined reason codes handled in
          <Link to={"/settings/order-definitions"}> Policy definitions</Link>
        </Info>
      )}
      {codeInput === CodeInput.PRE_DEFINED && (
        <StyledSelect
          label={"Code"}
          options={getOptions()}
          setValue={updateCode}
          value={selectedOption(reason?.code ?? "")}
        />
      )}
      {codeInput === CodeInput.CUSTOM_INPUT && (
        <Info>
          <InfoIcon />
          Input reason code with text
        </Info>
      )}
      <InputWrapper>
        {codeInput === CodeInput.CUSTOM_INPUT && (
          <Input
            label={"Code"}
            defaultValue={reason?.code ?? ""}
            name={"code"}
            placeholder={"Code"}
            onChange={(e: React.FormEvent<HTMLInputElement>) => updateCode(e.currentTarget.value)}
          />
        )}
        <Input
          label={"Comment"}
          defaultValue={reason?.cause ?? ""}
          name={"cause"}
          placeholder={"Comment"}
          onChange={(e: React.FormEvent<HTMLInputElement>) => updateCause(e.currentTarget.value)}
        />
        {addReason && (
          <StyledButton
            disabled={!reason.cause || !reason.code || addReasonLoading}
            loading={addReasonLoading}
            handleClick={addReason}
          >
            <PlusIcon /> Add Reason
          </StyledButton>
        )}
      </InputWrapper>
    </Container>
  )
}

export default ReasonInput
