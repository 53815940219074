import styled from "styled-components"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import TableRowSimple from "components/Ui/Table/TableRowSimple"
import TableHeader from "components/Ui/Table/TableHeader"
import SingleSelectSimple from "../../Ui/Form/SingleSelectSimple"

export const AddButton = styled(PrimaryButton)`
  margin: -3rem 0 0 auto;
`

export const TresholdCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  font-size: 1.1rem;
  line-height: 1.7rem;
  padding-right: 1rem;

  span {
    line-height: 1.8rem;
    font-size: 1.4rem;
    ${(p) => p.theme.bold}
  }
`

export const CountrySelect = styled(SingleSelectSimple)`
  margin-bottom: 1rem;
`

export const MarketRuleRow = styled(TableRowSimple)`
  background: rgba(255, 255, 255, 0.5);
  padding: 1.2rem 2rem 1rem;
  margin-bottom: rem;
`
export const MarketRules = styled.div`
  width: 100%;

  p {
    text-align: center;
  }
`
export const MarketRulesHeader = styled(TableHeader)`
  padding: 0 6rem 0 2rem;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 3rem;
`

export const Country = styled(TresholdCell)`
  width: 50%;
  flex: auto;
`

export const Value = styled(TresholdCell)`
  flex: auto;
  width: calc(50% - 8rem);
`
