import React, { useState } from "react"
import { Tooltip } from "react-tooltip"
import { formatOperation } from "../helpers"
import {
  CustomState,
  CustomStateType,
  Order,
  OrderCustomState
} from "lib/types/generated/graphql-types"
import { ReactComponent as XMarkIcon } from "images/icons/xmark-thick.svg"
import { ReactComponent as XMarkCircle } from "images/icons/circle-xmark.svg"

import {
  BlockingIcon,
  CustomStateButton,
  CustomStateComponent,
  CustomStateInfo,
  CustomStateWrapper,
  Id,
  InformativeIcon,
  Operation,
  OperationWrapper
} from "./OrderCustomStates.styled"
import Popup from "../../Ui/Popup"
import { ApolloQueryResult, useMutation } from "@apollo/client"
import REMOVE_CUSTOM_STATE_FROM_ORDER from "../../../graphql/mutations/settings/customState/RemoveCustomStateFromOrder"
import { useSelector } from "react-redux"
import { handleErrorMessages } from "helpers/errors"
import { orderLoading } from "lib/store/services/Order/orderSelector"
import { isSuperUser } from "helpers/user"

type Props = {
  customStates: OrderCustomState[]
  getCustomState: (id: string) => CustomState
  orderId: string
  refetch: () => Promise<ApolloQueryResult<Order>>
  removeCustomStateFromHistory: (id: string) => void
}

const OrderCustomStates = ({
  customStates,
  getCustomState,
  orderId,
  removeCustomStateFromHistory
}: Props) => {
  const [customStateToRemove, setCustomStateToRemove] = useState<CustomState>()
  const orderSelector = useSelector(orderLoading)

  const [removeCustomState, { loading }] = useMutation(REMOVE_CUSTOM_STATE_FROM_ORDER, {
    onCompleted: () => {
      if (customStateToRemove) removeCustomStateFromHistory(customStateToRemove?.id)
      setCustomStateToRemove(undefined)
    },
    onError: (e) => {
      handleErrorMessages(e)
    }
  })

  const removeCustomStateFromOrder = () => {
    removeCustomState({ variables: { orderId: orderId, stateId: customStateToRemove?.id } })
  }

  return (
    <CustomStateWrapper>
      {customStateToRemove && (
        <Popup
          title={"Remove custom state"}
          subtitle={"Are you sure you want to remove the following state from order:"}
          handleCloseClick={() => setCustomStateToRemove(undefined)}
          buttonText={"Yes, remove"}
          handleOkClick={removeCustomStateFromOrder}
          loading={loading}
        >
          <div>
            <b>{customStateToRemove.description ?? customStateToRemove.id}</b>
            <Id>{customStateToRemove.id}</Id>
          </div>
          {customStateToRemove?.operations && customStateToRemove?.operations?.length > 0 && (
            <OperationWrapper>
              Blocking operations:
              {customStateToRemove?.operations?.map((operation) => (
                <Operation key={operation}>
                  <XMarkCircle />
                  {formatOperation(operation)}
                </Operation>
              ))}
            </OperationWrapper>
          )}
        </Popup>
      )}
      {customStates?.map((state) => (
        <CustomStateComponent
          key={state.id}
          $blockingState={state.type === CustomStateType.Blocking}
          className={orderSelector.orderLoading ? "blur" : ""}
        >
          {state?.operations && state?.operations?.length > 0 && (
            <Tooltip id={`${state.id}`}>
              <div>
                Blocking operations:{" "}
                {state?.operations?.map((operation) => (
                  <Operation key={operation}>
                    <XMarkCircle />
                    {formatOperation(operation)}
                  </Operation>
                ))}
              </div>
            </Tooltip>
          )}
          <CustomStateInfo data-tooltip-id={`${state.id}`} data-tooltip-delay-hide={100}>
            {state.type === CustomStateType.Blocking ? <BlockingIcon /> : <InformativeIcon />}
            {getCustomState(state?.id)?.description ?? state?.id}
          </CustomStateInfo>
          {isSuperUser() && (
            <CustomStateButton
              $blockingState={state.type === CustomStateType.Blocking}
              onClick={() =>
                setCustomStateToRemove(
                  getCustomState(state.id) ?? { id: state.id, description: state.id }
                )
              }
            >
              <XMarkIcon />
            </CustomStateButton>
          )}
        </CustomStateComponent>
      ))}
    </CustomStateWrapper>
  )
}

export default OrderCustomStates
