import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import { store } from "lib/store"
import App from "./App"
import { theme } from "./theme"
import { BrowserRouter } from "react-router-dom"
import GlobalStyle from "./globalStyle"
import ConfigProvider from "./configuration/useConfig"
import { ErrorBoundaryWrapper } from "./ErrorBoundary"

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)

root.render(
  <ConfigProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundaryWrapper>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ErrorBoundaryWrapper>
    </ThemeProvider>
  </ConfigProvider>
)
