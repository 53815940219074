import styled from "styled-components"

export const Flex = styled.div`
  display: flex;
`

export const TimeZoneWrapper = styled.div`
  display: flex;
  padding: 1rem;
  background-color: ${(p) => p.theme.colors.greyLighter};
  border-radius: 0.75rem;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
