import styled from "styled-components"
import TableRow from "../../Ui/Table/TableRow"
import { ReactComponent as Block } from "images/icons/circle-xmark.svg"
import { ReactComponent as Information } from "images/icons/circle-info.svg"

export const StyledTableRow = styled(TableRow)`
  > div > div {
    height: fit-content;
  }
`

export const InputWrapper = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.6rem;
  padding: 2rem;
  margin-bottom: 1rem;
`

export const Label = styled.div`
  margin: 0.5rem 0 0.5rem;
`

export const Header = styled.div`
  margin-bottom: 1rem;
  font-weight: 700;
`

export const Info = styled.div`
  display: flex;
`

export const Value = styled.div`
  margin-left: 1rem;
`

export const Subtitle = styled.div`
  margin-top: 0;
  line-height: 1rem;
  margin-bottom: 1rem;
`

export const BlockingIcon = styled(Block)`
  fill: ${({ theme }) => theme.colors.errorText};
`

export const InfoIcon = styled(Information)`
  fill: ${({ theme }) => theme.colors.yellowDark};
`

export const Operations = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Operation = styled.div`
  background: ${({ theme }) => theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 0 1rem;
  font-size: 1.1rem;
  margin: 0.2rem 1rem 0.2rem 0;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`

export const SelectContainer = styled.div`
  width: 100%;

  svg {
    margin-right: 0;
  }

  div[class*="-control"] {
    min-height: 4.5rem;
    padding: 0.1rem 0 0 0.8rem;
    border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    border-radius: 0.5rem;
    transition: all 0.2s;
    cursor: pointer;
    box-shadow: 0 0 0.5rem 0 transparent;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      &:hover,
      &:focus {
        border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
      }
    }

    div[class*="-multiValue"] {
      padding: 0 0.5rem 0 0.5rem;
      background: ${(p) => p.theme.colors.greyLighter};
      display: flex;
      align-items: center;
      margin: 0.4rem 0.4rem 0.4rem 0;
      height: 2.8rem;
    }

    div[class*="-ValueContainer"] {
      padding-left: 0;
    }

    div[class*="-MultiValueRemove"] {
      svg {
        fill: ${(p) => p.theme.colors.black};
      }

      &:hover {
        background: none;

        svg {
          fill: ${(p) => p.theme.colors.turquoiseDark};
        }
      }
    }

    div[class*="-placeholder"] {
      color: ${(p) => p.theme.colors.greyDarker};
    }
  }
`

export const SelectWrapper = styled.div`
  margin-bottom: 2rem;
  margin-left: 3rem;
`
