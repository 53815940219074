import styled from "styled-components"

export const AddButton = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-weight: bold;
  margin-top: 1rem;

  svg {
    height: 1.6rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.turquoiseDark};
    color: ${({ theme }) => theme.colors.white};
    svg {
     fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const Box = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 0.6rem;
  padding: 2rem;
`
