import React, { useState } from "react"
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import { Button, ColorTick, Label, LegendContainer, LegendItem, LegendText, Wrapper } from "./DashboardBarChart.styled"
import type { dataObj } from "../../views/Dashboards/DashboardsSales"
import { theme } from "../../theme"
import Popup from "../Ui/Popup"

const COLORS = [
  theme.colors.dashboard.turquoise,
  theme.colors.dashboard.purple,
  theme.colors.dashboard.pink,
  theme.colors.dashboard.yellow,
  theme.colors.dashboard.turquoiseLight,
  theme.colors.dashboard.purpleLight,
  theme.colors.dashboard.pinkLight,
  theme.colors.dashboard.yellowLight,
  theme.colors.dashboard.turquoiseLighter,
  theme.colors.dashboard.purpleLighter,
  theme.colors.dashboard.pinkLighter,
  theme.colors.dashboard.yellowLighter
]

interface Props {
  title: string
  data: dataObj[]
  handleClick?: () => void
}

export const DashboardBarChart: React.FC<Props> = ({ title, data }) => {
  const [openPopUp, setOpenPopUp] = useState(false)

  const handleClickShowAll = () => {
    setOpenPopUp(true)
  }

  const closePopup = () => {
    setOpenPopUp(false)
  }

  const allData = data.map((x, index) => ({
    name: x.name,
    value: x.value,
    fill: COLORS[index % COLORS.length]
  }))

  const filteredData = data.slice(0, 4).map((x, index) => ({
    name: x.name,
    value: x.value,
    fill: COLORS[index % COLORS.length]
  }))

  return (
    <>
      <Wrapper>
        <Label>{title}</Label>
        <ResponsiveContainer width="100%">
          <BarChart
            data={filteredData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
            maxBarSize={40}
          >
            <XAxis dataKey="value" tick={{ fontSize: 10 }} interval={0} />
            <Tooltip cursor={{
              fill: theme.colors.turquoiseBorder
            }} />
            <Bar dataKey="value" fill="#8884d8" radius={[5, 5, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
        <LegendContainer>
          {filteredData.map((item) => (
            <LegendItem key={item.name}>
              <ColorTick color={item.fill} />
              <LegendText>{item.name}</LegendText>
            </LegendItem>
          ))}
        </LegendContainer>
        <Button onClick={handleClickShowAll}>See all</Button>
      </Wrapper>
      {openPopUp && (
        <Popup title={""} handleCloseClick={closePopup} hideOkButton={true} overrideOkButton={true}>
          <Wrapper>
            <Label>{title}</Label>
            <ResponsiveContainer width="100%" height="60%">
              <BarChart
                data={allData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
                maxBarSize={40}
              >
                <XAxis dataKey="value" tick={{ fontSize: 10 }} interval={0} />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8" radius={[5, 5, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
            <LegendContainer>
              {allData.map((item) => (
                <LegendItem key={item.name}>
                  <ColorTick color={item.fill} />
                  <LegendText>{item.name}</LegendText>
                </LegendItem>
              ))}
            </LegendContainer>
          </Wrapper>
        </Popup>
      )}
    </>
  )
}
