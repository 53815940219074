import styled from "styled-components"
import { Item, List } from "../../../Ui/List/List"

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 30rem;
  height: 30rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem;
  margin: 0 auto 2rem;
`

export const Image = styled.img`
  width: 100% !important;
  display: block;
  flex-shrink: 0;
  max-height: 100%;
  object-fit: contain;
`

export const Parent = styled.strong`
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  position: relative;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};

  p {
    line-height: 2.4rem;
  }
`

export const StyledList = styled(List)`
  margin-bottom: 0;
`

export const StyledItem = styled(Item)`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`
