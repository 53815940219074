import styled from "styled-components"

export const PeriodSelection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.8rem;
  background-color: ${(p) => p.theme.colors.greyLighter};
  border: 1px solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.75rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
`
export const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  ${(p) => p.theme.mQ.MEDIA_SMALL} {
    flex-direction: column;
  }
`
