import React from "react"
import GET_EXTERNAL_EVENTS_DESTINATION_APIS from "../../../graphql/queries/settings/externalEvents/GetExternalEventsDestinationAPIs"
import DestinationApisTable from "./DestinationApisTable"
import { useQuery } from "@apollo/client"
import { AddButton, Box } from "../Shared.styled"

import { ReactComponent as Code } from "../../../images/icons/laptop-code.svg"
import { useNavigate } from "react-router-dom"


const DestinationAPIs = () => {
  const { data, loading, error, refetch } = useQuery(GET_EXTERNAL_EVENTS_DESTINATION_APIS)
  const apiDestinations = data?.getExternalEventsDestinationAPIs
  const navigate = useNavigate()

  return (
    <div>
      <h2>Webhook API destinations</h2>
      <Box>
        <DestinationApisTable
          apiDestinations={apiDestinations}
          loading={loading}
          error={error}
          refetch={refetch}
        />
        <AddButton
          onClick={() => navigate("/settings/external-events/create-api-destination")}
        >
          <Code/>
          Add new API destination
        </AddButton>
      </Box>
    </div>
  )
}

export default DestinationAPIs
