import styled from "styled-components"
import PrimaryButton from "../../components/Ui/Buttons/PrimaryButton"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Break = styled.hr`
  margin: 1rem 0 2rem;
`

export const Info = styled.div`
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 48%;
  }
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
`

export const SignOutButton = styled(PrimaryButton)`
  svg {
    margin-right: 0;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    svg {
      margin-right: 1rem;
    }
  }
`

export const CampaignOnly = styled.p`
  color: red;
`
