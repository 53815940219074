import React from "react"
import { Order, OrderCompensation } from "@lib/types/generated/graphql-types"
import TotalItem from "./TotalItem"
import GiftCardItem from "./GiftCardItem"
import Money from "../../../../../Money"
import DineroFactory from "dinero.js"
import { Row, Total } from "./Totals.styled"
import { Delivery } from "../../../Helpers/Delivery"

enum TYPE {
  PAYMENT,
  BONUS
}

type Props = {
  compensation: OrderCompensation
  order: Order
  refetch: () => void
}

const Totals = ({ compensation, order, refetch }: Props) => {
  const deliveries = new Delivery(order.Deliveries)

  const filteredGiftCardsInDeliveries = [
    ...deliveries.giftCardsInDeliveries()
      .reduce((map, current) => {
        const { giftCardId } = current
        const grouped = map.get(giftCardId)
        if (!grouped) {
          map.set(giftCardId, { ...current })
        } else {
          map.set(giftCardId, { ...grouped, amount: grouped.amount + current.amount })
        }
        return map
      }, new Map())
      .values()
  ]

  const totalDeliveryPayment = deliveries.paymentTotal()
  const totalDeliveryBonus = deliveries.bonusTotal()
  const totalDeliveryGiftCard = deliveries.giftCardTotal()

  const getTotalAmountFromOrderLines = () =>
    compensation?.orderLines
      ?.map(
        (orderLine) =>
          orderLine.totalDiscountAmount -
          (order?.orderLines?.find((ol) => ol.id === orderLine.orderLineId)
            ?.distributedTotalDiscountAmount ?? 0)
      )
      .reduce((a, b) => a + b, 0) ?? 0

  const getTotalAmountFromShippingFees = () =>
    compensation?.shippingFees
      ?.map(
        (shippingFee) =>
          shippingFee.totalDiscountAmount -
          (order?.shippingFees?.find((fee) => fee.id === shippingFee.shippingFeeId)
            ?.discountAmount ?? 0)
      )
      .reduce((a, b) => a + b, 0) ?? 0

  const getTotalAmountFromLines = () =>
    getTotalAmountFromOrderLines() + getTotalAmountFromShippingFees()

  const getTotalCompensationAmount = () =>
    (compensation?.payment?.amount ?? 0) +
    (compensation.bonus?.amount ?? 0) +
    (compensation?.giftCards?.reduce((a, b) => a + b.amount, 0) ?? 0)

  return (
    <>
      {totalDeliveryPayment > 0 && compensation?.payment?.amount && (
        <>
          <TotalItem
            order={order}
            compensation={compensation}
            refetch={refetch}
            type={TYPE.PAYMENT}
            totalDeliveryAmount={totalDeliveryPayment}
          />
        </>
      )}
      {totalDeliveryBonus > 0 && compensation?.bonus?.amount && (
        <>
          <TotalItem
            order={order}
            compensation={compensation}
            refetch={refetch}
            type={TYPE.BONUS}
            totalDeliveryAmount={totalDeliveryBonus}
          />
        </>
      )}
      {totalDeliveryGiftCard > 0 &&
        compensation?.giftCards &&
        compensation?.giftCards?.length > 0 && (
          <>
            {filteredGiftCardsInDeliveries?.map((giftCard) => (
              <GiftCardItem
                deliveryGiftCard={giftCard}
                order={order}
                key={giftCard.giftCardId}
                refetch={refetch}
                compensation={compensation}
              />
            ))}
          </>
        )}
      <Total>
        {getTotalAmountFromLines() < getTotalCompensationAmount() && (
          <>
            <Row>
              <div>Amount from order lines:</div>
              <Money
                currencyUnit={order.currencyCode as DineroFactory.Currency}
                amount={getTotalAmountFromLines()}
              />
            </Row>
            <Row>
              <div>Amount not linked to order lines:</div>
              <Money
                currencyUnit={order.currencyCode as DineroFactory.Currency}
                amount={getTotalCompensationAmount() - getTotalAmountFromLines()}
              />
            </Row>
          </>
        )}
        {compensation?.fee && compensation?.fee?.amount > 0 &&
          <Row>
            <div>Compensation fee:</div>
              <Money
                currencyUnit={order.currencyCode as DineroFactory.Currency}
                amount={compensation?.fee?.amount}
              />
          </Row>
        }
        <Row>
          <b>To be compensated:</b>
          <b>
            <Money
              currencyUnit={order.currencyCode as DineroFactory.Currency}
              amount={getTotalCompensationAmount()}
            />
          </b>
        </Row>
      </Total>
    </>
  )
}

export default Totals
