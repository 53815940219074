import gql from "graphql-tag"

const SEARCH_ORDERS_TOTAL_HITS = gql`
  query searchOrders($from: Int!, $size: Int!, $sort: [SearchSorting!], $query: AWSJSON, $trackTotalHits: Boolean, $aggs: AWSJSON) {
    searchOrders(
      from: $from,
      size: $size,
      sort: $sort,
      query: $query,
      trackTotalHits: $trackTotalHits,
      aggs: $aggs
    ) {
      total
      hits {
        storeGroupId
        currencyCode
        totals {
          grandTotal
          taxTotal
        }
      }
      aggregations
    }
  }
`

export default SEARCH_ORDERS_TOTAL_HITS
