import React from "react"
import styled from "styled-components"

type StyledProps = {
  value: boolean
}

const Container = styled.div<StyledProps>`
  body {
    background: ${(p) => p.theme.colors.blueDark};
    padding: 60px;
  }

  .toggle {
    position: relative;
    width: 6.8rem;
    height: 3rem;
    border-radius: 2rem;
    background-color: ${(p) => p.theme.colors.white};
    overflow: hidden;
    border: 0.1rem solid
      ${(p) => (p.value ? p.theme.colors.turquoiseDark : p.theme.colors.error.highlight)};
  }

  .toggle__checkbox {
    position: absolute;
    left: -9999px;
  }

  .toggle__label {
    position: relative;
    display: inline-block;
    top: 0.15rem;
    left: 0.2rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${(p) =>
      p.value ? p.theme.colors.turquoiseDark : p.theme.colors.error.highlight};
    transition: all 0.25s ease-in-out;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      color: ${(p) => (p.value ? p.theme.colors.turquoiseDark : p.theme.colors.error.highlight)};
      font: {
        size: 13px;
        weight: bold;
      }
      text-transform: uppercase;
      line-height: 2.5rem;
      transition: all 0.15s ease-in-out;
    }

    &:before {
      left: -2.8rem;
      content: "on";
      opacity: 0;
    }

    &:after {
      left: 2.8rem;
      content: "off";
      opacity: 1;
    }
  }

  .toggle__checkbox:checked ~ .toggle__label {
    left: 4rem;

    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0;
    }
  }
`

type Props = {
  value: boolean | undefined
  setValue: (value: boolean) => void
}

const Toggle: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ value = false, setValue, ...props }, ref) => {
    return (
      <Container value={value}>
        <div className="toggle">
          <input
            ref={ref}
            id="toggle1"
            className="toggle__checkbox"
            type="checkbox"
            checked={value}
            onChange={(e) => setValue(e.currentTarget.checked)}
            {...props}
          />
          <label htmlFor="toggle1" className="toggle__label"></label>
        </div>
      </Container>
    )
  }
)

Toggle.displayName = "Toggle"

export default Toggle
