import styled from "styled-components"

export const PaymentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1rem 1rem 0.5rem !important;
`

export const Total = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  b {
    font-size: 1.6rem;
  }
`


export const Totals = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  margin-top: 3rem;
`

export const TotalRow = styled.div`
display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
`

export const PaymentLabel = styled.div`
  svg {
    width: 2rem;
    margin-right: 1rem;
  }
`

export const PaymentMethod = styled.div`
  font-size: 0.9rem;
`

export const InputWrapper = styled.div`
  padding: 1rem;

  border-radius: 0.6rem;
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 1rem;

  div {
    padding-bottom: 0;
  }

  span {
    display: flex;
    align-items: center;
  }
  h2 {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  input {
    margin-bottom: 2rem;
  }
`

export const Error = styled.div`
  font-size: 1.2rem;
  color: ${(p) => p.theme.colors.errorText};
`

export const GiftCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 3rem 1rem 0.5rem !important;
`

export const GiftCardLabel = styled.div`
  svg {
    width: 2rem;
    margin-right: 1rem;
  }
`

export const GiftCardId = styled.div`
  font-size: 0.9rem;
`
