import React from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import { InputReason, Order } from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"

import GET_STORE_GROUP from "../../../../../graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import { Blur, Container, Summary } from "./OrderSummary.styled"

import Money from "../../../../Money"
import { Item, Label, Value } from "../../../../Ui/List/List"
import FlagIcon from "../../../../Ui/FlagIcon"
import DotLoader from "../../../../Ui/DotLoader"
import CopyButton from "../../../../Ui/CopyButton"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import CREATE_RELEASE from "../../../../../graphql/mutations/order/releases/CreateRelease"
import { handleErrorMessages } from "helpers/errors"
import { Release } from "../../Helpers/Release"
import ReasonInput from "../../Reason/ReasonInput"
import ALL_REASON_CODES from "../../../../../graphql/queries/settings/reasonCodes/AllReasonCodes"

type Props = {
  order: Order
  refetch: () => void
  orderLoading: boolean
}

export const OrderSummary = ({ order, refetch }: Props) => {
  const [reason, setReason] = React.useState<InputReason>({ cause: "", code: "" })
  const releases = new Release(order.Releases)
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  const { data: reasonCodesData } = useQuery(ALL_REASON_CODES)
  const customReasons = reasonCodesData?.Order?.customReasonCodes?.codes

  const [createRelease, { loading: createLoading }] = useMutation(CREATE_RELEASE, {
    onCompleted: () => {
      refetch()
      setReason({ cause: "", code: "" })
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const createOrderRelease = () => {
    return createRelease({
      variables: {
        orderId: order.id,
        input: {
          ...((reason.code || reason.cause) && { reason })
        }
      }
    })
  }

  if (!order)
    return (
      <Blur>
        <Header>
          <h2>Order: #1234567</h2>
        </Header>
        <Summary>
          <Item flexibleHeight>
            <Label>E-mail:</Label>
            <Value>
              <>
                john.doe@email.com
                <CopyButton string={""} />
              </>
            </Value>
          </Item>
          <Item>
            <Label>Store group:</Label>
            <Value>store group</Value>
          </Item>
          <Item>
            <Label>Store market:</Label>
            <Value flex>
              <>Sweden</>
            </Value>
          </Item>
          <Item>
            <Label>Order date:</Label>
            <Value>2000-00-00 00:00</Value>
          </Item>
          <Item>
            <Label>Total order value:</Label>
            <Value>SEK 200.00</Value>
          </Item>
        </Summary>
      </Blur>
    )
  if (!order) return null
  return (
    <Container>
      <Header>
        <h2>Order: #{order?.reference}</h2>
      </Header>
      <Summary>
        <Item flexibleHeight>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order?.billingAddress?.email}
              <CopyButton string={order?.billingAddress?.email} />
            </>
          </Value>
        </Item>
        <Item>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Item>
        <Item>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order?.countryCode} />
              {getCountryName(order?.countryCode)}
            </>
          </Value>
        </Item>
        <Item>
          <Label>Order date:</Label>
          <Value>{dayjs(order?.date).format("YYYY-MM-DD HH:mm")}</Value>
        </Item>
        <Item>
          <Label>Total order value:</Label>
          <Value>
            <Money
              amount={parseInt(`${order?.totals?.grandTotal ?? 0}`)}
              currencyUnit={order?.currencyCode as DineroFactory.Currency}
            />
          </Value>
        </Item>
      </Summary>
      {!releases.onGoing() && (
        <>
          <h2>Reason (Optional)</h2>
          <Summary>
            <ReasonInput reason={reason} setReason={setReason} customReasons={customReasons} />
          </Summary>
        </>
      )}
      <PrimaryButton
        handleClick={createOrderRelease}
        loading={createLoading}
        disabled={releases.onGoing()}
      >
        Create release draft
      </PrimaryButton>
    </Container>
  )
}
