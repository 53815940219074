import React, { useRef, useState } from "react"
import { v4 as uuid } from "uuid"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Common } from "@lib/types"
import { FrontendOutcome, LogicState, Outcomes } from "@lib/types/discount"
import {
  InputOutcomeType,
  StoreGroup as StoreGroupType,
  StoreMarket as StoreMarketType
} from "lib/types/generated/graphql-types"
import { getCurrency } from "helpers/currency"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import { OutcomeSelect } from "../Ui/OutcomeSelect"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import {
  BuyGet,
  BuySelect,
  CartDiscount,
  FreeShipping,
  GiftWithPurchase,
  ItemDiscounts,
  OptimizeBuyGets,
  SelectItems
} from "./Outcomes"
import { ShippingTags } from "./Outcomes/ShippingTags"

type Props = {
  listId: string
  outcome?: FrontendOutcome
  // eslint-disable-next-line
  setState: any
}

const EditOutcome = ({ listId, outcome, setState }: Props) => {
  const dispatch = useAppDispatch()
  const [outcomeType, setOutcomeType] = useState<string>()
  const submitRef = useRef<HTMLButtonElement>(null)
  const [currencyOptions, setCurrencyOptions] = useState<Common.Option[]>([])

  const updateOutcomeType = (outcomeType: Common.Option) => {
    setOutcomeType(outcomeType?.value)
  }

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      const storeMarkets = data.getStoreGroups
        .map((storeGroup: StoreGroupType) => storeGroup.storeMarkets)
        .flat()
      const currencyCodes = storeMarkets.map(
        (storeMarket: StoreMarketType) => storeMarket.currencyCode
      )
      const uniqueCurrencyCodes = [...new Set(currencyCodes)] as string[]
      setCurrencyOptions(
        uniqueCurrencyCodes.map((code: string) => ({
          value: code,
          label: getCurrency(code).currency
        }))
      )
    }
  })

  const addOutcome = (data: Outcomes | undefined) => {
    setState((prev: LogicState) => ({
      ...prev,
      [listId]: {
        ...prev[listId],
        outcomes: outcome?.id
          ? prev[listId].outcomes.map((o: FrontendOutcome) =>
              o.id === outcome.id
                ? {
                    ...o,
                    data: {
                      type: outcomeType,
                      ...data
                    }
                  }
                : o
            )
          : prev[listId].outcomes.length !== 0
            ? [
                ...prev[listId].outcomes,
                {
                  id: uuid(),
                  title: "AND",
                  type: "operator"
                },
                {
                  id: uuid(),
                  title: "Outcome",
                  type: "outcome",
                  data: {
                    type: outcomeType,
                    ...data
                  }
                }
              ]
            : [
                {
                  id: uuid(),
                  title: "Outcome",
                  type: "outcome",
                  data: {
                    type: outcomeType,
                    ...data
                  }
                }
              ]
      }
    }))
    dispatch(hideEditSidebar())
  }

  const renderForm = () => {
    switch (outcomeType) {
      case InputOutcomeType.Cartdiscount:
        return (
          <CartDiscount
            outcome={outcome}
            addOutcome={addOutcome}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case InputOutcomeType.Buyget:
        return (
          <BuyGet
            outcome={outcome}
            addOutcome={addOutcome}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case InputOutcomeType.Buyselect:
        return (
          <BuySelect
            outcome={outcome}
            addOutcome={addOutcome}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case InputOutcomeType.Itemdiscounts:
        return (
          <ItemDiscounts
            outcome={outcome}
            addOutcome={addOutcome}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case InputOutcomeType.Gift:
        return <GiftWithPurchase outcome={outcome} addOutcome={addOutcome} submitRef={submitRef} />
      case InputOutcomeType.Freeshipping:
        return <FreeShipping addOutcome={addOutcome} submitRef={submitRef} />
      case InputOutcomeType.Selectitems:
        return (
          <SelectItems
            outcome={outcome}
            addOutcome={addOutcome}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case InputOutcomeType.Optimizebuygets:
        return (
          <OptimizeBuyGets
            outcome={outcome}
            addOutcome={addOutcome}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case InputOutcomeType.Shippingtags:
        return <ShippingTags outcome={outcome} addOutcome={addOutcome} submitRef={submitRef} />
      default:
        return null
    }
  }

  return (
    <EditSidebar>
      <EditSidebarHeader title={outcome?.data ? "Edit outcome" : "Add outcome"} id={outcome?.id}>
        <PrimaryButton type="button" handleClick={() => submitRef?.current?.click()}>
          Save
        </PrimaryButton>
      </EditSidebarHeader>
      <OutcomeSelect
        disabled={!!outcome?.data}
        setValue={updateOutcomeType}
        defaultValue={outcome?.data?.type}
      />
      <>{outcomeType && renderForm()}</>
    </EditSidebar>
  )
}

export default EditOutcome
