import React from "react"
import { Order, OrderHistory } from "@lib/types/generated/graphql-types"
import { Cancellation } from "../Helpers/Cancellation"
import PendingCancellation from "./PendingCancellation"
import FailedCancellation from "./FailedCancellation"

type Props = {
  order: Order
  refetch: () => void
  orderHistory: OrderHistory
}

const CancelOrder = ({ order, refetch, orderHistory }: Props) => {
  const cancellations = new Cancellation(order.Cancellations)
  return (
    <>
      {cancellations.pending() && (
        <PendingCancellation
          pendingCancellation={cancellations.getPendingCancellation()}
          orderRefetch={refetch}
        />
      )}
      {cancellations.failed() && (
        <FailedCancellation
          failedCancellation={cancellations.getFailedCancellation()}
          orderId={order.id}
          refetch={refetch}
          orderHistory={orderHistory.history}
        />
      )}
    </>
  )
}

export default CancelOrder
