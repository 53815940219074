import React from "react"
import styled from "styled-components"

import PageHeader from "components/Ui/Page/PageHeader"

const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.8rem;
  margin-bottom: 3rem;
  padding-top: 0.5rem;

  iframe {
    border: none;
    min-height: 80rem;
  }
`

export const ProductBoard = () => (
  <>
    <PageHeader title="Product board" />
    <Container>
      <iframe
        src="https://portal.productboard.com/qsukhsvxkjqa83urkb1j4j7u?hide_logo=1"
        width="100%"
        height="100%"
      />
    </Container>
  </>
)
