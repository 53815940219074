import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components"
import { isSuperUser, isCampaignUser } from "../../../helpers/user"

interface ContainerProps {
  hasDescription: boolean
  disabled: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: ${(p) => (p.hasDescription ? "flex-start" : "center")};
  flex-wrap: no-wrap;
  padding: 1.5rem 0;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.disabled ? "0.4" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "all")};
`

const Box = styled.input`
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
  padding: 0;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  position: relative;
  flex-shrink: 0;
  background: ${(p) => p.theme.colors.white};
  margin: 0;
  transition: all 0.2s;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    height: 2.4rem;
    width: 2.4rem;
  }

  &:checked {
    background: ${(p) => p.theme.colors.primary};
  }

  &:checked::before {
    display: none;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 0.4rem;
    left: 0.8rem;
    width: 0.4rem;
    height: 1rem;
    transform: rotate(45deg);
    border-style: solid;
    border-color: ${(p) => p.theme.colors.black};
    border-width: 0 0.2rem 0.2rem 0;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      top: 0.2rem;
      left: 0.8rem;
      width: 0.5rem;
      height: 1.3rem;
    }
  }

  &:focus {
    outline: none;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    &:hover {
      border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

const Label = styled.div`
  margin-left: 1.5rem;
  line-height: 1.8rem;

  label {
    color: ${(p) => p.theme.colors.black};
    cursor: pointer;
    display: inline-block;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`

const Description = styled.p`
  width: 100%;
  color: ${(p) => p.theme.colors.greyDarker};
  margin: 0;
  font-size: 1.3rem;
`

type Props = {
  name: string
  label: string
  disabled?: boolean
  description?: string
  setValue: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void) | undefined
  value: boolean | undefined
}

const CheckboxSimple = ({
  name,
  disabled,
  label,
  description,
  setValue,
  value,
  ...props
}: Props) => {
  const handleOnChange = () => {
    if (setValue) setValue(!value)
  }
  return (
    <Container disabled={disabled ?? false} hasDescription={!!description} {...props}>
      <Box
        type="checkbox"
        disabled={isCampaignUser() ? disabled : !isSuperUser() || disabled}
        checked={value}
        onChange={handleOnChange}
        id={name}
      />
      <Label>
        {label && <label htmlFor={name}>{label}</label>}
        {description && <Description>{description}</Description>}
      </Label>
    </Container>
  )
}

export default CheckboxSimple
