import React from "react"
import { Control, Controller } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import { SidebarDivider } from "../../Dashboard/Shared.styled"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { IngridConfig, InputIngridConfig } from "@lib/types/generated/graphql-types"
import styled from "styled-components"
import SingleSelect from "../../Ui/Form/SingleSelect"
import { Common } from "@lib/types"
import { UseFormSetValue } from "react-hook-form/dist/types/form"

const BaseUrlSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

interface ExtendedInputIngridConfig extends InputIngridConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputIngridConfig>
  errors: FieldErrors
  showId?: boolean
  setValue: UseFormSetValue<ExtendedInputIngridConfig>
  defaultValues?: IngridConfig
}

const IngridConfigInputs = ({ control, errors, setValue, showId, defaultValues }: Props) => {
  const updateBaseUrl = (baseUrl: Common.Option) => {
    setValue("baseUrl", baseUrl.value)
  }

  const baseUrlOptions = [
    {
      value: "https://api-stage.ingrid.com/",
      label: "Global test | api-stage.ingrid.com"
    },
    {
      value: "https://api.ingrid.com/",
      label: "Global prod | api.ingrid.com"
    }
  ]

  return (
    <>
      {showId && (
        <Controller
          name="id"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="Identifier *"
              description="For internal use in Brink"
              placeholder="Aa - Zz, 0 - 9, -_."
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field",
            pattern: {
              value: /^[A-Za-z0-9_.-]+$/,
              message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
            }
          }}
          defaultValue=""
        />
      )}
      <SidebarDivider />
      <Controller
        name="siteId"
        render={({ field }) => (
          <Input {...field} type="text" label="Ingrid site ID *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.siteId ?? ""}
      />
      <Controller
        name="apiKey"
        render={({ field }) => (
          <Input {...field} type="text" label="Ingrid private key (base64) *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="baseUrl"
        render={({ field }) => (
          <BaseUrlSelect
            {...field}
            options={baseUrlOptions}
            setValue={updateBaseUrl}
            defaultValue={
              defaultValues?.baseUrl === "https://api.ingrid.com/"
                ? {
                    value: "https://api.ingrid.com/",
                    label: "Global prod | api.ingrid.com"
                  }
                : {
                    value: "https://api-stage.ingrid.com/",
                    label: "Global test | api-stage.ingrid.com"
                  }
            }
            label="Base URL *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
    </>
  )
}

export default IngridConfigInputs
