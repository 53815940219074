import React, { Dispatch, SetStateAction } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"

import ADD_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS from "graphql/mutations/settings/externalEvents/AddExternalEventsDestinationEventBus"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import Input from "components/Ui/Form/Input"

type Props = {
  setCreateEventBus: Dispatch<SetStateAction<boolean>>
  refetch: () => void
}

type Inputs = {
  eventBusArn: string
}

const CreateEventBus = ({ setCreateEventBus, refetch }: Props) => {
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>()

  const [createEventBus, { loading: createLoading }] = useMutation(ADD_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: `Event bus ${data.addExternalEventsDestinationEventBus.eventBusArn} successfully created`,
          type: "success"
        })
      )
      setCreateEventBus(false)
      dispatch(hideEditSidebar())
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    createEventBus({
      variables: {
        eventBusArn: data.eventBusArn.trim()
      }
    })
  }

  return (
    <EditSidebar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader title="Create new Event Bus">
          <PrimaryButton loading={createLoading} type="submit">
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <EditSidebarSection>
          <Controller
            name="eventBusArn"
            render={({ field }) => (
              <Input
                {...field}
                label="Event bus ARN *"
                description="Example: arn:aws:events:eu-west-1:123456789:event-bus/{eventbusName}"
                errors={errors}
              />
            )}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
        </EditSidebarSection>
      </form>
    </EditSidebar>
  )
}

export default CreateEventBus
