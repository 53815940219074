import styled from "styled-components"

export const Container = styled.div`
  padding: 2rem 0;
  position: relative;
  background: ${(p) => p.theme.colors.turquoiseLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.turquoiseBorder};
  border-radius: 0.5rem;
  margin: 1rem 0 3rem;
`

export const Divider = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.turquoiseBorder};
  width: 100%;
  margin: 1rem 0;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 0 2.5rem;
  }

  p,
  h4,
  h3 {
    margin: 0;
  }
`

export const Discount = styled.p`
  color: ${(p) => p.theme.colors.turquoiseDark};
`
