import { Maybe, OrderLineOption } from "@lib/types/generated/graphql-types"
import React from "react"
import { Key, Value, Option, Container, Label } from "./OrderLineOptions.styled"

type Props = {
  options: Maybe<OrderLineOption>[]
}

const OrderLineOptions = ({ options }: Props) => {
  return (
    <Container>
      <Label>Options: </Label>
      {options.map((option) => (
        <Option key={option?.key}>
          <Key>{option?.key}</Key>
          <Value>{option?.value}</Value>
        </Option>
      ))}
    </Container>
  )
}

export default OrderLineOptions
