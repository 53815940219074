import React from "react"
import styled from "styled-components"
import { useMutation, useQuery } from "@apollo/client"
import { Controller } from "react-hook-form"
import { SubmitHandler, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { InputCampaignGroup } from "@lib/types/generated/graphql-types"
import { useParams } from "react-router-dom"

import GET_CAMPAIGN_GROUP from "graphql/queries/campaign/GetCampaignGroup"
import CREATE_OR_UPDATE_CAMPAIGN_GROUP from "graphql/mutations/campaign/CreateOrUpdateCampaignGroup"

import PageHeader from "components/Ui/Page/PageHeader"
import Input from "components/Ui/Form/Input"
import { BrinkLoader } from "components/Ui/BrinkLoader"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import CampaignTable from "components/Campaign/CampaignTable"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  position: relative;
`

type Inputs = {
  id: string
  name: string
  description: string
  campaignName: string
  validDateRangeFrom: string
  validDateRangeTo: string
  roundingRules: string
  priceRule: string
}

export const UpdateCampaignGroup = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Inputs>()

  const { data, loading, refetch } = useQuery(GET_CAMPAIGN_GROUP, {
    variables: { id: id }
  })
  const campaignGroup = data?.getCampaignGroup?.campaignGroup

  const [addCampaignGroup, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_CAMPAIGN_GROUP,
    {
      onCompleted: () => {
        refetch()
        dispatch(
          alertActions.actions.createAlert({
            message: "Campaign Group successfully updated",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputCampaignGroup> = (data) => {
    addCampaignGroup({
      variables: { ...data, id: campaignGroup.id }
    })
  }
  if (loading) return <BrinkLoader />

  if (!data) return null
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        title={campaignGroup.name}
        group="Campaign group"
        id={campaignGroup.id}
        hasTabs
        goBackLinkUrl="/campaigns"
        goBackLinkText="Back to campaigns"
      >
        <PrimaryButton
          type="submit"
          loading={createLoading}
          handleClick={() => handleSubmit(onSubmit)}
        >
          Save
        </PrimaryButton>
      </PageHeader>
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Connected campaigns</Tab>
        </TabList>

        <TabPanel>
          <Container>
            <div>
              <Controller
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    label="Campaign name *"
                    placeholder="Aa - Zz, 0 - 9"
                    errors={errors}
                  />
                )}
                control={control}
                defaultValue={campaignGroup?.name}
                rules={{
                  required: "This is a required field"
                }}
              />
              <Controller
                name="description"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    label="Description *"
                    placeholder="Aa - Zz, 0 - 9"
                    errors={errors}
                  />
                )}
                control={control}
                defaultValue={campaignGroup?.description}
                rules={{
                  required: "This is a required field"
                }}
              />
            </div>
          </Container>
        </TabPanel>
        <TabPanel>
          <CampaignTable
            campaigns={data.getCampaignGroup.campaigns || []}
            loading={false}
            error={undefined}
            hideDelete
          />
        </TabPanel>
      </Tabs>
    </form>
  )
}
