import React, { useState } from "react"
import styled from "styled-components"

import { List, Item, Label, Value } from "components/Ui/List/List"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as AttributeIcon } from "images/icons/square-list.svg"

type StyledProps = {
  $expanded: boolean
}

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 3rem;
  position: relative;
`

const Arrow = styled(DownIcon)<StyledProps>`
  width: 1.2rem !important;
  margin-left: 1rem;
  transition: transform 0.2s;
  transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
`

const Tab = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => (p.$expanded ? "none" : `0.1rem solid ${p.theme.colors.greyLight}`)};

  svg {
    width: 1.6rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.turquoiseDark};

    svg {
      fill: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

type Props = {
  // eslint-disable-next-line
  customAttributes: any
  insideEditSidebar?: boolean
}

export const ProductVariantCustomAttributes = ({ customAttributes, insideEditSidebar }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <>
      <Tab onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        <AttributeIcon />
        {insideEditSidebar ? <h4>Custom attributes</h4> : <h2>Custom attributes</h2>}
        <Arrow $expanded={expanded} />
      </Tab>
      {expanded && (
        <Block>
          <List>
            {Object.keys(customAttributes).length > 0 ? (
              customAttributes &&
              Object.keys(customAttributes)?.map((key) => (
                <Item key={key}>
                  <Label>
                    <>{key}:</>
                  </Label>
                  <Value>{customAttributes[key]}</Value>
                </Item>
              ))
            ) : (
              <p>No attributes set</p>
            )}
          </List>
        </Block>
      )}
    </>
  )
}
