import styled from "styled-components"
import SingleSelectSimple from "../../../Ui/Form/SingleSelectSimple"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"

type StyledProps = {
  $selected: boolean
}

export const Container = styled.div`
margin-top: 1rem;
`

export const Switch = styled.div`
  display: flex;
  border: 0.15rem solid ${({ theme }) => theme.colors.turquoiseDarker};
  border-radius: 0.6rem;
  width: fit-content;
  justify-content: center;
  margin-bottom: 0.3rem;
`

export const Button = styled.div<StyledProps>`
  padding: 0.3rem 1rem;
  background: ${({ theme, $selected }) => ($selected ? theme.colors.turquoiseDarker : "none")};
  color: ${({ theme, $selected }) => ($selected ? theme.colors.white : theme.colors.black)};
  font-weight: ${({ $selected }) => ($selected ? "700" : "400")};
  font-size: 1.1rem;

  &:hover {
    cursor: pointer;
  }
`

export const Info = styled.div`
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  a {
    margin-left: 0.2rem;
    text-decoration: underline;
  }

  svg {
    height: 1.2rem;
    margin-right: 0.5rem;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const StyledSelect = styled(SingleSelectSimple)`
  margin-bottom: 2rem;
`

export const StyledButton = styled(PrimaryButton)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.turquoiseDarker};
`
