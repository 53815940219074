import styled from "styled-components"
import TableHeader from "../../Ui/Table/TableHeader"
import { Tooltip } from "react-tooltip"

type StyledProps = {
  padding: string
}

export const Container = styled.div`
  width: 100%;
  min-height: 20rem;
`

export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
`

export const StatusHeader = styled(FlexColumn)`
  min-width: 16rem;
`

export const StatusColumn = styled(FlexColumn)`
  min-width: 16rem;
`
export const StoreGroupColumn = styled(FlexColumn)`
  min-width: 14rem;
  overflow: hidden;
`


export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem !important;
  align-items: flex-start;
`

export const OrderDate = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;

  div {
    padding-right: 1rem;
  }
`

export const Customer = styled(FlexColumn)`
  min-width: 26rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding: 0 2rem 0 6.4rem;
`

export const SmallInfo = styled.div`
  font-size: 1.1rem;
  margin: 0;
  line-height: 1.6rem;
  display: flex;
  align-items: center;

  svg {
    height: 1rem;
    margin-left: 0.3rem;
  }
`

export const Status = styled.span`
  background: #eee;
  width: auto !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  border-radius: 0.4rem;
  min-width: 14rem;
  font-weight: 700;

  &:first-child {
    margin: 0;
  }
`

export const StatusFull = styled(Status)<StyledProps>`
  background: #dcf4e5;
  color: rgb(9, 174, 76);
  padding: ${(p) => p.padding};
`

export const StatusPart = styled(Status)<StyledProps>`
  color: #e69503;
  background: #ffedcd;
  padding: ${(p) => p.padding};
`

export const StatusCancel = styled(Status)<StyledProps>`
  background: #ffe6e6;
  color: #e64d4d;
  padding: ${(p) => p.padding};
`

export const StatusDefault = styled.div`
  background: ${({theme}) => theme.colors.greyLightest};
  color: ${({theme}) => theme.colors.greyDarker};
  padding: 0.4rem 1rem;
  border: 0.1rem solid ${({theme}) => theme.colors.greyLight};
  width: auto !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  border-radius: 0.4rem;
  min-width: 14rem;
  font-weight: 700;
`

export const PaymentContainer = styled(FlexColumn)`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 18rem;

  > span {
    display: flex;
    flex-direction: column;
  }

  svg {
    width: 2.4rem;
    display: block;
  }
`

export const IconContainer = styled.div`
  border: 0.1rem solid rgb(223, 227, 232);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 1.5rem;

  svg {
    margin-right: 0;
  }
`

export const PaymentMethod = styled(SmallInfo)`
  &:first-letter {
    text-transform: uppercase;
  }
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`

export const TooltipContent = styled.div`
position: absolute;
`

export const StyledTooltip = styled(Tooltip)`

`
