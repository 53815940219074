import styled from "styled-components"

type StyledProps = {
  $expanded?: boolean
  $deleted?: boolean
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem 1.5rem 0 1.5rem;
  margin: 0 0 2rem 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 6rem 2rem 2rem;
  }
`

export const BaseData = styled.div`
  cursor: pointer;
`

export const NotCompleted = styled.div`
  position: absolute;
  right: 6rem;
  top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  background: ${(p) => p.theme.colors.errorBackground};
  color: ${(p) => p.theme.colors.errorText};
  border-radius: 0.4rem;
`

export const Dates = styled.div`
  display: flex;
  font-size: 1.2rem;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
  }

  svg {
    height: 1.5rem;
    margin-left: 0.5rem;
    fill: ${(p) => p.theme.colors.errorText};
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    flex-direction: row;
  }
`

export const PaymentInfo = styled(Dates)`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

export const ProviderStatus = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 2rem 0 1rem 0;

  div {
    display: flex;
    margin-bottom: 1rem;
  }

  span {
    width: 24rem;
    display: flex;
    justify-content: center;
  }
`

export const Label = styled.div`
  width: 10rem;
`

export const Row = styled.div`
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  height: 6rem;
  align-items: center;
  border-radius: 0.6rem;
  flex-direction: row !important;
  margin-right: 0 !important;
  margin-bottom: 1rem;

  span {
    margin-right: 1rem;
  }
`

export const RefundFee = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
`

export const CompensationFee = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
`

export const AdditionalData = styled.div``

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`

export const ExpandIcon = styled.span<StyledProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  border-radius: 0 0.6rem 0.6rem 0;
  padding: 1.6rem 0;
  cursor: pointer;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    transition: all 0.2s;
    overflow: hidden;
    transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
  }
`

export const OrderLineWrapper = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.4rem;
  justify-content: space-between;
  transition: all 0.2s;
  margin-bottom: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    flex-direction: row;
  }
`

export const Percentage = styled.div`
  background: ${(p) => p.theme.colors.turquoiseDark};
  color: ${(p) => p.theme.colors.white};
  padding: 0 0.3rem;
  border-radius: 0.6rem;
  width: 5rem;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0.5rem;
  top: 1rem;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex: initial !important;
  align-self: center;
  margin-right: 1rem;
  height: 9rem;
  width: 9rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    transition: all 0.2s;
    margin-right: 2rem;
    height: 9rem;
    width: 9rem;
  }
`

export const ProductImage = styled.img`
  width: 100%;
  display: block;
  flex-shrink: 0;
  max-height: 100%;
  object-fit: contain;
`

export const Discount = styled.div`
  text-decoration: line-through;
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
`

export const Price = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const ModificationStatus = styled.div<StyledProps>`
  background: ${(p) =>
    p.$deleted ? p.theme.colors.error.background : p.theme.colors.turquoiseDarker};
  color: ${(p) => p.theme.colors.white};
  font-weight: 700;
  position: absolute;
  transform-origin: 50% 50%;
  font-size: 1.1rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  left: -2.4rem;
  top: 1.3rem;
  rotate: -45deg;

  &:before {
    content: "";
    position: absolute;
    top: -0.1rem;
    right: -0.1rem;
    rotate: 180deg;
    border-top: 2.7rem solid transparent;
    border-left: 2.7rem solid ${(p) => p.theme.colors.white};
    width: 0;
  }

  &:after {
    content: "";
    position: absolute;
    top: -0.1rem;
    left: -0.1rem;
    rotate: 90deg;
    border-top: 2.7rem solid transparent;
    border-left: 2.7rem solid ${(p) => p.theme.colors.white};
    width: 0;
  }
`

export const Info = styled.div`
  display: flex;

  span {
    width: 9rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin-right: 2rem;
    }
  }

  svg {
    height: 3rem;
  }
`

export const ProductName = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
`

export const Id = styled.p`
  font-size: 1.1rem;
  margin-top: 0.5rem;
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  background: ${(p) => p.theme.colors.greyLighter};
  border-radius: 0.4rem;
  width: fit-content;
  padding: 0 0.5rem;

  span {
    color: ${(p) => p.theme.colors.greyDarker};
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  height: 5rem;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
  }
`

export const FieldTitle = styled.span`
  ${(p) => p.theme.bold};
  line-height: 1.8rem;
`

export const TotalTitle = styled(FieldTitle)`
  font-size: 1.5rem;
  margin-left: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 1.6rem;
  }
`

export const Bold = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const Action = styled.div`
  padding: 1rem;
  border-radius: 0.4rem;
  font-size: 1.1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  background: ${(p) => p.theme.colors.yellowLighter};
  display: flex;
  flex-direction: column;

  justify-content: center;

  span {
    display: flex;
    justify-content: space-between;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 24rem;
  }
`
