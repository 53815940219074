import styled from "styled-components"
export const DefaultRuleWrapper = styled.div`
  margin: 2rem 0;
`

export const RoundingRulesWrapper = styled.div`
  h3 {
    color: ${(p) => p.theme.colors.turquoiseDark};
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 3rem;
  }
`

export const TestRule = styled.div`
  border-radius: 0.6rem;
  padding: 0 4rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  background: ${(p) => p.theme.colors.white};
`

export const RuleExampleWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const ExampleValue = styled.div`
  width: 18rem;
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
`

export const CustomValue = styled.div`
  padding-top: 2.4rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 700;
  svg {
    margin: 0 1rem;
    width: 1.6rem;
  }
`

export const OutputExample = styled.div`
  border-radius: 2rem;
  margin-right: 1rem;
  width: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.turquoiseDark};

  svg {
    margin: 0 0.5rem;
    width: 1.6rem;
    fill: ${(p) => p.theme.colors.white};
  }
`

export const CheckboxDescription = styled.span`
  color: ${(p) => p.theme.colors.greyDarker};
  font-size: 1.4rem;
  margin-top: 2rem;
`
export const Description = styled.span`
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.greyDarker};
  margin-bottom: 1.5rem;
`
