import React from "react"
import styled from "styled-components"

import { List, Item, Label, Value } from "components/Ui/List/List"

import { ReactComponent as AttributeIcon } from "images/icons/square-list.svg"

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 4rem;
  position: relative;
`

type Props = {
  // eslint-disable-next-line
  customAttributes: any
  insideEditSidebar?: boolean
}

const CustomAttributes = ({ customAttributes }: Props) => {
  return (
    <>
      <h2>
        <AttributeIcon />
        Custom attributes
      </h2>
      <Block>
        <List>
          {Object.keys(customAttributes).length > 0 ? (
            customAttributes &&
            Object.keys(customAttributes)?.map((key) => (
              <Item key={key}>
                <Label>{key}</Label>
                <Value>{customAttributes[key]}</Value>
              </Item>
            ))
          ) : (
            <p>No attributes set</p>
          )}
        </List>
      </Block>
    </>
  )
}

export default CustomAttributes
