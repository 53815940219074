import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { TaxGroup } from "lib/types/generated/graphql-types"
import { useLazyQuery } from "@apollo/client"
import TaxGroupTable from "../../../components/TaxGroup/TaxGroupTable"

import SEARCH_TAX_GROUPS from "../../../graphql/queries/tax/SearchTaxGroups"
import { useSelector } from "react-redux"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"
import UpdateTaxGroup from "./UpdateTaxGroup"
import PageSectionHeader from "../../../components/Ui/Page/PageSectionHeader"
import Search from "../../../components/Ui/Search"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"
import { CreateTaxGroup } from "./CreateTaxGroup"

const Container = styled.div``


export const TaxGroups = () => {
  const [editId, setEditId] = useState("")
  const showSidebar = useSelector(editSidebarOpen)
  const [taxGroups, setTaxGroups] = useState<TaxGroup[] | undefined>()
  const[searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    if (!showSidebar) setEditId("")
  }, [showSidebar])

  const getQuery = () => {
    return esb
      .requestBodySearch()
      .query(
        esb
          .boolQuery()
          .must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)])
      )
  }

  const query = getQuery().toJSON() as Query

  const [searchTaxGroups, { loading, error, refetch }] = useLazyQuery(
    SEARCH_TAX_GROUPS,
    {
      onCompleted: (data) => {
        setTaxGroups(data?.searchTaxGroups?.hits)
      }
    }
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchTaxGroups({
          variables: {
            from: 0,
            size: 40,
            query: JSON.stringify(query.query)
          }
        }).then((response) => {
          setTaxGroups(response?.data?.searchTaxGroups?.hits)
        })
      },
      searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  const deleteTaxGroup = (id: string) => {
    setTaxGroups(taxGroups?.filter((taxGroup) => taxGroup.id !== id))
  }

  return (
    <Container>
      {editId ? (
        <UpdateTaxGroup
          id={editId}
          setEditId={setEditId}
          refetch={refetch}
          deleteTaxGroup={deleteTaxGroup}
        />
      ): <CreateTaxGroup refetch={refetch} setEditId={setEditId}/>}
      <PageSectionHeader title="Tax Groups" />
      <Search
        handleOnChange={(input: string) => {
          setSearchQuery(input)
        }}
        loading={loading}
        defaultValue={searchQuery}
      />
      <TaxGroupTable
        taxGroups={taxGroups || []}
        loading={loading && !searchQuery}
        error={error}
        setEditId={setEditId}
      />
    </Container>
  )
}
