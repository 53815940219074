import React, { Dispatch, SetStateAction, useEffect } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { DiscountCodeSearchHit } from "lib/types/generated/graphql-types"
import { Discount } from "lib/types"
import styled from "styled-components"
import { hideEditSidebar, showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"

import CREATE_OR_UPDATE_DISCOUNT_CODE from "graphql/mutations/discount/CreateOrUpdateDiscountCode"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import Input from "components/Ui/Form/Input"
import TimePeriodSelection from "components/Discount/TimePeriodSelection"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"
import SEARCH_DISCOUNT_CODES from "../../../graphql/queries/discount/SearchDiscountCodes"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`

const FieldTitle = styled.span`
  font-weight: bold;
`

const DiscountRuleId = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

type Props = {
  discountCodeRuleId: string
  setCreateDiscountCode: Dispatch<SetStateAction<boolean>>
  discountCodes: DiscountCodeSearchHit[]
  setDiscountCodes: Dispatch<SetStateAction<DiscountCodeSearchHit[]>>
}

const CreateDiscountCode = ({
  discountCodeRuleId,
  setCreateDiscountCode,
  discountCodes,
  setDiscountCodes
}: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Discount.CodeInputs>({ mode: "onSubmit", reValidateMode: "onSubmit" })

  useEffect(() => {
    if (discountCodeRuleId) {
      dispatch(showEditSidebar())
    }
  }, [discountCodeRuleId])

  const [addDiscountCode, { loading }] = useMutation(CREATE_OR_UPDATE_DISCOUNT_CODE, {
    onCompleted: (data) => {
      setDiscountCodes([data?.createOrUpdateDiscountCode, ...discountCodes])
      dispatch(
        alertActions.actions.createAlert({
          message: "Discount code successfully updated",
          type: "success"
        })
      )
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const [searchDiscountCodes, {loading: searchLoading}] = useLazyQuery(SEARCH_DISCOUNT_CODES)

  const validateCode = (code: string) => {
    const referenceSearchQuery = esb
      .requestBodySearch()
      .query(esb.boolQuery().must(esb.matchQuery("code", code)))
    const query = referenceSearchQuery.toJSON() as Query
    return searchDiscountCodes({
      variables: {
        from: 0,
        size: 1,
        sort: [{ field: "updated", order: "ASC" }],
        query: JSON.stringify(query.query)
      }
    })
  }

  const onSubmit: SubmitHandler<Discount.CodeInputs> = (data) => {
    const { usageLimit, code, from, to } = data
    const usageLimitExists = usageLimit?.toString() !== "" && usageLimit !== undefined
    addDiscountCode({
      variables: {
        discountCodeRuleId,
        code: code,
        isUnlimited: !usageLimitExists,
        ...(usageLimitExists && { usageLimit: usageLimit }),
        ...(from &&
          to && {
            validDateRange: {
              from: new Date(from),
              to: new Date(to)
            }
          })
      }
    })
  }

  return (
    <EditSidebar setEditId={() => setCreateDiscountCode(false)}>
      <form>
        <EditSidebarHeader
          title="Create discount code"
          setEditId={() => setCreateDiscountCode(false)}
        >
          <PrimaryButton
            type="submit"
            handleClick={handleSubmit(onSubmit)}
            loading={loading || searchLoading}
          >
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Row>
          <FieldTitle>Discount rule id:</FieldTitle>
          <DiscountRuleId
            onClick={() => {
              dispatch(hideEditSidebar())
              navigate(`/discounts/discount-code-rules/${discountCodeRuleId}`)
            }}
          >
            {discountCodeRuleId}
          </DiscountRuleId>
        </Row>
        <br />
        <Controller
          name="code"
          defaultValue={""}
          render={({ field }) => <Input label="Code" errors={errors} {...field} />}
          control={control}
          rules={{
            validate: async (code) => {
              return validateCode(code).then(
                (result) =>
                  result.data.searchDiscountCodes.total === 0 || "Discount code already exists"
              )
            },
            maxLength: 1000,
            required: "This is a required field"
          }}
        />
        <Controller
          name="usageLimit"
          render={({ field }) => (
            <Input
              type="number"
              placeholder="Unlimited"
              label="Usage limit"
              errors={errors}
              {...field}
            />
          )}
          control={control}
        />
        <br />
        <TimePeriodSelection errors={errors} control={control} inSideBar={true} />
      </form>
      <hr />
    </EditSidebar>
  )
}

export default CreateDiscountCode
