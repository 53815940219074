import React from "react"
import {
  SmallInfo,
  StatusCancel,
  StatusColumn,
  StatusDefault,
  StatusFull,
  StatusPart,
  StatusWrapper,
  StyledTooltip
} from "./OrderTable.styled"
import { getFormattedState } from "../helpers"
import { CustomState, OrderSearchHit } from "lib/types/generated/graphql-types"

type Props = {
  order: OrderSearchHit
  customStates: CustomState[]
}

const TableStatus = ({ order, customStates }: Props) => {
  const getStatus = (state: string, padding: string) => {
    switch (state) {
      case "FULLY_DELIVERED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Delivered</SmallInfo>
          </StatusFull>
        )
      case "PARTIALLY_DELIVERED":
        return (
          <StatusPart padding={padding}>
            <SmallInfo>Delivered in part</SmallInfo>
          </StatusPart>
        )
      case "PARTIALLY_REFUNDED":
        return (
          <StatusPart padding={padding}>
            <SmallInfo>Refunded in part</SmallInfo>
          </StatusPart>
        )
      case "FULLY_REFUNDED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Refunded</SmallInfo>
          </StatusFull>
        )
      case "FULLY_COMPENSATED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Compensated</SmallInfo>
          </StatusFull>
        )
      case "PARTIALLY_COMPENSATED":
        return (
          <StatusPart padding={padding}>
            <SmallInfo>Compensated in part</SmallInfo>
          </StatusPart>
        )
      case "CANCELLED":
        return (
          <StatusCancel padding={padding}>
            <SmallInfo>Cancelled</SmallInfo>
          </StatusCancel>
        )
      case "PLACED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Placed</SmallInfo>
          </StatusFull>
        )
      case "RELEASED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Released</SmallInfo>
          </StatusFull>
        )
      default:
        return state
    }
  }

  const getOrderStatus = () => {
    if(order.status.orderStates.length === 1) {
      return order.status.orderStates.map((status) =>
        getStatus(status, getPadding(2))
      )}

    return (
      <>
        <StyledTooltip id={`status-${order.id}`}>
          <div>
            {order?.status?.orderStates.map((state) => <div>{getFormattedState(state)}</div>)}
          </div>
        </StyledTooltip>
        <StatusDefault data-tooltip-id={`status-${order.id}`}>
          <SmallInfo>Order status ({order?.status?.orderStates?.length})</SmallInfo>
        </StatusDefault>
      </>
    )
  }

  const getStatusWithCustom = () => {
    return (
      <>
        {getOrderStatus()}
        <StyledTooltip id={`custom-${order.id}`}>
          <div>
            {order?.status?.customStates?.map((state) => (
              <div>{getCustomState(state.id)?.description ?? state.id}</div>
            ))}
          </div>
        </StyledTooltip>
        <StatusDefault data-tooltip-id={`custom-${order.id}`}>
          <SmallInfo>Custom states ({order?.status?.customStates?.length})</SmallInfo>
        </StatusDefault>
      </>
    )
  }

  const getCustomState = (id: string) =>
    customStates?.find((customState: CustomState) => customState.id === id)

  const getPadding = (length: number) =>
    length > 1 ? (length === 3 ? "0 0.5rem" : "0.5rem 1rem") : "1.5rem 1rem"

  return (
    <StatusColumn className="hideOnSmallScreens hideOnMediumScreens">
      <StatusWrapper>
        {order?.status?.customStates?.length ?? 0 > 0
          ? getStatusWithCustom()
          : order.status.orderStates.map((status) =>
              getStatus(status, getPadding(order.status.orderStates.length))
            )}
      </StatusWrapper>
    </StatusColumn>
  )
}

export default TableStatus
