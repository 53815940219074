import styled from "styled-components"

type StyledProps = {
  $selected?: boolean
}

export const AccessButton = styled.div`
  border-radius: 0.6rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    width: 1.5rem;
    margin-right: 1rem;
  }

  label {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
`

export const Scope = styled.div<StyledProps>`
  padding: 1rem;
  margin-bottom: 1rem;
  background: ${(p) => (p.$selected ? p.theme.colors.turquoiseDark : "rgba(255, 255, 255, 0.5)")};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${(p) => p.$selected && p.theme.colors.white};
  font-weight: ${(p) => p.$selected && "bold"};

  &:hover {
    cursor: pointer;
  }
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

export const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errorText};
  margin-left: 1rem;
`

export const LabelWrapper = styled.div`
  display: flex;
`
