import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"

import GET_NSHIFT_CONFIG from "graphql/queries/config/GetNShiftConfig"

import { Name } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as NshiftLogo } from "images/providers/icons/nshift.svg"

export const getLoadingRow = () => (
  <>
    <NshiftLogo />
    <div>
      <Name>ingrid-config-id</Name>
    </div>
    <div className="hideOnSmallScreens">
      <p>https://baseurl.se</p>
    </div>
    <div className="hideOnSmallScreens hideOnMediumScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMediumScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setNShiftConfigId?: Dispatch<SetStateAction<string>>
}

export const NShiftTableRow = ({ id, setNShiftConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_NSHIFT_CONFIG, {
    variables: { id: id }
  })

  const nShiftConfig = data?.getNShiftConfig

  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>
  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setNShiftConfigId) setNShiftConfigId(nShiftConfig.id)
        dispatch(showEditSidebar())
      }}
    >
      <NshiftLogo />
      <div>
        <Name>{nShiftConfig?.id}</Name>
      </div>
      <div className="hideOnSmallScreens">
        <p>{nShiftConfig?.baseUrl}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMediumScreens">
        <p>{dayjs(nShiftConfig?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMediumScreens">
        <p>{dayjs(nShiftConfig?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
