import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components"
import { useMutation, useQuery } from "@apollo/client"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { ChannelType, InputUpdateStoreGroup, StoreMarket } from "lib/types/generated/graphql-types"

import GET_STORE_GROUP from "graphql/queries/store/GetStoreGroup"
import UPDATE_STORE_GROUP from "graphql/mutations/store/UpdateStoreGroup"
import DELETE_STORE_GROUP from "graphql/mutations/store/DeleteStoreGroup"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import Box from "components/Ui/Box"
import StoreMarketTable from "components/StoreMarket/StoreMarketTable"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PageSectionHeader from "components/Ui/Page/PageSectionHeader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import UpdateStoreMarket from "../StoreMarkets/UpdateStoreMarket"
import SingleSelect from "../../../components/Ui/Form/SingleSelect"
import { countryOptions, getCountryName } from "../../../helpers/countries"
import { ReactComponent as CompanyIcon } from "images/icons/boxes-packing.svg"
import { ReactComponent as ConsumerIcon } from "images/icons/users.svg"
import { TooltipIcon } from "components/Ui/TooltipIcon"

const Container = styled.div`
  ul {
    margin: 0 0 3rem;
  }
`

const Form = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
`

const ChannelTypeWrapper = styled.div`
  display: flex;
  font-weight: 700;

  svg {
    width: 3rem;
    margin-right: 2rem;
  }
`

type Props = {
  id: string
  setEditId: Dispatch<SetStateAction<string>>
  refetch: () => void
}

const UpdateStoreGroup = ({ id, setEditId, refetch: refetchStoreGroups }: Props) => {
  const [editStoreMarket, setEditStoreMarket] = useState<StoreMarket>()
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm<InputUpdateStoreGroup>()

  const getLabel = (type: ChannelType) => {
    if (type === ChannelType.B2C) {
      return "Business-to-Consumer (B2C)"
    } else {
      return "Business-to-Business (B2B)"
    }
  }

  const resetForm = () => {
    reset({ name: "", description: "" })
    setEditId("")
  }

  const { loading, error, data, refetch } = useQuery(GET_STORE_GROUP, {
    variables: { id: id }
  })

  const refetchAll = () => {
    refetch()
    refetchStoreGroups()
  }

  const [updateStoreGroup, { loading: createLoading }] = useMutation(UPDATE_STORE_GROUP, {
    onCompleted: () => {
      refetchAll()
      dispatch(
        alertActions.actions.createAlert({
          message: "Store Group successfully updated",
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<InputUpdateStoreGroup> = (data) => {
    updateStoreGroup({
      variables: {
        ...data,
        id
      }
    })
  }

  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading Store group. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  if (loading) return <EditSidebar loading={true} />
  if (!data) return null

  const storeGroup = data?.getStoreGroup

  return (
    <Container>
      {editStoreMarket ? (
        <UpdateStoreMarket
          editStoreMarket={editStoreMarket}
          setEditStoreMarket={setEditStoreMarket}
          refetch={refetchAll}
          channelType={storeGroup.channelType}
          setEditId={setEditId}
        />
      ) : (
        <EditSidebar setEditId={setEditId}>
          <Box padding="0" margin="0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditSidebarHeader title={storeGroup.name} id={id} setEditId={setEditId}>
                <DeleteButton
                  id={id}
                  name={storeGroup.name}
                  mutation={DELETE_STORE_GROUP}
                  redirectUrl="/stores/store-groups"
                  entity="Store group"
                  refetch={refetchAll}
                  postDeleteFunction={resetForm}
                />
                <PrimaryButton type="submit" handleClick={handleSubmit(onSubmit)} loading={createLoading}>
                  Save
                </PrimaryButton>
              </EditSidebarHeader>
              <ChannelTypeWrapper>
                {storeGroup.channelType === ChannelType.B2C ? <ConsumerIcon /> : <CompanyIcon />}
                {getLabel(storeGroup.channelType)}
              </ChannelTypeWrapper>
              <hr />
              <Form>
                <EditSidebarSection>
                  <Controller
                    name="name"
                    render={({ field }) => (
                      <Input {...field} type="text" label="Name *" placeholder="Name" errors={errors} />
                    )}
                    defaultValue={storeGroup.name}
                    control={control}
                    rules={{
                      required: "This is a required field"
                    }}
                  />
                  <Controller
                    name="description"
                    render={({ field }) => (
                      <Input {...field} type="text" label="Description *" placeholder="Description" errors={errors} />
                    )}
                    defaultValue={storeGroup.description}
                    control={control}
                    rules={{
                      required: "This is a required field"
                    }}
                  />
                  {storeGroup.channelType === ChannelType.B2B && (
                    <Controller
                      name="countryOfSale"
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          label={
                            <>
                              {"Country of sale *"}
                              <TooltipIcon content="The country from which the Legal Entity making the sale is registered." />
                            </>
                          }
                          options={countryOptions}
                          setValue={(option) => setValue("countryOfSale", option.value)}
                          defaultValue={
                            storeGroup?.countryOfSale
                              ? {
                                  label: `${getCountryName(storeGroup.countryOfSale)} [${storeGroup.countryOfSale}]`,
                                  value: storeGroup.countryOfSale
                                }
                              : { label: "Select country", value: "" }
                          }
                        />
                      )}
                      control={control}
                      rules={{
                        required: "This is a required field"
                      }}
                    />
                  )}
                </EditSidebarSection>
              </Form>
              <hr />
            </form>
          </Box>
          <EditSidebarSection>
            <PageSectionHeader title="Store markets" description="Each store market represents a unique currency." />
            <StoreMarketTable
              id={id || ""}
              storeMarkets={storeGroup.storeMarkets}
              refetch={refetchAll}
              setEditStoreMarket={setEditStoreMarket}
              channelType={storeGroup.channelType}
            />
          </EditSidebarSection>
        </EditSidebar>
      )}
    </Container>
  )
}

export default UpdateStoreGroup
