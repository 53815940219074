import styled from "styled-components"
import { TabList } from "react-tabs"

type StyledProps = {
  $row?: boolean
  $expanded?: boolean
}
export const StoreGroupWrapper = styled.div`
  h4 {
    margin: 1rem 0;
  }

  h3 {
    margin: 0;
  }
`

export const TableData = styled.div<StyledProps>`
  background: ${(p) => (p.$row ? p.theme.colors.greyLighter : p.theme.colors.white)};
  display: flex;
  padding: 0.5rem;
`

export const StyledTabList = styled(TabList)`
margin: 0 0 2rem;
`

export const Flex = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(p) => p.$expanded ? "2rem": "0"};

  img {
    height: 4rem;
    margin-right: 2rem;
  }

  svg {
    width: 1.6rem;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
`

export const VariantWrapper = styled.div`
  display: flex;
`

export const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 4rem;
  position: relative;
  margin-bottom: 2rem;

  hr {
    margin: 1rem 0;
  }
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    &:hover {
      font-weight: 700;
      cursor: default;
    }
  }

  img {
    width: 1.8rem;
    margin-right: 0.5rem;
  }
`

export const TableHeader = styled.div`
  font-weight: 700;
  padding-bottom: 0.5rem;
`

export const Campaign = styled.div`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`
