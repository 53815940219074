import styled, { css } from "styled-components"
import { Link } from "react-router-dom"

import { ReactComponent as logo } from "images/brink-logo-icon.svg"

type StyledProps = {
  $expanded?: boolean
  $active?: boolean
  $subMenuExpanded?: boolean
}

export const Wrapper = styled.div<StyledProps>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transition: transform 0.2s ease 0s;
  transform: translateX(${(p) => (p.$expanded ? "0" : "-10rem")});

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    transform: translateX(0);
  }
`

export const Container = styled.div`
  position: relative;
  width: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.colors.blueDarker};
  z-index: 10002;

  hr {
    width: calc(100% - 3rem);
    margin: 1.5rem auto;
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
  }
`

export const Logo = styled(logo)<{ $isTestEnvironment: boolean | undefined }>`
  width: 6rem;
  padding-top: 2.5rem;
  fill: ${(p) => p.theme.colors.white};
  cursor: pointer;
  margin-right: ${(p) => (p.$isTestEnvironment ? "1rem" : "initial")};
  margin-bottom: 2rem;
`

export const Test = styled.p`
  background: ${(p) => p.theme.colors.yellow};
  color: ${(p) => p.theme.colors.black};
  position: absolute;
  right: 1.6rem;
  top: 0.8rem;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.1rem 0.5rem 0;
  border-radius: 0.3rem;
  line-height: 1.8rem;
`

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const NavItem = styled.div<StyledProps>`
  width: 100%;

  > a,
  > p {
    color: ${(p) => (p.$active ? p.theme.colors.turquoise : p.theme.colors.white)};
    transition: color 0.2s;

    svg {
      width: 2.2rem;
      height: 2.2rem;
      margin-bottom: 0.2rem;
      transition: fill 0.2s;
      fill: ${(p) => (p.$active ? p.theme.colors.turquoise : p.theme.colors.white)};
    }
  }
`

export const NavLinkStyles = `
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 7.5rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    padding-top: 1rem;
    background: transparent;
    transition: background 0.2s;
  `

export const NavLink = styled(Link)`
  ${NavLinkStyles}
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`

export const NavNoLink = styled.p<StyledProps>`
  ${NavLinkStyles};
  cursor: pointer;
  background-color: ${(p) =>
    p.$subMenuExpanded || p.$active ? p.theme.colors.blueDark : "transparent"};

  &:hover {
    background: ${(p) =>
      p.$subMenuExpanded ? p.theme.colors.blueDark : "rgba(255, 255, 255, 0.05)"};
  }
`

export const SubNav = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 25rem;
  padding: 1rem 2rem;
  background-color: ${(p) => p.theme.colors.blueDark};
  transform: translateX(${(p) => (p.$subMenuExpanded ? "10rem" : "calc(-100% - 10rem)")});
  transition: transform 0.4s;
  z-index: 1001;
`

export const SubTitle = styled.h3`
  padding: 0;
  font-size: 2.4rem;
  color: ${(p) => p.theme.colors.white};
  padding-bottom: 1rem;
`

const SubNavLinkStyles = css<StyledProps>`
  text-decoration: none;
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  font-weight: 400;
  color: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  width: 100%;
  background: ${(p) => (p.$active ? "rgba(255, 255, 255, 0.05)" : "transparent")};
  transition: background 0.2s;
  color: ${(p) => (p.$active ? p.theme.colors.turquoise : p.theme.colors.white)};
  margin-bottom: 0.5rem;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`

export const SubNavLink = styled(Link)<StyledProps>`
  ${SubNavLinkStyles}
`

export const SubNavExternalLink = styled.a<StyledProps>`
  ${SubNavLinkStyles}

  svg {
    fill: ${(p) => p.theme.colors.white};
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.6rem;
  }
`

export const SectionTitle = styled.h4`
  color: ${(p) => p.theme.colors.white};
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
  margin-top: 3rem;
`

export const User = styled(Link)<StyledProps>`
  padding: 1.5rem 0 2.5rem;
  color: ${(p) => (p.$active ? p.theme.colors.turquoise : p.theme.colors.white)};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.2s;

  svg {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
    fill: ${(p) => (p.$active ? p.theme.colors.turquoise : p.theme.colors.white)};
    transition: fill 0.2s;
  }

  &:hover {
    color: ${(p) => p.theme.colors.turquoise};

    svg {
      fill: ${(p) => p.theme.colors.turquoise};
    }
  }
`
