import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLazyQuery, useMutation } from "@apollo/client"
import esb from "elastic-builder"
import { Query, RULE_TYPE } from "lib/types/common"
import { useGetDiscountCodeRule } from "hooks/discounts"
import { v4 as uuidv4 } from "uuid"
import { useAppDispatch } from "lib/store"
import { Discount } from "@lib/types"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import {
  DiscountCodeRule,
  DiscountCodeSearchHit,
  LogicHolder
} from "@lib/types/generated/graphql-types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { transformLogicData } from "helpers/transformLogicData"
import { getLogicData } from "helpers/getLogicData"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { getJson } from "helpers/getJson"

import SEARCH_DISCOUNT_CODES from "graphql/queries/discount/SearchDiscountCodes"
import CREATE_OR_UPDATE_DISCOUNT_CODE_RULE from "graphql/mutations/discount/CreateOrUpdateDiscountCodeRule"

import { Activate, Deactivate, Divider } from "../Shared.styled"

import Box from "components/Ui/Box"
import PageSection from "components/Ui/Page/PageSection"
import PageHeader from "components/Ui/Page/PageHeader"
import PageSectionHeader from "components/Ui/Page/PageSectionHeader"
import LogicRules from "components/Discount/LogicRules"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import { DiscountCodeTable } from "components/Discount/DiscountCode/DiscountCodeTable"
import GenerateDiscountCodes from "./GenerateDiscountCodes"
import UpdateDiscountCode from "./UpdateDiscountCode"
import CreateDiscountCode from "./CreateDiscountCode"
import DuplicateDiscountCodeRule from "./DuplicateDiscountCodeRule"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import Status from "components/Ui/Status"
import DiscountRuleGeneralUpdateInput from "components/Discount/DiscountRule/DiscountRuleGeneralUpdateInput"
import { ActionDropdownButton } from "components/Ui/Buttons/ActionDropdownButton"

import { ReactComponent as TagIcon } from "images/icons/tag.svg"
import { ReactComponent as TagsIcon } from "images/icons/tags.svg"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import { ReactComponent as OnIcon } from "images/icons/circle-play.svg"
import { ReactComponent as OffIcon } from "images/icons/circle-minus.svg"
import { ReactComponent as CodeIcon } from "images/icons/brackets-curly.svg"
import { ActionButtons, AddButton, StyledSearch } from "./UpdateDiscountCodeRule.styled"

export const UpdateDiscountCodeRule = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [discountCodeRule, setDiscountCodeRule] = useState<DiscountCodeRule>()
  const [discountCodes, setDiscountCodes] = useState<DiscountCodeSearchHit[]>([])
  const [discountCodesTotalHits, setDiscountCodesTotalHits] = useState<number>(0)
  const [editCode, setEditCode] = useState("")
  const [generateCodes, setGenerateCodes] = useState<boolean>(false)
  const [createDiscountCode, setCreateDiscountCode] = useState<boolean>(false)
  const [duplicateDiscountCodeRule, setDuplicateDiscountCodeRule] = useState<boolean>(false)

  const [logicState, setLogicState] = useState<Discount.LogicState>({
    [uuidv4()]: {
      conditions: [],
      outcomes: []
    }
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm<Discount.DiscountRuleInputs>()

  const isStackable = watch("isStackable")

  const { data: discountCodeRuleData, loading, refetch } = useGetDiscountCodeRule(id ?? "")

  useEffect(() => {
    if (discountCodeRuleData) {
      const { getDiscountCodeRule } = discountCodeRuleData
      setDiscountCodeRule(getDiscountCodeRule)
      setValue("name", getDiscountCodeRule?.name)
      setValue("isActive", getDiscountCodeRule?.isActive)

      if (getDiscountCodeRule?.logicRules.length > 0) {
        setLogicState({})
        getDiscountCodeRule?.logicRules.forEach((logicRule: LogicHolder) => {
          setLogicState((prev: Discount.LogicState) => ({
            ...prev,
            ...(getLogicData(logicRule, getDiscountCodeRule) as Discount.LogicState)
          }))
        })
      }
    }
  }, [discountCodeRuleData])

  const referenceSearchQuery = esb.requestBodySearch().query(
    esb
      .boolQuery()
      .must(esb.matchQuery("discountCodeRuleId", id))
      .must([esb.queryStringQuery(`*${searchQuery}*`).analyzeWildcard(true)])
  )

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 25,
    sort: [{ field: "updated", order: "ASC" }],
    query: JSON.stringify(query.query),
    trackTotalHits: true
  }

  const [searchDiscountCodes, { loading: codeLoading, error: codeError, refetch: refetchCodes }] =
    useLazyQuery(SEARCH_DISCOUNT_CODES, {
      variables
    })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchDiscountCodes({
          variables
        }).then((response) => {
          setDiscountCodesTotalHits(response?.data?.searchDiscountCodes.total)
          setDiscountCodes(response?.data?.searchDiscountCodes.hits)
        })
      },
      searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery, generateCodes])

  const [addDiscountRule, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_DISCOUNT_CODE_RULE,
    {
      onCompleted: () => {
        refetch()
        dispatch(
          alertActions.actions.createAlert({
            message: "Discount code rule successfully updated",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<Discount.DiscountRuleInputs> = (data) => {
    const { name, isActive, isStackable, applyLast, isExclusive, sortOrder, from, to } = data

    const transformedData = transformLogicData(logicState)
    addDiscountRule({
      variables: {
        id,
        name,
        isActive: isActive ?? false,
        isStackable: isStackable ?? false,
        applyLast: applyLast ?? false,
        isExclusive: isExclusive ?? false,
        sortOrder,
        ...transformedData,
        ...(from &&
          to && {
            validDateRange: {
              from: new Date(from),
              to: new Date(to)
            }
          })
      }
    })
  }

  const updateActive = (status: boolean) => {
    addDiscountRule({
      variables: {
        ...discountCodeRule,
        isActive: status
      }
    })
  }

  useEffect(() => {
    if (isStackable === undefined) {
      setValue("isStackable", discountCodeRuleData?.getDiscountCodeRule?.isStackable)
      return
    }
    if (!isStackable) {
      setValue("isExclusive", false)
      setValue("applyLast", false)
    }

  }, [isStackable, discountCodeRule, setValue])

  if (loading) return <BrinkLoader />
  if (!discountCodeRule) return null

  return (
    <>
      {duplicateDiscountCodeRule && (
        <DuplicateDiscountCodeRule
          discountCodeRuleData={discountCodeRuleData.getDiscountCodeRule}
          logicState={logicState}
          setDuplicateDiscountCodeRule={setDuplicateDiscountCodeRule}
          setDiscountCodes={setDiscountCodes}
        />
      )}
      <Box padding="0" margin="0">
        <PageHeader
          title={
            <>
              {discountCodeRule.name}
              {discountCodeRule.isActive ? (
                <Status status="Active" />
              ) : (
                <Status status="Inactive" />
              )}
            </>
          }
          group="Discount rule"
          id={id}
          hasTabs
          goBackLinkUrl="/discounts/discount-rules"
          goBackLinkText="Back to discount rules"
        >
          <ActionDropdownButton
            title="Save"
            submitEvent={handleSubmit(onSubmit)}
            submitLoading={createLoading}
          >
            <li
              onClick={() => {
                setDuplicateDiscountCodeRule(true)
              }}
            >
              <CopyIcon /> Duplicate
            </li>
            <li
              onClick={() => {
                getJson(discountCodeRule)
              }}
            >
              <CodeIcon /> Get rule JSON
            </li>
            {discountCodeRule.isActive ? (
              <>
                <Divider />
                <Deactivate
                  onClick={() => {
                    updateActive(false)
                  }}
                >
                  <OffIcon /> Deactivate rule
                </Deactivate>
              </>
            ) : (
              <>
                <Divider />
                <Activate
                  onClick={() => {
                    updateActive(true)
                  }}
                >
                  <OnIcon /> Activate rule
                </Activate>
              </>
            )}
          </ActionDropdownButton>
        </PageHeader>

        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Logic rules</Tab>
            <Tab>Discount codes ({discountCodesTotalHits})</Tab>
          </TabList>
          <TabPanel>
            <form>
              <DiscountRuleGeneralUpdateInput
                discountRule={discountCodeRule}
                control={control}
                isStackable={isStackable}
                errors={errors}
                type={RULE_TYPE.EXTERNAL_RULE}
              />
            </form>
          </TabPanel>
          <TabPanel>
            <LogicRules state={logicState} setState={setLogicState} />
          </TabPanel>
          <TabPanel>
            <PageSection>
              <PageSectionHeader
                title="Discount codes"
                description="Discount codes connected to rule"
              />
              <ActionButtons>
                <AddButton
                  type="button"
                  handleClick={() => {
                    dispatch(showEditSidebar())
                    setGenerateCodes(true)
                  }}
                >
                  <TagsIcon /> Generate <div> discount </div> codes
                </AddButton>
                <AddButton
                  type="button"
                  handleClick={() => {
                    dispatch(showEditSidebar())
                    setCreateDiscountCode(true)
                  }}
                >
                  <TagIcon /> Create <div> discount </div> code
                </AddButton>
              </ActionButtons>
            </PageSection>

            {createDiscountCode && (
              <CreateDiscountCode
                discountCodeRuleId={discountCodeRule.id}
                setCreateDiscountCode={setCreateDiscountCode}
                setDiscountCodes={setDiscountCodes}
                discountCodes={discountCodes}
              />
            )}
            {editCode && (
              <UpdateDiscountCode
                discountCode={editCode}
                setEditId={setEditCode}
                setDiscountCodes={setDiscountCodes}
              />
            )}
            {generateCodes && (
              <GenerateDiscountCodes
                discountCodeRuleId={discountCodeRule.id}
                setGenerateCodes={setGenerateCodes}
                refetch={refetchCodes}
              />
            )}
            <StyledSearch
              handleOnChange={(input) => setSearchQuery(input)}
              placeholder="Search by discount code name"
              loading={codeLoading}
              defaultValue={searchQuery}
            />
            <div>
              {codeError ? (
                <ErrorMessage
                  showRefreshButton
                  message={
                    <>
                      Error loading discount codes. Try refreshing the page, or contact{" "}
                      <a href="mailto:support@brinkcommerce.com">support</a>.
                    </>
                  }
                />
              ) : (
                <DiscountCodeTable
                  discountCodes={discountCodes}
                  setCodeToShow={setEditCode}
                  loading={loading}
                  error={codeError}
                />
              )}
            </div>
          </TabPanel>
        </Tabs>
      </Box>
    </>
  )
}
