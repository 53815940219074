import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useMutation } from "@apollo/client"
import { Common } from "@lib/types"
import { CurrencyCodeRecord } from "currency-codes"
import data from "currency-codes/data"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { countryOptions, getCountryName } from "helpers/countries"

import CREATE_STORE_MARKET from "graphql/mutations/store/CreateStoreMarket"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import SingleSelect from "components/Ui/Form/SingleSelect"
import Checkbox from "components/Ui/Form/Checkbox"
import { ChannelType, InputCreateStoreMarket, StoreMarket } from "lib/types/generated/graphql-types"
import { TooltipIcon } from "components/Ui/TooltipIcon"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 0 3rem;
  width: 55rem;
  z-index: 10000;
`

const Country = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Currency = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Tax = styled.div`
  margin-bottom: 2rem;
`

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  div {
    padding-top: 0;
  }
`

interface FullInputStoreMarket extends InputCreateStoreMarket {
  countryCode: string
}

type Props = {
  id: string
  refetch: () => void
  countryCode: string
  setEditMode: Dispatch<SetStateAction<boolean>>
  setEditStoreMarket: Dispatch<SetStateAction<StoreMarket | undefined>>
  channelType: ChannelType
}

const CreateStoreMarket = ({ id, countryCode, setEditMode, refetch, setEditStoreMarket, channelType }: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<FullInputStoreMarket>()
  const dispatch = useAppDispatch()

  const currencyOptions = data.map((currency: CurrencyCodeRecord) => ({
    value: currency.code,
    label: `${currency.currency} [${currency.code}]`
  }))

  const updateCurrencyCode = (currencyCode: Common.Option) => {
    setValue("currencyCode", currencyCode.value)
  }

  const updateCountryCode = (countryCode: Common.Option) => {
    setValue("countryCode", countryCode.value)
  }

  const [addStoreMarket, { loading: createLoading }] = useMutation(CREATE_STORE_MARKET, {
    onCompleted: (data) => {
      refetch()
      setEditStoreMarket(data.createStoreMarket)
      dispatch(
        alertActions.actions.createAlert({
          message: "Store market successfully created",
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<FullInputStoreMarket> = (data) => {
    addStoreMarket({
      variables: {
        storeGroupId: id,
        countryCode: data.countryCode,
        currencyCode: data.currencyCode,
        isTaxIncludedInPrice: channelType === ChannelType.B2B ? false : data.isTaxIncludedInPrice,
        isTaxExemptionEligible: data?.isTaxExemptionEligible
      }
    })
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader title={`Create store market`} cancelEvent={() => setEditMode(false)}>
          <PrimaryButton type="submit" loading={createLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Country>
          <Controller
            name="countryCode"
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={countryOptions}
                setValue={updateCountryCode}
                defaultValue={{
                  label: getCountryName(countryCode) ?? "",
                  value: countryCode
                }}
              />
            )}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <ErrorMessage errors={errors} name="countryCode" />
        </Country>

        <Currency>
          <Controller
            name="currencyCode"
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={currencyOptions}
                setValue={updateCurrencyCode}
                defaultValue={{ label: "Select currency", value: "" }}
              />
            )}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <ErrorMessage errors={errors} name="currencyCode" />
        </Currency>
        {channelType === ChannelType.B2C && (
          <Tax>
            <Checkbox
              control={control}
              name="isTaxIncludedInPrice"
              label="Tax included in price"
              defaultValue={false}
            />
          </Tax>
        )}
        {channelType === ChannelType.B2B && (
          <CheckboxWrapper>
            <Checkbox control={control} name="isTaxExemptionEligible" label="Tax Exemption eligible" />
            <TooltipIcon content="Based on whether a session & checkout is completed using a VAT number, tax should be exempted." />
          </CheckboxWrapper>
        )}
      </form>
    </Container>
  )
}

export default CreateStoreMarket
