import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Provider } from "@lib/types/generated/graphql-types"
import { PROVIDER_TYPE } from "lib/types/common"

import { Container, EmptyList, Wrapper } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import { getLoadingRow, WalleyConfigTableRow } from "./WalleyConfigTableRow"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as Logo } from "images/providers/icons/walley.svg"
import TableHeader from "../../Ui/Table/TableHeader"

type Props = {
  providers: Provider[]
  error: object | undefined
  setWalleyConfigId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const WalleyConfigTable = ({ providers, setWalleyConfigId, error, loading }: Props) => {
  const [walleyProviders, setWalleyProviders] = useState<Provider[]>([])

  useEffect(() => {
    if (providers) {
      setWalleyProviders(
        providers?.filter((provider) => provider.type === PROVIDER_TYPE.WALLEY_CHECKOUT)
      )
    }
  }, [providers])

  const tableHeader = (
    <TableHeader iconHeader={true}>
      <div>Store ID</div>
      <div>Environment</div>
      <div>Profile name</div>
      <div>Created</div>
      <div>Updated</div>
    </TableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (walleyProviders?.length < 1)
    return (
      <EmptyList>
        <Logo /> <p> No configurations added</p>
      </EmptyList>
    )
  if (!providers) return null

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Walley settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...walleyProviders].map((walleyProvider) => (
          <WalleyConfigTableRow
            key={walleyProvider.id}
            id={walleyProvider.id}
            setWalleyConfigId={setWalleyConfigId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
