import React from "react"

import DineroFactory from "dinero.js"

import Money from "../../../../Money"
import {
  DeliveryGiftCardProduct,
  OrderDelivery,
  OrderGiftCardProductItemRetain24
} from "lib/types/generated/graphql-types"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { ReactComponent as GiftCardIcon } from "../../../../../images/icons/gift-card.svg"
import { useMutation } from "@apollo/client"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { handleErrorMessages } from "helpers/errors"
import {
  GiftCardDetails,
  GiftCardProductContainer,
  ItemName,
  ItemPrice
} from "./GiftCardItem.styled"
import { getDeliveryInput } from "../helpers"

type Props = {
  item: DeliveryGiftCardProduct
  currencyCode?: string
  orderGiftCardProduct: OrderGiftCardProductItemRetain24 | undefined
  delivery: OrderDelivery
  refetch: () => void
}

const GiftCardItem = ({
  item,
  currencyCode,
  orderGiftCardProduct,
  refetch,
  delivery,
  ...props
}: Props) => {
  const [updateDelivery, { loading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const removeGiftCardProduct = () => {
    updateDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...getDeliveryInput(delivery),
          giftCardProducts: delivery.giftCardProducts
            .filter(
              (giftCardProduct) => giftCardProduct.giftCardProductId !== item.giftCardProductId
            )
            .map((giftCardProduct) => ({ giftCardProductId: giftCardProduct.giftCardProductId }))
        }
      }
    })
  }

  return (
    <GiftCardProductContainer {...props}>
      <GiftCardDetails>
        <GiftCardIcon />
        <div>
          <ItemName>{orderGiftCardProduct?.name}</ItemName>
          <ItemPrice>
            <Money
              amount={Number(item.priceAmount ?? 0)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </ItemPrice>
        </div>
      </GiftCardDetails>
      <SecondaryButton loading={loading} handleClick={removeGiftCardProduct}>
        Undo
      </SecondaryButton>
    </GiftCardProductContainer>
  )
}

export default GiftCardItem
