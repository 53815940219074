import React, { Dispatch, SetStateAction, useEffect } from "react"
import styled from "styled-components"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import { ErrorMessage } from "@hookform/error-message"
import { Common } from "@lib/types"
import { getCountryName } from "helpers/countries"
import { CurrencyCodeRecord } from "currency-codes"
import data from "currency-codes/data"
import { useAppDispatch } from "lib/store"
import { ChannelType, InputUpdateStoreMarket, StoreMarket } from "lib/types/generated/graphql-types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import UPDATE_STORE_MARKET from "graphql/mutations/store/UpdateStoreMarket"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import SingleSelect from "components/Ui/Form/SingleSelect"
import Box from "components/Ui/Box"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import Checkbox from "components/Ui/Form/Checkbox"
import DeleteStoreMarket from "components/StoreMarket/DeleteStoreMarket"
import FlagIcon from "components/Ui/FlagIcon"
import { PaymentProviders } from "components/StoreMarket/Capabilities/PaymentProviders"
import ShippingProviders from "components/StoreMarket/Capabilities/ShippingProviders"
import { GiftCardPaymentProviders } from "../../../components/StoreMarket/Capabilities/GiftCardPaymentProviders"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import Inventories from "../../../components/StoreMarket/Inventories"
import { TooltipIcon } from "components/Ui/TooltipIcon"
import { VoyadoSelect } from "../../../components/StoreMarket/CartCapabilities/VoyadoSelect"
import BonusSelect from "../../../components/StoreMarket/CartCapabilities/BonusSelect"
import GiftCardProductSelect from "../../../components/StoreMarket/CartCapabilities/GiftCardProductSelect"

const Form = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
`

const InputSection = styled.div`
  flex: 1;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`
const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  div {
    padding-top: 0;
  }
`

type Props = {
  editStoreMarket: StoreMarket | undefined
  setEditStoreMarket: Dispatch<SetStateAction<StoreMarket | undefined>>
  refetch: () => void
  channelType: ChannelType
  setEditId: Dispatch<SetStateAction<string>>
}

const UpdateStoreMarket = ({
  editStoreMarket,
  setEditStoreMarket,
  refetch,
  channelType,
  setEditId
}: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<InputUpdateStoreMarket>()

  useEffect(() => {
    if (editStoreMarket?.capabilities) {
      setValue("capabilities.paymentProviders", editStoreMarket.capabilities.paymentProviders)
      setValue("capabilities.shippingProviders", editStoreMarket.capabilities.shippingProviders)
      setValue("capabilities.giftCardProviders", editStoreMarket.capabilities.giftCardProviders)
    }
  }, [editStoreMarket, setValue])

  const dispatch = useAppDispatch()

  const [updateStoreMarket, { loading: createLoading }] = useMutation(UPDATE_STORE_MARKET, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Store market successfully updated",
          type: "success"
        })
      )
      setEditStoreMarket(undefined)
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<InputUpdateStoreMarket> = (data) => {
    updateStoreMarket({
      variables: {
        currencyCode: data.currencyCode,
        countryCode: editStoreMarket?.countryCode,
        isTaxIncludedInPrice: channelType === ChannelType.B2B ? false : data.isTaxIncludedInPrice,
        isTaxExemptionEligible: data?.isTaxExemptionEligible,
        storeGroupId: editStoreMarket?.storeGroupId,
        cartCapabilities: {
          ...(data?.cartCapabilities?.bonus?.id
            && { bonus: { id: data?.cartCapabilities?.bonus?.id } }),
          ...(data?.cartCapabilities?.voyado?.id
            && { voyado: { id: data?.cartCapabilities?.voyado?.id } }),
          ...(data?.cartCapabilities?.retain24?.id
            && { retain24: { id: data?.cartCapabilities?.retain24?.id } })
        },
        capabilities: {
          paymentProviders: data?.capabilities?.paymentProviders ?? [],
          giftCardProviders: data?.capabilities?.giftCardProviders ?? [],
          shippingProviders: data?.capabilities?.shippingProviders ?? []
        }
      }
    })
  }

  const currencyOptions = data.map((currency: CurrencyCodeRecord) => ({
    value: currency.code,
    label: `${currency.currency} [${currency.code}]`
  }))

  const updateCurrencyCode = (currencyCode: Common.Option) => {
    setValue("currencyCode", currencyCode.value)
  }

  if (createLoading) return <EditSidebar loading={true} />

  if (!editStoreMarket) return null

  return (
    <EditSidebar
      setEditStoreMarket={setEditStoreMarket}
      cancelEvent={() => {
        setEditId("")
        dispatch(hideEditSidebar())
      }}
    >
      <Box padding="0" margin="0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditSidebarHeader
            title={getCountryName(editStoreMarket?.countryCode) ?? ""}
            icon={<FlagIcon countryCode={editStoreMarket.countryCode} />}
            cancelEvent={() => setEditStoreMarket(undefined)}
          >
            <DeleteStoreMarket
              name={getCountryName(editStoreMarket?.countryCode) ?? ""}
              storeGroupId={editStoreMarket?.storeGroupId}
              countryCode={editStoreMarket?.countryCode}
              refetch={refetch}
              icon={false}
              setEditStoreMarket={setEditStoreMarket}
            />
            <PrimaryButton
              type="submit"
              handleClick={handleSubmit(onSubmit)}
              loading={createLoading}
            >
              Save
            </PrimaryButton>
          </EditSidebarHeader>
          <Form>
            <EditSidebarSection>
              <InputSection>
                <ErrorMessage errors={errors} name="countryCode" />
              </InputSection>
              <InputSection>
                <Label>Currency</Label>
                <Controller
                  name="currencyCode"
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      options={currencyOptions}
                      setValue={updateCurrencyCode}
                      defaultValue={{
                        label: editStoreMarket?.currencyCode ?? "",
                        value: editStoreMarket?.currencyCode ?? ""
                      }}
                    />
                  )}
                  defaultValue={editStoreMarket?.currencyCode ?? undefined}
                  control={control}
                  rules={{
                    required: "This is a required field"
                  }}
                />
                <ErrorMessage errors={errors} name="currencyCode" />
              </InputSection>
              {channelType === ChannelType.B2C && (
                <Checkbox
                  control={control}
                  name="isTaxIncludedInPrice"
                  label="Tax included in price"
                  defaultValue={editStoreMarket?.isTaxIncludedInPrice ?? false}
                />
              )}
              <InputSection>
                {channelType === ChannelType.B2B && (
                  <CheckboxWrapper>
                    <Checkbox
                      control={control}
                      name="isTaxExemptionEligible"
                      label="Tax Exemption eligible"
                      defaultValue={editStoreMarket?.isTaxExemptionEligible ?? undefined}
                    />
                    <TooltipIcon content="Based on whether a session & checkout is completed using a VAT number, tax should be exempted." />
                  </CheckboxWrapper>
                )}
              </InputSection>
              <PaymentProviders
                control={control}
                errors={errors}
                setValue={setValue}
                defaultValue={editStoreMarket?.capabilities?.paymentProviders?.map((provider) => ({
                  label: provider?.id ?? "",
                  value: provider?.id ?? ""
                }))}
              />
              <GiftCardPaymentProviders
                control={control}
                errors={errors}
                setValue={setValue}
                defaultValue={editStoreMarket?.capabilities?.giftCardProviders?.map((provider) => ({
                  label: provider?.id ?? "",
                  value: provider?.id ?? ""
                }))}
              />

              <ShippingProviders
                defaultValue={editStoreMarket?.capabilities?.shippingProviders?.map((provider) => ({
                  label: provider?.id ?? "",
                  value: provider?.id ?? ""
                }))}
                control={control}
                errors={errors}
                setValue={setValue}
              />
              <VoyadoSelect
                defaultValue={editStoreMarket?.cartCapabilities?.voyado?.id}
                control={control}
                errors={errors}
                setValue={setValue}
              />
              <BonusSelect
                defaultValue={editStoreMarket?.cartCapabilities?.bonus?.id}
                control={control}
                errors={errors}
                setValue={setValue}
              />
              <GiftCardProductSelect
                defaultValue={editStoreMarket?.cartCapabilities?.retain24?.id}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </EditSidebarSection>
          </Form>
        </form>
        <hr />
        <Inventories editStoreMarket={editStoreMarket} />
      </Box>
    </EditSidebar>
  )
}
export default UpdateStoreMarket
