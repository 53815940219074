import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import GET_INGRID_CONFIG from "graphql/queries/config/GetIngridConfig"

import { Name } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as IngridLogo } from "images/providers/icons/ingrid.svg"

export const getLoadingRow = () => (
  <>
    <IngridLogo />
    <div>
      <Name>ingrid-config-id</Name>
    </div>
    <div className="hideOnSmallScreens">
      <p>https://baseurl.se</p>
    </div>
    <div className="hideOnSmallScreens hideOnMediumScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMediumScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setIngridConfigId?: Dispatch<SetStateAction<string>>
}

export const IngridTableRow = ({ id, setIngridConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_INGRID_CONFIG, {
    variables: { id: id }
  })

  const ingridConfig = data?.getIngridConfig
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>
  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setIngridConfigId) setIngridConfigId(ingridConfig.id)
        dispatch(showEditSidebar())
      }}
    >
      <IngridLogo />
      <div>
        <Name>{ingridConfig?.id}</Name>
      </div>
      <div className="hideOnSmallScreens">
        <p>{ingridConfig?.baseUrl}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMediumScreens">
        <p>{dayjs(ingridConfig?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMediumScreens">
        <p>{dayjs(ingridConfig?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
