import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useMutation } from "@apollo/client"
import { Common } from "lib/types"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { getCountryName, countryOptions } from "helpers/countries"
import { TaxMarket } from "lib/types/generated/graphql-types"

import CREATE_OR_UPDATE_TAX_MARKET from "graphql/mutations/tax/CreateOrUpdateTaxMarket"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import SingleSelect from "components/Ui/Form/SingleSelect"
import Input from "components/Ui/Form/Input"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 0 3rem;
  width: 55rem;
  z-index: 10000;
`

const Country = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Tax = styled.div``

const Formatted = styled.div`
  margin: 1rem 0;
`

type Inputs = {
  countryCode: string
  taxPercentage: number
  taxPercentageDecimals: number
}

type Props = {
  taxGroupId: string
  refetch: () => void
  countryCode: string
  setEditMode: Dispatch<SetStateAction<boolean>>
  setEditTaxMarket: Dispatch<SetStateAction<TaxMarket | undefined>>
}

const CreateTaxMarket = ({
  taxGroupId,
  countryCode,
  setEditMode,
  refetch,
  setEditTaxMarket
}: Props) => {
  const {
    control,
    setValue,
    formState: { errors }
  } = useForm<Inputs>()

  const dispatch = useAppDispatch()
  const [taxPercentage, setTaxPercentage] = useState<number>(0)
  const [taxPercentageDecimals, setTaxPercentageDecimals] = useState<number>(0)

  const updateCountryCode = (countryCode: Common.Option) => {
    setValue("countryCode", countryCode.value)
  }

  const [addTaxMarket, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_TAX_MARKET, {
    onCompleted: (data) => {
      refetch()
      setEditTaxMarket(data.createTaxMarket)
      setEditMode(false)
      dispatch(
        alertActions.actions.createAlert({
          message: "Tax market successfully created",
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const submitTaxMarket = () => {
    addTaxMarket({
      variables: {
        taxGroupId: taxGroupId,
        countryCode: countryCode,
        taxPercentage: taxPercentage,
        taxPercentageDecimals: taxPercentageDecimals
      }
    })
  }
  const updateValue = (value: Common.Option) => {
    setTaxPercentageDecimals(parseInt(value.value))
  }
  const options = [
    {
      value: "2",
      label: "2"
    },
    {
      value: "3",
      label: "3"
    }
  ]
  return (
    <Container>
      <EditSidebarHeader title={`Create tax market`} cancelEvent={() => setEditMode(false)}>
        <PrimaryButton type="submit" loading={createLoading} handleClick={submitTaxMarket}>
          Save
        </PrimaryButton>
      </EditSidebarHeader>
      <Country>
        <Controller
          name="countryCode"
          render={({ field }) => (
            <SingleSelect
              {...field}
              options={countryOptions}
              setValue={updateCountryCode}
              defaultValue={{
                label: getCountryName(countryCode) ?? "",
                value: countryCode
              }}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
        <ErrorMessage errors={errors} name="countryCode" />
      </Country>

      <Tax>
        <Controller
          name="taxPercentage"
          render={({ field }) => (
            <Input
              {...field}
              type="number"
              errors={errors}
              label="Tax percentage in minor units*"
              onChange={(e: { target: { value: number } }) => {
                setTaxPercentage(e.target.value)
              }}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field",
            min: {
              value: 0,
              message: "Least value is 0"
            }
          }}
        />
      </Tax>
      <Tax>
        <Controller
          name="taxPercentageDecimals"
          render={({ field }) => (
            <SingleSelect
              {...field}
              options={options}
              placeholder="2 or 3"
              label="Tax percentage decimals *"
              setValue={updateValue}
              defaultValue={options[0]}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field",
            min: {
              value: 2,
              message: "Least value is 2"
            },
            max: { value: 3, message: "Greatest value is 3" }
          }}
        />
      </Tax>

      <Formatted>
        <strong>Formatted: </strong>
        {taxPercentage && taxPercentageDecimals
          ? taxPercentage / 10 ** taxPercentageDecimals + "%"
          : "0"}
      </Formatted>
    </Container>
  )
}

export default CreateTaxMarket
