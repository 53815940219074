import styled from "styled-components"
import TableHeader from "components/Ui/Table/TableHeader"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
`

export const Name = styled.div`
  ${(p) => p.theme.bold};
`
