import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  column-gap: 6rem;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 2rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const InputWrapper = styled.div`
  display: flex;
  gap: 1rem;

  button {
    margin-top: 2.8rem;
    height: 4.3rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin-top: 2.9rem;
    }
  }

  span {
    margin-bottom: 0;
  }
`

export const Name = styled.div`
  margin-right: 1rem;
  width: 100%;
`

export const SortOrder = styled.div`
  min-width: 20rem;
`

export const DateWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 2rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const CheckboxWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-top: 1.3rem;
  }
`
