import gql from "graphql-tag"

const CREATE_OR_UPDATE_CUSTOM_STATE = gql`
  mutation createCustomState($stateId: ID!, $input: InputCustomState!) {
    Order {
      createCustomState(stateId: $stateId, input: $input) {
        id
      }
    }
  }
`

export default CREATE_OR_UPDATE_CUSTOM_STATE
