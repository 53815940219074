import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"

import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { CreateWalleyConfig } from "./CreateWalleyConfig"
import { WalleyConfigTable } from "components/Config/Walley/WalleyConfigTable"
import { EditWalleyConfig } from "./EditWalleyConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as WalleyLogo } from "images/providers/walley-logo.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageWalleyLogo = styled(WalleyLogo)`
  height: 5rem;
`

export const WalleyConfiguration = () => {
  const [walleyConfigId, setWalleyConfigId] = useState<string>("")
  const isSidebarOpen = useSelector(editSidebarOpen)
  const { loading, data, refetch, error } = useQuery(ALL_PAYMENT_PROVIDERS)
  const paymentProviders = data?.getPaymentProviders
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isSidebarOpen) {
      setWalleyConfigId("")
    }
  }, [isSidebarOpen])

  return (
    <Container>
      <PageHeader
        title={<PageWalleyLogo />}
        description="Manage your Walley Checkout configurations"
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(toggleEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!walleyConfigId && <CreateWalleyConfig refetch={refetch} />}
      {walleyConfigId && (
        <EditWalleyConfig
          walleyConfigId={walleyConfigId}
          setWalleyConfig={setWalleyConfigId}
          refetch={refetch}
        />
      )}
      <WalleyConfigTable
        providers={paymentProviders}
        error={error}
        setWalleyConfigId={setWalleyConfigId}
        loading={loading}
      />
    </Container>
  )
}
