import React from "react"
import { Controller } from "react-hook-form"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { Common } from "lib/types"
import styled from "styled-components"
import { InputSveaCheckoutConfig, SveaCheckoutConfig } from "@lib/types/generated/graphql-types"

import { SidebarDivider } from "components/Dashboard/Shared.styled"

import Input from "../../Ui/Form/Input"
import SingleSelect from "../../Ui/Form/SingleSelect"

const BaseUrlSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

export interface FormInputSveaCheckoutConfig extends InputSveaCheckoutConfig {
  id: string
}

type Props = {
  control: Control<FormInputSveaCheckoutConfig>
  errors: FieldErrors
  setValue: UseFormSetValue<FormInputSveaCheckoutConfig>
  showId?: boolean
  defaultValues?: SveaCheckoutConfig
}

export const SveaConfigInputs = ({
  control,
  errors,
  setValue,
  showId = false,
  defaultValues
}: Props) => {


  const updateEnv = (env: Common.Option) => {
    if (env.value === "test") {
      setValue("checkoutBaseUrl", "https://checkoutapistage.svea.com")
      setValue("paymentAdminBaseUrl", "https://paymentadminapistage.svea.com")
    } else {
      setValue("checkoutBaseUrl", "https://checkoutapi.svea.com")
      setValue("paymentAdminBaseUrl", "https://paymentadminapisvea.com")
    }
  }

  const isProdEnv = () =>
    defaultValues?.checkoutBaseUrl === "https://checkoutapi.svea.com" &&
    defaultValues?.paymentAdminBaseUrl === "https://paymentadminapisvea.com"

  return (
    <>
      {showId && (
        <>
          <Controller
            name="id"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Identifier *"
                placeholder="Aa - Zz, 0 - 9, -_."
                description="For internal use in Brink"
                errors={errors}
              />
            )}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_.-]+$/,
                message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
              }
            }}
            defaultValue=""
          />
          <SidebarDivider />
        </>
      )}
      <Controller
        name="merchantId"
        render={({ field }) => (
          <Input {...field} type="text" label="Svea checkout merchant id *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.merchantId}
      />
      <Controller
        name="apiKey"
        render={({ field }) => (
          <Input {...field} type="text" label="Svea checkout API-key *" errors={errors} />
        )}
        control={control}
        defaultValue={""}
        rules={{
          required: "This is a required field"
        }}
      />
      <BaseUrlSelect
        options={[
          {
            value: "test",
            label: "Test"
          },
          {
            value: "production",
            label: "Production"
          }
        ]}
        setValue={updateEnv}
        defaultValue={
          isProdEnv()
            ? { label: "Production", value: "production" }
            : { label: "Test", value: "test" }
        }
        label="Environment *"
      />
    </>
  )
}
