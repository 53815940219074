import { OrderCompensation } from "@lib/types/generated/graphql-types"
import { OrderOperations } from "./OrderOperations"

export class Compensation extends OrderOperations {
  constructor(compensations: OrderCompensation[]) {
    super(compensations)
  }

  public completedCompensations(): OrderCompensation[] {
    return this.completedList() as OrderCompensation[]
  }

  public onGoingCompensations(): OrderCompensation[] {
    return this.onGoingList() as OrderCompensation[]
  }

  public failedCompensations(): OrderCompensation[] {
    return this.failedList() as OrderCompensation[]
  }

  public getOngoingCompensation(): OrderCompensation {
    const onGoingList = this.onGoingList() as OrderCompensation[]
    return onGoingList[0]
  }

  public getPendingCompensation(): OrderCompensation {
    const pendingList = this.pendingList() as OrderCompensation[]
    return pendingList[0]
  }

  public getCompletedGiftCardAmount(id: string): number {
    return (
      this.completedCompensations()
        ?.flatMap((compensation) => compensation.giftCards)
        .find((compensatedGiftCard) => compensatedGiftCard?.giftCardId === id)?.amount ?? 0
    )
  }

  public paymentTotal(): number {
    return this.completedCompensations()
      .map((compensation) => compensation.totals.grandTotal)
      .reduce((a, b) => a + b, 0)
  }

  public giftCardTotal(): number {
    return this.completedCompensations()
      .map((compensation) => compensation.totals.giftCardTotal)
      .reduce((a, b) => a + b, 0)
  }

  public bonusTotal(): number {
    return this.completedCompensations()
      .map((compensation) => compensation.totals.bonusTotal)
      .reduce((a, b) => a + b, 0)
  }
}
