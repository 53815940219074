import React from "react"
import { CustomReasonCode, Maybe, OrderCancellation } from "@lib/types/generated/graphql-types"

import dayjs from "dayjs"
import {
  Container,
  Dates,
  Footer,
  Label,
  NotCompleted,
  PaymentInfo,
  ProviderStatus,
  Row
} from "./Shared.styled"
import Status from "../../Ui/Status"
import { useQuery } from "@apollo/client"
import GET_CANCELLATION from "../../../graphql/queries/order/GetCancellation"
import LoadingRow from "./LoadingRow"
import Reason from "./Reason"

type Props = {
  cancellationId: string
  getCustomCode: (code: Maybe<string> | undefined) => CustomReasonCode
}
const Cancellation = ({ cancellationId, getCustomCode }: Props) => {
  const { data, loading } = useQuery(GET_CANCELLATION, {
    variables: { cancellationId: cancellationId }
  })

  const cancellation = data?.Order?.cancellation as OrderCancellation
  if (loading) return <LoadingRow />
  return (
    <Container>
      {!cancellation.completed && <NotCompleted>Not completed</NotCompleted>}
      <h3>Cancellation #{cancellation.id}</h3>
      <Dates>
        <div>
          Started: <b>{dayjs(cancellation.started).format("YYYY-MM-DD : HH:mm:ss")}</b>
        </div>
        {cancellation.completed && (
          <div>
            Completed: <b>{dayjs(cancellation?.completed).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
        )}
        {cancellation?.restarted && (
          <>
            <div>
              Restarted: <b>{dayjs(cancellation.restarted).format("YYYY-MM-DD : HH:mm:ss")}</b>
            </div>
            <div>
              Restarts: <b>{cancellation.restarts}</b>
            </div>
          </>
        )}
      </Dates>
      <Reason reason={cancellation?.reason} getCustomCode={getCustomCode} />
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>
          <Status status={cancellation.paymentProvider.status?.current ?? ""} />
        </div>
        {cancellation?.bonusProvider && (
          <div>
            <Label>Bonus status:</Label>{" "}
            <Status status={cancellation?.bonusProvider?.status?.current ?? ""} />
          </div>
        )}
        {cancellation?.giftCardProvider && (
          <div>
            <Label>Gift card status:</Label>
            <Status status={cancellation?.giftCardProvider?.status?.current ?? ""} />
          </div>
        )}
        {cancellation?.bonus && (
          <div>
            <Label>Bonus reservationId:</Label> <b>{cancellation?.bonus?.reservationId}</b>
          </div>
        )}
        {cancellation?.giftCards.length > 0 && (
          <>
            {`Gift card${cancellation?.giftCards.length > 1 ? "s" : ""}:`}
            {cancellation.giftCards.map((giftCard) => (
              <Row key={giftCard.giftCardId}>
                <span>
                  Id: <b>{giftCard.giftCardId}</b>
                </span>
                <span>
                  Status: <Status status={giftCard.status} />
                </span>
              </Row>
            ))}
          </>
        )}
      </ProviderStatus>
      <PaymentInfo>
        {cancellation?.cancelledPayment && (
          <div>
            Payment reference: <b>{cancellation.cancelledPayment?.reference}</b>
          </div>
        )}
      </PaymentInfo>
      <Footer />
    </Container>
  )
}

export default Cancellation
