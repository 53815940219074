import styled from "styled-components"
import { StoreMarketsTable } from "./SetChannels.styled"

export const Container = styled.div`
margin-bottom: 2rem;
`


export const ErrorMessage = styled.div`
  background: ${(props) => props.theme.colors.error.background};
  color: ${(props) => props.theme.colors.error.text};
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  font-weight: 700;

  svg {
    width: 1.6rem;
    fill: ${(props) => props.theme.colors.error.text};
    margin-right: 1rem;
  }
`

export const StyledStoreMarketsTable = styled(StoreMarketsTable)`
  min-height: 0;
  margin-top: 1rem;
`

export const ChannelsWrapper = styled.div`
  margin: 2rem 0;
  border: 0.1rem solid ${(props) => props.theme.colors.borderLight};
  border-radius: 0.6rem;
  background: ${(props) => props.theme.colors.white};
  padding: 0 2rem;
`

export const GeneralWrapper = styled.div`
  margin: 2rem 0;

`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;

  h3 {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    height: 2rem;
    margin-right: 1rem;
  }

  span {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
`
export const GeneralHeader = styled(Header)`
  padding: 0;
  &:hover {
    cursor: default;
  }
`

export const TimePeriod = styled.div`
display: flex;
  align-items: center;
b {
  margin-right: 1rem;
}

  svg {
    width: 1.6rem;
    margin-left: 1rem;
  }
`

