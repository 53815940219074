import React from "react"
import styled, { keyframes } from "styled-components"

const load = keyframes`
0% {
  box-shadow: 9988px 0 0 0 #000, 9999px 0 0 0 #000, 10010px 0 0 0 #000;
}
16.667% {
  box-shadow: 9988px -10px 0 0 #000, 9999px 0 0 0 #000, 10010px 0 0 0 #000;
}
33.333% {
  box-shadow: 9988px 0 0 0 #000, 9999px 0 0 0 #000, 10010px 0 0 0 #000;
}
50% {
  box-shadow: 9988px 0 0 0 #000, 9999px -10px 0 0 #000, 10010px 0 0 0 #000;
}
66.667% {
  box-shadow: 9988px 0 0 0 #000, 9999px 0 0 0 #000, 10010px 0 0 0 #000;
}
83.333% {
  box-shadow: 9988px 0 0 0 #000, 9999px 0 0 0 #000, 10010px -10px 0 0 #000;
}
100% {
  box-shadow: 9988px 0 0 0 #000, 9999px 0 0 0 #000, 10010px 0 0 0 #000;
}
`

const Loader = styled.div`
  margin-left: 1.5rem;
  position: relative;
  left: -9999px;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.2rem;
  animation: ${load} 1.5s infinite linear;
`

const DotLoader = () => <Loader />

export default DotLoader
