import React, { useState, useEffect, ReactNode } from "react"

import {
  Container,
  GoBackLink,
  Group,
  PageTitle,
  Id,
  Description,
  Actions,
  StickyContainer,
  Wrapper,
  GoBackLinkSticky,
  StickyActionButtons
} from "./PageHeader.styled"
import CopyButton from "../CopyButton"

import { ReactComponent as ArrowLeft } from "images/icons/arrow-left.svg"

type Props = {
  children?: React.ReactNode | React.ReactNode[] | React.ReactNode
  title: string | React.ReactNode
  id?: string
  description?: string | React.ReactNode | React.ReactNode[] | ReactNode
  group?: string
  goBackLinkUrl?: string
  goBackLinkText?: string
  hasTabs?: boolean
}

const PageHeader = ({
  children,
  title,
  id,
  description,
  group,
  goBackLinkUrl,
  goBackLinkText,
  hasTabs = false,
  ...props
}: Props) => {
  const [sticky, setSticky] = useState(false)

  const stick = () => {
    const currentPos = window.pageYOffset
    setSticky(currentPos > 130)
  }

   
  useEffect(stick, [])

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", stick)
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", stick)
    }
  })
  return (
    <>
      <Container $hasTabs={hasTabs} {...props}>
        <div>
          {goBackLinkUrl && (
            <GoBackLink to={goBackLinkUrl}>
              <ArrowLeft /> {goBackLinkText || "Go back"}
            </GoBackLink>
          )}
          {group && <Group>{group}</Group>}
          <PageTitle>{title}</PageTitle>
          {id && (
            <Id>
              <span>{id}</span>
              <CopyButton string={id} />
            </Id>
          )}
          {description && <Description>{description}</Description>}
        </div>
        <Actions>{children}</Actions>
      </Container>

      <StickyContainer $sticky={sticky}>
        <Wrapper>
          {goBackLinkUrl && (
            <GoBackLinkSticky to={goBackLinkUrl}>
              <ArrowLeft />
            </GoBackLinkSticky>
          )}
          {group && <Group>{group}</Group>}
          <PageTitle>{title}</PageTitle>
        </Wrapper>
        <StickyActionButtons>{children}</StickyActionButtons>
      </StickyContainer>
    </>
  )
}

export default PageHeader
