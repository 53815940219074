import styled from "styled-components"

type StyledProps = {
  $selected?: boolean
  $orderPercentage?: boolean
}

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  button {
    position: absolute;
    right: 0;
    bottom: 2rem;

    svg {
      margin-left: 1rem;
    }
  }
`

export const RowContainer = styled.div`
  min-height: 7rem;
  padding: 1.5rem 2rem;
  margin: 1rem 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;

  hr {
    margin: 1.5rem 0;
  }

  &:hover {
    cursor: initial;
    background: ${(p) => p.theme.colors.white};
  }
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  margin-bottom: 0;
  flex-direction: column;

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    flex-direction: row;
  }

  &:hover {
    cursor: initial;
    background: ${(p) => p.theme.colors.white};
  }

  svg {
    height: 4rem;
  }
`

export const OrderPercentageContainer = styled.div`
  margin: 2rem 0;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`

export const PercentageButtonWrapper = styled.div<StyledProps>`
  display: flex;
  padding-top: 0.5rem;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 9rem;
  gap: 0.5rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`

export const Amount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const AmountInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
`

export const InputPercentage = styled.input`
  border-radius: 0.6rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  width: 4.5rem;
  height: 3rem;
  padding: 0 1rem;
`

export const PercentageButton = styled.div<StyledProps>`
  background: ${(p) =>
    p.$orderPercentage && p.$selected
      ? p.theme.colors.greyDark
      : p.$selected
      ? p.theme.colors.turquoiseDark
      : p.theme.colors.white};
  color: ${(p) => (p.$selected ? p.theme.colors.white : p.theme.colors.black)};
  border: 0.1rem solid
    ${(p) => ( p.$orderPercentage && p.$selected ? p.theme.colors.greyLight : p.$selected ? p.theme.colors.turquoiseDark : p.theme.colors.greyLight)};
  font-weight: ${(p) => (p.$selected ? "bold" : "normal")};
  border-radius: 0.6rem;
  width: 4.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.turquoiseDark};
    color: ${(p) => p.theme.colors.white};
  }
`

export const Compensated = styled.div`
  border-left: 0.4rem solid ${(p) => p.theme.colors.turquoiseDark};
  padding-left: 1rem;
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    text-decoration: line-through;
  }
`

export const ProductImage = styled.img`
  width: 5rem !important;
  display: block;
  flex-shrink: 0;
  border-radius: 0.6rem;
  align-self: flex-start;
`

export const Id = styled.p`
  font-size: 1.1rem;
  margin-top: 0.5rem;
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  background: ${(p) => p.theme.colors.greyLighter};
  border-radius: 0.4rem;
  width: fit-content;
  padding: 0 0.5rem;

  span {
    color: ${(p) => p.theme.colors.greyDarker};
  }
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`


export const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  padding: 2rem;
  border-radius: 0.6rem;
`

export const ProviderStatus = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 2rem 0 1rem 0;

  div {
    display: flex;
    margin-bottom: 1rem;
  }

  span {
    width: 24rem;
    display: flex;
    justify-content: center;
  }
`

