import React from "react"
import styled from "styled-components"
import { OrderPaymentAdyen } from "@lib/types/generated/graphql-types"
import { getPaymentMethodDisplayName, getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"

import Status from "components/Ui/Status"
import { List, Item, Value, Label } from "components/Ui/List/List"

import { ReactComponent as AdyenLogo } from "images/providers/adyen-vector-logo.svg"

const Logo = styled(AdyenLogo)`
  position: absolute;
  height: 2.5rem;
  right: 3rem;
`

type Props = {
  orderPayment: OrderPaymentAdyen
}

const Adyen = ({ orderPayment }: Props) => {
  if (!orderPayment) return null

  return (
    <List>
      <Logo />
      <Item>
        <Label>Status:</Label>
        <Value>
          <Status status={orderPayment.status.toUpperCase()} />
        </Value>
      </Item>
      {orderPayment.failedReason ? (
        <Item>
          <Label>Failed reason:</Label>
          <Value>
            <Status status={orderPayment.failedReason} />
          </Value>
        </Item>
      ) : (
        <></>
      )}
      <Item>
        <Label>Potential fraud:</Label>
        <Value>
          <Status status={orderPayment.potentialFraud ? "Yes" : "No"} />
        </Value>
      </Item>
      <Item>
        <Label>Payment provider:</Label>
        <Value>{getPaymentProviderDisplayName(orderPayment.providerName)}</Value>
      </Item>
      <Item>
        <Label>Payment method:</Label>
        <Value capitalize>{getPaymentMethodDisplayName(orderPayment.method)}</Value>
      </Item>
      <Item>
        <Label>Payment reference:</Label>
        <Value>{orderPayment.reference}</Value>
      </Item>
      <Item>
        <Label>Merchant account code:</Label>
        <Value>{orderPayment.merchantAccountCode}</Value>
      </Item>
      <Item>
        <Label>Provider ID:</Label>
        <Value>{orderPayment.providerId}</Value>
      </Item>
    </List>
  )
}

export default Adyen
