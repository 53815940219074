import React from "react"
import styled from "styled-components"
import DineroFactory from "dinero.js"

import { OrderBonus } from "@lib/types/generated/graphql-types"

import Status from "components/Ui/Status"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import Money from "components/Money"
import { ReactComponent as Icon } from "images/icons/hand-holding-dollar.svg"

const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }

  > div {
    flex: 1;
  }
`

type Props = {
  orderBonus: OrderBonus
}

const Bonus = ({ orderBonus }: Props) => {
  if (!orderBonus) return null

  return (
    <Columns>
      <OrderBlock>
        <h2>
          <Icon />
          Bonus
        </h2>
        <List>
          {orderBonus.providerName ? (
            <>
              <Item>
                <Label>Provider name:</Label>
                <Value>{orderBonus.providerName}</Value>
              </Item>
              <Item>
                <Label>Provider ID:</Label>
                <Value>{orderBonus.providerId}</Value>
              </Item>
              <Item>
                <Label>Reservation ID:</Label>
                <Value>{orderBonus.reservationId}</Value>
              </Item>
              <Item>
                <Label>Customer ID:</Label>
                <Value>{orderBonus.customerId}</Value>
              </Item>
              <Item>
                <Label>Status:</Label>
                <Value>
                  <Status status={orderBonus.status} />
                </Value>
              </Item>
              <Item>
                <Label>Amount:</Label>
                <Value>
                  <Money
                    amount={parseInt(`${orderBonus.amount}`)}
                    currencyUnit={orderBonus.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
              {orderBonus.points ? (
                <Item>
                  <Label>Bonus points:</Label>
                  <Value>{orderBonus.points}</Value>
                </Item>
              ) : (
                <></>
              )}
              {orderBonus.failedReason ? (
                <Item>
                  <Label>Failed reason:</Label>
                  <Value>
                    <Status status={orderBonus.failedReason} />
                  </Value>
                </Item>
              ) : (
                <></>
              )}
            </>
          ) : (
            <p>No bonus used for this order.</p>
          )}
        </List>
      </OrderBlock>
    </Columns>
  )
}

export default Bonus
