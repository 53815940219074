import React, { KeyboardEvent, useState } from "react"
import { ButtonWrapper, Comment, CommentInput, Info } from "./Operations.styled"
import { ReactComponent as CommentIcon } from "images/icons/message-lines.svg"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"
import { ReactComponent as GearIcon } from "images/icons/gear.svg"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { ReactComponent as XmarkIcon } from "images/icons/xmark.svg"
import { ReactComponent as CheckIcon } from "images/icons/check.svg"

import Popup from "../../../Ui/Popup"
import { useMutation } from "@apollo/client"
import DELETE_ORDER_COMMENT from "../../../../graphql/mutations/order/comment/DeleteOrderComment"
import UPDATE_ORDER_COMMENT from "../../../../graphql/mutations/order/comment/UpdateOrderComment"
import { useSelector } from "react-redux"
import { selectUserAttributes } from "lib/store/services/auth/selectors"
import { isSuperUser } from "helpers/user"

type Props = {
  comment: string | undefined
  commentId: string
  orderId: string
  refetch: () => void
  actorEmail: string
}

const CommentInfo = ({ comment, commentId, orderId, refetch, actorEmail }: Props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [editComment, setEditComment] = useState(false)
  const [updatedComment, setUpdatedComment] = useState(comment)
  const userAttributes = useSelector(selectUserAttributes)

  const editPermission = () => userAttributes.email === actorEmail

  const [deleteComment, { loading }] = useMutation(DELETE_ORDER_COMMENT, {
    variables: { commentId: commentId, orderId: orderId },
    onCompleted: () => {
      refetch()
      setShowPopup(false)
    }
  })

  const [updateComment, { loading: updateLoading }] = useMutation(UPDATE_ORDER_COMMENT, {
    variables: { commentId: commentId, orderId: orderId, comment: { text: updatedComment } },
    onCompleted: () => {
      refetch()
      setEditComment(false)
    }
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateComment()
    }
  }

  return (
    <Info className={updateLoading ? "blur" : ""}>
      {showPopup && (
        <Popup
          title="Delete comment"
          subtitle={`Are you sure you want to delete the following comment?`}
          handleCloseClick={() => setShowPopup(false)}
          handleOkClick={() => deleteComment()}
          type="delete"
          loading={loading}
        >
          <Comment>
            <CommentIcon />
            {comment}
          </Comment>
        </Popup>
      )}
      {editComment ? (
        <CommentInput
          name={"comment"}
          value={updatedComment}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setUpdatedComment(e.currentTarget.value)
          }
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Comment>
          <CommentIcon />
          <b>{comment}</b>
        </Comment>
      )}
      {editPermission() && isSuperUser() && (
        <ButtonWrapper>
          <SecondaryButton handleClick={() => setEditComment(!editComment)}>
            {editComment ? <XmarkIcon /> : <GearIcon />}
          </SecondaryButton>
          {editComment && (
            <SecondaryButton handleClick={() => updateComment()}>
              <CheckIcon />
            </SecondaryButton>
          )}
          {!editComment && (
            <SecondaryButton handleClick={() => setShowPopup(true)}>
              <TrashIcon />
            </SecondaryButton>
          )}
        </ButtonWrapper>
      )}
    </Info>
  )
}

export default CommentInfo
