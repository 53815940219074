import gql from "graphql-tag"

const DELETE_REASON_CODE = gql`
  mutation deleteCustomReasonCode($code: ID!) {
    Order {
      deleteCustomReasonCode(code: $code)
    }
  }
`

export default DELETE_REASON_CODE
