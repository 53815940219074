import {
  OrderCancellation,
  OrderCompensation,
  OrderDelivery,
  OrderModification,
  OrderRelease,
  OrderRefund
} from "@lib/types/generated/graphql-types"

export class OrderOperations {
  private readonly operations: (
    | OrderDelivery
    | OrderCompensation
    | OrderModification
    | OrderRelease
    | OrderCancellation
    | OrderRefund
  )[]

  constructor(
    operations: (
      | OrderDelivery
      | OrderCompensation
      | OrderModification
      | OrderRelease
      | OrderCancellation
      | OrderRefund
    )[]
  ) {
    this.operations = operations
  }

  protected completedList(): (
    | OrderDelivery
    | OrderCompensation
    | OrderModification
    | OrderRelease
    | OrderCancellation
    | OrderRefund
  )[] {
    return (
      this.operations?.filter(
        (
          operation:
            | OrderDelivery
            | OrderCompensation
            | OrderModification
            | OrderRelease
            | OrderCancellation
            | OrderRefund
        ) => operation.completed
      ) ?? []
    )
  }

  protected onGoingList(): (
    | OrderDelivery
    | OrderCompensation
    | OrderModification
    | OrderRelease
    | OrderCancellation
    | OrderRefund
  )[] {
    return this.operations?.filter(
      (operation) => !operation.completed && !operation.started && !operation.failed
    )
  }

  protected pendingList(): (
    | OrderDelivery
    | OrderCompensation
    | OrderModification
    | OrderRelease
    | OrderCancellation
    | OrderRefund
  )[] {
    return this.operations?.filter(
      (operation) => !operation.completed && operation.started && !operation.failed
    )
  }

  protected failedList(): (
    | OrderDelivery
    | OrderCompensation
    | OrderModification
    | OrderRelease
    | OrderCancellation
    | OrderRefund
  )[] {
    return this.operations?.filter((operation) => !!operation.failed)
  }

  public completed(): boolean {
    return this.completedList()?.length > 0
  }

  public onGoing(): boolean {
    return this.onGoingList()?.length > 0
  }

  public pending(): boolean {
    return this.pendingList()?.length > 0
  }

  public failed(): boolean {
    return this.failedList()?.length > 0
  }

  public active(): boolean {
    return this.onGoing() || this.pending() || this.failed()
  }

}




