import React from "react"

import { Container, ProgressBar, Upcoming } from "./Progress.styled"

import { ReactComponent as UpcomingnIcon } from "images/icons/calendar-clock.svg"
import { ReactComponent as CheckIcon } from "images/icons/check.svg"
import { ReactComponent as ArrowIcon } from "images/icons/arrows-left-right.svg"
import styled from "styled-components"
import dayjs from "dayjs"

type Props = {
  startDate: string
  endDate: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1rem;
`
const DateWrapper = styled.div`
  display: none;

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
  }
  svg {
    width: 1.1rem;
  }
`
export const Progress = ({ startDate, endDate, ...props }: Props) => {
  if (!startDate || !endDate) return null

  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()
  const diff = end - start
  const now = Date.now()
  const untilNow = now - start
  const calc = untilNow / diff
  const percentage = end < now ? 100 : calc * 100

  return (
    <Wrapper>
      <DateWrapper>
        <span>{dayjs(startDate).format("YYYY-MM-DD HH:mm")}</span>
        <ArrowIcon/>
        <span>{dayjs(endDate).format("YYYY-MM-DD HH:mm")}</span>
      </DateWrapper>
      <Container {...props}>
        <ProgressBar $percentage={percentage} />
        {now < start ? (
          <Upcoming>
            <UpcomingnIcon /> Upcoming
          </Upcoming>
        ) : end < now ? (
          <span>
            <CheckIcon />
            Completed
          </span>
        ) : (
          <span>Running ({Math.round(percentage)}%)</span>
        )}
      </Container>
    </Wrapper>
  )
}
