import styled from "styled-components"

export const Title = styled.h2`
  display: flex;
  align-items: center;

  svg {
    height: 2.8rem;
    width: 2.8rem;
    margin-right: 1rem;
  }
`

export const Banner = styled.div`
  background: ${(p) => p.theme.colors.turquoiseDarker};
  color: ${(p) => p.theme.colors.white};
  font-weight: 700;
  position: absolute;
  transform-origin: 50% 50%;
  font-size: 1.1rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  left: -2.4rem;
  top: 1.3rem;
  rotate: -45deg;

  &:before {
    content: "";
    position: absolute;
    top: -0.1rem;
    right: -0.1rem;
    rotate: 180deg;
    border-top: 2.7rem solid transparent;
    border-left: 2.7rem solid ${(p) => p.theme.colors.background};
    width: 0;
  }

  &:after {
    content: "";
    position: absolute;
    top: -0.1rem;
    left: -0.1rem;
    rotate: 90deg;
    border-top: 2.7rem solid transparent;
    border-left: 2.7rem solid ${(p) => p.theme.colors.background};
    width: 0;
  }
`
