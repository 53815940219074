import styled from "styled-components"

type StyledProps = {
  addedReason: boolean
}

export const Container = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: ${p => p.addedReason ? "1fr" : "1fr 2fr 2fr"};
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    grid-template-columns: ${p => p.addedReason ? "1fr" : "3fr 3fr 2fr"};
  }
`

export const Label = styled.h4`
margin: 0;
`

export const AddedReason =styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  span {
    margin-right: 1rem;
  }

  svg {
    height: 1.6rem;

    &:hover {
      cursor: pointer;
      fill: ${(p) => p.theme.colors.grey};
    }
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
svg {
  height: 1.6rem;
  margin-right: 1rem;
}

  &:hover {
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  margin: 2rem 0;
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 0.6rem;
  background: ${p => p.theme.colors.background};
`

