import styled from "styled-components"

export const ShowMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  button {
    padding: 1rem 3rem;
  }
`
