import React, { useEffect, useState } from "react"
import { useOutlet } from "react-router-dom"
import styled from "styled-components"

import { Sidebar } from "components/Ui/Navigation/Sidebar/Sidebar"
import Footer from "components/Ui/Footer"
import Alert from "components/Ui/Messages/Alert"
import { MobileHeader } from "components/Ui/MobileHeader"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import GraphqlErrorMessage from "../components/Ui/Messages/GraphqlErrorMessage"

const Wrapper = styled.div`
  padding: 2rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: ${(p) => p.theme.colors.background};
  margin-top: 5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 6rem 6rem 0 16rem;
    margin-top: 0;
  }
`

const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 20rem);
  width: 100%;
  position: relative;
`

export const Layout = () => {
  const outlet = useOutlet()
  const [sideBarExpanded, setSidebarExpanded] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(hideEditSidebar())
    }
  }, [])

  return (
    <>
      <Sidebar sideBarExpanded={sideBarExpanded} setSidebarExpanded={setSidebarExpanded} />
      <MobileHeader sideBarExpanded={sideBarExpanded} setSidebarExpanded={setSidebarExpanded} />
      <Wrapper>
        <Container>{outlet}</Container>
        <Footer />
        <Alert />
        <GraphqlErrorMessage/>
      </Wrapper>
    </>
  )
}
