import { RootState } from "@lib/store"
import { State } from "@lib/types"
import { createSelector } from "@reduxjs/toolkit"


const selectBrink = (state: RootState): State.AnalyticsState => state.analytics
export const selectFilteredDays = createSelector(selectBrink, (state) => state.filteredDays)
export const selectStoreGroups = createSelector(selectBrink, (state) => state.storeGroups)
export const selectStoreMarkets = createSelector(selectBrink, (state) => state.storeMarkets)
export const selectTopProductsFilteredBy = createSelector(selectBrink, (state) => state.topProductsFilteredBy)
export const selectTopProductsFilteredByType = createSelector(selectBrink, (state) => state.topProductsFilteredByType)
export const selectTopProductsFilteredByHits = createSelector(selectBrink, (state) => state.topProductsFilteredByHits)
export const selectTopCodesFilteredByHits = createSelector(selectBrink, (state) => state.topCodesFilteredByHits)
export const selectProductNames = createSelector(selectBrink, (state) => state.productNames)
export const selectFilteredByExcludingTax = createSelector(selectBrink, (state) => state.filteredByExcludingTax)
