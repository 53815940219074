import React, { useEffect } from "react"
import { useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { hideEditSidebar, showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import dayjs from "dayjs"

import GET_GENERATE_CODES from "graphql/queries/discount/GetGenerateCodes"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import Status from "components/Ui/Status"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { List, Item, Label, Value } from "components/Ui/List/List"

import { ReactComponent as CsvIcon } from "images/icons/file-csv.svg"

const StyledList = styled(List)`
  padding-bottom: 4rem;
`

const StyledItem = styled(Item)`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`

const StyledLabel = styled(Label)`
  width: 25rem;
`

const DiscountRuleId = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const StyledButton = styled(PrimaryButton)`
  margin: 0 auto 3rem;
`

type Props = {
  jobId: string
}

const ShowCodeGenerationJob = ({ jobId }: Props) => {
  const { data, loading, error } = useQuery(GET_GENERATE_CODES, {
    variables: { jobId }
  })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (jobId) {
      dispatch(showEditSidebar())
    }
  }, [jobId])

  if (loading) return <EditSidebar loading={true} />
  if (error || !data)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading code generation jobs. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const {
    status,
    created,
    updated,
    numberOfCodesRequested,
    numberOfCodesGenerated,
    prefix,
    usageLimit,
    discountCodeRuleId,
    fileUrl
  } = data.getGenerateCodes

  return (
    <EditSidebar>
      <EditSidebarHeader title="Code generation job" id={jobId} />
      <StyledList>
        <StyledItem>
          <StyledLabel>Status</StyledLabel>
          <Status status={status} />
        </StyledItem>
        <StyledItem>
          <StyledLabel>Created:</StyledLabel>
          <Value>
            <>{dayjs(created).format("YYYY-MM-DD HH:mm")}</>
          </Value>
        </StyledItem>
        <StyledItem>
          <StyledLabel>Updated:</StyledLabel>
          <Value>
            <>{dayjs(updated).format("YYYY-MM-DD HH:mm")}</>
          </Value>
        </StyledItem>
        <StyledItem>
          <StyledLabel>Number of codes requested:</StyledLabel>
          <Value>{numberOfCodesRequested}</Value>
        </StyledItem>
        <StyledItem>
          <StyledLabel>Number of codes generated:</StyledLabel>
          <Value>{numberOfCodesGenerated}</Value>
        </StyledItem>
        <StyledItem>
          <StyledLabel>Prefix:</StyledLabel>
          <Value>{prefix}</Value>
        </StyledItem>
        <StyledItem>
          <StyledLabel>Usage limit:</StyledLabel>
          <Value>{usageLimit}</Value>
        </StyledItem>
        <StyledItem showAsColumn>
          <StyledLabel>Discount rule id:</StyledLabel>
          <DiscountRuleId
            onClick={() => {
              dispatch(hideEditSidebar())
              navigate(`/discounts/discount-code-rules/${discountCodeRuleId}`)
            }}
          >
            {discountCodeRuleId}
          </DiscountRuleId>
        </StyledItem>
      </StyledList>
      <hr />
      <a href={fileUrl} target="_blank" rel="noreferrer">
        <StyledButton disabled={status !== "COMPLETED"} overrideDisabled={status === "COMPLETED"}>
          <CsvIcon /> Download generated codes
        </StyledButton>
      </a>
    </EditSidebar>
  )
}

export default ShowCodeGenerationJob
