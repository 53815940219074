import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;
  margin-top: 4rem;
  grid-template-columns: repeat(1, 1fr);

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const Title = styled.h3`
  text-align: center;
`

export const Button = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 3rem 4rem;
  cursor: pointer;
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  button {
    margin: 3rem auto 1rem;

    svg {
      width: 2rem;
      height: 2rem;
      margin: 0 1rem 0 0;
    }
  }

  svg {
    margin: 1rem 0;
    width: 5rem;
    height: 5rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin: 2rem 0 0;
      width: 6rem;
      height: 6rem;
    }
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
  }
`
