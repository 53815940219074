import React from "react"
import styled, { keyframes } from "styled-components"
import { ReactComponent as BrinkIcon } from "../../images/brink-logo-icon.svg"

const styledKeyframes = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
`

const LoaderComponent = styled.div`
  display: inline-block;
  position: relative;
  width: 4.4rem;
  height: 4.4rem;

  svg {
    animation: ${styledKeyframes} 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    fill: ${(p) => p.theme.colors.turquoise};
  }
`

type Props = {
  color?: string
}

const BrinkLoader = ({ color, ...props }: Props) => (
  <Container>
    <LoaderComponent color={color} {...props}>
      <BrinkIcon />
    </LoaderComponent>
  </Container>
)

export { BrinkLoader }
