import React, { RefObject, useEffect, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { Conditions, FrontendCondition, TagCondition } from "@lib/types/discount"
import { getCountryName } from "helpers/countries"
import { Option } from "@lib/types/common"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import QuickAdd from "components/Ui/Table/QuickAdd"
import MultiSelect from "components/Ui/Form/MultiSelect"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import { TagConditions } from "../../Ui/TagConditions/TagConditions"
import { Loader } from "components/Ui/Loader"

const Container = styled.div`
  > div {
    display: block;
  }
`

const ConditionsAdd = styled(QuickAdd)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
`

type Props = {
  condition?: FrontendCondition
  addCondition: (data: Conditions) => void
  submitRef: RefObject<HTMLButtonElement> | undefined
}

const CountryCode = ({ condition, addCondition, submitRef }: Props) => {
  const { error, data, loading } = useQuery(ALL_STORE_GROUPS)
  const [invalid, setInvalid] = useState<boolean | undefined>(undefined)
  const [storeMarkets, setStoreMarkets] = useState<string[]>(condition?.data?.identifiers ?? [])
  const [tagConditions, setTagConditions] = useState<TagCondition[]>(
    condition?.data?.itemRule?.tagConditions ?? []
  )
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(
    condition?.data?.itemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPrices, setExcludeFullPrices] = useState(
    condition?.data?.itemRule?.excludeFullPrices ?? false
  )
  const options = data?.getStoreGroups?.map(
    (storeGroup: { storeMarkets: [] }) => storeGroup.storeMarkets
  )
  const optionsFormatted = options
    ?.flat()
    .map((storeMarket: { countryCode: string }) => ({
      value: storeMarket.countryCode,
      label: getCountryName(storeMarket.countryCode)
    }))
    .reduce((unique: Option[], option: Option) => {
      if (!unique.some((obj: Option) => obj.label === option.label && obj.value === option.value)) {
        unique.push(option)
      }
      return unique
    }, [])

  const defaultValues = storeMarkets
    ? storeMarkets.map(
        (countryCode: string) =>
          ({
            value: countryCode,
            label: getCountryName(countryCode)
          }) as Option
      )
    : undefined

  const onSubmit = () => {
    if (storeMarkets.length > 0) {
      addCondition({
        identifiers: storeMarkets,
        itemRule: { tagConditions, excludeDiscountedPrices, excludeFullPrices }
      })
    } else {
      setInvalid(true)
    }
  }

  useEffect(() => {
    if (storeMarkets.length > 0) {
      setInvalid(false)
    }
  }, [storeMarkets])

  if (loading) return <Loader color="black" />

  return (
    <Container>
      {error ? (
        <ErrorMessage message="Error loading Store markets" />
      ) : (
        <ConditionsAdd>
          <MultiSelect
            options={optionsFormatted}
            setValue={setStoreMarkets}
            defaultValue={defaultValues}
            returnValueOnly
            invalid={invalid === true}
            label="Select store market(s) *"
          />
        </ConditionsAdd>
      )}
      <TagConditions
        tagConditions={tagConditions}
        setTagConditions={setTagConditions}
        excludeDiscountedPrices={excludeDiscountedPrices}
        setExcludeDiscountedPrices={setExcludeDiscountedPrices}
        excludeFullPrices={excludeFullPrices}
        setExcludeFullPrices={setExcludeFullPrices}
      />
      <button
        onClick={() => onSubmit()}
        ref={submitRef}
        type="button"
        style={{ display: "none" }}
      />
    </Container>
  )
}

export default CountryCode
