import React, { useEffect, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"
import PageHeader from "components/Ui/Page/PageHeader"
import { useDispatch, useSelector } from "react-redux"
import { selectUserGroups } from "lib/store/services/auth/selectors"
import { UserError } from "../UserError"
import SingleSelect from "../../components/Ui/Form/SingleSelect"
import { Option, Query } from "@lib/types/common"
import { Flex, Layout, TimeZoneWrapper } from "./DashboardsSales.styled"
import { isReadOnlyUser } from "helpers/user"
import TopProductsList from "../../components/DashboardSales/TopProductsList"
import TopDiscountCodesList from "../../components/DashboardSales/TopDiscountCodesList"
import { AnalyticsOrdersOverview } from "../../components/DashboardSales/AnalyticsOrdersOverview"
import {
  selectFilteredByExcludingTax,
  selectFilteredDays,
  selectProductNames,
  selectStoreGroups,
  selectStoreMarkets,
  selectTopCodesFilteredByHits,
  selectTopProductsFilteredBy,
  selectTopProductsFilteredByHits,
  selectTopProductsFilteredByType
} from "lib/store/services/analytics/selectors"
import esb from "elastic-builder"
import dayjs from "dayjs"
import SEARCH_ORDERS_TOTAL_HITS from "../../graphql/queries/order/SearchOrdersTotalHits"
import { filterByAll, filterByDiscount, filterByNonDiscount, filterByParent, filterByVariant } from "../../components/DashboardSales/AnalyticsData"
import { OrderSearchHits, ProductParentSearchHits } from "@lib/types/generated/graphql-types"
import { DashboardBarChart } from "../../components/DashboardSales/DashboardBarChart"
import { IconWrapper } from "../../components/Dashboard/OrderChart.styled"
import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg"
import { ReactComponent as CardIcon } from "../../images/icons/credit-card.svg"
import PeriodSelections from "../../components/DashboardSales/PeriodSelections"
import { getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"
import { addData, bucket, groupByPaymentMethodName, groupDiscountCodesByUppercaseKey, topDiscountCodesData } from "./DashboardSalesAggregations"
import SEARCH_PRODUCT_PARENTS from "../../graphql/queries/product/SearchProductParents"
import { setProductNames } from "lib/store/services/analytics/slice"
import Dinero from "dinero.js"

export interface dataObj {
  name: string
  value: number
}

export function DashboardsSales() {
  const initialData: bucket[] = []
  const initialCodesData: topDiscountCodesData[] = []
  const [topDiscountCodes, setDiscountCodes] = useState(initialCodesData)
  const [topProductVariantsAll, setTopProductVariantsAll] = useState(initialData)
  const [topProductVariantsCampaign, setTopProductVariantsCampaign] = useState(initialData)
  const [topProductVariantsNonDiscount, setTopProductVariantsNonDiscount] = useState(initialData)
  const [topProductParentsAll, setTopProductParentsAll] = useState(initialData)
  const topProductsFilteredBy = useSelector(selectTopProductsFilteredBy)
  const topProductsFilteredByType = useSelector(selectTopProductsFilteredByType)
  const topProductsFilteredByHits = useSelector(selectTopProductsFilteredByHits)
  const topCodesFilteredByHits = useSelector(selectTopCodesFilteredByHits)
  const [orders, setOrders] = useState<OrderSearchHits>()
  const [currency, setCurrency] = useState<Option>({
    value: "SEK",
    label: "SEK"
  })
  const [timeZone, setTimeZone] = useState<Option>({
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    label: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const availableCurrencies = ["SEK", "NOK", "DKK", "EUR", "USD"]
  const { data: storeGroupsData, loading: storeGroupsLoading } = useQuery(ALL_STORE_GROUPS, {
    fetchPolicy: "cache-first"
  })

  const userGroups = useSelector(selectUserGroups)
  const timeZones = Intl.supportedValuesOf("timeZone")
  const filteredDays = useSelector(selectFilteredDays)
  const selectedStoreGroups = useSelector(selectStoreGroups)
  const selectedStoreMarkets = useSelector(selectStoreMarkets)

  const [searchOrders, { loading: searchOrdersLoading, error }] = useLazyQuery(
    SEARCH_ORDERS_TOTAL_HITS,
    {
      onCompleted: (data) => {
        setOrders(data?.searchOrders)
      }
    }
  )

  useEffect(() => {
    const boolQuery = esb.boolQuery().must(
      esb
        .rangeQuery("date")
        .gt(
          dayjs()
            .subtract(filteredDays - 1, "day")
            .startOf("day")
            .format("YYYY-MM-DD-HH:mm")
        )
        .lt(dayjs().format("YYYY-MM-DD-HH:mm"))
        .format("yyyy-MM-dd-HH:mm")
        .timeZone(timeZone.value)
    )

    if (selectedStoreGroups?.length > 0) {
      boolQuery.must(
        esb.termsQuery(
          "storeGroupId",
          selectedStoreGroups.map((option) => option.value)
        )
      )
    }
    if (selectedStoreMarkets.length > 0) {
      boolQuery.must(
        esb.termsQuery(
          "countryCode",
          selectedStoreMarkets.map((option) => option.value)
        )
      )
    }

    const dailyRevenueQuery = esb.requestBodySearch().query(boolQuery)
    const query = dailyRevenueQuery.toJSON() as Query
    const aggs = esb.requestBodySearch()
      .agg(esb.termsAggregation("totalValuesPerCurrencyCode", "currencyCode")
        .aggs(
          [
            esb.sumAggregation("grandTotal", "totals.grandTotal"),
            esb.sumAggregation("taxTotal", "totals.taxTotal")
          ]
        ))
      .agg(esb.termsAggregation("totalValuesPerStoreGroup", "storeGroupId")
        .aggs(
          [
            esb.sumAggregation("grandTotal", "totals.grandTotal"),
            esb.sumAggregation("taxTotal", "totals.taxTotal")
          ]
        ))
      .agg(esb.termsAggregation("topDiscountCodes", "discountCodes")
        .size(25)
        .order("_count", "desc")
        .aggs(
          [
            esb.sumAggregation("subTotal", "totals.subTotal"),
            esb.sumAggregation("discountTotal", "totals.discountTotal")
          ]
        ))
      .agg(esb.nestedAggregation("topProductParents", "orderLines")
        .aggs(
          [
            esb.termsAggregation("topList", "orderLines.productParentId")
              .size(25)
              .order("totalQuantity", "desc")
              .aggs(
                [
                  esb.sumAggregation("totalQuantity", "orderLines.quantity"),
                  esb.sumAggregation("priceTotal", "orderLines.distributedTotalPriceAmount"),
                  esb.sumAggregation("discountTotal", "orderLines.distributedTotalDiscountAmount"),
                  esb.topHitsAggregation("topProductDetails")
                    .size(1)
                    .source(
                      [
                        "orderLines.productParentId",
                        "orderLines.imageUrl",
                        "orderLines.quantity",
                        "orderLines.name"
                      ]
                    )
                ]
              )
          ]
        )
      )
      .agg(esb.nestedAggregation("topProductVariantsFilterByNonDiscount", "orderLines")
        .aggs(
          [
            esb.filterAggregation("filtered_orderLines",
              esb.termsQuery("orderLines.distributedTotalDiscountAmount", 0))
              .aggs(
                [
                  esb.termsAggregation("topList", "orderLines.productVariantId")
                    .size(25)
                    .order("totalQuantity", "desc")
                    .aggs(
                      [
                        esb.sumAggregation("totalQuantity", "orderLines.quantity"),
                        esb.topHitsAggregation("topProductDetails")
                          .size(1)
                          .source(
                            [
                              "orderLines.productParentId",
                              "orderLines.productVariantId",
                              "orderLines.imageUrl",
                              "orderLines.name"
                            ]
                          )
                      ]
                    )

                ]
              )
          ]
        )
      )
      .agg(esb.nestedAggregation("topProductVariantsFilterByCampaign", "orderLines")
        .aggs(
          [
            esb.filterAggregation("filtered_orderLines",
              esb.rangeQuery("orderLines.distributedTotalDiscountAmount")
                .gt(0))
              .aggs(
                [
                  esb.termsAggregation("topList", "orderLines.productVariantId")
                    .size(25)
                    .order("totalQuantity", "desc")
                    .aggs(
                      [
                        esb.sumAggregation("totalQuantity", "orderLines.quantity"),
                        esb.sumAggregation("priceTotal", "orderLines.distributedTotalPriceAmount"),
                        esb.sumAggregation("discountTotal", "orderLines.distributedTotalDiscountAmount"),
                        esb.topHitsAggregation("topProductDetails")
                          .size(1)
                          .source(
                            [
                              "orderLines.productParentId",
                              "orderLines.productVariantId",
                              "orderLines.imageUrl",
                              "orderLines.name"
                            ]
                          )
                      ]
                    )

                ]
              )
          ]
        )
      )
      .agg(esb.nestedAggregation("topProductVariants", "orderLines")
        .aggs(
          [
            esb.termsAggregation("topList", "orderLines.productVariantId")
              .size(25)
              .order("totalQuantity", "desc")
              .aggs(
                [
                  esb.sumAggregation("totalQuantity", "orderLines.quantity"),
                  esb.sumAggregation("priceTotal", "orderLines.distributedTotalPriceAmount"),
                  esb.sumAggregation("discountTotal", "orderLines.distributedTotalDiscountAmount"),
                  esb.topHitsAggregation("topProductDetails")
                    .size(1)
                    .source(
                      [
                        "orderLines.productParentId",
                        "orderLines.productVariantId",
                        "orderLines.imageUrl",
                        "orderLines.name"
                      ]
                    )
                ]
              )
          ]
        )
      )
      .agg(esb.termsAggregation("totalByPaymentMethod", "paymentMethod"))
      .agg(
        esb.termsAggregation("totalByPaymentProvider", "paymentProviderName"))
      .toJSON() as Query

    const delayDebounceFn = setTimeout(() => {
      if (query) {
        searchOrders({
          variables: {
            from: 0,
            size: 0,
            query: JSON.stringify({
              bool: {
                must: [query.query]
              }
            }),
            trackTotalHits: true,
            aggs: JSON.stringify(aggs.aggs)
          }
        }).then((response) => {
          setOrders(response?.data?.searchOrders)
        })
      }
    })
    return () => clearTimeout(delayDebounceFn)
  }, [filteredDays, selectedStoreGroups, selectedStoreMarkets, timeZone])

  const initData: dataObj[] = []
  const [salesByCurrency, setSalesByCurrency] = useState(initData)
  const [countByCurrency, setCountByCurrency] = useState(initData)
  const [countByStoreGroup, setCountByStoreGroup] = useState(initData)
  const [countByPaymentMethod, setCountByPaymentMethod] = useState(initData)
  const [countByPaymentProvider, setCountByPaymentProvider] = useState(initData)
  const filteredByExcludingTax = useSelector(selectFilteredByExcludingTax)
  const filterByType = useSelector(selectTopProductsFilteredByType)
  const dispatch = useDispatch()
  const productNames = useSelector(selectProductNames)
  const [searchProducts, {loading: loadingProducts}] = useLazyQuery(SEARCH_PRODUCT_PARENTS, {
    onCompleted: (data) => {
      const resp = data?.searchProductParents as ProductParentSearchHits
      if (resp.hits) {
        dispatch(setProductNames(
          Object.fromEntries(
            resp.hits.map(x => [x.id, x.name])
          )
        ))
      }
    }
  })

  useEffect(() => {
      const aggregatedData = orders?.aggregations
        ? (JSON.parse(orders?.aggregations) as addData)
        : ({
          totalValuesPerCurrencyCode: { buckets: [] },
          totalValuesPerStoreGroup: { buckets: [] },
          totalByPaymentMethod: { buckets: [] },
          totalByPaymentProvider: { buckets: [] },
          topProductVariants: { topList: { buckets: [] } },
          topProductVariantsFilterByCampaign: { filtered_orderLines: { topList: { buckets: [] } } },
          topProductVariantsFilterByNonDiscount: {
            filtered_orderLines: { topList: { buckets: [] } }
          },
          topProductParents: { topList: { buckets: [] } },
          topDiscountCodes: { buckets: [] }
        } as addData)

      const filterParents = aggregatedData?.topProductParents.topList.buckets.slice(
        0,
        topProductsFilteredByHits
      )
      setTopProductParentsAll(filterParents)

      const filterVariants = aggregatedData?.topProductVariants.topList.buckets.slice(
        0,
        topProductsFilteredByHits
      )
      setTopProductVariantsAll(filterVariants)

      const filterDiscountCodes = aggregatedData?.topDiscountCodes?.buckets.slice(
        0,
        topCodesFilteredByHits
      )
      setDiscountCodes(groupDiscountCodesByUppercaseKey(filterDiscountCodes))

      const filterVariantsCampaign =
        aggregatedData?.topProductVariantsFilterByCampaign.filtered_orderLines.topList.buckets.slice(
          0,
          topProductsFilteredByHits
        )
      setTopProductVariantsCampaign(filterVariantsCampaign)

      const filterVariantsNonDiscount =
        aggregatedData?.topProductVariantsFilterByNonDiscount.filtered_orderLines.topList.buckets.slice(
          0,
          topProductsFilteredByHits
        )
      setTopProductVariantsNonDiscount(filterVariantsNonDiscount)

      setSalesByCurrency(
        aggregatedData?.totalValuesPerCurrencyCode.buckets.map((x) => {
          const grandTotal = Dinero({ amount: x.grandTotal.value })
          const taxTotal = Dinero({ amount: x.taxTotal.value })
          const excludingTax = grandTotal.subtract(taxTotal)

          return {
            name: x.key,
            value: filteredByExcludingTax ? excludingTax.getAmount() : grandTotal.getAmount()
          }
        })
      )

      setCountByCurrency(aggregatedData?.totalValuesPerCurrencyCode.buckets.map(
        (x) => {
          return {
            name: x.key,
            value: x.doc_count
          }
        })
      )

      setCountByStoreGroup(aggregatedData?.totalValuesPerStoreGroup.buckets.map(
        (x) => {
          return {
            name: storeGroupsData?.getStoreGroups.find((s: { id: string }) => s.id === x.key)?.name ?? x.key,
            value: x.doc_count
          }
        })
      )

      setCountByPaymentMethod(groupByPaymentMethodName(aggregatedData?.totalByPaymentMethod.buckets))

      setCountByPaymentProvider(aggregatedData?.totalByPaymentProvider.buckets.map(
        (x) => {
          return {
            name: getPaymentProviderDisplayName(x.key),
            value: x.doc_count
          }
        })
      )

      const boolQuery = esb.boolQuery().must(
        esb.termsQuery(
          "id",
          filterParents.map(p => p.topProductDetails?.hits.hits[0]._source.productParentId ?? "")
        )
      )
      const productsQuery = esb.requestBodySearch().query(boolQuery)
      const query = productsQuery.toJSON() as Query

      const delayDebounceFn = setTimeout(
        () => {
          if (query) {
            searchProducts({
              variables: {
                from: 0,
                size: 25,
                query: JSON.stringify(query?.query),
                trackTotalHits: true
              }
            })
          }
        }
      )
      return () => clearTimeout(delayDebounceFn)
    }, [orders, topProductsFilteredByHits, topCodesFilteredByHits, filterByType, filteredByExcludingTax]
  )

  const loading = storeGroupsLoading || searchOrdersLoading

  if (!userGroups) return <UserError />

  return (
    <>
      <PageHeader title="Brink Sales Dashboard" />
      <TimeZoneWrapper>
        <Flex>
          <IconWrapper>
            <ClockIcon />
          </IconWrapper>
        </Flex>
        <SingleSelect
          overrideDisabled={isReadOnlyUser()}
          options={timeZones.map((timeZone) => ({ value: timeZone, label: timeZone }))}
          setValue={setTimeZone}
          defaultValue={{
            value: Intl.DateTimeFormat().resolvedOptions().timeZone,
            label: Intl.DateTimeFormat().resolvedOptions().timeZone
          }}
        />
        <Flex>
          <IconWrapper>
            <CardIcon />
          </IconWrapper>
        </Flex>
        <SingleSelect
          overrideDisabled={isReadOnlyUser()}
          options={availableCurrencies.map((currency) => ({ value: currency, label: currency }))}
          setValue={setCurrency}
          defaultValue={{
            value: "SEK",
            label: "SEK"
          }}
        />
      </TimeZoneWrapper>
      <PeriodSelections
        timeZone={timeZone.value}
        storeGroups={storeGroupsData?.getStoreGroups}
      ></PeriodSelections>
      <Layout className={loading ? "blur" : ""}>
        <AnalyticsOrdersOverview
          timeZone={timeZone.value}
          currencyCode={currency.value}
          totalOrders={orders?.total ?? 0}
          data={salesByCurrency}
          filterCurrencies={availableCurrencies}
        />
      </Layout>
      <Layout className={loading ? "blur" : ""}>
        <DashboardBarChart
          title={"Sales By Store"}
          data={countByStoreGroup}
        ></DashboardBarChart>
        <DashboardBarChart
          title={"Sales By Currency"}
          data={countByCurrency}
        ></DashboardBarChart>
        <DashboardBarChart
          title={"Sales By Payments"}
          data={countByPaymentMethod}
        ></DashboardBarChart>
        <DashboardBarChart
          title={"Sales By PSP"}
          data={countByPaymentProvider}
        ></DashboardBarChart>
      </Layout>
      <Layout className={loading ? "blur" : ""}>
        <TopDiscountCodesList
          data={topDiscountCodes}
          loading={loading}
          error={error}
        ></TopDiscountCodesList>
      </Layout>
      {topProductsFilteredBy === filterByAll && topProductsFilteredByType === filterByVariant ? (
        <TopProductsList
          data={topProductVariantsAll}
          loading={loading}
          error={error}
        ></TopProductsList>
      ) : topProductsFilteredBy === filterByAll && topProductsFilteredByType === filterByParent ? (
        <TopProductsList
          productParentNames={productNames}
          data={topProductParentsAll}
          loading={loading && loadingProducts}
          error={error}
        ></TopProductsList>
      ) : topProductsFilteredBy === filterByDiscount &&
      topProductsFilteredByType === filterByVariant ? (
        <TopProductsList
          data={topProductVariantsCampaign}
          loading={loading}
          error={error}
        ></TopProductsList>
      ) : topProductsFilteredBy === filterByNonDiscount &&
      topProductsFilteredByType === filterByVariant ? (
        <TopProductsList
          data={topProductVariantsNonDiscount}
          loading={loading}
          error={error}
        ></TopProductsList>
      ) : (
        <></>
      )}
    </>
  )
}
