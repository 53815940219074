import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 3rem;
  margin: 0 auto;
  max-width: 30rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(12, 24, 41, 0.8);
  color: ${(p) => p.theme.colors.white};
  border-radius: 1rem;
  z-index: 1000;
  white-space: pre-wrap;

  svg {
    fill: ${(p) => p.theme.colors.white};
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
    cursor: pointer;
  }
`
