import React, { KeyboardEvent, useState } from "react"
import { ActionWrapper, Container, Initials, InputWrapper } from "./AddComment.styled"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import { useSelector } from "react-redux"
import { selectUserAttributes } from "lib/store/services/auth/selectors"
import { useMutation } from "@apollo/client"
import CREATE_ORDER_COMMENT from "../../../graphql/mutations/order/comment/CreateOrderComment"

type Props = {
  orderId: string
  refetch: () => void
}

const AddComment = ({ orderId, refetch }: Props) => {
  const [comment, setComment] = useState<string>("")
  const userAttributes = useSelector(selectUserAttributes)

  const [addComment, { loading }] = useMutation(CREATE_ORDER_COMMENT, {
    onCompleted: () => {
      refetch()
      setComment("")
    }
  })

  const getInitials = () => {
    const givenName = userAttributes?.given_name
    const familyName = userAttributes?.family_name
    const email = userAttributes?.email
    if (givenName && familyName) {
      return `${givenName.slice(0, 1)}${familyName.slice(0, 1)}`
    }
    if (email) {
      const name = email.split("@")
      if (name[0].includes(".")) {
        const fragments = name[0].split(".")
        return `${fragments[0].slice(0, 1)}${fragments[1].slice(0, 1)}`
      }
    }
    return "C"
  }

  const addCommentToOrder = () => {
    if (comment) {
      addComment({ variables: { orderId: orderId, comment: { text: comment } } })
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addCommentToOrder()
    }
  }

  return (
    <Container className={loading ? "blur" : ""}>
      <InputWrapper>
        <Initials>{getInitials()}</Initials>
        <input
          placeholder={"Leave a comment"}
          value={comment}
          onChange={(e) => setComment(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
      </InputWrapper>
      <ActionWrapper>
        <PrimaryButton disabled={!comment} handleClick={addCommentToOrder}>
          Post
        </PrimaryButton>
      </ActionWrapper>
    </Container>
  )
}

export default AddComment
