import styled from "styled-components"

type StyledProps = { $expanded: boolean }

export const DiscountCodeContainer = styled.div`
  position: relative;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.5rem;

  svg {
    height: 2rem;
  }
`

export const CheckoutTab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 0 1.5rem;
  cursor: pointer;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 0 2.5rem;
  }

  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`

export const ArrowWrapper = styled.div<StyledProps>`
  svg {
    transition: all 0.3s;
    height: 1.4rem;
    transform: ${(p) => (p.$expanded ? "rotate(180deg)" : "rotate(0deg)")};
  }
`

export const InputWrapper = styled.div<StyledProps>`
  background: ${(p) => p.theme.colors.background};
  max-height: ${(p) => (p.$expanded ? "15rem" : "0")};
  transition: max-height 0.3s ease-out;
  overflow: hidden;

  > div {
    display: flex;
    align-items: center;
    padding: 3rem;

    div {
      padding: 0;
    }

    button {
      min-width: 8rem;
      margin-left: 1rem;
    }
  }

  input {
    height: 4.5rem;
  }
`

export const ErrorMessage = styled.p`
  margin: -1rem 1.5rem 1.5rem;
  font-size: 1.3rem;
  color: red;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: -1.5rem 2.5rem 1.5rem;
  }
`

export const AppliedDiscountCodes = styled.ul<StyledProps>`
  transition: margin 0.3s ease-out;
  margin: ${(p) => (p.$expanded ? "1.5rem" : "0 1.5rem 1.5rem")};

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: ${(p) => (p.$expanded ? "1.5rem 2.5rem" : "0 2.5rem 1.5rem")};
  }
`

export const AppliedDiscountCode = styled.li`
  padding: 0.4rem 1rem;
  margin: 0 0 0.6rem;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  svg {
    height: 1.5rem;
  }

  &:last-of-type {
    margin: 0;
  }
`
