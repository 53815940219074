export const getPaymentProviderDisplayName = (paymentProvider: string) => {
  switch (paymentProvider) {
    case "KlarnaCheckout":
      return "Klarna Checkout"
    case "SveaCheckout":
      return "Svea Checkout"
    case "QliroOne":
      return "Qliro One"
    case "WalleyCheckout":
      return "Walley Checkout"
    case "BrinkZeroPayment":
      return "Brink zero"
    default:
      return paymentProvider
  }
}

export const getPaymentMethodDisplayName = (paymentMethod: string) => {
  switch (paymentMethod) {
    case "PAY_BY_CARD":
    case "CARD":
    case "card":
      return "Card"
    case "DIRECT_DEBIT":
      return "Direct debit"
    case "INVOICE":
    case "invoice":
      return "Invoice"
    case "BANK_TRANSFER":
      return "Bank transfer"
    case "FIXED_AMOUNT":
      return "Fixed amount"
    case "FIXED_SUM_CREDIT":
      return "Fixed sum credit"
    case "TRUSTLY_DIRECT":
      return "Trustly - Direct payment"
    case "TRUSTLY":
      return "Trustly"
    case "QLIRO_INVOICE":
      return "Qliro invoice"
    case "QLIRO_CAMPAIGN":
      return "Qliro campaign"
    case "QLIRO_PARTPAYMENT_FIXED":
      return "Qliro campaign"
    case "QLIRO_PARTPAYMENT_ACCOUNT":
      return "Partpayment fixed"
    case "DirectInvoice":
      return "Direct invoice"
    case "SWISH":
      return "Swish"
    case "PAYPAL":
      return "Paypal"
    case "VIPPS":
      return "Vipps"
    case "CREDITCARDS":
      return "Credit cards"
    case "MOBILEPAY":
      return "Mobilepay"
    case "KlarnaPayNow":
      return "Klarna - Pay now"
    case "BrinkZeroPayment":
      return "Brink zero payment"

    default:
      return paymentMethod
  }
}
