import React from "react"
import { useNavigate } from "react-router-dom"
import { Container, EmptyList, FlexColumn } from "../Order/OrderTable/OrderTable.styled"
import TableRow from "../Ui/Table/TableRow"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"
import ErrorMessage from "../Ui/Messages/ErrorMessage"
import { ReactComponent as CartIcon } from "../../images/icons/cart-shopping.svg"
import { StyledTableHeader } from "./TopProducts.styled"
import { bucket } from "../../views/Dashboards/DashboardSalesAggregations"
import { ProductImage, ProductImageContainer } from "../Products/Shared.styled"
import placeholder from "../../images/placeholder.svg"
import { useSelector } from "react-redux"
import {
  selectTopProductsFilteredBy,
  selectTopProductsFilteredByType
} from "lib/store/services/analytics/selectors"
import { filterByAll, filterByDiscount, filterByParent, filterByVariant } from "./AnalyticsData"
import Dinero from "dinero.js"

type Props = {
  productParentNames?: { [productId: string]: string }
  topProducts: bucket[]
  loading: boolean
  error: object | undefined
}

export const TopProducts = ({ topProducts, loading, error, productParentNames }: Props) => {
  const navigate = useNavigate()
  const filterByType = useSelector(selectTopProductsFilteredByType)
  const filterBy = useSelector(selectTopProductsFilteredBy)

  function getProductParentName(parentId: string | undefined) {
    if (!parentId || !productParentNames) {
      return parentId
    }
    return productParentNames[parentId] ?? parentId
  }

  function toAverageDiscountPercentage(d: bucket): string {
    // Average discount in percentage can be calculated with a fixed currency
    const price = Dinero({ amount: d.priceTotal?.value })
    const discount = Dinero({ amount: d.discountTotal?.value })

    const totalBeforeDiscount = price.add(discount)

    if (totalBeforeDiscount.isZero()) {
      return "0.00"
    }
    const discountPercentage = discount.multiply(100).divide(totalBeforeDiscount.getAmount())
    return discountPercentage.getAmount().toFixed(2)
  }

  const tableHeader = (
    <StyledTableHeader>
      <div>Product Name</div>
      <div className="hideOnSmallScreens">ParentId</div>
      {filterByType === filterByVariant ? (
        <div className="hideOnSmallScreens">VariantId</div>
      ) : (
        <></>
      )}
      <div>Total quantity</div>
      {filterBy === filterByAll || filterBy === filterByDiscount ? (
        <div className="hideOnSmallScreens">Average Product Discount</div>
      ) : (
        <></>
      )}
    </StyledTableHeader>
  )

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const getRows = (product?: bucket) =>
    product ? (
      <TableRow
        key={product.topProductDetails?.hits.hits[0]._source.productVariantId}
        handleOnClick={() => {
          navigate(`/products/${product?.topProductDetails?.hits.hits[0]._source.productParentId}`)
        }}
      >
        <ProductImageContainer>
          <ProductImage
            src={product.topProductDetails?.hits.hits[0]._source.imageUrl as string}
            onError={handleImageError}
          />
        </ProductImageContainer>
        <FlexColumn>
          <p>
            {filterByParent === filterByType
              ? getProductParentName(
                  product.topProductDetails?.hits.hits[0]._source.productParentId
                )
              : product.topProductDetails?.hits.hits[0]._source.name}
          </p>
        </FlexColumn>
        <FlexColumn className="hideOnSmallScreens">
          <p>{product.topProductDetails?.hits.hits[0]._source.productParentId}</p>
        </FlexColumn>
        {filterByType === filterByVariant ? (
          <FlexColumn className="hideOnSmallScreens">
            <p>{product.topProductDetails?.hits.hits[0]._source.productVariantId}</p>
          </FlexColumn>
        ) : (
          <></>
        )}
        <FlexColumn>
          <p>{product.totalQuantity.value}</p>
        </FlexColumn>
        {filterBy === filterByAll || filterBy === filterByDiscount ? (
          <FlexColumn className="hideOnSmallScreens">
            <p>{toAverageDiscountPercentage(product)}%</p>
          </FlexColumn>
        ) : (
          <></>
        )}
      </TableRow>
    ) : (
      <></>
    )

  if (loading && !topProducts)
    return <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getRows()} />

  return (
    <Container>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading Top Products. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {topProducts && topProducts.length > 0 ? (
        <>
          {tableHeader}
          <div>{topProducts.map((order) => getRows(order))}</div>
        </>
      ) : (
        <EmptyList>
          <CartIcon />
          <p> No orders found</p>
        </EmptyList>
      )}
    </Container>
  )
}
