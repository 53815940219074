import React, { useState } from "react"
import * as graphql from "lib/types/generated/graphql-types"
import FailedModification from "./FailedModification"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import StatusPopup from "./Cart/StatusPopup"
import PendingModification from "./PendingModification"
import AddOrUpdateOrderLineSideBar from "./EditOrderLine/AddOrUpdateOrderLineSideBar"
import ModificationAction from "./Cart/ModificationAction"
import { Modification } from "../Helpers/Modification"

type Props = {
  order: graphql.Order
  refetch: () => void
  loading: boolean
  orderHistory: graphql.OrderHistory
}

const ModifyOrder = ({ order, refetch, loading, orderHistory }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedModification, setStartedModification] = useState("")
  const [productVariant, setProductVariant] = useState<graphql.ProductVariant>()
  const [editOrderLine, setEditOrderLine] = useState<graphql.OrderLine | undefined>()
  const [paymentAction, setPaymentAction] =
    useState<graphql.InputActionPaymentOrderModificationOrderLines>({
      actionType: graphql.InputActionType.Auto
    })
  const modifications = new Modification(order.Modifications)

  if (modifications.pending() && !showStatusPopup)
    return (
      <PendingModification
        pendingModification={modifications.getPendingModification()}
        orderRefetch={refetch}
        orderLoading={loading}
      />
    )

  return (
    <>
      {showStatusPopup && startedModification && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          modificationId={startedModification}
          refetchOrder={refetch}
        />
      )}
      {modifications.failed() ? (
        modifications
          .failedModifications()
          .map((modification: graphql.OrderModification) => (
            <FailedModification
              key={modification.id}
              failedModification={modification}
              currencyCode={order.currencyCode}
              setShowStatusPopup={setShowStatusPopup}
              setStartedModification={setStartedModification}
              orderHistory={orderHistory.history}
              refetch={refetch}
            />
          ))
      ) : !showConfirmation && order ? (
        <Container>
          <AddOrUpdateOrderLineSideBar
            productVariant={productVariant}
            setProductVariant={setProductVariant}
            storeGroupId={order?.storeGroupId}
            countryCode={order.countryCode}
            currencyCode={order.currencyCode}
            order={order}
            refetch={refetch}
            editOrderLine={editOrderLine}
            setEditOrderLine={setEditOrderLine}
          />
          <div>
            <OrderSummary
              order={order}
              refetch={refetch}
              orderLoading={loading}
              editOrderLine={editOrderLine}
              setEditOrderLine={setEditOrderLine}
              setProductVariant={setProductVariant}
            />
          </div>
          <div>
            <Cart
              order={order}
              refetch={refetch}
              setShowConfirmation={setShowConfirmation}
              orderLoading={loading}
            />
          </div>
        </Container>
      ) : (
        <Container>
          <div>
            <Cart
              order={order}
              refetch={refetch}
              setShowConfirmation={setShowConfirmation}
              summaryMode={true}
              orderLoading={loading}
            />
          </div>
          <div>
            {modifications
              .onGoingModifications()
              ?.map((modification) => (
                <ModificationAction
                  key={modification.id}
                  modification={modification}
                  paymentAction={paymentAction}
                  setPaymentAction={setPaymentAction}
                  orderId={order.id}
                  setShowStatusPopup={setShowStatusPopup}
                  setStartedModification={setStartedModification}
                  setShowConfirmation={setShowConfirmation}
                />
              ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default ModifyOrder
