import { Common } from "@lib/types"

export const NUMBER_OF_HITS_OPTIONS = ["5", "10", "25"]

export const filterByVariant = "variant"
export const filterByAll = "all"
export const filterByNonDiscount = "non-discount"
export const filterByDiscount = "discount"
export const filterByParent = "parent"

export const DEFAULT_TOP_PRODUCTS_FILTER: Common.TopProductsFilter = {
  numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
  filterBy: filterByVariant
}
