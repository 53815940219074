import styled from "styled-components"
import MultiSelect from "../../../Ui/Form/MultiSelect"
import SingleSelect from "../../../Ui/Form/SingleSelect"
import TableRowSimple from "../../../Ui/Table/TableRowSimple"

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    flex-direction: row;
  }

  span {
    margin: 1rem 0;
    font-weight: 700;

    ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
      margin: 0 2rem;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  background: ${p => p.theme.colors.white};
  padding: 2rem 4rem;
  border-radius: 0.6rem;
  margin: 2rem 0;
`

export const SelectWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: flex-start;

  button {
    margin-bottom: 0.3rem;
  }

  margin-bottom: 2rem;
`

export const StyledMultiSelect = styled(MultiSelect)`
  div {
    div {
      div {
        input {
          z-index: 10000 !important;
        }
      }
    }
  }
`

export const SelectLabel = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 1.2rem;
    margin-right: 0.8rem;
  }
`

export const CountryInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  padding: 0.2rem 1rem;
  margin: 0 1rem 1rem 0;
  border-radius: 0.6rem;

  img {
    width: 1.2rem;
    margin-right: 1rem;
  }
`

export const CountrySelect = styled(SingleSelect)`
  input {
    z-index: 1000 !important;
  }
`

export const StoreMarketRow = styled(TableRowSimple)`
  background: rgba(255, 255, 255, 0.5);
  padding: 4.2rem 2rem 1rem;
  width: 100%;
  position: relative;
`

export const StoreGroupLabel = styled.div`
  margin-bottom: 0.5rem;
  position: absolute;
  top: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    height: 1.3rem;
    margin-right: 1rem;
  }
`

export const Value = styled.div`

`

export const Values = styled.div`
  display: flex;
  flex-wrap: wrap;
`


export const Wrapper = styled.div`
  width: 100%;
`

export const StoreMarketsTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 30rem;

  p {
    text-align: center;
  }
`

export const Description = styled.span`
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.greyDarker};
  margin-bottom: 1.5rem;
`
