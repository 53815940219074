import React from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import esb from "elastic-builder"
import { StoreGroupSearchHit } from "@lib/types/generated/graphql-types"

import SEARCH_STORE_GROUPS from "graphql/queries/store/SearchStoreGroups"

import { Loader } from "components/Ui/Loader"

const DataItem = styled.div`
  padding: 0 1rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
`

const DataItemCondition = styled(DataItem)`
  background: rgb(255, 205, 121);
`

type Props = {
  identifiers: string[] | undefined
}
export const StoreGroupDataItems = ({ identifiers }: Props) => {
  const searchStoreGroupsQuery = esb.termsQuery("id", identifiers)

  const { data, loading } = useQuery(SEARCH_STORE_GROUPS, {
    variables: {
      from: 0,
      size: 100,
      query: JSON.stringify(searchStoreGroupsQuery)
    }
  })

  const storeGroups = data?.searchStoreGroups?.hits
  if (loading) return <Loader color={"black"} />
  return (
    <>
      {storeGroups?.map((storeGroup: StoreGroupSearchHit) => (
        <DataItemCondition key={storeGroup.id}>{storeGroup.name}</DataItemCondition>
      ))}
    </>
  )
}
