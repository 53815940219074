import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;

  @media (max-width: 48rem) {
    flex-direction: column;
  }
`

export const Card = styled.div`
  background-color: ${(p) => p.theme.colors.turquoiseDark};
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 17rem;
  min-height: 15rem;
  cursor: pointer;
  transition: transform 0.2s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;


  &:hover {
    transform: scale(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`

export const Title = styled.h2`
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 0 2rem 0;
  text-align: center;
`

export const Value = styled.div`
  margin-top: 2rem;
  color: white;
  font-size: 5.5rem;
  font-weight: 900;
  letter-spacing: 0.05em;

  @media (max-width: 48rem) {
    font-size: 2.5rem;
  }

  @media (max-width: 75rem) {
    font-size: 3.5rem;
  }
`

export const Currency = styled.span`
  font-size: 1.5rem;
  margin-left: 0.5rem;
  opacity: 0.9;
`
