import React, { useState } from "react"
import { Flex, Hit, HitBlur, Id, Name, Stock } from "./AddOrUpdateProductVariant.styled"
import { v4 as uuidv4 } from "uuid"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { useQuery } from "@apollo/client"
import GET_PRODUCT_VARIANT from "../../../../../graphql/queries/product/GetProductVariant"
import { ProductVariant } from "@lib/types/generated/graphql-types"
import Popup from "../../../../Ui/Popup"
import { handleImageError } from "helpers/image"
import { InfoWrapper } from "./ProductVariantSearchHitRow.styled"

type Props = {
  variantId: string
  addProductVariant: (productVariant: ProductVariant) => void
}

const ProductVariantSearchHitRow = ({ variantId, addProductVariant }: Props) => {
  const [showPopup, setShowPopup] = useState(false)
  const { data, loading } = useQuery(GET_PRODUCT_VARIANT, {
    variables: { id: variantId },
    fetchPolicy: "cache-first"
  })

  const productVariant = data?.Product?.variant

  const handleAddVariant = () => {
    if (productVariant?.Stock?.stockQuantity > 0) {
      addProductVariant(productVariant)
      return
    }
    setShowPopup(true)
  }

  if (loading)
    return (
      <HitBlur>
        <Flex>
          <Name>Product name</Name>
          <Id>product id</Id>
          <Stock>Stock: 1</Stock>
        </Flex>
        <PrimaryButton disabled={true}>Add</PrimaryButton>
      </HitBlur>
    )
  if (!productVariant) return null
  return (
    <Hit key={uuidv4()}>
      {showPopup && (
        <Popup
          title={"Add product variant"}
          subtitle={"Add product variant without stock?"}
          handleCloseClick={() => setShowPopup(false)}
          buttonText={"Add anyway"}
          handleOkClick={() => addProductVariant(productVariant)}
        >
          <InfoWrapper>
            <img
              src={productVariant?.imageUrl ?? ""}
              alt={productVariant?.name}
              onError={handleImageError}
            />
            <div>
              <b>{productVariant.name}</b>
              <span>Stock: {productVariant.Stock.stockQuantity}</span>
            </div>
          </InfoWrapper>
        </Popup>
      )}
      <Flex>
        <Name>{productVariant.name}</Name>
        <Id>{productVariant.id}</Id>
        <Stock>Stock: {productVariant?.Stock?.stockQuantity}</Stock>
      </Flex>
      <PrimaryButton handleClick={handleAddVariant}>Add</PrimaryButton>
    </Hit>
  )
}

export default ProductVariantSearchHitRow
