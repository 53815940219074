import gql from "graphql-tag"

const UPDATE_ORDER_COMMENT = gql`
  mutation updateOrderComment($orderId: ID!, $commentId: ID! $comment: InputComment!) {
    Order {
      updateOrderComment(orderId: $orderId, commentId: $commentId, comment: $comment) {
        id
      }
    }
  }
`

export default UPDATE_ORDER_COMMENT
