import React from "react"
import styled from "styled-components"
import { OrderPaymentAvarda } from "@lib/types/generated/graphql-types"
import { getPaymentMethodDisplayName, getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"

import Status from "components/Ui/Status"
import { List, Item, Value, Label } from "components/Ui/List/List"

import { ReactComponent as AvardaLogo } from "images/providers/avarda-logo.svg"

const Logo = styled(AvardaLogo)`
  position: absolute;
  height: 2.5rem;
  right: 3rem;
`

type Props = {
  orderPayment: OrderPaymentAvarda
}

const Avarda = ({ orderPayment }: Props) => {
  if (!orderPayment) return null

  return (
    <List>
      <Logo />
      <Item>
        <Label>Status:</Label>
        <Value>
          <Status status={orderPayment.status} />
        </Value>
      </Item>
      <Item>
        <Label>Payment provider:</Label>
        <Value>{getPaymentProviderDisplayName(orderPayment.providerName)}</Value>
      </Item>
      <Item>
        <Label>Payment method:</Label>
        <Value capitalize>{getPaymentMethodDisplayName(orderPayment.method)}</Value>
      </Item>
      <Item>
        <Label>Payment reference:</Label>
        <Value>{orderPayment.reference}</Value>
      </Item>
      <Item>
        <Label>Purchase ID:</Label>
        <Value>{orderPayment.purchaseId}</Value>
      </Item>
      <Item>
        <Label>Provider ID:</Label>
        <Value>{orderPayment.providerId}</Value>
      </Item>
    </List>
  )
}

export default Avarda
