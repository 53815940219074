import gql from "graphql-tag"

const ADD_CUSTOM_STATE_TO_ORDER = gql`
  mutation addCustomState($stateId: ID!, $orderId: ID!) {
    Order {
      addCustomState(stateId: $stateId, orderId: $orderId)
    }
  }
`

export default ADD_CUSTOM_STATE_TO_ORDER
