import React from "react"
import { Order, OrderGiftCardProductItemRetain24 } from "lib/types/generated/graphql-types"
import { DeliveryButtonWrapper, Id, Name, Row } from "../Shared.styled"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import CREATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/CreateDelivery"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { ReactComponent as DeliverIcon } from "../../../../../images/icons/truck-ramp-box.svg"
import { handleErrorMessages } from "helpers/errors"
import { ReactComponent as GiftCardIcon } from "../../../../../images/icons/gift-card.svg"
import Status from "../../../../Ui/Status"
import { Delivery } from "../../Helpers/Delivery"
import { getDeliveryInput } from "../helpers"

type Props = {
  giftCardProduct: OrderGiftCardProductItemRetain24
  order: Order
  currencyCode: string
  refetch: () => void
}

export const GiftCardProductRow = ({ giftCardProduct, order, currencyCode, refetch }: Props) => {
  const deliveries = new Delivery(order.Deliveries)

  const giftCardProductDelivered =
    deliveries
      .completedDeliveries()
      .filter((delivery) =>
        delivery.giftCardProducts.find((gc) => gc.giftCardProductId === giftCardProduct.id)
      ).length > 0

  const [createDelivery, { loading: createLoading }] = useMutation(CREATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [updateDelivery, { loading: updateLoading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const giftCardExistInDelivery = (id: string) => {
    return deliveries
      .getOngoingDelivery()
      ?.giftCardProducts?.find((gc) => gc.giftCardProductId === id)
  }

  const createOrUpdateOrderDelivery = () => {
    if (!deliveries.onGoing()) {
      return createDelivery({
        variables: {
          orderId: order.id,
          input: {
            giftCardProducts: {
              giftCardProductId: giftCardProduct.id
            }
          }
        }
      })
    }
    return updateDelivery({
      variables: {
        deliveryId: deliveries.getOngoingDelivery().id,
        input: {
          ...getDeliveryInput(deliveries.getOngoingDelivery()),
          giftCardProducts: [
            ...deliveries.getOngoingDelivery()?.giftCardProducts?.map(({ giftCardProductId }) => ({
              giftCardProductId
            })),
            { giftCardProductId: giftCardProduct.id }
          ]
        }
      }
    })
  }

  return (
    <Row key={giftCardProduct.id}>
      <GiftCardIcon />
      <div>
        <Name>{giftCardProduct?.name}</Name>
        <Money
          amount={giftCardProduct?.amount}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>
        <Id>
          <b>Gift card ID:</b> <span>{giftCardProduct.id}</span>
        </Id>
      </div>
      <DeliveryButtonWrapper>
        {giftCardProductDelivered ? (
          <Status status={"Delivered"} />
        ) : (
          <SecondaryButton
            handleClick={createOrUpdateOrderDelivery}
            disabled={!!giftCardExistInDelivery(giftCardProduct.id)}
            loading={createLoading || updateLoading}
          >
            <DeliverIcon />
            Deliver
          </SecondaryButton>
        )}
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={giftCardProduct.amount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </DeliveryButtonWrapper>
    </Row>
  )
}
