import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components"
import { ProductVariant } from "@lib/types/generated/graphql-types"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"

import {
  Container,
  ProductImageContainer,
  ProductImage,
  Name,
  Id,
  EmptyList
} from "./Shared.styled"

import { StyledStatusContainer } from "./ProductVariantTable.styled"

import TableRowSimple from "components/Ui/Table/TableRowSimple"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"
import ErrorMessage from "../Ui/Messages/ErrorMessage"
import Status from "components/Ui/Status"

import { ReactComponent as ProductIcon } from "images/icons/bags-shopping.svg"
import placeholder from "images/placeholder.svg"
import { handleImageError } from "helpers/image"

const Info = styled.p`
  font-size: 1.2rem;
  margin: 0;
`

type Props = {
  productData: ProductVariant[]
  loading: boolean
  error: object | undefined
  setShowVariant: Dispatch<SetStateAction<ProductVariant | undefined>>
}

export const ProductVariantTable = ({ productData, loading, error, setShowVariant }: Props) => {
  const dispatch = useDispatch()

  const handleRowClick = (variant: ProductVariant) => {
    setShowVariant(variant)
    dispatch(showEditSidebar())
  }

  const getRows = (productVariant?: ProductVariant) =>
    productVariant ? (
      <TableRowSimple
        key={productVariant.id}
        handleOnClick={() => {
          handleRowClick(productVariant)
        }}
      >
        <ProductImageContainer>
          <ProductImage src={productVariant?.imageUrl as string} onError={handleImageError} />
        </ProductImageContainer>
        <div>
          <Name>{productVariant.name}</Name>
          <Id>{productVariant.id}</Id>
          <Info>Stock: {productVariant?.Stock?.stockQuantity}</Info>
        </div>
        <StyledStatusContainer>
          {productVariant.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
        </StyledStatusContainer>
      </TableRowSimple>
    ) : (
      <TableRowSimple>
        <ProductImageContainer>
          <ProductImage src={placeholder} />
        </ProductImageContainer>
        <div>
          <Name>Product name</Name>
          <Id>Product id</Id>
          <Info>Stock: 1</Info>
        </div>
        <StyledStatusContainer>
          <Status status="Default" />
        </StyledStatusContainer>
      </TableRowSimple>
    )

  if (loading)
    return (
      <>
        <TableBlurLoading numberOfRows={4} rows={getRows()} />
      </>
    )

  return (
    <Container>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading product variants. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {productData ? (
        <>
          <div>
            {[...productData]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((productVariant) => getRows(productVariant))}
          </div>
        </>
      ) : (
        <EmptyList>
          <ProductIcon />
          <p> No variants found</p>
        </EmptyList>
      )}
    </Container>
  )
}
