import React, { useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import ALL_SHIPPING_PROVIDERS from "graphql/queries/config/AllShippingProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { NShiftConfigTable } from "components/Config/NShift/NShiftConfigTable"
import { CreateNShiftConfig } from "./CreateNShiftConfig"
import { EditNShiftConfig } from "./EditNShiftConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as NShiftLogo } from "images/providers/nshift-logo.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageLogo = styled(NShiftLogo)`
  height: 6rem;
`

export const NShiftConfiguration = () => {
  const [nShiftConfigId, setNShiftConfigId] = useState<string>("")
  const { loading, data, refetch, error } = useQuery(ALL_SHIPPING_PROVIDERS)
  const shippingProviders = data?.getShippingProviders
  const dispatch = useAppDispatch()

  return (
    <Container>
      <PageHeader
        title={<PageLogo />}
        description={<>Handle your nShift configurations</>}
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!nShiftConfigId && <CreateNShiftConfig refetch={refetch} />}
      {nShiftConfigId && (
        <EditNShiftConfig
          nShiftConfigId={nShiftConfigId}
          refetch={refetch}
          setNShiftConfigId={setNShiftConfigId}
        />
      )}
      <NShiftConfigTable
        providers={shippingProviders}
        error={error}
        setNShiftConfigId={setNShiftConfigId}
        loading={loading}
      />
    </Container>
  )
}
