import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { User } from "@lib/types/generated/graphql-types"
import dayjs from "dayjs"

import { Container, Date, Email, Name, StyledTableRow, Username, Wrapper } from "./UserTable.styled"

import { BrinkLoader } from "components/Ui/BrinkLoader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import Status from "components/Ui/Status"

import { ReactComponent as UserIcon } from "images/icons/user-large.svg"
import TableHeader from "../Ui/Table/TableHeader"

type Props = {
  users: User[]
  loading?: boolean
  error: object | undefined
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

export const UserTable = ({ users, loading = false, error, setEditId }: Props) => {
  const dispatch = useAppDispatch()

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Users. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}

        {loading && <BrinkLoader />}
        <TableHeader iconHeader={true}>
          <Username>User</Username>
          <div className="hideOnSmallScreens">Status</div>
          <div className="hideOnMediumScreens">Confirmation status</div>
          <Date className="hideOnSmallScreens">Created</Date>
          <Date className="hideOnSmallScreens">Updated</Date>
        </TableHeader>
        {[...users].map((user) => (
          <StyledTableRow
            key={user.username}
            handleOnClick={() => {
              setEditId(user.username)
              dispatch(showEditSidebar())
            }}
          >
            <UserIcon />
            <Username>
              <div>
                <Name>
                  {user.givenName} {user.familyName}
                </Name>
                <Email>{user.username}</Email>
              </div>
            </Username>
            <div className="hideOnSmallScreens">
              <Status status={user.enabled ? "ENABLED" : "DISABLED"} />
            </div>
            <div className="hideOnMediumScreens">
              <Status status={user.status === "CONFIRMED" ? "CONFIRMED" : "NOT CONFIRMED"} />
            </div>
            <Date className="hideOnSmallScreens">{dayjs(user.created).format("YYYY-MM-DD")}</Date>
            <Date className="hideOnSmallScreens">{dayjs(user.updated).format("YYYY-MM-DD")}</Date>
          </StyledTableRow>
        ))}
      </Container>
    </Wrapper>
  )
}
