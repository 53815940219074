import gql from "graphql-tag"

const CREATE_OR_UPDATE_REASON_CODE = gql`
  mutation createCustomReasonCode($code: ID!, $input: InputCustomReasonCode!) {
    Order {
      createCustomReasonCode(code: $code, input: $input) {
        code
      }
    }
  }
`

export default CREATE_OR_UPDATE_REASON_CODE
