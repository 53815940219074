import gql from "graphql-tag"

const GET_REASON_CODE = gql`
  query getReasonCode($code: ID!) {
    Order {
      customReasonCode(code: $code ) {
        label
        code
        created
        updated
      }
    }
  }
`

export default GET_REASON_CODE
