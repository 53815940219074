import { OrderOperations } from "./OrderOperations"
import { OrderRelease } from "@lib/types/generated/graphql-types"

export class Release extends OrderOperations {
  constructor(release: OrderRelease[]) {
    super(release)
  }

  public getPendingRelease(): OrderRelease {
    const pendingList = this.pendingList() as OrderRelease[]
    return pendingList[0]
  }

  public failedReleases(): OrderRelease[] {
    return this.failedList() as OrderRelease[]
  }
}
