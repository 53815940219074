import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components"
import { Common, Discount } from "@lib/types"
import { useQuery } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"

import ALL_TAG_KEYS from "graphql/queries/tags/AllTagKeys"

import SingleSelect from "components/Ui/Form/SingleSelect"
import Options from "./Options"
import { Loader } from "components/Ui/Loader"
import CheckboxSimple from "components/Ui/Form/CheckboxSimple"
import { EditPopup } from "components/Ui/EditPopup/EditPopup"
import { Tooltip } from "components/Ui/Tooltip"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as RemoveIcon } from "images/icons/xmark.svg"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { ReactComponent as TagIcon } from "images/icons/tag.svg"
import { isSuperUser } from "helpers/user"

const Container = styled.div``

const Conditions = styled.div`
  margin-bottom: 3rem;

  p {
    text-align: center;
  }
`

const TagRow = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.6rem;
  margin-bottom: 0.3rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }
`

const TagHeader = styled.div`
  padding: 0.9rem 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`

const TagKey = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  ${(p) => p.theme.bold};

  svg {
    margin-right: 0.6rem;
  }

`

const TagValues = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 2rem 2rem;
  background: rgba(0, 0, 0, 0.02);
`

const TagValue = styled.div`
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin: 1rem 0.8rem 0 0;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  ${(p) => p.theme.bold}
  display: inline-flex;
  align-items: center;
`

const Label = styled.span`
  padding: 0 1rem;
`

const Actions = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 1.2rem;
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.6);
    transition: 0.2s;
    outline: none;

    &:hover {
      fill: rgba(0, 0, 0, 1);
    }
  }
`

const RemoveTagValue = styled.span`
  border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.greyLighter};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 0.7rem;
  cursor: pointer;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: ${(p) => p.theme.colors.black};
  }

  &:hover {
    background: ${(p) => p.theme.colors.greyLight};
  }
`

const TagSelect = styled(SingleSelect)`
  margin: 1rem 0;
`

const Tags = styled.div`
  margin-bottom: 5rem;
`

const AddTagCondition = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 1.4rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  left: 50%;
  margin-left: -1.4rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: 0.2s;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${(p) => p.theme.colors.black};
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    &:hover {
      background: ${(p) => p.theme.colors.turquoiseDark};
      border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};

      svg {
        fill: ${(p) => p.theme.colors.white};
      }
    }
  }
`

const EmptyTags = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.6rem;
  padding: 0.5rem 0;
  position: relative;
  //margin-bottom: 1rem;

  p {
    ${(p) => p.theme.bold};
  }
`

const Operator = styled(TagRow)`
  display: flex;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.3rem;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0 0.7rem;

  svg {
    margin-left: 1rem;
    height: 1.3rem;
  }
`

const OperatorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

type Props = {
  tagConditions: Discount.TagCondition[]
  setTagConditions: Dispatch<SetStateAction<Discount.TagCondition[]>>
  excludeDiscountedPrices: boolean
  setExcludeDiscountedPrices: Dispatch<SetStateAction<boolean>>
  excludeFullPrices: boolean
  setExcludeFullPrices: Dispatch<SetStateAction<boolean>>
  label?: string
  description?: string
}

type EditObject = {
  mode: string
  key: string
}

export const TagConditions = ({
  tagConditions,
  setTagConditions,
  label,
  description,
  excludeDiscountedPrices,
  setExcludeDiscountedPrices,
  excludeFullPrices,
  setExcludeFullPrices,
  ...props
}: Props) => {
  const { loading, data } = useQuery(ALL_TAG_KEYS)
  const [addNewTag, setAddNewTag] = useState<string>("")
  const [editTagRule, setEditTagRule] = useState<EditObject | undefined>()
  const [tagValues, setTagValues] = useState<Common.Option[]>([])
  const [addTagKey, setAddTagKey] = useState<Common.Option | undefined>()

  if (loading) return <Loader color="black" />

  const clearForm = () => {
    setTagValues([])
    setAddNewTag("")
    setEditTagRule(undefined)
    setAddTagKey(undefined)
  }
  const addTagCondition = (mode: string) => {
    if (addTagKey?.value && tagValues) {
      const listClone = Array.from(tagConditions)
      const newTagCondition = {
        key: addTagKey.value,
        mode: mode,
        values: tagValues.map((value) => value.value)
      }
      listClone.push(newTagCondition)
      setTagConditions(listClone)
      clearForm()
    }
  }

  const removeTagCondition = (mode: string, key: string) => {
    const listClone = Array.from(tagConditions)
    const currentIndex = listClone.findIndex((tag) => tag.mode === mode && tag.key === key)
    listClone.splice(currentIndex, 1)
    setTagConditions(listClone)
  }

  const addTagValue = (mode: string, key: string) => {
    const listClone = Array.from(tagConditions)
    const current = listClone.find((tag) => tag.mode === mode && tag.key === key)
    const currentIndex = listClone.findIndex((tag) => tag.mode === mode && tag.key === key)
    if (!current) return
    const values = current.values.concat(tagValues.map((t) => t.value))
    listClone[currentIndex] = { mode, key, values }
    setTagConditions(listClone)
    clearForm()
  }

  const removeTagValue = (mode: string, key: string, value: string) => {
    const listClone = Array.from(tagConditions)
    const current = listClone.find((tag) => tag.mode === mode && tag.key === key)
    const currentIndex = listClone.findIndex((tag) => tag.mode === mode && tag.key === key)
    if (!current) return
    const values = current.values.filter((v) => v !== value)
    if (values.length === 0) {
      removeTagCondition("INCLUDE", key)
      return
    }
    listClone[currentIndex] = { mode, key, values }
    setTagConditions(listClone)
  }

  const includeTags = tagConditions?.filter((tagCondition) => tagCondition.mode === "INCLUDE")
  const excludeTags = tagConditions?.filter((tagCondition) => tagCondition.mode === "EXCLUDE")

  const includeOptions = data?.tagKeys?.keys
    ?.filter((key: string) => !includeTags.find((k) => k.key === key))
    .map((key: string) => ({
      value: key,
      label: key
    }))

  const excludeOptions = data?.tagKeys?.keys
    ?.filter((key: string) => !excludeTags.find((k) => k.key === key))
    .map((key: string) => ({
      value: key,
      label: key
    }))

  return (
    <Container {...props}>
      <h3>{label ?? "Tag rules"}</h3>
      {description && <p>{description}</p>}
      <CheckboxSimple
        name="excludeDiscountedPrices"
        label="Exclude discounted prices"
        description="Should items with discounted prices be excluded?"
        value={excludeDiscountedPrices}
        setValue={(e: boolean) => {
          setExcludeDiscountedPrices(e)
          if (excludeFullPrices) {
            setExcludeFullPrices(false)
          }
        }}
        disabled={excludeFullPrices}
      />
      <CheckboxSimple
        name="excludeFullPrices"
        label="Exclude full prices"
        description="Should items with full prices be excluded?"
        value={excludeFullPrices}
        setValue={(e: boolean) => {
          setExcludeFullPrices(e)
          if (excludeDiscountedPrices) {
            setExcludeDiscountedPrices(false)
          }
        }}
        disabled={excludeDiscountedPrices}
      />

      <Conditions>
        <Tags>
          <h3>Include tags:</h3>
          {includeTags.length > 0 ? (
            <>
              {includeTags.map((tagCondition, index) => {
                return (
                  <>
                    <TagRow key={uuidv4()}>
                      <TagHeader>
                        <TagKey>
                          <TagIcon /> <span>{tagCondition.key}</span>
                        </TagKey>
                        {isSuperUser() && (
                          <Actions>
                            <Tooltip id="add-tag-value" />
                            <PlusIcon
                              data-tooltip-id="add-tag-value"
                              data-tooltip-content="Add tag values"
                              onClick={() =>
                                setEditTagRule({ mode: "INCLUDE", key: tagCondition.key })
                              }
                            />
                            <Tooltip id="remove-tag-condition" />
                            <TrashIcon
                              data-tooltip-id="remove-tag-condition"
                              data-tooltip-content="Remove tag"
                              onClick={() => removeTagCondition("INCLUDE", tagCondition.key)}
                            />
                          </Actions>
                        )}
                      </TagHeader>

                      {editTagRule &&
                        editTagRule.mode === "INCLUDE" &&
                        editTagRule.key === tagCondition.key && (
                          <EditPopup
                            title={`Add tags to '${
                              tagCondition.key
                            }'`}
                            buttonText="Add tag values"
                            handleOkClick={() => addTagValue("INCLUDE", tagCondition.key)}
                            handleCloseClick={() => clearForm()}
                            disableButton={!tagValues.length}
                          >
                            <Options
                              tagKey={tagCondition.key}
                              tagValues={tagValues}
                              setTagValues={setTagValues}
                              tagConditions={tagConditions}
                            />
                          </EditPopup>
                        )}
                      <TagValues key={tagCondition.key}>
                        {tagCondition.values?.map((value) => {
                          return (
                            <TagValue key={uuidv4()}>
                              <Label>{value}</Label>
                              <Tooltip id="remove-tag-value" />
                              {isSuperUser() && (
                                <RemoveTagValue
                                  data-tooltip-id="remove-tag-value"
                                  data-tooltip-content="Remove tag value"
                                  onClick={() => removeTagValue("INCLUDE", tagCondition.key, value)}
                                >
                                  <RemoveIcon />
                                </RemoveTagValue>
                              )}
                            </TagValue>
                          )
                        })}
                      </TagValues>
                    </TagRow>
                    {index + 1 !== includeTags?.length && (
                      <OperatorWrapper>
                        <Operator>And</Operator>
                      </OperatorWrapper>
                    )}
                  </>
                )
              })}
            </>
          ) : (
            <EmptyTags>
              <p>No tags added</p>
            </EmptyTags>
          )}
          {isSuperUser() && (
            <>
              <Tooltip id="add-tag-key" />
              <AddTagCondition
                data-tooltip-id="add-tag-key"
                data-tooltip-content="Add new tag"
                onClick={() => setAddNewTag("INCLUDE")}
              >
                <PlusIcon />
              </AddTagCondition>
            </>
          )}

          {addNewTag === "INCLUDE" && (
            <EditPopup
              title="Add new tag to include"
              buttonText="Add tag"
              disableButton={!addTagKey || !tagValues.length}
              handleOkClick={() => addTagCondition("INCLUDE")}
              handleCloseClick={() => clearForm()}
            >
              <TagSelect options={includeOptions} setValue={setAddTagKey} />
              {addTagKey && (
                <Options
                  tagKey={addTagKey.value}
                  tagValues={tagValues}
                  setTagValues={setTagValues}
                  tagConditions={tagConditions}
                />
              )}
            </EditPopup>
          )}
        </Tags>

        <Tags>
          <h3>Exclude tags:</h3>
          {excludeTags.length > 0 ? (
            <>
              {excludeTags.map((tagCondition, index) => {
                return (
                  <>
                    <TagRow key={uuidv4()}>
                      <TagHeader>
                        <TagKey>
                          <TagIcon /> <span>{tagCondition.key}</span>
                        </TagKey>
                        {isSuperUser() && (
                          <Actions>
                            <Tooltip id="add-tag-value" />
                            <PlusIcon
                              data-tooltip-id="add-tag-value"
                              data-tooltip-content="Add tag values"
                              onClick={() =>
                                setEditTagRule({ mode: "EXCLUDE", key: tagCondition.key })
                              }
                            />
                            <Tooltip id="remove-tag-condition" />
                            <TrashIcon
                              data-tooltip-id="remove-tag-condition"
                              data-tooltip-content="Remove tag"
                              onClick={() => removeTagCondition("EXCLUDE", tagCondition.key)}
                            />
                          </Actions>
                        )}
                      </TagHeader>

                      {editTagRule &&
                        editTagRule.mode === "EXCLUDE" &&
                        editTagRule.key === tagCondition.key && (
                          <EditPopup
                            title={`Add tags to '${
                              tagCondition.key
                            }'`}
                            buttonText="Add tag values"
                            handleOkClick={() => addTagValue("EXCLUDE", tagCondition.key)}
                            handleCloseClick={() => clearForm()}
                            disableButton={!tagValues.length}
                          >
                            <Options
                              tagKey={tagCondition.key}
                              tagValues={tagValues}
                              setTagValues={setTagValues}
                              tagConditions={tagConditions}
                            />
                          </EditPopup>
                        )}
                      <TagValues key={tagCondition.key}>
                        {tagCondition.values?.map((value) => {
                          return (
                            <TagValue key={uuidv4()}>
                              <Label>{value}</Label>
                              <Tooltip id="remove-tag-value" />
                              {isSuperUser() && (
                                <RemoveTagValue
                                  data-tooltip-id="remove-tag-value"
                                  data-tooltip-content="Remove tag value"
                                  onClick={() => removeTagValue("EXCLUDE", tagCondition.key, value)}
                                >
                                  <RemoveIcon />
                                </RemoveTagValue>
                              )}
                            </TagValue>
                          )
                        })}
                      </TagValues>
                    </TagRow>
                    {index + 1 !== excludeTags?.length && (
                      <OperatorWrapper>
                        <Operator>Or</Operator>
                      </OperatorWrapper>
                    )}
                  </>
                )
              })}
            </>
          ) : (
            <EmptyTags>
              <p>No tags added</p>
            </EmptyTags>
          )}
          {isSuperUser() && (
            <>
              <Tooltip id="add-tag-key" />
              <AddTagCondition
                data-tooltip-id="add-tag-key"
                data-tooltip-content="Add new tag"
                onClick={() => setAddNewTag("EXCLUDE")}
              >
                <PlusIcon />
              </AddTagCondition>
            </>
          )}

          {addNewTag === "EXCLUDE" && (
            <EditPopup
              title="Add new tag to exclude"
              buttonText="Add tag"
              disableButton={!addTagKey || !tagValues.length}
              handleOkClick={() => addTagCondition("EXCLUDE")}
              handleCloseClick={() => clearForm()}
            >
              <TagSelect options={excludeOptions} setValue={setAddTagKey} />
              {addTagKey && (
                <Options
                  tagKey={addTagKey.value}
                  tagValues={tagValues}
                  setTagValues={setTagValues}
                  tagConditions={tagConditions}
                />
              )}
            </EditPopup>
          )}
        </Tags>
      </Conditions>
    </Container>
  )
}
