import React, { RefObject, useEffect, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { Conditions, FrontendCondition } from "@lib/types/discount"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import QuickAdd from "components/Ui/Table/QuickAdd"
import MultiSelect from "components/Ui/Form/MultiSelect"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import { Loader } from "components/Ui/Loader"

const Container = styled.div`
  > div {
    display: block;
  }
`

const ConditionsAdd = styled(QuickAdd)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
`

type Props = {
  condition?: FrontendCondition
  addCondition: (data: Conditions) => void
  submitRef: RefObject<HTMLButtonElement> | undefined
}

const StoreGroup = ({ condition, addCondition, submitRef }: Props) => {
  const { error, data, loading } = useQuery(ALL_STORE_GROUPS)
  const [invalid, setInvalid] = useState<boolean | undefined>(undefined)

  const [storeGroups, setStoreGroups] = useState<string[]>(condition?.data?.identifiers ?? [])

  const options = data?.getStoreGroups?.map((storeGroup: { id: string; name: string }) => ({
    value: storeGroup.id,
    label: data?.getStoreGroups?.find((sg: { id: string }) => sg.id === storeGroup.id)?.name
  }))

  const defaultValues = storeGroups
    ? storeGroups.map((id: string) => ({
        value: id,
        label: data?.getStoreGroups?.find((storeGroup: { id: string }) => storeGroup.id === id)
          ?.name
      }))
    : undefined

  const onSubmit = () => {
    if (storeGroups.length > 0) {
      addCondition({
        identifiers: storeGroups
      })
    } else {
      setInvalid(true)
    }
  }

  useEffect(() => {
    if (storeGroups.length > 0) {
      setInvalid(false)
    }
  }, [storeGroups])

  if (loading) return <Loader color="black" />

  return (
    <Container>
      {error ? (
        <ErrorMessage message="Error loading Store groups" />
      ) : (
        <ConditionsAdd>
          <MultiSelect
            options={options}
            setValue={setStoreGroups}
            defaultValue={defaultValues}
            returnValueOnly
            invalid={invalid === true}
            label="Select store group(s) *"
          />
        </ConditionsAdd>
      )}
      <button
        onClick={() => onSubmit()}
        ref={submitRef}
        type="button"
        style={{ display: "none" }}
      />
    </Container>
  )
}

export default StoreGroup
