import React from "react"
import { QueryPlatformConfig } from "@lib/types/generated/graphql-types"

import { Container, Header, Title, StyledTableHeader, Name, Period } from "./DiscountQuotas.styled"

import TableRow from "components/Ui/Table/TableRow"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"

import { ReactComponent as DiscountIcon } from "images/icons/badge-percent.svg"

type Props = {
  PlatformConfig: QueryPlatformConfig
  error: object | undefined
}

export const DiscountQuotas = ({ PlatformConfig, error }: Props) => {
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading Discount quotas. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )
  return (
    <Container>
      <Header>
        <Title>Discount quotas</Title>
        <p>
          Discount quotas in merchant portal are applied to ensure fair usage and stability of the
          service, to upgrade plan contact <a href="mailto:support@brinkcommerce.com">support</a>
        </p>
      </Header>
      <h2>Merchant portal discount quotas</h2>
      <StyledTableHeader>
        <div className={"hideOnSmallScreens"}>Name</div>
        <div>Max number of generation jobs </div>
        <div>Max number of codes </div>
      </StyledTableHeader>
      <TableRow>
        <DiscountIcon />
        <div className={"hideOnSmallScreens"}>
          <Name>Discount code generations</Name>
        </div>
        <div>
          <b>
            {PlatformConfig.discount?.externalApi.discountManagementQuotas?.maxGenerationJobsPerDay.toLocaleString(
              "en-US"
            )}
          </b>
          <Period>/day</Period>
        </div>
        <div>
          <b>
            {PlatformConfig.discount?.externalApi.discountManagementQuotas?.maxNumberOfCodes.toLocaleString(
              "en-US"
            )}
          </b>
          <Period>/job</Period>
        </div>
      </TableRow>
    </Container>
  )
}
