import styled from "styled-components"

import { IconTableHeader } from "../../Ui/Table/Shared.styled"
import TableRow from "../../Ui/Table/TableRow"

export const StyledIconTableHeader = styled(IconTableHeader)`
  padding-right: 8rem;
`

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: initial;
  }
`

export const Name = styled.div`
font-size: 1.1rem;
  ${(p) => p.theme.bold};
  width: 17rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 25rem;
  }
`
