import gql from "graphql-tag"

const GET_BUNDLE_GROUP = gql`
  query getBundleGroup($id: ID!) {
    getBundleGroup(id: $id) {
      id
      name
      isActive
      created
      updated
      bundles {
        discountValue {
          type
          percentage
          percentageDecimals
          fixedDiscounts {
            units
            currencyCode
          }
        }
        id
        itemRules {
          quantity
          excludeDiscountedPrices
          excludeFullPrices
          productParents
          discountValue {
            type
            percentage
            percentageDecimals
            fixedDiscounts {
              units
              currencyCode
            }
          }
          tagConditions {
            key
            mode
            values
          }
        }
      }
      storeMarkets {
        key
        value
      }
      validDateRange {
        from
        to
      }
    }
  }
`

export default GET_BUNDLE_GROUP
