import { OrderRefund } from "@lib/types/generated/graphql-types"
import { OrderOperations } from "./OrderOperations"

export class Refund extends OrderOperations {
  constructor(refund: OrderRefund[]) {
    super(refund)
  }

  public completedRefunds(): OrderRefund[] {
    return this.completedList() as OrderRefund[]
  }

  public failedRefunds(): OrderRefund[] {
    return this.failedList() as OrderRefund[]
  }

  public getOnGoingRefund(): OrderRefund {
    const onGoingList = this.onGoingList() as OrderRefund[]
    return onGoingList[0]
  }

  public getPendingRefund(): OrderRefund {
    const pendingList = this.pendingList() as OrderRefund[]
    return pendingList[0]
  }
}
