import { OrderOperations } from "./OrderOperations"
import {
  OrderLine,
  OrderLineModificationCreateData, OrderLineModificationDeleteData,
  OrderLineModificationUpdateData,
  OrderModificationOrderLines
} from "@lib/types/generated/graphql-types"
import Dinero from "dinero.js"
import DineroFactory from "dinero.js"

export class Modification extends OrderOperations {
  constructor(modification: OrderModificationOrderLines[]) {
    super(modification)
  }

  public onGoingModifications(): OrderModificationOrderLines[] {
    return this.onGoingList() as OrderModificationOrderLines[]
  }

  public failedModifications(): OrderModificationOrderLines[] {
    return this.failedList() as OrderModificationOrderLines[]
  }

  public getOngoingModification(): OrderModificationOrderLines {
    const onGoingList = this.onGoingList() as OrderModificationOrderLines[]
    return onGoingList[0]
  }

  public getPendingModification(): OrderModificationOrderLines {
    const pendingList = this.pendingList() as OrderModificationOrderLines[]
    return pendingList[0]
  }

  public deleteOutputToInput(
    data: OrderLineModificationDeleteData
  ): OrderLineModificationUpdateData {
    return {
      description: data.description,
      id: data.id,
      name: data.name,
      productVariantId: data.productVariantId,
      productParentId: data.productParentId,
      taxGroupId: data.taxGroupId,
      ...(data.imageUrl && { imageUrl: data.imageUrl })
    }
  }

  public updateOutputToInput(
    data: OrderLineModificationUpdateData
  ): OrderLineModificationUpdateData {
    return {
      description: data.description,
      id: data.id,
      name: data.name,
      productVariantId: data.productVariantId,
      productParentId: data.productParentId,
      taxGroupId: data.taxGroupId,
      ...(data.imageUrl && { imageUrl: data.imageUrl }),
      price: {
        basePriceAmount: data?.price?.basePriceAmount ?? 0,
        quantity: data.price?.quantity ?? 0,
        distributedTotalPriceAmount: data?.price?.distributedTotalPriceAmount,
        salePriceAmount: data?.price?.salePriceAmount,
        taxPercentage: data?.price?.taxPercentage ?? 0,
        taxPercentageDecimals: data?.price?.taxPercentageDecimals,
        totalPriceAmount: data?.price?.totalPriceAmount ?? 0
      }
    }
  }

  public createOutputToInput(
    data: OrderLineModificationCreateData
  ) {
    return {
      description: data.description,
      name: data.name,
      productVariantId: data.productVariantId,
      productParentId: data.productParentId,
      taxGroupId: data.taxGroupId,
      ...(data.imageUrl && { imageUrl: data.imageUrl }),
      basePriceAmount: data?.basePriceAmount,
      quantity: data?.quantity,
      distributedTotalPriceAmount: data?.distributedTotalPriceAmount,
      salePriceAmount: data?.salePriceAmount,
      taxPercentage: data?.taxPercentage,
      taxPercentageDecimals: data?.taxPercentageDecimals,
      totalPriceAmount: data?.totalPriceAmount
    }
  }

  public getDistributedDiscountOnQty(
    editOrderLine: OrderLine,
    currencyCode: string,
    quantity: number
  ): number {
    return Dinero({
      amount: editOrderLine.distributedTotalDiscountAmount,
      currency: currencyCode as DineroFactory.Currency
    })
      .convertPrecision(3)
      .divide(editOrderLine?.quantity)
      .multiply(quantity)
      .convertPrecision(2)
      .getAmount()
  }
}
