
import { api} from './api'
import { slice } from "lib/store/services/brink/slice"


export const {
  useUpdateItemMutation,
  useStartSessionMutation,
  useDeleteItemMutation,
   useAddItemMutation,
  useAddDiscountCodeMutation,
  useDeleteDiscountCodeMutation,
  useStartCheckoutMutation,
} = api


export const { reset: reset } = slice.actions
