export const TINY = 320
export const SMALL = 560
export const MEDIUM = 960
export const LARGE = 1400
export const XLARGE = 1600

export const animation = {
  defaultDuration: "700",
  defaultEffect: "slide-up",
  defaultDelay: "100",
  defaultEasing: "ease"
}

export const theme = {
  colors: {
    background: "#F9FAFC",
    black: "#172532",
    white: "#ffffff",
    turquoise: "#47e0c2",
    turquoiseLight: "#91ecda",
    turquoiseLighter: "#edfcf9",
    turquoiseDark: "#39b39b",
    turquoiseDarker: "#2b8674",
    turquoiseGradient: "linear-gradient(171deg,rgba(71, 224, 194, 1) 0%,rgba(50, 157, 136, 1) 100%)",
    turquoiseBorder: "rgba(57, 179, 155, 0.2)",
    blueLight: "#F0F8FF",
    blue: "#7CB9E8",
    blueDark: "#111f32",
    blueDarker: "#0d1827",
    blueDarkOpac: "rgb(12,24,41, 0.8)",
    borderLight: "#e8e8e8",
    orange: "#F28C28",
    yellowDark: "#FFAA1D",
    yellow: "#FFCD79",
    yellowLight: "#ffe6bc",
    yellowLighter: "#fff9D9",
    yellowBorder: "#FFF1A9",
    grey: "#cccccc",
    greyLight: "#dfe3e8",
    greyLighter: "#eff1f4",
    greyLightest: "#f5f7f8",
    greyDark: "#999",
    greyDarker: "#666",
    greyGradient: "linear-gradient(180deg,rgba(255, 255, 255, 1) 0%,rgba(245, 245, 245, 1) 100%)",
    errorBackground: "#ffcccc",
    errorBorder: "#FF8A8A",
    errorText: "#cc3333",
    default: {
      background: "#777",
      highlight: "#ccc",
      text: "#fff"
    },
    error: {
      background: "#cc3333",
      highlight: "#9E362C",
      text: "#fff"
    },
    success: {
      background: "#128112",
      highlight: "#fff",
      text: "#fff"
    },
    dashboard: {
      turquoise: "#18b496",
      purple: "#5854ff",
      pink: "#ff329a",
      yellow: "#ffc700",
      turquoiseLight: "#91ecda",
      purpleLight: "#8683ff",
      pinkLight: "#ff70b7",
      yellowLight: "#ffe6bc",
      turquoiseLighter: "#edfcf9",
      purpleLighter: "#b5b3ff",
      pinkLighter: "#ffadd5",
      yellowLighter: "#fff9D9",
    }
  },
  bold: `
    font-weight: 700;
    font-family: "Museo Sans Bold", serif;
    letter-spacing: 0.05rem;
  `,
  fonts: {
    primary: '"Museo Sans", sans-serif',
    secondary: '"Suez One", serif'
  },
  mQ: {
    MEDIA_TINY: `@media (max-width: ${TINY}px)`,
    MEDIA_MAX_SMALL: `@media (max-width: ${SMALL}px)`,
    MEDIA_SMALL: `@media (min-width: ${TINY + 1}px) and (max-width: ${SMALL}px)`,
    MEDIA_MIN_MEDIUM: `@media (min-width: ${SMALL + 1}px)`,
    MEDIA_MAX_MEDIUM: `@media (max-width: ${MEDIUM}px)`,
    MEDIA_MEDIUM: `@media (min-width: ${SMALL + 1}px) and (max-width: ${MEDIUM}px)`,
    MEDIA_MIN_LARGE: `@media (min-width: ${MEDIUM + 1}px)`,
    MEDIA_MIN_X_LARGE: `@media (min-width: ${LARGE + 1}px)`,
    MEDIA_MIN_XX_LARGE: `@media (min-width: ${XLARGE + 1}px)`
  }
}
