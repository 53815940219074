import React from "react"
import { v4 as uuid } from "uuid"
import { PlatformConfig, QueryPlatformConfig } from "@lib/types/generated/graphql-types"

import {
  Container,
  Name,
  Period,
  StyledTableHeader,
  StyledTableRow,
  Wrapper
} from "./RateLimitTable.styled"

import { BrinkLoader } from "components/Ui/BrinkLoader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import { TooltipIcon } from "components/Ui/TooltipIcon"

import { ReactComponent as Code } from "images/icons/laptop-code.svg"
import { ReactComponent as PriceIcon } from "images/icons/badge-dollar.svg"
import {
  ReactComponent as DiscountIcon,
  ReactComponent as TaxIcon
} from "images/icons/badge-percent.svg"
import { ReactComponent as StoreIcon } from "images/icons/store.svg"
import { ReactComponent as InventoryIcon } from "images/icons/warehouse.svg"
import { ReactComponent as OrderIcon } from "images/icons/cart-shopping.svg"
import { ReactComponent as ProductIcon } from "images/icons/bags-shopping.svg"

type Props = {
  services: QueryPlatformConfig
  loading: boolean
  error: object | undefined
}

const fetchIcon = (service: string) => {
  switch (service) {
    case "product":
      return <ProductIcon />
    case "discount":
      return <DiscountIcon />
    case "stock":
      return <InventoryIcon />
    case "order":
      return <OrderIcon />
    case "store":
      return <StoreIcon />
    case "tax":
      return <TaxIcon />
    case "price":
      return <PriceIcon />
    default:
      return <Code />
  }
}

export const RateLimitTable = ({ services, loading, error }: Props) => (
  <Wrapper>
    <Container>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading Rate limits. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}

      <StyledTableHeader>
        <div>Name</div>
        <div>
          Rate limit{" "}
          <span className="hideOnSmallScreens">
            <TooltipIcon content="The rate, in requests per second, that clients can call Brink Commerce API." />
          </span>
        </div>
        <div>
          Burst limit{" "}
          <span className="hideOnSmallScreens">
            <TooltipIcon content="The number of concurrent requests that a client can make to Brink Commerce API." />
          </span>{" "}
        </div>
        <div>
          Quota limit{" "}
          <span className="hideOnSmallScreens">
            <TooltipIcon content="The total number of requests that a user can make during the time period." />
          </span>
        </div>
      </StyledTableHeader>
      {loading ? (
        <BrinkLoader />
      ) : (
        <>
          {services &&
            Object.keys(services)
              .filter((service) => service !== "__typename")
              .sort((a, b) => a.localeCompare(b))
              .map((serviceKey: string) => {
                const service = services[serviceKey as keyof QueryPlatformConfig] as PlatformConfig
                const { rateLimit, burstLimit, quotaLimit, quotaPeriod } =
                  service?.externalApi?.usagePlans[0] ?? {}
                return (
                  <StyledTableRow key={uuid()}>
                    {fetchIcon(serviceKey)}
                    <div>
                      <Name>{serviceKey}</Name>
                    </div>
                    <div>
                      <b>{rateLimit}</b>
                      <Period>/s</Period>
                    </div>
                    <div>
                      <b>{burstLimit}</b>
                      <Period>/s</Period>
                    </div>
                    <div>
                      {quotaLimit === 0 ? (
                        "No limit"
                      ) : (
                        <>
                          <b> {quotaLimit}</b>
                          <Period>/{quotaPeriod}</Period>
                        </>
                      )}
                    </div>
                  </StyledTableRow>
                )
              })}
        </>
      )}
    </Container>
  </Wrapper>
)
