import gql from "graphql-tag"

const DELETE_ORDER_COMMENT = gql`
  mutation deleteOrderComment($orderId: ID!, $commentId: ID!) {
    Order {
      deleteOrderComment(orderId: $orderId, commentId: $commentId)
    }
  }
`

export default DELETE_ORDER_COMMENT
