import React from "react"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { CustomReasonCode, Maybe, Reason } from "@lib/types/generated/graphql-types"
import { Comment, Container, DeleteButton, Info } from "./AddedReason.styled"
import { ReactComponent as CommentIcon } from "images/icons/comment-lines.svg"

type Props = {
  reason: Maybe<Reason> | undefined
  removeReason?: () => void
  loading?: boolean
  getCustomCode: (code: Maybe<string> | undefined) => CustomReasonCode | undefined
}

const AddedReason = ({ reason, removeReason, loading, getCustomCode }: Props) => {
  const customCode = getCustomCode(reason?.code)

  if(!reason) return(<div>- No reason added</div>)

  return (
    <Container>
      <Info>
        {reason?.code && (
          <span>
            <b>{customCode ? `${customCode.code} - ${customCode.label}` : reason.code}</b>
          </span>
        )}
        {reason?.cause && (
          <Comment>
            <CommentIcon />
            {reason?.cause}
          </Comment>
        )}
      </Info>

      {removeReason && (loading ? (
        <DeleteButton className={loading ? "blur" : ""}>
          <TrashIcon />
        </DeleteButton>
      ) : (
        <DeleteButton onClick={removeReason}>
          <TrashIcon />
        </DeleteButton>
      ))}
    </Container>
  )
}

export default AddedReason
