import React, { useEffect, useState } from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import { Order } from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"

import GET_STORE_GROUP from "../../../../../graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import {
  Blur,
  ButtonWrapper,
  Container,
  OrderLinesLabel,
  Row,
  Summary
} from "./OrderSummary.styled"

import Money from "../../../../Money"
import { Item, Label, Value } from "../../../../Ui/List/List"
import FlagIcon from "../../../../Ui/FlagIcon"
import DotLoader from "../../../../Ui/DotLoader"
import CopyButton from "../../../../Ui/CopyButton"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { VariantRow } from "./VariantRow"
import { ShippingRow } from "./ShippingRow"
import CREATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/CreateCompensation"
import {
  toCompensationOrderLineFromState,
  toCompensationShippingFeeFromState
} from "../Helpers/helpers"
import CompensationTotals from "./CompensationTotals"
import { ReactComponent as PaymentIcon } from "images/icons/credit-card.svg"
import { NoticeBanner } from "../../../../Ui/Messages/NoticeBanner"
import { handleErrorMessages } from "helpers/errors"
import {
  InputOrderLineCompensationWithAmount,
  InputShippingFeeCompensationWithAmount
} from "@lib/types/order"
import OrderPercentage from "./OrderPercentage"
import { Compensation } from "../../Helpers/Compensation"
import { Delivery } from "../../Helpers/Delivery"
import { Refund } from "../../Helpers/Refund"

type CompensationState = {
  payment?: { amount: number }
  bonus?: { amount: number }
  giftCards?: { amount: number; giftCardId: string }[]
}

type Props = {
  order: Order
  refetch: () => void
  orderLoading: boolean
}

export const OrderSummary = ({ order, refetch }: Props) => {
  const [compensation, setCompensation] = useState<CompensationState>()
  const [orderPercentage, setOrderPercentage] = useState<number>()
  const [customPercentage, setCustomPercentage] = useState<string>()

  const deliveries = new Delivery(order.Deliveries)
  const compensations = new Compensation(order.Compensations)
  const refunds = new Refund(order.Refunds)

  const orderLinesInDeliveries = deliveries
    .completedDeliveries()
    ?.flatMap((delivery) => delivery.orderLines)
  const shippingFeesInDeliveries = deliveries
    .completedDeliveries()
    ?.flatMap((delivery) => delivery.shippingFees)

  const totalPayment = deliveries.paymentTotal() - compensations.paymentTotal()
  const totalGiftCard = deliveries.giftCardTotal() - compensations.giftCardTotal()
  const totalBonus = deliveries.bonusTotal() - compensations.bonusTotal()

  const [inputOrderLines, setInputOrderLines] = useState<InputOrderLineCompensationWithAmount[]>(
    orderLinesInDeliveries.map((deliveryLine) => ({
      orderLineId: deliveryLine.orderLineId,
      quantity: deliveryLine.quantity,
      taxPercentage: deliveryLine.taxPercentage,
      taxPercentageDecimals: deliveryLine.taxPercentageDecimals,
      totalAmount: deliveryLine.totalAmount,
      totalTaxAmount: deliveryLine.totalTaxAmount,
      totalDiscountAmount: deliveryLine.totalDiscountAmount,
      compensationAmount: 0
    }))
  )

  const getOrderShippingFee = (shippingFeeId: string) =>
    order?.shippingFees?.find((fee) => fee.id === shippingFeeId)

  const [inputShippingFees, setInputShippingFees] = useState<
    InputShippingFeeCompensationWithAmount[]
  >(
    shippingFeesInDeliveries.map((shippingFee) => ({
      shippingFeeId: shippingFee.shippingFeeId,
      totalAmount: shippingFee.totalAmount,
      compensationAmount: 0,
      taxPercentage: getOrderShippingFee(shippingFee.shippingFeeId)?.taxPercentage ?? 0,
      taxPercentageDecimals:
        getOrderShippingFee(shippingFee.shippingFeeId)?.taxPercentageDecimals ?? 0,
      totalDiscountAmount: getOrderShippingFee(shippingFee.shippingFeeId)?.discountAmount ?? 0,
      totalTaxAmount: getOrderShippingFee(shippingFee.shippingFeeId)?.taxAmount ?? 0
    }))
  )

  const getTotalGiftCardAmount = () => {
    return compensation?.giftCards?.reduce((a, b) => a + b.amount, 0) ?? 0
  }

  const getTotalCompensationAmount = () => {
    return (
      (compensation?.payment?.amount ?? 0) +
      (compensation?.bonus?.amount ?? 0) +
      getTotalGiftCardAmount()
    )
  }

  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  const updateCompensationPayment = (amount: number) => {
    if (!amount) {
      const copy = compensation
      delete copy?.payment
      if (copy && Object.keys(copy).length === 0) {
        setCompensation(undefined)
        return
      }
      setCompensation(copy)
      return
    }
    setCompensation({ ...compensation, payment: { amount } })
  }

  const updateCompensationBonus = (amount: number) => {
    if (!amount) {
      const copy = compensation
      delete copy?.bonus
      if (copy && Object.keys(copy).length === 0) {
        setCompensation(undefined)
        return
      }
      setCompensation(copy)
      return
    }
    setCompensation({ ...compensation, bonus: { amount } })
  }

  const updateCompensationGiftCard = (amount: number, giftCardId: string) => {
    const giftCardExists = compensation?.giftCards?.find(
      (giftCard) => giftCard.giftCardId === giftCardId
    )
    setCompensation({
      ...compensation,
      giftCards: giftCardExists
        ? compensation?.giftCards?.map((giftCard) =>
            giftCard.giftCardId === giftCardId
              ? {
                  giftCardId: giftCardId,
                  amount: amount
                }
              : giftCard
          )
        : [...(compensation?.giftCards ?? []), { giftCardId: giftCardId, amount: amount }]
    })
  }

  const updateOrderLinesAmount = (percentage: number) => {
    if (percentage) {
      setInputOrderLines(
        inputOrderLines.map((inputOrderLine) => ({
          ...inputOrderLine,
          compensationAmount: Math.floor(inputOrderLine.totalAmount * (percentage / 100))
        }))
      )
      setInputShippingFees(
        inputShippingFees.map((inputShippingFee) => ({
          ...inputShippingFee,
          compensationAmount: Math.floor(inputShippingFee.totalAmount * (percentage / 100))
        }))
      )
    }
  }

  const clearOrderPercentage = () => {
    setOrderPercentage(undefined)
    setCustomPercentage(undefined)
  }

  const [createCompensation, { loading: createLoading }] = useMutation(CREATE_COMPENSATION, {
    onCompleted: () => {
      setCompensation(undefined)
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const createOrderCompensation = () => {
    createCompensation({
      variables: {
        orderId: order.id,
        input: {
          ...(compensation?.payment && { payment: compensation.payment }),
          ...(compensation?.bonus && { bonus: compensation.bonus }),
          ...(compensation?.giftCards &&
            compensation?.giftCards?.length > 0 && { giftCards: compensation.giftCards }),
          ...(inputOrderLines.length > 0 && {
            orderLines: inputOrderLines
              .filter((orderLine) => orderLine.compensationAmount > 0)
              .map((orderLine) => toCompensationOrderLineFromState(orderLine, order.currencyCode))
          }),
          ...(inputShippingFees.length > 0 && {
            shippingFees: inputShippingFees
              .filter((shippingFee) => shippingFee.compensationAmount > 0)
              .map((shippingFee) =>
                toCompensationShippingFeeFromState(shippingFee, order.currencyCode)
              )
          })
        }
      }
    })
    setInputOrderLines(
      inputOrderLines.map((orderLine) => ({ ...orderLine, compensationAmount: 0 }))
    )
    setInputShippingFees(
      inputShippingFees.map((shippingFee) => ({ ...shippingFee, compensationAmount: 0 }))
    )
    setOrderPercentage(undefined)
    window.scrollTo(0, 0)
  }

  const getAmountFromLines = () => {
    return (
      inputOrderLines.reduce(
        (a, b) => a + (isNaN(b.compensationAmount) ? 0 : b.compensationAmount),
        0
      ) +
      inputShippingFees.reduce(
        (a, b) => a + (isNaN(b.compensationAmount) ? 0 : b.compensationAmount),
        0
      )
    )
  }

  const maxAmount = totalPayment + totalBonus + totalGiftCard

  const compensationDisabled =
    (compensation?.payment?.amount ?? 0) > maxAmount ||
    Object.keys(compensation ?? {}).length === 0 ||
    getTotalCompensationAmount() - getAmountFromLines() < 0 ||
    getTotalCompensationAmount() <= 0

  useEffect(() => {
    if (order.totals.grandTotal >= getAmountFromLines())
      setCompensation({ ...compensation, payment: { amount: getAmountFromLines() } })
  }, [inputOrderLines, inputShippingFees])

  if (!order)
    return (
      <Blur>
        <Header>
          <h2>Order: #1234567</h2>
        </Header>
        <Summary>
          <Item flexibleHeight>
            <Label>E-mail:</Label>
            <Value>
              <>
                john.doe@email.com
                <CopyButton string={""} />
              </>
            </Value>
          </Item>
          <Item>
            <Label>Store group:</Label>
            <Value>store group</Value>
          </Item>
          <Item>
            <Label>Store market:</Label>
            <Value flex>
              <>Sweden</>
            </Value>
          </Item>
          <Item>
            <Label>Order date:</Label>
            <Value>2000-00-00 00:00</Value>
          </Item>
          <Item>
            <Label>Total order value:</Label>
            <Value>SEK 200.00</Value>
          </Item>
        </Summary>
      </Blur>
    )
  if (!order) return null

  return (
    <>
      <Header>
        <h2>Order: #{order?.reference}</h2>
      </Header>
      <Summary>
        <Row>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order?.billingAddress?.email}
              <CopyButton string={order?.billingAddress?.email} />
            </>
          </Value>
        </Row>
        <Row>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Row>
        <Row>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order?.countryCode} />
              {getCountryName(order?.countryCode)}
            </>
          </Value>
        </Row>
        <Row>
          <Label>Order date:</Label>
          <Value>{dayjs(order?.date).format("YYYY-MM-DD HH:mm")}</Value>
        </Row>
        {totalGiftCard > 0 ? (
          <Row>
            <Label>Gift card total:</Label>
            <Value>
              <Money
                amount={parseInt(`${totalGiftCard}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Row>
        ) : (
          <></>
        )}
        {totalBonus > 0 ? (
          <Row>
            <Label>Bonus total:</Label>
            <Value>
              <Money
                amount={parseInt(`${totalBonus}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Row>
        ) : (
          <></>
        )}
        {totalPayment > 0 ? (
          <Row>
            <Label>Payment total:</Label>
            <Value>
              <Money
                amount={parseInt(`${totalPayment}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Row>
        ) : (
          <></>
        )}
      </Summary>
      <Container>
        <h2>Compensation</h2>
        <>
          {!compensations.onGoing() && (
            <OrderPercentage
              percentage={orderPercentage}
              customPercentage={customPercentage}
              setCustomPercentage={setCustomPercentage}
              setPercentage={setOrderPercentage}
              updateOrderLinesAmount={updateOrderLinesAmount}
            />
          )}
          <>
            {compensations.onGoing() && (
              <OrderLinesLabel>Or for individual order lines</OrderLinesLabel>
            )}
            {orderLinesInDeliveries
              ?.filter(
                (orderLine) =>
                  !compensations.onGoingCompensations()?.find((compensation) =>
                    compensation?.orderLines?.find(
                      (compensationOrderLine) =>
                        compensationOrderLine.orderLineId === orderLine.orderLineId
                    )
                  )
              )
              .map((orderLine) => (
                <VariantRow
                  key={orderLine?.orderLineId}
                  orderLine={orderLine}
                  order={order}
                  refetch={refetch}
                  currencyCode={order?.currencyCode}
                  setInputOrderLines={setInputOrderLines}
                  inputOrderLines={inputOrderLines}
                  clearOrderPercentage={clearOrderPercentage}
                  orderPercentage={orderPercentage}
                />
              ))}
            {shippingFeesInDeliveries
              ?.filter(
                (shippingFee) =>
                  !compensations.onGoingCompensations()?.find((compensation) =>
                    compensation?.shippingFees?.find(
                      (compensationShippingFee) =>
                        compensationShippingFee.shippingFeeId === shippingFee.shippingFeeId
                    )
                  )
              )
              .map((shippingFee) => (
                <ShippingRow
                  key={shippingFee?.shippingFeeId}
                  shippingFee={shippingFee}
                  order={order}
                  currencyCode={order?.currencyCode}
                  setInputShippingFees={setInputShippingFees}
                  inputShippingFees={inputShippingFees}
                  refetch={refetch}
                  clearOrderPercentage={clearOrderPercentage}
                  orderPercentage={orderPercentage}
                />
              ))}
          </>
          {!refunds.completed() && (totalPayment > 0 || totalBonus > 0 || totalGiftCard > 0) ? (
            orderLinesInDeliveries?.length > 0 || shippingFeesInDeliveries?.length > 0 ? (
              <>
                {!compensations.onGoing() && (
                  <CompensationTotals
                    order={order}
                    updatePayment={updateCompensationPayment}
                    updateGiftCard={updateCompensationGiftCard}
                    updateBonus={updateCompensationBonus}
                    amount={compensation?.payment?.amount || 0}
                    icon={<PaymentIcon />}
                    maxAmount={maxAmount}
                    compensation={compensation}
                    amountFromLines={getAmountFromLines()}
                    totalCompensationAmount={getTotalCompensationAmount()}
                    totalGiftCardAmount={getTotalGiftCardAmount()}
                  />
                )}
              </>
            ) : (
              <div>
                <h2>Order lines ({orderLinesInDeliveries?.length ?? 0})</h2>{" "}
                <NoticeBanner type="important">
                  Missing completed deliveries for orderlines
                </NoticeBanner>
                <h2>Shipping ({shippingFeesInDeliveries?.length ?? 0})</h2>{" "}
                <NoticeBanner type="important">
                  Missing completed deliveries for shipping
                </NoticeBanner>
              </div>
            )
          ) : (
            <div>
              {totalPayment === 0 && totalBonus === 0 && totalGiftCard === 0 ? (
                <NoticeBanner type="important">{`Missing amount to compensate`}</NoticeBanner>
              ) : (
                <NoticeBanner type="important">{`Can't create compensation when refund exists`}</NoticeBanner>
              )}
            </div>
          )}
        </>

        {!compensations.onGoing() && (
          <>
            {!refunds.completed() && (totalPayment > 0 || totalBonus > 0 || totalGiftCard > 0) ? (
              <ButtonWrapper>
                <PrimaryButton
                  handleClick={createOrderCompensation}
                  loading={createLoading}
                  disabled={compensationDisabled}
                >
                  Create compensation draft
                </PrimaryButton>
              </ButtonWrapper>
            ) : (
              <></>
            )}
          </>
        )}
      </Container>
    </>
  )
}
