import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const Option = styled.div`
  display: flex;
  border-radius: 0.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.turquoiseDarker};
  margin: 0 1rem 1rem 0;
  font-size: 1.1rem;
`

export const Label = styled.div`
  margin-right: 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 1rem;
`

export const Key = styled.div`
  background: ${({ theme }) => theme.colors.turquoiseDarker};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  border-radius: 0.45rem 0 0 0.45rem;
  padding: 0 1rem;
  border-right: 0.1rem solid ${({ theme }) => theme.colors.turquoiseDarker};
`
export const Value = styled.div`
  border-radius: 0 0.6rem 0.6rem 0;
  padding: 0 1rem;

`
