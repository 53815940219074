import styled from "styled-components"

export const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7rem;
  width: 100%;
  height: 10rem;
  padding: 0 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

${(p) => p.theme.mQ.MEDIA_MAX_MEDIUM} {
    gap: 0.3rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const ColorTick = styled.div<{ color: string }>`
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 0.125rem;
  background-color: ${(props) => props.color};
`

export const LegendText = styled.span`
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 40rem; // Fixed height for all charts
  max-width: 465px;
  border-radius: 0.8rem;
  padding: 1.5rem;
  padding-bottom: 3.5rem;
  margin-bottom: 1.5rem;

  .xAxis {
    font-size: 1rem;
    font-weight: 700;
  }
`

export const Label = styled.div`
  ${(p) => p.theme.bold}
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
`

export const Button = styled.button`
  color: ${(p) => p.theme.colors.greyDark};
  font-size: 1.4rem;
  width: 100%;
  max-width: 10rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  background: transparent;

  &:hover {
    background: ${(p) => p.theme.colors.turquoiseDark};
    color: ${(p) => p.theme.colors.white};
  }
`
