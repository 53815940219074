import gql from "graphql-tag"

const ALL_REASON_CODES = gql`
  query getReasonCodes {
    Order {
     customReasonCodes {
       codes {
         label
         code
         created
         updated
       }
     }
    }
  }
`

export default ALL_REASON_CODES
