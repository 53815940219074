import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import GET_EXTERNAL_EVENT_RULES from "../../../graphql/queries/settings/externalEvents/GetExternalEventsRules"
import { Name } from "../EventRules/EventRulesTable.styled"
import {
  AddButton,
  Border,
  DisabledRuleContainer, EmptyList,
  EnabledEvents,
  EventRuleRow,
  Grid,
  IconLabel,
  RuleContainer,
  TypeSelect,
  TypeWrapper
} from "./ConnectedEventRules.styled"
import { ReactComponent as SatelliteIcon } from "../../../images/icons/satellite-dish.svg"
import { ReactComponent as XmarkIcon } from "../../../images/icons/circle-xmark.svg"
import { ReactComponent as ChevronDown } from "../../../images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "../../../images/icons/chevron-up.svg"
import { ReactComponent as PlusIcon } from "../../../images/icons/plus-thick.svg"
import { ReactComponent as MinusIcon } from "../../../images/icons/minus-thick.svg"
import { ReactComponent as BanIcon } from "../../../images/icons/ban.svg"

import SecondaryButton from "../../Ui/Buttons/SecondaryButton"
import ENABLE_EXTERNAL_EVENT_RULE from "../../../graphql/mutations/settings/externalEvents/EnableExternalEventsRule"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import GET_EXTERNAL_EVENTS_DESTINATION_APIS from "../../../graphql/queries/settings/externalEvents/GetExternalEventsDestinationAPIs"
import GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES from "../../../graphql/queries/settings/externalEvents/GetExternalEventsDestinationEventBuses"
import Popup from "../../Ui/Popup"
import { FilterMode } from "lib/types/externalEvents"

type Props = {
  filterEventRules: string[]
  setFilterEventRules: Dispatch<SetStateAction<string[]>>
  filterMode: FilterMode
  setFilterMode: Dispatch<SetStateAction<FilterMode>>
  customEvents?: boolean
}

const ConnectedEventRules = ({
  filterEventRules,
  setFilterEventRules,
  filterMode,
  setFilterMode
}: Props) => {
  const [showDisabledRules, setShowDisabledRules] = useState(false)

  const [activateRule, setActivateRule] = useState("")
  const { data, refetch, loading: eventRulesLoading } = useQuery(GET_EXTERNAL_EVENT_RULES)
  const eventRules = data?.getExternalEventsRules?.rules ?? []
  const dispatch = useAppDispatch()

  const { data: destinationApisData } = useQuery(GET_EXTERNAL_EVENTS_DESTINATION_APIS)
  const apiDestinations = destinationApisData?.getExternalEventsDestinationAPIs.apiDestinations

  const { data: eventBusData } = useQuery(GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES)
  const eventBuses = eventBusData?.getExternalEventsDestinationEventBuses?.eventBuses

  const isRuleAdded = (name: string) => filterEventRules.includes(name)

  const [enableEventRule, { loading }] = useMutation(ENABLE_EXTERNAL_EVENT_RULE, {
    onCompleted: () => {
      refetch().then(() => setActivateRule(""))
      dispatch(
        alertActions.actions.createAlert({
          message: `Event rule is now enabled`,
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const enabledEventRules = [...eventRules]
    .sort((a, b) => a.ruleName.localeCompare(b.ruleName))
    .filter((rule) => rule.state === "ENABLED")

  const disabledEventRules = [...eventRules]
    .sort((a, b) => a.ruleName.localeCompare(b.ruleName))
    .filter((rule) => rule.state !== "ENABLED")

  const handleEnabledRuleClick = (name: string) => {
    if (isRuleAdded(name)) {
      setFilterEventRules((prev) => prev.filter((rule) => rule !== name))
      return
    }
    const copy = [...filterEventRules]
    copy.push(name)
    setFilterEventRules(copy)
  }

  const handleEnableEventRule = (ruleName: string) => {
    if (apiDestinations.length > 0 || eventBuses.length > 0) {
      setActivateRule(ruleName)
      return
    }
    enableEventRule({
      variables: {
        ruleName: activateRule
      }
    })
  }

  return (
    <>
      {activateRule && (
        <Popup
          title={`Enable ${activateRule}`}
          handleCloseClick={() => setActivateRule("")}
          buttonText={"Enable anyway"}
          loading={loading || eventRulesLoading}
          handleOkClick={() =>
            enableEventRule({
              variables: {
                ruleName: activateRule
              }
            })
          }
        >
          Enabling event rule {activateRule} will affect current API destinations and eventbuses:
          <div>
            {apiDestinations.map((destination: string) => (
              <div key={destination}>{destination}</div>
            ))}
            {eventBuses.map((bus: string) => (
              <div key={bus}>{bus}</div>
            ))}
          </div>
        </Popup>
      )}
      <h2>Subscribing to events</h2>
      <TypeWrapper>
        <Border>
          <TypeSelect
            $selected={filterMode === FilterMode.ALL}
            onClick={() => setFilterMode(FilterMode.ALL)}
          >
            All enabled events
          </TypeSelect>
          <TypeSelect
            $selected={filterMode === FilterMode.SELECT}
            onClick={() => setFilterMode(FilterMode.SELECT)}
          >
            Specific events
          </TypeSelect>
        </Border>
      </TypeWrapper>
      {filterMode === FilterMode.ALL && (
        <div>
          <h4> Enabled events:</h4>
          <EnabledEvents>
            {enabledEventRules.length < 1 && <div> No enabled events</div>}
            {enabledEventRules.map((rule) => (
              <EventRuleRow key={rule.ruleName}>
                <SatelliteIcon />
                {rule.ruleName}
              </EventRuleRow>
            ))}
          </EnabledEvents>
        </div>
      )}
      {filterMode === FilterMode.SELECT && (
        <>
          <h4> Selected events:</h4>
          <EnabledEvents>
            {filterEventRules.length < 1 && <EmptyList><BanIcon/> No selected events</EmptyList>}
            {filterEventRules.map((rule) => (
              <EventRuleRow key={rule}>
                <SatelliteIcon />
                {rule}
              </EventRuleRow>
            ))}
          </EnabledEvents>
          <h4> Enabled events ({filterEventRules.length})</h4>
          <Grid>
            {eventRules &&
              enabledEventRules.map((rule) => (
                <RuleContainer key={rule.ruleName} $included={isRuleAdded(rule.ruleName)}>
                  <span>
                    <SatelliteIcon />
                    <div>
                      <Name>{rule.ruleName}</Name>
                    </div>
                  </span>
                  <AddButton
                    onClick={() => handleEnabledRuleClick(rule.ruleName)}
                    $selected={isRuleAdded(rule.ruleName)}
                  >
                    {isRuleAdded(rule.ruleName) ? <MinusIcon /> : <PlusIcon />}
                  </AddButton>
                </RuleContainer>
              ))}
          </Grid>
          {disabledEventRules.length > 0 && (
            <>
              <IconLabel onClick={() => setShowDisabledRules(!showDisabledRules)}>
                Disabled events ({disabledEventRules.length}){" "}
                {showDisabledRules ? <ChevronUp /> : <ChevronDown />}
              </IconLabel>
              <Grid>
                {eventRules &&
                  showDisabledRules &&
                  disabledEventRules.map((rule) => (
                    <DisabledRuleContainer key={rule.ruleName}>
                      <span>
                        <XmarkIcon />
                        <div>
                          <Name>{rule.ruleName}</Name>
                        </div>
                      </span>

                      <SecondaryButton
                        type={"button"}
                        handleClick={() => handleEnableEventRule(rule.ruleName)}
                      >
                        Enable
                      </SecondaryButton>
                    </DisabledRuleContainer>
                  ))}
              </Grid>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ConnectedEventRules
