import React, { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { Group, InputCreateUser } from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"

import CREATE_USER from "graphql/mutations/user/CreateUser"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import Input from "components/Ui/Form/Input"
import MultiSelect from "../../components/Ui/Form/MultiSelect"
import ADD_USER_TO_GROUP from "../../graphql/mutations/user/AddUserToGroup"
import REMOVE_USER_FROM_GROUP from "../../graphql/mutations/user/RemoveUserFromGroup"
import GROUPS from "../../graphql/queries/user/Groups"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { ReactComponent as UserIcon } from "images/icons/user-plus.svg"
import { AccessInfo, AccessWrapper } from "./CreateUser.styled"
import { Label } from "./User.styled"

type Props = {
  refetch: () => void
}

const getRolesDescription = (roleName: string) => {
  switch (roleName) {
    case "brinkcommerce-readonly":
      return "Members of this group can view discounts, orders, configurations, etc."
    case "brinkcommerce-superuser":
      return "Members of this group can create and edit configurations, manage discount rules, and perform other related tasks."
    case "brinkcommerce-useradmin":
      return "Members of this group can administrate users and API clients in Merchant Portal"
  }
}

export const CreateUser = ({ refetch }: Props) => {
  const dispatch = useDispatch()
  const [username, setUserName] = useState("")
  const [addedGroups, setAddedGroups] = useState<string[]>([])
  const [groupList, setGroupList] = useState<Group[]>([])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<InputCreateUser>()

  const closeSidebar = () => {
    setUserName("")
    setAddedGroups([])
    dispatch(hideEditSidebar())
  }

  const [createUser, { loading: createLoading }] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      setUserName(data.createUser.username)
      reset({
        username: "",
        givenName: "",
        familyName: ""
      })
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const { data: groupsData } = useQuery(GROUPS, {
    variables: { limit: 10 },
    onCompleted: (data) => setGroupList(data.groups.groups)
  })

  const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [removeUserFromGroup] = useMutation(REMOVE_USER_FROM_GROUP, {
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const updateGroups = (value: string[]) => {
    const addedGroup = value.filter((x) => !addedGroups.includes(x))
    const removedGroup = addedGroups.filter((x) => !value.includes(x))
    if (addedGroup.length > 0) {
      addUserToGroup({
        variables: { username: username, groupName: addedGroup[0] }
      })
    }

    if (removedGroup.length > 0) {
      removeUserFromGroup({
        variables: { username: username, groupName: removedGroup[0] }
      })
    }

    setAddedGroups(value)
  }

  const onSubmit: SubmitHandler<InputCreateUser> = (data) => {
    createUser({
      variables: {
        ...data
      }
    })
  }

  return (
    <EditSidebar cancelEvent={closeSidebar}>
      {username !== "" ? (
        <>
          <EditSidebarHeader icon={<UserIcon />} title="Create new user"></EditSidebarHeader>
          <EditSidebarSection>
            <AccessWrapper>
              <MultiSelect
                label="Access"
                setValue={updateGroups}
                returnValueOnly={true}
                defaultValue={addedGroups.map((g: string) => ({
                  value: g,
                  label: g
                }))}
                options={groupsData.groups.groups.map((g: Group) => ({
                  value: g.name,
                  label: g.name
                }))}
              />
              {groupList?.map((group) => (
                <AccessInfo key={group.name}>
                  <Label>{group.name}</Label>
                  <div>{getRolesDescription(group.name)}</div>
                </AccessInfo>
              ))}
            </AccessWrapper>
            <PrimaryButton handleClick={closeSidebar} disabled={addedGroups.length < 1}>
              Save
            </PrimaryButton>
          </EditSidebarSection>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditSidebarHeader icon={<UserIcon />} title="Create new user"></EditSidebarHeader>
          <EditSidebarSection>
            <Controller
              name="username"
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  label="Username (Email address) *"
                  placeholder="Username"
                  errors={errors}
                />
              )}
              defaultValue=""
              control={control}
              rules={{
                required: "This is a required field"
              }}
            />
            <Controller
              name="givenName"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  label="Given name *"
                  placeholder="Given name"
                  errors={errors}
                />
              )}
              defaultValue=""
              control={control}
              rules={{
                required: "This is a required field"
              }}
            />
            <Controller
              name="familyName"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  label="Family name *"
                  placeholder="Family name"
                  errors={errors}
                />
              )}
              defaultValue=""
              control={control}
              rules={{
                required: "This is a required field"
              }}
            />
            <PrimaryButton loading={createLoading} type="submit">
              Add role
            </PrimaryButton>
          </EditSidebarSection>
        </form>
      )}
    </EditSidebar>
  )
}
