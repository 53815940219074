import React from "react"
import TableHeader from "../../Ui/Table/TableHeader"
import { CustomState, CustomStateType } from "lib/types/generated/graphql-types"
import { v4 as uuid } from "uuid"
import { BlockingIcon, InfoIcon, Operation, Operations, StyledTableRow } from "./Shared.styled"
import { useDispatch } from "react-redux"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { Tooltip } from "../../Ui/Tooltip"

type Props = {
  customStates: CustomState[]
  handleSelectState: (code: string) => void
}

const CustomStateTable = ({ customStates, handleSelectState }: Props) => {
  const dispatch = useDispatch()

  const getTypeName = (type: CustomStateType): string => {
    if (type === CustomStateType.Informative) return "Informative"
    return "Blocking"
  }

  const formatOperation = (operation: string) => {
    if (operation === "MODIFY_ORDER_LINES") return "Modification"
    return operation
  }

  const selectRow = (id: string) => {
    handleSelectState(id)
    dispatch(showEditSidebar())
  }

  if (customStates.length < 1)
    return (
      <>
        <TableHeader>
          <div>Title</div>
        </TableHeader>
        <StyledTableRow disableHover={true}>
          <div>No definitions created</div>
        </StyledTableRow>
      </>
    )

  return (
    <>
      <TableHeader iconHeader={true}>
        <div>Name</div>
        <div>Blocked operations</div>
      </TableHeader>
      {customStates?.map((customState) => (
        <StyledTableRow key={customState.id} handleOnClick={() => selectRow(customState.id)}>
          <Tooltip id="tooltip" />
          {customState.type === CustomStateType.Blocking ? (
            <BlockingIcon
              data-tooltip-id="tooltip"
              data-tooltip-content={getTypeName(customState.type)}
              data-tooltip-delay-hide={100}
            />
          ) : (
            <InfoIcon
              data-tooltip-id="tooltip"
              data-tooltip-content={getTypeName(customState.type)}
              data-tooltip-delay-hide={100}
            />
          )}
          <div>{customState.description || customState.id}</div>
          <Operations>
            {customState?.operations?.map((operation) => (
              <Operation key={uuid()}>{formatOperation(operation)}</Operation>
            ))}
          </Operations>
        </StyledTableRow>
      ))}
    </>
  )
}

export default CustomStateTable
