import type { Session } from '@lib/types'
import type { RootState } from '@lib/store'
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { selectToken } from './selectors'

export interface RequestSessionStart {
  awsRegion: string
  customerName: string
  storeGroupId: string
  countryCode: string
  languageCode: string
}

export interface RequestAddItem {
  awsRegion: string
  customerName: string
  productVariantId: string
  quantity: number
}

export interface RequestUpdateItem {
  awsRegion: string
  customerName: string
  itemId: string
  quantity: number
}

export interface RequestGetSession {
  awsRegion: string
  customerName: string
}

export interface RequestDeleteItem {
  itemId: string
  awsRegion: string
  customerName: string
}

export interface RequestAddDiscountCode {
  discountCode: string
  awsRegion: string
  customerName: string
}

export interface RequestDeleteDiscountCode {
  discountCode: string
  awsRegion: string
  customerName: string
}

export interface RequestSessionCheckout {
  awsRegion: string
  customerName: string
  paymentProvider: {
    name: string
    id: string
  }
  shippingProvider?: {
    name: string
    id: string
  }
  giftCardProvider?: {
    name: string
    id: string
  }

  voucherProvider?: {
    name: string
    id: string
  }
}

export interface BrinkError {
  status?: number
  data?: {
    error: string
  }
}

export const api = createApi({
  reducerPath: 'brinkApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://shopper.eu-west-1.staging.brinkcommerce.io/shopper`,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, BrinkError, object>,
  endpoints: (build) => ({
    getSession: build.query<Session.ShopperSessionResponse, RequestGetSession>({
      query: ({awsRegion, customerName}) => `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions`,
    }),
    startSession: build.mutation<Session.ShopperSessionResponse, RequestSessionStart>({
      query: ({ awsRegion, customerName, storeGroupId, countryCode, languageCode }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/start`,
        method: 'POST',
        body: { storeGroupId, countryCode, languageCode },
      }),
    }),
    addItem: build.mutation<Session.ShopperSessionResponse, RequestAddItem>({
      query: ({ awsRegion, customerName, productVariantId, quantity }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/items`,
        method: 'POST',
        body: { productVariantId, quantity },
      }),
    }),
    updateItem: build.mutation<Session.ShopperSessionResponse, RequestUpdateItem>({
      query: ({ awsRegion, customerName, itemId, quantity }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/items/${itemId}`,
        method: 'PUT',
        body: { quantity },
      }),
    }),
    deleteItem: build.mutation<Session.ShopperSessionResponse, RequestDeleteItem>({
      query: ({ awsRegion, customerName, itemId }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/items/${itemId}`,
        method: 'DELETE',
      }),
    }),
    addDiscountCode: build.mutation<Session.ShopperSessionResponse, RequestAddDiscountCode>({
      query: ({ awsRegion, customerName, discountCode }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/discount-codes`,
        method: 'POST',
        body: { discountCode },
      }),
    }),
    deleteDiscountCode: build.mutation<Session.ShopperSessionResponse, RequestDeleteDiscountCode>({
      query: ({ awsRegion, customerName, discountCode }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/discount-codes/${discountCode}`,
        method: 'DELETE',
      }),
    }),
    startCheckout: build.mutation<Session.ShopperSessionResponse, RequestSessionCheckout>({
      query: ({ awsRegion, customerName, paymentProvider, shippingProvider, giftCardProvider }) => ({
        url: `https://shopper.${awsRegion}.${customerName}.brinkcommerce.io/shopper/sessions/checkout/start`,
        method: 'POST',
        body: { paymentProvider, shippingProvider, giftCardProvider },
      }),
    }),
  }),
})
