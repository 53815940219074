import React from "react"
import styled from "styled-components"

import { ReactComponent as EditIcon } from "images/icons/gear.svg"
import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"
import { isSuperUser } from "helpers/user"

const Button = styled.button`
  width: 4rem;
  height: 4rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 2rem;

  svg {
    height: 1.8rem;
    margin-right: 0;
    fill: ${(p) => p.theme.colors.greyDark};
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.colors.white};
    }
  }
`

const EditButton = styled(Button)`
  &:hover {
    background: ${(p) => p.theme.colors.turquoiseDark};
    border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
  }
`

const DeleteButton = styled(Button)`
  &:hover {
    background: ${(p) => p.theme.colors.error.background};
    border: 0.1rem solid ${(p) => p.theme.colors.error.background};
    cursor: ${(p) => (p.disabled ? "initial" : "pointer")};
  }
`

type Props = {
  mode: string
  handleOnClick?: React.MouseEventHandler
}

const ActionButton = ({ mode, handleOnClick, ...props }: Props) => {

  return (
    <>
      {mode === "edit" && (
        <EditButton
          type="button"
          title="Edit"
          onClick={handleOnClick}
          {...props}
        >
          <EditIcon />
        </EditButton>
      )}
      {(mode === "delete" && isSuperUser())&& (
        <DeleteButton
          type="button"
          title="Delete"
          disabled={!isSuperUser()}
          onClick={handleOnClick}
          {...props}
        >
          <DeleteIcon />
        </DeleteButton>
      )}
    </>
  )
}

export default ActionButton
