import styled from "styled-components"
import PageSection from "components/Ui/Page/PageSection"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

type LadderProps = {
  $index: number
}

export const Wrapper = styled.div``

export const Content = styled.div`
  max-width: 100%;
`

export const Header = styled(PageSection)`
  justify-content: space-between;
`

export const AddListButton = styled(PrimaryButton)`
  margin: 0 0 2rem auto;
  align-self: flex-end;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: 0 0 4rem auto;
  }
`

export const LogicRule = styled.div`
  margin-bottom: 4rem;
  background: ${(p) => p.theme.colors.greyLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  flex: 1;
`

export const Flex = styled.div`
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 0 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
`

export const Divider = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 0.1rem;
    border-right: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    height: 100%;
    position: absolute;
    left: 50%;
  }
`

export const LogicTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;

  ${(p) => p.theme.bold}

  h2 {
    flex: 1;
  }
`

export const Logic = styled(Flex)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.3rem;
  padding: 1rem 0;
  display: block;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1rem 3rem;
    display: flex;
  }
`

export const Item = styled.div`
  position: relative;
  user-select: none;
  margin: 0 0 1.5rem 0;
  border-radius: 0.3rem;
  ${(p) => p.theme.bold}
  background: #fff;
  border: 0.1rem solid #ddd;

  svg {
    height: 1.4rem;
    margin-right: 1rem;
  }

  h4 {
    margin: 0;
  }
`
export const Operator = styled(Item)`
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.white};
`

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
`

export const List = styled.div`
  flex: 1;
  align-self: flex-start;
  position: relative;
  padding: 0.5rem 1.5rem 1rem;
  min-height: 10rem;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ActionButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  margin-left: 1.5rem;

  svg {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    fill: rgba(0, 0, 0, 0.6);
  }

  &:hover {
    svg {
      fill: rgba(0, 0, 0, 1);
    }
  }
`

export const DataItems = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.02);
  flex-wrap: wrap;

  &:not(:empty) {
    padding: 2rem;
    border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  }
`

export const DataItem = styled.div`
  padding: 0 1rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  background: ${(p) => p.theme.colors.greyLight};
`

export const DataItemCondition = styled(DataItem)`
  background: rgb(255, 205, 121);
`

export const DataItemOutcome = styled(DataItem)`
  background: rgb(57, 179, 155);
  color: ${(p) => p.theme.colors.white};
  margin-bottom: 0.5rem;
`

export const AddButtonWrapper = styled.div`
  border-radius: 2.5rem;
  padding: 0.5rem;
  z-index: 1;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
`

export const EmptyContainer = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1rem 3rem;
  border-radius: 0.3rem;
  margin-bottom: 1rem;

  p {
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
    ${(p) => p.theme.bold};
  }
`

export const AddButton = styled(PrimaryButton)`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 1.8rem !important;
  padding: 0;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  transition: 0.2s;

  svg {
    fill: ${(p) => p.theme.colors.black};
    margin-right: 0 !important;
    height: 1.8rem;
    width: 1.8rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    &:hover {
      background: ${(p) => p.theme.colors.turquoiseDark};
      border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};

      svg {
        fill: ${(p) => p.theme.colors.white};
      }
    }
  }
`

export const DisabledAddButton = styled(AddButton)`
  background: ${(p) => p.theme.colors.white} !important;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  opacity: 0.5;

  &:before {
    display: none;
  }

  svg {
    fill: ${(p) => p.theme.colors.greyDark};
  }

  &:hover {
    opacity: 0.5;
    background: ${(p) => p.theme.colors.white} !important;
    border: 0.1rem solid ${(p) => p.theme.colors.greyLight} !important;

    svg {
      fill: ${(p) => p.theme.colors.greyDark};
    }
  }
`

export const Container = styled.div`
  display: flex;
`

export const LadderContainer = styled.div<LadderProps>`
  position: relative;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: calc(5rem * ${(p) => p.$index});
  }
`

export const Ladder = styled.div<LadderProps>`
  display: none;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: block;
    position: absolute;
    width: 3rem;
    border-left: 0.2rem solid ${(p) => p.theme.colors.greyDark};
    border-bottom: 0.2rem solid ${(p) => p.theme.colors.greyDark};
    height: calc(50% + 0rem);
    bottom: calc(50% + 2rem);
    left: calc(5rem * ${(p) => p.$index - 1});
  }
`
