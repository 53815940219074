import React from "react"
import styled from "styled-components"
import { Tooltip } from "./Tooltip"
import { ReactComponent as InfoIcon } from "images/icons/circle-info.svg"

const Wrapper = styled.div`
  z-index: 10000;

  i {
    height: 1.8rem;
    display: inline-flex;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.6rem;
    fill: ${(p) => p.theme.colors.black};
  }
`

const StyledTooltip = styled(Tooltip)`
  margin-left: 0.3rem;
  margin-bottom: 1rem !important;
  padding-top: 1rem !important;
`

type Props = {
  content: string
}

export const TooltipIcon = ({ content, ...props }: Props) => (
  <Wrapper>
    <i
      data-tooltip-id="tooltip"
      data-tooltip-content={content}
      data-tooltip-delay-hide={100}
      {...props}
    >
      <InfoIcon />
    </i>
    <StyledTooltip id="tooltip" />
  </Wrapper>
)
