import styled from "styled-components"

export const CartContainer = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  background: ${(p) => p.theme.colors.white};
  border-radius: 0.5rem;
  position: relative;
  margin-bottom: 2rem;
  padding: 0 1.5rem 2rem;

  div[class*="-MenuList"] {
    padding: 0;
  }

  div[class*="-singleValue"],
  div[class*="-option"] {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    svg {
      width: 3.4rem;
    }
  }
  div[class*="-placeholder"] {
    padding-left: 2rem;
  }

  div[class*="-option"] {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};
    height: 5rem;
    padding: 0 2rem 0 0.3rem;

    &:last-of-type {
      border-bottom: none;
    }
  }

  div[class*="-control"] {
    height: 5rem;
    padding: 0;
  }

  div[class*="-ValueContainer"] {
    padding: 0;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 0 2.5rem 3rem;
  }
`

export const Error = styled.div`
color: ${(p) => p.theme.colors.errorText};
  font-size: 1.2rem;
  padding-bottom: 1rem;
  word-break: break-word;
`

export const EmptyContainer = styled(CartContainer)`
  text-align: center;
  padding-top: 2rem;

  h2 {
    margin-bottom: 1rem;
  }
`

export const Label = styled.h4`
  margin: 0;
`

export const CartItems = styled.div`
  padding: 0;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  margin-bottom: 2rem;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 1rem;
  }
`
