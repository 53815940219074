import styled from "styled-components"
import QuickAdd from "../../../../../Ui/Table/QuickAdd"
import PrimaryButton from "../../../../../Ui/Buttons/PrimaryButton"
import TableRowSimple from "../../../../../Ui/Table/TableRowSimple"
import SingleSelect from "../../../../../Ui/Form/SingleSelect"



export const Container = styled.div`
  margin-right: 0;
  padding-bottom: 1rem;

  > div {
    display: block;
  }
`

export const Add = styled(QuickAdd)`
  background: transparent;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
  margin-bottom: 1rem;
  border-radius: 0;
`

export const AddButton = styled(PrimaryButton)`
  height: 3.5rem !important ;
  width: 8rem;
  position: absolute;
  right: 0;
  top: 12rem;
`

export const FixedPrice = styled.div`
  width: 100%;

  p {
    text-align: center;
  }
`


export const FixedPriceRow = styled(TableRowSimple)`
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 0 2rem 1rem;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
`

export const FixedPriceCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-right: 1rem;
  font-weight: 700;

`

export const Currency = styled(FixedPriceCell)`
  width: 50%;
  flex: auto;
`

export const Amount = styled(FixedPriceCell)`
  flex: auto;
  width: calc(50% - 8rem);
`

export const InputWrapper = styled.div`
display: flex;
  flex-direction: column;
  position: relative;

`

export const CurrencySelect = styled(SingleSelect)`
  margin-bottom: 1rem;
`

export const Formatted = styled.div`
  margin: 1rem;
`
