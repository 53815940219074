import React, { Dispatch, SetStateAction, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_STORE_GROUP from "graphql/mutations/store/CreateStoreGroup"

import Input from "components/Ui/Form/Input"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Box from "components/Ui/Box"
import EditSidebar from "../../../components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "../../../components/Ui/EditSidebar/EditSidebarHeader"
import styled from "styled-components"
import { ReactComponent as CompanyIcon } from "images/icons/boxes-packing.svg"
import { ReactComponent as ConsumerIcon } from "images/icons/users.svg"
import SingleSelect from "../../../components/Ui/Form/SingleSelect"
import { countryOptions } from "../../../helpers/countries"
import { ChannelType } from "lib/types/generated/graphql-types"
import { TooltipIcon } from "components/Ui/TooltipIcon"

const TypeButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 2rem;
  height: 6rem;
  font-size: 1.3rem;

  svg {
    height: 2.4rem;
    margin-right: 2rem;
  }

  span {
    width: 100%;
    display: flex;
  }
`

const ChannelTypeWrapper = styled.div`
  display: flex;
  font-weight: 700;

  svg {
    width: 3rem;
    margin-right: 2rem;
  }
`

type Inputs = {
  id: string
  name: string
  description: string
  countryOfSale: string
}

type Props = {
  setEditId: SetStateAction<Dispatch<string>>
  refetch: () => void
}

export function CreateStoreGroup({ setEditId, refetch }: Props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset
  } = useForm<Inputs>()
  const [channelType, setChannelType] = useState<ChannelType | undefined>()
  const dispatch = useAppDispatch()

  const [addStoreGroup, { loading: createLoading }] = useMutation(CREATE_STORE_GROUP, {
    onCompleted: (response) => {
      setEditId(response.createStoreGroup.id)
      dispatch(
        alertActions.actions.createAlert({
          message: "Store Group successfully created",
          type: "success"
        })
      )
      reset({
        id: "",
        name: "",
        description: ""
      })
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) =>
    addStoreGroup({
      variables: { ...data, id: data.id.replaceAll(" ", ""), channelType: channelType }
    })

  const getLabel = (type: ChannelType) => {
    if (type === ChannelType.B2C) {
      return "Business-to-Consumer (B2C)"
    } else {
      return "Business-to-Business (B2B)"
    }
  }

  if (!channelType)
    return (
      <EditSidebar>
        <Box padding="0" margin="0">
          <EditSidebarHeader title="New store group"></EditSidebarHeader>
          <h4>Choose channel type: </h4>
          <TypeButton handleClick={() => setChannelType(ChannelType.B2C)}>
            <ConsumerIcon />
            {getLabel(ChannelType.B2C)}
          </TypeButton>
          <TypeButton handleClick={() => setChannelType(ChannelType.B2B)}>
            <CompanyIcon />
            {getLabel(ChannelType.B2B)}
          </TypeButton>
        </Box>
      </EditSidebar>
    )

  return (
    <EditSidebar>
      <Box padding="0" margin="0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditSidebarHeader title="New store group" cancelEvent={() => setChannelType(undefined)}>
            <PrimaryButton type="submit" handleClick={handleSubmit(onSubmit)} loading={createLoading}>
              Save
            </PrimaryButton>
          </EditSidebarHeader>
          <ChannelTypeWrapper>
            {channelType === ChannelType.B2C ? <ConsumerIcon /> : <CompanyIcon />}
            {getLabel(channelType)}
          </ChannelTypeWrapper>

          <hr />
          <Controller
            name="id"
            render={({ field }) => (
              <Input {...field} type="text" label="Identifier *" placeholder="Id" errors={errors} />
            )}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="name"
            render={({ field }) => <Input {...field} type="text" label="Name *" placeholder="Name" errors={errors} />}
            control={control}
            defaultValue=""
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="description"
            render={({ field }) => (
              <Input {...field} type="text" label="Description *" placeholder="Description" errors={errors} />
            )}
            control={control}
            defaultValue=""
            rules={{
              required: "This is a required field"
            }}
          />
          {channelType === "B2B" && (
            <Controller
              name="countryOfSale"
              render={({ field }) => (
                <>
                  <SingleSelect
                    {...field}
                    label={
                      <>
                        {"Country of sale *"}
                        <TooltipIcon content="The country from which the Legal Entity making the sale is registered." />
                      </>
                    }
                    options={countryOptions}
                    setValue={(option) => setValue("countryOfSale", option.value)}
                    defaultValue={{ label: "Select country", value: "" }}
                  />
                </>
              )}
              rules={{
                required: "This is a required field"
              }}
              control={control}
            />
          )}
        </form>
      </Box>
    </EditSidebar>
  )
}
