import { OrderRefund } from "@lib/types/generated/graphql-types"
import { Info } from "./Operations.styled"
import Money from "../../../Money"
import DineroFactory from "dinero.js"

type Props = {
  refund: OrderRefund | undefined
  status: string
  currencyCode: string
}

const RefundInfo = ({ refund, status, currencyCode }: Props) => {
  if (status === "REFUND_PAYMENT_SUCCESS") {
    return (
      <Info>
        <b>
          <Money
            amount={refund?.totals?.grandTotal ?? 0}
            currencyUnit={currencyCode as DineroFactory.Currency}
          />
        </b>
        <div>{refund?.refundedPayment?.reference}</div>
        <div>{refund?.paymentProvider?.providerName}</div>
      </Info>
    )
  }
  return null
}

export default RefundInfo
