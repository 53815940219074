import * as graphql from "lib/types/generated/graphql-types"
import FailedDelivery from "./FailedDelivery"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import React, { useState } from "react"
import DeliveryAction from "./Cart/DeliveryAction"
import StatusPopup from "./Cart/StatusPopup"
import PendingDelivery from "./PendingDelivery"
import { Delivery } from "../Helpers/Delivery"

type Props = {
  order: graphql.Order
  refetch: () => void
  loading: boolean
  orderHistory: graphql.OrderHistory
}

const DeliveryOrder = ({ order, refetch, loading, orderHistory }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedDelivery, setStartedDelivery] = useState("")
  const deliveries = new Delivery(order?.Deliveries)
  const filteredDeliveries = order?.Deliveries?.filter(
    (delivery) => !delivery.completed && !delivery.started
  )

  const [giftCardAction, setGiftCardAction] = useState<graphql.InputActionGiftCard>({
    actionType: graphql.InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<graphql.InputActionBonus>({
    actionType: graphql.InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<graphql.InputActionPaymentDelivery>({
    actionType: graphql.InputActionType.Auto
  })
  const [shippingAction, setShippingAction] = useState<graphql.InputActionShipping>({
    actionType: graphql.InputActionType.Auto
  })

  if (deliveries.pending() && !showStatusPopup)
    return (
      <PendingDelivery pendingDelivery={deliveries.getPendingDelivery()} orderRefetch={refetch} orderLoading={loading}/>
    )
  return (
    <>
      {showStatusPopup && startedDelivery && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          deliveryId={startedDelivery}
          refetchOrder={refetch}
        />
      )}
      {deliveries.failed() ? (
        deliveries.failedDeliveries().map((delivery: graphql.OrderDelivery) => (
          <FailedDelivery
            key={delivery.id}
            failedDelivery={delivery}
            currencyCode={order.currencyCode}
            setShowStatusPopup={setShowStatusPopup}
            setStartedDelivery={setStartedDelivery}
            orderHistory={orderHistory.history}
            refetch={refetch}
          />
        ))
      ) : !showConfirmation ? (
        <Container>
          <div>
            <OrderSummary order={order} refetch={refetch} orderLoading={loading} />
          </div>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation} orderLoading={loading} />
          </div>
        </Container>
      ) : (
        <Container>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation} orderLoading={loading} />
          </div>
          <div>
            {filteredDeliveries?.map((delivery) => (
              <DeliveryAction
                key={delivery.id}
                delivery={delivery}
                actionState={{
                  giftCardAction,
                  setGiftCardAction,
                  bonusAction,
                  setBonusAction,
                  paymentAction,
                  setPaymentAction,
                  shippingAction,
                  setShippingAction
                }}
                orderId={order.id}
                setShowStatusPopup={setShowStatusPopup}
                setStartedDelivery={setStartedDelivery}
                setShowConfirmation={setShowConfirmation}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default DeliveryOrder
