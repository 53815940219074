import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../../"

const selectOrder = (state: RootState): { orderLoading: boolean } =>
  state.order

export const orderLoading  = createSelector(
  selectOrder,
  (orderLoading) => orderLoading
)
