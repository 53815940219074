import styled from "styled-components"
import { List } from "../../../../Ui/List/List"

export const Container = styled.div`
  background: ${p => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem;
`

export const Summary = styled(List)`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  margin: 0 0 4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 3rem;
  }
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  min-height: 5rem;
`

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`

export const Error = styled.div`
  font-size: 1.2rem;
  color: ${(p) => p.theme.colors.errorText};
`

export const ButtonWrapper = styled.div`
margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const OrderLinesLabel = styled.b`
margin-bottom: 1rem ;
`
