import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: any; output: any; }
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: { input: any; output: any; }
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: { input: any; output: any; }
};

export type Actor = {
  __typename?: 'Actor';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AddExternalEventsDestinationApiResult = {
  __typename?: 'AddExternalEventsDestinationAPIResult';
  apiDestinationName: Scalars['String']['output'];
};

export type AddExternalEventsDestinationEventBusResult = {
  __typename?: 'AddExternalEventsDestinationEventBusResult';
  eventBusArn: Scalars['String']['output'];
};

export type AddExternalEventsRuleResult = {
  __typename?: 'AddExternalEventsRuleResult';
  ruleName: Scalars['String']['output'];
};

export type AdyenConfig = {
  __typename?: 'AdyenConfig';
  clientKey: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: AdyenCredentials;
  environment: AdyenEnvironment;
  id: Scalars['ID']['output'];
  liveEndpointUrlPrefix?: Maybe<Scalars['String']['output']>;
  merchantAccount: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
  webhooks?: Maybe<Array<AdyenWebhook>>;
};

export type AdyenCredentials = {
  __typename?: 'AdyenCredentials';
  apiKey: Scalars['String']['output'];
  /** @deprecated will be moved to AdyenWebhookCredentials.hmac instead */
  hmac?: Maybe<Scalars['String']['output']>;
};

export enum AdyenEnvironment {
  Live = 'LIVE',
  Test = 'TEST'
}

export type AdyenWebhook = {
  __typename?: 'AdyenWebhook';
  configId?: Maybe<Scalars['String']['output']>;
  created: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sequenceNumber: Scalars['Int']['output'];
  status?: Maybe<AdyenWebhookStatus>;
  target?: Maybe<AdyenWebhookTarget>;
  tested?: Maybe<Scalars['AWSDateTime']['output']>;
  updated: Scalars['AWSDateTime']['output'];
  username: Scalars['String']['output'];
};

export type AdyenWebhookCredentials = {
  __typename?: 'AdyenWebhookCredentials';
  hmac?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type AdyenWebhookGenerationResult = {
  __typename?: 'AdyenWebhookGenerationResult';
  started: Scalars['Boolean']['output'];
};

export type AdyenWebhookLegacy = {
  __typename?: 'AdyenWebhookLegacy';
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  updated: Scalars['AWSDateTime']['output'];
  username: Scalars['String']['output'];
};

export enum AdyenWebhookStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum AdyenWebhookTarget {
  OrderManagement = 'OrderManagement',
  Shopper = 'Shopper'
}

export type ApiClient = {
  __typename?: 'ApiClient';
  clientId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ApiClientDetails = {
  __typename?: 'ApiClientDetails';
  clientId: Scalars['ID']['output'];
  clientSecret?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['AWSDateTime']['output']>;
  name: Scalars['String']['output'];
  scopes?: Maybe<Array<Scalars['String']['output']>>;
  updated?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type ApiClientsList = {
  __typename?: 'ApiClientsList';
  apiClients: Array<ApiClient>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ApiResource = {
  __typename?: 'ApiResource';
  identifier: Scalars['String']['output'];
  scopes?: Maybe<Array<ApiScope>>;
};

export type ApiScope = {
  __typename?: 'ApiScope';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type AvardaConfig = {
  __typename?: 'AvardaConfig';
  authUrl: Scalars['String']['output'];
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: AvardaCredentials;
  id: Scalars['ID']['output'];
  shippingBroker?: Maybe<AvardaShippingBroker>;
  updated: Scalars['AWSDateTime']['output'];
};

export type AvardaCredentials = {
  __typename?: 'AvardaCredentials';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type AvardaShippingBroker = {
  __typename?: 'AvardaShippingBroker';
  name: Scalars['String']['output'];
};

export type AvardaShippingBrokerShipping = {
  __typename?: 'AvardaShippingBrokerShipping';
  ingrid?: Maybe<AvardaShippingBrokerShippingIngrid>;
};

export type AvardaShippingBrokerShippingIngrid = {
  __typename?: 'AvardaShippingBrokerShippingIngrid';
  carrier: Scalars['String']['output'];
  category?: Maybe<AvardaShippingBrokerShippingIngridCategory>;
  currencyCode: Scalars['String']['output'];
  deliveryType: Scalars['String']['output'];
  externalMethodId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<AvardaShippingBrokerShippingIngridLocation>;
  price: Scalars['Float']['output'];
  product: Scalars['String']['output'];
  shippingMethod: Scalars['String']['output'];
  taxAmount: Scalars['Int']['output'];
  taxGroupId: Scalars['String']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  tosId: Scalars['String']['output'];
};

export type AvardaShippingBrokerShippingIngridCategory = {
  __typename?: 'AvardaShippingBrokerShippingIngridCategory';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tags?: Maybe<Array<AvardaShippingBrokerShippingIngridCategoryTag>>;
};

export type AvardaShippingBrokerShippingIngridCategoryTag = {
  __typename?: 'AvardaShippingBrokerShippingIngridCategoryTag';
  name: Scalars['String']['output'];
};

export type AvardaShippingBrokerShippingIngridLocation = {
  __typename?: 'AvardaShippingBrokerShippingIngridLocation';
  address: AvardaShippingBrokerShippingIngridLocationAddress;
  externalId: Scalars['String']['output'];
};

export type AvardaShippingBrokerShippingIngridLocationAddress = {
  __typename?: 'AvardaShippingBrokerShippingIngridLocationAddress';
  addressLines: Array<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type BsgGiftCardConfig = {
  __typename?: 'BSGGiftCardConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: BsgGiftCardCredentials;
  id: Scalars['ID']['output'];
  scopes: Array<Scalars['String']['output']>;
  tokenUrl: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type BsgGiftCardCredentials = {
  __typename?: 'BSGGiftCardCredentials';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type BonusConfig = {
  __typename?: 'BonusConfig';
  authUrl: Scalars['String']['output'];
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: BonusCredentials;
  id: Scalars['ID']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type BonusCredentials = {
  __typename?: 'BonusCredentials';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type BonusProvider = {
  __typename?: 'BonusProvider';
  providerId: Scalars['String']['output'];
  providerName: BonusProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum BonusProviderName {
  Bonus = 'Bonus'
}

export type Bundle = {
  __typename?: 'Bundle';
  discountValue?: Maybe<DiscountValue>;
  id: Scalars['String']['output'];
  itemRules: Array<BundleItemRule>;
};

export type BundleGroup = {
  __typename?: 'BundleGroup';
  bundles: Array<Bundle>;
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  storeMarkets: Array<Maybe<BundleStoreMarket>>;
  updated: Scalars['AWSDateTime']['output'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type BundleGroupSearchHit = {
  __typename?: 'BundleGroupSearchHit';
  bundles: Array<Scalars['String']['output']>;
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validTo?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type BundleGroupSearchHits = {
  __typename?: 'BundleGroupSearchHits';
  hits: Array<BundleGroupSearchHit>;
  total: Scalars['Int']['output'];
};

export type BundleItemRule = {
  __typename?: 'BundleItemRule';
  discountValue?: Maybe<DiscountValue>;
  excludeDiscountedPrices: Scalars['Boolean']['output'];
  excludeFullPrices: Scalars['Boolean']['output'];
  productParents: Array<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  tagConditions: Array<TagCondition>;
};

export type BundleOutcome = {
  __typename?: 'BundleOutcome';
  bundleGroupId?: Maybe<Scalars['String']['output']>;
  bundleId?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
};

export type BundleStoreMarket = {
  __typename?: 'BundleStoreMarket';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  campaignGroupId: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  endDate: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceRule: PriceRule;
  productVariantRules: ProductVariantRules;
  roundingRules?: Maybe<RoundingRules>;
  startDate: Scalars['AWSDateTime']['output'];
  storeMarkets: Array<CampaignStoreMarket>;
  updated: Scalars['AWSDateTime']['output'];
};

export type CampaignDeletionResult = {
  __typename?: 'CampaignDeletionResult';
  result: Scalars['String']['output'];
};

export type CampaignGroup = {
  __typename?: 'CampaignGroup';
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type CampaignGroupList = {
  __typename?: 'CampaignGroupList';
  campaignGroup: CampaignGroup;
  campaigns: Array<Campaign>;
};

export type CampaignGroupSearchHit = {
  __typename?: 'CampaignGroupSearchHit';
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type CampaignGroupSearchHits = {
  __typename?: 'CampaignGroupSearchHits';
  hits: Array<CampaignGroupSearchHit>;
  total: Scalars['Int']['output'];
};

export type CampaignIdentity = {
  __typename?: 'CampaignIdentity';
  campaignGroupId: Scalars['ID']['output'];
  campaignId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CampaignMoney = {
  __typename?: 'CampaignMoney';
  key: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type CampaignSearchHit = {
  __typename?: 'CampaignSearchHit';
  campaignGroupId: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  endDate: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['AWSDateTime']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type CampaignSearchHits = {
  __typename?: 'CampaignSearchHits';
  hits: Array<CampaignSearchHit>;
  total: Scalars['Int']['output'];
};

export type CampaignStoreMarket = {
  __typename?: 'CampaignStoreMarket';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type CancellationBonus = {
  __typename?: 'CancellationBonus';
  reservationId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CancellationGiftCard = {
  __typename?: 'CancellationGiftCard';
  giftCardId: Scalars['ID']['output'];
  reservationId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type CancellationGiftCardProduct = {
  __typename?: 'CancellationGiftCardProduct';
  cancelTransactionId?: Maybe<Scalars['String']['output']>;
  giftCardProductId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type CancelledPayment = {
  __typename?: 'CancelledPayment';
  reference: Scalars['String']['output'];
};

export type Capabilities = {
  __typename?: 'Capabilities';
  giftCardProviders?: Maybe<Array<Provider>>;
  paymentProviders?: Maybe<Array<Provider>>;
  shippingProviders?: Maybe<Array<Provider>>;
};

export type CapabilityProvider = {
  __typename?: 'CapabilityProvider';
  id: Scalars['ID']['output'];
};

export type CartCapabilities = {
  __typename?: 'CartCapabilities';
  bonus?: Maybe<CapabilityProvider>;
  retain24?: Maybe<CapabilityProvider>;
  voyado?: Maybe<CapabilityProvider>;
};

export type CartRuleOutcome = {
  __typename?: 'CartRuleOutcome';
  discountAmount?: Maybe<Scalars['Int']['output']>;
  ruleID?: Maybe<Scalars['String']['output']>;
};

export enum ChannelType {
  B2B = 'B2B',
  B2C = 'B2C'
}

export type CodeRuleOutcome = {
  __typename?: 'CodeRuleOutcome';
  code?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  ruleID?: Maybe<Scalars['String']['output']>;
};

export type CodesGenerationSearchHit = {
  __typename?: 'CodesGenerationSearchHit';
  created: Scalars['AWSDateTime']['output'];
  discountCodeRuleId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  numberOfCodesGenerated: Scalars['Int']['output'];
  numberOfCodesRequested: Scalars['Int']['output'];
  prefix: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
  usageLimit: Scalars['Int']['output'];
};

export type CodesGenerationSearchHits = {
  __typename?: 'CodesGenerationSearchHits';
  hits: Array<CodesGenerationSearchHit>;
  total: Scalars['Int']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  text: Scalars['String']['output'];
};

export type CompanyAttribute = {
  __typename?: 'CompanyAttribute';
  name?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

export type CompensationBonus = {
  __typename?: 'CompensationBonus';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  reservationId: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
};

export type CompensationFee = {
  __typename?: 'CompensationFee';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  taxAmount: Scalars['Int']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
};

export type CompensationGiftCard = {
  __typename?: 'CompensationGiftCard';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  giftCardId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type CompensationOrderLine = {
  __typename?: 'CompensationOrderLine';
  currencyCode: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type CompensationPayment = {
  __typename?: 'CompensationPayment';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type CompensationShippingFee = {
  __typename?: 'CompensationShippingFee';
  currencyCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shippingFeeId: Scalars['ID']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['ID']['output'];
  identifiers?: Maybe<Array<Scalars['String']['output']>>;
  itemRule?: Maybe<ItemRule>;
  thresholds?: Maybe<Array<Money>>;
  triggerQuantity?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type CreateGenerateCodesResult = {
  __typename?: 'CreateGenerateCodesResult';
  jobId: Scalars['String']['output'];
};

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  currencyCode: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type CurrencyRates = {
  __typename?: 'CurrencyRates';
  baseCurrencyCode: Scalars['String']['output'];
  rates: Array<CurrencyRate>;
};

export enum CustomOperation {
  Cancellation = 'CANCELLATION',
  Compensation = 'COMPENSATION',
  Delivery = 'DELIVERY',
  ModifyOrderLines = 'MODIFY_ORDER_LINES',
  Refund = 'REFUND',
  Release = 'RELEASE'
}

export type CustomReasonCode = {
  __typename?: 'CustomReasonCode';
  code: Scalars['ID']['output'];
  created: Scalars['AWSDateTime']['output'];
  label?: Maybe<Scalars['String']['output']>;
  revision: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type CustomReasonCodes = {
  __typename?: 'CustomReasonCodes';
  codes: Array<Maybe<CustomReasonCode>>;
};

export type CustomState = {
  __typename?: 'CustomState';
  created: Scalars['AWSDateTime']['output'];
  deleted?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  operations?: Maybe<Array<Scalars['String']['output']>>;
  revision: Scalars['Int']['output'];
  type: CustomStateType;
  updated: Scalars['AWSDateTime']['output'];
};

export enum CustomStateType {
  Blocking = 'BLOCKING',
  Informative = 'INFORMATIVE'
}

export type CustomStates = {
  __typename?: 'CustomStates';
  states: Array<Maybe<CustomState>>;
};

export type DeletedResult = {
  __typename?: 'DeletedResult';
  status: Scalars['String']['output'];
};

export type DeliveryBonus = {
  __typename?: 'DeliveryBonus';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  reservationId: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
};

export type DeliveryCapture = {
  __typename?: 'DeliveryCapture';
  reference: Scalars['String']['output'];
};

export type DeliveryGift = {
  __typename?: 'DeliveryGift';
  giftId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export type DeliveryGiftCard = {
  __typename?: 'DeliveryGiftCard';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  giftCardId: Scalars['ID']['output'];
  reservationId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type DeliveryGiftCardProduct = {
  __typename?: 'DeliveryGiftCardProduct';
  createFailedReason?: Maybe<Scalars['String']['output']>;
  createId?: Maybe<Scalars['String']['output']>;
  createTransactionId?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  giftCardProductId: Scalars['ID']['output'];
  priceAmount: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type DeliveryOrderLine = {
  __typename?: 'DeliveryOrderLine';
  currencyCode: Scalars['String']['output'];
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type DeliveryShippingFee = {
  __typename?: 'DeliveryShippingFee';
  currencyCode: Scalars['String']['output'];
  shippingFeeId: Scalars['ID']['output'];
  totalAmount: Scalars['Int']['output'];
};

export type DeliveryTracking = {
  __typename?: 'DeliveryTracking';
  reference: Scalars['String']['output'];
  shippingCompany: Scalars['String']['output'];
  shippingMethod: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DeliveryVoucher = {
  __typename?: 'DeliveryVoucher';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  voucherId: Scalars['ID']['output'];
};

export type Dimensions = {
  __typename?: 'Dimensions';
  height?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  code: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  discountCodeRuleId: Scalars['String']['output'];
  isUnlimited: Scalars['Boolean']['output'];
  updated: Scalars['AWSDateTime']['output'];
  usageLimit?: Maybe<Scalars['Int']['output']>;
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountCodeRule = {
  __typename?: 'DiscountCodeRule';
  applyLast: Scalars['Boolean']['output'];
  conditions: Array<Condition>;
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isStackable: Scalars['Boolean']['output'];
  logicRules: Array<LogicHolder>;
  name: Scalars['String']['output'];
  outcomes: Array<Outcome>;
  sortOrder: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountCodeRuleSearchHit = {
  __typename?: 'DiscountCodeRuleSearchHit';
  applyLast: Scalars['Boolean']['output'];
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isStackable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validTo?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type DiscountCodeRuleSearchHits = {
  __typename?: 'DiscountCodeRuleSearchHits';
  hits: Array<DiscountCodeRuleSearchHit>;
  total: Scalars['Int']['output'];
};

export type DiscountCodeSearchHit = {
  __typename?: 'DiscountCodeSearchHit';
  code: Scalars['ID']['output'];
  created: Scalars['AWSDateTime']['output'];
  discountCodeRuleId: Scalars['String']['output'];
  isUnlimited: Scalars['Boolean']['output'];
  updated: Scalars['AWSDateTime']['output'];
  usageLimit?: Maybe<Scalars['Int']['output']>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validTo?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type DiscountCodeSearchHits = {
  __typename?: 'DiscountCodeSearchHits';
  hits: Array<DiscountCodeSearchHit>;
  total: Scalars['Int']['output'];
};

export type DiscountExternalRule = {
  __typename?: 'DiscountExternalRule';
  applyLast: Scalars['Boolean']['output'];
  conditions: Array<Condition>;
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isStackable: Scalars['Boolean']['output'];
  logicRules: Array<LogicHolder>;
  name: Scalars['String']['output'];
  outcomes: Array<Outcome>;
  sortOrder: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountExternalRuleSearchHit = {
  __typename?: 'DiscountExternalRuleSearchHit';
  applyLast: Scalars['Boolean']['output'];
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isStackable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validTo?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type DiscountExternalRuleSearchHits = {
  __typename?: 'DiscountExternalRuleSearchHits';
  hits: Array<DiscountExternalRuleSearchHit>;
  total: Scalars['Int']['output'];
};

export type DiscountManagementQuotas = {
  __typename?: 'DiscountManagementQuotas';
  maxGenerationJobsPerDay: Scalars['Int']['output'];
  maxNumberOfCodes: Scalars['Int']['output'];
};

export type DiscountRule = {
  __typename?: 'DiscountRule';
  conditions: Array<Condition>;
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isStackable: Scalars['Boolean']['output'];
  logicRules: Array<LogicHolder>;
  name: Scalars['String']['output'];
  outcomes: Array<Outcome>;
  sortOrder: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountRuleSearchHit = {
  __typename?: 'DiscountRuleSearchHit';
  created: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isStackable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validTo?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type DiscountRuleSearchHits = {
  __typename?: 'DiscountRuleSearchHits';
  hits: Array<DiscountRuleSearchHit>;
  total: Scalars['Int']['output'];
};

export type DiscountValue = {
  __typename?: 'DiscountValue';
  fixedDiscounts?: Maybe<Array<Money>>;
  percentage?: Maybe<Scalars['Int']['output']>;
  percentageDecimals?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type ExternalApi = {
  __typename?: 'ExternalApi';
  discountManagementQuotas?: Maybe<DiscountManagementQuotas>;
  usagePlans: Array<UsagePlan>;
};

export type ExternalEventDestinationApi = {
  __typename?: 'ExternalEventDestinationAPI';
  active: Scalars['Boolean']['output'];
  created: Scalars['AWSDateTime']['output'];
  endpoint?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<ExternalEventDestinationApiFilter>>;
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type ExternalEventDestinationApiFilter = {
  __typename?: 'ExternalEventDestinationAPIFilter';
  pattern?: Maybe<ExternalEventDestinationApiFilterPattern>;
  ruleName: Scalars['String']['output'];
};

export type ExternalEventDestinationApiFilterPattern = {
  __typename?: 'ExternalEventDestinationAPIFilterPattern';
  detailTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type ExternalEventDestinationApIs = {
  __typename?: 'ExternalEventDestinationAPIs';
  apiDestinations: Array<Scalars['String']['output']>;
  apiDestinationsDetails: Array<ExternalEventDestinationsDetails>;
};

export type ExternalEventDestinationEventBuses = {
  __typename?: 'ExternalEventDestinationEventBuses';
  eventBuses: Array<Scalars['String']['output']>;
};

export type ExternalEventDestinationsDetails = {
  __typename?: 'ExternalEventDestinationsDetails';
  active: Scalars['Boolean']['output'];
  created: Scalars['AWSDateTime']['output'];
  endpoint?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type ExternalEventRuleState = {
  __typename?: 'ExternalEventRuleState';
  ruleName: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type ExternalEventRules = {
  __typename?: 'ExternalEventRules';
  rules: Array<ExternalEventRuleState>;
};

export type ExternalRuleOutcome = {
  __typename?: 'ExternalRuleOutcome';
  discountAmount?: Maybe<Scalars['Int']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  ruleID?: Maybe<Scalars['String']['output']>;
};

export type GenerateCodes = {
  __typename?: 'GenerateCodes';
  created: Scalars['AWSDateTime']['output'];
  discountCodeRuleId: Scalars['String']['output'];
  fileUrl?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
  numberOfCodesGenerated?: Maybe<Scalars['Int']['output']>;
  numberOfCodesRequested: Scalars['Int']['output'];
  prefix: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
  usageLimit: Scalars['Int']['output'];
};

export type Gift = {
  __typename?: 'Gift';
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type GiftCardProductProvider = {
  __typename?: 'GiftCardProductProvider';
  providerId: Scalars['String']['output'];
  providerName: GiftCardProductProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum GiftCardProductProviderName {
  Retain24 = 'Retain24'
}

export type GiftCardProvider = {
  __typename?: 'GiftCardProvider';
  providerId: Scalars['String']['output'];
  providerName: GiftCardProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum GiftCardProviderName {
  BsgGiftCard = 'BsgGiftCard',
  KbsGiftCard = 'KbsGiftCard',
  Retain24 = 'Retain24'
}

export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['ID']['output'];
};

export type GroupDetails = {
  __typename?: 'GroupDetails';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['ID']['output'];
  users: Array<User>;
};

export type GroupsList = {
  __typename?: 'GroupsList';
  groups: Array<Group>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type IngridConfig = {
  __typename?: 'IngridConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: IngridCredentials;
  id: Scalars['ID']['output'];
  siteId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type IngridCredentials = {
  __typename?: 'IngridCredentials';
  apiKey: Scalars['String']['output'];
};

export type IngridDelivery = {
  __typename?: 'IngridDelivery';
  carrierProductId?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<IngridDeliveryAddress>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  locationExternalId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  taxAmount?: Maybe<Scalars['Int']['output']>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']['output']>;
  tosId?: Maybe<Scalars['String']['output']>;
};

export type IngridDeliveryAddress = {
  __typename?: 'IngridDeliveryAddress';
  addressLines?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type InputActionBonus = {
  actionType: InputActionType;
};

export type InputActionGiftCard = {
  actionType: InputActionType;
  giftCards?: InputMaybe<Array<InputActionGiftCardManual>>;
};

export type InputActionGiftCardManual = {
  giftCardId?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type InputActionPaymentCancellation = {
  actionType: InputActionType;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type InputActionPaymentCompensation = {
  actionType: InputActionType;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type InputActionPaymentDelivery = {
  actionType: InputActionType;
  captureReference?: InputMaybe<Scalars['String']['input']>;
};

export type InputActionPaymentOrderModificationOrderLines = {
  actionType: InputActionType;
};

export type InputActionPaymentRefund = {
  actionType: InputActionType;
  refundReference?: InputMaybe<Scalars['String']['input']>;
};

export type InputActionPaymentRelease = {
  actionType: InputActionType;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type InputActionShipping = {
  actionType: InputActionType;
  shippingCompany?: InputMaybe<Scalars['String']['input']>;
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
  trackingReference?: InputMaybe<Scalars['String']['input']>;
  trackingUrl?: InputMaybe<Scalars['String']['input']>;
};

export enum InputActionType {
  Auto = 'AUTO',
  Manual = 'MANUAL',
  Skip = 'SKIP'
}

export type InputAddExternalEventsDestinationApi = {
  apiDestinationEndpoint: Scalars['String']['input'];
  apiKeyAuth?: InputMaybe<InputExternalEventsApiKeyAuth>;
  basicAuth?: InputMaybe<InputExternalEventsBasicAuth>;
  filterEventRules?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputAddUserToGroup = {
  groupname: Scalars['String']['input'];
  username: Scalars['AWSEmail']['input'];
};

export type InputAdyenConfig = {
  apiKey: Scalars['String']['input'];
  clientKey: Scalars['String']['input'];
  environment: AdyenEnvironment;
  hmac?: InputMaybe<Scalars['String']['input']>;
  liveEndpointUrlPrefix?: InputMaybe<Scalars['String']['input']>;
  merchantAccount: Scalars['String']['input'];
};

export type InputAdyenWebhookChange = {
  configId: Scalars['String']['input'];
  created: Scalars['AWSDateTime']['input'];
  description: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  sequenceNumber: Scalars['Int']['input'];
  status?: InputMaybe<AdyenWebhookStatus>;
  target: AdyenWebhookTarget;
  tested?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updated: Scalars['AWSDateTime']['input'];
  username: Scalars['String']['input'];
};

export type InputAvardaConfig = {
  authUrl: Scalars['String']['input'];
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  shippingBroker?: InputMaybe<InputAvardaShippingBroker>;
};

export type InputAvardaShippingBroker = {
  name: Scalars['String']['input'];
};

export type InputBsgGiftCardConfig = {
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
  tokenUrl: Scalars['String']['input'];
};

export type InputBonusConfig = {
  authUrl: Scalars['String']['input'];
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};

export type InputBundle = {
  discountValue?: InputMaybe<InputDiscountValue>;
  id: Scalars['String']['input'];
  itemRules: Array<InputBundleItemRule>;
};

export type InputBundleItemRule = {
  discountValue?: InputMaybe<InputDiscountValue>;
  excludeDiscountedPrices: Scalars['Boolean']['input'];
  excludeFullPrices: Scalars['Boolean']['input'];
  productParents: Array<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  tagConditions: Array<InputTagCondition>;
};

export type InputCampaign = {
  campaignGroupId: Scalars['String']['input'];
  endDate: Scalars['AWSDateTime']['input'];
  name: Scalars['String']['input'];
  priceRule: InputPriceRule;
  productVariantRules: InputProductVariantRules;
  roundingRules?: InputMaybe<InputRoundingRules>;
  startDate: Scalars['AWSDateTime']['input'];
  storeMarkets: Array<InputStoreMarket>;
};

export type InputCampaignGroup = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InputCampaignMoney = {
  key: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type InputCampaignPeriod = {
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type InputCapabilities = {
  giftCardProviders?: InputMaybe<Array<InputProvider>>;
  paymentProviders?: InputMaybe<Array<InputProvider>>;
  shippingProviders?: InputMaybe<Array<InputProvider>>;
};

export type InputCapabilityProvider = {
  id: Scalars['ID']['input'];
};

export type InputCartCapabilities = {
  bonus?: InputMaybe<InputCapabilityProvider>;
  retain24?: InputMaybe<InputCapabilityProvider>;
  voyado?: InputMaybe<InputCapabilityProvider>;
};

export type InputComment = {
  text: Scalars['String']['input'];
};

export type InputCondition = {
  id: Scalars['ID']['input'];
  identifiers?: InputMaybe<Array<Scalars['String']['input']>>;
  itemRule?: InputMaybe<InputItemRule>;
  thresholds?: InputMaybe<Array<InputMoney>>;
  triggerQuantity?: InputMaybe<Scalars['Int']['input']>;
  type: InputConditionType;
};

export enum InputConditionType {
  Cartoverthreshold = 'CARTOVERTHRESHOLD',
  Cartunderthreshold = 'CARTUNDERTHRESHOLD',
  Countrycode = 'COUNTRYCODE',
  Minitems = 'MINITEMS',
  Minitemsvalue = 'MINITEMSVALUE',
  Storegroup = 'STOREGROUP'
}

export type InputCreateApiClient = {
  name: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type InputCreateGenerateCodes = {
  discountCodeRuleId: Scalars['String']['input'];
  numberOfCodes: Scalars['Int']['input'];
  prefix: Scalars['String']['input'];
  usageLimit: Scalars['Int']['input'];
};

export type InputCreateOrUpdateBundleGroup = {
  bundles: Array<InputBundle>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  storeMarkets: Array<InputStoreMarket>;
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountCode = {
  discountCodeRuleId: Scalars['String']['input'];
  isUnlimited: Scalars['Boolean']['input'];
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountCodeRule = {
  applyLast: Scalars['Boolean']['input'];
  conditions: Array<InputCondition>;
  isActive: Scalars['Boolean']['input'];
  isExclusive: Scalars['Boolean']['input'];
  isStackable: Scalars['Boolean']['input'];
  logicRules: Array<InputLogicHolder>;
  name: Scalars['String']['input'];
  outcomes: Array<InputOutcome>;
  sortOrder: Scalars['Int']['input'];
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountExternalRule = {
  applyLast: Scalars['Boolean']['input'];
  conditions: Array<InputCondition>;
  isActive: Scalars['Boolean']['input'];
  isExclusive: Scalars['Boolean']['input'];
  isStackable: Scalars['Boolean']['input'];
  logicRules: Array<InputLogicHolder>;
  name: Scalars['String']['input'];
  outcomes: Array<InputOutcome>;
  sortOrder: Scalars['Int']['input'];
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountRule = {
  conditions: Array<InputCondition>;
  isActive: Scalars['Boolean']['input'];
  isStackable: Scalars['Boolean']['input'];
  logicRules: Array<InputLogicHolder>;
  name: Scalars['String']['input'];
  outcomes: Array<InputOutcome>;
  sortOrder: Scalars['Int']['input'];
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateStoreGroup = {
  channelType: ChannelType;
  countryOfSale?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InputCreateStoreMarket = {
  capabilities?: InputMaybe<InputCapabilities>;
  cartCapabilities?: InputMaybe<InputCartCapabilities>;
  currencyCode: Scalars['String']['input'];
  isTaxExemptionEligible?: InputMaybe<Scalars['Boolean']['input']>;
  isTaxIncludedInPrice: Scalars['Boolean']['input'];
};

export type InputCreateUser = {
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  username: Scalars['AWSEmail']['input'];
};

export type InputCustomReasonCode = {
  label?: InputMaybe<Scalars['String']['input']>;
};

export type InputCustomState = {
  description?: InputMaybe<Scalars['String']['input']>;
  operations?: InputMaybe<Array<CustomOperation>>;
  type: CustomStateType;
};

export type InputDiscountValue = {
  fixedDiscounts?: InputMaybe<Array<InputMoney>>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
  percentageDecimals?: InputMaybe<Scalars['Int']['input']>;
  type: InputDiscountValueType;
};

export enum InputDiscountValueType {
  Fixed = 'FIXED',
  Relative = 'RELATIVE'
}

export type InputExternalEventsApiKeyAuth = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InputExternalEventsBasicAuth = {
  password: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

export type InputGift = {
  productVariantId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type InputGiftCardProductSelection = {
  giftCardProductId: Scalars['ID']['input'];
};

export type InputIngridConfig = {
  apiKey: Scalars['String']['input'];
  baseUrl: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type InputInventory = {
  address?: InputMaybe<InputInventoryAddress>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InputInventoryAddress = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
};

export type InputInventoryProductVariantStock = {
  quantity: Scalars['Int']['input'];
};

export type InputItemRule = {
  excludeDiscountedPrices: Scalars['Boolean']['input'];
  excludeFullPrices?: InputMaybe<Scalars['Boolean']['input']>;
  tagConditions: Array<InputTagCondition>;
};

export type InputKbsGiftCardConfig = {
  baseUrl: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type InputKlarnaCheckoutConfig = {
  baseUrl: Scalars['String']['input'];
  klarnaShippingAssistantEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  merchantId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type InputLogicHolder = {
  do: Array<Scalars['String']['input']>;
  if: Scalars['String']['input'];
};

export type InputMarketRoundingRule = {
  key: Scalars['String']['input'];
  value?: InputMaybe<InputRoundingRule>;
};

export type InputMoney = {
  currencyCode: Scalars['String']['input'];
  units: Scalars['Int']['input'];
};

export type InputNShiftConfig = {
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  deliveryCheckoutId: Scalars['String']['input'];
};

export type InputOrderCancellation = {
  reason?: InputMaybe<InputReason>;
};

export type InputOrderCompensation = {
  bonus?: InputMaybe<InputOrderCompensationBonus>;
  fee?: InputMaybe<InputOrderCompensationFee>;
  giftCards?: InputMaybe<Array<InputOrderCompensationGiftCard>>;
  orderLines?: InputMaybe<Array<InputOrderLineCompensation>>;
  payment?: InputMaybe<InputOrderCompensationPayment>;
  reason?: InputMaybe<InputReason>;
  shippingFees?: InputMaybe<Array<InputOrderShippingFeeCompensation>>;
};

export type InputOrderCompensationBonus = {
  amount: Scalars['Int']['input'];
};

export type InputOrderCompensationFee = {
  amount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  taxPercentage: Scalars['Int']['input'];
  taxPercentageDecimals: Scalars['Int']['input'];
};

export type InputOrderCompensationGiftCard = {
  amount: Scalars['Int']['input'];
  giftCardId: Scalars['ID']['input'];
};

export type InputOrderCompensationPayment = {
  amount: Scalars['Int']['input'];
};

export type InputOrderDelivery = {
  giftCardProducts?: InputMaybe<Array<InputGiftCardProductSelection>>;
  gifts?: InputMaybe<Array<InputOrderGiftSelection>>;
  orderLines?: InputMaybe<Array<InputOrderLineSelection>>;
  reason?: InputMaybe<InputReason>;
  shippingFees?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputOrderGiftSelection = {
  giftId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type InputOrderLineCompensation = {
  orderLineId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  taxPercentage: Scalars['Int']['input'];
  taxPercentageDecimals: Scalars['Int']['input'];
  totalAmount: Scalars['Int']['input'];
  totalDiscountAmount: Scalars['Int']['input'];
  totalTaxAmount: Scalars['Int']['input'];
};

export type InputOrderLineModification = {
  data: Scalars['AWSJSON']['input'];
  modificationType: OrderLineModificationType;
};

export type InputOrderLineSelection = {
  orderLineId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type InputOrderModificationOrderLines = {
  orderLines: Array<InputOrderLineModification>;
  reason?: InputMaybe<InputReason>;
};

export type InputOrderModificationOrderLinesStart = {
  payment?: InputMaybe<InputActionPaymentOrderModificationOrderLines>;
};

export type InputOrderRefund = {
  fee?: InputMaybe<InputOrderRefundFee>;
  gifts?: InputMaybe<Array<InputOrderGiftSelection>>;
  orderLines?: InputMaybe<Array<InputOrderLineSelection>>;
  reason?: InputMaybe<InputReason>;
  shippingFees?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputOrderRefundFee = {
  amount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  taxPercentage: Scalars['Int']['input'];
  taxPercentageDecimals: Scalars['Int']['input'];
};

export type InputOrderRelease = {
  reason?: InputMaybe<InputReason>;
};

export type InputOrderShippingFeeCompensation = {
  shippingFeeId: Scalars['ID']['input'];
  taxPercentage: Scalars['Int']['input'];
  taxPercentageDecimals: Scalars['Int']['input'];
  totalAmount: Scalars['Int']['input'];
  totalDiscountAmount: Scalars['Int']['input'];
  totalTaxAmount: Scalars['Int']['input'];
};

export type InputOrderStartCancellation = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentCancellation>;
};

export type InputOrderStartCompensation = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentCompensation>;
};

export type InputOrderStartDelivery = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentDelivery>;
  shipping?: InputMaybe<InputActionShipping>;
};

export type InputOrderStartRefund = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentRefund>;
};

export type InputOrderStartRelease = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentRelease>;
};

export type InputOutcome = {
  combineItemDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  discountItemRule?: InputMaybe<InputItemRule>;
  discountQuantity?: InputMaybe<Scalars['Int']['input']>;
  discountValue?: InputMaybe<InputDiscountValue>;
  gifts?: InputMaybe<Array<InputGift>>;
  id: Scalars['ID']['input'];
  isRecurrent?: InputMaybe<Scalars['Boolean']['input']>;
  shippingTags?: InputMaybe<Array<Scalars['String']['input']>>;
  subOutcomes?: InputMaybe<Array<InputSubOutcome>>;
  triggerItemRule?: InputMaybe<InputItemRule>;
  triggerQuantity?: InputMaybe<Scalars['Int']['input']>;
  type: InputOutcomeType;
};

export enum InputOutcomeType {
  Buyget = 'BUYGET',
  Buyselect = 'BUYSELECT',
  Cartdiscount = 'CARTDISCOUNT',
  Freeshipping = 'FREESHIPPING',
  Gift = 'GIFT',
  Itemdiscounts = 'ITEMDISCOUNTS',
  Optimizebuygets = 'OPTIMIZEBUYGETS',
  Selectitems = 'SELECTITEMS',
  Shippingtags = 'SHIPPINGTAGS'
}

export type InputPriceRule = {
  monies?: InputMaybe<Array<InputCampaignMoney>>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
  percentageDecimals?: InputMaybe<Scalars['Int']['input']>;
  type: InputPriceRuleType;
};

export enum InputPriceRuleType {
  FixedDiscount = 'FIXED_DISCOUNT',
  RelativeDiscount = 'RELATIVE_DISCOUNT',
  SetPrice = 'SET_PRICE'
}

export type InputProductVariantRules = {
  tagLogic: Scalars['String']['input'];
};

export type InputProductVariantStockConfig = {
  validateStock: Scalars['Boolean']['input'];
};

export type InputProvider = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type InputQliroOneConfig = {
  baseUrl: Scalars['String']['input'];
  integratedShipping?: InputMaybe<InputQliroOneIntegratedShipping>;
  merchantApiKey: Scalars['String']['input'];
  merchantApiSecret: Scalars['String']['input'];
  merchantOrderManagementStatusPushUrl: Scalars['String']['input'];
  useLegacyIds?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputQliroOneIntegratedShipping = {
  deliveryCheckoutId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type InputReason = {
  cause?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type InputRemoveUserFromGroup = {
  groupname: Scalars['String']['input'];
  username: Scalars['AWSEmail']['input'];
};

export type InputRetain24Config = {
  baseUrl: Scalars['String']['input'];
  certificate: Scalars['String']['input'];
  certificatePassword: Scalars['String']['input'];
  redeem?: InputMaybe<Scalars['Boolean']['input']>;
  simulationKey?: InputMaybe<Scalars['String']['input']>;
};

export type InputRoundingRule = {
  roundDown: Scalars['Boolean']['input'];
  roundUp: Scalars['Boolean']['input'];
  targets: Array<Scalars['String']['input']>;
};

export type InputRoundingRules = {
  defaultRule: InputRoundingRule;
  marketRules?: InputMaybe<Array<InputMarketRoundingRule>>;
};

export type InputStoreMarket = {
  key: Scalars['String']['input'];
  value: Array<Scalars['String']['input']>;
};

export type InputSubOutcome = {
  discountQuantity?: InputMaybe<Scalars['Int']['input']>;
  discountValue?: InputMaybe<InputDiscountValue>;
  triggerQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type InputSveaCheckoutConfig = {
  apiKey: Scalars['String']['input'];
  checkoutBaseUrl: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
  paymentAdminBaseUrl: Scalars['String']['input'];
};

export type InputTagCondition = {
  key: Scalars['String']['input'];
  mode: InputTagConditionMode;
  values: Array<Scalars['String']['input']>;
};

export enum InputTagConditionMode {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE'
}

export type InputTaxGroup = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InputTaxMarket = {
  taxPercentage: Scalars['Int']['input'];
  taxPercentageDecimals: Scalars['Int']['input'];
};

export type InputUpdateApiClient = {
  name: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type InputUpdateExternalEventsDestinationApi = {
  apiKeyAuth?: InputMaybe<InputExternalEventsApiKeyAuth>;
  basicAuth?: InputMaybe<InputExternalEventsBasicAuth>;
  filterEventRules?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputUpdateStoreGroup = {
  countryOfSale?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateStoreMarket = {
  capabilities?: InputMaybe<InputCapabilities>;
  cartCapabilities?: InputMaybe<InputCartCapabilities>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTaxExemptionEligible?: InputMaybe<Scalars['Boolean']['input']>;
  isTaxIncludedInPrice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputUpdateUser = {
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
};

export type InputValidDateRange = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type InputVoyadoConfig = {
  apiKey: Scalars['String']['input'];
  baseUrl: Scalars['String']['input'];
};

export type InputWalleyCheckoutConfig = {
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  deliveryModuleEnabled: Scalars['Boolean']['input'];
  profileName?: InputMaybe<Scalars['String']['input']>;
  scope: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type Inventory = {
  __typename?: 'Inventory';
  address?: Maybe<InventoryAddress>;
  created: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated: Scalars['AWSDateTime']['output'];
};

export type InventoryAddress = {
  __typename?: 'InventoryAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
};

export type InventoryProductVariantStock = {
  __typename?: 'InventoryProductVariantStock';
  inventoryId: Scalars['String']['output'];
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type InventoryProductVariantStockConfig = {
  __typename?: 'InventoryProductVariantStockConfig';
  productVariantId: Scalars['ID']['output'];
  validateStock?: Maybe<Scalars['Boolean']['output']>;
};

export type InventoryStoreMarket = {
  __typename?: 'InventoryStoreMarket';
  countryCode: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  inventories?: Maybe<Array<Inventory>>;
  storeDescription?: Maybe<Scalars['String']['output']>;
  storeGroupId: Scalars['String']['output'];
  storeName?: Maybe<Scalars['String']['output']>;
  updated: Scalars['AWSDateTime']['output'];
};

export type InventoryStoreMarketInventories = {
  __typename?: 'InventoryStoreMarketInventories';
  countryCode: Scalars['String']['output'];
  inventoryIds?: Maybe<Array<Scalars['String']['output']>>;
  storeGroupId: Scalars['String']['output'];
};

export type ItemRule = {
  __typename?: 'ItemRule';
  excludeDiscountedPrices: Scalars['Boolean']['output'];
  excludeFullPrices?: Maybe<Scalars['Boolean']['output']>;
  tagConditions: Array<TagCondition>;
};

export type KbsGiftCardConfig = {
  __typename?: 'KBSGiftCardConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: KbsGiftCardCredentials;
  id: Scalars['ID']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type KbsGiftCardCredentials = {
  __typename?: 'KBSGiftCardCredentials';
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type KlarnaCheckoutConfig = {
  __typename?: 'KlarnaCheckoutConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: KlarnaCredentials;
  id: Scalars['ID']['output'];
  klarnaShippingAssistantEnabled?: Maybe<Scalars['Boolean']['output']>;
  merchantId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type KlarnaCredentials = {
  __typename?: 'KlarnaCredentials';
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type KlarnaShippingAssistantAddon = {
  __typename?: 'KlarnaShippingAssistantAddon';
  externalId: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  userInput: Scalars['String']['output'];
};

export type KlarnaShippingAssistantAddress = {
  __typename?: 'KlarnaShippingAssistantAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  streetAddress2: Scalars['String']['output'];
};

export type KlarnaShippingAssistantCarrierProduct = {
  __typename?: 'KlarnaShippingAssistantCarrierProduct';
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type KlarnaShippingAssistantLocation = {
  __typename?: 'KlarnaShippingAssistantLocation';
  address: KlarnaShippingAssistantAddress;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type KlarnaShippingAssistantLocationTimeslot = {
  __typename?: 'KlarnaShippingAssistantLocationTimeslot';
  cutoff: Scalars['String']['output'];
  end: Scalars['String']['output'];
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  start: Scalars['String']['output'];
};

export type KlarnaShippingAssistantSelectedShippingOption = {
  __typename?: 'KlarnaShippingAssistantSelectedShippingOption';
  carrier: Scalars['String']['output'];
  carrierProduct?: Maybe<KlarnaShippingAssistantCarrierProduct>;
  location?: Maybe<KlarnaShippingAssistantLocation>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  selectedAddons?: Maybe<Array<KlarnaShippingAssistantAddon>>;
  taxAmount: Scalars['Int']['output'];
  taxRate: Scalars['Int']['output'];
  timeslot?: Maybe<KlarnaShippingAssistantLocationTimeslot>;
  tmsReference: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LogicHolder = {
  __typename?: 'LogicHolder';
  do: Array<Scalars['String']['output']>;
  if: Scalars['String']['output'];
};

export type MarketPrice = {
  __typename?: 'MarketPrice';
  countryCode: Scalars['String']['output'];
  price: Price;
};

export type MarketRoundingRule = {
  __typename?: 'MarketRoundingRule';
  key: Scalars['String']['output'];
  value?: Maybe<RoundingRule>;
};

export type Money = {
  __typename?: 'Money';
  currencyCode: Scalars['String']['output'];
  units: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  Order: MutationOrderManagement;
  addExternalEventsDestinationAPI: AddExternalEventsDestinationApiResult;
  addExternalEventsDestinationEventBus: AddExternalEventsDestinationEventBusResult;
  addExternalEventsRule: AddExternalEventsRuleResult;
  addUserToGroup: OperationResult;
  createApiClient: ApiClientDetails;
  createGenerateCodes?: Maybe<CreateGenerateCodesResult>;
  createOrUpdateAdyenConfig: AdyenConfig;
  createOrUpdateAvardaConfig: AvardaConfig;
  createOrUpdateBSGGiftCardConfig: BsgGiftCardConfig;
  createOrUpdateBonusConfig: BonusConfig;
  createOrUpdateBundleGroup?: Maybe<BundleGroup>;
  createOrUpdateCampaign: Campaign;
  createOrUpdateCampaignGroup: CampaignGroup;
  createOrUpdateDiscountCode?: Maybe<DiscountCode>;
  createOrUpdateDiscountCodeRule?: Maybe<DiscountCodeRule>;
  createOrUpdateDiscountExternalRule?: Maybe<DiscountExternalRule>;
  createOrUpdateDiscountRule?: Maybe<DiscountRule>;
  createOrUpdateIngridConfig: IngridConfig;
  createOrUpdateInventory: Inventory;
  createOrUpdateKBSGiftCardConfig: KbsGiftCardConfig;
  createOrUpdateKlarnaCheckoutConfig: KlarnaCheckoutConfig;
  createOrUpdateNShiftConfig: NShiftConfig;
  createOrUpdateQliroOneConfig: QliroOneConfig;
  createOrUpdateRetain24Config: Retain24Config;
  createOrUpdateSveaCheckoutConfig: SveaCheckoutConfig;
  createOrUpdateTaxGroup: TaxGroup;
  createOrUpdateTaxMarket: TaxMarket;
  createOrUpdateVoyadoConfig: VoyadoConfig;
  createOrUpdateWalleyCheckoutConfig: WalleyCheckoutConfig;
  createStoreGroup?: Maybe<StoreGroup>;
  createStoreMarket?: Maybe<StoreMarket>;
  createUser: User;
  deleteAdyenConfig: DeletedResult;
  deleteApiClient: OperationResult;
  deleteAvardaConfig: DeletedResult;
  deleteBSGGiftCardConfig: DeletedResult;
  deleteBonusConfig: DeletedResult;
  deleteBundleGroup: DeletedResult;
  deleteCampaign: CampaignDeletionResult;
  deleteCampaignGroup: DeletedResult;
  deleteDiscountCode: DeletedResult;
  deleteDiscountCodeRule: DeletedResult;
  deleteDiscountExternalRule: DeletedResult;
  deleteDiscountRule: DeletedResult;
  deleteExternalEventsDestinationAPI: DeletedResult;
  deleteExternalEventsDestinationEventBus: DeletedResult;
  deleteExternalEventsRule: DeletedResult;
  deleteIngridConfig: DeletedResult;
  deleteInventory: DeletedResult;
  deleteInventoryProductVariantStock: DeletedResult;
  deleteKBSGiftCardConfig: DeletedResult;
  deleteKlarnaCheckoutConfig: DeletedResult;
  deleteNShiftConfig: DeletedResult;
  deleteQliroOneConfig: DeletedResult;
  deleteRetain24Config: DeletedResult;
  deleteStoreGroup: DeletedResult;
  deleteStoreMarket: DeletedResult;
  deleteSveaCheckoutConfig: DeletedResult;
  deleteTaxGroup: DeletedResult;
  deleteTaxMarket: DeletedResult;
  deleteUser: OperationResult;
  deleteVoyadoConfig: DeletedResult;
  deleteWalleyCheckoutConfig: DeletedResult;
  disableUser: OperationResult;
  enableUser: OperationResult;
  /** @deprecated use generateAdyenWebhooks() instead */
  generateAdyenWebhookCredentials: AdyenWebhookCredentials;
  generateAdyenWebhooks: AdyenWebhookGenerationResult;
  privateAdyenWebhookChange: AdyenWebhook;
  removeUserFromGroup: OperationResult;
  /** @deprecated use Order.resendOrderEvent() instead */
  resendOrderEvent: OperationResult;
  resendPassword: OperationResult;
  setShippingTaxGroup: ShippingTaxGroup;
  updateApiClient: ApiClientDetails;
  updateCampaignPeriod: Campaign;
  updateExternalEventsDestinationAPI: UpdateExternalEventsDestinationApiResult;
  updateInventoryProductVariantStock: InventoryProductVariantStock;
  updateInventoryStoreMarketInventories: InventoryStoreMarketInventories;
  updateProductVariantStockConfig: InventoryProductVariantStockConfig;
  updateStoreGroup?: Maybe<StoreGroup>;
  updateStoreMarket?: Maybe<StoreMarket>;
  updateUser: OperationResult;
};


export type MutationAddExternalEventsDestinationApiArgs = {
  destName: Scalars['String']['input'];
  options: InputAddExternalEventsDestinationApi;
};


export type MutationAddExternalEventsDestinationEventBusArgs = {
  eventBusArn: Scalars['String']['input'];
};


export type MutationAddExternalEventsRuleArgs = {
  ruleName: Scalars['String']['input'];
};


export type MutationAddUserToGroupArgs = {
  input: InputAddUserToGroup;
};


export type MutationCreateApiClientArgs = {
  input: InputCreateApiClient;
};


export type MutationCreateGenerateCodesArgs = {
  input: InputCreateGenerateCodes;
};


export type MutationCreateOrUpdateAdyenConfigArgs = {
  adyenConfig: InputAdyenConfig;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateAvardaConfigArgs = {
  avardaConfig: InputAvardaConfig;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateBsgGiftCardConfigArgs = {
  bsgGiftCardConfig: InputBsgGiftCardConfig;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateBonusConfigArgs = {
  bonusConfig: InputBonusConfig;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateBundleGroupArgs = {
  bundleGroup: InputCreateOrUpdateBundleGroup;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateCampaignArgs = {
  campaign: InputCampaign;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateCampaignGroupArgs = {
  campaignGroup: InputCampaignGroup;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateDiscountCodeArgs = {
  code: Scalars['String']['input'];
  discountCode: InputCreateOrUpdateDiscountCode;
};


export type MutationCreateOrUpdateDiscountCodeRuleArgs = {
  discountCodeRule: InputCreateOrUpdateDiscountCodeRule;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateDiscountExternalRuleArgs = {
  discountExternalRule: InputCreateOrUpdateDiscountExternalRule;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateDiscountRuleArgs = {
  discountRule: InputCreateOrUpdateDiscountRule;
  id: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateIngridConfigArgs = {
  id: Scalars['ID']['input'];
  ingridConfig: InputIngridConfig;
};


export type MutationCreateOrUpdateInventoryArgs = {
  id: Scalars['ID']['input'];
  inventory: InputInventory;
};


export type MutationCreateOrUpdateKbsGiftCardConfigArgs = {
  id: Scalars['ID']['input'];
  kbsGiftCardConfig: InputKbsGiftCardConfig;
};


export type MutationCreateOrUpdateKlarnaCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
  klarnaCheckoutConfig: InputKlarnaCheckoutConfig;
};


export type MutationCreateOrUpdateNShiftConfigArgs = {
  id: Scalars['ID']['input'];
  nShiftConfig: InputNShiftConfig;
};


export type MutationCreateOrUpdateQliroOneConfigArgs = {
  id: Scalars['ID']['input'];
  qliroOneConfig: InputQliroOneConfig;
};


export type MutationCreateOrUpdateRetain24ConfigArgs = {
  id: Scalars['ID']['input'];
  retain24Config: InputRetain24Config;
};


export type MutationCreateOrUpdateSveaCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
  sveaCheckoutConfig: InputSveaCheckoutConfig;
};


export type MutationCreateOrUpdateTaxGroupArgs = {
  id: Scalars['ID']['input'];
  taxGroup: InputTaxGroup;
};


export type MutationCreateOrUpdateTaxMarketArgs = {
  countryCode: Scalars['String']['input'];
  taxGroupId: Scalars['String']['input'];
  taxMarket: InputTaxMarket;
};


export type MutationCreateOrUpdateVoyadoConfigArgs = {
  id: Scalars['ID']['input'];
  voyadoConfig: InputVoyadoConfig;
};


export type MutationCreateOrUpdateWalleyCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
  walleyCheckoutConfig: InputWalleyCheckoutConfig;
};


export type MutationCreateStoreGroupArgs = {
  id: Scalars['ID']['input'];
  storeGroup: InputCreateStoreGroup;
};


export type MutationCreateStoreMarketArgs = {
  countryCode: Scalars['String']['input'];
  storeGroupId: Scalars['String']['input'];
  storeMarket: InputCreateStoreMarket;
};


export type MutationCreateUserArgs = {
  input: InputCreateUser;
};


export type MutationDeleteAdyenConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteApiClientArgs = {
  clientId: Scalars['ID']['input'];
};


export type MutationDeleteAvardaConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBsgGiftCardConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBonusConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBundleGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCampaignGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDiscountCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationDeleteDiscountCodeRuleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDiscountExternalRuleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDiscountRuleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteExternalEventsDestinationApiArgs = {
  destName: Scalars['String']['input'];
};


export type MutationDeleteExternalEventsDestinationEventBusArgs = {
  eventBusArn: Scalars['String']['input'];
};


export type MutationDeleteExternalEventsRuleArgs = {
  ruleName: Scalars['String']['input'];
};


export type MutationDeleteIngridConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInventoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInventoryProductVariantStockArgs = {
  inventoryId: Scalars['ID']['input'];
  productVariantId: Scalars['ID']['input'];
};


export type MutationDeleteKbsGiftCardConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteKlarnaCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNShiftConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteQliroOneConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRetain24ConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStoreGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStoreMarketArgs = {
  countryCode: Scalars['String']['input'];
  storeGroupId: Scalars['String']['input'];
};


export type MutationDeleteSveaCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaxGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaxMarketArgs = {
  countryCode: Scalars['String']['input'];
  taxGroupId: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  username: Scalars['AWSEmail']['input'];
};


export type MutationDeleteVoyadoConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWalleyCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisableUserArgs = {
  username: Scalars['AWSEmail']['input'];
};


export type MutationEnableUserArgs = {
  username: Scalars['AWSEmail']['input'];
};


export type MutationGenerateAdyenWebhookCredentialsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGenerateAdyenWebhooksArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPrivateAdyenWebhookChangeArgs = {
  input: InputAdyenWebhookChange;
};


export type MutationRemoveUserFromGroupArgs = {
  input: InputRemoveUserFromGroup;
};


export type MutationResendOrderEventArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationResendPasswordArgs = {
  username: Scalars['AWSEmail']['input'];
};


export type MutationSetShippingTaxGroupArgs = {
  taxGroupId: Scalars['String']['input'];
};


export type MutationUpdateApiClientArgs = {
  clientId: Scalars['ID']['input'];
  input: InputUpdateApiClient;
};


export type MutationUpdateCampaignPeriodArgs = {
  id: Scalars['ID']['input'];
  period: InputCampaignPeriod;
};


export type MutationUpdateExternalEventsDestinationApiArgs = {
  destName: Scalars['String']['input'];
  options: InputUpdateExternalEventsDestinationApi;
};


export type MutationUpdateInventoryProductVariantStockArgs = {
  inventoryId: Scalars['ID']['input'];
  productVariantId: Scalars['ID']['input'];
  stock: InputInventoryProductVariantStock;
};


export type MutationUpdateInventoryStoreMarketInventoriesArgs = {
  countryCode: Scalars['String']['input'];
  inventoryIds: Array<Scalars['String']['input']>;
  storeGroupId: Scalars['ID']['input'];
};


export type MutationUpdateProductVariantStockConfigArgs = {
  config: InputProductVariantStockConfig;
  productVariantId: Scalars['ID']['input'];
};


export type MutationUpdateStoreGroupArgs = {
  id: Scalars['ID']['input'];
  storeGroup: InputUpdateStoreGroup;
};


export type MutationUpdateStoreMarketArgs = {
  countryCode: Scalars['String']['input'];
  storeGroupId: Scalars['String']['input'];
  storeMarket: InputUpdateStoreMarket;
};


export type MutationUpdateUserArgs = {
  input: InputUpdateUser;
  username: Scalars['AWSEmail']['input'];
};

export type MutationOrderManagement = {
  __typename?: 'MutationOrderManagement';
  addCustomState: Scalars['ID']['output'];
  createCancellation: OrderCancellation;
  createCompensation: OrderCompensation;
  createCustomReasonCode: CustomReasonCode;
  createCustomState: CustomState;
  createDelivery: OrderDelivery;
  createOrderComment: OrderComment;
  createOrderModificationOrderLines: OrderModificationOrderLines;
  createRefund: OrderRefund;
  createRelease: OrderRelease;
  deleteCancellation: OrderOperationResult;
  deleteCompensation: OrderOperationResult;
  deleteCustomReasonCode: Scalars['ID']['output'];
  deleteCustomState: Scalars['ID']['output'];
  deleteDelivery: OrderOperationResult;
  deleteOrderComment: Scalars['ID']['output'];
  deleteOrderModificationOrderLines: OrderOperationResult;
  deleteRefund: OrderOperationResult;
  deleteRelease: OrderOperationResult;
  removeCustomState: Scalars['ID']['output'];
  resendOrderEvent: OperationResult;
  restartCancellation: OrderOperationResult;
  restartCompensation: OrderOperationResult;
  restartDelivery: OrderOperationResult;
  restartOrderModificationOrderLines: OrderOperationResult;
  restartRefund: OrderOperationResult;
  restartRelease: OrderOperationResult;
  startCancellation: OrderOperationResult;
  startCompensation: OrderOperationResult;
  startDelivery: OrderOperationResult;
  startOrderModificationOrderLines: OrderOperationResult;
  startRefund: OrderOperationResult;
  startRelease: OrderOperationResult;
  updateCompensation: OrderCompensation;
  updateDelivery: OrderDelivery;
  updateOrderComment: OrderComment;
  updateOrderModificationOrderLines: OrderModificationOrderLines;
  updateRefund: OrderRefund;
};


export type MutationOrderManagementAddCustomStateArgs = {
  orderId: Scalars['ID']['input'];
  stateId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateCancellationArgs = {
  input: InputOrderCancellation;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateCompensationArgs = {
  input: InputOrderCompensation;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateCustomReasonCodeArgs = {
  code: Scalars['ID']['input'];
  input: InputCustomReasonCode;
};


export type MutationOrderManagementCreateCustomStateArgs = {
  input: InputCustomState;
  stateId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateDeliveryArgs = {
  input: InputOrderDelivery;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateOrderCommentArgs = {
  comment: InputComment;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateOrderModificationOrderLinesArgs = {
  input: InputOrderModificationOrderLines;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateRefundArgs = {
  input: InputOrderRefund;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementCreateReleaseArgs = {
  input: InputOrderRelease;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteCancellationArgs = {
  cancellationId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteCompensationArgs = {
  compensationId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteCustomReasonCodeArgs = {
  code: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteCustomStateArgs = {
  stateId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteOrderCommentArgs = {
  commentId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteOrderModificationOrderLinesArgs = {
  modificationId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteRefundArgs = {
  refundId: Scalars['ID']['input'];
};


export type MutationOrderManagementDeleteReleaseArgs = {
  releaseId: Scalars['ID']['input'];
};


export type MutationOrderManagementRemoveCustomStateArgs = {
  orderId: Scalars['ID']['input'];
  stateId: Scalars['ID']['input'];
};


export type MutationOrderManagementResendOrderEventArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementRestartCancellationArgs = {
  cancellationId: Scalars['ID']['input'];
  input: InputOrderStartCancellation;
};


export type MutationOrderManagementRestartCompensationArgs = {
  compensationId: Scalars['ID']['input'];
  input: InputOrderStartCompensation;
};


export type MutationOrderManagementRestartDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
  input: InputOrderStartDelivery;
};


export type MutationOrderManagementRestartOrderModificationOrderLinesArgs = {
  input: InputOrderModificationOrderLinesStart;
  modificationId: Scalars['ID']['input'];
};


export type MutationOrderManagementRestartRefundArgs = {
  input: InputOrderStartRefund;
  refundId: Scalars['ID']['input'];
};


export type MutationOrderManagementRestartReleaseArgs = {
  input: InputOrderStartRelease;
  releaseId: Scalars['ID']['input'];
};


export type MutationOrderManagementStartCancellationArgs = {
  cancellationId: Scalars['ID']['input'];
  input: InputOrderStartCancellation;
};


export type MutationOrderManagementStartCompensationArgs = {
  compensationId: Scalars['ID']['input'];
  input: InputOrderStartCompensation;
};


export type MutationOrderManagementStartDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
  input: InputOrderStartDelivery;
};


export type MutationOrderManagementStartOrderModificationOrderLinesArgs = {
  input: InputOrderModificationOrderLinesStart;
  modificationId: Scalars['ID']['input'];
};


export type MutationOrderManagementStartRefundArgs = {
  input: InputOrderStartRefund;
  refundId: Scalars['ID']['input'];
};


export type MutationOrderManagementStartReleaseArgs = {
  input: InputOrderStartRelease;
  releaseId: Scalars['ID']['input'];
};


export type MutationOrderManagementUpdateCompensationArgs = {
  compensationId: Scalars['ID']['input'];
  input: InputOrderCompensation;
};


export type MutationOrderManagementUpdateDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
  input: InputOrderDelivery;
};


export type MutationOrderManagementUpdateOrderCommentArgs = {
  comment: InputComment;
  commentId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};


export type MutationOrderManagementUpdateOrderModificationOrderLinesArgs = {
  input: InputOrderModificationOrderLines;
  modificationId: Scalars['ID']['input'];
};


export type MutationOrderManagementUpdateRefundArgs = {
  input: InputOrderRefund;
  refundId: Scalars['ID']['input'];
};

export type NShiftConfig = {
  __typename?: 'NShiftConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: NShiftCredentials;
  deliveryCheckoutId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type NShiftCredentials = {
  __typename?: 'NShiftCredentials';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type NshiftAddress = {
  __typename?: 'NshiftAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quickId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type NshiftPreparedShipment = {
  __typename?: 'NshiftPreparedShipment';
  agent?: Maybe<NshiftAddress>;
  orderNo: Scalars['String']['output'];
  prepareId: Scalars['String']['output'];
  sender: NshiftAddress;
  service: NshiftService;
};

export type NshiftService = {
  __typename?: 'NshiftService';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sourceSystem: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type OperationResult = {
  __typename?: 'OperationResult';
  status: Scalars['String']['output'];
};

export type Order = {
  __typename?: 'Order';
  Bonus?: Maybe<OrderBonus>;
  Bundles?: Maybe<Array<OrderBundle>>;
  Cancellations: Array<OrderCancellation>;
  Compensations: Array<OrderCompensation>;
  Deliveries: Array<OrderDelivery>;
  GiftCard: OrderGiftCardBsgGiftCard | OrderGiftCardEmpty | OrderGiftCardKbsGiftCard | OrderGiftCardRetain24;
  GiftCardProduct: OrderGiftCardProductEmpty | OrderGiftCardProductRetain24;
  Modifications: Array<OrderModificationOrderLines>;
  Payment: OrderPaymentAdyen | OrderPaymentAvarda | OrderPaymentBrinkZeroPayment | OrderPaymentKlarnaCheckout | OrderPaymentQliroOne | OrderPaymentSveaCheckout | OrderPaymentWalleyCheckout;
  Promotion: OrderPromotionEmpty | OrderPromotionVoyado;
  Refunds: Array<OrderRefund>;
  Releases: Array<OrderRelease>;
  Reservation?: Maybe<OrderReservation>;
  Shipping: OrderShippingAvardaShippingBroker | OrderShippingEmpty | OrderShippingIngrid | OrderShippingKlarnaShippingAssistant | OrderShippingNshift | OrderShippingQliroIntegratedShipping | OrderShippingWalleyDeliveryModule;
  Voucher: OrderVoucherEmpty | OrderVoucherVoyado;
  attributes?: Maybe<OrderAttributes>;
  billingAddress: OrderAddress;
  bonusProvider?: Maybe<BonusProvider>;
  channelType: ChannelType;
  countryCode: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  date: Scalars['AWSDateTime']['output'];
  discountCodeRules?: Maybe<Array<OrderDiscountCodeRule>>;
  discountExternalRules?: Maybe<Array<OrderDiscountExternalRule>>;
  discountOutcome: OrderDiscountOutcome;
  discountRules?: Maybe<Array<OrderDiscountRule>>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  gifts?: Maybe<Array<OrderGift>>;
  id: Scalars['ID']['output'];
  isTaxExempt: Scalars['Boolean']['output'];
  isTaxExemptionEligible: Scalars['Boolean']['output'];
  isTaxIncludedInPrice: Scalars['Boolean']['output'];
  languageCode: Scalars['String']['output'];
  orderLines?: Maybe<Array<OrderLine>>;
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reference: Scalars['String']['output'];
  shippingAddress: OrderAddress;
  shippingFees?: Maybe<Array<ShippingFee>>;
  shippingProvider?: Maybe<ShippingProvider>;
  status: OrderStatus;
  storeGroupId: Scalars['String']['output'];
  taxationCountry: Scalars['String']['output'];
  totals: OrderTotals;
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  pcc?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetAddress2?: Maybe<Scalars['String']['output']>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
};

export type OrderAttributes = {
  __typename?: 'OrderAttributes';
  company?: Maybe<CompanyAttribute>;
  merchantReference1?: Maybe<Scalars['String']['output']>;
  merchantReference2?: Maybe<Scalars['String']['output']>;
};

export type OrderBonus = {
  __typename?: 'OrderBonus';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  points?: Maybe<Scalars['Int']['output']>;
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reservationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type OrderBundle = {
  __typename?: 'OrderBundle';
  bundleGroupId: Scalars['String']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
};

export type OrderCancellation = {
  __typename?: 'OrderCancellation';
  bonus?: Maybe<CancellationBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  cancelledPayment?: Maybe<CancelledPayment>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProducts: Array<CancellationGiftCardProduct>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<CancellationGiftCard>;
  id: Scalars['ID']['output'];
  orderId: Scalars['String']['output'];
  orderReference: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderComment = {
  __typename?: 'OrderComment';
  comment: Comment;
  created: Scalars['AWSDateTime']['output'];
  createdBy: Actor;
  id: Scalars['ID']['output'];
  orderId: Scalars['ID']['output'];
  revision: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type OrderCompensation = {
  __typename?: 'OrderCompensation';
  bonus?: Maybe<CompensationBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  fee?: Maybe<CompensationFee>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards?: Maybe<Array<CompensationGiftCard>>;
  id: Scalars['ID']['output'];
  orderId: Scalars['String']['output'];
  orderLines?: Maybe<Array<CompensationOrderLine>>;
  orderReference: Scalars['String']['output'];
  payment?: Maybe<CompensationPayment>;
  paymentProvider?: Maybe<PaymentProvider>;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingFees?: Maybe<Array<CompensationShippingFee>>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  totals: OrderCompensationTotals;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderCompensationTotals = {
  __typename?: 'OrderCompensationTotals';
  bonusTotal: Scalars['Int']['output'];
  giftCardTotal: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  paymentTotal: Scalars['Int']['output'];
};

export type OrderCustomState = {
  __typename?: 'OrderCustomState';
  id: Scalars['ID']['output'];
  operations?: Maybe<Array<Scalars['String']['output']>>;
  type: CustomStateType;
};

export type OrderDelivery = {
  __typename?: 'OrderDelivery';
  bonus?: Maybe<DeliveryBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  capturedPayment?: Maybe<DeliveryCapture>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProducts: Array<DeliveryGiftCardProduct>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<DeliveryGiftCard>;
  gifts: Array<DeliveryGift>;
  id: Scalars['ID']['output'];
  orderId: Scalars['String']['output'];
  orderLines: Array<DeliveryOrderLine>;
  orderReference: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingFees: Array<DeliveryShippingFee>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  totals: OrderDeliveryTotals;
  tracking?: Maybe<DeliveryTracking>;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
  vouchers: Array<DeliveryVoucher>;
};

export type OrderDeliveryTotals = {
  __typename?: 'OrderDeliveryTotals';
  bonusTotal: Scalars['Int']['output'];
  giftCardTotal: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  shippingTotal: Scalars['Int']['output'];
  subTotal: Scalars['Int']['output'];
  voucherTotal: Scalars['Int']['output'];
};

export type OrderDiscountCodeRule = {
  __typename?: 'OrderDiscountCodeRule';
  code: Scalars['String']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  freeShipping?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shippingTags?: Maybe<Array<Scalars['String']['output']>>;
};

export type OrderDiscountExternalRule = {
  __typename?: 'OrderDiscountExternalRule';
  currencyCode?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  freeShipping?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  shippingTags?: Maybe<Array<Scalars['String']['output']>>;
};

export type OrderDiscountOutcome = {
  __typename?: 'OrderDiscountOutcome';
  cartRules?: Maybe<Array<CartRuleOutcome>>;
  codeRules?: Maybe<Array<CodeRuleOutcome>>;
  discountReduction?: Maybe<Scalars['Int']['output']>;
  externalRules?: Maybe<Array<ExternalRuleOutcome>>;
  freeShipping?: Maybe<Scalars['Boolean']['output']>;
  shippingTags?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscountAmount?: Maybe<Scalars['Int']['output']>;
};

export type OrderDiscountRule = {
  __typename?: 'OrderDiscountRule';
  currencyCode?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  freeShipping?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shippingTags?: Maybe<Array<Scalars['String']['output']>>;
};

export type OrderGift = {
  __typename?: 'OrderGift';
  basePriceAmount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountRuleId: Scalars['String']['output'];
  displayDescription: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productParentId: Scalars['String']['output'];
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  salePriceAmount: Scalars['Int']['output'];
  taxGroupId: Scalars['String']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalPriceAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type OrderGiftCard = {
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderGiftCardBsgGiftCard = OrderGiftCard & {
  __typename?: 'OrderGiftCardBSGGiftCard';
  giftCards?: Maybe<Array<OrderGiftCardItemBsgGiftCard>>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderGiftCardEmpty = OrderGiftCard & {
  __typename?: 'OrderGiftCardEmpty';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderGiftCardItemBsgGiftCard = {
  __typename?: 'OrderGiftCardItemBSGGiftCard';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reservationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type OrderGiftCardItemKbsGiftCard = {
  __typename?: 'OrderGiftCardItemKBSGiftCard';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reservationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type OrderGiftCardItemRetain24 = {
  __typename?: 'OrderGiftCardItemRetain24';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  redeemId?: Maybe<Scalars['String']['output']>;
  reservationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type OrderGiftCardKbsGiftCard = OrderGiftCard & {
  __typename?: 'OrderGiftCardKBSGiftCard';
  giftCards?: Maybe<Array<OrderGiftCardItemKbsGiftCard>>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderGiftCardProduct = {
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
};

export type OrderGiftCardProductEmpty = OrderGiftCardProduct & {
  __typename?: 'OrderGiftCardProductEmpty';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
};

export type OrderGiftCardProductItem = {
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  distribution: Scalars['String']['output'];
  id: Scalars['String']['output'];
  merchantReference?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  recipient?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type OrderGiftCardProductItemRetain24 = OrderGiftCardProductItem & {
  __typename?: 'OrderGiftCardProductItemRetain24';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  distribution: Scalars['String']['output'];
  emailText?: Maybe<Scalars['String']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  merchantReference?: Maybe<Scalars['String']['output']>;
  multiCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  recipient?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  sendDate?: Maybe<Scalars['AWSDateTime']['output']>;
  smsText?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  templateId: Scalars['Int']['output'];
  transactionId?: Maybe<Scalars['Int']['output']>;
};

export type OrderGiftCardProductRetain24 = OrderGiftCardProduct & {
  __typename?: 'OrderGiftCardProductRetain24';
  giftCardProducts?: Maybe<Array<OrderGiftCardProductItemRetain24>>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderGiftCardRetain24 = OrderGiftCard & {
  __typename?: 'OrderGiftCardRetain24';
  giftCards?: Maybe<Array<OrderGiftCardItemRetain24>>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  history: Array<OrderHistoryLogEntry>;
  operations?: Maybe<OrderHistoryOperations>;
  /** @deprecated moved to Order.status */
  states: Array<OrderState>;
};

export type OrderHistoryLogEntry = {
  __typename?: 'OrderHistoryLogEntry';
  actor?: Maybe<Actor>;
  comment?: Maybe<Comment>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  operation: Scalars['String']['output'];
  operationId: Scalars['ID']['output'];
  reason?: Maybe<Reason>;
  status: Scalars['String']['output'];
  timestamp: Scalars['AWSDateTime']['output'];
  type: Scalars['String']['output'];
};

export type OrderHistoryOperations = {
  __typename?: 'OrderHistoryOperations';
  compensated: OrderHistoryTransactionsCompensated;
  delivered: OrderHistoryTransactions;
  refunded: OrderHistoryTransactions;
  released: OrderHistoryTransactions;
};

export type OrderHistoryTransactionBonus = {
  __typename?: 'OrderHistoryTransactionBonus';
  amount: Scalars['Int']['output'];
};

export type OrderHistoryTransactionGift = {
  __typename?: 'OrderHistoryTransactionGift';
  giftId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export type OrderHistoryTransactionGiftCard = {
  __typename?: 'OrderHistoryTransactionGiftCard';
  amount: Scalars['Int']['output'];
  giftCardId: Scalars['String']['output'];
};

export type OrderHistoryTransactionGiftCardProduct = {
  __typename?: 'OrderHistoryTransactionGiftCardProduct';
  giftCardProductId: Scalars['String']['output'];
};

export type OrderHistoryTransactionOrderLine = {
  __typename?: 'OrderHistoryTransactionOrderLine';
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export type OrderHistoryTransactionPayment = {
  __typename?: 'OrderHistoryTransactionPayment';
  amount: Scalars['Int']['output'];
};

export type OrderHistoryTransactionShippingFee = {
  __typename?: 'OrderHistoryTransactionShippingFee';
  shippingFeeId: Scalars['String']['output'];
};

export type OrderHistoryTransactionVoucher = {
  __typename?: 'OrderHistoryTransactionVoucher';
  amount: Scalars['Int']['output'];
  voucherId: Scalars['String']['output'];
};

export type OrderHistoryTransactions = {
  __typename?: 'OrderHistoryTransactions';
  bonus?: Maybe<OrderHistoryTransactionBonus>;
  giftCardProducts: Array<OrderHistoryTransactionGiftCardProduct>;
  giftCards: Array<OrderHistoryTransactionGiftCard>;
  gifts: Array<OrderHistoryTransactionGift>;
  orderLines: Array<OrderHistoryTransactionOrderLine>;
  shippingFees: Array<OrderHistoryTransactionShippingFee>;
  vouchers: Array<OrderHistoryTransactionVoucher>;
};

export type OrderHistoryTransactionsCompensated = {
  __typename?: 'OrderHistoryTransactionsCompensated';
  bonus?: Maybe<OrderHistoryTransactionBonus>;
  giftCards: Array<OrderHistoryTransactionGiftCard>;
  payment?: Maybe<OrderHistoryTransactionPayment>;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  basePriceAmount: Scalars['Int']['output'];
  campaign?: Maybe<CampaignIdentity>;
  currencyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountOutcome: OrderLineDiscountOutcome;
  displayDescription: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  distributedTotalDiscountAmount: Scalars['Int']['output'];
  distributedTotalPriceAmount: Scalars['Int']['output'];
  distributedTotalTaxAmount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options: Array<OrderLineOption>;
  productParentId: Scalars['String']['output'];
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  salePriceAmount: Scalars['Int']['output'];
  taxGroupId: Scalars['String']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalPriceAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type OrderLineDiscountOutcome = {
  __typename?: 'OrderLineDiscountOutcome';
  bundles?: Maybe<Array<BundleOutcome>>;
  cartRules?: Maybe<Array<CartRuleOutcome>>;
  codeRules?: Maybe<Array<CodeRuleOutcome>>;
  externalRules?: Maybe<Array<ExternalRuleOutcome>>;
  totalDiscountAmount?: Maybe<Scalars['Int']['output']>;
};

export type OrderLineModification = {
  __typename?: 'OrderLineModification';
  data: OrderLineModificationCreateData | OrderLineModificationDeleteData | OrderLineModificationUpdateData;
  modificationType: OrderLineModificationType;
};

export type OrderLineModificationCreateData = OrderLineModificationData & {
  __typename?: 'OrderLineModificationCreateData';
  basePriceAmount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalDiscountAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalPriceAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productParentId?: Maybe<Scalars['String']['output']>;
  productVariantId?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  salePriceAmount?: Maybe<Scalars['Int']['output']>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']['output']>;
  totalDiscountAmount?: Maybe<Scalars['Int']['output']>;
  totalPriceAmount?: Maybe<Scalars['Int']['output']>;
  totalTaxAmount?: Maybe<Scalars['Int']['output']>;
};

export type OrderLineModificationData = {
  id: Scalars['String']['output'];
};

export type OrderLineModificationDeleteData = OrderLineModificationData & {
  __typename?: 'OrderLineModificationDeleteData';
  basePriceAmount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  distributedTotalDiscountAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalPriceAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productParentId?: Maybe<Scalars['String']['output']>;
  productVariantId?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  salePriceAmount?: Maybe<Scalars['Int']['output']>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']['output']>;
  totalDiscountAmount?: Maybe<Scalars['Int']['output']>;
  totalPriceAmount?: Maybe<Scalars['Int']['output']>;
  totalTaxAmount?: Maybe<Scalars['Int']['output']>;
};

export enum OrderLineModificationType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type OrderLineModificationUpdateData = OrderLineModificationData & {
  __typename?: 'OrderLineModificationUpdateData';
  description?: Maybe<Scalars['String']['output']>;
  displayDescription?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<OrderLineModificationUpdateDataPrice>;
  productParentId?: Maybe<Scalars['String']['output']>;
  productVariantId?: Maybe<Scalars['String']['output']>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
};

export type OrderLineModificationUpdateDataPrice = {
  __typename?: 'OrderLineModificationUpdateDataPrice';
  basePriceAmount: Scalars['Int']['output'];
  discountAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalDiscountAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalPriceAmount?: Maybe<Scalars['Int']['output']>;
  distributedTotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  salePriceAmount?: Maybe<Scalars['Int']['output']>;
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals?: Maybe<Scalars['Int']['output']>;
  totalDiscountAmount?: Maybe<Scalars['Int']['output']>;
  totalPriceAmount: Scalars['Int']['output'];
  totalTaxAmount?: Maybe<Scalars['Int']['output']>;
};

export type OrderLineOption = {
  __typename?: 'OrderLineOption';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type OrderModification = {
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  id: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  orderReference: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  type: OrderModificationType;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderModificationOrderLines = OrderModification & {
  __typename?: 'OrderModificationOrderLines';
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  id: Scalars['String']['output'];
  newTotals?: Maybe<OrderTotals>;
  oldTotals?: Maybe<OrderTotals>;
  orderId: Scalars['String']['output'];
  orderLines: Array<OrderLineModification>;
  orderReference: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  type: OrderModificationType;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export enum OrderModificationType {
  OrderLines = 'ORDER_LINES'
}

export type OrderOperationResult = {
  __typename?: 'OrderOperationResult';
  status: Scalars['String']['output'];
};

export type OrderPayment = {
  method?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type OrderPaymentAdyen = OrderPayment & {
  __typename?: 'OrderPaymentAdyen';
  deepLinkPath?: Maybe<Scalars['String']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  merchantAccountCode: Scalars['String']['output'];
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  potentialFraud?: Maybe<Scalars['Boolean']['output']>;
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderPaymentAvarda = OrderPayment & {
  __typename?: 'OrderPaymentAvarda';
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  purchaseId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderPaymentBrinkZeroPayment = OrderPayment & {
  __typename?: 'OrderPaymentBrinkZeroPayment';
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  originalProvider: OrderPaymentBrinkZeroPaymentOriginalProvider;
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type OrderPaymentBrinkZeroPaymentOriginalProvider = {
  __typename?: 'OrderPaymentBrinkZeroPaymentOriginalProvider';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OrderPaymentKlarnaCheckout = OrderPayment & {
  __typename?: 'OrderPaymentKlarnaCheckout';
  deepLinkPath?: Maybe<Scalars['String']['output']>;
  fraudStatus?: Maybe<Scalars['String']['output']>;
  klarnaMerchantId: Scalars['String']['output'];
  klarnaOrderId: Scalars['String']['output'];
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderPaymentQliroOne = OrderPayment & {
  __typename?: 'OrderPaymentQliroOne';
  identityVerified: Scalars['Boolean']['output'];
  merchantProvidedQuestionAnswer?: Maybe<Scalars['Boolean']['output']>;
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  orderItems?: Maybe<Array<OrderPaymentQliroOneOrderItem>>;
  paymentTypeCode?: Maybe<Scalars['String']['output']>;
  privateVerificationCode: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  qliroOrderId: Scalars['Int']['output'];
  reference: Scalars['String']['output'];
  requireIdentityVerification: Scalars['Boolean']['output'];
  signupForNewsletter?: Maybe<Scalars['Boolean']['output']>;
  status: Scalars['String']['output'];
  upsell?: Maybe<OrderPaymentQliroOneUpsell>;
};

export type OrderPaymentQliroOneOrderItem = {
  __typename?: 'OrderPaymentQliroOneOrderItem';
  merchantReference: Scalars['String']['output'];
  paymentTransactionId: Scalars['Int']['output'];
};

export type OrderPaymentQliroOneUpsell = {
  __typename?: 'OrderPaymentQliroOneUpsell';
  isTaxIncludedInPrice: Scalars['Boolean']['output'];
  items: Array<OrderPaymentQliroOneUpsellItems>;
  status: Scalars['String']['output'];
  totals: OrderPaymentQliroOneUpsellTotals;
};

export type OrderPaymentQliroOneUpsellItems = {
  __typename?: 'OrderPaymentQliroOneUpsellItems';
  displayName: Scalars['String']['output'];
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalPriceAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type OrderPaymentQliroOneUpsellTotals = {
  __typename?: 'OrderPaymentQliroOneUpsellTotals';
  discountTotal: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  subTotal: Scalars['Int']['output'];
  taxTotal: Scalars['Int']['output'];
};

export type OrderPaymentSveaCheckout = OrderPayment & {
  __typename?: 'OrderPaymentSveaCheckout';
  expirationDate?: Maybe<Scalars['String']['output']>;
  isCompany?: Maybe<Scalars['Boolean']['output']>;
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  peppolId?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
  sveaMerchantId: Scalars['String']['output'];
  sveaOrderId: Scalars['Int']['output'];
};

export type OrderPaymentWalleyCheckout = OrderPayment & {
  __typename?: 'OrderPaymentWalleyCheckout';
  amountToPay: Scalars['Float']['output'];
  method: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  purchaseIdentifier: Scalars['String']['output'];
  purchaseResult: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type OrderPromotion = {
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderPromotionEmpty = OrderPromotion & {
  __typename?: 'OrderPromotionEmpty';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderPromotionItemVoyado = {
  __typename?: 'OrderPromotionItemVoyado';
  failedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrderPromotionVoyado = OrderPromotion & {
  __typename?: 'OrderPromotionVoyado';
  contactId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  promotions?: Maybe<Array<OrderPromotionItemVoyado>>;
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderRefund = {
  __typename?: 'OrderRefund';
  bonus?: Maybe<RefundBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  fee?: Maybe<RefundFee>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<RefundGiftCard>;
  gifts: Array<RefundGift>;
  id: Scalars['ID']['output'];
  orderId: Scalars['String']['output'];
  orderLines: Array<RefundOrderLine>;
  orderReference: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  refundedPayment?: Maybe<RefundedPayment>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingFees: Array<RefundShippingFee>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  totals: OrderRefundTotals;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
  vouchers: Array<RefundVoucher>;
};

export type OrderRefundTotals = {
  __typename?: 'OrderRefundTotals';
  bonusTotal: Scalars['Int']['output'];
  giftCardTotal: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  shippingTotal: Scalars['Int']['output'];
  subTotal: Scalars['Int']['output'];
  voucherTotal: Scalars['Int']['output'];
};

export type OrderRelease = {
  __typename?: 'OrderRelease';
  bonus?: Maybe<ReleaseBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']['output']>;
  created: Scalars['AWSDateTime']['output'];
  failed?: Maybe<Scalars['AWSDateTime']['output']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProducts: Array<ReleaseGiftCardProduct>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<ReleaseGiftCard>;
  gifts: Array<ReleaseGift>;
  id: Scalars['ID']['output'];
  orderId: Scalars['String']['output'];
  orderLines: Array<ReleaseOrderLine>;
  orderReference: Scalars['String']['output'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  releasedPayment?: Maybe<ReleasedPayment>;
  restarted?: Maybe<Scalars['AWSDateTime']['output']>;
  restarts?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  shippingFees: Array<ReleaseShippingFee>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']['output']>;
  updated: Scalars['AWSDateTime']['output'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderReservation = {
  __typename?: 'OrderReservation';
  created: Scalars['AWSDateTime']['output'];
  orderId: Scalars['String']['output'];
  processedProductVariants: Array<ProductVariantReservation>;
  sessionId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type OrderSearchHit = {
  __typename?: 'OrderSearchHit';
  billingAddress: OrderAddress;
  channelType?: Maybe<ChannelType>;
  countryCode: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  date: Scalars['AWSDateTime']['output'];
  discountCodes?: Maybe<Array<Scalars['String']['output']>>;
  discountExternalReferences?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isTaxExempt?: Maybe<Scalars['Boolean']['output']>;
  isTaxExemptionEligible?: Maybe<Scalars['Boolean']['output']>;
  merchantReference1?: Maybe<Scalars['String']['output']>;
  merchantReference2?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  paymentProviderName: Scalars['String']['output'];
  paymentReference: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  shippingAddress: OrderAddress;
  status: OrderStatus;
  storeGroupId: Scalars['String']['output'];
  taxationCountry?: Maybe<Scalars['String']['output']>;
  totals: OrderTotals;
};

export type OrderSearchHits = {
  __typename?: 'OrderSearchHits';
  aggregations?: Maybe<Scalars['AWSJSON']['output']>;
  hits: Array<OrderSearchHit>;
  total: Scalars['Int']['output'];
};

export type OrderShipping = {
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type OrderShippingAvardaShippingBroker = OrderShipping & {
  __typename?: 'OrderShippingAvardaShippingBroker';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  shipping?: Maybe<AvardaShippingBrokerShipping>;
  shippingBroker: Scalars['String']['output'];
};

export type OrderShippingEmpty = OrderShipping & {
  __typename?: 'OrderShippingEmpty';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type OrderShippingIngrid = OrderShipping & {
  __typename?: 'OrderShippingIngrid';
  deliveries?: Maybe<Array<IngridDelivery>>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type OrderShippingKlarnaShippingAssistant = OrderShipping & {
  __typename?: 'OrderShippingKlarnaShippingAssistant';
  klarnaMerchantId: Scalars['String']['output'];
  klarnaOrderId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  selectedShippingOption: KlarnaShippingAssistantSelectedShippingOption;
};

export type OrderShippingNshift = OrderShipping & {
  __typename?: 'OrderShippingNshift';
  orderId: Scalars['String']['output'];
  preparedShipment?: Maybe<NshiftPreparedShipment>;
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type OrderShippingQliroIntegratedShipping = OrderShipping & {
  __typename?: 'OrderShippingQliroIntegratedShipping';
  ingrid?: Maybe<QliroIngrid>;
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  shipments: Array<QliroShipmentOrderItem>;
};

export type OrderShippingWalleyDeliveryModule = OrderShipping & {
  __typename?: 'OrderShippingWalleyDeliveryModule';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  purchaseIdentifier: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  shipping: WalleyDeliveryModuleShipping;
};

export enum OrderState {
  Cancelled = 'CANCELLED',
  FullyCompensated = 'FULLY_COMPENSATED',
  FullyDelivered = 'FULLY_DELIVERED',
  FullyRefunded = 'FULLY_REFUNDED',
  PartiallyCompensated = 'PARTIALLY_COMPENSATED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Placed = 'PLACED',
  Released = 'RELEASED'
}

export type OrderStatus = {
  __typename?: 'OrderStatus';
  customStates?: Maybe<Array<OrderCustomState>>;
  orderStates: Array<OrderState>;
};

export type OrderTotals = {
  __typename?: 'OrderTotals';
  bonusTotal: Scalars['Int']['output'];
  discountTotal: Scalars['Int']['output'];
  giftCardTotal: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  shippingTotal: Scalars['Int']['output'];
  subTotal: Scalars['Int']['output'];
  taxTotal: Scalars['Int']['output'];
  voucherTotal: Scalars['Int']['output'];
};

export type OrderVoucher = {
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderVoucherEmpty = OrderVoucher & {
  __typename?: 'OrderVoucherEmpty';
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderVoucherItemVoyado = {
  __typename?: 'OrderVoucherItemVoyado';
  amount: Scalars['Int']['output'];
  checkNumber: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderVoucherVoyado = OrderVoucher & {
  __typename?: 'OrderVoucherVoyado';
  contactId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  status: Scalars['String']['output'];
  vouchers?: Maybe<Array<OrderVoucherItemVoyado>>;
};

export type Outcome = {
  __typename?: 'Outcome';
  combineItemDiscount?: Maybe<Scalars['Boolean']['output']>;
  discountItemRule?: Maybe<ItemRule>;
  discountQuantity?: Maybe<Scalars['Int']['output']>;
  discountValue?: Maybe<DiscountValue>;
  gifts?: Maybe<Array<Gift>>;
  id: Scalars['ID']['output'];
  isRecurrent?: Maybe<Scalars['Boolean']['output']>;
  shippingTags?: Maybe<Array<Scalars['String']['output']>>;
  subOutcomes?: Maybe<Array<SubOutcome>>;
  triggerItemRule?: Maybe<ItemRule>;
  triggerQuantity?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  providerId: Scalars['String']['output'];
  providerName: PaymentProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum PaymentProviderName {
  Adyen = 'Adyen',
  Avarda = 'Avarda',
  BrinkZeroPayment = 'BrinkZeroPayment',
  KlarnaCheckout = 'KlarnaCheckout',
  QliroOne = 'QliroOne',
  SveaCheckout = 'SveaCheckout',
  WalleyCheckout = 'WalleyCheckout'
}

export type PlatformConfig = {
  __typename?: 'PlatformConfig';
  externalApi: ExternalApi;
};

export type Price = {
  __typename?: 'Price';
  basePriceAmount: Scalars['Int']['output'];
  campaign?: Maybe<CampaignIdentity>;
  currencyCode: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  lowestPriceRecord?: Maybe<PriceRecord>;
  priceRecords: Array<PriceRecord>;
  referencePriceAmount?: Maybe<Scalars['Int']['output']>;
  salePriceAmount: Scalars['Int']['output'];
  startDate: Scalars['AWSDateTime']['output'];
};

export type PriceRecord = {
  __typename?: 'PriceRecord';
  basePriceAmount: Scalars['Int']['output'];
  campaign?: Maybe<CampaignIdentity>;
  endDate: Scalars['AWSDateTime']['output'];
  salePriceAmount: Scalars['Int']['output'];
  startDate: Scalars['AWSDateTime']['output'];
};

export type PriceRule = {
  __typename?: 'PriceRule';
  monies?: Maybe<Array<CampaignMoney>>;
  percentage?: Maybe<Scalars['Int']['output']>;
  percentageDecimals?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type ProductParent = {
  __typename?: 'ProductParent';
  Variants?: Maybe<Array<ProductVariant>>;
  created: Scalars['AWSDateTime']['output'];
  customAttributes?: Maybe<Scalars['AWSJSON']['output']>;
  description: Scalars['String']['output'];
  displayDescriptions: Scalars['AWSJSON']['output'];
  displayNames: Scalars['AWSJSON']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  tags?: Maybe<Scalars['AWSJSON']['output']>;
  updated: Scalars['AWSDateTime']['output'];
};

export type ProductParentSearchHit = {
  __typename?: 'ProductParentSearchHit';
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  tags?: Maybe<Array<KeyValue>>;
  updated: Scalars['AWSDateTime']['output'];
};

export type ProductParentSearchHits = {
  __typename?: 'ProductParentSearchHits';
  hits: Array<ProductParentSearchHit>;
  total: Scalars['Int']['output'];
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  Stock?: Maybe<Stock>;
  StoreGroupPrices: Array<StoreGroupPrice>;
  TaxGroup: TaxGroup;
  created: Scalars['AWSDateTime']['output'];
  customAttributes?: Maybe<Scalars['AWSJSON']['output']>;
  description: Scalars['String']['output'];
  dimensions?: Maybe<Dimensions>;
  displayDescriptions: Scalars['AWSJSON']['output'];
  displayNames: Scalars['AWSJSON']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  productParentId: Scalars['ID']['output'];
  shippingAttributes?: Maybe<Array<Scalars['String']['output']>>;
  tags?: Maybe<Scalars['AWSJSON']['output']>;
  updated: Scalars['AWSDateTime']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
};

export type ProductVariantReservation = {
  __typename?: 'ProductVariantReservation';
  inventoryId: Scalars['String']['output'];
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type ProductVariantRules = {
  __typename?: 'ProductVariantRules';
  tagLogic: Scalars['String']['output'];
};

export type ProductVariantSearchHit = {
  __typename?: 'ProductVariantSearchHit';
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  productParentId: Scalars['String']['output'];
  tags?: Maybe<Array<KeyValue>>;
  taxGroupId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type ProductVariantSearchHits = {
  __typename?: 'ProductVariantSearchHits';
  hits: Array<ProductVariantSearchHit>;
  total: Scalars['Int']['output'];
};

export type PromotionProvider = {
  __typename?: 'PromotionProvider';
  providerId: Scalars['String']['output'];
  providerName: PromotionProviderName;
};

export enum PromotionProviderName {
  Voyado = 'Voyado'
}

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type ProviderStatusLog = {
  __typename?: 'ProviderStatusLog';
  current: Scalars['String']['output'];
  history: Array<ProviderStatusLogEntry>;
};

export type ProviderStatusLogEntry = {
  __typename?: 'ProviderStatusLogEntry';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['AWSDateTime']['output']>;
  type: Scalars['String']['output'];
};

export type QliroIngrid = {
  __typename?: 'QliroIngrid';
  deliveries?: Maybe<Array<QliroIngridDelivery>>;
};

export type QliroIngridDelivery = {
  __typename?: 'QliroIngridDelivery';
  carrierProductId?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<QliroIngridDeliveryAddress>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  locationExternalId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  taxAmount?: Maybe<Scalars['Int']['output']>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']['output']>;
  tosId?: Maybe<Scalars['String']['output']>;
};

export type QliroIngridDeliveryAddress = {
  __typename?: 'QliroIngridDeliveryAddress';
  addressLines?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type QliroOneConfig = {
  __typename?: 'QliroOneConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: QliroOneCredentials;
  id: Scalars['ID']['output'];
  integratedShipping?: Maybe<QliroOneIntegratedShipping>;
  merchantOrderManagementStatusPushUrl: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
  useLegacyIds?: Maybe<Scalars['Boolean']['output']>;
};

export type QliroOneCredentials = {
  __typename?: 'QliroOneCredentials';
  merchantApiKey: Scalars['String']['output'];
  merchantApiSecret: Scalars['String']['output'];
};

export type QliroOneIntegratedShipping = {
  __typename?: 'QliroOneIntegratedShipping';
  deliveryCheckoutId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type QliroShipmentOrderItem = {
  __typename?: 'QliroShipmentOrderItem';
  description: Scalars['String']['output'];
  merchantReference: Scalars['String']['output'];
  metadata?: Maybe<QliroShipmentOrderItemMetadata>;
  paymentTransactionId: Scalars['Int']['output'];
  pricePerItemExVat: Scalars['Float']['output'];
  pricePerItemIncVat: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type QliroShipmentOrderItemMetadata = {
  __typename?: 'QliroShipmentOrderItemMetadata';
  shippingMethodMerchantReference: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  Configuration?: Maybe<QueryConfigurationManagement>;
  Order: QueryOrderManagement;
  PlatformConfig?: Maybe<QueryPlatformConfig>;
  Product: QueryProductManagement;
  apiClient: ApiClientDetails;
  apiClients: ApiClientsList;
  apiResources: Array<ApiResource>;
  getAdyenConfig: AdyenConfig;
  getAdyenWebhooks: Array<AdyenWebhookLegacy>;
  getAvardaConfig: AvardaConfig;
  getBSGGiftCardConfig: BsgGiftCardConfig;
  getBonusConfig: BonusConfig;
  getBonusConfigs: Array<CapabilityProvider>;
  getBundleGroup: BundleGroup;
  getCampaign: Campaign;
  getCampaignGroup: CampaignGroupList;
  getCurrencyRates?: Maybe<CurrencyRates>;
  getDiscountCode: DiscountCode;
  getDiscountCodeRule: DiscountCodeRule;
  getDiscountExternalRule: DiscountExternalRule;
  getDiscountRule: DiscountRule;
  getExternalEventsDestinationAPI: ExternalEventDestinationApi;
  getExternalEventsDestinationAPIs: ExternalEventDestinationApIs;
  getExternalEventsDestinationEventBuses: ExternalEventDestinationEventBuses;
  getExternalEventsRule: ExternalEventRuleState;
  getExternalEventsRules: ExternalEventRules;
  getGenerateCodes: GenerateCodes;
  getGiftCardProviders: Array<Provider>;
  getIngridConfig: IngridConfig;
  getInventories: Array<Inventory>;
  getInventory: Inventory;
  getInventoryStoreMarket: InventoryStoreMarket;
  getKBSGiftCardConfig: KbsGiftCardConfig;
  getKlarnaCheckoutConfig: KlarnaCheckoutConfig;
  getNShiftConfig: NShiftConfig;
  getPaymentProviders: Array<Provider>;
  getQliroOneConfig: QliroOneConfig;
  getRetain24Config: Retain24Config;
  getShippingProviders: Array<Provider>;
  getShippingTaxGroup: ShippingTaxGroup;
  getStoreGroup: StoreGroup;
  getStoreGroups: Array<StoreGroup>;
  getSveaCheckoutConfig: SveaCheckoutConfig;
  getTaxGroup: TaxGroup;
  getTaxMarkets: Array<TaxMarket>;
  getVoyadoConfig: VoyadoConfig;
  getVoyadoConfigs: Array<CapabilityProvider>;
  getWalleyCheckoutConfig: WalleyCheckoutConfig;
  groupUsers: UsersList;
  groups?: Maybe<GroupsList>;
  searchBundleGroups?: Maybe<BundleGroupSearchHits>;
  searchCampaignGroups?: Maybe<CampaignGroupSearchHits>;
  searchCampaigns?: Maybe<CampaignSearchHits>;
  searchCodesGenerations?: Maybe<CodesGenerationSearchHits>;
  searchDiscountCodeRules?: Maybe<DiscountCodeRuleSearchHits>;
  searchDiscountCodes?: Maybe<DiscountCodeSearchHits>;
  searchDiscountExternalRules?: Maybe<DiscountExternalRuleSearchHits>;
  searchDiscountRules?: Maybe<DiscountRuleSearchHits>;
  searchOrders?: Maybe<OrderSearchHits>;
  searchProductParents?: Maybe<ProductParentSearchHits>;
  searchProductVariants?: Maybe<ProductVariantSearchHits>;
  searchStoreGroups?: Maybe<StoreGroupSearchHits>;
  searchStoreMarkets?: Maybe<StoreMarketSearchHits>;
  searchTaxGroups?: Maybe<TaxGroupSearchHits>;
  searchTaxMarkets?: Maybe<TaxMarketSearchHits>;
  tagKeys?: Maybe<TagKeys>;
  tagValues?: Maybe<TagValues>;
  user: UserDetails;
  users: UsersList;
};


export type QueryApiClientArgs = {
  clientId: Scalars['ID']['input'];
};


export type QueryApiClientsArgs = {
  limit: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdyenConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAvardaConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBsgGiftCardConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBonusConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBundleGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCampaignGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCurrencyRatesArgs = {
  baseCurrencyCode: Scalars['String']['input'];
  filterCurrencies: Array<Scalars['String']['input']>;
};


export type QueryGetDiscountCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetDiscountCodeRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDiscountExternalRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDiscountRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetExternalEventsDestinationApiArgs = {
  destName: Scalars['String']['input'];
};


export type QueryGetExternalEventsRuleArgs = {
  ruleName: Scalars['String']['input'];
};


export type QueryGetGenerateCodesArgs = {
  jobId: Scalars['String']['input'];
};


export type QueryGetIngridConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInventoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInventoryStoreMarketArgs = {
  countryCode: Scalars['String']['input'];
  storeGroupId: Scalars['String']['input'];
};


export type QueryGetKbsGiftCardConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetKlarnaCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNShiftConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetQliroOneConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetRetain24ConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStoreGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSveaCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTaxGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTaxMarketsArgs = {
  taxGroupId: Scalars['String']['input'];
};


export type QueryGetVoyadoConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWalleyCheckoutConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGroupUsersArgs = {
  groupname: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGroupsArgs = {
  limit: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchBundleGroupsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchCampaignGroupsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchCampaignsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchCodesGenerationsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchDiscountCodeRulesArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchDiscountCodesArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchDiscountExternalRulesArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchDiscountRulesArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchOrdersArgs = {
  aggs?: InputMaybe<Scalars['AWSJSON']['input']>;
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchProductParentsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchProductVariantsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchStoreGroupsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchStoreMarketsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchTaxGroupsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchTaxMarketsArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['AWSJSON']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTagValuesArgs = {
  key: Scalars['String']['input'];
  prefix?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  username: Scalars['AWSEmail']['input'];
};


export type QueryUsersArgs = {
  limit: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type QueryConfigurationManagement = {
  __typename?: 'QueryConfigurationManagement';
  validate?: Maybe<QueryConfigurationManagementValidate>;
};

export type QueryConfigurationManagementValidate = {
  __typename?: 'QueryConfigurationManagementValidate';
  adyen: ValidateConfigResult;
  avarda: ValidateConfigResult;
  bonus: ValidateConfigResult;
  klarnaCheckout: ValidateConfigResult;
  qliroOne: ValidateConfigResult;
  retain24: ValidateConfigResult;
  sveaCheckout: ValidateConfigResult;
  walleyCheckout: ValidateConfigResult;
};


export type QueryConfigurationManagementValidateAdyenArgs = {
  baseUrl: Scalars['String']['input'];
  environment?: InputMaybe<Scalars['String']['input']>;
  merchantAccount: Scalars['String']['input'];
  xApiKey: Scalars['String']['input'];
};


export type QueryConfigurationManagementValidateAvardaArgs = {
  authUrl: Scalars['String']['input'];
  baseUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};


export type QueryConfigurationManagementValidateBonusArgs = {
  input: InputBonusConfig;
};


export type QueryConfigurationManagementValidateKlarnaCheckoutArgs = {
  baseUrl: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryConfigurationManagementValidateQliroOneArgs = {
  input: InputQliroOneConfig;
};


export type QueryConfigurationManagementValidateRetain24Args = {
  input: InputRetain24Config;
};


export type QueryConfigurationManagementValidateSveaCheckoutArgs = {
  baseUrl: Scalars['String']['input'];
  checkoutSecret: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
};


export type QueryConfigurationManagementValidateWalleyCheckoutArgs = {
  input: InputWalleyCheckoutConfig;
};

export type QueryOrderManagement = {
  __typename?: 'QueryOrderManagement';
  cancellation: OrderCancellation;
  compensation: OrderCompensation;
  customReasonCode: CustomReasonCode;
  customReasonCodes: CustomReasonCodes;
  customState: CustomState;
  customStates: CustomStates;
  delivery: OrderDelivery;
  details: Order;
  history: OrderHistory;
  orderModificationOrderLines?: Maybe<OrderModificationOrderLines>;
  refund: OrderRefund;
  release: OrderRelease;
  /** @deprecated replaced by order history */
  status: OrderHistory;
};


export type QueryOrderManagementCancellationArgs = {
  cancellationId: Scalars['ID']['input'];
};


export type QueryOrderManagementCompensationArgs = {
  compensationId: Scalars['ID']['input'];
};


export type QueryOrderManagementCustomReasonCodeArgs = {
  code: Scalars['ID']['input'];
};


export type QueryOrderManagementCustomStateArgs = {
  stateId: Scalars['ID']['input'];
};


export type QueryOrderManagementDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
};


export type QueryOrderManagementDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderManagementHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderManagementOrderModificationOrderLinesArgs = {
  modificationId: Scalars['ID']['input'];
};


export type QueryOrderManagementRefundArgs = {
  refundId: Scalars['ID']['input'];
};


export type QueryOrderManagementReleaseArgs = {
  releaseId: Scalars['ID']['input'];
};


export type QueryOrderManagementStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPlatformConfig = {
  __typename?: 'QueryPlatformConfig';
  campaign?: Maybe<PlatformConfig>;
  discount?: Maybe<PlatformConfig>;
  order?: Maybe<PlatformConfig>;
  price?: Maybe<PlatformConfig>;
  product?: Maybe<PlatformConfig>;
  stock?: Maybe<PlatformConfig>;
  store?: Maybe<PlatformConfig>;
  tax?: Maybe<PlatformConfig>;
};

export type QueryProductManagement = {
  __typename?: 'QueryProductManagement';
  parent: ProductParent;
  variant: ProductVariant;
};


export type QueryProductManagementParentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductManagementVariantArgs = {
  id: Scalars['ID']['input'];
};

export enum QuotaPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type Reason = {
  __typename?: 'Reason';
  cause?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
};

export type RefundBonus = {
  __typename?: 'RefundBonus';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  reservationId: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
};

export type RefundFee = {
  __typename?: 'RefundFee';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  taxAmount: Scalars['Int']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
};

export type RefundGift = {
  __typename?: 'RefundGift';
  giftId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export type RefundGiftCard = {
  __typename?: 'RefundGiftCard';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  giftCardId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type RefundOrderLine = {
  __typename?: 'RefundOrderLine';
  currencyCode: Scalars['String']['output'];
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type RefundShippingFee = {
  __typename?: 'RefundShippingFee';
  currencyCode: Scalars['String']['output'];
  shippingFeeId: Scalars['ID']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type RefundVoucher = {
  __typename?: 'RefundVoucher';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  voucherId: Scalars['ID']['output'];
};

export type RefundedPayment = {
  __typename?: 'RefundedPayment';
  reference: Scalars['String']['output'];
};

export type ReleaseBonus = {
  __typename?: 'ReleaseBonus';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  reservationId: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type ReleaseGift = {
  __typename?: 'ReleaseGift';
  giftId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export type ReleaseGiftCard = {
  __typename?: 'ReleaseGiftCard';
  amount: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  giftCardId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type ReleaseGiftCardProduct = {
  __typename?: 'ReleaseGiftCardProduct';
  cancelErrorMessage?: Maybe<Scalars['String']['output']>;
  cancelErrorStatusCode?: Maybe<Scalars['Int']['output']>;
  cancelTransactionId?: Maybe<Scalars['String']['output']>;
  createFailedReason?: Maybe<Scalars['String']['output']>;
  createId?: Maybe<Scalars['String']['output']>;
  createTransactionId?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  giftCardProductId: Scalars['ID']['output'];
  priceAmount: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type ReleaseOrderLine = {
  __typename?: 'ReleaseOrderLine';
  currencyCode: Scalars['String']['output'];
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type ReleaseShippingFee = {
  __typename?: 'ReleaseShippingFee';
  currencyCode: Scalars['String']['output'];
  shippingFeeId: Scalars['ID']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalTaxAmount: Scalars['Int']['output'];
};

export type ReleasedPayment = {
  __typename?: 'ReleasedPayment';
  reference: Scalars['String']['output'];
};

export type Retain24Config = {
  __typename?: 'Retain24Config';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: Retain24Credentials;
  id: Scalars['ID']['output'];
  redeem: Scalars['Boolean']['output'];
  simulationKey?: Maybe<Scalars['String']['output']>;
  updated: Scalars['AWSDateTime']['output'];
};

export type Retain24Credentials = {
  __typename?: 'Retain24Credentials';
  certificate: Scalars['String']['output'];
  privateKey: Scalars['String']['output'];
};

export type RoundingRule = {
  __typename?: 'RoundingRule';
  roundDown: Scalars['Boolean']['output'];
  roundUp: Scalars['Boolean']['output'];
  targets: Array<Scalars['String']['output']>;
};

export type RoundingRules = {
  __typename?: 'RoundingRules';
  defaultRule: RoundingRule;
  marketRules?: Maybe<Array<MarketRoundingRule>>;
};

export type Rule = {
  __typename?: 'Rule';
  discountAmount: Scalars['Int']['output'];
  discountRuleId: Scalars['String']['output'];
};

export type Schema = {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
  subscriptions?: Maybe<Subscription>;
};

export type SearchSorting = {
  field: Scalars['String']['input'];
  order?: InputMaybe<SortOrder>;
};

export type ShippingFee = {
  __typename?: 'ShippingFee';
  basePriceAmount?: Maybe<Scalars['Int']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  salePriceAmount?: Maybe<Scalars['Int']['output']>;
  taxAmount?: Maybe<Scalars['Int']['output']>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']['output']>;
};

export type ShippingProvider = {
  __typename?: 'ShippingProvider';
  providerId: Scalars['String']['output'];
  providerName: ShippingProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum ShippingProviderName {
  AvardaShippingBroker = 'AvardaShippingBroker',
  Ingrid = 'Ingrid',
  KlarnaShippingAssistant = 'KlarnaShippingAssistant',
  Nshift = 'Nshift',
  QliroIntegratedShipping = 'QliroIntegratedShipping',
  WalleyDeliveryModule = 'WalleyDeliveryModule'
}

export type ShippingTaxGroup = {
  __typename?: 'ShippingTaxGroup';
  created: Scalars['AWSDateTime']['output'];
  taxGroupId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Stock = {
  __typename?: 'Stock';
  id: Scalars['ID']['output'];
  inventories: Array<StockInventory>;
  stockQuantity: Scalars['Int']['output'];
  validateStock: Scalars['Boolean']['output'];
};

export type StockInventory = {
  __typename?: 'StockInventory';
  created: Scalars['AWSDateTime']['output'];
  inventoryId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type StoreGroup = {
  __typename?: 'StoreGroup';
  channelType: ChannelType;
  countryOfSale?: Maybe<Scalars['String']['output']>;
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  storeMarkets?: Maybe<Array<StoreMarket>>;
  updated: Scalars['AWSDateTime']['output'];
};

export type StoreGroupPrice = {
  __typename?: 'StoreGroupPrice';
  marketPrices: Array<MarketPrice>;
  storeGroupId: Scalars['ID']['output'];
};

export type StoreGroupSearchHit = {
  __typename?: 'StoreGroupSearchHit';
  countryOfSale?: Maybe<Scalars['String']['output']>;
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type StoreGroupSearchHits = {
  __typename?: 'StoreGroupSearchHits';
  hits: Array<StoreGroupSearchHit>;
  total: Scalars['Int']['output'];
};

export type StoreMarket = {
  __typename?: 'StoreMarket';
  capabilities?: Maybe<Capabilities>;
  cartCapabilities?: Maybe<CartCapabilities>;
  channelType: ChannelType;
  countryCode: Scalars['String']['output'];
  countryOfSale?: Maybe<Scalars['String']['output']>;
  created: Scalars['AWSDateTime']['output'];
  currencyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isTaxExemptionEligible?: Maybe<Scalars['Boolean']['output']>;
  isTaxIncludedInPrice: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  storeGroupId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type StoreMarketSearchHit = {
  __typename?: 'StoreMarketSearchHit';
  cartCapabilities?: Maybe<CartCapabilities>;
  countryCode: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  currencyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  giftCardProviders?: Maybe<Array<Provider>>;
  isArchived: Scalars['Boolean']['output'];
  isTaxExemptionEligible?: Maybe<Scalars['Boolean']['output']>;
  isTaxIncludedInPrice: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  paymentProviders?: Maybe<Array<Provider>>;
  shippingProviders?: Maybe<Array<Provider>>;
  storeGroupId: Scalars['String']['output'];
  taxProviders?: Maybe<Array<Provider>>;
  updated: Scalars['AWSDateTime']['output'];
};

export type StoreMarketSearchHits = {
  __typename?: 'StoreMarketSearchHits';
  hits: Array<StoreMarketSearchHit>;
  total: Scalars['Int']['output'];
};

export type SubOutcome = {
  __typename?: 'SubOutcome';
  discountQuantity?: Maybe<Scalars['Int']['output']>;
  discountValue?: Maybe<DiscountValue>;
  triggerQuantity?: Maybe<Scalars['Int']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  OnAdyenWebhookChanged?: Maybe<AdyenWebhook>;
};


export type SubscriptionOnAdyenWebhookChangedArgs = {
  configId: Scalars['String']['input'];
};

export type SveaCheckoutConfig = {
  __typename?: 'SveaCheckoutConfig';
  checkoutBaseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: SveaCredentials;
  id: Scalars['ID']['output'];
  merchantId: Scalars['String']['output'];
  paymentAdminBaseUrl: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type SveaCredentials = {
  __typename?: 'SveaCredentials';
  apiKey: Scalars['String']['output'];
};

export type TagCondition = {
  __typename?: 'TagCondition';
  key: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type TagKeys = {
  __typename?: 'TagKeys';
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

export type TagValues = {
  __typename?: 'TagValues';
  key: Scalars['String']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type TaxGroup = {
  __typename?: 'TaxGroup';
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type TaxGroupSearchHit = {
  __typename?: 'TaxGroupSearchHit';
  created: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type TaxGroupSearchHits = {
  __typename?: 'TaxGroupSearchHits';
  hits: Array<TaxGroupSearchHit>;
  total: Scalars['Int']['output'];
};

export type TaxMarket = {
  __typename?: 'TaxMarket';
  countryCode: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  taxGroupId: Scalars['String']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type TaxMarketSearchHit = {
  __typename?: 'TaxMarketSearchHit';
  countryCode: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  taxGroupId: Scalars['String']['output'];
  taxPercentage: Scalars['Int']['output'];
  taxPercentageDecimals: Scalars['Int']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type TaxMarketSearchHits = {
  __typename?: 'TaxMarketSearchHits';
  hits: Array<TaxMarketSearchHit>;
  total: Scalars['Int']['output'];
};

export type UpdateExternalEventsDestinationApiResult = {
  __typename?: 'UpdateExternalEventsDestinationAPIResult';
  apiDestinationName: Scalars['String']['output'];
};

export type UsagePlan = {
  __typename?: 'UsagePlan';
  burstLimit: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  quotaLimit: Scalars['Int']['output'];
  quotaPeriod: QuotaPeriod;
  rateLimit: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  created: Scalars['AWSDateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  status: UserStatus;
  updated: Scalars['AWSDateTime']['output'];
  username: Scalars['ID']['output'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  created: Scalars['AWSDateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  groups: Array<Group>;
  status: UserStatus;
  updated: Scalars['AWSDateTime']['output'];
  username: Scalars['ID']['output'];
};

export enum UserStatus {
  Archived = 'ARCHIVED',
  Confirmed = 'CONFIRMED',
  ExternalProvider = 'EXTERNAL_PROVIDER',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ResetRequired = 'RESET_REQUIRED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN'
}

export type UsersList = {
  __typename?: 'UsersList';
  nextToken?: Maybe<Scalars['String']['output']>;
  users: Array<User>;
};

export type ValidDateRange = {
  __typename?: 'ValidDateRange';
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type ValidateConfigResult = {
  __typename?: 'ValidateConfigResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type VoucherProvider = {
  __typename?: 'VoucherProvider';
  providerId: Scalars['String']['output'];
  providerName: VoucherProviderName;
};

export enum VoucherProviderName {
  Voyado = 'Voyado'
}

export type VoyadoConfig = {
  __typename?: 'VoyadoConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: VoyadoCredentials;
  id: Scalars['ID']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type VoyadoCredentials = {
  __typename?: 'VoyadoCredentials';
  apiKey: Scalars['String']['output'];
};

export type WalleyCheckoutConfig = {
  __typename?: 'WalleyCheckoutConfig';
  baseUrl: Scalars['String']['output'];
  created: Scalars['AWSDateTime']['output'];
  credentials: WalleyCredentials;
  deliveryModuleEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  profileName?: Maybe<Scalars['String']['output']>;
  scope: Scalars['String']['output'];
  storeId: Scalars['String']['output'];
  updated: Scalars['AWSDateTime']['output'];
};

export type WalleyCredentials = {
  __typename?: 'WalleyCredentials';
  clientId: Scalars['String']['output'];
  secret: Scalars['String']['output'];
};

export type WalleyDeliveryModuleDeliveryDate = {
  __typename?: 'WalleyDeliveryModuleDeliveryDate';
  date: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  timeFrom: Scalars['String']['output'];
  timeTo: Scalars['String']['output'];
};

export type WalleyDeliveryModuleDestination = {
  __typename?: 'WalleyDeliveryModuleDestination';
  deliveryDate?: Maybe<WalleyDeliveryModuleDeliveryDate>;
  fee: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type WalleyDeliveryModuleOption = {
  __typename?: 'WalleyDeliveryModuleOption';
  Fee: Scalars['Float']['output'];
  Type: Scalars['String']['output'];
  Value: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type WalleyDeliveryModuleShipment = {
  __typename?: 'WalleyDeliveryModuleShipment';
  bookedShipmentId: Scalars['String']['output'];
  feeItemId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shippingChoice: WalleyDeliveryModuleShippingChoice;
};

export type WalleyDeliveryModuleShipping = {
  __typename?: 'WalleyDeliveryModuleShipping';
  provider: Scalars['String']['output'];
  shipments: Array<WalleyDeliveryModuleShipment>;
  shippingFee: Scalars['Float']['output'];
};

export type WalleyDeliveryModuleShippingChoice = {
  __typename?: 'WalleyDeliveryModuleShippingChoice';
  destination: WalleyDeliveryModuleDestination;
  fee: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  options: Array<WalleyDeliveryModuleOption>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  OrderGiftCard: ( OrderGiftCardBsgGiftCard ) | ( OrderGiftCardEmpty ) | ( OrderGiftCardKbsGiftCard ) | ( OrderGiftCardRetain24 );
  OrderGiftCardProduct: ( OrderGiftCardProductEmpty ) | ( OrderGiftCardProductRetain24 );
  OrderGiftCardProductItem: ( OrderGiftCardProductItemRetain24 );
  OrderLineModificationData: ( OrderLineModificationCreateData ) | ( OrderLineModificationDeleteData ) | ( OrderLineModificationUpdateData );
  OrderModification: ( Omit<OrderModificationOrderLines, 'newTotals' | 'oldTotals' | 'orderLines'> & { newTotals?: Maybe<_RefType['OrderTotals']>, oldTotals?: Maybe<_RefType['OrderTotals']>, orderLines: Array<_RefType['OrderLineModification']> } );
  OrderPayment: ( OrderPaymentAdyen ) | ( OrderPaymentAvarda ) | ( OrderPaymentBrinkZeroPayment ) | ( OrderPaymentKlarnaCheckout ) | ( OrderPaymentQliroOne ) | ( OrderPaymentSveaCheckout ) | ( OrderPaymentWalleyCheckout );
  OrderPromotion: ( OrderPromotionEmpty ) | ( OrderPromotionVoyado );
  OrderShipping: ( OrderShippingAvardaShippingBroker ) | ( OrderShippingEmpty ) | ( OrderShippingIngrid ) | ( OrderShippingKlarnaShippingAssistant ) | ( OrderShippingNshift ) | ( OrderShippingQliroIntegratedShipping ) | ( OrderShippingWalleyDeliveryModule );
  OrderVoucher: ( OrderVoucherEmpty ) | ( OrderVoucherVoyado );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']['output']>;
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']['output']>;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']['output']>;
  Actor: ResolverTypeWrapper<Actor>;
  AddExternalEventsDestinationAPIResult: ResolverTypeWrapper<AddExternalEventsDestinationApiResult>;
  AddExternalEventsDestinationEventBusResult: ResolverTypeWrapper<AddExternalEventsDestinationEventBusResult>;
  AddExternalEventsRuleResult: ResolverTypeWrapper<AddExternalEventsRuleResult>;
  AdyenConfig: ResolverTypeWrapper<AdyenConfig>;
  AdyenCredentials: ResolverTypeWrapper<AdyenCredentials>;
  AdyenEnvironment: AdyenEnvironment;
  AdyenWebhook: ResolverTypeWrapper<AdyenWebhook>;
  AdyenWebhookCredentials: ResolverTypeWrapper<AdyenWebhookCredentials>;
  AdyenWebhookGenerationResult: ResolverTypeWrapper<AdyenWebhookGenerationResult>;
  AdyenWebhookLegacy: ResolverTypeWrapper<AdyenWebhookLegacy>;
  AdyenWebhookStatus: AdyenWebhookStatus;
  AdyenWebhookTarget: AdyenWebhookTarget;
  ApiClient: ResolverTypeWrapper<ApiClient>;
  ApiClientDetails: ResolverTypeWrapper<ApiClientDetails>;
  ApiClientsList: ResolverTypeWrapper<ApiClientsList>;
  ApiResource: ResolverTypeWrapper<ApiResource>;
  ApiScope: ResolverTypeWrapper<ApiScope>;
  AvardaConfig: ResolverTypeWrapper<AvardaConfig>;
  AvardaCredentials: ResolverTypeWrapper<AvardaCredentials>;
  AvardaShippingBroker: ResolverTypeWrapper<AvardaShippingBroker>;
  AvardaShippingBrokerShipping: ResolverTypeWrapper<AvardaShippingBrokerShipping>;
  AvardaShippingBrokerShippingIngrid: ResolverTypeWrapper<AvardaShippingBrokerShippingIngrid>;
  AvardaShippingBrokerShippingIngridCategory: ResolverTypeWrapper<AvardaShippingBrokerShippingIngridCategory>;
  AvardaShippingBrokerShippingIngridCategoryTag: ResolverTypeWrapper<AvardaShippingBrokerShippingIngridCategoryTag>;
  AvardaShippingBrokerShippingIngridLocation: ResolverTypeWrapper<AvardaShippingBrokerShippingIngridLocation>;
  AvardaShippingBrokerShippingIngridLocationAddress: ResolverTypeWrapper<AvardaShippingBrokerShippingIngridLocationAddress>;
  BSGGiftCardConfig: ResolverTypeWrapper<BsgGiftCardConfig>;
  BSGGiftCardCredentials: ResolverTypeWrapper<BsgGiftCardCredentials>;
  BonusConfig: ResolverTypeWrapper<BonusConfig>;
  BonusCredentials: ResolverTypeWrapper<BonusCredentials>;
  BonusProvider: ResolverTypeWrapper<BonusProvider>;
  BonusProviderName: BonusProviderName;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Bundle: ResolverTypeWrapper<Bundle>;
  BundleGroup: ResolverTypeWrapper<BundleGroup>;
  BundleGroupSearchHit: ResolverTypeWrapper<BundleGroupSearchHit>;
  BundleGroupSearchHits: ResolverTypeWrapper<BundleGroupSearchHits>;
  BundleItemRule: ResolverTypeWrapper<BundleItemRule>;
  BundleOutcome: ResolverTypeWrapper<BundleOutcome>;
  BundleStoreMarket: ResolverTypeWrapper<BundleStoreMarket>;
  Campaign: ResolverTypeWrapper<Campaign>;
  CampaignDeletionResult: ResolverTypeWrapper<CampaignDeletionResult>;
  CampaignGroup: ResolverTypeWrapper<CampaignGroup>;
  CampaignGroupList: ResolverTypeWrapper<CampaignGroupList>;
  CampaignGroupSearchHit: ResolverTypeWrapper<CampaignGroupSearchHit>;
  CampaignGroupSearchHits: ResolverTypeWrapper<CampaignGroupSearchHits>;
  CampaignIdentity: ResolverTypeWrapper<CampaignIdentity>;
  CampaignMoney: ResolverTypeWrapper<CampaignMoney>;
  CampaignSearchHit: ResolverTypeWrapper<CampaignSearchHit>;
  CampaignSearchHits: ResolverTypeWrapper<CampaignSearchHits>;
  CampaignStoreMarket: ResolverTypeWrapper<CampaignStoreMarket>;
  CancellationBonus: ResolverTypeWrapper<CancellationBonus>;
  CancellationGiftCard: ResolverTypeWrapper<CancellationGiftCard>;
  CancellationGiftCardProduct: ResolverTypeWrapper<CancellationGiftCardProduct>;
  CancelledPayment: ResolverTypeWrapper<CancelledPayment>;
  Capabilities: ResolverTypeWrapper<Capabilities>;
  CapabilityProvider: ResolverTypeWrapper<CapabilityProvider>;
  CartCapabilities: ResolverTypeWrapper<CartCapabilities>;
  CartRuleOutcome: ResolverTypeWrapper<CartRuleOutcome>;
  ChannelType: ChannelType;
  CodeRuleOutcome: ResolverTypeWrapper<CodeRuleOutcome>;
  CodesGenerationSearchHit: ResolverTypeWrapper<CodesGenerationSearchHit>;
  CodesGenerationSearchHits: ResolverTypeWrapper<CodesGenerationSearchHits>;
  Comment: ResolverTypeWrapper<Comment>;
  CompanyAttribute: ResolverTypeWrapper<CompanyAttribute>;
  CompensationBonus: ResolverTypeWrapper<CompensationBonus>;
  CompensationFee: ResolverTypeWrapper<CompensationFee>;
  CompensationGiftCard: ResolverTypeWrapper<CompensationGiftCard>;
  CompensationOrderLine: ResolverTypeWrapper<CompensationOrderLine>;
  CompensationPayment: ResolverTypeWrapper<CompensationPayment>;
  CompensationShippingFee: ResolverTypeWrapper<CompensationShippingFee>;
  Condition: ResolverTypeWrapper<Condition>;
  CreateGenerateCodesResult: ResolverTypeWrapper<CreateGenerateCodesResult>;
  CurrencyRate: ResolverTypeWrapper<CurrencyRate>;
  CurrencyRates: ResolverTypeWrapper<CurrencyRates>;
  CustomOperation: CustomOperation;
  CustomReasonCode: ResolverTypeWrapper<CustomReasonCode>;
  CustomReasonCodes: ResolverTypeWrapper<CustomReasonCodes>;
  CustomState: ResolverTypeWrapper<CustomState>;
  CustomStateType: CustomStateType;
  CustomStates: ResolverTypeWrapper<CustomStates>;
  DeletedResult: ResolverTypeWrapper<DeletedResult>;
  DeliveryBonus: ResolverTypeWrapper<DeliveryBonus>;
  DeliveryCapture: ResolverTypeWrapper<DeliveryCapture>;
  DeliveryGift: ResolverTypeWrapper<DeliveryGift>;
  DeliveryGiftCard: ResolverTypeWrapper<DeliveryGiftCard>;
  DeliveryGiftCardProduct: ResolverTypeWrapper<DeliveryGiftCardProduct>;
  DeliveryOrderLine: ResolverTypeWrapper<DeliveryOrderLine>;
  DeliveryShippingFee: ResolverTypeWrapper<DeliveryShippingFee>;
  DeliveryTracking: ResolverTypeWrapper<DeliveryTracking>;
  DeliveryVoucher: ResolverTypeWrapper<DeliveryVoucher>;
  Dimensions: ResolverTypeWrapper<Dimensions>;
  DiscountCode: ResolverTypeWrapper<DiscountCode>;
  DiscountCodeRule: ResolverTypeWrapper<DiscountCodeRule>;
  DiscountCodeRuleSearchHit: ResolverTypeWrapper<DiscountCodeRuleSearchHit>;
  DiscountCodeRuleSearchHits: ResolverTypeWrapper<DiscountCodeRuleSearchHits>;
  DiscountCodeSearchHit: ResolverTypeWrapper<DiscountCodeSearchHit>;
  DiscountCodeSearchHits: ResolverTypeWrapper<DiscountCodeSearchHits>;
  DiscountExternalRule: ResolverTypeWrapper<DiscountExternalRule>;
  DiscountExternalRuleSearchHit: ResolverTypeWrapper<DiscountExternalRuleSearchHit>;
  DiscountExternalRuleSearchHits: ResolverTypeWrapper<DiscountExternalRuleSearchHits>;
  DiscountManagementQuotas: ResolverTypeWrapper<DiscountManagementQuotas>;
  DiscountRule: ResolverTypeWrapper<DiscountRule>;
  DiscountRuleSearchHit: ResolverTypeWrapper<DiscountRuleSearchHit>;
  DiscountRuleSearchHits: ResolverTypeWrapper<DiscountRuleSearchHits>;
  DiscountValue: ResolverTypeWrapper<DiscountValue>;
  ExternalApi: ResolverTypeWrapper<ExternalApi>;
  ExternalEventDestinationAPI: ResolverTypeWrapper<ExternalEventDestinationApi>;
  ExternalEventDestinationAPIFilter: ResolverTypeWrapper<ExternalEventDestinationApiFilter>;
  ExternalEventDestinationAPIFilterPattern: ResolverTypeWrapper<ExternalEventDestinationApiFilterPattern>;
  ExternalEventDestinationAPIs: ResolverTypeWrapper<ExternalEventDestinationApIs>;
  ExternalEventDestinationEventBuses: ResolverTypeWrapper<ExternalEventDestinationEventBuses>;
  ExternalEventDestinationsDetails: ResolverTypeWrapper<ExternalEventDestinationsDetails>;
  ExternalEventRuleState: ResolverTypeWrapper<ExternalEventRuleState>;
  ExternalEventRules: ResolverTypeWrapper<ExternalEventRules>;
  ExternalRuleOutcome: ResolverTypeWrapper<ExternalRuleOutcome>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GenerateCodes: ResolverTypeWrapper<GenerateCodes>;
  Gift: ResolverTypeWrapper<Gift>;
  GiftCardProductProvider: ResolverTypeWrapper<GiftCardProductProvider>;
  GiftCardProductProviderName: GiftCardProductProviderName;
  GiftCardProvider: ResolverTypeWrapper<GiftCardProvider>;
  GiftCardProviderName: GiftCardProviderName;
  Group: ResolverTypeWrapper<Group>;
  GroupDetails: ResolverTypeWrapper<GroupDetails>;
  GroupsList: ResolverTypeWrapper<GroupsList>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IngridConfig: ResolverTypeWrapper<IngridConfig>;
  IngridCredentials: ResolverTypeWrapper<IngridCredentials>;
  IngridDelivery: ResolverTypeWrapper<IngridDelivery>;
  IngridDeliveryAddress: ResolverTypeWrapper<IngridDeliveryAddress>;
  InputActionBonus: InputActionBonus;
  InputActionGiftCard: InputActionGiftCard;
  InputActionGiftCardManual: InputActionGiftCardManual;
  InputActionPaymentCancellation: InputActionPaymentCancellation;
  InputActionPaymentCompensation: InputActionPaymentCompensation;
  InputActionPaymentDelivery: InputActionPaymentDelivery;
  InputActionPaymentOrderModificationOrderLines: InputActionPaymentOrderModificationOrderLines;
  InputActionPaymentRefund: InputActionPaymentRefund;
  InputActionPaymentRelease: InputActionPaymentRelease;
  InputActionShipping: InputActionShipping;
  InputActionType: InputActionType;
  InputAddExternalEventsDestinationAPI: InputAddExternalEventsDestinationApi;
  InputAddUserToGroup: InputAddUserToGroup;
  InputAdyenConfig: InputAdyenConfig;
  InputAdyenWebhookChange: InputAdyenWebhookChange;
  InputAvardaConfig: InputAvardaConfig;
  InputAvardaShippingBroker: InputAvardaShippingBroker;
  InputBSGGiftCardConfig: InputBsgGiftCardConfig;
  InputBonusConfig: InputBonusConfig;
  InputBundle: InputBundle;
  InputBundleItemRule: InputBundleItemRule;
  InputCampaign: InputCampaign;
  InputCampaignGroup: InputCampaignGroup;
  InputCampaignMoney: InputCampaignMoney;
  InputCampaignPeriod: InputCampaignPeriod;
  InputCapabilities: InputCapabilities;
  InputCapabilityProvider: InputCapabilityProvider;
  InputCartCapabilities: InputCartCapabilities;
  InputComment: InputComment;
  InputCondition: InputCondition;
  InputConditionType: InputConditionType;
  InputCreateApiClient: InputCreateApiClient;
  InputCreateGenerateCodes: InputCreateGenerateCodes;
  InputCreateOrUpdateBundleGroup: InputCreateOrUpdateBundleGroup;
  InputCreateOrUpdateDiscountCode: InputCreateOrUpdateDiscountCode;
  InputCreateOrUpdateDiscountCodeRule: InputCreateOrUpdateDiscountCodeRule;
  InputCreateOrUpdateDiscountExternalRule: InputCreateOrUpdateDiscountExternalRule;
  InputCreateOrUpdateDiscountRule: InputCreateOrUpdateDiscountRule;
  InputCreateStoreGroup: InputCreateStoreGroup;
  InputCreateStoreMarket: InputCreateStoreMarket;
  InputCreateUser: InputCreateUser;
  InputCustomReasonCode: InputCustomReasonCode;
  InputCustomState: InputCustomState;
  InputDiscountValue: InputDiscountValue;
  InputDiscountValueType: InputDiscountValueType;
  InputExternalEventsApiKeyAuth: InputExternalEventsApiKeyAuth;
  InputExternalEventsBasicAuth: InputExternalEventsBasicAuth;
  InputGift: InputGift;
  InputGiftCardProductSelection: InputGiftCardProductSelection;
  InputIngridConfig: InputIngridConfig;
  InputInventory: InputInventory;
  InputInventoryAddress: InputInventoryAddress;
  InputInventoryProductVariantStock: InputInventoryProductVariantStock;
  InputItemRule: InputItemRule;
  InputKBSGiftCardConfig: InputKbsGiftCardConfig;
  InputKlarnaCheckoutConfig: InputKlarnaCheckoutConfig;
  InputLogicHolder: InputLogicHolder;
  InputMarketRoundingRule: InputMarketRoundingRule;
  InputMoney: InputMoney;
  InputNShiftConfig: InputNShiftConfig;
  InputOrderCancellation: InputOrderCancellation;
  InputOrderCompensation: InputOrderCompensation;
  InputOrderCompensationBonus: InputOrderCompensationBonus;
  InputOrderCompensationFee: InputOrderCompensationFee;
  InputOrderCompensationGiftCard: InputOrderCompensationGiftCard;
  InputOrderCompensationPayment: InputOrderCompensationPayment;
  InputOrderDelivery: InputOrderDelivery;
  InputOrderGiftSelection: InputOrderGiftSelection;
  InputOrderLineCompensation: InputOrderLineCompensation;
  InputOrderLineModification: InputOrderLineModification;
  InputOrderLineSelection: InputOrderLineSelection;
  InputOrderModificationOrderLines: InputOrderModificationOrderLines;
  InputOrderModificationOrderLinesStart: InputOrderModificationOrderLinesStart;
  InputOrderRefund: InputOrderRefund;
  InputOrderRefundFee: InputOrderRefundFee;
  InputOrderRelease: InputOrderRelease;
  InputOrderShippingFeeCompensation: InputOrderShippingFeeCompensation;
  InputOrderStartCancellation: InputOrderStartCancellation;
  InputOrderStartCompensation: InputOrderStartCompensation;
  InputOrderStartDelivery: InputOrderStartDelivery;
  InputOrderStartRefund: InputOrderStartRefund;
  InputOrderStartRelease: InputOrderStartRelease;
  InputOutcome: InputOutcome;
  InputOutcomeType: InputOutcomeType;
  InputPriceRule: InputPriceRule;
  InputPriceRuleType: InputPriceRuleType;
  InputProductVariantRules: InputProductVariantRules;
  InputProductVariantStockConfig: InputProductVariantStockConfig;
  InputProvider: InputProvider;
  InputQliroOneConfig: InputQliroOneConfig;
  InputQliroOneIntegratedShipping: InputQliroOneIntegratedShipping;
  InputReason: InputReason;
  InputRemoveUserFromGroup: InputRemoveUserFromGroup;
  InputRetain24Config: InputRetain24Config;
  InputRoundingRule: InputRoundingRule;
  InputRoundingRules: InputRoundingRules;
  InputStoreMarket: InputStoreMarket;
  InputSubOutcome: InputSubOutcome;
  InputSveaCheckoutConfig: InputSveaCheckoutConfig;
  InputTagCondition: InputTagCondition;
  InputTagConditionMode: InputTagConditionMode;
  InputTaxGroup: InputTaxGroup;
  InputTaxMarket: InputTaxMarket;
  InputUpdateApiClient: InputUpdateApiClient;
  InputUpdateExternalEventsDestinationAPI: InputUpdateExternalEventsDestinationApi;
  InputUpdateStoreGroup: InputUpdateStoreGroup;
  InputUpdateStoreMarket: InputUpdateStoreMarket;
  InputUpdateUser: InputUpdateUser;
  InputValidDateRange: InputValidDateRange;
  InputVoyadoConfig: InputVoyadoConfig;
  InputWalleyCheckoutConfig: InputWalleyCheckoutConfig;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Inventory: ResolverTypeWrapper<Inventory>;
  InventoryAddress: ResolverTypeWrapper<InventoryAddress>;
  InventoryProductVariantStock: ResolverTypeWrapper<InventoryProductVariantStock>;
  InventoryProductVariantStockConfig: ResolverTypeWrapper<InventoryProductVariantStockConfig>;
  InventoryStoreMarket: ResolverTypeWrapper<InventoryStoreMarket>;
  InventoryStoreMarketInventories: ResolverTypeWrapper<InventoryStoreMarketInventories>;
  ItemRule: ResolverTypeWrapper<ItemRule>;
  KBSGiftCardConfig: ResolverTypeWrapper<KbsGiftCardConfig>;
  KBSGiftCardCredentials: ResolverTypeWrapper<KbsGiftCardCredentials>;
  KeyValue: ResolverTypeWrapper<KeyValue>;
  KlarnaCheckoutConfig: ResolverTypeWrapper<KlarnaCheckoutConfig>;
  KlarnaCredentials: ResolverTypeWrapper<KlarnaCredentials>;
  KlarnaShippingAssistantAddon: ResolverTypeWrapper<KlarnaShippingAssistantAddon>;
  KlarnaShippingAssistantAddress: ResolverTypeWrapper<KlarnaShippingAssistantAddress>;
  KlarnaShippingAssistantCarrierProduct: ResolverTypeWrapper<KlarnaShippingAssistantCarrierProduct>;
  KlarnaShippingAssistantLocation: ResolverTypeWrapper<KlarnaShippingAssistantLocation>;
  KlarnaShippingAssistantLocationTimeslot: ResolverTypeWrapper<KlarnaShippingAssistantLocationTimeslot>;
  KlarnaShippingAssistantSelectedShippingOption: ResolverTypeWrapper<KlarnaShippingAssistantSelectedShippingOption>;
  LogicHolder: ResolverTypeWrapper<LogicHolder>;
  MarketPrice: ResolverTypeWrapper<MarketPrice>;
  MarketRoundingRule: ResolverTypeWrapper<MarketRoundingRule>;
  Money: ResolverTypeWrapper<Money>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationOrderManagement: ResolverTypeWrapper<Omit<MutationOrderManagement, 'createOrderModificationOrderLines' | 'updateOrderModificationOrderLines'> & { createOrderModificationOrderLines: ResolversTypes['OrderModificationOrderLines'], updateOrderModificationOrderLines: ResolversTypes['OrderModificationOrderLines'] }>;
  NShiftConfig: ResolverTypeWrapper<NShiftConfig>;
  NShiftCredentials: ResolverTypeWrapper<NShiftCredentials>;
  NshiftAddress: ResolverTypeWrapper<NshiftAddress>;
  NshiftPreparedShipment: ResolverTypeWrapper<NshiftPreparedShipment>;
  NshiftService: ResolverTypeWrapper<NshiftService>;
  OperationResult: ResolverTypeWrapper<OperationResult>;
  Order: ResolverTypeWrapper<Omit<Order, 'GiftCard' | 'GiftCardProduct' | 'Modifications' | 'Payment' | 'Promotion' | 'Shipping' | 'Voucher' | 'totals'> & { GiftCard: ResolversTypes['OrderGiftCard'], GiftCardProduct: ResolversTypes['OrderGiftCardProduct'], Modifications: Array<ResolversTypes['OrderModification']>, Payment: ResolversTypes['OrderPayment'], Promotion: ResolversTypes['OrderPromotion'], Shipping: ResolversTypes['OrderShipping'], Voucher: ResolversTypes['OrderVoucher'], totals: ResolversTypes['OrderTotals'] }>;
  OrderAddress: ResolverTypeWrapper<OrderAddress>;
  OrderAttributes: ResolverTypeWrapper<OrderAttributes>;
  OrderBonus: ResolverTypeWrapper<OrderBonus>;
  OrderBundle: ResolverTypeWrapper<OrderBundle>;
  OrderCancellation: ResolverTypeWrapper<OrderCancellation>;
  OrderComment: ResolverTypeWrapper<OrderComment>;
  OrderCompensation: ResolverTypeWrapper<OrderCompensation>;
  OrderCompensationTotals: ResolverTypeWrapper<OrderCompensationTotals>;
  OrderCustomState: ResolverTypeWrapper<OrderCustomState>;
  OrderDelivery: ResolverTypeWrapper<OrderDelivery>;
  OrderDeliveryTotals: ResolverTypeWrapper<OrderDeliveryTotals>;
  OrderDiscountCodeRule: ResolverTypeWrapper<OrderDiscountCodeRule>;
  OrderDiscountExternalRule: ResolverTypeWrapper<OrderDiscountExternalRule>;
  OrderDiscountOutcome: ResolverTypeWrapper<OrderDiscountOutcome>;
  OrderDiscountRule: ResolverTypeWrapper<OrderDiscountRule>;
  OrderGift: ResolverTypeWrapper<OrderGift>;
  OrderGiftCard: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderGiftCard']>;
  OrderGiftCardBSGGiftCard: ResolverTypeWrapper<OrderGiftCardBsgGiftCard>;
  OrderGiftCardEmpty: ResolverTypeWrapper<OrderGiftCardEmpty>;
  OrderGiftCardItemBSGGiftCard: ResolverTypeWrapper<OrderGiftCardItemBsgGiftCard>;
  OrderGiftCardItemKBSGiftCard: ResolverTypeWrapper<OrderGiftCardItemKbsGiftCard>;
  OrderGiftCardItemRetain24: ResolverTypeWrapper<OrderGiftCardItemRetain24>;
  OrderGiftCardKBSGiftCard: ResolverTypeWrapper<OrderGiftCardKbsGiftCard>;
  OrderGiftCardProduct: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderGiftCardProduct']>;
  OrderGiftCardProductEmpty: ResolverTypeWrapper<OrderGiftCardProductEmpty>;
  OrderGiftCardProductItem: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderGiftCardProductItem']>;
  OrderGiftCardProductItemRetain24: ResolverTypeWrapper<OrderGiftCardProductItemRetain24>;
  OrderGiftCardProductRetain24: ResolverTypeWrapper<OrderGiftCardProductRetain24>;
  OrderGiftCardRetain24: ResolverTypeWrapper<OrderGiftCardRetain24>;
  OrderHistory: ResolverTypeWrapper<OrderHistory>;
  OrderHistoryLogEntry: ResolverTypeWrapper<OrderHistoryLogEntry>;
  OrderHistoryOperations: ResolverTypeWrapper<OrderHistoryOperations>;
  OrderHistoryTransactionBonus: ResolverTypeWrapper<OrderHistoryTransactionBonus>;
  OrderHistoryTransactionGift: ResolverTypeWrapper<OrderHistoryTransactionGift>;
  OrderHistoryTransactionGiftCard: ResolverTypeWrapper<OrderHistoryTransactionGiftCard>;
  OrderHistoryTransactionGiftCardProduct: ResolverTypeWrapper<OrderHistoryTransactionGiftCardProduct>;
  OrderHistoryTransactionOrderLine: ResolverTypeWrapper<OrderHistoryTransactionOrderLine>;
  OrderHistoryTransactionPayment: ResolverTypeWrapper<OrderHistoryTransactionPayment>;
  OrderHistoryTransactionShippingFee: ResolverTypeWrapper<OrderHistoryTransactionShippingFee>;
  OrderHistoryTransactionVoucher: ResolverTypeWrapper<OrderHistoryTransactionVoucher>;
  OrderHistoryTransactions: ResolverTypeWrapper<OrderHistoryTransactions>;
  OrderHistoryTransactionsCompensated: ResolverTypeWrapper<OrderHistoryTransactionsCompensated>;
  OrderLine: ResolverTypeWrapper<OrderLine>;
  OrderLineDiscountOutcome: ResolverTypeWrapper<OrderLineDiscountOutcome>;
  OrderLineModification: ResolverTypeWrapper<Omit<OrderLineModification, 'data'> & { data: ResolversTypes['OrderLineModificationData'] }>;
  OrderLineModificationCreateData: ResolverTypeWrapper<OrderLineModificationCreateData>;
  OrderLineModificationData: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderLineModificationData']>;
  OrderLineModificationDeleteData: ResolverTypeWrapper<OrderLineModificationDeleteData>;
  OrderLineModificationType: OrderLineModificationType;
  OrderLineModificationUpdateData: ResolverTypeWrapper<OrderLineModificationUpdateData>;
  OrderLineModificationUpdateDataPrice: ResolverTypeWrapper<OrderLineModificationUpdateDataPrice>;
  OrderLineOption: ResolverTypeWrapper<OrderLineOption>;
  OrderModification: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderModification']>;
  OrderModificationOrderLines: ResolverTypeWrapper<Omit<OrderModificationOrderLines, 'newTotals' | 'oldTotals' | 'orderLines'> & { newTotals?: Maybe<ResolversTypes['OrderTotals']>, oldTotals?: Maybe<ResolversTypes['OrderTotals']>, orderLines: Array<ResolversTypes['OrderLineModification']> }>;
  OrderModificationType: OrderModificationType;
  OrderOperationResult: ResolverTypeWrapper<OrderOperationResult>;
  OrderPayment: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderPayment']>;
  OrderPaymentAdyen: ResolverTypeWrapper<OrderPaymentAdyen>;
  OrderPaymentAvarda: ResolverTypeWrapper<OrderPaymentAvarda>;
  OrderPaymentBrinkZeroPayment: ResolverTypeWrapper<OrderPaymentBrinkZeroPayment>;
  OrderPaymentBrinkZeroPaymentOriginalProvider: ResolverTypeWrapper<OrderPaymentBrinkZeroPaymentOriginalProvider>;
  OrderPaymentKlarnaCheckout: ResolverTypeWrapper<OrderPaymentKlarnaCheckout>;
  OrderPaymentQliroOne: ResolverTypeWrapper<OrderPaymentQliroOne>;
  OrderPaymentQliroOneOrderItem: ResolverTypeWrapper<OrderPaymentQliroOneOrderItem>;
  OrderPaymentQliroOneUpsell: ResolverTypeWrapper<OrderPaymentQliroOneUpsell>;
  OrderPaymentQliroOneUpsellItems: ResolverTypeWrapper<OrderPaymentQliroOneUpsellItems>;
  OrderPaymentQliroOneUpsellTotals: ResolverTypeWrapper<OrderPaymentQliroOneUpsellTotals>;
  OrderPaymentSveaCheckout: ResolverTypeWrapper<OrderPaymentSveaCheckout>;
  OrderPaymentWalleyCheckout: ResolverTypeWrapper<OrderPaymentWalleyCheckout>;
  OrderPromotion: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderPromotion']>;
  OrderPromotionEmpty: ResolverTypeWrapper<OrderPromotionEmpty>;
  OrderPromotionItemVoyado: ResolverTypeWrapper<OrderPromotionItemVoyado>;
  OrderPromotionVoyado: ResolverTypeWrapper<OrderPromotionVoyado>;
  OrderRefund: ResolverTypeWrapper<OrderRefund>;
  OrderRefundTotals: ResolverTypeWrapper<OrderRefundTotals>;
  OrderRelease: ResolverTypeWrapper<OrderRelease>;
  OrderReservation: ResolverTypeWrapper<OrderReservation>;
  OrderSearchHit: ResolverTypeWrapper<Omit<OrderSearchHit, 'totals'> & { totals: ResolversTypes['OrderTotals'] }>;
  OrderSearchHits: ResolverTypeWrapper<Omit<OrderSearchHits, 'hits'> & { hits: Array<ResolversTypes['OrderSearchHit']> }>;
  OrderShipping: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderShipping']>;
  OrderShippingAvardaShippingBroker: ResolverTypeWrapper<OrderShippingAvardaShippingBroker>;
  OrderShippingEmpty: ResolverTypeWrapper<OrderShippingEmpty>;
  OrderShippingIngrid: ResolverTypeWrapper<OrderShippingIngrid>;
  OrderShippingKlarnaShippingAssistant: ResolverTypeWrapper<OrderShippingKlarnaShippingAssistant>;
  OrderShippingNshift: ResolverTypeWrapper<OrderShippingNshift>;
  OrderShippingQliroIntegratedShipping: ResolverTypeWrapper<OrderShippingQliroIntegratedShipping>;
  OrderShippingWalleyDeliveryModule: ResolverTypeWrapper<OrderShippingWalleyDeliveryModule>;
  OrderState: OrderState;
  OrderStatus: ResolverTypeWrapper<OrderStatus>;
  OrderTotals: ResolverTypeWrapper<OrderTotals>;
  OrderVoucher: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrderVoucher']>;
  OrderVoucherEmpty: ResolverTypeWrapper<OrderVoucherEmpty>;
  OrderVoucherItemVoyado: ResolverTypeWrapper<OrderVoucherItemVoyado>;
  OrderVoucherVoyado: ResolverTypeWrapper<OrderVoucherVoyado>;
  Outcome: ResolverTypeWrapper<Outcome>;
  PaymentProvider: ResolverTypeWrapper<PaymentProvider>;
  PaymentProviderName: PaymentProviderName;
  PlatformConfig: ResolverTypeWrapper<PlatformConfig>;
  Price: ResolverTypeWrapper<Price>;
  PriceRecord: ResolverTypeWrapper<PriceRecord>;
  PriceRule: ResolverTypeWrapper<PriceRule>;
  ProductParent: ResolverTypeWrapper<ProductParent>;
  ProductParentSearchHit: ResolverTypeWrapper<ProductParentSearchHit>;
  ProductParentSearchHits: ResolverTypeWrapper<ProductParentSearchHits>;
  ProductVariant: ResolverTypeWrapper<ProductVariant>;
  ProductVariantReservation: ResolverTypeWrapper<ProductVariantReservation>;
  ProductVariantRules: ResolverTypeWrapper<ProductVariantRules>;
  ProductVariantSearchHit: ResolverTypeWrapper<ProductVariantSearchHit>;
  ProductVariantSearchHits: ResolverTypeWrapper<ProductVariantSearchHits>;
  PromotionProvider: ResolverTypeWrapper<PromotionProvider>;
  PromotionProviderName: PromotionProviderName;
  Provider: ResolverTypeWrapper<Provider>;
  ProviderStatusLog: ResolverTypeWrapper<ProviderStatusLog>;
  ProviderStatusLogEntry: ResolverTypeWrapper<ProviderStatusLogEntry>;
  QliroIngrid: ResolverTypeWrapper<QliroIngrid>;
  QliroIngridDelivery: ResolverTypeWrapper<QliroIngridDelivery>;
  QliroIngridDeliveryAddress: ResolverTypeWrapper<QliroIngridDeliveryAddress>;
  QliroOneConfig: ResolverTypeWrapper<QliroOneConfig>;
  QliroOneCredentials: ResolverTypeWrapper<QliroOneCredentials>;
  QliroOneIntegratedShipping: ResolverTypeWrapper<QliroOneIntegratedShipping>;
  QliroShipmentOrderItem: ResolverTypeWrapper<QliroShipmentOrderItem>;
  QliroShipmentOrderItemMetadata: ResolverTypeWrapper<QliroShipmentOrderItemMetadata>;
  Query: ResolverTypeWrapper<{}>;
  QueryConfigurationManagement: ResolverTypeWrapper<QueryConfigurationManagement>;
  QueryConfigurationManagementValidate: ResolverTypeWrapper<QueryConfigurationManagementValidate>;
  QueryOrderManagement: ResolverTypeWrapper<Omit<QueryOrderManagement, 'details' | 'orderModificationOrderLines'> & { details: ResolversTypes['Order'], orderModificationOrderLines?: Maybe<ResolversTypes['OrderModificationOrderLines']> }>;
  QueryPlatformConfig: ResolverTypeWrapper<QueryPlatformConfig>;
  QueryProductManagement: ResolverTypeWrapper<QueryProductManagement>;
  QuotaPeriod: QuotaPeriod;
  Reason: ResolverTypeWrapper<Reason>;
  RefundBonus: ResolverTypeWrapper<RefundBonus>;
  RefundFee: ResolverTypeWrapper<RefundFee>;
  RefundGift: ResolverTypeWrapper<RefundGift>;
  RefundGiftCard: ResolverTypeWrapper<RefundGiftCard>;
  RefundOrderLine: ResolverTypeWrapper<RefundOrderLine>;
  RefundShippingFee: ResolverTypeWrapper<RefundShippingFee>;
  RefundVoucher: ResolverTypeWrapper<RefundVoucher>;
  RefundedPayment: ResolverTypeWrapper<RefundedPayment>;
  ReleaseBonus: ResolverTypeWrapper<ReleaseBonus>;
  ReleaseGift: ResolverTypeWrapper<ReleaseGift>;
  ReleaseGiftCard: ResolverTypeWrapper<ReleaseGiftCard>;
  ReleaseGiftCardProduct: ResolverTypeWrapper<ReleaseGiftCardProduct>;
  ReleaseOrderLine: ResolverTypeWrapper<ReleaseOrderLine>;
  ReleaseShippingFee: ResolverTypeWrapper<ReleaseShippingFee>;
  ReleasedPayment: ResolverTypeWrapper<ReleasedPayment>;
  Retain24Config: ResolverTypeWrapper<Retain24Config>;
  Retain24Credentials: ResolverTypeWrapper<Retain24Credentials>;
  RoundingRule: ResolverTypeWrapper<RoundingRule>;
  RoundingRules: ResolverTypeWrapper<RoundingRules>;
  Rule: ResolverTypeWrapper<Rule>;
  Schema: ResolverTypeWrapper<Omit<Schema, 'mutation' | 'query'> & { mutation?: Maybe<ResolversTypes['Mutation']>, query?: Maybe<ResolversTypes['Query']> }>;
  SearchSorting: SearchSorting;
  ShippingFee: ResolverTypeWrapper<ShippingFee>;
  ShippingProvider: ResolverTypeWrapper<ShippingProvider>;
  ShippingProviderName: ShippingProviderName;
  ShippingTaxGroup: ResolverTypeWrapper<ShippingTaxGroup>;
  SortOrder: SortOrder;
  Stock: ResolverTypeWrapper<Stock>;
  StockInventory: ResolverTypeWrapper<StockInventory>;
  StoreGroup: ResolverTypeWrapper<StoreGroup>;
  StoreGroupPrice: ResolverTypeWrapper<StoreGroupPrice>;
  StoreGroupSearchHit: ResolverTypeWrapper<StoreGroupSearchHit>;
  StoreGroupSearchHits: ResolverTypeWrapper<StoreGroupSearchHits>;
  StoreMarket: ResolverTypeWrapper<StoreMarket>;
  StoreMarketSearchHit: ResolverTypeWrapper<StoreMarketSearchHit>;
  StoreMarketSearchHits: ResolverTypeWrapper<StoreMarketSearchHits>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SubOutcome: ResolverTypeWrapper<SubOutcome>;
  Subscription: ResolverTypeWrapper<{}>;
  SveaCheckoutConfig: ResolverTypeWrapper<SveaCheckoutConfig>;
  SveaCredentials: ResolverTypeWrapper<SveaCredentials>;
  TagCondition: ResolverTypeWrapper<TagCondition>;
  TagKeys: ResolverTypeWrapper<TagKeys>;
  TagValues: ResolverTypeWrapper<TagValues>;
  TaxGroup: ResolverTypeWrapper<TaxGroup>;
  TaxGroupSearchHit: ResolverTypeWrapper<TaxGroupSearchHit>;
  TaxGroupSearchHits: ResolverTypeWrapper<TaxGroupSearchHits>;
  TaxMarket: ResolverTypeWrapper<TaxMarket>;
  TaxMarketSearchHit: ResolverTypeWrapper<TaxMarketSearchHit>;
  TaxMarketSearchHits: ResolverTypeWrapper<TaxMarketSearchHits>;
  UpdateExternalEventsDestinationAPIResult: ResolverTypeWrapper<UpdateExternalEventsDestinationApiResult>;
  UsagePlan: ResolverTypeWrapper<UsagePlan>;
  User: ResolverTypeWrapper<User>;
  UserDetails: ResolverTypeWrapper<UserDetails>;
  UserStatus: UserStatus;
  UsersList: ResolverTypeWrapper<UsersList>;
  ValidDateRange: ResolverTypeWrapper<ValidDateRange>;
  ValidateConfigResult: ResolverTypeWrapper<ValidateConfigResult>;
  VoucherProvider: ResolverTypeWrapper<VoucherProvider>;
  VoucherProviderName: VoucherProviderName;
  VoyadoConfig: ResolverTypeWrapper<VoyadoConfig>;
  VoyadoCredentials: ResolverTypeWrapper<VoyadoCredentials>;
  WalleyCheckoutConfig: ResolverTypeWrapper<WalleyCheckoutConfig>;
  WalleyCredentials: ResolverTypeWrapper<WalleyCredentials>;
  WalleyDeliveryModuleDeliveryDate: ResolverTypeWrapper<WalleyDeliveryModuleDeliveryDate>;
  WalleyDeliveryModuleDestination: ResolverTypeWrapper<WalleyDeliveryModuleDestination>;
  WalleyDeliveryModuleOption: ResolverTypeWrapper<WalleyDeliveryModuleOption>;
  WalleyDeliveryModuleShipment: ResolverTypeWrapper<WalleyDeliveryModuleShipment>;
  WalleyDeliveryModuleShipping: ResolverTypeWrapper<WalleyDeliveryModuleShipping>;
  WalleyDeliveryModuleShippingChoice: ResolverTypeWrapper<WalleyDeliveryModuleShippingChoice>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSDateTime: Scalars['AWSDateTime']['output'];
  AWSEmail: Scalars['AWSEmail']['output'];
  AWSJSON: Scalars['AWSJSON']['output'];
  Actor: Actor;
  AddExternalEventsDestinationAPIResult: AddExternalEventsDestinationApiResult;
  AddExternalEventsDestinationEventBusResult: AddExternalEventsDestinationEventBusResult;
  AddExternalEventsRuleResult: AddExternalEventsRuleResult;
  AdyenConfig: AdyenConfig;
  AdyenCredentials: AdyenCredentials;
  AdyenWebhook: AdyenWebhook;
  AdyenWebhookCredentials: AdyenWebhookCredentials;
  AdyenWebhookGenerationResult: AdyenWebhookGenerationResult;
  AdyenWebhookLegacy: AdyenWebhookLegacy;
  ApiClient: ApiClient;
  ApiClientDetails: ApiClientDetails;
  ApiClientsList: ApiClientsList;
  ApiResource: ApiResource;
  ApiScope: ApiScope;
  AvardaConfig: AvardaConfig;
  AvardaCredentials: AvardaCredentials;
  AvardaShippingBroker: AvardaShippingBroker;
  AvardaShippingBrokerShipping: AvardaShippingBrokerShipping;
  AvardaShippingBrokerShippingIngrid: AvardaShippingBrokerShippingIngrid;
  AvardaShippingBrokerShippingIngridCategory: AvardaShippingBrokerShippingIngridCategory;
  AvardaShippingBrokerShippingIngridCategoryTag: AvardaShippingBrokerShippingIngridCategoryTag;
  AvardaShippingBrokerShippingIngridLocation: AvardaShippingBrokerShippingIngridLocation;
  AvardaShippingBrokerShippingIngridLocationAddress: AvardaShippingBrokerShippingIngridLocationAddress;
  BSGGiftCardConfig: BsgGiftCardConfig;
  BSGGiftCardCredentials: BsgGiftCardCredentials;
  BonusConfig: BonusConfig;
  BonusCredentials: BonusCredentials;
  BonusProvider: BonusProvider;
  Boolean: Scalars['Boolean']['output'];
  Bundle: Bundle;
  BundleGroup: BundleGroup;
  BundleGroupSearchHit: BundleGroupSearchHit;
  BundleGroupSearchHits: BundleGroupSearchHits;
  BundleItemRule: BundleItemRule;
  BundleOutcome: BundleOutcome;
  BundleStoreMarket: BundleStoreMarket;
  Campaign: Campaign;
  CampaignDeletionResult: CampaignDeletionResult;
  CampaignGroup: CampaignGroup;
  CampaignGroupList: CampaignGroupList;
  CampaignGroupSearchHit: CampaignGroupSearchHit;
  CampaignGroupSearchHits: CampaignGroupSearchHits;
  CampaignIdentity: CampaignIdentity;
  CampaignMoney: CampaignMoney;
  CampaignSearchHit: CampaignSearchHit;
  CampaignSearchHits: CampaignSearchHits;
  CampaignStoreMarket: CampaignStoreMarket;
  CancellationBonus: CancellationBonus;
  CancellationGiftCard: CancellationGiftCard;
  CancellationGiftCardProduct: CancellationGiftCardProduct;
  CancelledPayment: CancelledPayment;
  Capabilities: Capabilities;
  CapabilityProvider: CapabilityProvider;
  CartCapabilities: CartCapabilities;
  CartRuleOutcome: CartRuleOutcome;
  CodeRuleOutcome: CodeRuleOutcome;
  CodesGenerationSearchHit: CodesGenerationSearchHit;
  CodesGenerationSearchHits: CodesGenerationSearchHits;
  Comment: Comment;
  CompanyAttribute: CompanyAttribute;
  CompensationBonus: CompensationBonus;
  CompensationFee: CompensationFee;
  CompensationGiftCard: CompensationGiftCard;
  CompensationOrderLine: CompensationOrderLine;
  CompensationPayment: CompensationPayment;
  CompensationShippingFee: CompensationShippingFee;
  Condition: Condition;
  CreateGenerateCodesResult: CreateGenerateCodesResult;
  CurrencyRate: CurrencyRate;
  CurrencyRates: CurrencyRates;
  CustomReasonCode: CustomReasonCode;
  CustomReasonCodes: CustomReasonCodes;
  CustomState: CustomState;
  CustomStates: CustomStates;
  DeletedResult: DeletedResult;
  DeliveryBonus: DeliveryBonus;
  DeliveryCapture: DeliveryCapture;
  DeliveryGift: DeliveryGift;
  DeliveryGiftCard: DeliveryGiftCard;
  DeliveryGiftCardProduct: DeliveryGiftCardProduct;
  DeliveryOrderLine: DeliveryOrderLine;
  DeliveryShippingFee: DeliveryShippingFee;
  DeliveryTracking: DeliveryTracking;
  DeliveryVoucher: DeliveryVoucher;
  Dimensions: Dimensions;
  DiscountCode: DiscountCode;
  DiscountCodeRule: DiscountCodeRule;
  DiscountCodeRuleSearchHit: DiscountCodeRuleSearchHit;
  DiscountCodeRuleSearchHits: DiscountCodeRuleSearchHits;
  DiscountCodeSearchHit: DiscountCodeSearchHit;
  DiscountCodeSearchHits: DiscountCodeSearchHits;
  DiscountExternalRule: DiscountExternalRule;
  DiscountExternalRuleSearchHit: DiscountExternalRuleSearchHit;
  DiscountExternalRuleSearchHits: DiscountExternalRuleSearchHits;
  DiscountManagementQuotas: DiscountManagementQuotas;
  DiscountRule: DiscountRule;
  DiscountRuleSearchHit: DiscountRuleSearchHit;
  DiscountRuleSearchHits: DiscountRuleSearchHits;
  DiscountValue: DiscountValue;
  ExternalApi: ExternalApi;
  ExternalEventDestinationAPI: ExternalEventDestinationApi;
  ExternalEventDestinationAPIFilter: ExternalEventDestinationApiFilter;
  ExternalEventDestinationAPIFilterPattern: ExternalEventDestinationApiFilterPattern;
  ExternalEventDestinationAPIs: ExternalEventDestinationApIs;
  ExternalEventDestinationEventBuses: ExternalEventDestinationEventBuses;
  ExternalEventDestinationsDetails: ExternalEventDestinationsDetails;
  ExternalEventRuleState: ExternalEventRuleState;
  ExternalEventRules: ExternalEventRules;
  ExternalRuleOutcome: ExternalRuleOutcome;
  Float: Scalars['Float']['output'];
  GenerateCodes: GenerateCodes;
  Gift: Gift;
  GiftCardProductProvider: GiftCardProductProvider;
  GiftCardProvider: GiftCardProvider;
  Group: Group;
  GroupDetails: GroupDetails;
  GroupsList: GroupsList;
  ID: Scalars['ID']['output'];
  IngridConfig: IngridConfig;
  IngridCredentials: IngridCredentials;
  IngridDelivery: IngridDelivery;
  IngridDeliveryAddress: IngridDeliveryAddress;
  InputActionBonus: InputActionBonus;
  InputActionGiftCard: InputActionGiftCard;
  InputActionGiftCardManual: InputActionGiftCardManual;
  InputActionPaymentCancellation: InputActionPaymentCancellation;
  InputActionPaymentCompensation: InputActionPaymentCompensation;
  InputActionPaymentDelivery: InputActionPaymentDelivery;
  InputActionPaymentOrderModificationOrderLines: InputActionPaymentOrderModificationOrderLines;
  InputActionPaymentRefund: InputActionPaymentRefund;
  InputActionPaymentRelease: InputActionPaymentRelease;
  InputActionShipping: InputActionShipping;
  InputAddExternalEventsDestinationAPI: InputAddExternalEventsDestinationApi;
  InputAddUserToGroup: InputAddUserToGroup;
  InputAdyenConfig: InputAdyenConfig;
  InputAdyenWebhookChange: InputAdyenWebhookChange;
  InputAvardaConfig: InputAvardaConfig;
  InputAvardaShippingBroker: InputAvardaShippingBroker;
  InputBSGGiftCardConfig: InputBsgGiftCardConfig;
  InputBonusConfig: InputBonusConfig;
  InputBundle: InputBundle;
  InputBundleItemRule: InputBundleItemRule;
  InputCampaign: InputCampaign;
  InputCampaignGroup: InputCampaignGroup;
  InputCampaignMoney: InputCampaignMoney;
  InputCampaignPeriod: InputCampaignPeriod;
  InputCapabilities: InputCapabilities;
  InputCapabilityProvider: InputCapabilityProvider;
  InputCartCapabilities: InputCartCapabilities;
  InputComment: InputComment;
  InputCondition: InputCondition;
  InputCreateApiClient: InputCreateApiClient;
  InputCreateGenerateCodes: InputCreateGenerateCodes;
  InputCreateOrUpdateBundleGroup: InputCreateOrUpdateBundleGroup;
  InputCreateOrUpdateDiscountCode: InputCreateOrUpdateDiscountCode;
  InputCreateOrUpdateDiscountCodeRule: InputCreateOrUpdateDiscountCodeRule;
  InputCreateOrUpdateDiscountExternalRule: InputCreateOrUpdateDiscountExternalRule;
  InputCreateOrUpdateDiscountRule: InputCreateOrUpdateDiscountRule;
  InputCreateStoreGroup: InputCreateStoreGroup;
  InputCreateStoreMarket: InputCreateStoreMarket;
  InputCreateUser: InputCreateUser;
  InputCustomReasonCode: InputCustomReasonCode;
  InputCustomState: InputCustomState;
  InputDiscountValue: InputDiscountValue;
  InputExternalEventsApiKeyAuth: InputExternalEventsApiKeyAuth;
  InputExternalEventsBasicAuth: InputExternalEventsBasicAuth;
  InputGift: InputGift;
  InputGiftCardProductSelection: InputGiftCardProductSelection;
  InputIngridConfig: InputIngridConfig;
  InputInventory: InputInventory;
  InputInventoryAddress: InputInventoryAddress;
  InputInventoryProductVariantStock: InputInventoryProductVariantStock;
  InputItemRule: InputItemRule;
  InputKBSGiftCardConfig: InputKbsGiftCardConfig;
  InputKlarnaCheckoutConfig: InputKlarnaCheckoutConfig;
  InputLogicHolder: InputLogicHolder;
  InputMarketRoundingRule: InputMarketRoundingRule;
  InputMoney: InputMoney;
  InputNShiftConfig: InputNShiftConfig;
  InputOrderCancellation: InputOrderCancellation;
  InputOrderCompensation: InputOrderCompensation;
  InputOrderCompensationBonus: InputOrderCompensationBonus;
  InputOrderCompensationFee: InputOrderCompensationFee;
  InputOrderCompensationGiftCard: InputOrderCompensationGiftCard;
  InputOrderCompensationPayment: InputOrderCompensationPayment;
  InputOrderDelivery: InputOrderDelivery;
  InputOrderGiftSelection: InputOrderGiftSelection;
  InputOrderLineCompensation: InputOrderLineCompensation;
  InputOrderLineModification: InputOrderLineModification;
  InputOrderLineSelection: InputOrderLineSelection;
  InputOrderModificationOrderLines: InputOrderModificationOrderLines;
  InputOrderModificationOrderLinesStart: InputOrderModificationOrderLinesStart;
  InputOrderRefund: InputOrderRefund;
  InputOrderRefundFee: InputOrderRefundFee;
  InputOrderRelease: InputOrderRelease;
  InputOrderShippingFeeCompensation: InputOrderShippingFeeCompensation;
  InputOrderStartCancellation: InputOrderStartCancellation;
  InputOrderStartCompensation: InputOrderStartCompensation;
  InputOrderStartDelivery: InputOrderStartDelivery;
  InputOrderStartRefund: InputOrderStartRefund;
  InputOrderStartRelease: InputOrderStartRelease;
  InputOutcome: InputOutcome;
  InputPriceRule: InputPriceRule;
  InputProductVariantRules: InputProductVariantRules;
  InputProductVariantStockConfig: InputProductVariantStockConfig;
  InputProvider: InputProvider;
  InputQliroOneConfig: InputQliroOneConfig;
  InputQliroOneIntegratedShipping: InputQliroOneIntegratedShipping;
  InputReason: InputReason;
  InputRemoveUserFromGroup: InputRemoveUserFromGroup;
  InputRetain24Config: InputRetain24Config;
  InputRoundingRule: InputRoundingRule;
  InputRoundingRules: InputRoundingRules;
  InputStoreMarket: InputStoreMarket;
  InputSubOutcome: InputSubOutcome;
  InputSveaCheckoutConfig: InputSveaCheckoutConfig;
  InputTagCondition: InputTagCondition;
  InputTaxGroup: InputTaxGroup;
  InputTaxMarket: InputTaxMarket;
  InputUpdateApiClient: InputUpdateApiClient;
  InputUpdateExternalEventsDestinationAPI: InputUpdateExternalEventsDestinationApi;
  InputUpdateStoreGroup: InputUpdateStoreGroup;
  InputUpdateStoreMarket: InputUpdateStoreMarket;
  InputUpdateUser: InputUpdateUser;
  InputValidDateRange: InputValidDateRange;
  InputVoyadoConfig: InputVoyadoConfig;
  InputWalleyCheckoutConfig: InputWalleyCheckoutConfig;
  Int: Scalars['Int']['output'];
  Inventory: Inventory;
  InventoryAddress: InventoryAddress;
  InventoryProductVariantStock: InventoryProductVariantStock;
  InventoryProductVariantStockConfig: InventoryProductVariantStockConfig;
  InventoryStoreMarket: InventoryStoreMarket;
  InventoryStoreMarketInventories: InventoryStoreMarketInventories;
  ItemRule: ItemRule;
  KBSGiftCardConfig: KbsGiftCardConfig;
  KBSGiftCardCredentials: KbsGiftCardCredentials;
  KeyValue: KeyValue;
  KlarnaCheckoutConfig: KlarnaCheckoutConfig;
  KlarnaCredentials: KlarnaCredentials;
  KlarnaShippingAssistantAddon: KlarnaShippingAssistantAddon;
  KlarnaShippingAssistantAddress: KlarnaShippingAssistantAddress;
  KlarnaShippingAssistantCarrierProduct: KlarnaShippingAssistantCarrierProduct;
  KlarnaShippingAssistantLocation: KlarnaShippingAssistantLocation;
  KlarnaShippingAssistantLocationTimeslot: KlarnaShippingAssistantLocationTimeslot;
  KlarnaShippingAssistantSelectedShippingOption: KlarnaShippingAssistantSelectedShippingOption;
  LogicHolder: LogicHolder;
  MarketPrice: MarketPrice;
  MarketRoundingRule: MarketRoundingRule;
  Money: Money;
  Mutation: {};
  MutationOrderManagement: Omit<MutationOrderManagement, 'createOrderModificationOrderLines' | 'updateOrderModificationOrderLines'> & { createOrderModificationOrderLines: ResolversParentTypes['OrderModificationOrderLines'], updateOrderModificationOrderLines: ResolversParentTypes['OrderModificationOrderLines'] };
  NShiftConfig: NShiftConfig;
  NShiftCredentials: NShiftCredentials;
  NshiftAddress: NshiftAddress;
  NshiftPreparedShipment: NshiftPreparedShipment;
  NshiftService: NshiftService;
  OperationResult: OperationResult;
  Order: Omit<Order, 'GiftCard' | 'GiftCardProduct' | 'Modifications' | 'Payment' | 'Promotion' | 'Shipping' | 'Voucher' | 'totals'> & { GiftCard: ResolversParentTypes['OrderGiftCard'], GiftCardProduct: ResolversParentTypes['OrderGiftCardProduct'], Modifications: Array<ResolversParentTypes['OrderModification']>, Payment: ResolversParentTypes['OrderPayment'], Promotion: ResolversParentTypes['OrderPromotion'], Shipping: ResolversParentTypes['OrderShipping'], Voucher: ResolversParentTypes['OrderVoucher'], totals: ResolversParentTypes['OrderTotals'] };
  OrderAddress: OrderAddress;
  OrderAttributes: OrderAttributes;
  OrderBonus: OrderBonus;
  OrderBundle: OrderBundle;
  OrderCancellation: OrderCancellation;
  OrderComment: OrderComment;
  OrderCompensation: OrderCompensation;
  OrderCompensationTotals: OrderCompensationTotals;
  OrderCustomState: OrderCustomState;
  OrderDelivery: OrderDelivery;
  OrderDeliveryTotals: OrderDeliveryTotals;
  OrderDiscountCodeRule: OrderDiscountCodeRule;
  OrderDiscountExternalRule: OrderDiscountExternalRule;
  OrderDiscountOutcome: OrderDiscountOutcome;
  OrderDiscountRule: OrderDiscountRule;
  OrderGift: OrderGift;
  OrderGiftCard: ResolversInterfaceTypes<ResolversParentTypes>['OrderGiftCard'];
  OrderGiftCardBSGGiftCard: OrderGiftCardBsgGiftCard;
  OrderGiftCardEmpty: OrderGiftCardEmpty;
  OrderGiftCardItemBSGGiftCard: OrderGiftCardItemBsgGiftCard;
  OrderGiftCardItemKBSGiftCard: OrderGiftCardItemKbsGiftCard;
  OrderGiftCardItemRetain24: OrderGiftCardItemRetain24;
  OrderGiftCardKBSGiftCard: OrderGiftCardKbsGiftCard;
  OrderGiftCardProduct: ResolversInterfaceTypes<ResolversParentTypes>['OrderGiftCardProduct'];
  OrderGiftCardProductEmpty: OrderGiftCardProductEmpty;
  OrderGiftCardProductItem: ResolversInterfaceTypes<ResolversParentTypes>['OrderGiftCardProductItem'];
  OrderGiftCardProductItemRetain24: OrderGiftCardProductItemRetain24;
  OrderGiftCardProductRetain24: OrderGiftCardProductRetain24;
  OrderGiftCardRetain24: OrderGiftCardRetain24;
  OrderHistory: OrderHistory;
  OrderHistoryLogEntry: OrderHistoryLogEntry;
  OrderHistoryOperations: OrderHistoryOperations;
  OrderHistoryTransactionBonus: OrderHistoryTransactionBonus;
  OrderHistoryTransactionGift: OrderHistoryTransactionGift;
  OrderHistoryTransactionGiftCard: OrderHistoryTransactionGiftCard;
  OrderHistoryTransactionGiftCardProduct: OrderHistoryTransactionGiftCardProduct;
  OrderHistoryTransactionOrderLine: OrderHistoryTransactionOrderLine;
  OrderHistoryTransactionPayment: OrderHistoryTransactionPayment;
  OrderHistoryTransactionShippingFee: OrderHistoryTransactionShippingFee;
  OrderHistoryTransactionVoucher: OrderHistoryTransactionVoucher;
  OrderHistoryTransactions: OrderHistoryTransactions;
  OrderHistoryTransactionsCompensated: OrderHistoryTransactionsCompensated;
  OrderLine: OrderLine;
  OrderLineDiscountOutcome: OrderLineDiscountOutcome;
  OrderLineModification: Omit<OrderLineModification, 'data'> & { data: ResolversParentTypes['OrderLineModificationData'] };
  OrderLineModificationCreateData: OrderLineModificationCreateData;
  OrderLineModificationData: ResolversInterfaceTypes<ResolversParentTypes>['OrderLineModificationData'];
  OrderLineModificationDeleteData: OrderLineModificationDeleteData;
  OrderLineModificationUpdateData: OrderLineModificationUpdateData;
  OrderLineModificationUpdateDataPrice: OrderLineModificationUpdateDataPrice;
  OrderLineOption: OrderLineOption;
  OrderModification: ResolversInterfaceTypes<ResolversParentTypes>['OrderModification'];
  OrderModificationOrderLines: Omit<OrderModificationOrderLines, 'newTotals' | 'oldTotals' | 'orderLines'> & { newTotals?: Maybe<ResolversParentTypes['OrderTotals']>, oldTotals?: Maybe<ResolversParentTypes['OrderTotals']>, orderLines: Array<ResolversParentTypes['OrderLineModification']> };
  OrderOperationResult: OrderOperationResult;
  OrderPayment: ResolversInterfaceTypes<ResolversParentTypes>['OrderPayment'];
  OrderPaymentAdyen: OrderPaymentAdyen;
  OrderPaymentAvarda: OrderPaymentAvarda;
  OrderPaymentBrinkZeroPayment: OrderPaymentBrinkZeroPayment;
  OrderPaymentBrinkZeroPaymentOriginalProvider: OrderPaymentBrinkZeroPaymentOriginalProvider;
  OrderPaymentKlarnaCheckout: OrderPaymentKlarnaCheckout;
  OrderPaymentQliroOne: OrderPaymentQliroOne;
  OrderPaymentQliroOneOrderItem: OrderPaymentQliroOneOrderItem;
  OrderPaymentQliroOneUpsell: OrderPaymentQliroOneUpsell;
  OrderPaymentQliroOneUpsellItems: OrderPaymentQliroOneUpsellItems;
  OrderPaymentQliroOneUpsellTotals: OrderPaymentQliroOneUpsellTotals;
  OrderPaymentSveaCheckout: OrderPaymentSveaCheckout;
  OrderPaymentWalleyCheckout: OrderPaymentWalleyCheckout;
  OrderPromotion: ResolversInterfaceTypes<ResolversParentTypes>['OrderPromotion'];
  OrderPromotionEmpty: OrderPromotionEmpty;
  OrderPromotionItemVoyado: OrderPromotionItemVoyado;
  OrderPromotionVoyado: OrderPromotionVoyado;
  OrderRefund: OrderRefund;
  OrderRefundTotals: OrderRefundTotals;
  OrderRelease: OrderRelease;
  OrderReservation: OrderReservation;
  OrderSearchHit: Omit<OrderSearchHit, 'totals'> & { totals: ResolversParentTypes['OrderTotals'] };
  OrderSearchHits: Omit<OrderSearchHits, 'hits'> & { hits: Array<ResolversParentTypes['OrderSearchHit']> };
  OrderShipping: ResolversInterfaceTypes<ResolversParentTypes>['OrderShipping'];
  OrderShippingAvardaShippingBroker: OrderShippingAvardaShippingBroker;
  OrderShippingEmpty: OrderShippingEmpty;
  OrderShippingIngrid: OrderShippingIngrid;
  OrderShippingKlarnaShippingAssistant: OrderShippingKlarnaShippingAssistant;
  OrderShippingNshift: OrderShippingNshift;
  OrderShippingQliroIntegratedShipping: OrderShippingQliroIntegratedShipping;
  OrderShippingWalleyDeliveryModule: OrderShippingWalleyDeliveryModule;
  OrderStatus: OrderStatus;
  OrderTotals: OrderTotals;
  OrderVoucher: ResolversInterfaceTypes<ResolversParentTypes>['OrderVoucher'];
  OrderVoucherEmpty: OrderVoucherEmpty;
  OrderVoucherItemVoyado: OrderVoucherItemVoyado;
  OrderVoucherVoyado: OrderVoucherVoyado;
  Outcome: Outcome;
  PaymentProvider: PaymentProvider;
  PlatformConfig: PlatformConfig;
  Price: Price;
  PriceRecord: PriceRecord;
  PriceRule: PriceRule;
  ProductParent: ProductParent;
  ProductParentSearchHit: ProductParentSearchHit;
  ProductParentSearchHits: ProductParentSearchHits;
  ProductVariant: ProductVariant;
  ProductVariantReservation: ProductVariantReservation;
  ProductVariantRules: ProductVariantRules;
  ProductVariantSearchHit: ProductVariantSearchHit;
  ProductVariantSearchHits: ProductVariantSearchHits;
  PromotionProvider: PromotionProvider;
  Provider: Provider;
  ProviderStatusLog: ProviderStatusLog;
  ProviderStatusLogEntry: ProviderStatusLogEntry;
  QliroIngrid: QliroIngrid;
  QliroIngridDelivery: QliroIngridDelivery;
  QliroIngridDeliveryAddress: QliroIngridDeliveryAddress;
  QliroOneConfig: QliroOneConfig;
  QliroOneCredentials: QliroOneCredentials;
  QliroOneIntegratedShipping: QliroOneIntegratedShipping;
  QliroShipmentOrderItem: QliroShipmentOrderItem;
  QliroShipmentOrderItemMetadata: QliroShipmentOrderItemMetadata;
  Query: {};
  QueryConfigurationManagement: QueryConfigurationManagement;
  QueryConfigurationManagementValidate: QueryConfigurationManagementValidate;
  QueryOrderManagement: Omit<QueryOrderManagement, 'details' | 'orderModificationOrderLines'> & { details: ResolversParentTypes['Order'], orderModificationOrderLines?: Maybe<ResolversParentTypes['OrderModificationOrderLines']> };
  QueryPlatformConfig: QueryPlatformConfig;
  QueryProductManagement: QueryProductManagement;
  Reason: Reason;
  RefundBonus: RefundBonus;
  RefundFee: RefundFee;
  RefundGift: RefundGift;
  RefundGiftCard: RefundGiftCard;
  RefundOrderLine: RefundOrderLine;
  RefundShippingFee: RefundShippingFee;
  RefundVoucher: RefundVoucher;
  RefundedPayment: RefundedPayment;
  ReleaseBonus: ReleaseBonus;
  ReleaseGift: ReleaseGift;
  ReleaseGiftCard: ReleaseGiftCard;
  ReleaseGiftCardProduct: ReleaseGiftCardProduct;
  ReleaseOrderLine: ReleaseOrderLine;
  ReleaseShippingFee: ReleaseShippingFee;
  ReleasedPayment: ReleasedPayment;
  Retain24Config: Retain24Config;
  Retain24Credentials: Retain24Credentials;
  RoundingRule: RoundingRule;
  RoundingRules: RoundingRules;
  Rule: Rule;
  Schema: Omit<Schema, 'mutation' | 'query'> & { mutation?: Maybe<ResolversParentTypes['Mutation']>, query?: Maybe<ResolversParentTypes['Query']> };
  SearchSorting: SearchSorting;
  ShippingFee: ShippingFee;
  ShippingProvider: ShippingProvider;
  ShippingTaxGroup: ShippingTaxGroup;
  Stock: Stock;
  StockInventory: StockInventory;
  StoreGroup: StoreGroup;
  StoreGroupPrice: StoreGroupPrice;
  StoreGroupSearchHit: StoreGroupSearchHit;
  StoreGroupSearchHits: StoreGroupSearchHits;
  StoreMarket: StoreMarket;
  StoreMarketSearchHit: StoreMarketSearchHit;
  StoreMarketSearchHits: StoreMarketSearchHits;
  String: Scalars['String']['output'];
  SubOutcome: SubOutcome;
  Subscription: {};
  SveaCheckoutConfig: SveaCheckoutConfig;
  SveaCredentials: SveaCredentials;
  TagCondition: TagCondition;
  TagKeys: TagKeys;
  TagValues: TagValues;
  TaxGroup: TaxGroup;
  TaxGroupSearchHit: TaxGroupSearchHit;
  TaxGroupSearchHits: TaxGroupSearchHits;
  TaxMarket: TaxMarket;
  TaxMarketSearchHit: TaxMarketSearchHit;
  TaxMarketSearchHits: TaxMarketSearchHits;
  UpdateExternalEventsDestinationAPIResult: UpdateExternalEventsDestinationApiResult;
  UsagePlan: UsagePlan;
  User: User;
  UserDetails: UserDetails;
  UsersList: UsersList;
  ValidDateRange: ValidDateRange;
  ValidateConfigResult: ValidateConfigResult;
  VoucherProvider: VoucherProvider;
  VoyadoConfig: VoyadoConfig;
  VoyadoCredentials: VoyadoCredentials;
  WalleyCheckoutConfig: WalleyCheckoutConfig;
  WalleyCredentials: WalleyCredentials;
  WalleyDeliveryModuleDeliveryDate: WalleyDeliveryModuleDeliveryDate;
  WalleyDeliveryModuleDestination: WalleyDeliveryModuleDestination;
  WalleyDeliveryModuleOption: WalleyDeliveryModuleOption;
  WalleyDeliveryModuleShipment: WalleyDeliveryModuleShipment;
  WalleyDeliveryModuleShipping: WalleyDeliveryModuleShipping;
  WalleyDeliveryModuleShippingChoice: WalleyDeliveryModuleShippingChoice;
};

export type Aws_Api_KeyDirectiveArgs = { };

export type Aws_Api_KeyDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Api_KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_AuthDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_AuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = { };

export type Aws_IamDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_IamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_LambdaDirectiveArgs = { };

export type Aws_LambdaDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_LambdaDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = { };

export type Aws_OidcDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_OidcDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_PublishDirectiveArgs = {
  subscriptions?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_PublishDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_PublishDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_SubscribeDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_SubscribeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DeferDirectiveArgs = { };

export type DeferDirectiveResolver<Result, Parent, ContextType = any, Args = DeferDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export type ActorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor'] = ResolversParentTypes['Actor']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddExternalEventsDestinationApiResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalEventsDestinationAPIResult'] = ResolversParentTypes['AddExternalEventsDestinationAPIResult']> = {
  apiDestinationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddExternalEventsDestinationEventBusResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalEventsDestinationEventBusResult'] = ResolversParentTypes['AddExternalEventsDestinationEventBusResult']> = {
  eventBusArn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddExternalEventsRuleResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalEventsRuleResult'] = ResolversParentTypes['AddExternalEventsRuleResult']> = {
  ruleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenConfig'] = ResolversParentTypes['AdyenConfig']> = {
  clientKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['AdyenCredentials'], ParentType, ContextType>;
  environment?: Resolver<ResolversTypes['AdyenEnvironment'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liveEndpointUrlPrefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantAccount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  webhooks?: Resolver<Maybe<Array<ResolversTypes['AdyenWebhook']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenCredentials'] = ResolversParentTypes['AdyenCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hmac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhook'] = ResolversParentTypes['AdyenWebhook']> = {
  configId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sequenceNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AdyenWebhookStatus']>, ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['AdyenWebhookTarget']>, ParentType, ContextType>;
  tested?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhookCredentials'] = ResolversParentTypes['AdyenWebhookCredentials']> = {
  hmac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookGenerationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhookGenerationResult'] = ResolversParentTypes['AdyenWebhookGenerationResult']> = {
  started?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookLegacyResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhookLegacy'] = ResolversParentTypes['AdyenWebhookLegacy']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiClient'] = ResolversParentTypes['ApiClient']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiClientDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiClientDetails'] = ResolversParentTypes['ApiClientDetails']> = {
  clientId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  clientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scopes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiClientsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiClientsList'] = ResolversParentTypes['ApiClientsList']> = {
  apiClients?: Resolver<Array<ResolversTypes['ApiClient']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiResourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiResource'] = ResolversParentTypes['ApiResource']> = {
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scopes?: Resolver<Maybe<Array<ResolversTypes['ApiScope']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiScopeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiScope'] = ResolversParentTypes['ApiScope']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaConfig'] = ResolversParentTypes['AvardaConfig']> = {
  authUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['AvardaCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shippingBroker?: Resolver<Maybe<ResolversTypes['AvardaShippingBroker']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaCredentials'] = ResolversParentTypes['AvardaCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBroker'] = ResolversParentTypes['AvardaShippingBroker']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBrokerShipping'] = ResolversParentTypes['AvardaShippingBrokerShipping']> = {
  ingrid?: Resolver<Maybe<ResolversTypes['AvardaShippingBrokerShippingIngrid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerShippingIngridResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBrokerShippingIngrid'] = ResolversParentTypes['AvardaShippingBrokerShippingIngrid']> = {
  carrier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['AvardaShippingBrokerShippingIngridCategory']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deliveryType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalMethodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['AvardaShippingBrokerShippingIngridLocation']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tosId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerShippingIngridCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBrokerShippingIngridCategory'] = ResolversParentTypes['AvardaShippingBrokerShippingIngridCategory']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['AvardaShippingBrokerShippingIngridCategoryTag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerShippingIngridCategoryTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBrokerShippingIngridCategoryTag'] = ResolversParentTypes['AvardaShippingBrokerShippingIngridCategoryTag']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerShippingIngridLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBrokerShippingIngridLocation'] = ResolversParentTypes['AvardaShippingBrokerShippingIngridLocation']> = {
  address?: Resolver<ResolversTypes['AvardaShippingBrokerShippingIngridLocationAddress'], ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvardaShippingBrokerShippingIngridLocationAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvardaShippingBrokerShippingIngridLocationAddress'] = ResolversParentTypes['AvardaShippingBrokerShippingIngridLocationAddress']> = {
  addressLines?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BsgGiftCardConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['BSGGiftCardConfig'] = ResolversParentTypes['BSGGiftCardConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['BSGGiftCardCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scopes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tokenUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BsgGiftCardCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BSGGiftCardCredentials'] = ResolversParentTypes['BSGGiftCardCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['BonusConfig'] = ResolversParentTypes['BonusConfig']> = {
  authUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['BonusCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BonusCredentials'] = ResolversParentTypes['BonusCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['BonusProvider'] = ResolversParentTypes['BonusProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['BonusProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bundle'] = ResolversParentTypes['Bundle']> = {
  discountValue?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemRules?: Resolver<Array<ResolversTypes['BundleItemRule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleGroup'] = ResolversParentTypes['BundleGroup']> = {
  bundles?: Resolver<Array<ResolversTypes['Bundle']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeMarkets?: Resolver<Array<Maybe<ResolversTypes['BundleStoreMarket']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleGroupSearchHit'] = ResolversParentTypes['BundleGroupSearchHit']> = {
  bundles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleGroupSearchHits'] = ResolversParentTypes['BundleGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['BundleGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleItemRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleItemRule'] = ResolversParentTypes['BundleItemRule']> = {
  discountValue?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  excludeDiscountedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  excludeFullPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  productParents?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tagConditions?: Resolver<Array<ResolversTypes['TagCondition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleOutcome'] = ResolversParentTypes['BundleOutcome']> = {
  bundleGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bundleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleStoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleStoreMarket'] = ResolversParentTypes['BundleStoreMarket']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['Campaign'] = ResolversParentTypes['Campaign']> = {
  campaignGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceRule?: Resolver<ResolversTypes['PriceRule'], ParentType, ContextType>;
  productVariantRules?: Resolver<ResolversTypes['ProductVariantRules'], ParentType, ContextType>;
  roundingRules?: Resolver<Maybe<ResolversTypes['RoundingRules']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  storeMarkets?: Resolver<Array<ResolversTypes['CampaignStoreMarket']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignDeletionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDeletionResult'] = ResolversParentTypes['CampaignDeletionResult']> = {
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroup'] = ResolversParentTypes['CampaignGroup']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroupList'] = ResolversParentTypes['CampaignGroupList']> = {
  campaignGroup?: Resolver<ResolversTypes['CampaignGroup'], ParentType, ContextType>;
  campaigns?: Resolver<Array<ResolversTypes['Campaign']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroupSearchHit'] = ResolversParentTypes['CampaignGroupSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroupSearchHits'] = ResolversParentTypes['CampaignGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['CampaignGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignIdentityResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignIdentity'] = ResolversParentTypes['CampaignIdentity']> = {
  campaignGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignMoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignMoney'] = ResolversParentTypes['CampaignMoney']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignSearchHit'] = ResolversParentTypes['CampaignSearchHit']> = {
  campaignGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignSearchHits'] = ResolversParentTypes['CampaignSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['CampaignSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignStoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignStoreMarket'] = ResolversParentTypes['CampaignStoreMarket']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancellationBonus'] = ResolversParentTypes['CancellationBonus']> = {
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancellationGiftCard'] = ResolversParentTypes['CancellationGiftCard']> = {
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancellationGiftCardProduct'] = ResolversParentTypes['CancellationGiftCardProduct']> = {
  cancelTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftCardProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelledPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelledPayment'] = ResolversParentTypes['CancelledPayment']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CapabilitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Capabilities'] = ResolversParentTypes['Capabilities']> = {
  giftCardProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  paymentProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  shippingProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CapabilityProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['CapabilityProvider'] = ResolversParentTypes['CapabilityProvider']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CartCapabilitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartCapabilities'] = ResolversParentTypes['CartCapabilities']> = {
  bonus?: Resolver<Maybe<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  retain24?: Resolver<Maybe<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  voyado?: Resolver<Maybe<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CartRuleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartRuleOutcome'] = ResolversParentTypes['CartRuleOutcome']> = {
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ruleID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodeRuleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodeRuleOutcome'] = ResolversParentTypes['CodeRuleOutcome']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ruleID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodesGenerationSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodesGenerationSearchHit'] = ResolversParentTypes['CodesGenerationSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  numberOfCodesGenerated?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfCodesRequested?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodesGenerationSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodesGenerationSearchHits'] = ResolversParentTypes['CodesGenerationSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['CodesGenerationSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyAttribute'] = ResolversParentTypes['CompanyAttribute']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationBonus'] = ResolversParentTypes['CompensationBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationFee'] = ResolversParentTypes['CompensationFee']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationGiftCard'] = ResolversParentTypes['CompensationGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationOrderLine'] = ResolversParentTypes['CompensationOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationPayment'] = ResolversParentTypes['CompensationPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationShippingFee'] = ResolversParentTypes['CompensationShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Condition'] = ResolversParentTypes['Condition']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identifiers?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  itemRule?: Resolver<Maybe<ResolversTypes['ItemRule']>, ParentType, ContextType>;
  thresholds?: Resolver<Maybe<Array<ResolversTypes['Money']>>, ParentType, ContextType>;
  triggerQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateGenerateCodesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateGenerateCodesResult'] = ResolversParentTypes['CreateGenerateCodesResult']> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyRate'] = ResolversParentTypes['CurrencyRate']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyRates'] = ResolversParentTypes['CurrencyRates']> = {
  baseCurrencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rates?: Resolver<Array<ResolversTypes['CurrencyRate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomReasonCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomReasonCode'] = ResolversParentTypes['CustomReasonCode']> = {
  code?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomReasonCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomReasonCodes'] = ResolversParentTypes['CustomReasonCodes']> = {
  codes?: Resolver<Array<Maybe<ResolversTypes['CustomReasonCode']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomState'] = ResolversParentTypes['CustomState']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  operations?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CustomStateType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomStatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomStates'] = ResolversParentTypes['CustomStates']> = {
  states?: Resolver<Array<Maybe<ResolversTypes['CustomState']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedResult'] = ResolversParentTypes['DeletedResult']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryBonus'] = ResolversParentTypes['DeliveryBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryCaptureResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryCapture'] = ResolversParentTypes['DeliveryCapture']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryGift'] = ResolversParentTypes['DeliveryGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryGiftCard'] = ResolversParentTypes['DeliveryGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryGiftCardProduct'] = ResolversParentTypes['DeliveryGiftCardProduct']> = {
  createFailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  priceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryOrderLine'] = ResolversParentTypes['DeliveryOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryShippingFee'] = ResolversParentTypes['DeliveryShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryTrackingResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryTracking'] = ResolversParentTypes['DeliveryTracking']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingCompany?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryVoucher'] = ResolversParentTypes['DeliveryVoucher']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  voucherId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dimensions'] = ResolversParentTypes['Dimensions']> = {
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCode'] = ResolversParentTypes['DiscountCode']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isUnlimited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeRule'] = ResolversParentTypes['DiscountCodeRule']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  conditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logicRules?: Resolver<Array<ResolversTypes['LogicHolder']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes?: Resolver<Array<ResolversTypes['Outcome']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeRuleSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeRuleSearchHit'] = ResolversParentTypes['DiscountCodeRuleSearchHit']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeRuleSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeRuleSearchHits'] = ResolversParentTypes['DiscountCodeRuleSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountCodeRuleSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeSearchHit'] = ResolversParentTypes['DiscountCodeSearchHit']> = {
  code?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isUnlimited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeSearchHits'] = ResolversParentTypes['DiscountCodeSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountCodeSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountExternalRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountExternalRule'] = ResolversParentTypes['DiscountExternalRule']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  conditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logicRules?: Resolver<Array<ResolversTypes['LogicHolder']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes?: Resolver<Array<ResolversTypes['Outcome']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountExternalRuleSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountExternalRuleSearchHit'] = ResolversParentTypes['DiscountExternalRuleSearchHit']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountExternalRuleSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountExternalRuleSearchHits'] = ResolversParentTypes['DiscountExternalRuleSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountExternalRuleSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountManagementQuotasResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountManagementQuotas'] = ResolversParentTypes['DiscountManagementQuotas']> = {
  maxGenerationJobsPerDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxNumberOfCodes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRule'] = ResolversParentTypes['DiscountRule']> = {
  conditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logicRules?: Resolver<Array<ResolversTypes['LogicHolder']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes?: Resolver<Array<ResolversTypes['Outcome']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRuleSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRuleSearchHit'] = ResolversParentTypes['DiscountRuleSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRuleSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRuleSearchHits'] = ResolversParentTypes['DiscountRuleSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountRuleSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountValue'] = ResolversParentTypes['DiscountValue']> = {
  fixedDiscounts?: Resolver<Maybe<Array<ResolversTypes['Money']>>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalApiResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalApi'] = ResolversParentTypes['ExternalApi']> = {
  discountManagementQuotas?: Resolver<Maybe<ResolversTypes['DiscountManagementQuotas']>, ParentType, ContextType>;
  usagePlans?: Resolver<Array<ResolversTypes['UsagePlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationApiResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationAPI'] = ResolversParentTypes['ExternalEventDestinationAPI']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endpoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<ResolversTypes['ExternalEventDestinationAPIFilter']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationApiFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationAPIFilter'] = ResolversParentTypes['ExternalEventDestinationAPIFilter']> = {
  pattern?: Resolver<Maybe<ResolversTypes['ExternalEventDestinationAPIFilterPattern']>, ParentType, ContextType>;
  ruleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationApiFilterPatternResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationAPIFilterPattern'] = ResolversParentTypes['ExternalEventDestinationAPIFilterPattern']> = {
  detailTypes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationApIsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationAPIs'] = ResolversParentTypes['ExternalEventDestinationAPIs']> = {
  apiDestinations?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  apiDestinationsDetails?: Resolver<Array<ResolversTypes['ExternalEventDestinationsDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationEventBusesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationEventBuses'] = ResolversParentTypes['ExternalEventDestinationEventBuses']> = {
  eventBuses?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationsDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationsDetails'] = ResolversParentTypes['ExternalEventDestinationsDetails']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endpoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventRuleStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventRuleState'] = ResolversParentTypes['ExternalEventRuleState']> = {
  ruleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventRules'] = ResolversParentTypes['ExternalEventRules']> = {
  rules?: Resolver<Array<ResolversTypes['ExternalEventRuleState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalRuleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalRuleOutcome'] = ResolversParentTypes['ExternalRuleOutcome']> = {
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenerateCodes'] = ResolversParentTypes['GenerateCodes']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfCodesGenerated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfCodesRequested?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gift'] = ResolversParentTypes['Gift']> = {
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftCardProductProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftCardProductProvider'] = ResolversParentTypes['GiftCardProductProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['GiftCardProductProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftCardProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftCardProvider'] = ResolversParentTypes['GiftCardProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['GiftCardProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupDetails'] = ResolversParentTypes['GroupDetails']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupsList'] = ResolversParentTypes['GroupsList']> = {
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridConfig'] = ResolversParentTypes['IngridConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['IngridCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  siteId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridCredentials'] = ResolversParentTypes['IngridCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridDelivery'] = ResolversParentTypes['IngridDelivery']> = {
  carrierProductId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryAddress?: Resolver<Maybe<ResolversTypes['IngridDeliveryAddress']>, ParentType, ContextType>;
  deliveryType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tosId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridDeliveryAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridDeliveryAddress'] = ResolversParentTypes['IngridDeliveryAddress']> = {
  addressLines?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Inventory'] = ResolversParentTypes['Inventory']> = {
  address?: Resolver<Maybe<ResolversTypes['InventoryAddress']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryAddress'] = ResolversParentTypes['InventoryAddress']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryProductVariantStockResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryProductVariantStock'] = ResolversParentTypes['InventoryProductVariantStock']> = {
  inventoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryProductVariantStockConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryProductVariantStockConfig'] = ResolversParentTypes['InventoryProductVariantStockConfig']> = {
  productVariantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  validateStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryStoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryStoreMarket'] = ResolversParentTypes['InventoryStoreMarket']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  inventories?: Resolver<Maybe<Array<ResolversTypes['Inventory']>>, ParentType, ContextType>;
  storeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryStoreMarketInventoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryStoreMarketInventories'] = ResolversParentTypes['InventoryStoreMarketInventories']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inventoryIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRule'] = ResolversParentTypes['ItemRule']> = {
  excludeDiscountedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  excludeFullPrices?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tagConditions?: Resolver<Array<ResolversTypes['TagCondition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KbsGiftCardConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['KBSGiftCardConfig'] = ResolversParentTypes['KBSGiftCardConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['KBSGiftCardCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KbsGiftCardCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['KBSGiftCardCredentials'] = ResolversParentTypes['KBSGiftCardCredentials']> = {
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['KeyValue'] = ResolversParentTypes['KeyValue']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaCheckoutConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaCheckoutConfig'] = ResolversParentTypes['KlarnaCheckoutConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['KlarnaCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  klarnaShippingAssistantEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  merchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaCredentials'] = ResolversParentTypes['KlarnaCredentials']> = {
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantAddonResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantAddon'] = ResolversParentTypes['KlarnaShippingAssistantAddon']> = {
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userInput?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantAddress'] = ResolversParentTypes['KlarnaShippingAssistantAddress']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantCarrierProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantCarrierProduct'] = ResolversParentTypes['KlarnaShippingAssistantCarrierProduct']> = {
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantLocation'] = ResolversParentTypes['KlarnaShippingAssistantLocation']> = {
  address?: Resolver<ResolversTypes['KlarnaShippingAssistantAddress'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantLocationTimeslotResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantLocationTimeslot'] = ResolversParentTypes['KlarnaShippingAssistantLocationTimeslot']> = {
  cutoff?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantSelectedShippingOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantSelectedShippingOption'] = ResolversParentTypes['KlarnaShippingAssistantSelectedShippingOption']> = {
  carrier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  carrierProduct?: Resolver<Maybe<ResolversTypes['KlarnaShippingAssistantCarrierProduct']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['KlarnaShippingAssistantLocation']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selectedAddons?: Resolver<Maybe<Array<ResolversTypes['KlarnaShippingAssistantAddon']>>, ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeslot?: Resolver<Maybe<ResolversTypes['KlarnaShippingAssistantLocationTimeslot']>, ParentType, ContextType>;
  tmsReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogicHolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogicHolder'] = ResolversParentTypes['LogicHolder']> = {
  do?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  if?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketPrice'] = ResolversParentTypes['MarketPrice']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketRoundingRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketRoundingRule'] = ResolversParentTypes['MarketRoundingRule']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['RoundingRule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  Order?: Resolver<ResolversTypes['MutationOrderManagement'], ParentType, ContextType>;
  addExternalEventsDestinationAPI?: Resolver<ResolversTypes['AddExternalEventsDestinationAPIResult'], ParentType, ContextType, RequireFields<MutationAddExternalEventsDestinationApiArgs, 'destName' | 'options'>>;
  addExternalEventsDestinationEventBus?: Resolver<ResolversTypes['AddExternalEventsDestinationEventBusResult'], ParentType, ContextType, RequireFields<MutationAddExternalEventsDestinationEventBusArgs, 'eventBusArn'>>;
  addExternalEventsRule?: Resolver<ResolversTypes['AddExternalEventsRuleResult'], ParentType, ContextType, RequireFields<MutationAddExternalEventsRuleArgs, 'ruleName'>>;
  addUserToGroup?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationAddUserToGroupArgs, 'input'>>;
  createApiClient?: Resolver<ResolversTypes['ApiClientDetails'], ParentType, ContextType, RequireFields<MutationCreateApiClientArgs, 'input'>>;
  createGenerateCodes?: Resolver<Maybe<ResolversTypes['CreateGenerateCodesResult']>, ParentType, ContextType, RequireFields<MutationCreateGenerateCodesArgs, 'input'>>;
  createOrUpdateAdyenConfig?: Resolver<ResolversTypes['AdyenConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateAdyenConfigArgs, 'adyenConfig' | 'id'>>;
  createOrUpdateAvardaConfig?: Resolver<ResolversTypes['AvardaConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateAvardaConfigArgs, 'avardaConfig' | 'id'>>;
  createOrUpdateBSGGiftCardConfig?: Resolver<ResolversTypes['BSGGiftCardConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateBsgGiftCardConfigArgs, 'bsgGiftCardConfig' | 'id'>>;
  createOrUpdateBonusConfig?: Resolver<ResolversTypes['BonusConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateBonusConfigArgs, 'bonusConfig' | 'id'>>;
  createOrUpdateBundleGroup?: Resolver<Maybe<ResolversTypes['BundleGroup']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateBundleGroupArgs, 'bundleGroup' | 'id'>>;
  createOrUpdateCampaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateCampaignArgs, 'campaign' | 'id'>>;
  createOrUpdateCampaignGroup?: Resolver<ResolversTypes['CampaignGroup'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateCampaignGroupArgs, 'campaignGroup' | 'id'>>;
  createOrUpdateDiscountCode?: Resolver<Maybe<ResolversTypes['DiscountCode']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountCodeArgs, 'code' | 'discountCode'>>;
  createOrUpdateDiscountCodeRule?: Resolver<Maybe<ResolversTypes['DiscountCodeRule']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountCodeRuleArgs, 'discountCodeRule' | 'id'>>;
  createOrUpdateDiscountExternalRule?: Resolver<Maybe<ResolversTypes['DiscountExternalRule']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountExternalRuleArgs, 'discountExternalRule' | 'id'>>;
  createOrUpdateDiscountRule?: Resolver<Maybe<ResolversTypes['DiscountRule']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountRuleArgs, 'discountRule' | 'id'>>;
  createOrUpdateIngridConfig?: Resolver<ResolversTypes['IngridConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateIngridConfigArgs, 'id' | 'ingridConfig'>>;
  createOrUpdateInventory?: Resolver<ResolversTypes['Inventory'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateInventoryArgs, 'id' | 'inventory'>>;
  createOrUpdateKBSGiftCardConfig?: Resolver<ResolversTypes['KBSGiftCardConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateKbsGiftCardConfigArgs, 'id' | 'kbsGiftCardConfig'>>;
  createOrUpdateKlarnaCheckoutConfig?: Resolver<ResolversTypes['KlarnaCheckoutConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateKlarnaCheckoutConfigArgs, 'id' | 'klarnaCheckoutConfig'>>;
  createOrUpdateNShiftConfig?: Resolver<ResolversTypes['NShiftConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateNShiftConfigArgs, 'id' | 'nShiftConfig'>>;
  createOrUpdateQliroOneConfig?: Resolver<ResolversTypes['QliroOneConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateQliroOneConfigArgs, 'id' | 'qliroOneConfig'>>;
  createOrUpdateRetain24Config?: Resolver<ResolversTypes['Retain24Config'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateRetain24ConfigArgs, 'id' | 'retain24Config'>>;
  createOrUpdateSveaCheckoutConfig?: Resolver<ResolversTypes['SveaCheckoutConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateSveaCheckoutConfigArgs, 'id' | 'sveaCheckoutConfig'>>;
  createOrUpdateTaxGroup?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateTaxGroupArgs, 'id' | 'taxGroup'>>;
  createOrUpdateTaxMarket?: Resolver<ResolversTypes['TaxMarket'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateTaxMarketArgs, 'countryCode' | 'taxGroupId' | 'taxMarket'>>;
  createOrUpdateVoyadoConfig?: Resolver<ResolversTypes['VoyadoConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateVoyadoConfigArgs, 'id' | 'voyadoConfig'>>;
  createOrUpdateWalleyCheckoutConfig?: Resolver<ResolversTypes['WalleyCheckoutConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateWalleyCheckoutConfigArgs, 'id' | 'walleyCheckoutConfig'>>;
  createStoreGroup?: Resolver<Maybe<ResolversTypes['StoreGroup']>, ParentType, ContextType, RequireFields<MutationCreateStoreGroupArgs, 'id' | 'storeGroup'>>;
  createStoreMarket?: Resolver<Maybe<ResolversTypes['StoreMarket']>, ParentType, ContextType, RequireFields<MutationCreateStoreMarketArgs, 'countryCode' | 'storeGroupId' | 'storeMarket'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteAdyenConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteAdyenConfigArgs, 'id'>>;
  deleteApiClient?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationDeleteApiClientArgs, 'clientId'>>;
  deleteAvardaConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteAvardaConfigArgs, 'id'>>;
  deleteBSGGiftCardConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteBsgGiftCardConfigArgs, 'id'>>;
  deleteBonusConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteBonusConfigArgs, 'id'>>;
  deleteBundleGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteBundleGroupArgs, 'id'>>;
  deleteCampaign?: Resolver<ResolversTypes['CampaignDeletionResult'], ParentType, ContextType, RequireFields<MutationDeleteCampaignArgs, 'id'>>;
  deleteCampaignGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteCampaignGroupArgs, 'id'>>;
  deleteDiscountCode?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountCodeArgs, 'code'>>;
  deleteDiscountCodeRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountCodeRuleArgs, 'id'>>;
  deleteDiscountExternalRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountExternalRuleArgs, 'id'>>;
  deleteDiscountRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountRuleArgs, 'id'>>;
  deleteExternalEventsDestinationAPI?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteExternalEventsDestinationApiArgs, 'destName'>>;
  deleteExternalEventsDestinationEventBus?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteExternalEventsDestinationEventBusArgs, 'eventBusArn'>>;
  deleteExternalEventsRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteExternalEventsRuleArgs, 'ruleName'>>;
  deleteIngridConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteIngridConfigArgs, 'id'>>;
  deleteInventory?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteInventoryArgs, 'id'>>;
  deleteInventoryProductVariantStock?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteInventoryProductVariantStockArgs, 'inventoryId' | 'productVariantId'>>;
  deleteKBSGiftCardConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteKbsGiftCardConfigArgs, 'id'>>;
  deleteKlarnaCheckoutConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteKlarnaCheckoutConfigArgs, 'id'>>;
  deleteNShiftConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteNShiftConfigArgs, 'id'>>;
  deleteQliroOneConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteQliroOneConfigArgs, 'id'>>;
  deleteRetain24Config?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteRetain24ConfigArgs, 'id'>>;
  deleteStoreGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteStoreGroupArgs, 'id'>>;
  deleteStoreMarket?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteStoreMarketArgs, 'countryCode' | 'storeGroupId'>>;
  deleteSveaCheckoutConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteSveaCheckoutConfigArgs, 'id'>>;
  deleteTaxGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteTaxGroupArgs, 'id'>>;
  deleteTaxMarket?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteTaxMarketArgs, 'countryCode' | 'taxGroupId'>>;
  deleteUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'username'>>;
  deleteVoyadoConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteVoyadoConfigArgs, 'id'>>;
  deleteWalleyCheckoutConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteWalleyCheckoutConfigArgs, 'id'>>;
  disableUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationDisableUserArgs, 'username'>>;
  enableUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationEnableUserArgs, 'username'>>;
  generateAdyenWebhookCredentials?: Resolver<ResolversTypes['AdyenWebhookCredentials'], ParentType, ContextType, RequireFields<MutationGenerateAdyenWebhookCredentialsArgs, 'id'>>;
  generateAdyenWebhooks?: Resolver<ResolversTypes['AdyenWebhookGenerationResult'], ParentType, ContextType, RequireFields<MutationGenerateAdyenWebhooksArgs, 'id'>>;
  privateAdyenWebhookChange?: Resolver<ResolversTypes['AdyenWebhook'], ParentType, ContextType, RequireFields<MutationPrivateAdyenWebhookChangeArgs, 'input'>>;
  removeUserFromGroup?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationRemoveUserFromGroupArgs, 'input'>>;
  resendOrderEvent?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationResendOrderEventArgs, 'orderId'>>;
  resendPassword?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationResendPasswordArgs, 'username'>>;
  setShippingTaxGroup?: Resolver<ResolversTypes['ShippingTaxGroup'], ParentType, ContextType, RequireFields<MutationSetShippingTaxGroupArgs, 'taxGroupId'>>;
  updateApiClient?: Resolver<ResolversTypes['ApiClientDetails'], ParentType, ContextType, RequireFields<MutationUpdateApiClientArgs, 'clientId' | 'input'>>;
  updateCampaignPeriod?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationUpdateCampaignPeriodArgs, 'id' | 'period'>>;
  updateExternalEventsDestinationAPI?: Resolver<ResolversTypes['UpdateExternalEventsDestinationAPIResult'], ParentType, ContextType, RequireFields<MutationUpdateExternalEventsDestinationApiArgs, 'destName' | 'options'>>;
  updateInventoryProductVariantStock?: Resolver<ResolversTypes['InventoryProductVariantStock'], ParentType, ContextType, RequireFields<MutationUpdateInventoryProductVariantStockArgs, 'inventoryId' | 'productVariantId' | 'stock'>>;
  updateInventoryStoreMarketInventories?: Resolver<ResolversTypes['InventoryStoreMarketInventories'], ParentType, ContextType, RequireFields<MutationUpdateInventoryStoreMarketInventoriesArgs, 'countryCode' | 'inventoryIds' | 'storeGroupId'>>;
  updateProductVariantStockConfig?: Resolver<ResolversTypes['InventoryProductVariantStockConfig'], ParentType, ContextType, RequireFields<MutationUpdateProductVariantStockConfigArgs, 'config' | 'productVariantId'>>;
  updateStoreGroup?: Resolver<Maybe<ResolversTypes['StoreGroup']>, ParentType, ContextType, RequireFields<MutationUpdateStoreGroupArgs, 'id' | 'storeGroup'>>;
  updateStoreMarket?: Resolver<Maybe<ResolversTypes['StoreMarket']>, ParentType, ContextType, RequireFields<MutationUpdateStoreMarketArgs, 'countryCode' | 'storeGroupId' | 'storeMarket'>>;
  updateUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input' | 'username'>>;
};

export type MutationOrderManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationOrderManagement'] = ResolversParentTypes['MutationOrderManagement']> = {
  addCustomState?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationOrderManagementAddCustomStateArgs, 'orderId' | 'stateId'>>;
  createCancellation?: Resolver<ResolversTypes['OrderCancellation'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateCancellationArgs, 'input' | 'orderId'>>;
  createCompensation?: Resolver<ResolversTypes['OrderCompensation'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateCompensationArgs, 'input' | 'orderId'>>;
  createCustomReasonCode?: Resolver<ResolversTypes['CustomReasonCode'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateCustomReasonCodeArgs, 'code' | 'input'>>;
  createCustomState?: Resolver<ResolversTypes['CustomState'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateCustomStateArgs, 'input' | 'stateId'>>;
  createDelivery?: Resolver<ResolversTypes['OrderDelivery'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateDeliveryArgs, 'input' | 'orderId'>>;
  createOrderComment?: Resolver<ResolversTypes['OrderComment'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateOrderCommentArgs, 'comment' | 'orderId'>>;
  createOrderModificationOrderLines?: Resolver<ResolversTypes['OrderModificationOrderLines'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateOrderModificationOrderLinesArgs, 'input' | 'orderId'>>;
  createRefund?: Resolver<ResolversTypes['OrderRefund'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateRefundArgs, 'input' | 'orderId'>>;
  createRelease?: Resolver<ResolversTypes['OrderRelease'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateReleaseArgs, 'input' | 'orderId'>>;
  deleteCancellation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteCancellationArgs, 'cancellationId'>>;
  deleteCompensation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteCompensationArgs, 'compensationId'>>;
  deleteCustomReasonCode?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteCustomReasonCodeArgs, 'code'>>;
  deleteCustomState?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteCustomStateArgs, 'stateId'>>;
  deleteDelivery?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteDeliveryArgs, 'deliveryId'>>;
  deleteOrderComment?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteOrderCommentArgs, 'commentId' | 'orderId'>>;
  deleteOrderModificationOrderLines?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteOrderModificationOrderLinesArgs, 'modificationId'>>;
  deleteRefund?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteRefundArgs, 'refundId'>>;
  deleteRelease?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteReleaseArgs, 'releaseId'>>;
  removeCustomState?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationOrderManagementRemoveCustomStateArgs, 'orderId' | 'stateId'>>;
  resendOrderEvent?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementResendOrderEventArgs, 'orderId'>>;
  restartCancellation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartCancellationArgs, 'cancellationId' | 'input'>>;
  restartCompensation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartCompensationArgs, 'compensationId' | 'input'>>;
  restartDelivery?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartDeliveryArgs, 'deliveryId' | 'input'>>;
  restartOrderModificationOrderLines?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartOrderModificationOrderLinesArgs, 'input' | 'modificationId'>>;
  restartRefund?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartRefundArgs, 'input' | 'refundId'>>;
  restartRelease?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartReleaseArgs, 'input' | 'releaseId'>>;
  startCancellation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartCancellationArgs, 'cancellationId' | 'input'>>;
  startCompensation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartCompensationArgs, 'compensationId' | 'input'>>;
  startDelivery?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartDeliveryArgs, 'deliveryId' | 'input'>>;
  startOrderModificationOrderLines?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartOrderModificationOrderLinesArgs, 'input' | 'modificationId'>>;
  startRefund?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartRefundArgs, 'input' | 'refundId'>>;
  startRelease?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartReleaseArgs, 'input' | 'releaseId'>>;
  updateCompensation?: Resolver<ResolversTypes['OrderCompensation'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateCompensationArgs, 'compensationId' | 'input'>>;
  updateDelivery?: Resolver<ResolversTypes['OrderDelivery'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateDeliveryArgs, 'deliveryId' | 'input'>>;
  updateOrderComment?: Resolver<ResolversTypes['OrderComment'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateOrderCommentArgs, 'comment' | 'commentId' | 'orderId'>>;
  updateOrderModificationOrderLines?: Resolver<ResolversTypes['OrderModificationOrderLines'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateOrderModificationOrderLinesArgs, 'input' | 'modificationId'>>;
  updateRefund?: Resolver<ResolversTypes['OrderRefund'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateRefundArgs, 'input' | 'refundId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NShiftConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['NShiftConfig'] = ResolversParentTypes['NShiftConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['NShiftCredentials'], ParentType, ContextType>;
  deliveryCheckoutId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NShiftCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NShiftCredentials'] = ResolversParentTypes['NShiftCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NshiftAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['NshiftAddress'] = ResolversParentTypes['NshiftAddress']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quickId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NshiftPreparedShipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NshiftPreparedShipment'] = ResolversParentTypes['NshiftPreparedShipment']> = {
  agent?: Resolver<Maybe<ResolversTypes['NshiftAddress']>, ParentType, ContextType>;
  orderNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prepareId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['NshiftAddress'], ParentType, ContextType>;
  service?: Resolver<ResolversTypes['NshiftService'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NshiftServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NshiftService'] = ResolversParentTypes['NshiftService']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceSystem?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationResult'] = ResolversParentTypes['OperationResult']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = {
  Bonus?: Resolver<Maybe<ResolversTypes['OrderBonus']>, ParentType, ContextType>;
  Bundles?: Resolver<Maybe<Array<ResolversTypes['OrderBundle']>>, ParentType, ContextType>;
  Cancellations?: Resolver<Array<ResolversTypes['OrderCancellation']>, ParentType, ContextType>;
  Compensations?: Resolver<Array<ResolversTypes['OrderCompensation']>, ParentType, ContextType>;
  Deliveries?: Resolver<Array<ResolversTypes['OrderDelivery']>, ParentType, ContextType>;
  GiftCard?: Resolver<ResolversTypes['OrderGiftCard'], ParentType, ContextType>;
  GiftCardProduct?: Resolver<ResolversTypes['OrderGiftCardProduct'], ParentType, ContextType>;
  Modifications?: Resolver<Array<ResolversTypes['OrderModification']>, ParentType, ContextType>;
  Payment?: Resolver<ResolversTypes['OrderPayment'], ParentType, ContextType>;
  Promotion?: Resolver<ResolversTypes['OrderPromotion'], ParentType, ContextType>;
  Refunds?: Resolver<Array<ResolversTypes['OrderRefund']>, ParentType, ContextType>;
  Releases?: Resolver<Array<ResolversTypes['OrderRelease']>, ParentType, ContextType>;
  Reservation?: Resolver<Maybe<ResolversTypes['OrderReservation']>, ParentType, ContextType>;
  Shipping?: Resolver<ResolversTypes['OrderShipping'], ParentType, ContextType>;
  Voucher?: Resolver<ResolversTypes['OrderVoucher'], ParentType, ContextType>;
  attributes?: Resolver<Maybe<ResolversTypes['OrderAttributes']>, ParentType, ContextType>;
  billingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  channelType?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRules?: Resolver<Maybe<Array<ResolversTypes['OrderDiscountCodeRule']>>, ParentType, ContextType>;
  discountExternalRules?: Resolver<Maybe<Array<ResolversTypes['OrderDiscountExternalRule']>>, ParentType, ContextType>;
  discountOutcome?: Resolver<ResolversTypes['OrderDiscountOutcome'], ParentType, ContextType>;
  discountRules?: Resolver<Maybe<Array<ResolversTypes['OrderDiscountRule']>>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  gifts?: Resolver<Maybe<Array<ResolversTypes['OrderGift']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isTaxExempt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  languageCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Maybe<Array<ResolversTypes['OrderLine']>>, ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  shippingFees?: Resolver<Maybe<Array<ResolversTypes['ShippingFee']>>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxationCountry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderTotals'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderAddress'] = ResolversParentTypes['OrderAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  familyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateOrProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  telephoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderAttributesResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderAttributes'] = ResolversParentTypes['OrderAttributes']> = {
  company?: Resolver<Maybe<ResolversTypes['CompanyAttribute']>, ParentType, ContextType>;
  merchantReference1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantReference2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderBonus'] = ResolversParentTypes['OrderBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderBundleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderBundle'] = ResolversParentTypes['OrderBundle']> = {
  bundleGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCancellationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCancellation'] = ResolversParentTypes['OrderCancellation']> = {
  bonus?: Resolver<Maybe<ResolversTypes['CancellationBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  cancelledPayment?: Resolver<Maybe<ResolversTypes['CancelledPayment']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['CancellationGiftCardProduct']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['CancellationGiftCard']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderComment'] = ResolversParentTypes['OrderComment']> = {
  comment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCompensationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCompensation'] = ResolversParentTypes['OrderCompensation']> = {
  bonus?: Resolver<Maybe<ResolversTypes['CompensationBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['CompensationFee']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Maybe<Array<ResolversTypes['CompensationGiftCard']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Maybe<Array<ResolversTypes['CompensationOrderLine']>>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['CompensationPayment']>, ParentType, ContextType>;
  paymentProvider?: Resolver<Maybe<ResolversTypes['PaymentProvider']>, ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Maybe<Array<ResolversTypes['CompensationShippingFee']>>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderCompensationTotals'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCompensationTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCompensationTotals'] = ResolversParentTypes['OrderCompensationTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paymentTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCustomStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCustomState'] = ResolversParentTypes['OrderCustomState']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  operations?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CustomStateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDelivery'] = ResolversParentTypes['OrderDelivery']> = {
  bonus?: Resolver<Maybe<ResolversTypes['DeliveryBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  capturedPayment?: Resolver<Maybe<ResolversTypes['DeliveryCapture']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['DeliveryGiftCardProduct']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['DeliveryGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['DeliveryGift']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['DeliveryOrderLine']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['DeliveryShippingFee']>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderDeliveryTotals'], ParentType, ContextType>;
  tracking?: Resolver<Maybe<ResolversTypes['DeliveryTracking']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  vouchers?: Resolver<Array<ResolversTypes['DeliveryVoucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDeliveryTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDeliveryTotals'] = ResolversParentTypes['OrderDeliveryTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountCodeRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountCodeRule'] = ResolversParentTypes['OrderDiscountCodeRule']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountExternalRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountExternalRule'] = ResolversParentTypes['OrderDiscountExternalRule']> = {
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountOutcome'] = ResolversParentTypes['OrderDiscountOutcome']> = {
  cartRules?: Resolver<Maybe<Array<ResolversTypes['CartRuleOutcome']>>, ParentType, ContextType>;
  codeRules?: Resolver<Maybe<Array<ResolversTypes['CodeRuleOutcome']>>, ParentType, ContextType>;
  discountReduction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  externalRules?: Resolver<Maybe<Array<ResolversTypes['ExternalRuleOutcome']>>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shippingTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountRule'] = ResolversParentTypes['OrderDiscountRule']> = {
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGift'] = ResolversParentTypes['OrderGift']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCard'] = ResolversParentTypes['OrderGiftCard']> = {
  __resolveType: TypeResolveFn<'OrderGiftCardBSGGiftCard' | 'OrderGiftCardEmpty' | 'OrderGiftCardKBSGiftCard' | 'OrderGiftCardRetain24', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderGiftCardBsgGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardBSGGiftCard'] = ResolversParentTypes['OrderGiftCardBSGGiftCard']> = {
  giftCards?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardItemBSGGiftCard']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardEmpty'] = ResolversParentTypes['OrderGiftCardEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardItemBsgGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardItemBSGGiftCard'] = ResolversParentTypes['OrderGiftCardItemBSGGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardItemKbsGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardItemKBSGiftCard'] = ResolversParentTypes['OrderGiftCardItemKBSGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardItemRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardItemRetain24'] = ResolversParentTypes['OrderGiftCardItemRetain24']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  redeemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardKbsGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardKBSGiftCard'] = ResolversParentTypes['OrderGiftCardKBSGiftCard']> = {
  giftCards?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardItemKBSGiftCard']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProduct'] = ResolversParentTypes['OrderGiftCardProduct']> = {
  __resolveType: TypeResolveFn<'OrderGiftCardProductEmpty' | 'OrderGiftCardProductRetain24', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderGiftCardProductEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductEmpty'] = ResolversParentTypes['OrderGiftCardProductEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardProductItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductItem'] = ResolversParentTypes['OrderGiftCardProductItem']> = {
  __resolveType: TypeResolveFn<'OrderGiftCardProductItemRetain24', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distribution?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderGiftCardProductItemRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductItemRetain24'] = ResolversParentTypes['OrderGiftCardProductItemRetain24']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distribution?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  smsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardProductRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductRetain24'] = ResolversParentTypes['OrderGiftCardProductRetain24']> = {
  giftCardProducts?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardProductItemRetain24']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardRetain24'] = ResolversParentTypes['OrderGiftCardRetain24']> = {
  giftCards?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardItemRetain24']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistory'] = ResolversParentTypes['OrderHistory']> = {
  history?: Resolver<Array<ResolversTypes['OrderHistoryLogEntry']>, ParentType, ContextType>;
  operations?: Resolver<Maybe<ResolversTypes['OrderHistoryOperations']>, ParentType, ContextType>;
  states?: Resolver<Array<ResolversTypes['OrderState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryLogEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryLogEntry'] = ResolversParentTypes['OrderHistoryLogEntry']> = {
  actor?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['Comment']>, ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryOperationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryOperations'] = ResolversParentTypes['OrderHistoryOperations']> = {
  compensated?: Resolver<ResolversTypes['OrderHistoryTransactionsCompensated'], ParentType, ContextType>;
  delivered?: Resolver<ResolversTypes['OrderHistoryTransactions'], ParentType, ContextType>;
  refunded?: Resolver<ResolversTypes['OrderHistoryTransactions'], ParentType, ContextType>;
  released?: Resolver<ResolversTypes['OrderHistoryTransactions'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionBonus'] = ResolversParentTypes['OrderHistoryTransactionBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionGift'] = ResolversParentTypes['OrderHistoryTransactionGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionGiftCard'] = ResolversParentTypes['OrderHistoryTransactionGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionGiftCardProduct'] = ResolversParentTypes['OrderHistoryTransactionGiftCardProduct']> = {
  giftCardProductId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionOrderLine'] = ResolversParentTypes['OrderHistoryTransactionOrderLine']> = {
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionPayment'] = ResolversParentTypes['OrderHistoryTransactionPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionShippingFee'] = ResolversParentTypes['OrderHistoryTransactionShippingFee']> = {
  shippingFeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionVoucher'] = ResolversParentTypes['OrderHistoryTransactionVoucher']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactions'] = ResolversParentTypes['OrderHistoryTransactions']> = {
  bonus?: Resolver<Maybe<ResolversTypes['OrderHistoryTransactionBonus']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['OrderHistoryTransactionGiftCardProduct']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['OrderHistoryTransactionGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['OrderHistoryTransactionGift']>, ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['OrderHistoryTransactionOrderLine']>, ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['OrderHistoryTransactionShippingFee']>, ParentType, ContextType>;
  vouchers?: Resolver<Array<ResolversTypes['OrderHistoryTransactionVoucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderHistoryTransactionsCompensatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderHistoryTransactionsCompensated'] = ResolversParentTypes['OrderHistoryTransactionsCompensated']> = {
  bonus?: Resolver<Maybe<ResolversTypes['OrderHistoryTransactionBonus']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['OrderHistoryTransactionGiftCard']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['OrderHistoryTransactionPayment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLine'] = ResolversParentTypes['OrderLine']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaign?: Resolver<Maybe<ResolversTypes['CampaignIdentity']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountOutcome?: Resolver<ResolversTypes['OrderLineDiscountOutcome'], ParentType, ContextType>;
  displayDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distributedTotalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distributedTotalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distributedTotalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['OrderLineOption']>, ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineDiscountOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineDiscountOutcome'] = ResolversParentTypes['OrderLineDiscountOutcome']> = {
  bundles?: Resolver<Maybe<Array<ResolversTypes['BundleOutcome']>>, ParentType, ContextType>;
  cartRules?: Resolver<Maybe<Array<ResolversTypes['CartRuleOutcome']>>, ParentType, ContextType>;
  codeRules?: Resolver<Maybe<Array<ResolversTypes['CodeRuleOutcome']>>, ParentType, ContextType>;
  externalRules?: Resolver<Maybe<Array<ResolversTypes['ExternalRuleOutcome']>>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineModificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineModification'] = ResolversParentTypes['OrderLineModification']> = {
  data?: Resolver<ResolversTypes['OrderLineModificationData'], ParentType, ContextType>;
  modificationType?: Resolver<ResolversTypes['OrderLineModificationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineModificationCreateDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineModificationCreateData'] = ResolversParentTypes['OrderLineModificationCreateData']> = {
  basePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalPriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalTaxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productParentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productVariantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTaxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineModificationDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineModificationData'] = ResolversParentTypes['OrderLineModificationData']> = {
  __resolveType: TypeResolveFn<'OrderLineModificationCreateData' | 'OrderLineModificationDeleteData' | 'OrderLineModificationUpdateData', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderLineModificationDeleteDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineModificationDeleteData'] = ResolversParentTypes['OrderLineModificationDeleteData']> = {
  basePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  distributedTotalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalPriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalTaxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productParentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productVariantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTaxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineModificationUpdateDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineModificationUpdateData'] = ResolversParentTypes['OrderLineModificationUpdateData']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['OrderLineModificationUpdateDataPrice']>, ParentType, ContextType>;
  productParentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productVariantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineModificationUpdateDataPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineModificationUpdateDataPrice'] = ResolversParentTypes['OrderLineModificationUpdateDataPrice']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalPriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distributedTotalTaxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineOption'] = ResolversParentTypes['OrderLineOption']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderModificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderModification'] = ResolversParentTypes['OrderModification']> = {
  __resolveType: TypeResolveFn<'OrderModificationOrderLines', ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['OrderModificationType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
};

export type OrderModificationOrderLinesResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderModificationOrderLines'] = ResolversParentTypes['OrderModificationOrderLines']> = {
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newTotals?: Resolver<Maybe<ResolversTypes['OrderTotals']>, ParentType, ContextType>;
  oldTotals?: Resolver<Maybe<ResolversTypes['OrderTotals']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['OrderLineModification']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['OrderModificationType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderOperationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderOperationResult'] = ResolversParentTypes['OrderOperationResult']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPayment'] = ResolversParentTypes['OrderPayment']> = {
  __resolveType: TypeResolveFn<'OrderPaymentAdyen' | 'OrderPaymentAvarda' | 'OrderPaymentBrinkZeroPayment' | 'OrderPaymentKlarnaCheckout' | 'OrderPaymentQliroOne' | 'OrderPaymentSveaCheckout' | 'OrderPaymentWalleyCheckout', ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderPaymentAdyenResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentAdyen'] = ResolversParentTypes['OrderPaymentAdyen']> = {
  deepLinkPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantAccountCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  potentialFraud?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentAvardaResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentAvarda'] = ResolversParentTypes['OrderPaymentAvarda']> = {
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentBrinkZeroPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentBrinkZeroPayment'] = ResolversParentTypes['OrderPaymentBrinkZeroPayment']> = {
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originalProvider?: Resolver<ResolversTypes['OrderPaymentBrinkZeroPaymentOriginalProvider'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentBrinkZeroPaymentOriginalProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentBrinkZeroPaymentOriginalProvider'] = ResolversParentTypes['OrderPaymentBrinkZeroPaymentOriginalProvider']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentKlarnaCheckoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentKlarnaCheckout'] = ResolversParentTypes['OrderPaymentKlarnaCheckout']> = {
  deepLinkPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fraudStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  klarnaMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  klarnaOrderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOne'] = ResolversParentTypes['OrderPaymentQliroOne']> = {
  identityVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  merchantProvidedQuestionAnswer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderItems?: Resolver<Maybe<Array<ResolversTypes['OrderPaymentQliroOneOrderItem']>>, ParentType, ContextType>;
  paymentTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateVerificationCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qliroOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requireIdentityVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signupForNewsletter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsell?: Resolver<Maybe<ResolversTypes['OrderPaymentQliroOneUpsell']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOneOrderItem'] = ResolversParentTypes['OrderPaymentQliroOneOrderItem']> = {
  merchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentTransactionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneUpsellResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOneUpsell'] = ResolversParentTypes['OrderPaymentQliroOneUpsell']> = {
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['OrderPaymentQliroOneUpsellItems']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderPaymentQliroOneUpsellTotals'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneUpsellItemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOneUpsellItems'] = ResolversParentTypes['OrderPaymentQliroOneUpsellItems']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneUpsellTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOneUpsellTotals'] = ResolversParentTypes['OrderPaymentQliroOneUpsellTotals']> = {
  discountTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentSveaCheckoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentSveaCheckout'] = ResolversParentTypes['OrderPaymentSveaCheckout']> = {
  expirationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  peppolId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sveaMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sveaOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentWalleyCheckoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentWalleyCheckout'] = ResolversParentTypes['OrderPaymentWalleyCheckout']> = {
  amountToPay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseResult?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPromotionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotion'] = ResolversParentTypes['OrderPromotion']> = {
  __resolveType: TypeResolveFn<'OrderPromotionEmpty' | 'OrderPromotionVoyado', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderPromotionEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotionEmpty'] = ResolversParentTypes['OrderPromotionEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPromotionItemVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotionItemVoyado'] = ResolversParentTypes['OrderPromotionItemVoyado']> = {
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPromotionVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotionVoyado'] = ResolversParentTypes['OrderPromotionVoyado']> = {
  contactId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  promotions?: Resolver<Maybe<Array<ResolversTypes['OrderPromotionItemVoyado']>>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderRefundResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderRefund'] = ResolversParentTypes['OrderRefund']> = {
  bonus?: Resolver<Maybe<ResolversTypes['RefundBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['RefundFee']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['RefundGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['RefundGift']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['RefundOrderLine']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  refundedPayment?: Resolver<Maybe<ResolversTypes['RefundedPayment']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['RefundShippingFee']>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderRefundTotals'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  vouchers?: Resolver<Array<ResolversTypes['RefundVoucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderRefundTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderRefundTotals'] = ResolversParentTypes['OrderRefundTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderReleaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderRelease'] = ResolversParentTypes['OrderRelease']> = {
  bonus?: Resolver<Maybe<ResolversTypes['ReleaseBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['ReleaseGiftCardProduct']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['ReleaseGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['ReleaseGift']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['ReleaseOrderLine']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  releasedPayment?: Resolver<Maybe<ResolversTypes['ReleasedPayment']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['ReleaseShippingFee']>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderReservationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderReservation'] = ResolversParentTypes['OrderReservation']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  processedProductVariants?: Resolver<Array<ResolversTypes['ProductVariantReservation']>, ParentType, ContextType>;
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderSearchHit'] = ResolversParentTypes['OrderSearchHit']> = {
  billingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  channelType?: Resolver<Maybe<ResolversTypes['ChannelType']>, ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  discountExternalReferences?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isTaxExempt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  merchantReference1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantReference2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProviderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxationCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderTotals'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderSearchHits'] = ResolversParentTypes['OrderSearchHits']> = {
  aggregations?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  hits?: Resolver<Array<ResolversTypes['OrderSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShipping'] = ResolversParentTypes['OrderShipping']> = {
  __resolveType: TypeResolveFn<'OrderShippingAvardaShippingBroker' | 'OrderShippingEmpty' | 'OrderShippingIngrid' | 'OrderShippingKlarnaShippingAssistant' | 'OrderShippingNshift' | 'OrderShippingQliroIntegratedShipping' | 'OrderShippingWalleyDeliveryModule', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderShippingAvardaShippingBrokerResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingAvardaShippingBroker'] = ResolversParentTypes['OrderShippingAvardaShippingBroker']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipping?: Resolver<Maybe<ResolversTypes['AvardaShippingBrokerShipping']>, ParentType, ContextType>;
  shippingBroker?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingEmpty'] = ResolversParentTypes['OrderShippingEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingIngridResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingIngrid'] = ResolversParentTypes['OrderShippingIngrid']> = {
  deliveries?: Resolver<Maybe<Array<ResolversTypes['IngridDelivery']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingKlarnaShippingAssistantResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingKlarnaShippingAssistant'] = ResolversParentTypes['OrderShippingKlarnaShippingAssistant']> = {
  klarnaMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  klarnaOrderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selectedShippingOption?: Resolver<ResolversTypes['KlarnaShippingAssistantSelectedShippingOption'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingNshiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingNshift'] = ResolversParentTypes['OrderShippingNshift']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preparedShipment?: Resolver<Maybe<ResolversTypes['NshiftPreparedShipment']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingQliroIntegratedShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingQliroIntegratedShipping'] = ResolversParentTypes['OrderShippingQliroIntegratedShipping']> = {
  ingrid?: Resolver<Maybe<ResolversTypes['QliroIngrid']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipments?: Resolver<Array<ResolversTypes['QliroShipmentOrderItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingWalleyDeliveryModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingWalleyDeliveryModule'] = ResolversParentTypes['OrderShippingWalleyDeliveryModule']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipping?: Resolver<ResolversTypes['WalleyDeliveryModuleShipping'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatus'] = ResolversParentTypes['OrderStatus']> = {
  customStates?: Resolver<Maybe<Array<ResolversTypes['OrderCustomState']>>, ParentType, ContextType>;
  orderStates?: Resolver<Array<ResolversTypes['OrderState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderTotals'] = ResolversParentTypes['OrderTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucher'] = ResolversParentTypes['OrderVoucher']> = {
  __resolveType: TypeResolveFn<'OrderVoucherEmpty' | 'OrderVoucherVoyado', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderVoucherEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucherEmpty'] = ResolversParentTypes['OrderVoucherEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderVoucherItemVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucherItemVoyado'] = ResolversParentTypes['OrderVoucherItemVoyado']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  checkNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderVoucherVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucherVoyado'] = ResolversParentTypes['OrderVoucherVoyado']> = {
  contactId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<ResolversTypes['OrderVoucherItemVoyado']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Outcome'] = ResolversParentTypes['Outcome']> = {
  combineItemDiscount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  discountItemRule?: Resolver<Maybe<ResolversTypes['ItemRule']>, ParentType, ContextType>;
  discountQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  discountValue?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  gifts?: Resolver<Maybe<Array<ResolversTypes['Gift']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRecurrent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shippingTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  subOutcomes?: Resolver<Maybe<Array<ResolversTypes['SubOutcome']>>, ParentType, ContextType>;
  triggerItemRule?: Resolver<Maybe<ResolversTypes['ItemRule']>, ParentType, ContextType>;
  triggerQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentProvider'] = ResolversParentTypes['PaymentProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['PaymentProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformConfig'] = ResolversParentTypes['PlatformConfig']> = {
  externalApi?: Resolver<ResolversTypes['ExternalApi'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaign?: Resolver<Maybe<ResolversTypes['CampaignIdentity']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lowestPriceRecord?: Resolver<Maybe<ResolversTypes['PriceRecord']>, ParentType, ContextType>;
  priceRecords?: Resolver<Array<ResolversTypes['PriceRecord']>, ParentType, ContextType>;
  referencePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceRecord'] = ResolversParentTypes['PriceRecord']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaign?: Resolver<Maybe<ResolversTypes['CampaignIdentity']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceRule'] = ResolversParentTypes['PriceRule']> = {
  monies?: Resolver<Maybe<Array<ResolversTypes['CampaignMoney']>>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductParent'] = ResolversParentTypes['ProductParent']> = {
  Variants?: Resolver<Maybe<Array<ResolversTypes['ProductVariant']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayDescriptions?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  displayNames?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductParentSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductParentSearchHit'] = ResolversParentTypes['ProductParentSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['KeyValue']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductParentSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductParentSearchHits'] = ResolversParentTypes['ProductParentSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['ProductParentSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant']> = {
  Stock?: Resolver<Maybe<ResolversTypes['Stock']>, ParentType, ContextType>;
  StoreGroupPrices?: Resolver<Array<ResolversTypes['StoreGroupPrice']>, ParentType, ContextType>;
  TaxGroup?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dimensions?: Resolver<Maybe<ResolversTypes['Dimensions']>, ParentType, ContextType>;
  displayDescriptions?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  displayNames?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shippingAttributes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantReservationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantReservation'] = ResolversParentTypes['ProductVariantReservation']> = {
  inventoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantRules'] = ResolversParentTypes['ProductVariantRules']> = {
  tagLogic?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantSearchHit'] = ResolversParentTypes['ProductVariantSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['KeyValue']>>, ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantSearchHits'] = ResolversParentTypes['ProductVariantSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['ProductVariantSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionProvider'] = ResolversParentTypes['PromotionProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['PromotionProviderName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Provider'] = ResolversParentTypes['Provider']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderStatusLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProviderStatusLog'] = ResolversParentTypes['ProviderStatusLog']> = {
  current?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  history?: Resolver<Array<ResolversTypes['ProviderStatusLogEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderStatusLogEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProviderStatusLogEntry'] = ResolversParentTypes['ProviderStatusLogEntry']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroIngridResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroIngrid'] = ResolversParentTypes['QliroIngrid']> = {
  deliveries?: Resolver<Maybe<Array<ResolversTypes['QliroIngridDelivery']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroIngridDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroIngridDelivery'] = ResolversParentTypes['QliroIngridDelivery']> = {
  carrierProductId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryAddress?: Resolver<Maybe<ResolversTypes['QliroIngridDeliveryAddress']>, ParentType, ContextType>;
  deliveryType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tosId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroIngridDeliveryAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroIngridDeliveryAddress'] = ResolversParentTypes['QliroIngridDeliveryAddress']> = {
  addressLines?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroOneConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroOneConfig'] = ResolversParentTypes['QliroOneConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['QliroOneCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integratedShipping?: Resolver<Maybe<ResolversTypes['QliroOneIntegratedShipping']>, ParentType, ContextType>;
  merchantOrderManagementStatusPushUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  useLegacyIds?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroOneCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroOneCredentials'] = ResolversParentTypes['QliroOneCredentials']> = {
  merchantApiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantApiSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroOneIntegratedShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroOneIntegratedShipping'] = ResolversParentTypes['QliroOneIntegratedShipping']> = {
  deliveryCheckoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroShipmentOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroShipmentOrderItem'] = ResolversParentTypes['QliroShipmentOrderItem']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['QliroShipmentOrderItemMetadata']>, ParentType, ContextType>;
  paymentTransactionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pricePerItemExVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pricePerItemIncVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroShipmentOrderItemMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroShipmentOrderItemMetadata'] = ResolversParentTypes['QliroShipmentOrderItemMetadata']> = {
  shippingMethodMerchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  Configuration?: Resolver<Maybe<ResolversTypes['QueryConfigurationManagement']>, ParentType, ContextType>;
  Order?: Resolver<ResolversTypes['QueryOrderManagement'], ParentType, ContextType>;
  PlatformConfig?: Resolver<Maybe<ResolversTypes['QueryPlatformConfig']>, ParentType, ContextType>;
  Product?: Resolver<ResolversTypes['QueryProductManagement'], ParentType, ContextType>;
  apiClient?: Resolver<ResolversTypes['ApiClientDetails'], ParentType, ContextType, RequireFields<QueryApiClientArgs, 'clientId'>>;
  apiClients?: Resolver<ResolversTypes['ApiClientsList'], ParentType, ContextType, RequireFields<QueryApiClientsArgs, 'limit'>>;
  apiResources?: Resolver<Array<ResolversTypes['ApiResource']>, ParentType, ContextType>;
  getAdyenConfig?: Resolver<ResolversTypes['AdyenConfig'], ParentType, ContextType, RequireFields<QueryGetAdyenConfigArgs, 'id'>>;
  getAdyenWebhooks?: Resolver<Array<ResolversTypes['AdyenWebhookLegacy']>, ParentType, ContextType>;
  getAvardaConfig?: Resolver<ResolversTypes['AvardaConfig'], ParentType, ContextType, RequireFields<QueryGetAvardaConfigArgs, 'id'>>;
  getBSGGiftCardConfig?: Resolver<ResolversTypes['BSGGiftCardConfig'], ParentType, ContextType, RequireFields<QueryGetBsgGiftCardConfigArgs, 'id'>>;
  getBonusConfig?: Resolver<ResolversTypes['BonusConfig'], ParentType, ContextType, RequireFields<QueryGetBonusConfigArgs, 'id'>>;
  getBonusConfigs?: Resolver<Array<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  getBundleGroup?: Resolver<ResolversTypes['BundleGroup'], ParentType, ContextType, RequireFields<QueryGetBundleGroupArgs, 'id'>>;
  getCampaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<QueryGetCampaignArgs, 'id'>>;
  getCampaignGroup?: Resolver<ResolversTypes['CampaignGroupList'], ParentType, ContextType, RequireFields<QueryGetCampaignGroupArgs, 'id'>>;
  getCurrencyRates?: Resolver<Maybe<ResolversTypes['CurrencyRates']>, ParentType, ContextType, RequireFields<QueryGetCurrencyRatesArgs, 'baseCurrencyCode' | 'filterCurrencies'>>;
  getDiscountCode?: Resolver<ResolversTypes['DiscountCode'], ParentType, ContextType, RequireFields<QueryGetDiscountCodeArgs, 'code'>>;
  getDiscountCodeRule?: Resolver<ResolversTypes['DiscountCodeRule'], ParentType, ContextType, RequireFields<QueryGetDiscountCodeRuleArgs, 'id'>>;
  getDiscountExternalRule?: Resolver<ResolversTypes['DiscountExternalRule'], ParentType, ContextType, RequireFields<QueryGetDiscountExternalRuleArgs, 'id'>>;
  getDiscountRule?: Resolver<ResolversTypes['DiscountRule'], ParentType, ContextType, RequireFields<QueryGetDiscountRuleArgs, 'id'>>;
  getExternalEventsDestinationAPI?: Resolver<ResolversTypes['ExternalEventDestinationAPI'], ParentType, ContextType, RequireFields<QueryGetExternalEventsDestinationApiArgs, 'destName'>>;
  getExternalEventsDestinationAPIs?: Resolver<ResolversTypes['ExternalEventDestinationAPIs'], ParentType, ContextType>;
  getExternalEventsDestinationEventBuses?: Resolver<ResolversTypes['ExternalEventDestinationEventBuses'], ParentType, ContextType>;
  getExternalEventsRule?: Resolver<ResolversTypes['ExternalEventRuleState'], ParentType, ContextType, RequireFields<QueryGetExternalEventsRuleArgs, 'ruleName'>>;
  getExternalEventsRules?: Resolver<ResolversTypes['ExternalEventRules'], ParentType, ContextType>;
  getGenerateCodes?: Resolver<ResolversTypes['GenerateCodes'], ParentType, ContextType, RequireFields<QueryGetGenerateCodesArgs, 'jobId'>>;
  getGiftCardProviders?: Resolver<Array<ResolversTypes['Provider']>, ParentType, ContextType>;
  getIngridConfig?: Resolver<ResolversTypes['IngridConfig'], ParentType, ContextType, RequireFields<QueryGetIngridConfigArgs, 'id'>>;
  getInventories?: Resolver<Array<ResolversTypes['Inventory']>, ParentType, ContextType>;
  getInventory?: Resolver<ResolversTypes['Inventory'], ParentType, ContextType, RequireFields<QueryGetInventoryArgs, 'id'>>;
  getInventoryStoreMarket?: Resolver<ResolversTypes['InventoryStoreMarket'], ParentType, ContextType, RequireFields<QueryGetInventoryStoreMarketArgs, 'countryCode' | 'storeGroupId'>>;
  getKBSGiftCardConfig?: Resolver<ResolversTypes['KBSGiftCardConfig'], ParentType, ContextType, RequireFields<QueryGetKbsGiftCardConfigArgs, 'id'>>;
  getKlarnaCheckoutConfig?: Resolver<ResolversTypes['KlarnaCheckoutConfig'], ParentType, ContextType, RequireFields<QueryGetKlarnaCheckoutConfigArgs, 'id'>>;
  getNShiftConfig?: Resolver<ResolversTypes['NShiftConfig'], ParentType, ContextType, RequireFields<QueryGetNShiftConfigArgs, 'id'>>;
  getPaymentProviders?: Resolver<Array<ResolversTypes['Provider']>, ParentType, ContextType>;
  getQliroOneConfig?: Resolver<ResolversTypes['QliroOneConfig'], ParentType, ContextType, RequireFields<QueryGetQliroOneConfigArgs, 'id'>>;
  getRetain24Config?: Resolver<ResolversTypes['Retain24Config'], ParentType, ContextType, RequireFields<QueryGetRetain24ConfigArgs, 'id'>>;
  getShippingProviders?: Resolver<Array<ResolversTypes['Provider']>, ParentType, ContextType>;
  getShippingTaxGroup?: Resolver<ResolversTypes['ShippingTaxGroup'], ParentType, ContextType>;
  getStoreGroup?: Resolver<ResolversTypes['StoreGroup'], ParentType, ContextType, RequireFields<QueryGetStoreGroupArgs, 'id'>>;
  getStoreGroups?: Resolver<Array<ResolversTypes['StoreGroup']>, ParentType, ContextType>;
  getSveaCheckoutConfig?: Resolver<ResolversTypes['SveaCheckoutConfig'], ParentType, ContextType, RequireFields<QueryGetSveaCheckoutConfigArgs, 'id'>>;
  getTaxGroup?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType, RequireFields<QueryGetTaxGroupArgs, 'id'>>;
  getTaxMarkets?: Resolver<Array<ResolversTypes['TaxMarket']>, ParentType, ContextType, RequireFields<QueryGetTaxMarketsArgs, 'taxGroupId'>>;
  getVoyadoConfig?: Resolver<ResolversTypes['VoyadoConfig'], ParentType, ContextType, RequireFields<QueryGetVoyadoConfigArgs, 'id'>>;
  getVoyadoConfigs?: Resolver<Array<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  getWalleyCheckoutConfig?: Resolver<ResolversTypes['WalleyCheckoutConfig'], ParentType, ContextType, RequireFields<QueryGetWalleyCheckoutConfigArgs, 'id'>>;
  groupUsers?: Resolver<ResolversTypes['UsersList'], ParentType, ContextType, RequireFields<QueryGroupUsersArgs, 'groupname' | 'limit'>>;
  groups?: Resolver<Maybe<ResolversTypes['GroupsList']>, ParentType, ContextType, RequireFields<QueryGroupsArgs, 'limit'>>;
  searchBundleGroups?: Resolver<Maybe<ResolversTypes['BundleGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchBundleGroupsArgs, 'from' | 'size'>>;
  searchCampaignGroups?: Resolver<Maybe<ResolversTypes['CampaignGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchCampaignGroupsArgs, 'from' | 'size'>>;
  searchCampaigns?: Resolver<Maybe<ResolversTypes['CampaignSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchCampaignsArgs, 'from' | 'size'>>;
  searchCodesGenerations?: Resolver<Maybe<ResolversTypes['CodesGenerationSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchCodesGenerationsArgs, 'from' | 'size'>>;
  searchDiscountCodeRules?: Resolver<Maybe<ResolversTypes['DiscountCodeRuleSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountCodeRulesArgs, 'from' | 'size'>>;
  searchDiscountCodes?: Resolver<Maybe<ResolversTypes['DiscountCodeSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountCodesArgs, 'from' | 'size'>>;
  searchDiscountExternalRules?: Resolver<Maybe<ResolversTypes['DiscountExternalRuleSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountExternalRulesArgs, 'from' | 'size'>>;
  searchDiscountRules?: Resolver<Maybe<ResolversTypes['DiscountRuleSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountRulesArgs, 'from' | 'size'>>;
  searchOrders?: Resolver<Maybe<ResolversTypes['OrderSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchOrdersArgs, 'from' | 'size'>>;
  searchProductParents?: Resolver<Maybe<ResolversTypes['ProductParentSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchProductParentsArgs, 'from' | 'size'>>;
  searchProductVariants?: Resolver<Maybe<ResolversTypes['ProductVariantSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchProductVariantsArgs, 'from' | 'size'>>;
  searchStoreGroups?: Resolver<Maybe<ResolversTypes['StoreGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchStoreGroupsArgs, 'from' | 'size'>>;
  searchStoreMarkets?: Resolver<Maybe<ResolversTypes['StoreMarketSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchStoreMarketsArgs, 'from' | 'size'>>;
  searchTaxGroups?: Resolver<Maybe<ResolversTypes['TaxGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchTaxGroupsArgs, 'from' | 'size'>>;
  searchTaxMarkets?: Resolver<Maybe<ResolversTypes['TaxMarketSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchTaxMarketsArgs, 'from' | 'size'>>;
  tagKeys?: Resolver<Maybe<ResolversTypes['TagKeys']>, ParentType, ContextType>;
  tagValues?: Resolver<Maybe<ResolversTypes['TagValues']>, ParentType, ContextType, RequireFields<QueryTagValuesArgs, 'key'>>;
  user?: Resolver<ResolversTypes['UserDetails'], ParentType, ContextType, RequireFields<QueryUserArgs, 'username'>>;
  users?: Resolver<ResolversTypes['UsersList'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'limit'>>;
};

export type QueryConfigurationManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryConfigurationManagement'] = ResolversParentTypes['QueryConfigurationManagement']> = {
  validate?: Resolver<Maybe<ResolversTypes['QueryConfigurationManagementValidate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryConfigurationManagementValidateResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryConfigurationManagementValidate'] = ResolversParentTypes['QueryConfigurationManagementValidate']> = {
  adyen?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateAdyenArgs, 'baseUrl' | 'merchantAccount' | 'xApiKey'>>;
  avarda?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateAvardaArgs, 'authUrl' | 'baseUrl' | 'clientId' | 'clientSecret'>>;
  bonus?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateBonusArgs, 'input'>>;
  klarnaCheckout?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateKlarnaCheckoutArgs, 'baseUrl' | 'password' | 'username'>>;
  qliroOne?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateQliroOneArgs, 'input'>>;
  retain24?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateRetain24Args, 'input'>>;
  sveaCheckout?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateSveaCheckoutArgs, 'baseUrl' | 'checkoutSecret' | 'merchantId'>>;
  walleyCheckout?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateWalleyCheckoutArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryOrderManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryOrderManagement'] = ResolversParentTypes['QueryOrderManagement']> = {
  cancellation?: Resolver<ResolversTypes['OrderCancellation'], ParentType, ContextType, RequireFields<QueryOrderManagementCancellationArgs, 'cancellationId'>>;
  compensation?: Resolver<ResolversTypes['OrderCompensation'], ParentType, ContextType, RequireFields<QueryOrderManagementCompensationArgs, 'compensationId'>>;
  customReasonCode?: Resolver<ResolversTypes['CustomReasonCode'], ParentType, ContextType, RequireFields<QueryOrderManagementCustomReasonCodeArgs, 'code'>>;
  customReasonCodes?: Resolver<ResolversTypes['CustomReasonCodes'], ParentType, ContextType>;
  customState?: Resolver<ResolversTypes['CustomState'], ParentType, ContextType, RequireFields<QueryOrderManagementCustomStateArgs, 'stateId'>>;
  customStates?: Resolver<ResolversTypes['CustomStates'], ParentType, ContextType>;
  delivery?: Resolver<ResolversTypes['OrderDelivery'], ParentType, ContextType, RequireFields<QueryOrderManagementDeliveryArgs, 'deliveryId'>>;
  details?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<QueryOrderManagementDetailsArgs, 'id'>>;
  history?: Resolver<ResolversTypes['OrderHistory'], ParentType, ContextType, RequireFields<QueryOrderManagementHistoryArgs, 'id'>>;
  orderModificationOrderLines?: Resolver<Maybe<ResolversTypes['OrderModificationOrderLines']>, ParentType, ContextType, RequireFields<QueryOrderManagementOrderModificationOrderLinesArgs, 'modificationId'>>;
  refund?: Resolver<ResolversTypes['OrderRefund'], ParentType, ContextType, RequireFields<QueryOrderManagementRefundArgs, 'refundId'>>;
  release?: Resolver<ResolversTypes['OrderRelease'], ParentType, ContextType, RequireFields<QueryOrderManagementReleaseArgs, 'releaseId'>>;
  status?: Resolver<ResolversTypes['OrderHistory'], ParentType, ContextType, RequireFields<QueryOrderManagementStatusArgs, 'id'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryPlatformConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryPlatformConfig'] = ResolversParentTypes['QueryPlatformConfig']> = {
  campaign?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  stock?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  store?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryProductManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryProductManagement'] = ResolversParentTypes['QueryProductManagement']> = {
  parent?: Resolver<ResolversTypes['ProductParent'], ParentType, ContextType, RequireFields<QueryProductManagementParentArgs, 'id'>>;
  variant?: Resolver<ResolversTypes['ProductVariant'], ParentType, ContextType, RequireFields<QueryProductManagementVariantArgs, 'id'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reason'] = ResolversParentTypes['Reason']> = {
  cause?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundBonus'] = ResolversParentTypes['RefundBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundFee'] = ResolversParentTypes['RefundFee']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundGift'] = ResolversParentTypes['RefundGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundGiftCard'] = ResolversParentTypes['RefundGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundOrderLine'] = ResolversParentTypes['RefundOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundShippingFee'] = ResolversParentTypes['RefundShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundVoucher'] = ResolversParentTypes['RefundVoucher']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  voucherId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundedPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundedPayment'] = ResolversParentTypes['RefundedPayment']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseBonus'] = ResolversParentTypes['ReleaseBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseGift'] = ResolversParentTypes['ReleaseGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseGiftCard'] = ResolversParentTypes['ReleaseGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseGiftCardProduct'] = ResolversParentTypes['ReleaseGiftCardProduct']> = {
  cancelErrorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cancelErrorStatusCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cancelTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createFailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  priceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseOrderLine'] = ResolversParentTypes['ReleaseOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseShippingFee'] = ResolversParentTypes['ReleaseShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleasedPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleasedPayment'] = ResolversParentTypes['ReleasedPayment']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Retain24ConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['Retain24Config'] = ResolversParentTypes['Retain24Config']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['Retain24Credentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  redeem?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  simulationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Retain24CredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Retain24Credentials'] = ResolversParentTypes['Retain24Credentials']> = {
  certificate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privateKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingRule'] = ResolversParentTypes['RoundingRule']> = {
  roundDown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  roundUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targets?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingRules'] = ResolversParentTypes['RoundingRules']> = {
  defaultRule?: Resolver<ResolversTypes['RoundingRule'], ParentType, ContextType>;
  marketRules?: Resolver<Maybe<Array<ResolversTypes['MarketRoundingRule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rule'] = ResolversParentTypes['Rule']> = {
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schema'] = ResolversParentTypes['Schema']> = {
  mutation?: Resolver<Maybe<ResolversTypes['Mutation']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>;
  subscriptions?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingFee'] = ResolversParentTypes['ShippingFee']> = {
  basePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingProvider'] = ResolversParentTypes['ShippingProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['ShippingProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingTaxGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingTaxGroup'] = ResolversParentTypes['ShippingTaxGroup']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stock'] = ResolversParentTypes['Stock']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventories?: Resolver<Array<ResolversTypes['StockInventory']>, ParentType, ContextType>;
  stockQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  validateStock?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockInventoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockInventory'] = ResolversParentTypes['StockInventory']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  inventoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroup'] = ResolversParentTypes['StoreGroup']> = {
  channelType?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  countryOfSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeMarkets?: Resolver<Maybe<Array<ResolversTypes['StoreMarket']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroupPrice'] = ResolversParentTypes['StoreGroupPrice']> = {
  marketPrices?: Resolver<Array<ResolversTypes['MarketPrice']>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroupSearchHit'] = ResolversParentTypes['StoreGroupSearchHit']> = {
  countryOfSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroupSearchHits'] = ResolversParentTypes['StoreGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['StoreGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreMarket'] = ResolversParentTypes['StoreMarket']> = {
  capabilities?: Resolver<Maybe<ResolversTypes['Capabilities']>, ParentType, ContextType>;
  cartCapabilities?: Resolver<Maybe<ResolversTypes['CartCapabilities']>, ParentType, ContextType>;
  channelType?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryOfSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreMarketSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreMarketSearchHit'] = ResolversParentTypes['StoreMarketSearchHit']> = {
  cartCapabilities?: Resolver<Maybe<ResolversTypes['CartCapabilities']>, ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  shippingProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreMarketSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreMarketSearchHits'] = ResolversParentTypes['StoreMarketSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['StoreMarketSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubOutcome'] = ResolversParentTypes['SubOutcome']> = {
  discountQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  discountValue?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  triggerQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  OnAdyenWebhookChanged?: SubscriptionResolver<Maybe<ResolversTypes['AdyenWebhook']>, "OnAdyenWebhookChanged", ParentType, ContextType, RequireFields<SubscriptionOnAdyenWebhookChangedArgs, 'configId'>>;
};

export type SveaCheckoutConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['SveaCheckoutConfig'] = ResolversParentTypes['SveaCheckoutConfig']> = {
  checkoutBaseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['SveaCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  merchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentAdminBaseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SveaCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SveaCredentials'] = ResolversParentTypes['SveaCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagCondition'] = ResolversParentTypes['TagCondition']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagKeysResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagKeys'] = ResolversParentTypes['TagKeys']> = {
  keys?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagValuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagValues'] = ResolversParentTypes['TagValues']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroup'] = ResolversParentTypes['TaxGroup']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupSearchHit'] = ResolversParentTypes['TaxGroupSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupSearchHits'] = ResolversParentTypes['TaxGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['TaxGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxMarket'] = ResolversParentTypes['TaxMarket']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxMarketSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxMarketSearchHit'] = ResolversParentTypes['TaxMarketSearchHit']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxMarketSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxMarketSearchHits'] = ResolversParentTypes['TaxMarketSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['TaxMarketSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateExternalEventsDestinationApiResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateExternalEventsDestinationAPIResult'] = ResolversParentTypes['UpdateExternalEventsDestinationAPIResult']> = {
  apiDestinationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsagePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsagePlan'] = ResolversParentTypes['UsagePlan']> = {
  burstLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quotaLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quotaPeriod?: Resolver<ResolversTypes['QuotaPeriod'], ParentType, ContextType>;
  rateLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDetails'] = ResolversParentTypes['UserDetails']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersListResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersList'] = ResolversParentTypes['UsersList']> = {
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidDateRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidDateRange'] = ResolversParentTypes['ValidDateRange']> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidateConfigResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidateConfigResult'] = ResolversParentTypes['ValidateConfigResult']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherProvider'] = ResolversParentTypes['VoucherProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['VoucherProviderName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoyadoConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoyadoConfig'] = ResolversParentTypes['VoyadoConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['VoyadoCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoyadoCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoyadoCredentials'] = ResolversParentTypes['VoyadoCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyCheckoutConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyCheckoutConfig'] = ResolversParentTypes['WalleyCheckoutConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['WalleyCredentials'], ParentType, ContextType>;
  deliveryModuleEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  profileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyCredentials'] = ResolversParentTypes['WalleyCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleDeliveryDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleDeliveryDate'] = ResolversParentTypes['WalleyDeliveryModuleDeliveryDate']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  timeFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeTo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleDestinationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleDestination'] = ResolversParentTypes['WalleyDeliveryModuleDestination']> = {
  deliveryDate?: Resolver<Maybe<ResolversTypes['WalleyDeliveryModuleDeliveryDate']>, ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleOption'] = ResolversParentTypes['WalleyDeliveryModuleOption']> = {
  Fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleShipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleShipment'] = ResolversParentTypes['WalleyDeliveryModuleShipment']> = {
  bookedShipmentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feeItemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingChoice?: Resolver<ResolversTypes['WalleyDeliveryModuleShippingChoice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleShipping'] = ResolversParentTypes['WalleyDeliveryModuleShipping']> = {
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipments?: Resolver<Array<ResolversTypes['WalleyDeliveryModuleShipment']>, ParentType, ContextType>;
  shippingFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleShippingChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleShippingChoice'] = ResolversParentTypes['WalleyDeliveryModuleShippingChoice']> = {
  destination?: Resolver<ResolversTypes['WalleyDeliveryModuleDestination'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['WalleyDeliveryModuleOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSDateTime?: GraphQLScalarType;
  AWSEmail?: GraphQLScalarType;
  AWSJSON?: GraphQLScalarType;
  Actor?: ActorResolvers<ContextType>;
  AddExternalEventsDestinationAPIResult?: AddExternalEventsDestinationApiResultResolvers<ContextType>;
  AddExternalEventsDestinationEventBusResult?: AddExternalEventsDestinationEventBusResultResolvers<ContextType>;
  AddExternalEventsRuleResult?: AddExternalEventsRuleResultResolvers<ContextType>;
  AdyenConfig?: AdyenConfigResolvers<ContextType>;
  AdyenCredentials?: AdyenCredentialsResolvers<ContextType>;
  AdyenWebhook?: AdyenWebhookResolvers<ContextType>;
  AdyenWebhookCredentials?: AdyenWebhookCredentialsResolvers<ContextType>;
  AdyenWebhookGenerationResult?: AdyenWebhookGenerationResultResolvers<ContextType>;
  AdyenWebhookLegacy?: AdyenWebhookLegacyResolvers<ContextType>;
  ApiClient?: ApiClientResolvers<ContextType>;
  ApiClientDetails?: ApiClientDetailsResolvers<ContextType>;
  ApiClientsList?: ApiClientsListResolvers<ContextType>;
  ApiResource?: ApiResourceResolvers<ContextType>;
  ApiScope?: ApiScopeResolvers<ContextType>;
  AvardaConfig?: AvardaConfigResolvers<ContextType>;
  AvardaCredentials?: AvardaCredentialsResolvers<ContextType>;
  AvardaShippingBroker?: AvardaShippingBrokerResolvers<ContextType>;
  AvardaShippingBrokerShipping?: AvardaShippingBrokerShippingResolvers<ContextType>;
  AvardaShippingBrokerShippingIngrid?: AvardaShippingBrokerShippingIngridResolvers<ContextType>;
  AvardaShippingBrokerShippingIngridCategory?: AvardaShippingBrokerShippingIngridCategoryResolvers<ContextType>;
  AvardaShippingBrokerShippingIngridCategoryTag?: AvardaShippingBrokerShippingIngridCategoryTagResolvers<ContextType>;
  AvardaShippingBrokerShippingIngridLocation?: AvardaShippingBrokerShippingIngridLocationResolvers<ContextType>;
  AvardaShippingBrokerShippingIngridLocationAddress?: AvardaShippingBrokerShippingIngridLocationAddressResolvers<ContextType>;
  BSGGiftCardConfig?: BsgGiftCardConfigResolvers<ContextType>;
  BSGGiftCardCredentials?: BsgGiftCardCredentialsResolvers<ContextType>;
  BonusConfig?: BonusConfigResolvers<ContextType>;
  BonusCredentials?: BonusCredentialsResolvers<ContextType>;
  BonusProvider?: BonusProviderResolvers<ContextType>;
  Bundle?: BundleResolvers<ContextType>;
  BundleGroup?: BundleGroupResolvers<ContextType>;
  BundleGroupSearchHit?: BundleGroupSearchHitResolvers<ContextType>;
  BundleGroupSearchHits?: BundleGroupSearchHitsResolvers<ContextType>;
  BundleItemRule?: BundleItemRuleResolvers<ContextType>;
  BundleOutcome?: BundleOutcomeResolvers<ContextType>;
  BundleStoreMarket?: BundleStoreMarketResolvers<ContextType>;
  Campaign?: CampaignResolvers<ContextType>;
  CampaignDeletionResult?: CampaignDeletionResultResolvers<ContextType>;
  CampaignGroup?: CampaignGroupResolvers<ContextType>;
  CampaignGroupList?: CampaignGroupListResolvers<ContextType>;
  CampaignGroupSearchHit?: CampaignGroupSearchHitResolvers<ContextType>;
  CampaignGroupSearchHits?: CampaignGroupSearchHitsResolvers<ContextType>;
  CampaignIdentity?: CampaignIdentityResolvers<ContextType>;
  CampaignMoney?: CampaignMoneyResolvers<ContextType>;
  CampaignSearchHit?: CampaignSearchHitResolvers<ContextType>;
  CampaignSearchHits?: CampaignSearchHitsResolvers<ContextType>;
  CampaignStoreMarket?: CampaignStoreMarketResolvers<ContextType>;
  CancellationBonus?: CancellationBonusResolvers<ContextType>;
  CancellationGiftCard?: CancellationGiftCardResolvers<ContextType>;
  CancellationGiftCardProduct?: CancellationGiftCardProductResolvers<ContextType>;
  CancelledPayment?: CancelledPaymentResolvers<ContextType>;
  Capabilities?: CapabilitiesResolvers<ContextType>;
  CapabilityProvider?: CapabilityProviderResolvers<ContextType>;
  CartCapabilities?: CartCapabilitiesResolvers<ContextType>;
  CartRuleOutcome?: CartRuleOutcomeResolvers<ContextType>;
  CodeRuleOutcome?: CodeRuleOutcomeResolvers<ContextType>;
  CodesGenerationSearchHit?: CodesGenerationSearchHitResolvers<ContextType>;
  CodesGenerationSearchHits?: CodesGenerationSearchHitsResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  CompanyAttribute?: CompanyAttributeResolvers<ContextType>;
  CompensationBonus?: CompensationBonusResolvers<ContextType>;
  CompensationFee?: CompensationFeeResolvers<ContextType>;
  CompensationGiftCard?: CompensationGiftCardResolvers<ContextType>;
  CompensationOrderLine?: CompensationOrderLineResolvers<ContextType>;
  CompensationPayment?: CompensationPaymentResolvers<ContextType>;
  CompensationShippingFee?: CompensationShippingFeeResolvers<ContextType>;
  Condition?: ConditionResolvers<ContextType>;
  CreateGenerateCodesResult?: CreateGenerateCodesResultResolvers<ContextType>;
  CurrencyRate?: CurrencyRateResolvers<ContextType>;
  CurrencyRates?: CurrencyRatesResolvers<ContextType>;
  CustomReasonCode?: CustomReasonCodeResolvers<ContextType>;
  CustomReasonCodes?: CustomReasonCodesResolvers<ContextType>;
  CustomState?: CustomStateResolvers<ContextType>;
  CustomStates?: CustomStatesResolvers<ContextType>;
  DeletedResult?: DeletedResultResolvers<ContextType>;
  DeliveryBonus?: DeliveryBonusResolvers<ContextType>;
  DeliveryCapture?: DeliveryCaptureResolvers<ContextType>;
  DeliveryGift?: DeliveryGiftResolvers<ContextType>;
  DeliveryGiftCard?: DeliveryGiftCardResolvers<ContextType>;
  DeliveryGiftCardProduct?: DeliveryGiftCardProductResolvers<ContextType>;
  DeliveryOrderLine?: DeliveryOrderLineResolvers<ContextType>;
  DeliveryShippingFee?: DeliveryShippingFeeResolvers<ContextType>;
  DeliveryTracking?: DeliveryTrackingResolvers<ContextType>;
  DeliveryVoucher?: DeliveryVoucherResolvers<ContextType>;
  Dimensions?: DimensionsResolvers<ContextType>;
  DiscountCode?: DiscountCodeResolvers<ContextType>;
  DiscountCodeRule?: DiscountCodeRuleResolvers<ContextType>;
  DiscountCodeRuleSearchHit?: DiscountCodeRuleSearchHitResolvers<ContextType>;
  DiscountCodeRuleSearchHits?: DiscountCodeRuleSearchHitsResolvers<ContextType>;
  DiscountCodeSearchHit?: DiscountCodeSearchHitResolvers<ContextType>;
  DiscountCodeSearchHits?: DiscountCodeSearchHitsResolvers<ContextType>;
  DiscountExternalRule?: DiscountExternalRuleResolvers<ContextType>;
  DiscountExternalRuleSearchHit?: DiscountExternalRuleSearchHitResolvers<ContextType>;
  DiscountExternalRuleSearchHits?: DiscountExternalRuleSearchHitsResolvers<ContextType>;
  DiscountManagementQuotas?: DiscountManagementQuotasResolvers<ContextType>;
  DiscountRule?: DiscountRuleResolvers<ContextType>;
  DiscountRuleSearchHit?: DiscountRuleSearchHitResolvers<ContextType>;
  DiscountRuleSearchHits?: DiscountRuleSearchHitsResolvers<ContextType>;
  DiscountValue?: DiscountValueResolvers<ContextType>;
  ExternalApi?: ExternalApiResolvers<ContextType>;
  ExternalEventDestinationAPI?: ExternalEventDestinationApiResolvers<ContextType>;
  ExternalEventDestinationAPIFilter?: ExternalEventDestinationApiFilterResolvers<ContextType>;
  ExternalEventDestinationAPIFilterPattern?: ExternalEventDestinationApiFilterPatternResolvers<ContextType>;
  ExternalEventDestinationAPIs?: ExternalEventDestinationApIsResolvers<ContextType>;
  ExternalEventDestinationEventBuses?: ExternalEventDestinationEventBusesResolvers<ContextType>;
  ExternalEventDestinationsDetails?: ExternalEventDestinationsDetailsResolvers<ContextType>;
  ExternalEventRuleState?: ExternalEventRuleStateResolvers<ContextType>;
  ExternalEventRules?: ExternalEventRulesResolvers<ContextType>;
  ExternalRuleOutcome?: ExternalRuleOutcomeResolvers<ContextType>;
  GenerateCodes?: GenerateCodesResolvers<ContextType>;
  Gift?: GiftResolvers<ContextType>;
  GiftCardProductProvider?: GiftCardProductProviderResolvers<ContextType>;
  GiftCardProvider?: GiftCardProviderResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupDetails?: GroupDetailsResolvers<ContextType>;
  GroupsList?: GroupsListResolvers<ContextType>;
  IngridConfig?: IngridConfigResolvers<ContextType>;
  IngridCredentials?: IngridCredentialsResolvers<ContextType>;
  IngridDelivery?: IngridDeliveryResolvers<ContextType>;
  IngridDeliveryAddress?: IngridDeliveryAddressResolvers<ContextType>;
  Inventory?: InventoryResolvers<ContextType>;
  InventoryAddress?: InventoryAddressResolvers<ContextType>;
  InventoryProductVariantStock?: InventoryProductVariantStockResolvers<ContextType>;
  InventoryProductVariantStockConfig?: InventoryProductVariantStockConfigResolvers<ContextType>;
  InventoryStoreMarket?: InventoryStoreMarketResolvers<ContextType>;
  InventoryStoreMarketInventories?: InventoryStoreMarketInventoriesResolvers<ContextType>;
  ItemRule?: ItemRuleResolvers<ContextType>;
  KBSGiftCardConfig?: KbsGiftCardConfigResolvers<ContextType>;
  KBSGiftCardCredentials?: KbsGiftCardCredentialsResolvers<ContextType>;
  KeyValue?: KeyValueResolvers<ContextType>;
  KlarnaCheckoutConfig?: KlarnaCheckoutConfigResolvers<ContextType>;
  KlarnaCredentials?: KlarnaCredentialsResolvers<ContextType>;
  KlarnaShippingAssistantAddon?: KlarnaShippingAssistantAddonResolvers<ContextType>;
  KlarnaShippingAssistantAddress?: KlarnaShippingAssistantAddressResolvers<ContextType>;
  KlarnaShippingAssistantCarrierProduct?: KlarnaShippingAssistantCarrierProductResolvers<ContextType>;
  KlarnaShippingAssistantLocation?: KlarnaShippingAssistantLocationResolvers<ContextType>;
  KlarnaShippingAssistantLocationTimeslot?: KlarnaShippingAssistantLocationTimeslotResolvers<ContextType>;
  KlarnaShippingAssistantSelectedShippingOption?: KlarnaShippingAssistantSelectedShippingOptionResolvers<ContextType>;
  LogicHolder?: LogicHolderResolvers<ContextType>;
  MarketPrice?: MarketPriceResolvers<ContextType>;
  MarketRoundingRule?: MarketRoundingRuleResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationOrderManagement?: MutationOrderManagementResolvers<ContextType>;
  NShiftConfig?: NShiftConfigResolvers<ContextType>;
  NShiftCredentials?: NShiftCredentialsResolvers<ContextType>;
  NshiftAddress?: NshiftAddressResolvers<ContextType>;
  NshiftPreparedShipment?: NshiftPreparedShipmentResolvers<ContextType>;
  NshiftService?: NshiftServiceResolvers<ContextType>;
  OperationResult?: OperationResultResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderAddress?: OrderAddressResolvers<ContextType>;
  OrderAttributes?: OrderAttributesResolvers<ContextType>;
  OrderBonus?: OrderBonusResolvers<ContextType>;
  OrderBundle?: OrderBundleResolvers<ContextType>;
  OrderCancellation?: OrderCancellationResolvers<ContextType>;
  OrderComment?: OrderCommentResolvers<ContextType>;
  OrderCompensation?: OrderCompensationResolvers<ContextType>;
  OrderCompensationTotals?: OrderCompensationTotalsResolvers<ContextType>;
  OrderCustomState?: OrderCustomStateResolvers<ContextType>;
  OrderDelivery?: OrderDeliveryResolvers<ContextType>;
  OrderDeliveryTotals?: OrderDeliveryTotalsResolvers<ContextType>;
  OrderDiscountCodeRule?: OrderDiscountCodeRuleResolvers<ContextType>;
  OrderDiscountExternalRule?: OrderDiscountExternalRuleResolvers<ContextType>;
  OrderDiscountOutcome?: OrderDiscountOutcomeResolvers<ContextType>;
  OrderDiscountRule?: OrderDiscountRuleResolvers<ContextType>;
  OrderGift?: OrderGiftResolvers<ContextType>;
  OrderGiftCard?: OrderGiftCardResolvers<ContextType>;
  OrderGiftCardBSGGiftCard?: OrderGiftCardBsgGiftCardResolvers<ContextType>;
  OrderGiftCardEmpty?: OrderGiftCardEmptyResolvers<ContextType>;
  OrderGiftCardItemBSGGiftCard?: OrderGiftCardItemBsgGiftCardResolvers<ContextType>;
  OrderGiftCardItemKBSGiftCard?: OrderGiftCardItemKbsGiftCardResolvers<ContextType>;
  OrderGiftCardItemRetain24?: OrderGiftCardItemRetain24Resolvers<ContextType>;
  OrderGiftCardKBSGiftCard?: OrderGiftCardKbsGiftCardResolvers<ContextType>;
  OrderGiftCardProduct?: OrderGiftCardProductResolvers<ContextType>;
  OrderGiftCardProductEmpty?: OrderGiftCardProductEmptyResolvers<ContextType>;
  OrderGiftCardProductItem?: OrderGiftCardProductItemResolvers<ContextType>;
  OrderGiftCardProductItemRetain24?: OrderGiftCardProductItemRetain24Resolvers<ContextType>;
  OrderGiftCardProductRetain24?: OrderGiftCardProductRetain24Resolvers<ContextType>;
  OrderGiftCardRetain24?: OrderGiftCardRetain24Resolvers<ContextType>;
  OrderHistory?: OrderHistoryResolvers<ContextType>;
  OrderHistoryLogEntry?: OrderHistoryLogEntryResolvers<ContextType>;
  OrderHistoryOperations?: OrderHistoryOperationsResolvers<ContextType>;
  OrderHistoryTransactionBonus?: OrderHistoryTransactionBonusResolvers<ContextType>;
  OrderHistoryTransactionGift?: OrderHistoryTransactionGiftResolvers<ContextType>;
  OrderHistoryTransactionGiftCard?: OrderHistoryTransactionGiftCardResolvers<ContextType>;
  OrderHistoryTransactionGiftCardProduct?: OrderHistoryTransactionGiftCardProductResolvers<ContextType>;
  OrderHistoryTransactionOrderLine?: OrderHistoryTransactionOrderLineResolvers<ContextType>;
  OrderHistoryTransactionPayment?: OrderHistoryTransactionPaymentResolvers<ContextType>;
  OrderHistoryTransactionShippingFee?: OrderHistoryTransactionShippingFeeResolvers<ContextType>;
  OrderHistoryTransactionVoucher?: OrderHistoryTransactionVoucherResolvers<ContextType>;
  OrderHistoryTransactions?: OrderHistoryTransactionsResolvers<ContextType>;
  OrderHistoryTransactionsCompensated?: OrderHistoryTransactionsCompensatedResolvers<ContextType>;
  OrderLine?: OrderLineResolvers<ContextType>;
  OrderLineDiscountOutcome?: OrderLineDiscountOutcomeResolvers<ContextType>;
  OrderLineModification?: OrderLineModificationResolvers<ContextType>;
  OrderLineModificationCreateData?: OrderLineModificationCreateDataResolvers<ContextType>;
  OrderLineModificationData?: OrderLineModificationDataResolvers<ContextType>;
  OrderLineModificationDeleteData?: OrderLineModificationDeleteDataResolvers<ContextType>;
  OrderLineModificationUpdateData?: OrderLineModificationUpdateDataResolvers<ContextType>;
  OrderLineModificationUpdateDataPrice?: OrderLineModificationUpdateDataPriceResolvers<ContextType>;
  OrderLineOption?: OrderLineOptionResolvers<ContextType>;
  OrderModification?: OrderModificationResolvers<ContextType>;
  OrderModificationOrderLines?: OrderModificationOrderLinesResolvers<ContextType>;
  OrderOperationResult?: OrderOperationResultResolvers<ContextType>;
  OrderPayment?: OrderPaymentResolvers<ContextType>;
  OrderPaymentAdyen?: OrderPaymentAdyenResolvers<ContextType>;
  OrderPaymentAvarda?: OrderPaymentAvardaResolvers<ContextType>;
  OrderPaymentBrinkZeroPayment?: OrderPaymentBrinkZeroPaymentResolvers<ContextType>;
  OrderPaymentBrinkZeroPaymentOriginalProvider?: OrderPaymentBrinkZeroPaymentOriginalProviderResolvers<ContextType>;
  OrderPaymentKlarnaCheckout?: OrderPaymentKlarnaCheckoutResolvers<ContextType>;
  OrderPaymentQliroOne?: OrderPaymentQliroOneResolvers<ContextType>;
  OrderPaymentQliroOneOrderItem?: OrderPaymentQliroOneOrderItemResolvers<ContextType>;
  OrderPaymentQliroOneUpsell?: OrderPaymentQliroOneUpsellResolvers<ContextType>;
  OrderPaymentQliroOneUpsellItems?: OrderPaymentQliroOneUpsellItemsResolvers<ContextType>;
  OrderPaymentQliroOneUpsellTotals?: OrderPaymentQliroOneUpsellTotalsResolvers<ContextType>;
  OrderPaymentSveaCheckout?: OrderPaymentSveaCheckoutResolvers<ContextType>;
  OrderPaymentWalleyCheckout?: OrderPaymentWalleyCheckoutResolvers<ContextType>;
  OrderPromotion?: OrderPromotionResolvers<ContextType>;
  OrderPromotionEmpty?: OrderPromotionEmptyResolvers<ContextType>;
  OrderPromotionItemVoyado?: OrderPromotionItemVoyadoResolvers<ContextType>;
  OrderPromotionVoyado?: OrderPromotionVoyadoResolvers<ContextType>;
  OrderRefund?: OrderRefundResolvers<ContextType>;
  OrderRefundTotals?: OrderRefundTotalsResolvers<ContextType>;
  OrderRelease?: OrderReleaseResolvers<ContextType>;
  OrderReservation?: OrderReservationResolvers<ContextType>;
  OrderSearchHit?: OrderSearchHitResolvers<ContextType>;
  OrderSearchHits?: OrderSearchHitsResolvers<ContextType>;
  OrderShipping?: OrderShippingResolvers<ContextType>;
  OrderShippingAvardaShippingBroker?: OrderShippingAvardaShippingBrokerResolvers<ContextType>;
  OrderShippingEmpty?: OrderShippingEmptyResolvers<ContextType>;
  OrderShippingIngrid?: OrderShippingIngridResolvers<ContextType>;
  OrderShippingKlarnaShippingAssistant?: OrderShippingKlarnaShippingAssistantResolvers<ContextType>;
  OrderShippingNshift?: OrderShippingNshiftResolvers<ContextType>;
  OrderShippingQliroIntegratedShipping?: OrderShippingQliroIntegratedShippingResolvers<ContextType>;
  OrderShippingWalleyDeliveryModule?: OrderShippingWalleyDeliveryModuleResolvers<ContextType>;
  OrderStatus?: OrderStatusResolvers<ContextType>;
  OrderTotals?: OrderTotalsResolvers<ContextType>;
  OrderVoucher?: OrderVoucherResolvers<ContextType>;
  OrderVoucherEmpty?: OrderVoucherEmptyResolvers<ContextType>;
  OrderVoucherItemVoyado?: OrderVoucherItemVoyadoResolvers<ContextType>;
  OrderVoucherVoyado?: OrderVoucherVoyadoResolvers<ContextType>;
  Outcome?: OutcomeResolvers<ContextType>;
  PaymentProvider?: PaymentProviderResolvers<ContextType>;
  PlatformConfig?: PlatformConfigResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  PriceRecord?: PriceRecordResolvers<ContextType>;
  PriceRule?: PriceRuleResolvers<ContextType>;
  ProductParent?: ProductParentResolvers<ContextType>;
  ProductParentSearchHit?: ProductParentSearchHitResolvers<ContextType>;
  ProductParentSearchHits?: ProductParentSearchHitsResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  ProductVariantReservation?: ProductVariantReservationResolvers<ContextType>;
  ProductVariantRules?: ProductVariantRulesResolvers<ContextType>;
  ProductVariantSearchHit?: ProductVariantSearchHitResolvers<ContextType>;
  ProductVariantSearchHits?: ProductVariantSearchHitsResolvers<ContextType>;
  PromotionProvider?: PromotionProviderResolvers<ContextType>;
  Provider?: ProviderResolvers<ContextType>;
  ProviderStatusLog?: ProviderStatusLogResolvers<ContextType>;
  ProviderStatusLogEntry?: ProviderStatusLogEntryResolvers<ContextType>;
  QliroIngrid?: QliroIngridResolvers<ContextType>;
  QliroIngridDelivery?: QliroIngridDeliveryResolvers<ContextType>;
  QliroIngridDeliveryAddress?: QliroIngridDeliveryAddressResolvers<ContextType>;
  QliroOneConfig?: QliroOneConfigResolvers<ContextType>;
  QliroOneCredentials?: QliroOneCredentialsResolvers<ContextType>;
  QliroOneIntegratedShipping?: QliroOneIntegratedShippingResolvers<ContextType>;
  QliroShipmentOrderItem?: QliroShipmentOrderItemResolvers<ContextType>;
  QliroShipmentOrderItemMetadata?: QliroShipmentOrderItemMetadataResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QueryConfigurationManagement?: QueryConfigurationManagementResolvers<ContextType>;
  QueryConfigurationManagementValidate?: QueryConfigurationManagementValidateResolvers<ContextType>;
  QueryOrderManagement?: QueryOrderManagementResolvers<ContextType>;
  QueryPlatformConfig?: QueryPlatformConfigResolvers<ContextType>;
  QueryProductManagement?: QueryProductManagementResolvers<ContextType>;
  Reason?: ReasonResolvers<ContextType>;
  RefundBonus?: RefundBonusResolvers<ContextType>;
  RefundFee?: RefundFeeResolvers<ContextType>;
  RefundGift?: RefundGiftResolvers<ContextType>;
  RefundGiftCard?: RefundGiftCardResolvers<ContextType>;
  RefundOrderLine?: RefundOrderLineResolvers<ContextType>;
  RefundShippingFee?: RefundShippingFeeResolvers<ContextType>;
  RefundVoucher?: RefundVoucherResolvers<ContextType>;
  RefundedPayment?: RefundedPaymentResolvers<ContextType>;
  ReleaseBonus?: ReleaseBonusResolvers<ContextType>;
  ReleaseGift?: ReleaseGiftResolvers<ContextType>;
  ReleaseGiftCard?: ReleaseGiftCardResolvers<ContextType>;
  ReleaseGiftCardProduct?: ReleaseGiftCardProductResolvers<ContextType>;
  ReleaseOrderLine?: ReleaseOrderLineResolvers<ContextType>;
  ReleaseShippingFee?: ReleaseShippingFeeResolvers<ContextType>;
  ReleasedPayment?: ReleasedPaymentResolvers<ContextType>;
  Retain24Config?: Retain24ConfigResolvers<ContextType>;
  Retain24Credentials?: Retain24CredentialsResolvers<ContextType>;
  RoundingRule?: RoundingRuleResolvers<ContextType>;
  RoundingRules?: RoundingRulesResolvers<ContextType>;
  Rule?: RuleResolvers<ContextType>;
  Schema?: SchemaResolvers<ContextType>;
  ShippingFee?: ShippingFeeResolvers<ContextType>;
  ShippingProvider?: ShippingProviderResolvers<ContextType>;
  ShippingTaxGroup?: ShippingTaxGroupResolvers<ContextType>;
  Stock?: StockResolvers<ContextType>;
  StockInventory?: StockInventoryResolvers<ContextType>;
  StoreGroup?: StoreGroupResolvers<ContextType>;
  StoreGroupPrice?: StoreGroupPriceResolvers<ContextType>;
  StoreGroupSearchHit?: StoreGroupSearchHitResolvers<ContextType>;
  StoreGroupSearchHits?: StoreGroupSearchHitsResolvers<ContextType>;
  StoreMarket?: StoreMarketResolvers<ContextType>;
  StoreMarketSearchHit?: StoreMarketSearchHitResolvers<ContextType>;
  StoreMarketSearchHits?: StoreMarketSearchHitsResolvers<ContextType>;
  SubOutcome?: SubOutcomeResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SveaCheckoutConfig?: SveaCheckoutConfigResolvers<ContextType>;
  SveaCredentials?: SveaCredentialsResolvers<ContextType>;
  TagCondition?: TagConditionResolvers<ContextType>;
  TagKeys?: TagKeysResolvers<ContextType>;
  TagValues?: TagValuesResolvers<ContextType>;
  TaxGroup?: TaxGroupResolvers<ContextType>;
  TaxGroupSearchHit?: TaxGroupSearchHitResolvers<ContextType>;
  TaxGroupSearchHits?: TaxGroupSearchHitsResolvers<ContextType>;
  TaxMarket?: TaxMarketResolvers<ContextType>;
  TaxMarketSearchHit?: TaxMarketSearchHitResolvers<ContextType>;
  TaxMarketSearchHits?: TaxMarketSearchHitsResolvers<ContextType>;
  UpdateExternalEventsDestinationAPIResult?: UpdateExternalEventsDestinationApiResultResolvers<ContextType>;
  UsagePlan?: UsagePlanResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserDetails?: UserDetailsResolvers<ContextType>;
  UsersList?: UsersListResolvers<ContextType>;
  ValidDateRange?: ValidDateRangeResolvers<ContextType>;
  ValidateConfigResult?: ValidateConfigResultResolvers<ContextType>;
  VoucherProvider?: VoucherProviderResolvers<ContextType>;
  VoyadoConfig?: VoyadoConfigResolvers<ContextType>;
  VoyadoCredentials?: VoyadoCredentialsResolvers<ContextType>;
  WalleyCheckoutConfig?: WalleyCheckoutConfigResolvers<ContextType>;
  WalleyCredentials?: WalleyCredentialsResolvers<ContextType>;
  WalleyDeliveryModuleDeliveryDate?: WalleyDeliveryModuleDeliveryDateResolvers<ContextType>;
  WalleyDeliveryModuleDestination?: WalleyDeliveryModuleDestinationResolvers<ContextType>;
  WalleyDeliveryModuleOption?: WalleyDeliveryModuleOptionResolvers<ContextType>;
  WalleyDeliveryModuleShipment?: WalleyDeliveryModuleShipmentResolvers<ContextType>;
  WalleyDeliveryModuleShipping?: WalleyDeliveryModuleShippingResolvers<ContextType>;
  WalleyDeliveryModuleShippingChoice?: WalleyDeliveryModuleShippingChoiceResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_lambda?: Aws_LambdaDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_publish?: Aws_PublishDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
  defer?: DeferDirectiveResolver<any, any, ContextType>;
};
