import React, { useState } from "react"

import DineroFactory from "dinero.js"

import {
  ActionWrapper,
  CartItemContainer,
  InfoWrapper,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice,
  QtySelector
} from "./CartItem.styled"
import Money from "../../../../Money"
import { DeliveryGift, OrderDelivery, OrderGift } from "lib/types/generated/graphql-types"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { ButtonWrapper } from "../Shared.styled"
import { useMutation } from "@apollo/client"
import { Loader } from "../../../../Ui/Loader"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { handleErrorMessages } from "helpers/errors"
import { handleImageError } from "helpers/image"

type Props = {
  item: DeliveryGift
  currencyCode?: string
  orderGift: OrderGift | undefined
  delivery: OrderDelivery
  refetch: () => void
}

const GiftItem = ({ item, currencyCode, orderGift, delivery, refetch, ...props }: Props) => {
  const [quantity, setQuantity] = useState(item.quantity)

  const [updateDelivery, { loading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const onUpdate = (qty: number) => {
    if (qty === 0) {
      return updateDelivery({
        variables: {
          deliveryId: delivery.id,
          input: {
            ...(delivery.shippingFees && {
              shippingFees: delivery.shippingFees.map((fee) => fee.shippingFeeId)
            }),
            ...(delivery.orderLines && {
              orderLines: delivery.orderLines.map((orderLine) => ({
                orderLineId: orderLine.orderLineId,
                quantity: orderLine.quantity
              }))
            }),
            gifts: delivery.gifts
              .filter((gift) => gift.giftId !== item.giftId)
              .map((gift) => ({ giftId: gift.giftId, quantity: gift.quantity }))
          }
        }
      })
    }
    return updateDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...(delivery.shippingFees && {
            shippingFees: delivery.shippingFees.map((fee) => fee.shippingFeeId)
          }),
          ...(delivery.orderLines && {
            orderLines: delivery.orderLines.map((orderLine) => ({
              orderLineId: orderLine.orderLineId,
              quantity: orderLine.quantity
            }))
          }),
          gifts: [
            ...delivery.gifts.map((gift) => ({
              giftId: gift.giftId,
              quantity: gift.giftId === item.giftId ? qty : gift.quantity
            }))
          ]
        }
      }
    })
  }

  const updateQuantity = (mod: number) => {
    const qty = Math.max(quantity + mod, 0)
    setQuantity(qty)
    onUpdate(qty)
  }

  return (
    <CartItemContainer {...props}>
      <InfoWrapper>
        <ItemImage>
          <img src={orderGift?.imageUrl ?? ""} onError={handleImageError} />
        </ItemImage>
        <ItemDetails>
          <ItemName>{orderGift?.name}</ItemName>
          <ItemPrice>
            <Money
              amount={Number(orderGift?.salePriceAmount)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
            {orderGift && orderGift?.salePriceAmount < orderGift?.basePriceAmount && (
              <span>
                <Money
                  amount={Number(orderGift?.totalPriceAmount ?? 0)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </span>
            )}
          </ItemPrice>
        </ItemDetails>
      </InfoWrapper>

      <ActionWrapper>
        <span>
          <b>Action:</b>{" "}
          {orderGift?.quantity === item.quantity
            ? "Will be delivered"
            : "Will be delivered in part"}
        </span>
        <span>
          <b>Items to deliver:</b>
          <QtySelector>
            {loading && <Loader color="black" />}
            <span onClick={() => updateQuantity(-1)}>-</span>
            <span>{item.quantity}</span>
            {
              <span
                onClick={() => {
                  if (orderGift?.quantity !== quantity) updateQuantity(+1)
                }}
              >
                +
              </span>
            }
          </QtySelector>
        </span>
        <span>
          <b>Amount to be delivered:</b>{" "}
          <Money
            amount={orderGift?.totalPriceAmount ?? 0}
            currencyUnit={currencyCode as DineroFactory.Currency}
          />
        </span>
        <hr />
        <ButtonWrapper>
          <SecondaryButton handleClick={() => onUpdate(0)}>Undo</SecondaryButton>
        </ButtonWrapper>
      </ActionWrapper>
    </CartItemContainer>
  )
}

export default GiftItem
