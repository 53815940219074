import React, { Dispatch, SetStateAction, useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import {
  hideEditSidebar,
  showEditSidebar
} from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { Discount } from "@lib/types"
import { DiscountCodeSearchHit } from "lib/types/generated/graphql-types"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_OR_UPDATE_DISCOUNT_CODE from "graphql/mutations/discount/CreateOrUpdateDiscountCode"
import DELETE_DISCOUNT_CODE from "graphql/mutations/discount/DeleteDiscountCode"
import GET_DISCOUNT_CODE from "graphql/queries/discount/GetDiscountCode"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import Input from "components/Ui/Form/Input"
import TimePeriodSelection from "components/Discount/TimePeriodSelection"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`

const FieldTitle = styled.span`
  font-weight: bold;
`

const DiscountRuleId = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

type Props = {
  discountCode: string
  setEditId: Dispatch<SetStateAction<string>>
  setDiscountCodes: Dispatch<SetStateAction<DiscountCodeSearchHit[]>>
}

const UpdateDiscountCode = ({
  discountCode,
  setEditId,
  setDiscountCodes
}: Props) => {
  const { data, loading, error } = useQuery(GET_DISCOUNT_CODE, {
    variables: { code: discountCode }
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Discount.CodeInputs>()

  useEffect(() => {
    if (discountCode) {
      dispatch(showEditSidebar())
    }
  }, [discountCode])

  const [updateDiscountCode, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_DISCOUNT_CODE,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Discount code successfully updated",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  if (loading) return <EditSidebar loading={true} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading discount code. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const { code, discountCodeRuleId, usageLimit, validDateRange } =
    data.getDiscountCode

  const onSubmit: SubmitHandler<Discount.CodeInputs> = (data) => {
    const { from, to, usageLimit } = data
    const usageLimitExists =
      usageLimit?.toString() !== "" && usageLimit !== undefined
      updateDiscountCode({
        variables: {
          code,
          discountCodeRuleId,
          isUnlimited: !usageLimitExists,
          ...(usageLimitExists && { usageLimit: usageLimit }),
          ...(from &&
            to && {
              validDateRange: {
                from: new Date(from),
                to: new Date(to)
              }
            })
        }
      })
  }

  const updateDiscountCodeList = () => {
    setDiscountCodes((prev) => prev.filter((current) => current.code !== code))
  }

  return (
    <EditSidebar setEditId={setEditId}>
      <form>
        <EditSidebarHeader
          title="Discount code"
          id={code}
          setEditId={setEditId}
        >
          <DeleteButton
            id={code}
            name={code}
            entity={"Discount Code"}
            mutation={DELETE_DISCOUNT_CODE}
            identifier="code"
            postDeleteFunction={updateDiscountCodeList}
          />
          <PrimaryButton
            type="submit"
            handleClick={handleSubmit(onSubmit)}
            loading={createLoading}
          >
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Row>
          <FieldTitle>Discount rule id:</FieldTitle>
          <DiscountRuleId
            onClick={() => {
              dispatch(hideEditSidebar())
              navigate(`/discounts/discount-code-rules/${discountCodeRuleId}`)
            }}
          >
            {discountCodeRuleId}
          </DiscountRuleId>
        </Row>
        <br />
        <Controller
          name="usageLimit"
          render={({ field }) => (
            <Input
              type="number"
              placeholder="Unlimited"
              label="UsageLimit"
              errors={errors}
              defaultValue={usageLimit === 0 ? "" : usageLimit}
              {...field}
            />
          )}
          control={control}
        />
        <br />
        <TimePeriodSelection
          control={control}
          defaultValues={validDateRange ?? undefined}
          inSideBar={true}
          errors={errors}
        />
      </form>
      <hr />
    </EditSidebar>
  )
}

export default UpdateDiscountCode
