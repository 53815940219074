import { getCountryName } from "helpers/countries"
import React, { useState, useEffect } from "react"
import styled from "styled-components"

export const Img = styled.img`
  width: 3rem;
`

type Props = {
  countryCode: string
}

const FlagIcon = ({ countryCode }: Props) => {
  const [icon, setIcon] = useState("")
  const countryName = getCountryName(countryCode)

  useEffect(() => {
    async function fetchFlag() {
      if (countryCode === "") return null
      await import(
        `images/icons/flags/svg/${countryCode.toLowerCase()}.svg`
      ).then((icon) => setIcon(icon.default))
    }
    fetchFlag()
  }, [countryCode])
  if (icon === "") return null
  return <Img alt={countryName} title={countryName} src={icon} />
}

export default FlagIcon
