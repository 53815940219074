import styled from "styled-components"
import TableHeader from "components/Ui/Table/TableHeader"

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

export const Flex = styled.div`
  display: flex;
`

export const Id = styled.p`
  font-size: 1rem;
  margin: 0;
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`
