import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import styled from "styled-components"
import { Common } from "@lib/types"
import { useLazyQuery } from "@apollo/client"
import TAG_VALUES from "graphql/queries/tags/TagValues"
import MultiSelect from "components/Ui/Form/MultiSelect"
import { TagCondition } from "@lib/types/campaign"

const Container = styled.div`
  z-index: 10000;
`

type Props = {
  tagKey: string
  tagValues: Common.Option[] | undefined
  setTagValues: Dispatch<SetStateAction<Common.Option[]>>
  tagConditions: TagCondition[] | []
  defaultValue?: Common.Option[]
}

const ExtendedMultiSelect = ({
  tagKey,
  setTagValues,
  tagConditions,
  tagValues,
  defaultValue,
  ...props
}: Props) => {
  const key = tagKey
  const [options, setOptions] = useState<Common.Option[]>([])
  const [searchInput, setSearchInput] = useState<string>("")

  const [getTags, { loading }] = useLazyQuery(TAG_VALUES, {
    variables: { key, prefix: searchInput },
    onCompleted: (data) => {
      setOptions(
        data?.tagValues?.values.map((value: Common.Option) => ({
          value,
          label: value
        }))
      )
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        getTags({ variables: { key, prefix: searchInput } }).then((response) => {
          const result = response?.data?.tagValues?.values.map((value: string) => ({
            value,
            label: value
          }))
          const tagValues = tagConditions
            .map((tc: TagCondition) =>
              tc.values.map((value: string) => ({
                value: value,
                label: value
              }))
            )
            .flat()
          const filteredArray = result.filter(
            (option: Common.Option) =>
              !tagValues.some(
                (tagValue: Common.Option) => JSON.stringify(tagValue) === JSON.stringify(option)
              )
          )
          setOptions(filteredArray)
        })
      },
      searchInput === "" ? 0 : 300
    )

    return () => clearTimeout(delayDebounceFn)
  }, [searchInput, key, tagConditions])

  return (
    <Container {...props}>
      <MultiSelect
        options={options}
        value={defaultValue ?? tagValues}
        setValue={setTagValues}
        onInputChange={(inputText: string) => setSearchInput(inputText)}
        loading={loading}
      />
    </Container>
  )
}

export default ExtendedMultiSelect
