"use client"

import React, { useState } from "react"
import PrimaryButton from "../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../Ui/Buttons/SecondaryButton"
import { setFilteredByExcludingTax, setFilteredDays, setSelectedStoreGroups, setSelectedStoreMarkets } from "lib/store/services/analytics/slice"
import { useDispatch, useSelector } from "react-redux"
import { selectFilteredByExcludingTax, selectFilteredDays, selectStoreGroups, selectStoreMarkets } from "lib/store/services/analytics/selectors"
import { Layout, PeriodSelection, SelectWrapper } from "./PeriodSelections.styled"
import { IconWrapper } from "../Dashboard/OrderChart.styled"
import MultiSelect from "../Ui/Form/MultiSelect"
import { isReadOnlyUser } from "helpers/user"
import { StoreGroup } from "lib/types/generated/graphql-types"
import { ReactComponent as StoreGroupIcon } from "../../images/icons/circle-nodes.svg"
import { ReactComponent as StoreMarketIcon } from "../../images/icons/earth.svg"
import { getCountryName } from "helpers/countries"
import { Option } from "@lib/types/common"
import { Divider, FilterGroup } from "./TopProductsFilters.styled"

type Range = 90 | 30 | 7 | 1

interface PeriodButtonProps {
  range: Range
  currentRange: Range
  onClick: (range: Range) => void
  disabled: boolean
  label: string
}

const PeriodButton: React.FC<PeriodButtonProps> = (
  {
    range,
    currentRange,
    onClick,
    disabled,
    label
  }) => {
  const isActive = range === currentRange
  if (isActive) {
    return (
      <PrimaryButton handleClick={() => onClick(range)} overrideDisabled={disabled}>
        {label}
      </PrimaryButton>
    )
  }

  return (
    <SecondaryButton handleClick={() => onClick(range)} overrideDisabled={disabled}>
      {label}
    </SecondaryButton>
  )
}

type Props = {
  timeZone: string
  storeGroups: StoreGroup[]
}

export default function PeriodSelections({ storeGroups }: Props) {
  const dispatch = useDispatch()
  const filteredDays = useSelector(selectFilteredDays) as Range
  const [range, setRange] = useState<Range>(filteredDays)
  const selectedStoreGroups = useSelector(selectStoreGroups)
  const selectedStoreMarkets = useSelector(selectStoreMarkets)
  const filteredByExcludingTax = useSelector(selectFilteredByExcludingTax)

  const handleRangeChange = (newRange: Range) => {
    setRange(newRange)
    dispatch(setFilteredDays(newRange))
  }

  const getStoreMarketOptions = () => {
    const storeMarkets = storeGroups
      ?.map((storeGroup: StoreGroup) => storeGroup.storeMarkets)
      .flat()
    const countryCodes = storeMarkets?.map((storeMarket) => storeMarket?.countryCode)
    const uniqueCountryCodes = [...new Set(countryCodes)] as string[]
    return uniqueCountryCodes
      .map(
        (code: string) =>
          ({
            value: code,
            label: getCountryName(code)
          }) as Option
      )
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  const handleClickFilterByExcludingTax = (value: boolean) => {
    dispatch(setFilteredByExcludingTax(value))
  }

  return (
    <SelectWrapper>
      <Layout>
        <IconWrapper>
          <StoreGroupIcon />
        </IconWrapper>
        <MultiSelect
          overrideDisable={isReadOnlyUser()}
          options={storeGroups?.map((storeGroup: StoreGroup) => ({
            value: storeGroup.id,
            label: storeGroup.name
          }))}
          placeholder={"All store groups"}
          value={selectedStoreGroups}
          setValue={(x) => dispatch(setSelectedStoreGroups(x))}
        />
        {getStoreMarketOptions()?.length > 0 && (
          <>
            <IconWrapper>
              <StoreMarketIcon />
            </IconWrapper>
            <MultiSelect
              overrideDisable={isReadOnlyUser()}
              placeholder={"Select store market"}
              options={getStoreMarketOptions()}
              value={selectedStoreMarkets}
              setValue={(x) => dispatch(setSelectedStoreMarkets(x))}
            />
          </>
        )}
      </Layout>
      <Divider></Divider>
      <FilterGroup>
        {filteredByExcludingTax ? (
          <>
            <PrimaryButton
              handleClick={() => handleClickFilterByExcludingTax(true)}
              overrideDisabled={true}
            >
              Excluding Tax
            </PrimaryButton>
            <SecondaryButton
              handleClick={() => handleClickFilterByExcludingTax(false)}
              overrideDisabled={true}
            >
              Including Tax
            </SecondaryButton>
          </>
        ) : (
          <>
            <SecondaryButton
              handleClick={() => handleClickFilterByExcludingTax(true)}
              overrideDisabled={true}
            >
              Excluding Tax
            </SecondaryButton>
            <PrimaryButton
              handleClick={() => handleClickFilterByExcludingTax(false)}
              overrideDisabled={true}
            >
              Including Tax
            </PrimaryButton>
          </>
        )}
      </FilterGroup>
      <Divider></Divider>
      <PeriodSelection>
        <PeriodButton
          range={1}
          currentRange={range}
          onClick={handleRangeChange}
          disabled={true}
          label={"Today"}
        ></PeriodButton>
        <PeriodButton
          range={7}
          currentRange={range}
          onClick={handleRangeChange}
          disabled={true}
          label={"7 days"}
        ></PeriodButton>
        <PeriodButton
          range={30}
          currentRange={range}
          onClick={handleRangeChange}
          disabled={true}
          label={"30 days"}
        ></PeriodButton>
      </PeriodSelection>
    </SelectWrapper>
  )
}
