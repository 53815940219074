import styled from "styled-components"

export const GiftCardProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
  align-items: center;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};

  &:last-of-type {
    border-bottom: none;
  }
`

export const GiftCardDetails = styled.div`
  display: flex;

svg {
  height: 4rem;
  margin-right: 2rem;
}
`

export const ItemName = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  ${(p) => p.theme.bold};
  word-break: break-word;
`

export const ItemPrice = styled.div`
  span {
    text-decoration: line-through;
    color: #666;
    font-weight: 400;
    margin-left: 1rem;
  }
`
