import React from "react"
import dayjs from "dayjs"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { ProductVariant } from "@lib/types/generated/graphql-types"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import Box from "components/Ui/Box"
import Status from "components/Ui/Status"
import DimensionsComponent from "components/Products/Dimensions"
import { ProductVariantDisplayAttributes } from "components/Products/ProductVariantDisplayAttributes"
import { Item, Label, List, Value } from "components/Ui/List/List"
import { ProductVariantCustomAttributes } from "components/Products/ProductVariantCustomAttributes"
import { ProductVariantTags } from "components/Products/ProductVariantTags"
import { ProductVariantStock } from "components/Products/Stock/ProductVariantStock"
import { ProductVariantShippingAttributes } from "../../components/Products/ProductVariantShippingAttributes"
import { handleImageError } from "helpers/image"

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 30rem;
  height: 30rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem;
  margin: 0 auto 2rem;
`

const Image = styled.img`
  width: 100% !important;
  display: block;
  flex-shrink: 0;
  max-height: 100%;
  object-fit: contain;
`

const Parent = styled.strong`
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  position: relative;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};

  p {
    line-height: 2.4rem;
  }
`

const StyledList = styled(List)`
  margin-bottom: 0;
`

const StyledItem = styled(Item)`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`

type Props = {
  variant: ProductVariant
  showParent?: boolean
}

const ShowVariant = ({ variant, showParent }: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return (
    <EditSidebar>
      <Box padding="0" margin="0">
        <EditSidebarHeader
          title={
            <>
              {variant.name} <Status status={variant.isActive ? "Active" : "Inactive"} />
            </>
          }
          id={variant.id}
        >
          {showParent ? (
            <>
              <div>Parent:</div>
              <Parent
                onClick={() => {
                  dispatch(hideEditSidebar())
                  navigate(`/products/${variant.productParentId}`)
                }}
              >
                {variant.productParentId}
              </Parent>
            </>
          ) : (
            <></>
          )}
        </EditSidebarHeader>
        <ImageWrapper>
          <Image src={variant?.imageUrl ?? ""} onError={handleImageError} />
        </ImageWrapper>
        <DimensionsComponent variant={variant} />
        <StyledList>
          <StyledItem>
            <Label>Tax group:</Label>
            <Value>{variant.TaxGroup.name}</Value>
          </StyledItem>
          <StyledItem>
            <Label>Created:</Label>
            <Value>{dayjs(variant.created).format("YYYY-MM-DD")}</Value>
          </StyledItem>
          <StyledItem>
            <Label>Updated:</Label>
            <Value>{dayjs(variant.updated).format("YYYY-MM-DD")}</Value>
          </StyledItem>
        </StyledList>
        <Description>
          <Label>Description:</Label> <p>{variant.description || "No description added"}</p>
        </Description>
        <ProductVariantStock stock={variant.Stock} />
        <ProductVariantCustomAttributes
          customAttributes={JSON.parse(variant.customAttributes)}
          insideEditSidebar={true}
        />
        <ProductVariantDisplayAttributes attributes={variant.displayNames} name={true} />
        <ProductVariantDisplayAttributes attributes={variant.displayDescriptions} name={false} />
        <ProductVariantTags tags={JSON.parse(variant.tags)} insideEditSidebar={true} />
        <ProductVariantShippingAttributes shippingAttributes={variant?.shippingAttributes} />
      </Box>
    </EditSidebar>
  )
}

export default ShowVariant
