import React, { ReactNode } from "react"
import styled from "styled-components"

const Container = styled.div`
  margin: 0 0 2.5rem;

  p {
    margin-top: 1.5rem;
    color: ${(p) => p.theme.colors.greyDarker};
  }

  a {
    color: ${(p) => p.theme.colors.turquoiseDarker};
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.turquoiseDarker};
    padding-bottom: 0.2rem;
    white-space: nowrap;
  }

  svg {
    fill: ${(p) => p.theme.colors.turquoiseDarker};
    height: 1.3rem;
  }
`

type Props = {
  title: string | ReactNode
  description?: string | ReactNode | ReactNode[]
}

const PageSectionHeader = ({ title, description, ...props }: Props) => (
  <Container {...props}>
    <h2>{title}</h2>
    {description && <p>{description}</p>}
  </Container>
)

export default PageSectionHeader
