import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

export const FilterContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

export const BoldText = styled.div`
  padding-bottom: 0.5rem;
  font-weight: 700;
`

export const CampaignWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`

export const Icon = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 2rem;
`
