import React, { useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import GET_ADYEN_WEBHOOKS from "graphql/queries/config/GetAdyenWebhooks"
import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import CreateAdyenConfig from "./CreateAdyenConfig"
import { AdyenConfigTable } from "components/Config/Adyen/AdyenConfigTable"
import EditAdyenConfig from "./EditAdyenConfig"
import { AdyenWebhookTable } from "components/Config/Adyen/AdyenWebhookTable"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as AdyenLogo } from "images/providers/adyen-vector-logo.svg"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import { NoticeBanner } from "components/Ui/Messages/NoticeBanner"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageAdyenLogo = styled(AdyenLogo)`
  height: 4rem;
`

const WebhookInfo = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1.5rem 1rem 1.5rem 0;
  display: flex;
  justify-content: space-between;
  ${(p) => p.theme.bold};
  margin: 1rem 0 2rem;

  svg {
    width: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }
`

export const AdyenConfiguration = () => {
  const [adyenConfigId, setAdyenConfigId] = useState<string>("")
  const { loading, data, refetch, error } = useQuery(ALL_PAYMENT_PROVIDERS)
  const { loading: webhookLoading, data: webhookData } = useQuery(GET_ADYEN_WEBHOOKS)
  const paymentProviders = data?.getPaymentProviders
  const webhooks = webhookData?.getAdyenWebhooks ?? []
  const [webhookExpanded, setWebhookExpanded] = useState(false)

  const dispatch = useAppDispatch()

  return (
    <Container>
      <PageHeader
        title={<PageAdyenLogo />}
        description={<>Handle your Adyen configurations</>}
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!adyenConfigId && <CreateAdyenConfig refetch={refetch} />}
      {adyenConfigId && (
        <EditAdyenConfig
          refetch={refetch}
          adyenConfigId={adyenConfigId}
          setAdyenConfigId={setAdyenConfigId}
        />
      )}
      <h1>Configurations</h1>
      <AdyenConfigTable
        providers={paymentProviders}
        error={error}
        adyenConfigId={adyenConfigId}
        setAdyenConfigId={setAdyenConfigId}
        loading={loading}
      />
      {webhooks.length > 0 && (
        <>
          <br />
          <h1>Webhooks</h1>
          <NoticeBanner>
            Manually creating webhooks is no longer a requirement. Brink will generate new webhooks
            as necessary when creating Adyen configurations.
          </NoticeBanner>
          <WebhookInfo onClick={() => setWebhookExpanded(!webhookExpanded)}>
            {webhookExpanded ? "Hide" : "Show"} manually configured webhooks
            {webhookExpanded ? <ChevronUp /> : <ChevronDown />}
          </WebhookInfo>
          {webhookExpanded && (
            <AdyenWebhookTable webhooks={webhooks} loading={webhookLoading} error={error} />
          )}
        </>
      )}
    </Container>
  )
}
