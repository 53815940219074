import React from "react"
import { Label, NumberOfHits, Show, Wrapper } from "./TopProductsFilters.styled"
import { NUMBER_OF_HITS_OPTIONS } from "./AnalyticsData"
import { useDispatch, useSelector } from "react-redux"
import { setTopCodesFilteredByHits } from "lib/store/services/analytics/slice"
import { SelectWrapper } from "./PeriodSelections.styled"
import { selectTopCodesFilteredByHits } from "lib/store/services/analytics/selectors"

const TopDiscountCodesFilters = () => {
  const dispatch = useDispatch()
  const topCodesFilteredByHits = useSelector(selectTopCodesFilteredByHits)

  const handleClickFilterByHits = (value: string) => {
    dispatch(setTopCodesFilteredByHits(parseInt(value)))
  }

  return (
    <Wrapper>
      <Label>Top Discount Codes</Label>
      <SelectWrapper>
        {NUMBER_OF_HITS_OPTIONS && topCodesFilteredByHits && (
          <Show>
            <p>Show:</p>
            {NUMBER_OF_HITS_OPTIONS.map((hits) => (
              <NumberOfHits
                key={hits}
                onClick={() => handleClickFilterByHits(hits)}
                $active={hits === topCodesFilteredByHits.toString()}
              >
                {hits}
              </NumberOfHits>
            ))}
          </Show>
        )}
      </SelectWrapper>
    </Wrapper>
  )
}
export default TopDiscountCodesFilters
