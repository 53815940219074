import styled from "styled-components"
import TableRow from "../../Ui/Table/TableRow"

type StyledProps = {
  $expanded: boolean
}


export const StyledTableRow = styled(TableRow)<StyledProps>`
background: ${({ $expanded, theme }) => $expanded ? theme.colors.greyLightest : theme.colors.white};
  user-select: none;
`

export const InfoWrapper = styled.div`
display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
`

export const Name = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    ${(p) => p.theme.bold};
    margin: 0;
  }

  span {
    margin-left: 1rem;
    font-size: 1.1rem;
    color: ${(p) => p.theme.colors.greyDarker};
  }
`

export const IconWrapper = styled.span`
  width: 4.4rem;
  display: flex;
svg {
  width: 1.9rem;
}
`
export const Actions = styled.div`
  max-width: 7rem;
  display: flex;
  justify-content: center;

  button {
    margin-left: 0;
  }
`

