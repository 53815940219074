import React from "react"
import { getLogicTypeDisplayName } from "helpers/getLogicTypeDisplayName"

import { LogicSelect } from "./LogicSelect"
import { InputConditionType } from "lib/types/generated/graphql-types"

const options = [
  {
    value: InputConditionType.Cartoverthreshold,
    label: getLogicTypeDisplayName(InputConditionType.Cartoverthreshold),
    description: "If the total value of items in the cart is X or higher."
  },
  {
    value: InputConditionType.Cartunderthreshold,
    label: getLogicTypeDisplayName(InputConditionType.Cartunderthreshold),
    description: "If the total value of items in the cart is X or lower.    "
  },
  {
    value: InputConditionType.Minitems,
    label: getLogicTypeDisplayName(InputConditionType.Minitems),
    description: "If the cart contains X items or more."
  },
  {
    value: InputConditionType.Minitemsvalue,
    label: getLogicTypeDisplayName(InputConditionType.Minitemsvalue),
    description: "If a selected group of items in the cart totals X or more."
  },
  {
    value: InputConditionType.Storegroup,
    label: getLogicTypeDisplayName(InputConditionType.Storegroup),
    description: "If the store is part of the specified store group X."
  },
  {
    value: InputConditionType.Countrycode,
    label: getLogicTypeDisplayName(InputConditionType.Countrycode),
    description: "If the visitor’s location is identified as country code X."
  }
]

type Props = {
  // eslint-disable-next-line
  setValue: any
  defaultValue?: string
  disabled?: boolean
}

export const ConditionSelect = ({ setValue, disabled, defaultValue }: Props) => (
  <LogicSelect
    options={options}
    setValue={setValue}
    disabled={disabled}
    placeholder="Select a condition type to add"
    defaultValue={defaultValue}
  />
)
