import React, { Dispatch, SetStateAction, useState } from "react"
import Popup from "../../../Ui/Popup"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentCancellation,
  InputActionType,
  InputOrderCancellation,
  InputReason,
  Order
} from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"
import CREATE_CANCELLATION from "../../../../graphql/mutations/order/cancellation/CreateCancellation"
import DELETE_CANCELLATION from "../../../../graphql/mutations/order/cancellation/DeleteCancellation"
import CancellationAction from "./CancellationAction"
import START_CANCELLATION from "../../../../graphql/mutations/order/cancellation/StartCancellation"
import StatusPopup from "./StatusPopup"
import { handleErrorMessages } from "helpers/errors"
import ReasonInput from "../Reason/ReasonInput"
import ALL_REASON_CODES from "../../../../graphql/queries/settings/reasonCodes/AllReasonCodes"

type Props = {
  setShowCancelPopup: Dispatch<SetStateAction<boolean>>
  showCancelPopup: boolean
  order: Order
  refetch: () => void
}

const CancelOrderPopup = ({ setShowCancelPopup, order, refetch, showCancelPopup }: Props) => {
  const [reason, setReason] = React.useState<InputReason>({ cause: "", code: "" })
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({
    actionType: InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({
    actionType: InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentCancellation>({
    actionType: InputActionType.Auto
  })

  const [createCancellation, { loading: createLoading }] = useMutation(CREATE_CANCELLATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [deleteCancellation] = useMutation(DELETE_CANCELLATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [startCancellation, { loading: startLoading }] = useMutation(START_CANCELLATION, {
    onCompleted: () => {
      setShowCancelPopup(false)
      setShowStatusPopup(true)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const createOrderCancellation = () => {
    const input: InputOrderCancellation = {}
    const inputReason: InputReason = {}
    if (reason.code != "") {
      inputReason.code = reason.code
    }
    if (reason.cause != "") {
      inputReason.cause = reason.cause
    }
    if (Object.keys(inputReason).length > 0) {
      input.reason = inputReason
    }

    return createCancellation({
      variables: {
        orderId: order.id,
        input: input
      }
    })
  }

  const deleteOrderCancellation = () => {
    if (order.Cancellations[0]?.id)
      deleteCancellation({ variables: { cancellationId: order.Cancellations[0].id } })
  }

  const startOrderCancellation = () =>
    startCancellation({
      variables: {
        cancellationId: order.Cancellations[0].id,
        input: {
          ...(order.Cancellations[0]?.bonus && { bonus: bonusAction }),
          ...(order.Cancellations[0].giftCards.length > 0 && { giftCard: giftCardAction }),
          payment: paymentAction
        }
      }
    })

  const { data } = useQuery(ALL_REASON_CODES)
  const customReasons = data?.Order?.customReasonCodes?.codes

  if (showStatusPopup) {
    return (
      <StatusPopup
        setShowStatusPopup={setShowStatusPopup}
        cancellationId={order?.Cancellations[0].id}
        refetchOrder={refetch}
      />
    )
  }

  if (showCancelPopup) {
    return (
      <Popup
        title={`Cancel order`}
        handleCloseClick={() => {
          setShowCancelPopup(false)
          deleteOrderCancellation()
        }}
        type={order?.Cancellations?.length > 0 ? "delete" : "default"}
        buttonText={order?.Cancellations?.length > 0 ? "Confirm cancellation" : "Next"}
        handleOkClick={() =>
          order?.Cancellations?.length > 0 ? startOrderCancellation() : createOrderCancellation()
        }
        loading={createLoading || startLoading}
      >
        <b>Reason (Optional)</b>
        <ReasonInput reason={reason} setReason={setReason} customReasons={customReasons} />
        {order?.Cancellations?.length > 0 && (
          <CancellationAction
            cancellation={order?.Cancellations[0]}
            actionState={{
              giftCardAction,
              setGiftCardAction,
              bonusAction,
              setBonusAction,
              paymentAction,
              setPaymentAction
            }}
            orderId={order.id}
          />
        )}
      </Popup>
    )
  }
  return <></>
}

export default CancelOrderPopup
