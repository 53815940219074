import {
  CreateIcon,
  FailureIcon,
  HistoryIcon,
  OperationIcon,
  SuccessIcon,
  UserIcon,
  WaitingIcon
} from "./OrderHistoryLog.styled"
import { ReactComponent as CheckIcon } from "../../../images/icons/check-thick.svg"
import { ReactComponent as FailedIcon } from "../../../images/icons/xmark-thick.svg"
import { ReactComponent as PlayIcon } from "../../../images/icons/play.svg"
import { ReactComponent as CreatedIcon } from "../../../images/icons/square-plus.svg"
import { ReactComponent as CompletedIcon } from "../../../images/icons/flag-checkered.svg"
import { ReactComponent as RequestIcon } from "../../../images/icons/stopwatch.svg"
import { ReactComponent as ExclamationIcon } from "../../../images/icons/exclamation.svg"
import { ReactComponent as RestartIcon } from "../../../images/icons/arrow-rotate-left-thick.svg"
import { ReactComponent as CustomStateIcon } from "../../../images/icons/link-horizontal.svg"
import { ReactComponent as RemovedCustomStateIcon } from "../../../images/icons/link-horizontal-slash.svg"
import { OrderHistoryLogEntry } from "@lib/types/generated/graphql-types"

type Props = {
  orderHistoryLogEntry: OrderHistoryLogEntry
}

export const Icon = ({ orderHistoryLogEntry }: Props) => {
  const status = orderHistoryLogEntry.status
  const type = orderHistoryLogEntry.type

  const getInitials = (userName: string) => {
    if (userName.includes("@")) {
      const name = userName.split("@")
      if (name[0].includes(".")) {
        const fragments = name[0].split(".")
        return `${fragments[0].slice(0, 1)}${fragments[1].slice(0, 1)}`
      }
    }
    return userName.slice(0, 1)
  }

  if (status.endsWith("_SUCCESS") || status === "SKIPPED" || status === "MANUAL") {
    return (
      <SuccessIcon>
        <CheckIcon />
      </SuccessIcon>
    )
  }

  if (status.endsWith("_FAILURE")) {
    return (
      <FailureIcon>
        <FailedIcon />
      </FailureIcon>
    )
  }

  if (status.endsWith("_REQUEST")) {
    return (
      <WaitingIcon>
        <RequestIcon />
      </WaitingIcon>
    )
  }

  if (type === "OPERATION_STARTED") {
    return (
      <OperationIcon>
        <PlayIcon />
      </OperationIcon>
    )
  }

  if (type === "OPERATION_RESTARTED") {
    return (
      <OperationIcon>
        <RestartIcon />
      </OperationIcon>
    )
  }

  if (type === "OPERATION_CREATED") {
    return (
      <CreateIcon>
        <CreatedIcon />
      </CreateIcon>
    )
  }

  if (type === "OPERATION_COMPLETED") {
    return (
      <SuccessIcon>
        <CompletedIcon />
      </SuccessIcon>
    )
  }

  if (type === "OPERATION_FAILED") {
    return (
      <FailureIcon>
        <ExclamationIcon />
      </FailureIcon>
    )
  }
  if (type === "ADD_CUSTOM_STATE") {
    return (
      <CreateIcon>
        <CustomStateIcon />
      </CreateIcon>
    )
  }
  if (type === "REMOVE_CUSTOM_STATE") {
    return (
      <FailureIcon>
        <RemovedCustomStateIcon />
      </FailureIcon>
    )
  }
  if (type === "ADD_COMMENT") {
    return <UserIcon>{getInitials(orderHistoryLogEntry?.actor?.name ?? "")}</UserIcon>
  }

  return <HistoryIcon />
}

export default Icon
