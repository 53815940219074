import styled from "styled-components"

type StyledProps = {
  $selected: boolean
}

type StatusProps = {
  $active: boolean
}

export const AuthTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

export const Border = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  display: flex;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

export const AuthTypeSelect = styled.div<StyledProps>`
  width: 20rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
  background: ${({ theme, $selected }) =>
    $selected ? theme.colors.turquoiseDark : theme.colors.white};
  color: ${({ theme, $selected }) => ($selected ? theme.colors.white : theme.colors.black)};
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }
`

export const Info = styled.p`
  display: flex;
  justify-content: center;
`

export const InfoText = styled.p`
  background: ${({ theme }) => theme.colors.turquoiseLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  padding: 1rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    height: 1.4rem;
  }
`

export const GeneralBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  border-radius: 0.6rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  margin-bottom: 4rem;
`

export const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;

  svg {
    height: 1.5rem;
    margin-left: 0.5rem;
    fill: ${(p) => p.$active ? p.theme.colors.turquoiseDarker : p.theme.colors.error.highlight};
  }
`

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`
