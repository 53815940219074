import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  width: 100%;

  > div {
    word-break: break-all;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Flex = styled.div`
  display: flex;
  gap: 1rem;

  button {
    flex-shrink: 0;
    height: 4.4rem;
  }
`

export const TabText = styled.div`
color: ${(p) => p.theme.colors.errorText};
`
