import React from "react"
import TableHeader from "../../Ui/Table/TableHeader"
import { CustomReasonCode } from "@lib/types/generated/graphql-types"
import { v4 as uuid } from "uuid"
import { ReactComponent as OptionIcon } from "images/icons/timeline.svg"
import dayjs from "dayjs"
import { StyledTableRow } from "./Shared.styled"
import { useDispatch } from "react-redux"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

type Props = {
  customReasonCodes: CustomReasonCode[]
  handleSelectReasonCode: (code: string) => void
}

const ReasonCodeTable = ({ customReasonCodes, handleSelectReasonCode }: Props) => {
  const dispatch = useDispatch()

  const selectRow = (code: string) => {
    handleSelectReasonCode(code)
    dispatch(showEditSidebar())
  }

  if (customReasonCodes.length < 1)
    return (
      <>
        <TableHeader>
          <div>Title</div>
        </TableHeader>
        <StyledTableRow disableHover={true}>
          <div>No definitions created</div>
        </StyledTableRow>
      </>
    )

  return (
    <>
      <TableHeader iconHeader={true}>
        <div>Code</div>
        <div>Name</div>
        <div className={"hideOnSmallScreens"}>Created</div>
      </TableHeader>
      {customReasonCodes?.map((customCode) => (
        <StyledTableRow
          key={uuid()}
          handleOnClick={() => selectRow(customCode.code)}
        >
          <OptionIcon />
          <div>{customCode.code}</div>
          <div>{customCode.label}</div>
          <div className={"hideOnSmallScreens"}>
            {dayjs(customCode.created).format("YYYY-MM-DD")}
          </div>
        </StyledTableRow>
      ))}
    </>
  )
}

export default ReasonCodeTable
