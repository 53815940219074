import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  column-gap: 3rem;
  grid-template-columns: repeat(1, 1fr);

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  min-width: 16rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem;
  margin-bottom: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    margin-bottom: 3rem;
  }
`

export const Logos = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  width: 100%;
  padding: 1rem 0 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1rem 0 0;
  }

  p {
    margin-top: 0;
  }
`

export const Logo = styled.div`
  padding: 1rem;
  border-radius: 0.6rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.4rem;
  height: 4.2rem;
  cursor: pointer;

  svg {
    max-height: 1.7rem;
  }
`

export const Label = styled.div`
  ${(p) => p.theme.bold}
  width: 100%;
  color: ${(p) => p.theme.colors.black};
  font-size: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 1.4rem;
  }
`
