import styled from "styled-components"

export const AccessWrapper = styled.div`
  margin-bottom: 2rem;
`

export const AccessInfo = styled.div`
  margin-top: 2rem;
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
`
