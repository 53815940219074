import styled from "styled-components"

import PrimaryButton from "./Buttons/PrimaryButton"
import ErrorMessage from "./Messages/ErrorMessage"

type StyledProps = {
  $blurBackgroundColor?: boolean
  $maxWidth?: string
}

export const Container = styled.div<StyledProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: calc(100% - 4rem);
  max-width: ${p => p.$maxWidth};
  margin: 0 2rem;
  min-height: 20rem;
  background: ${(p) => p.theme.colors.background};
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 2rem), -50%);
  border-radius: 0.6rem;
  padding: 2rem 3rem 0;
  color: ${(p) => p.theme.colors.black};
  z-index: 10001;

  h2 {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    margin-bottom: 0;
    padding-bottom: 2rem;
    width: 100%;
  }

  p {
    margin: 0 0 0.5rem;
    color: ${(p) => p.theme.colors.black};
  }
`

export const Blur = styled.div<StyledProps>`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${(p) => (p.$blurBackgroundColor ? "rgba(0, 0, 0, 0.4)" : "initial")};
  z-index: 10000;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 2rem;
  margin: 0 -3rem;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0 0 0.6rem 0.6rem;

  button {
    margin-left: 0 !important;
  }
`

export const Item = styled.ul`
  padding-left: 2rem;
  margin: 0;

  h4 {
    margin: 0;
    line-height: 2rem;
    display: flex;

    svg {
      fill: ${(p) => p.theme.colors.black};
      width: 1.4rem;
      margin-right: 1rem;
    }
  }

  span {
    font-size: 1.2rem;
    padding-left: 2.4rem;
  }
`

export const CancelButton = styled(PrimaryButton)`
  color: ${(p) => p.theme.colors.black};
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`

export const StyledErrorMessage = styled(ErrorMessage)`
  margin: 0;
`
