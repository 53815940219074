import styled from "styled-components"

import { Loader } from "components/Ui/Loader"

import { ReactComponent as MagnifyingGlass } from "images/icons/magnifying-glass.svg"
import { ReactComponent as ResetButton } from "images/icons/xmark.svg"

type StyledProps = {
  $show?: boolean
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    padding: 0;
    margin-left: 1rem;
  }
`

export const SearchContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  background: ${(p) => p.theme.colors.greyLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem;
`

export const SearchIcon = styled(MagnifyingGlass)`
  width: 2rem;
  height: 2rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 2.5rem;
    height: 2.5rem;
  }
`

export const SearchLoader = styled(Loader)``

export const ResetSearch = styled(ResetButton)<StyledProps>`
  height: 2.5rem;
  cursor: pointer;
  transition: opacity 0.3s;
  transition-delay: 0.3s;
  opacity: ${(p) => (p.$show ? "1" : "0")};
`

export const SearchResultContainer = styled.div`
  width: 100%;
  min-height: 20rem;
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`

export const Stock = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Actions = styled.div`
  position: absolute;
  right: 4rem;
  display: flex;
`
