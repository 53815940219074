import styled from "styled-components"

export const Container = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
`

export const Cause = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`
