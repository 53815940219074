import React from "react"
import { InputReason, OrderRefund } from "@lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import UPDATE_REFUND from "../../../../../graphql/mutations/order/refunds/UpdateRefund"
import { getRefundInput } from "../Helpers/helpers"
import { handleErrorMessages } from "helpers/errors"
import Reason from "../../Reason/Reason"

type Props = {
  refund: OrderRefund
  refetch: () => void
  orderLoading: boolean
}

const RefundReason = ({ refund, refetch, orderLoading }: Props) => {

  const [updateRefund, { loading }] = useMutation(UPDATE_REFUND, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const addReason = (reason: InputReason) => {
    updateRefund({
      variables: {
        refundId: refund.id,
        input: {
          ...getRefundInput(refund),
          ...(reason.cause && reason.code && { reason: reason })
        }
      }
    })
  }

  const removeReason = () => {
    updateRefund({
      variables: {
        refundId: refund.id,
        input: {
          ...getRefundInput(refund),
          reason: null
        }
      }
    })
  }

  return (
    <Reason
      addReason={addReason}
      removeReason={removeReason}
      orderReason={refund?.reason}
      loading={loading}
      orderLoading={orderLoading}
    />
  )
}

export default RefundReason
