import React from "react"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { OrderCompensation } from "@lib/types/generated/graphql-types"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import { useQuery } from "@apollo/client"
import Status from "../../../Ui/Status"
import { Label } from "../../Updates/Shared.styled"
import { Container, ProviderStatus } from "./Shared.styled"
import GET_COMPENSATION from "../../../../graphql/queries/order/GetCompensation"

type Props = {
  pendingCompensation: OrderCompensation | undefined
  orderRefetch: () => void
  orderLoading: boolean
}

const PendingCompensation = ({ pendingCompensation, orderRefetch, orderLoading }: Props) => {
  const { data, refetch, loading } = useQuery(GET_COMPENSATION, {
    variables: { compensationId: pendingCompensation?.id },
    fetchPolicy: "network-only"
  })

  const compensation = data?.Order?.compensation as OrderCompensation
  if (!compensation) return null

  return (
    <Container>
      <h4>Pending compensation #{pendingCompensation?.id}</h4>
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>{" "}
          <Status status={compensation?.paymentProvider?.status?.current ?? ""} />
        </div>
        {compensation?.shippingProvider?.status?.current && (
          <div>
            <Label>Shipping status:</Label>
            <Status status={compensation?.shippingProvider?.status?.current ?? ""} />
          </div>
        )}
        {compensation?.bonusProvider && (
          <div>
            <Label>Bonus status:</Label>
            <Status status={compensation?.bonusProvider?.status?.current ?? ""} />
          </div>
        )}
        {compensation?.giftCardProvider && (
          <div>
            <Label>Gift card status:</Label>
            <Status status={compensation?.giftCardProvider?.status?.current ?? ""} />
          </div>
        )}
      </ProviderStatus>
      <PrimaryButton
        handleClick={() => {
          refetch()
          orderRefetch()
        }}
        loading={loading || orderLoading}
      >
        <RefreshIcon />
        Refresh status
      </PrimaryButton>
    </Container>
  )
}

export default PendingCompensation
