import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"

import GET_VOYADO_CONFIG from "graphql/queries/config/Providers/Voyado/GetVoyadoConfig"

import { Name } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as VoyadoLogo } from "images/providers/icons/voyado.svg"

export const getLoadingRow = () => (
  <>
    <VoyadoLogo />
    <div>
      <Name>voyado-config-id</Name>
    </div>
    <div className="hideOnSmallScreens">
      <p>https://baseurl.se</p>
    </div>
    <div className="hideOnSmallScreens hideOnMediumScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMediumScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setVoyadoConfigId?: Dispatch<SetStateAction<string>>
}

export const VoyadoConfigTableRow = ({ id, setVoyadoConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_VOYADO_CONFIG, {
    variables: { id: id }
  })

  const voyadoConfig = data?.getVoyadoConfig

  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>
  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setVoyadoConfigId) setVoyadoConfigId(voyadoConfig.id)
        dispatch(showEditSidebar())
      }}
    >
      <VoyadoLogo />
      <div>
        <Name>{voyadoConfig?.id}</Name>
      </div>
      <div className="hideOnSmallScreens">
        <p>{voyadoConfig?.baseUrl}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMediumScreens">
        <p>{dayjs(voyadoConfig?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMediumScreens">
        <p>{dayjs(voyadoConfig?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
