import React, { ReactNode } from "react"
import styled, { css } from "styled-components"

type StyledListProps = {
  $collapse?: boolean
}

type StyledItemProps = {
  $flexibleHeight?: boolean
  $showAsColumn?: boolean
}

type StyledLabelProps = {
  $alignTop?: boolean
}

type StyledValueProps = {
  $flex?: boolean
  $alignRight?: boolean
  $capitalize?: boolean
}

const ListItems = styled.ul<StyledListProps>`
  flex: 1;
`

const ListItem = styled.li<StyledItemProps>`
  min-height: 5rem;
  height: ${(p) => (p.$flexibleHeight ? "auto" : "5rem")};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  padding: 0;

  ${(p) =>
    p.$showAsColumn
      ? css`
          padding: 1.5rem 0;
        `
      : css`
          display: flex;
          align-items: center;
          padding: 0;
        `}
  &:last-of-type {
    border-bottom: none;
  }
`

const ListLabel = styled.div<StyledLabelProps>`
  ${(p) => p.theme.bold};
  width: 17rem;
  align-self: ${(p) => (p.$alignTop ? "flex-start" : "initial")};
  display: flex;
  align-items: center;
`

const ListValue = styled.div<StyledValueProps>`
  flex: 1;
  text-align: ${(p) => (p.$alignRight ? "right" : "left")};
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.$alignRight ? "flex-end" : "flex-start")};
  line-height: 2rem;
  text-transform: ${(p) => (p.$capitalize ? "capitalize" : "initial")};
  word-break: break-all;

  ${(p) =>
    p.$flex &&
    css`
      display: flex;
      align-items: center;
    `}
  img {
    height: 2rem;
    margin-right: 0.5rem;
  }
`

type ListProps = {
  children: ReactNode | ReactNode[] | undefined
}

type ItemProps = {
  children: ReactNode | ReactNode[] | undefined
  flexibleHeight?: boolean
  showAsColumn?: boolean
}

type Props = {
  children: string | ReactNode | ReactNode[] | undefined | null | boolean | number
  flex?: boolean
  alignTop?: boolean
  alignRight?: boolean
  capitalize?: boolean
  handleOnClick?: () => void
}

const List = ({ children, ...props }: ListProps) => <ListItems {...props}>{children}</ListItems>

const Item = ({ children, flexibleHeight, showAsColumn, ...props }: ItemProps) => (
  <ListItem $flexibleHeight={flexibleHeight} $showAsColumn={showAsColumn} {...props}>
    {children}
  </ListItem>
)

const Label = ({ children, handleOnClick, alignTop, ...props }: Props) => (
  <ListLabel onClick={handleOnClick} $alignTop={alignTop} {...props}>
    {children}
  </ListLabel>
)

const Value = ({ children, flex, alignRight, capitalize, ...props }: Props) => (
  <ListValue $flex={flex} $alignRight={alignRight} $capitalize={capitalize} {...props}>
    {children}
  </ListValue>
)

export { List, Item, Label, Value }
