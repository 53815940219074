import styled from "styled-components"

import { IconTableHeader } from "../../Ui/Table/Shared.styled"
import TableRow from "../../Ui/Table/TableRow"

export const StyledIconTableHeader = styled(IconTableHeader)`
  padding-right: 8rem;
`

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: initial;
  }
`

export const Name = styled.div`
  ${(p) => p.theme.bold};
  width: 17rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 25rem;
  }
`

export const IconLabel = styled.h2`
  display: flex;
  align-items: center;
  height: 2rem;
  margin-top: 3rem;

  svg {
    height: 1.6rem;
    margin-left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
  }
`
