import styled from "styled-components"

export const CartItemContainer = styled.div`
  display: flex;
  padding: 0.7rem 5rem 0.7rem 0;
  position: relative;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};

  &:last-of-type {
    border-bottom: none;
  }
`

export const ItemImage = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;

  img {
    position: relative !important;
    object-fit: contain;
    background: ${(p) => p.theme.colors.background};
  }
`

export const ItemDetails = styled.div`
  padding: 1rem 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1.5rem 2rem;
  }
`

export const ItemName = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  ${(p) => p.theme.bold};
`

export const ItemPrice = styled.div`
  span {
    text-decoration: line-through;
    color: #666;
    font-weight: 400;
    margin-left: 1rem;
  }
`

export const ItemDelete = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 1.3rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    transition: all 0.3s;

    &:hover {
      background: ${(p) => p.theme.colors.greyLight};
      border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    }
  }
`

export const QtySelector = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  right: 0;

  span {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    &:first-of-type,
    &:last-of-type {
      width: 2.5rem;
      border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
      font-size: 1.6rem;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 0.3rem;
      padding: 0;

      ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        &:hover {
          background: ${(p) => p.theme.colors.greyLight};
          border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
        }
      }
    }
  }
`
