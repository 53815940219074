import React from "react"
import { Label, Wrapper } from "./ReleaseReason.styled"
import { CustomReasonCode, Maybe, OrderRelease } from "@lib/types/generated/graphql-types"
import AddedReason from "../../Reason/AddedReason"
import { useQuery } from "@apollo/client"
import ALL_REASON_CODES from "../../../../../graphql/queries/settings/reasonCodes/AllReasonCodes"

type Props = {
  release: OrderRelease
}

const ReleaseReason = ({ release }: Props) => {

  const { data, loading: allCodesLoading } = useQuery(ALL_REASON_CODES)
  const customReasons = data?.Order?.customReasonCodes?.codes

  const getCustomCode = (code: Maybe<string> | undefined) =>
    customReasons?.find((customCode: CustomReasonCode) => customCode.code === code)

  return (
    <Wrapper className={allCodesLoading ? "blur": ""}>
      <Label>Reason</Label>
      <AddedReason reason={release?.reason} getCustomCode={getCustomCode} />
    </Wrapper>
  )
}

export default ReleaseReason
