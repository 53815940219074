import React, { useEffect, useState } from "react"
import EditSidebar from "../../Ui/EditSidebar/EditSidebar"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import EditSidebarHeader from "../../Ui/EditSidebar/EditSidebarHeader"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { handleErrorMessages } from "helpers/errors"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { BlockingIcon, Info, InfoIcon, InputWrapper, Label, Value } from "./Shared.styled"
import dayjs from "dayjs"
import { CustomOperation, CustomState, CustomStateType, InputCustomState } from "lib/types/generated/graphql-types"
import GET_CUSTOM_STATE from "../../../graphql/queries/settings/customState/GetCustomState"
import CREATE_OR_UPDATE_CUSTOM_STATE from "../../../graphql/mutations/settings/customState/CreateOrUpdateCustomState"
import DELETE_CUSTOM_STATE from "../../../graphql/mutations/settings/customState/DeleteCustomState"
import CustomStateInputs from "./CustomStateInputs"


type StateInput = {
  input: InputCustomState
}

type Props = {
  editCustomStateId: string
  refetch: () => void
  handleClearEditCustomStateId: () => void
}

const EditCustomState = ({ refetch, editCustomStateId, handleClearEditCustomStateId }: Props) => {
  const [type, setType] = useState<CustomStateType>()
  const [customState, setCustomState] = useState<CustomState>()

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors }
  } = useForm<StateInput>()

  const dispatch = useDispatch()

  const setBlockingType = () => {
    setType(CustomStateType.Blocking)
    setValue("input.type", CustomStateType.Blocking)
  }

  const setInformativeType = () => {
    setType(CustomStateType.Informative)
    setValue("input.type", CustomStateType.Informative)
  }

  const { loading: fetchLoading } = useQuery(GET_CUSTOM_STATE, {
    variables: { stateId: editCustomStateId },
    onCompleted: (data) => {
      setCustomState(data?.Order?.customState)
      setType(data?.Order?.customState?.type)
    }
  })

  const cancelSidebar = () => {
    dispatch(hideEditSidebar())
    handleClearEditCustomStateId()
  }

  const handleMutationCompleted = (message: string) => {
    dispatch(
      alertActions.actions.createAlert({
        message: `${message}`,
        type: "success"
      })
    )
    refetch()
    reset()
    cancelSidebar()
  }

  const [updateCustomState, { loading }] = useMutation(CREATE_OR_UPDATE_CUSTOM_STATE, {
    onCompleted: () => {
      handleMutationCompleted("State updated")
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [deleteCustomState, { loading: deleteLoading }] = useMutation(DELETE_CUSTOM_STATE, {
    variables: { stateId: editCustomStateId },
    onCompleted: () => {
      handleMutationCompleted("State deleted successfully")
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const onSubmit: SubmitHandler<StateInput> = (data) => {
    updateCustomState({
      variables: {
        ...data,
        stateId: editCustomStateId,
        input: {
          ...data.input,
          ...(type === CustomStateType.Blocking && { operations: data.input.operations })
        }
      }
    })
  }
  useEffect(() => {
    if (customState) {
      setValue("input.type", customState.type)
      setValue("input.description", customState.description)
      customState?.operations && setValue("input.operations", customState?.operations as CustomOperation[])
    }
  }, [customState])

  if (fetchLoading)
    return (
      <EditSidebar cancelEvent={cancelSidebar}>
        <EditSidebarHeader title={"Edit State"} cancelEvent={cancelSidebar}>
          <PrimaryButton disabled={true} mode={"delete"}>
            Delete
          </PrimaryButton>
          <PrimaryButton disabled={true}>Update State</PrimaryButton>
        </EditSidebarHeader>
        <InputWrapper className="blur">
          <Label>State</Label>
          <Info>
            <div>Id:</div>
            <Value>
              <b>{editCustomStateId}</b>
            </Value>
          </Info>
        </InputWrapper>
      </EditSidebar>
    )

  return (
    <EditSidebar cancelEvent={cancelSidebar}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader
          title={"Edit State"}
          cancelEvent={cancelSidebar}
          icon={type === CustomStateType.Blocking ? <BlockingIcon /> : <InfoIcon />}
        >
          <PrimaryButton
            type={"button"}
            mode={"delete"}
            loading={deleteLoading}
            handleClick={() => deleteCustomState()}
          >
            Delete
          </PrimaryButton>
          <PrimaryButton type={"submit"} loading={loading}>
            Update state
          </PrimaryButton>
        </EditSidebarHeader>

        <InputWrapper>
          <b>State</b>
          <Info>
            <div>Id:</div>
            <Value>
              <b>{editCustomStateId}</b>
            </Value>
          </Info>
          <Info>
            <div>Created:</div>
            <Value>{dayjs(customState?.created).format("YYYY-MM-DD")}</Value>
          </Info>
          <Info>
            <div>Updated:</div>
            <Value>{dayjs(customState?.updated).format("YYYY-MM-DD")}</Value>
          </Info>
        </InputWrapper>
        {type && customState && (
          <CustomStateInputs
            register={register}
            errors={errors}
            control={control}
            setBlockingType={setBlockingType}
            setInformativeType={setInformativeType}
            selectedType={type}
            defaultValue={customState}
          />
        )}
      </form>
    </EditSidebar>
  )
}

export default EditCustomState
