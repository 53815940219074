import React from "react"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import Box from "components/Ui/Box"
import Status from "components/Ui/Status"
import DimensionsComponent from "components/Products/Dimensions"
import { ProductVariantDisplayAttributes } from "components/Products/ProductVariantDisplayAttributes"
import { Label, Value } from "components/Ui/List/List"
import { ProductVariantCustomAttributes } from "components/Products/ProductVariantCustomAttributes"
import { ProductVariantTags } from "components/Products/ProductVariantTags"
import { ProductVariantStock } from "components/Products/Stock/ProductVariantStock"
import { ProductVariantShippingAttributes } from "../../../Products/ProductVariantShippingAttributes"
import { useQuery } from "@apollo/client"
import GET_PRODUCT_VARIANT from "../../../../graphql/queries/product/GetProductVariant"
import { BrinkLoader } from "../../../Ui/BrinkLoader"
import {
  Description,
  Image,
  ImageWrapper,
  Parent,
  StyledItem,
  StyledList
} from "./ShowPreviewVariant.styled"
import { handleImageError } from "helpers/image"

type Props = {
  variantId: string
  showParent?: boolean
}

const ShowVariant = ({ variantId, showParent }: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_PRODUCT_VARIANT, { variables: { id: variantId } })

  const variant = data?.Product.variant

  if (loading)
    return (
      <EditSidebar>
        <BrinkLoader />
      </EditSidebar>
    )

  return (
    <EditSidebar>
      <Box padding="0" margin="0">
        <EditSidebarHeader
          title={
            <>
              {variant.name} <Status status={variant.isActive ? "Active" : "Inactive"} />
            </>
          }
          id={variant.id}
        >
          {showParent ? (
            <>
              <div>Parent:</div>
              <Parent
                onClick={() => {
                  dispatch(hideEditSidebar())
                  navigate(`/products/${variant.productParentId}`)
                }}
              >
                {variant.productParentId}
              </Parent>
            </>
          ) : (
            <></>
          )}
        </EditSidebarHeader>
        <ImageWrapper>
          <Image src={variant?.imageUrl ?? ""} onError={handleImageError} />
        </ImageWrapper>
        <DimensionsComponent variant={variant} />
        <StyledList>
          <StyledItem>
            <Label>Tax group:</Label>
            <Value>{variant.TaxGroup.name}</Value>
          </StyledItem>
          <StyledItem>
            <Label>Created:</Label>
            <Value>{dayjs(variant.created).format("YYYY-MM-DD")}</Value>
          </StyledItem>
          <StyledItem>
            <Label>Updated:</Label>
            <Value>{dayjs(variant.updated).format("YYYY-MM-DD")}</Value>
          </StyledItem>
        </StyledList>
        <Description>
          <Label>Description:</Label> <p>{variant.description || "No description added"}</p>
        </Description>
        <ProductVariantStock stock={variant.Stock} />
        <ProductVariantCustomAttributes
          customAttributes={JSON.parse(variant.customAttributes)}
          insideEditSidebar={true}
        />
        <ProductVariantDisplayAttributes attributes={variant.displayNames} name={true} />
        <ProductVariantDisplayAttributes attributes={variant.displayDescriptions} name={false} />
        <ProductVariantTags tags={JSON.parse(variant.tags)} insideEditSidebar={true} />
        <ProductVariantShippingAttributes shippingAttributes={variant?.shippingAttributes} />
      </Box>
    </EditSidebar>
  )
}

export default ShowVariant
