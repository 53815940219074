import styled from "styled-components"
import {StylesConfig} from "react-select"
import {Common} from "@lib/types"

interface StyledProps {
  invalid: boolean
}

export const SelectContainer = styled.div<StyledProps>`
  width: 100%;

  svg {
    margin-right: 0;
  }

  div[class*="-listbox"] {
    z-index: 1000;
  }

  div[class*="-control"] {
    height: 4.5rem;
    padding: 0.1rem 0 0 1rem;
    border: 0.1rem solid
      ${(p) => (p.invalid ? p.theme.colors.errorBackground : p.theme.colors.greyLight)};
    border-radius: 0.5rem;
    transition: all 0.2s;
    cursor: pointer;
  }
`
export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

export const customStyles: StylesConfig<Common.Option, false> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#eff1f4" : "white",
    cursor: "pointer",
    color: "black",
    ":active": {
      backgroundColor: "#f5f7f8"
    },
    ":hover": {
      backgroundColor: "#f5f7f8"
    }
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "0.1rem solid #39b39b !important;" : "0.1rem solid #dfe3e8",
    boxShadow: state.isFocused ? "0 0 0.5rem 0 #39b39b !important;" : "none",
    ":hover": {
      border: "0.1rem solid #39b39b !important"
    }
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0 0.4rem 1.1rem hsla(0, 0%, 0%, 0.1)",
    overflow: "hidden",
    borderRadius: "0.5rem",
    border: "0.1rem solid #dfe3e8",
    zIndex: "100000"
  })
}
