import React from "react"
import {
  CustomReasonCode,
  Maybe,
  Reason as OperationReason
} from "@lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"
import ALL_REASON_CODES from "../../../graphql/queries/settings/reasonCodes/AllReasonCodes"
import { Cause, Container } from "./Reason.styled"

type Props = {
  reason: OperationReason
}

const Reason = ({ reason }: Props) => {
  const { data, loading } = useQuery(ALL_REASON_CODES)
  const customReasons = data?.Order?.customReasonCodes?.codes

  const getCustomCode = (code: Maybe<string> | undefined) =>
    customReasons?.find((customCode: CustomReasonCode) => customCode.code === code)

  const customCode = getCustomCode(reason?.code)

  return (
    <Container className={loading ? "blur" : "hideOnXSmallScreens"}>
      <div>{customCode ? `${customCode.code} - ${customCode.label}` : reason?.code}</div>
      <Cause>{reason.cause}</Cause>
    </Container>
  )
}

export default Reason
