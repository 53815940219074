import React, { useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import CreateKlarnaConfig from "./CreateKlarnaConfig"
import { KlarnaCheckoutConfigTable } from "components/Config/KlarnaCheckout/KlarnaCheckoutConfigTable"
import EditKlarnaConfig from "./EditKlarnaConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as KlarnaLogo } from "images/providers/klarna-logo.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageKlarnaLogo = styled(KlarnaLogo)`
  height: 5rem;
`

export const KlarnaConfig = () => {
  const [editId, setEditId] = useState<string>("")
  const { loading, data, refetch, error } = useQuery(ALL_PAYMENT_PROVIDERS)
  const paymentProviders = data?.getPaymentProviders
  const dispatch = useAppDispatch()

  return (
    <Container>
      <PageHeader
        title={<PageKlarnaLogo />}
        description={<>Handle your Klarna configurations</>}
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!editId && <CreateKlarnaConfig refetch={refetch} />}
      {editId && <EditKlarnaConfig refetch={refetch} editId={editId} setEditId={setEditId} />}
      <KlarnaCheckoutConfigTable
        providers={paymentProviders}
        error={error}
        setEditId={setEditId}
        loading={loading}
      />
    </Container>
  )
}
