import React from "react"
import { Container, Content, Id, ImageAndDetails, Name, Qty } from "./OrderLine.styled"
import { OrderPaymentQliroOneUpsellItems } from "@lib/types/generated/graphql-types"

type Props = {
  upsellItem: OrderPaymentQliroOneUpsellItems
}

const UpsellOrderLine = ({ upsellItem }: Props) => {
  return (
    <Container>
      <Content>
        <div>
          <ImageAndDetails>
            <div>
              <Name>{upsellItem.displayName}</Name>
              <Id>
                <b>Id: </b>
                {upsellItem.productVariantId}
              </Id>
            </div>
          </ImageAndDetails>
        </div>
        <Qty>
          <div>
            <b>Quantity:</b> {upsellItem.quantity}
          </div>
        </Qty>
      </Content>
    </Container>
  )
}

export default UpsellOrderLine
