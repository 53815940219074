import React, { ReactNode } from "react"

import { Container, RefresButtonn } from "./ErrorMessage.styled"

import { ReactComponent as ErrorIcon } from "images/icons/circle-exclamation.svg"
import { ReactComponent as RefreshIcon } from "images/icons/rotate-right.svg"

const defaultMessage = (
  <>
    An error occured. Please contact
    <a href="mailto:support@brinkcommerce.com">support</a>.
  </>
)

type Props = {
  message?: string | ReactNode
  showRefreshButton?: boolean
}

const refreshage = () => {
  window.location.reload()
}

const ErrorMessage = ({ message, showRefreshButton = false, ...props }: Props) => (
  <Container {...props}>
    <span>
      <ErrorIcon /> {message || defaultMessage}
    </span>
    {showRefreshButton && (
      <RefresButtonn handleClick={refreshage} mode="secondary">
        <RefreshIcon /> Refresh
      </RefresButtonn>
    )}
  </Container>
)

export default ErrorMessage
