import React from "react"
import styled from "styled-components"
import { Common } from "@lib/types"
import SingleSelect from "components/Ui/Form/SingleSelect"

const StyledSelect = styled(SingleSelect)`
  div[class*="-MenuList"] {
    padding: 0;
  }
  div[class*="-option"] {
    padding: 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .customOption {
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    padding: 1.5rem 1.5rem 1rem;
    cursor: pointer;
  }

  .customOptionLabel {
    display: block;
    line-height: 1.4rem;
    ${(p) => p.theme.bold};
  }

  .customOptionDesc {
    color: #666;
    line-height: 1.2rem;
    font-size: 1.3rem;
  }

  div[class*="-singleValue"] {
    .customOption {
      padding: 0;
      border-bottom: none;
    }

    .customOptionDesc {
      display: none;
    }

    .customOptionLabel {
      font-weight: 400;
      font-family: "Museo Sans", serif;
    }
  }
`

type Props = {
  options: Common.Option[]
  placeholder: string
  // eslint-disable-next-line
  setValue: any
  defaultValue?: string
  disabled?: boolean
}

const formatOptionLabel = (data: Common.Option) => (
  <div className="customOption">
    <span className="customOptionLabel">{data.label}</span>
    <span className="customOptionDesc">{data.description}</span>
  </div>
)

export const LogicSelect = ({ options, placeholder, setValue, disabled, defaultValue }: Props) => (
  <StyledSelect
    options={options}
    setValue={setValue}
    disabled={disabled}
    formatOptionLabel={formatOptionLabel}
    placeholder={placeholder}
    defaultValue={defaultValue ? options.find((o) => o?.value === defaultValue) : undefined}
  />
)
