import gql from "graphql-tag"

const DELETE_CUSTOM_STATE = gql`
  mutation deleteCustomReasonCode($stateId: ID!) {
    Order {
      deleteCustomState(stateId: $stateId)
    }
  }
`

export default DELETE_CUSTOM_STATE
