import styled from "styled-components"
import { ActionDropdownButton } from "../../../Ui/Buttons/ActionDropdownButton"

export const Container = styled.div`
  width: 100%;
  border:  0.1rem solid ${p => p.theme.colors.borderLight};
  background: ${p => p.theme.colors.white};
  padding: 2rem;
  border-radius: 0.6rem;
  margin-top: 2rem;
`

export const Title = styled.div`
width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Provider = styled.div`
background: ${p => p.theme.colors.errorBackground};
  width: fit-content;
  color: ${p => p.theme.colors.errorText};
  padding: 0.3rem 1rem;
  border-radius: 0.6rem;
  margin-left: 1rem;
`

export const ProviderWrapper = styled.div`
display: flex;
  align-items: center;
`
export const ActionContainer = styled.div`
  padding: 1rem;
  border-radius: 0.6rem;
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  margin-bottom: 1rem;
`

export const ActionWrapper = styled.div`
  display: flex;
  background: ${p => p.theme.colors.white};
  align-items: center;

  button {
    height: 3rem;
  }

  svg {
    height: 2rem;
    margin-right: 1rem;
  }
  margin-bottom: 1rem;
`
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 17rem;
`

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  align-items: center;
  p {
    display: inline-block;
    word-break: break-word;
    font-size: 1.1rem;
    margin: 0.5rem;
  }
`

export const Providers = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  padding-top: 2rem;
`

export const GiftCardInputWrapper = styled.div`
display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 0.5rem;
  p {
    margin: 0;
    font-size: 1rem;
  }
`

export const StyledActionDropdownButton = styled(ActionDropdownButton)`
background: aliceblue;

`

export const ButtonWrapper = styled.div`
 padding-top: 1rem;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  button {
    margin-left: 1rem;
  }
`

