import React from "react"
import { useAuthenticator } from "@aws-amplify/ui-react"
import { signOut } from "aws-amplify/auth"
import PageHeader from "components/Ui/Page/PageHeader"
import { ReactComponent as LogoutIcon } from "images/icons/arrow-right-from-bracket.svg"
import { useSelector } from "react-redux"
import { selectUserAttributes, selectUserGroups } from "lib/store/services/auth/selectors"
import { Break, CampaignOnly, Container, Info, Label, SignOutButton } from "./User.styled"

export const User = () => {
  const { user } = useAuthenticator()
  const userGroups = useSelector(selectUserGroups)
  const userAttributes = useSelector(selectUserAttributes)
  const username = user.username

  const logout = () => {
    signOut().then(() => window.location.reload())
  }

  const campaignOnly =
    userGroups?.includes("brinkcommerce-campaignuser") &&
    !userGroups?.includes("brinkcommerce-superuser") &&
    !userGroups?.includes("brinkcommerce-useradmin") &&
    !userGroups?.includes("brinkcommerce-readonly")

  const getRolesDescription = () => {
    if (!userGroups) return "No access"
    const superUserDescription =
      "As a superuser in Merchant Portal, you can create and edit configurations, manage discount rules, and perform other related tasks."
    if (
      userGroups &&
      userGroups.includes("brinkcommerce-superuser") &&
      userGroups.includes("brinkcommerce-useradmin")
    ) {
      return (
        <>
          <p>{superUserDescription}</p>{" "}
          <p>You also have access to administrate users and API clients in Merchant Portal</p>
        </>
      )
    }

    if (
      userGroups &&
      userGroups.includes("brinkcommerce-campaignuser") &&
      userGroups.includes("brinkcommerce-superuser")
    ) {
      return (
        <>
          <p>{superUserDescription}</p>
          <p>You can also view, create and edit campaign groups and campaigns.</p>
        </>
      )
    }

    if (
      userGroups &&
      userGroups.includes("brinkcommerce-campaignuser") &&
      userGroups.includes("brinkcommerce-readonly")
    ) {
      return (
        <>
          <p>
            You have read-only access to Merchant Portal, which means you can view discounts,
            orders, configurations, etc.
          </p>
          <p>You can also view, create and edit campaign groups and campaigns.</p>
        </>
      )
    }
    if (userGroups && userGroups.includes("brinkcommerce-superuser")) {
      return <p>{superUserDescription}</p>
    }

    if (userGroups && userGroups.includes("brinkcommerce-readonly")) {
      return (
        <p>
          You have read-only access to Merchant Portal, which means you can view discounts, orders,
          configurations, etc.
        </p>
      )
    }

    if (userGroups && campaignOnly) {
      return (
        <CampaignOnly>
          You can view campaigns and campaign groups. However, to be able to create or edit, you
          require read-only access too. Only the user role user-admin can manage user access.
        </CampaignOnly>
      )
    }
  }

  return (
    <>
      <PageHeader title="Merchant Portal user">
        <SignOutButton handleClick={logout} overrideDisabled={true}>
          <LogoutIcon />
          <span className="hideOnSmallScreens">Sign out</span>
        </SignOutButton>
      </PageHeader>
      <Container>
        <Info>
          <Label>Given name</Label>
          <div>{userAttributes?.given_name}</div>
          <Break />
          <Label>Family name</Label>
          <div>{userAttributes?.family_name}</div>
          <Break />
          <Label>Username</Label>
          <div>{username}</div>
          <Break />
        </Info>
        <Info>
          <Label>Groups</Label>
          <div>
            {userGroups ? (
              userGroups?.map((role: string) => <div key={role}>{role}</div>)
            ) : (
              <div>No group connected to user</div>
            )}
          </div>
          <Break />
          <Label>Access</Label>
          {getRolesDescription()}
        </Info>
      </Container>
    </>
  )
}
