import gql from "graphql-tag"

const GET_CUSTOM_STATE = gql`
  query getCustomState($stateId: ID!) {
    Order {
      customState(stateId: $stateId ) {
        created
        deleted
        description
        id
        operations
        revision
        type
        updated
      }
    }
  }
`

export default GET_CUSTOM_STATE
