import React from "react"
import styled from "styled-components"
import { Tooltip as ReactTooltip } from "react-tooltip"

const StyledTooltip = styled(ReactTooltip)`
  min-width: 2rem;
  white-space: normal;
  background: ${(p) => p.theme.colors.black} !important;
  color: ${(p) => p.theme.colors.white} !important;
  line-height: 1.8rem;
  padding-top: 0.5rem !important;
  font-weight: 400 !important;
  font-family: "Museo Sans", serif;
  font-size: 1.2rem !important;
  z-index: 1000000;

  &:after {
    border-top-color: ${(p) => p.theme.colors.black} !important;
  }
`

type Props = {
  id: string
}

export const Tooltip = ({ id, ...props }: Props) => <StyledTooltip {...props} id={id} />
