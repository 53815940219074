import gql from "graphql-tag"

const REMOVE_CUSTOM_STATE_FROM_ORDER = gql`
  mutation removeCustomState($stateId: ID!, $orderId: ID!) {
    Order {
      removeCustomState(stateId: $stateId, orderId: $orderId)
    }
  }
`

export default REMOVE_CUSTOM_STATE_FROM_ORDER
