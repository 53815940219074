import React from "react"
import {
  CloseIcon,
  ProductParentRowContainer,
  ProductParentRowContainerBlur
} from "./ProductParents.styled"
import { ProductVariant } from "@lib/types/generated/graphql-types"
import placeholder from "../../../../../images/placeholder.svg"
import { ReactComponent as XmarkIcon } from "images/icons/xmark.svg"
import { useQuery } from "@apollo/client"
import GET_PRODUCT from "../../../../../graphql/queries/product/GetProduct"
import { handleImageError } from "helpers/image"

type Props = {
  padding?: string
  productParentId: string
  removeProductParent?: (parentId: string) => void
}

const ProductParent = ({ padding, removeProductParent, productParentId }: Props) => {
  const { data, loading } = useQuery(GET_PRODUCT, {
    variables: { id: productParentId },
    fetchPolicy: "cache-first"
  })

  const productParent = data?.Product?.parent

  if (loading)
    return (
      <ProductParentRowContainerBlur>
        <span>
          <b>Product name</b>
        </span>
        <span>
          <img src={placeholder} />
          Variant name
        </span>
        <span>
          <img src={placeholder} />
          Variant name
        </span>
        <span>
          <img src={placeholder} />
          Variant name
        </span>
      </ProductParentRowContainerBlur>
    )

  if (!productParent) return null
  return (
    <>
      <ProductParentRowContainer $padding={padding} key={productParent?.id}>
        {removeProductParent && (
          <CloseIcon onClick={() => removeProductParent(productParent?.id)}>
            <XmarkIcon />
          </CloseIcon>
        )}
        <span>
          <b>{productParent?.name}</b>
        </span>
        {productParent?.Variants?.map((variant: ProductVariant) => (
          <span key={variant?.id}>
            <img src={variant?.imageUrl ?? ""} alt={variant?.name} onError={handleImageError} />
            {variant?.name}
          </span>
        ))}
      </ProductParentRowContainer>
    </>
  )
}

export default ProductParent
