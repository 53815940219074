import React, { useEffect, useState } from "react"
import Box from "../../../components/Ui/Box"
import { TabText } from "./ManageOrder.styled"
import { useQuery } from "@apollo/client"
import GET_ORDER from "../../../graphql/queries/order/GetOrder"
import { useNavigate, useParams } from "react-router-dom"

import * as graphql from "lib/types/generated/graphql-types"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import CancelOrderPopup from "../../../components/Order/ManageOrder/CancelOrder/CancelOrderPopup"
import RefundOrder from "../../../components/Order/ManageOrder/RefundOrder/RefundOrder"
import DeliveryOrder from "../../../components/Order/ManageOrder/DeliverOrder/DeliveryOrder"
import CompensationOrder from "../../../components/Order/ManageOrder/CompensationOrder/CompensationOrder"
import { NoticeBanner } from "../../../components/Ui/Messages/NoticeBanner"
import ReleaseOrder from "../../../components/Order/ManageOrder/ReleaseOrder/ReleaseOrder"
import ModifyOrder from "../../../components/Order/ManageOrder/ModifyOrder/ModifyOrder"
import { Compensation } from "../../../components/Order/ManageOrder/Helpers/Compensation"
import { Delivery } from "../../../components/Order/ManageOrder/Helpers/Delivery"
import { Modification } from "../../../components/Order/ManageOrder/Helpers/Modification"
import { Refund } from "../../../components/Order/ManageOrder/Helpers/Refund"
import { Release } from "../../../components/Order/ManageOrder/Helpers/Release"
import { Cancellation } from "../../../components/Order/ManageOrder/Helpers/Cancellation"
import CancelOrder from "../../../components/Order/ManageOrder/CancelOrder/CancelOrder"

const ManageOrder = () => {
  const { id } = useParams()
  const { data, refetch, loading } = useQuery(GET_ORDER, { variables: { id } })
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const navigate = useNavigate()

  const order = data?.Order?.details
  const orderStatus = order?.status as graphql.OrderStatus
  const orderHistory = data?.Order?.history as graphql.OrderHistory
  const modifications = new Modification(order?.Modifications)
  const deliveries = new Delivery(order?.Deliveries)
  const releases = new Release(order?.Releases)
  const refunds = new Refund(order?.Refunds)
  const compensations = new Compensation(order?.Compensations)
  const cancellations = new Cancellation(order?.Cancellations)

  const showDelivery = () =>
    !releases.completed() &&
    !cancellations.active() &&
    !modifications.active() &&
    !releases.active() &&
    !refunds.active() &&
    !compensations.active()
  const showRelease = () =>
    !releases.completed() &&
    !orderStatus?.orderStates?.includes(graphql.OrderState.FullyDelivered) &&
    deliveries.completed() &&
    !refunds.active() &&
    !compensations.active()
  const showCompensation = () =>
    !refunds.completed() && deliveries.completed() && !releases.active() && !refunds.active()
  const showRefund = () =>
    !compensations.completed() &&
    deliveries.completed() &&
    !releases.active() &&
    !compensations.active()
  const showModify = () =>
    order && !deliveries.completed() && !cancellations.active() && !deliveries.active()
  const showCancellation = () =>
    !deliveries.completed() &&
    !deliveries.active() &&
    !modifications.active() &&
    !cancellations.completed()

  useEffect(() => {
    if (cancellations.completed()) {
      navigate(`/orders/${id}`)
    }
  }, [order?.Cancellations])

  return (
    <Box padding="0" margin="0">
      <PageHeader
        title={"Manage order #" + (order?.reference ?? "")}
        description={<>Manage order</>}
        goBackLinkUrl={`/orders/${id}`}
        goBackLinkText="Back to order"
        hasTabs
      >
        {showCancellation() && (
          <PrimaryButton mode="delete" handleClick={() => setShowCancelPopup(true)}>
            Cancel order
          </PrimaryButton>
        )}
      </PageHeader>
      <CancelOrderPopup
        setShowCancelPopup={setShowCancelPopup}
        showCancelPopup={showCancelPopup}
        order={order}
        refetch={refetch}
      />
      <Tabs>
        <TabList>
          {showDelivery() && <Tab>Deliveries</Tab>}
          {showModify() && <Tab>Modify order lines</Tab>}
          {deliveries.completed() && (
            <>
              {showRelease() && <Tab>Releases</Tab>}
              {showRefund() && <Tab>Refunds</Tab>}
              {showCompensation() && <Tab>Compensations</Tab>}
            </>
          )}
          {(cancellations.failed() || cancellations.pending()) && (
            <Tab>
              <TabText>Cancellations</TabText>
            </Tab>
          )}
        </TabList>
        {(cancellations.failed() || cancellations.pending()) && (
          <TabPanel>
            <CancelOrder order={order} refetch={refetch} orderHistory={orderHistory} />
          </TabPanel>
        )}
        {showDelivery() && (
          <TabPanel>
            <DeliveryOrder
              order={order}
              refetch={refetch}
              loading={loading}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showModify() && (
          <TabPanel>
            <ModifyOrder
              order={order}
              refetch={refetch}
              loading={loading}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showRelease() && (
          <TabPanel>
            <ReleaseOrder
              order={order}
              refetch={refetch}
              loading={loading}
              failedReleases={releases.failedReleases()}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showRefund() && (
          <TabPanel>
            {!refunds.completed() && !compensations.completed() && (
              <NoticeBanner type="reminder">{`You cannot issue both a refund and compensation for the same order. Once a refund is initiated, compensation cannot be applied to the order.`}</NoticeBanner>
            )}
            <RefundOrder
              order={order}
              refetch={refetch}
              failedRefunds={refunds.failedRefunds()}
              loading={loading}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showCompensation() && (
          <TabPanel>
            {!refunds.completed() && !compensations.completed() && (
              <NoticeBanner type="reminder">{`You cannot issue both a compensation and refund for the same order. Once a compensation is initiated, refund cannot be applied to the order.`}</NoticeBanner>
            )}
            <CompensationOrder
              order={order}
              refetch={refetch}
              failedCompensations={compensations.failedCompensations()}
              loading={loading}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
      </Tabs>
    </Box>
  )
}

export default ManageOrder
