import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import dayjs from "dayjs"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import GET_PRODUCT from "graphql/queries/product/GetProduct"

import Box from "components/Ui/Box"
import PageHeader from "components/Ui/Page/PageHeader"
import { Item, Label, List, Value } from "components/Ui/List/List"
import Status from "components/Ui/Status"
import { ProductVariantTable } from "components/Products/ProductVariantTable"
import { Tags } from "components/Products/Tags"
import ShowVariant from "./ShowVariant"
import CustomAttributes from "components/Products/CustomAttributes"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import DisplayAttributes from "components/Products/DisplayAttributes"
import { ProductBlock } from "components/Products/ProductBlock"
import { BrinkLoader } from "components/Ui/BrinkLoader"

import { ReactComponent as Icon } from "images/icons/magnifying-glass.svg"
import { ReactComponent as VariantsIcon } from "images/icons/layer-group.svg"
import ShowPrices from "../../components/Products/Prices/ShowPrices"
import { ProductVariant } from "lib/types/generated/graphql-types"
import { handleImageError } from "helpers/image"

const Container = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }
`

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 3rem 4rem;
  position: relative;
  margin: 0 0 2rem;

  h2 {
    display: flex;
    align-items: center;
    margin-top: 0;
  }
`

const ImageContainer = styled.div`
  width: 25rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-radius: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  padding: 1rem;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-right: 5rem;
  }

  svg {
    max-width: 20rem;
    max-height: 20rem;
  }
`

const Flex = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
  }
`

const StyledList = styled(List)`
  margin-top: 0;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  position: relative;

  p {
    line-height: 2.4rem;
  }
`

export const ViewProduct = () => {
  const { id } = useParams()
  const [showVariant, setShowVariant] = useState<ProductVariant>()

  const { data, loading, error } = useQuery(GET_PRODUCT, { variables: { id } })
  if (loading) return <BrinkLoader />

  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading product. [{error.message}], Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  if (!data) return null

  const product = data.Product?.parent

  return (
    <>
      {showVariant && <ShowVariant variant={showVariant} />}
      <Box padding="0" margin="0">
        <PageHeader
          title={
            <>
              {product.name}
              {product.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
            </>
          }
          goBackLinkUrl="/products"
          goBackLinkText="Back to products"
          hasTabs
          group="Product"
          id={product.id}
        />
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Prices</Tab>
            <Tab>Custom attributes</Tab>
            <Tab>Display names</Tab>
            <Tab>Display descriptions</Tab>
            <Tab>Tags</Tab>
          </TabList>

          <TabPanel>
            <Container>
              <ProductBlock>
                <h2>
                  <Icon />
                  General
                </h2>
                <Block>
                  <Flex>
                    <ImageContainer>
                      <img src={product?.imageUrl} alt={product.name} onError={handleImageError} />
                    </ImageContainer>
                    <StyledList>
                      <Item flexibleHeight showAsColumn>
                        <Label>Name:</Label>
                        <Value>{product.name}</Value>
                      </Item>
                      <Item flexibleHeight showAsColumn>
                        <Label>Created:</Label>
                        <Value>{dayjs(product.created).format("YYYY-MM-DD")}</Value>
                      </Item>
                      <Item flexibleHeight showAsColumn>
                        <Label>Updated:</Label>
                        <Value>{dayjs(product.updated).format("YYYY-MM-DD")}</Value>
                      </Item>
                    </StyledList>
                  </Flex>
                  <Description>
                    <Label>Description:</Label>{" "}
                    <p>{product.description || "No description added"}</p>
                  </Description>
                </Block>
              </ProductBlock>
              <ProductBlock>
                <h2>
                  <VariantsIcon />
                  Variants
                </h2>
                <ProductVariantTable
                  productData={product?.Variants}
                  loading={loading}
                  error={error}
                  setShowVariant={setShowVariant}
                />
              </ProductBlock>
            </Container>
          </TabPanel>
          <TabPanel>
            <ProductBlock>
              <ShowPrices variants={product?.Variants} />
            </ProductBlock>
          </TabPanel>
          <TabPanel>
            {product?.customAttributes && (
              <ProductBlock>
                <CustomAttributes customAttributes={JSON.parse(product.customAttributes)} />
              </ProductBlock>
            )}
          </TabPanel>
          <TabPanel>
            {product?.displayNames && (
              <ProductBlock>
                <DisplayAttributes name={true} attributes={product.displayNames} />
              </ProductBlock>
            )}
          </TabPanel>
          <TabPanel>
            {product?.displayDescriptions && (
              <ProductBlock>
                <DisplayAttributes name={false} attributes={product.displayDescriptions} />
              </ProductBlock>
            )}
          </TabPanel>
          <TabPanel>
            {product?.tags && (
              <ProductBlock>
                <Tags tags={JSON.parse(product.tags)} />
              </ProductBlock>
            )}
          </TabPanel>
        </Tabs>
      </Box>
    </>
  )
}
