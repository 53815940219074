import styled from "styled-components"

export const Container = styled.div`
    border: 0.1rem solid ${({ theme }) => theme.colors.borderLight};
    border-radius: 0.75rem;
    position: relative;
    left: -1rem;
    width: 100%;
    margin: 0.2rem 0;

    ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
        width: calc(100% - 3rem);
    }
`

export const InputWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  input {
    width: 80%;
    margin-left: 2rem;
    border: none;
  }
`

export const ActionWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 0 0.75rem 0.75rem;

  button {
    height: 3rem;
    border-radius: 0.75rem;
    font-size: 1.2rem;
    padding: 0 1.3rem
  }
`

export const Initials = styled.div`
  border-radius: 0.75rem;
  font-size: 1.8rem;
  background: ${({ theme }) => theme.colors.turquoiseDark};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  text-transform: uppercase;
`
