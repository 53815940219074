import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"
import { useLazyQuery } from "@apollo/client"
import { DiscountCodeSearchHit } from "@lib/types/generated/graphql-types"
import esb from "elastic-builder"
import PageHeader from "components/Ui/Page/PageHeader"
import { Query } from "@lib/types/common"
import SEARCH_DISCOUNT_CODES from "graphql/queries/discount/SearchDiscountCodes"
import { DiscountCodeTable } from "components/Discount/DiscountCode/DiscountCodeTable"
import Search from "components/Ui/Search"
import UpdateDiscountCode from "./UpdateDiscountCode"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
`

export const ShowDiscountCodes = () => {
  const [editCode, setEditCode] = useState("")
  const showSidebar = useSelector(editSidebarOpen)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [discountCodes, setDiscountCodes] = useState<DiscountCodeSearchHit[]>([])

  const referenceSearchQuery = esb
    .requestBodySearch()
    .query(
      esb.boolQuery().must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)])
    )

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 25,
    sort: [{ field: "updated", order: "DESC" }],
    query: JSON.stringify(query.query)
  }

  const [searchDiscountCodes, { loading, error }] = useLazyQuery(SEARCH_DISCOUNT_CODES, {
    variables
  })

  useEffect(() => {
    if (!showSidebar) setEditCode("")
  }, [showSidebar])

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchDiscountCodes({
          variables
        }).then((response) => {
          setDiscountCodes(response?.data?.searchDiscountCodes.hits)
        })
      },
      searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  return (
    <Container>
      <PageHeader
        title="Discount codes"
        description="Discount codes are listed below. These are generated in code rules. You can edit or search for a particular discount code."
      />
      <Search
        handleOnChange={(input) => setSearchQuery(input)}
        loading={loading}
        defaultValue={searchQuery}
        placeholder="Search by Rule ID or Discount Code"
      />
      <DiscountCodeTable
        discountCodes={discountCodes}
        setCodeToShow={setEditCode}
        loading={loading && !searchQuery}
        error={error}
      />
      {editCode && (
        <UpdateDiscountCode
          discountCode={editCode}
          setEditId={setEditCode}
          setDiscountCodes={setDiscountCodes}
        />
      )}
    </Container>
  )
}
